import { breakpoints } from '../../utils';
import { colors } from '~/constants';
import styled from 'styled-components';

export const SubjectItemContainer = styled.article`
  padding: 1rem 0;
  border-bottom: 1px solid #0000001a;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:has(+ div)) {
    margin-bottom: 1rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  svg {
    height: 1rem;
  }
`;

export const Title = styled.strong`
  font-size: 14px;
  line-height: 1.125rem;
  font-weight: 500;
  color: ${colors.chumbo};

  @media (${breakpoints[1176]}) {
    font-size: 1rem;
  }
`;

export const Subtitle = styled.span`
  font-size: 14px;
`;

export const TriggerContainer = styled.div`
  svg {
    color: ${colors.chumbo};
  }
`;

export const Icon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`;

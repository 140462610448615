import React from 'react';
import * as S from './styles';

const DownloadButtonApp = ({ children, iconSrc, ...rest }) => {
  return (
    <S.StyledButton {...rest}>
      {iconSrc && <S.IconDownload src={iconSrc} alt="" />}
      {children}
    </S.StyledButton>
  );
};

export default DownloadButtonApp;

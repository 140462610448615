import styled from 'styled-components';
import * as Common from '../styles';

export const { Text } = Common;
export const { TooltipText } = Common;

export const Container = styled(Common.Container)`
  padding-top: 40px;
`;

export const IntroContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 22px;
  margin-bottom: 40px;
`;

export const MobileOnlyWrapper = styled.div`
  display: block;

  @media screen and (min-width: 1176px) {
    display: none;
  }
`;

export const DesktopOnlyWrapper = styled.div`
  display: none;

  @media screen and (min-width: 1176px) {
    display: block;
  }
`;

export const Title = styled(Common.Title)`
  margin-bottom: 16px;
`;

export const SubTitle = styled(Common.Title)`
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-bottom: 16px !important;

  br {
    display: none;
  }

  @media screen and (min-width: 1176px) {
    font-size: 1.25rem !important;
    font-weight: 600 !important;

    br {
      display: block;
    }
  }
`;

export const TooltipWrapper = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
`;

export const Tooltip = styled.div`
  z-index: 10;
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 8px;
`;

export const TooltipArrow = styled.div`
  background-color: #f7f7f7;
  margin-top: -10px;
  margin-bottom: 10px;
  width: 18px;
  height: 18px;
  transform: rotate(45deg);
`;

export const SmallText = styled(Common.Text)`
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  margin-bottom: 16px !important;
`;

export const Image = styled.img`
  width: 127.5px;
  height: 109.84px;

  @media screen and (min-width: 1176px) {
    width: 309.93px;
    height: 267px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    flex-direction: row;
    gap: 24px;
  }
`;

export const InfoTextContainer = styled.div`
  @media screen and (min-width: 1176px) {
    min-height: 124px;
    margin-bottom: 32px;
  }
`;

export const Info = styled.div`
  flex: 1;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1176px) {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;

    ${SmallText} {
      margin-bottom: 0 !important;
    }
  }
`;

export const GrayboxWithIcon = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-top: auto;

  svg {
    width: 24px;
    height: 24px;
    stroke: #221f20;
    stroke-width: 1.5px;
  }

  ${SmallText} {
    margin-bottom: 0 !important;
  }

  @media screen and (min-width: 1176px) {
    padding: 16px;

    ${SmallText} {
      font-size: 1rem !important;
    }
  }
`;

export const Table = styled.table`
  display: block;

  tr {
    display: flex;
    gap: 24px;
    padding-right: 8px;

    td {
      padding: 24px 0;
      display: flex;
      align-items: center;
    }

    th:nth-of-type(1),
    td:nth-of-type(1) {
      margin-right: auto;
    }

    th:nth-of-type(2),
    th:nth-of-type(3),
    td:nth-of-type(2),
    td:nth-of-type(3) {
      flex: 0 0 56px;
      width: 56px;
      max-width: 56px;
      overflow: visible;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    td:nth-of-type(2) {
      border-right: 1px solid #221f20;
      border-left: 1px solid #221f20;
    }

    td:nth-of-type(3) {
      border-right: 1px solid #e4602f;
      border-left: 1px solid #e4602f;
    }
  }

  tr:nth-of-type(2) {
    td:nth-of-type(2) {
      border-top: 1px solid #221f20;
      border-radius: 16px 16px 0 0;
    }

    td:nth-of-type(3) {
      border-top: 1px solid #e4602f;
      border-radius: 16px 16px 0 0;
    }
  }

  tr:last-of-type {
    td {
      padding-bottom: 4px;
    }

    td:nth-of-type(2) {
      border-bottom: 1px solid #221f20;
      border-radius: 0 0 16px 16px;
    }

    td:nth-of-type(3) {
      border-bottom: 1px solid #e4602f;
      border-radius: 0 0 16px 16px;
    }
  }

  @media screen and (min-width: 1176px) {
    gap: 20px;
    padding-right: 0;

    tr td,
    tr th {
      border-width: 2px !important;
    }

    tr:nth-of-type(2) {
      td:nth-of-type(2),
      td:nth-of-type(3) {
        border-top: 0;
        border-radius: 0;
      }
    }

    tr {
      th:nth-of-type(2),
      th:nth-of-type(3),
      td:nth-of-type(2),
      td:nth-of-type(3) {
        flex: 0 0 330px;
        width: 330px;
        max-width: 330px;
        margin-bottom: 0;

        td:nth-of-type(2) {
          border-right: 1px solid #221f20;
          border-left: 1px solid #221f20;
        }

        td:nth-of-type(3) {
          border-right: 1px solid #e4602f;
          border-left: 1px solid #e4602f;
        }
      }

      th:nth-of-type(2) {
        border-top: 1px solid #221f20;
        border-right: 1px solid #221f20;
        border-left: 1px solid #221f20;
        border-radius: 16px 16px 0 0;
      }

      th:nth-of-type(3) {
        border-top: 1px solid #e4602f;
        border-right: 1px solid #e4602f;
        border-left: 1px solid #e4602f;
        border-radius: 16px 16px 0 0;
      }
    }

    tr:last-of-type {
      td {
        padding-bottom: 58px;
      }
    }
  }
`;

export const TableRow = styled.tr``;

export const TableHeader = styled.th`
  margin-bottom: 8px;
`;

export const TableData = styled.td`
  ${Text} {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  svg {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    stroke-width: 1px;
  }

  @media screen and (min-width: 1176px) {
    ${Text} {
      font-size: 1rem !important;
      font-weight: 500 !important;
    }

    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

export const TableIcon = styled(Common.Text)`
  font-size: 0.75rem !important;
  color: '#221f20';
  font-weight: 600 !important;
  padding-left: 16px;

  svg {
    width: 24px;
    height: 24px;
    stroke-width: 1.5px;
  }
`;

export const TableHeaderText = styled(Common.Text)`
  font-size: 0.75rem !important;
  color: ${props => (props.orange ? '#e4602f' : '#221f20')};
  font-weight: 600 !important;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const ColStyleRiachuelo = styled.col`
  border: 1px solid #221f20;
`;

export const ColStyleMastercard = styled.col`
  border: 1px solid #e4602f;
`;

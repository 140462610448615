import React from 'react';

import ImageHeader from '~/assets/img/faca-parte/header.png';
import Section1Image from '~/assets/img/faca-parte/section-1.png';
import Section2Image from '~/assets/img/faca-parte/section-2.png';
import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';
import {
  Container,
  Header,
  HeaderBody,
  HeaderContentWrapper,
  HeaderImage,
  HeaderImageWrapper,
  HeaderText,
  HeaderTitle,
  PageWrapper,
} from './styles';
import Section from './components/Section';

const sections = [
  {
    key: 1,
    title: 'Vem trabalhar com a gente',
    image: Section1Image,
    phrase: [
      `A Midway é um lugar cheio de oportunidades. Acreditamos nas pessoas, no que elas são e no que podem ser.`,
      `Quer fazer parte desse time incrível?`,
      `Preencha o formulário e se o seu perfil for compatível com alguma vaga, entraremos em contato com você.`,
    ],
    actions: [
      {
        key: 1,
        label: 'Cadastre seu currículo',
        url: 'https://blog.riachuelo.com.br/carreiras/',
        external: true,
      },
      {
        key: 2,
        label: 'Conheça a Midway',
        url: '/midway',
        outlined: true,
      },
    ],
  },
  {
    key: 2,
    title: 'Seja uma Startup parceira',
    image: Section2Image,
    phrase: [
      `Estamos sempre em busca de parceiros que tenham o mesmo propósito que o nosso: Escrever uma história mudando a visão que as pessoas têm sobre Financeiras, tornando soluções mais simples e fáceis para os nossos clientes.`,
    ],
    actions: [
      {
        key: 1,
        label: 'Conheça o programa de parcerias',
        url: '/faca-parte/startup',
      },
      {
        key: 2,
        label: 'Cadastre sua Startup',
        url: '/faca-parte/startup',
        outlined: true,
      },
    ],
  },
];

const BePart = () => {
  const hasMobile = useHasMobileDownloadHeader();

  const renderSections = () => {
    return sections.map((section, index) => (
      <Section {...section} index={index} />
    ));
  };

  return (
    <PageWrapper hasMobile={hasMobile}>
      <Header>
        <Container>
          <HeaderBody>
            <HeaderImageWrapper>
              <HeaderImage
                src={ImageHeader}
                alt="Faça parte da nossa história"
                title="Faça parte da nossa história"
              />
            </HeaderImageWrapper>
            <HeaderContentWrapper>
              <HeaderTitle>{`Faça parte \n da nossa história`}</HeaderTitle>
              <HeaderText>
                Nós existimos para conectar desejos a realizações. Esse é o
                nosso propósito, ou seja: nossa razão de existir e o porquê
                fazemos tudo o que fazemos.
              </HeaderText>
              <HeaderText>
                Para atingirmos esse objetivo, somos um time diverso, com mais
                de 35 mil RCHLOVERS que atua de forma colaborativa e ágil.
              </HeaderText>
            </HeaderContentWrapper>
          </HeaderBody>
        </Container>
      </Header>
      {renderSections()}
    </PageWrapper>
  );
};

export default BePart;

import React from 'react';
import PropTypes from 'prop-types';
import { generate } from 'shortid';
import TableBoostrap from 'react-bootstrap/Table';
import * as S from './styles';

const TableCard = ({ th, td, first, summary = '' }) => {
  return (
    <TableBoostrap
      striped
      bordered
      responsive
      className={!first ? '--not-first' : ''}
      aria-label={`Tabela ${th[0]}`}
      summary={summary}
    >
      <thead>
        <tr>
          {th.map(elm => (
            <S.Th scope="col" key={generate()} paddingBottom="10px">
              {elm}
            </S.Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {td.map(elm => (
          <tr key={generate()}>
            {elm.map(item => (
              <S.Td
                minWidth={!first}
                dangerouslySetInnerHTML={{ __html: item }}
                key={generate()}
              />
            ))}
          </tr>
        ))}
      </tbody>
    </TableBoostrap>
  );
};

TableCard.propTypes = {
  th: PropTypes.any,
  td: PropTypes.any,
  first: PropTypes.bool,
  summary: PropTypes.string,
};

TableCard.defaultProps = {
  th: [],
  td: [],
  first: false,
  summary: '',
};

export default TableCard;

import React from 'react';
import AppleStore from '~/assets/img/apple-store.png';
import GooglePlay from '~/assets/img/google-play.png';
import { trackingSelectContent } from '~/analytics';
import {
  Wrapper,
  Row,
  Col,
  MidwayLogo,
  SectionIcons,
  SocialMediaIcon,
  SocialMediaSection,
  StoreIcon,
  ListLinks,
  Link,
  StoreSection,
  StyledFooter,
  Section,
  RowCenter,
  Footer,
} from './styles';
import data from './data';
import LogoMidway from '../../assets/img/Footer/logo_midway_white.svg';
import Partners from './components/Partners';
import Copyright from './components/Copyright';
import BackToTop from './components/BackToTop';

const Desktop = () => {
  const renderFirstColumn = () => {
    return data.col1.items.map(item => {
      return (
        <div className="SectionIcon" key={`col-1-${item.id}`}>
          <a
            href={item.link}
            target={item.target}
            aria-label={item.ariaLabel}
            rel={item.rel}
            onClick={item.onClickFn}
          >
            <img
              src={item.icon}
              alt={item.ariaLabel}
              aria-label={item.ariaLabel}
            />
            <div
              className="sesionDescription"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          </a>
        </div>
      );
    });
  };

  const renderFourthColumn = () => {
    return (
      <div className="socialWrapper">
        <div>
          <h4>Siga a Midway</h4>
          <SocialMediaSection>
            {data.col2.items.map(({ Icon, ...item }) => {
              return (
                <a
                  key={`col-2-${item.id}`}
                  href={item.link}
                  target={item.target}
                  aria-label={item.ariaLabel}
                  rel={item.rel}
                  onClick={item.onClickFn}
                >
                  <SocialMediaIcon className="socialMediaIcon">
                    <Icon />
                  </SocialMediaIcon>
                </a>
              );
            })}
          </SocialMediaSection>
        </div>
        <StoreSection>
          <h4>Baixe nosso app</h4>
          <a
            onClick={() => trackingSelectContent('footer:app-store', 'home')}
            alt="Apple store"
            href="https://apps.apple.com/br/app/midway-app/id1548732480"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Baixar app na Apple store"
          >
            <StoreIcon
              width={140}
              height={41}
              src={AppleStore}
              alt="Apple Store"
            />
          </a>
          <a
            onClick={() => trackingSelectContent('footer:google-play', 'home')}
            alt="Play store"
            href="https://play.google.com/store/apps/details?id=br.com.midway"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Baixar app na play store"
          >
            <StoreIcon
              width={140}
              height={41}
              src={GooglePlay}
              alt="Play store"
            />
          </a>
        </StoreSection>
      </div>
    );
  };

  const renderThirdColumn = () => {
    return (
      <ListLinks>
        <h3>{data.col3.title}</h3>
        {data.col3.items.map(item => {
          return (
            <div key={`col-3-${item.id}`}>
              <Link
                onClick={item.onClickFn}
                href={item.link}
                target={item.target}
                rel={item.rel}
              >
                {item.title}
              </Link>
            </div>
          );
        })}
      </ListLinks>
    );
  };

  const renderSecondColumn = () => {
    return (
      <ListLinks>
        <h3>{data.col4.title}</h3>
        {data.col4.items.map(item => {
          return (
            <div key={`col-4-${item.id}`}>
              <Link
                onClick={item.onClickFn}
                href={item.link}
                target={item.target}
                rel={item.rel}
              >
                {item.title}
              </Link>
            </div>
          );
        })}
      </ListLinks>
    );
  };

  return (
    <Footer>
      <StyledFooter>
        <Wrapper>
          <Row>
            <Col>
              <MidwayLogo src={LogoMidway} alt="Midway logo" title="Midway" />
              <SectionIcons>{renderFirstColumn()}</SectionIcons>
            </Col>
            <Col>{renderSecondColumn()}</Col>
            <Col>{renderThirdColumn()}</Col>
            <Col className="right-spacer">{renderFourthColumn()}</Col>
          </Row>
          <hr />
          <Section padding="8px 51.5px 0 27px">
            <RowCenter>
              <Partners flex1 />
              <Copyright flex1 />
            </RowCenter>
            <BackToTop />
          </Section>
        </Wrapper>
      </StyledFooter>
    </Footer>
  );
};

export default Desktop;

export const mockData = [
  {
    id: 1,
    title: 'Cadastro',
    children: [
      {
        subTitle: 'Abertura de Conta',
        value: [
          {
            promotion: 'Grátis',
            valueBold: '',
            valueDescription: '',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Saque',
    children: [
      {
        subTitle:
          'Saques - cartão com função débito em terminais de autoatendimento',
        description:
          '(Para conta, cujo contrato prevê uso exclusivo de meios eletrônicos, não será cobrada tarifa neste canal)  ',
        number: '(1)',
        value: [
          {
            promotion: '',
            valueBold: 'R$ 6,90',
            valueDescription: 'por saque',
          },
        ],
      },
      {
        subTitle: 'Saques, com cartão de débito, nas Lojas Riachuelo',
        description:
          '(2 saques Grátis no mês. A partir do 3º saque será cobrado taxa ) (2)',
        number: '(2)',
        value: [
          {
            promotion: 'Dois primeiros grátis,',
            valueBold: 'a partir do 3º R$ 4,50',
            valueDescription: 'por saque',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Depósito',
    children: [
      {
        subTitle: 'Depósito por boleto',
        description:
          '(No mês, os 10 primeiros boletos são gratuitos. A partir do 11º, será cobrada uma taxa por boleto emitido) ',
        number: '(3)',
        value: [
          {
            promotion: 'Dez primeiros grátis,',
            valueBold: 'a partir do 11º R$ 1,50',
            valueDescription: 'por boleto',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Consulta',
    children: [
      {
        subTitle: 'Extrato – dos últimos 30 dias',
        description: '(pelo APP Midway)',
        value: [
          {
            promotion: 'Grátis',
            valueBold: '',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle: 'Extrato – dos últimos 30 dias',
        description: '(terminal de autoatendimento) ',
        number: '(4)',
        value: [
          {
            promotion: '',
            valueBold: 'R$ 3,50 ',
            valueDescription: 'por extrato',
          },
        ],
      },
      {
        subTitle: 'Extrato – demais períodos',
        description: '(pelo APP Midway) ',
        number: '(5)',
        value: [
          {
            promotion: 'Grátis',
            valueBold: '',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle: 'Consulta de Saldo',
        description: '(Terminal de auto atendimento)',
        value: [
          {
            promotion: '',
            valueBold: 'R$ 3,50 ',
            valueDescription: 'por consulta',
          },
        ],
      },
      {
        subTitle: 'Consulta de Saldo',
        description: '(pelo APP Midway)',
        value: [
          {
            promotion: 'Grátis',
            valueBold: '',
            valueDescription: '',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'Transferência de Recursos',
    children: [
      {
        subTitle: 'TED',
        description: '(Transferência com ou sem agendamento) ',
        number: '(6)',
        value: [
          {
            promotion: 'Grátis',
            valueBold: '',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle: 'Transferência entre contas na própria instituição ',
        number: '(7)',
        description: '',
        value: [
          {
            promotion: 'Grátis',
            valueBold: '',
            valueDescription: '',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: 'Cartão de crédito básico',
    children: [
      {
        subTitle: 'Anuidade cartão básico - internacional - Anuidade ',
        number: '(8)',
        description: '',
        value: [
          {
            promotion: 'Grátis',
            valueBold: '',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle: 'Fornecimento de 2a via do Extrato cartão com função debito ',
        number: '(9)',
        description: '',
        value: [
          {
            promotion: '',
            valueBold: 'Indisponível',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle:
          'Fornecimento de 2ª via da Fatura do cartão com função crédito ',
        number: '(10)',
        description: '',
        value: [
          {
            promotion: '',
            valueBold: 'Indisponível',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle:
          'Utilização de canais de atendimento para retirada em espécie no Brasil ',
        number: '(11)',
        description: '',
        value: [
          {
            promotion: '',
            valueBold: 'R$ 9,90 + Encargos',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle:
          'Utilização de canais de atendimento para retirada em espécie no exterior ',
        number: '(12)',
        description: '',
        value: [
          {
            promotion: '',
            valueBold: 'R$ 14,90 + Encargos + IOF',
            valueDescription: '',
          },
        ],
      },
      {
        subTitle: 'Pagamento de contas utilizando a função crédito ',
        number: '(13)',
        description: '',
        value: [
          {
            promotion: '',
            valueBold: 'Indisponível',
            valueDescription: '',
          },
        ],
      },
    ],
  },
];

import React from 'react';
import * as S from './styles';
import GridCards from './components/GridCards';
import HeroCarousel from './components/HeroCarousel';

const Insurance = () => {
  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BackgroundContainer>
          <HeroCarousel />
        </S.BackgroundContainer>
        <S.CentralizeContent>
          <S.CentralizeGrid>
            <S.ContainerCards>
              <S.TitleContainer>
                <S.Title>Conheça nossos Seguros</S.Title>
                <S.SubTitle>
                  A gente protege o que é mais importante pra você: Sua família,
                  suas conquistas e a sua saúde!
                </S.SubTitle>
              </S.TitleContainer>
              <GridCards />
            </S.ContainerCards>
          </S.CentralizeGrid>
          <S.CentralizePanel>
            <S.TextFinal>
              Não achou o produto ou serviço que estava procurando?
            </S.TextFinal>
            <S.TextFinalLink>
              Acesse a página de{' '}
              <a href="/contratos-de-produtos-descontinuados">
                produtos descontinuados
              </a>
            </S.TextFinalLink>
          </S.CentralizePanel>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default Insurance;

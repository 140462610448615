import styled from 'styled-components';

export const SectionWrapper = styled.div`
  background-color: #f7f7f7;
`;

export const SectionContainer = styled.div`
  display: flex;
  padding: 80px 132px;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 1270px;

  @media (max-width: 768px) {
    padding: 17px;
  }
`;

export const Title = styled.h2`
  font-size: 3.42rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 2.2rem;
    margin-bottom: 1em;
  }

  @media (max-width: 492px) {
    font-size: 1.2rem;
    margin-bottom: 1em;
  }
`;
export const Subtitle = styled.p`
  font-size: 1.71rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 492px) {
    font-size: 1rem;
  }
`;
export const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  margin-top: 40px;
`;

export const ImageWrapper = styled.div`
  width: 60%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
`;

export const CardsWrapper = styled.ol`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Card = styled.li`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  border-radius: 11px;
  border: 1px solid #dae5e3;
  background: #fff;
`;
export const CardTItle = styled.h3`
  color: #252525;
  font-size: 1.71rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 122.222% */

  @media screen and (max-width: 768px) {
    font-size: 1.2rem !important;
  }

  @media screen and (max-width: 492px) {
    font-size: 1rem !important;
  }

  &::after {
    content: '';
    display: block;
    width: 38px;
    height: 3px;
    background-color: #00726d;
    margin-top: 8px;
  }
`;
export const CardContent = styled.p`
  color: #252525;
  font-size: 1.14rem;
  font-weight: 500;
  line-height: 1.42rem; /* 125% */
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
`;

import React from 'react';
import { Modal } from 'react-bootstrap';

import { Icon } from '@midway/web-ui-component';
import './index.scss';

const ModalComponent = ({
  show,
  handleModal,
  children,
  invoiceInstallment,
  invoicement,
}) => {
  return (
    <Modal
      className={invoiceInstallment && 'invoice-details'}
      show={show}
      onHide={handleModal}
    >
      <Modal.Header>
        <button
          type="button"
          onClick={() => handleModal()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <Icon className="button-close" icon="times" size="1x" />
        </button>
      </Modal.Header>
      <Modal.Body style={{ minHeight: invoicement && 'max-content' }}>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;

import React from 'react';
import { TagContainer } from './styles';

const Tag = ({ text, discount, color }) => (
  <TagContainer discount={discount} color={color}>
    {text}
  </TagContainer>
);

export default Tag;

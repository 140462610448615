import {
  GradientOverlay,
  ScrollTo,
  SearchContainer,
  SearchTitle,
} from './styles';

import React from 'react';
import { useAttendancePortalState } from '~/pages/AttendancePortal/context/AttendancePortalContext';

const Search = () => {
  const {
    attendanceState: { banner },
  } = useAttendancePortalState();

  const handleScrollToCategories = () => {
    const titleElement = document.getElementById('end-banner');
    if (titleElement) {
      titleElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <SearchContainer
      role="banner"
      bannerUrl={banner}
      onClick={handleScrollToCategories}
    >
      <GradientOverlay />
      <ScrollTo id="end-banner" />
      <SearchTitle>CENTRAL DE AJUDA</SearchTitle>
    </SearchContainer>
  );
};

export default Search;

import React from 'react';

import { Button } from '@midway/web-ui-component';

import history from '~/services/history';
import * as S from './styles';

export const AccountHolderLoanButtons = props => {
  const {
    nextPage,
    changeButtonNext = false,
    disabledButton,
    changeButtonReturn = false,
  } = props;

  return (
    <S.FooterButton>
      <div className="ahl-button">
        <Button
          aria-label="voltar"
          state="primary"
          block
          outline
          title="Voltar"
          onClick={changeButtonReturn}
        />
      </div>
      {
        // se o changeButtonNext for chamado (passando a ser true), o onClick será personalizado
        changeButtonNext ? (
          <div className="ahl-button">
            <Button
              aria-label="Prosseguir com o empréstimo"
              state="primary"
              block
              title="Prosseguir"
              onClick={changeButtonNext}
              disabled={disabledButton}
            />
          </div>
        ) : (
          // caso contrário, será o padrão (puxando para uma rota a ser escolhida)
          <div className="ahl-button">
            <Button
              aria-label="Prosseguir com o empréstimo"
              state="primary"
              block
              title="Prosseguir"
              onClick={() => {
                history.push(`/${nextPage}`);
              }}
            />
          </div>
        )
      }
    </S.FooterButton>
  );
};

import React, { useState, useMemo } from 'react';
import { Button, Modal } from '@midway/web-ui-component';
import moment from 'moment';
import history from '~/services/history';
import IconClosed from '~/assets/img/icons/IconX.png';
import appleStoreIcon from '~/assets/img/apple-store.png';
import googlePlayIcon from '~/assets/img/google-play.png';
import { formatterCurrency } from '~/utils/utils';
import AccountHolderLoanHeader from '../../components/header/index';
import * as S from './styles';
import {
  tagSimulacaoEmprestimoStep3Resumo,
  tagSimulacaoEmprestimoStep3Final,
} from '../../../../services/tagging';

const AccountHolderLoanStep3 = props => {
  const { loanValue, qttPortion, firstDayPayment, firstInstallment } = props;

  const [showModalContract, setShowModalContract] = useState(false);

  const appleAppStoreUrl =
    'https://apps.apple.com/br/app/midway-app/id1548732480';
  const googlePlayStoreUrl =
    'https://play.google.com/store/apps/details?id=br.com.midway';

  const modalContract = useMemo(() => {
    if (showModalContract)
      return (
        <Modal
          state="white-lighter"
          openModal={setShowModalContract}
          id="ready-modal-for-screen-reader"
          onHide={() => setShowModalContract(false)}
        >
          <S.ModalContent>
            <div
              className="ahl-icon-closed"
              onClick={() => setShowModalContract(false)}
            >
              <img src={IconClosed} alt="" />
            </div>

            <div className="ahl-title">Gostou e quer contratar?</div>

            <div className="ahl-subtitle">
              Entre em contato em um dos nossos canais para realizar a
              contratação
            </div>

            <div className="ahl-middle">
              <div className="ahl-contract-app-top">
                <div className="ahl-middle-body-text-bold">
                  Contrate pelo app
                </div>

                <div className="ahl-middle-body-images">
                  <a
                    href={appleAppStoreUrl}
                    onClick={() =>
                      tagSimulacaoEmprestimoStep3Final('app-store')
                    }
                  >
                    <img src={appleStoreIcon} alt="applestoreicon" />
                  </a>
                  <a
                    href={googlePlayStoreUrl}
                    onClick={() =>
                      tagSimulacaoEmprestimoStep3Final('google-play')
                    }
                  >
                    <img src={googlePlayIcon} alt="googlePlayIcon" />
                  </a>
                </div>
              </div>

              <div className="ahl-middle-body">
                <div className="ahl-middle-body-text-bold">
                  Entre em contato pelo telefone
                </div>

                <div className="ahl-middle-body-text-bold">0800 727 4417</div>

                <div className="ahl-middle-body-text-small-deficient">
                  Deficientes Auditivos
                </div>
                <div className="ahl-middle-body-text-bold">0800 722 0626</div>

                <div className="ahl-middle-body-text-small">
                  Horário de atendimento 24h por dia, todos os dias da semana,
                  incluindo feriados nacionais.
                </div>
              </div>

              <div className="ahl-middle-body">
                <div className="ahl-middle-body-text-bold">
                  Vá a uma loja física
                </div>

                <div className="ahl-middle-body-text-small">
                  Visite alguma loja física Riachuelo e solicite a contratação
                  de um empréstimo
                </div>

                <div className="ahl-contract-app-bottom">
                  <div className="ahl-middle-body-text-bold">
                    Contrate pelo app
                  </div>

                  <div className="ahl-middle-body-images">
                    <a
                      href={appleAppStoreUrl}
                      onClick={() =>
                        tagSimulacaoEmprestimoStep3Final('app-store')
                      }
                    >
                      <img src={appleStoreIcon} alt="applestoreicon" />
                    </a>
                    <a
                      href={googlePlayStoreUrl}
                      onClick={() =>
                        tagSimulacaoEmprestimoStep3Final('google-play')
                      }
                    >
                      <img src={googlePlayIcon} alt="googlePlayIcon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </S.ModalContent>
        </Modal>
      );
  }, [showModalContract]);

  return (
    <S.Container className="container-fluid">
      <S.Content>
        <AccountHolderLoanHeader />

        <div className="ahl-title">Resumo da Simulação:</div>

        <div className="ahl-body-grey">
          <div className="ahl-body-grey-item">
            <div>Valor: </div>
            <div>{formatterCurrency.format(loanValue)}</div>
          </div>
          <div className="ahl-body-grey-item">
            <div>Qtde de parcelas: </div>
            <div>{qttPortion}</div>
          </div>
          <div className="ahl-body-grey-item">
            <div>Primeiro pagamento: </div>
            <div>{moment(firstDayPayment).format('DD/MM/YYYY')}</div>
          </div>
          <div className="ahl-body-grey-item">
            <div>Taxa de Juros: </div>
            <div>{`${firstInstallment.toString().substr(0, 5)} % a.m`}</div>
          </div>
        </div>

        <div className="ahl-text-bottom">
          Oferta válida até {moment().format('DD/MM/YYYY')}
        </div>
      </S.Content>

      <S.FooterButton>
        <div className="ahl-button">
          <Button
            aria-label="nova simulação de empréstimo"
            state="primary"
            block
            outline
            title="Nova simulação"
            onClick={() => {
              tagSimulacaoEmprestimoStep3Resumo(
                'nova-simulacao',
                loanValue,
                qttPortion,
                firstDayPayment,
                firstInstallment
              );
              history.push('/emprestimos');
            }}
          />
        </div>
        <div className="ahl-button">
          <Button
            aria-label="Contratar empréstimo"
            state="primary"
            block
            title="Contratar"
            onClick={() => {
              tagSimulacaoEmprestimoStep3Resumo(
                'contratacao',
                loanValue,
                qttPortion,
                firstDayPayment,
                firstInstallment
              );
              setShowModalContract(true);
            }}
          />
        </div>
      </S.FooterButton>

      {modalContract}
    </S.Container>
  );
};

export default AccountHolderLoanStep3;

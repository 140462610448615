import styled from 'styled-components';

export const ContainerCard = styled.div`
  padding-top: 60px;
  .card {
    overflow: visible !important;
    .card-body {
      overflow: visible !important;
    }
  }
`;

export const BodyCard = styled.div`
  ${props => (!props.col ? 'width: 290px' : '')};
  min-height: 445px;
  padding-left: 16px;
  padding-top: 25px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div.action {
    width: 250px;
    margin: 16px auto 20px auto;
  }
`;

export const IconCard = styled.div`
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid ${props => props.color};
  margin: -55px 0 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowIconCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleCard = styled.p`
  min-height: 75px;
  margin-top: 28px;
  margin-bottom: 10px !important;
  font-size: 25px !important;
  text-align: left;
  font-weight: bold;
  color: #252525;
`;

export const DescriptionCard = styled.div`
  font-size: 15px;
  color: #252525;
  text-align: left;
  min-height: 60px;
  margin: 0 auto 30px auto;
`;

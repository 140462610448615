import React from 'react';
import { useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';

function QRCode({ value }) {
  const { toogles } = useSelector(state => state.featuretoggle);
  const icon64 =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAABOCAYAAACOqiAdAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVSSURBVHgB7ZxPTBRXHMd/M7OAqSlsg1UDDVlIMYXEiiftSTl4bILl2IN6s5fCpXuyKU29eMNe6k081BuJd5tsjSUt6QFtmiWBFrZETKltVVoIbGdn+r4zC+6uszPvzc7MzjrvkxCWnbeb7Jff7/3+vNmfQgJkFnJp2qExVWs7Y5I5opiUYU+nqbV5ZipUUEl5QGTcK7WV7hROjj7zepFCHGS+z2VULTVJJl2g1hfKE0VRZkql/z4vvDdaqLvG7Q1gYWpR+4wtm6REokwb7UxABwusK5xlZWoqh4eUbAqGoY/WWp/qtHJwLjciRdvHMqDM3NxI5ZMvWZy0tLpUWV6VcPaellogKVo9Cka7fhJ7XpWr2oFAiuZCRi22QaMXFld20VWSeMJctn/f4hRVmyIJF6qiTloWV97bnpKEF3uP03a0MZKIkLaEM1Q6SxIhLOEUUk+QRIiU/cvMUALoTKXo0pEe+uDQEevvzZJOi9v/0pfra/Rod1fkrfaEe/U7HsOvHaSv3zluifeCDuv5U6+n6fIveVrc2uJ+P5USwFsdHQ6iVV+/za7jNy+JEO7j3r66ou3RqaXo4tFe4iURwsEVeTiX7iZeEuOqQa4DiRAO0TPIdSARws1vPudad/fpX8RLIoRDnhbkOpAI4fLbW5RdXXZdk11ZFkqCEyEcmH2yQWce/kjfVLgj9rT5f57T+z8v0OyfGySC1VYamL9v8r7g9tDxutfyLPO+urZCYYOcbLz7MJ3q6rLyr1pmn/zhKQQiqGiZVUlKcD3Libpcr60Xd+jm748pLCAaqgCUSvXgCQaNiAYCd9UrfQN0urOLwuJKX7+raFERyh53rf+YUDLJywQrncbLnY1mE4pwEO2rwWEKknNvdFs1Z1wILarCneC2QYB/RFDvFRShpiOXjvZYP41gB4N3Q3H9Rgg9j4N7DR30v5lP9PTFTjQQunDIs268PezZD3MCweBigxYbFpFUDrCYG4NDQq85zXLCOAWDWiIruZAc827wEPrawDGKM5HWqggU429652FxDAa1RF7kI/N3E+VTZpVxFw0ELpxXMxDBAhblFCx4gsGj4m7DdWYQBC7czMbjqtaNE04JLRJmr2AA0T5c/IniQCiu+gmagkV3qxg/dNiyMMBboqFlFQdrA6EIhwYhLMPr8AMWhk4KRPPa19DWvvs3/5lA2IQWHGAZV9e8b/D06q0B7JvXBc4DoiDUqIp2daNNTbh8FF1lUUJPR/CheY/nnIDLx2VfqySSPC67uuQZLJz4IkbBoJZIhMOH/2gpL3RSfpOlNTMhnl00SmSVA842eQ98rX3tt/jta5VEWnIhUHgFizgluW5EXqsiWCxu17/zMfvrUmz3tUqacpJ/eTnvGCzgyjhZbwWaIhwsKruyVPUcTt7jluS60bR7R5Db7SW2dpLbWl8jEz8ICBAEit72A6yjsk6bOn+qEgeaKhyIYznFQ2Ju8woaKZxPpHA+kcL5RArnEymcT6RwPpHC+WQvAcbQJa5visWhc7G+u+N6XaRh6pfy7frfLRCZIyThxnJV0zAekkQIWziib0kihB0cDpTukL3PSTixhLMm8pnGLZJwgVGQ++mIYRrTJOEC8zP3hbMGypl0nSTuMI2gVVUCbHToUyxQFEhSjwI0woMq4bDXmYY+KsVzxB71WJ7Q+lLJBTM0dfW8FK+Kgqbr5ysns7qOs1XUVE6Rox8dx9l6Tp4e+OH+NFs1QUmEBQLsaUIDlCuxrI+0KUVVLtCrD8tp6ZZh6tO+R3bXYg+J18bYi86Sqpwou3HrD4ln7siS2gdGybiHKopnSPz/q0jzkPbHO5cAAAAASUVORK5CYII=';

  if (!toogles.isEnableQRCodePayment || !value) return <></>;

  return (
    <QRCodeSVG
      className="qrcode"
      value={value}
      imageSettings={{
        src: icon64,
        x: undefined,
        y: undefined,
        height: 24,
        width: 24,
        excavate: true,
      }}
    />
  );
}

export default QRCode;

import React from 'react';
import { IconDeviceMobile, IconShield, IconCash } from '@tabler/icons-react';
import Container from '~/components/NewUi/Container';
import * as S from './style';
import { Typography } from '../NewUi';
import CardProduct from './CardProduct';
import IconCreditCard from '../../assets/img/icons/credit-card-2.svg';

const CARD_ITEMS = [
  {
    title: 'Conta digital grátis',
    description: 'Abra a sua conta digital Midway. É grátis.',
    icon: <IconDeviceMobile size={47} />,
    action: 'openAccount',
    actionText: 'Abrir conta gratuita',
  },
  {
    title: 'Cartões Riachuelo',
    description: 'Conheça e aproveite o cartão que está sempre na moda.',
    icon: (
      <img
        alt=""
        className="cardIcon"
        src={IconCreditCard}
        width={47}
        height={47}
      />
    ),
    link: '/cartoes#rchlo-credit',
    actionText: 'Conhecer cartões',
  },
  {
    title: 'Seguros e Assistências',
    description: 'Soluções para você e para os seus bens',
    icon: <IconShield size={47} />,
    link: '/produtos-financeiros',
    actionText: 'Conhecer produtos',
  },
  {
    title: 'Empréstimos',
    description: 'Precisa de dinheiro? A gente te ajuda',
    icon: <IconCash size={47} />,
    link: '/emprestimos',
    actionText: 'Conhecer empréstimo',
  },
];

const ProductsMidway = ({ handleOpenAccount }) => {
  return (
    <S.Section>
      <Container>
        <Typography variant="h2" styleType="title">
          Produtos Midway
        </Typography>
        <S.ProductWrapper>
          {CARD_ITEMS.map(cardItem => (
            <CardProduct
              {...cardItem}
              key={cardItem.title}
              aria-label={`Icone ${cardItem.title}`}
              action={
                cardItem.action === 'openAccount' ? handleOpenAccount : null
              }
            />
          ))}
        </S.ProductWrapper>
      </Container>
    </S.Section>
  );
};

export default ProductsMidway;

import React from 'react';
import styled from 'styled-components';

const SaibaMaisSection = styled.section`
  /* Estilos para SaibaMaisSection (classe "saiba-mais") */
`;

const SaibaMaisContainer = styled.div`
  padding: 32px 24px;
  max-width: 1440px;
  margin: 0 auto;
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  @media (min-width: 1024px) {
    gap: 24px;
    width: 47%;
  }
`;

const SaibaMaisTitle = styled.h3`
  font-size: 36px !important;
  font-weight: bold;
  text-align: left;
`;

const Subtitle = styled.div`
  text-align: left;
  font-size: 21px !important;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 24px !important;

    @media (min-width: 1024px) {
      margin-bottom: 12px;
    }
  }

  p.font-bold {
    font-weight: bold;
  }
`;

const SecondColumn = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const CardImage = styled.img``;

const SecondRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const KnowMoreList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap-y: 50px;
  }
`;

const KnowMoreItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 407px;

  @media (min-width: 1024px) {
    max-width: 407px;
  }
`;

const KnowMoreItemNumber = styled.span`
  border-radius: 50%;
  background-color: black;
  color: white;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
`;

const KnowMoreItemTitle = styled.p`
  text-align: left;
  font-weight: 500 !important;
  font-size: 20px;
`;

const KnowMoreItemDescription = styled.p`
  text-align: left;
  font-weight: normal;
  font-size: 14px;

  a {
    font-weight: 700;
    color: black;
  }

  strong {
    font-weight: 700;
    color: black;
  }
`;

const MobileCardImage = styled.img`
  display: block;
  margin-top: 24px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const SaibaMais = () => {
  const knowMoreContent = [
    {
      title: 'Cancelamento do cartão atual',
      description:
        'Estamos mudando a bandeira dos Cartões Riachuelo para Mastercard, e por isso seu cartão atual será cancelado muito em breve (inclusive aqueles que não têm data de vencimento próxima).<br/>Os cartões serão substituídos por um novo com bandeira Mastercard.',
    },
    {
      title: 'Senha',
      description:
        'Uma nova senha será enviada a você por SMS após o envio de seu novo cartão para o endereço escolhido. O cartão estará apto para ser usado com essa senha depois que for desbloqueado pelos Apps Midway ou Riachuelo.<br/>Se preferir você pode realizar o desbloqueio ligando em nossa <a class="font-bold underline" onClick="scrollToCallCenter()"> Central de Atendimento.</a>',
    },
    {
      title: 'Limite, data de vencimento, anuidade e compras parceladas',
      description:
        'Seu limite de crédito, data de vencimento da fatura e valor de anuidade continuarão os mesmos. Caso você tenha compras parceladas nada muda também. Você deve pagar a sua fatura normalmente.',
    },
    {
      title: 'Entrega do novo cartão ',
      description:
        'Você receberá seu novo cartão no endereço de sua preferência, basta atualizar seu endereço de entrega nos Apps Midway ou Riachuelo.<br/>Você também pode atualizar seu endereço ligando em nossa <a class="font-bold underline" onClick="scrollToCallCenter()">Central de Atendimento.</a>',
    },
    {
      title: 'Rastreio do cartão',
      description:
        'É possível rastrear a entrega de seu novo cartão pelos Apps Midway ou Riachuelo.',
    },
    {
      title: 'Desbloqueio do cartão',
      description:
        'Ao desbloquear o seu novo Cartão Riachuelo Mastercard pelos Apps Midway ou Riachuelo, o seu <strong>cartão anterior</strong> será <strong>cancelado automaticamente</strong>.<br/><br/>Se preferir você pode realizar o desbloqueio ligando em nossa <a class="font-bold underline" onClick="scrollToCallCenter()">Central de Atendimento.</a>',
    },
  ];

  return (
    <SaibaMaisSection id="saiba-mais">
      <SaibaMaisContainer>
        <FirstRow>
          <FirstColumn>
            <SaibaMaisTitle>SAIBA MAIS</SaibaMaisTitle>
            <Subtitle>
              <p>Fique tranquilo!</p>
              <p>
                Vamos ajudar você a entender a mudança para a bandeira
                Mastercard para que você tenha toda a segurança neste momento.
              </p>
              <p className="font-bold">
                Confira tudo o que você precisa saber:
              </p>
            </Subtitle>
          </FirstColumn>
          <SecondColumn>
            <CardImage src="/credit-card.png" alt="" />
          </SecondColumn>
        </FirstRow>
        <SecondRow>
          <KnowMoreList>
            {knowMoreContent.map(({ title, description }, index) => (
              <KnowMoreItem key={index}>
                <KnowMoreItemNumber>{index + 1}</KnowMoreItemNumber>
                <KnowMoreItemTitle>{title}</KnowMoreItemTitle>
                <KnowMoreItemDescription
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </KnowMoreItem>
            ))}
          </KnowMoreList>
          <MobileCardImage src="/credit-card.png" alt="" />
        </SecondRow>
      </SaibaMaisContainer>
    </SaibaMaisSection>
  );
};

export default SaibaMais;

import React, { useState, useEffect } from 'react';
import { trackingSelectContent } from '~/analytics';
import { useHistory } from 'react-router-dom';
import Accordion from '~/components/Accordion';
import { slugify } from '~/utils/utils';

// Banner
import BannerDesktop from '~/assets/img/limit-increase-campaign/banner-desktop.png';
import BannerMobile from '~/assets/img/limit-increase-campaign/banner-mobile.png';

// Step
import StepImg from '~/assets/img/limit-increase-campaign/step.svg';
import StepDesktop from '~/assets/img/limit-increase-campaign/steps-desktop.svg';
import StepMobile from '~/assets/img/limit-increase-campaign/steps-mobile.svg';

// Payment Step
import PaymentStep0 from '~/assets/img/limit-increase-campaign/payment-steps-0.svg';
import PaymentStep1 from '~/assets/img/limit-increase-campaign/payment-steps-1.svg';
import PaymentStep2 from '~/assets/img/limit-increase-campaign/payment-steps-2.svg';
import PaymentStep3 from '~/assets/img/limit-increase-campaign/payment-steps-3.svg';
import PaymentStep4 from '~/assets/img/limit-increase-campaign/payment-steps-4.svg';
import CardImage from '~/assets/img/limit-increase-campaign/card.png';

// HowTo
import Iphone from '~/assets/img/limit-increase-campaign/iphone.png';
import UnlockVideo from '~/assets/img/limit-increase-campaign/video-desbloqueio.mp4';
import PaymentVideo from '~/assets/img/limit-increase-campaign/video-pagarfatura.mp4';

// Our Benefits
import BrandsDesktop from '~/assets/img/limit-increase-campaign/marcas-riachuelo-desktop.svg';
import BrandsMobile from '~/assets/img/limit-increase-campaign/marcas-riachuelo-mobile.svg';
import Model from '~/assets/img/limit-increase-campaign/model.png';
import Benefits from '~/assets/img/limit-increase-campaign/benefits.svg';

import * as S from './styles';

const FLOW = 'dobro-limite-cartao-rchlo';

const Banner = () => {
  return (
    <S.BannerContainer>
      <S.BannerWrapper>
        <S.BannerDesktop src={BannerDesktop} />
        <S.BannerMobile src={BannerMobile} />
      </S.BannerWrapper>
    </S.BannerContainer>
  );
};

const Steps = () => {
  return (
    <S.StepsSection>
      <S.StepsLeftSection>
        <S.StepsTexts>
          <S.StepsTitle>
            Agora ficou fácil conquistar mais limite para o seu Cartão Riachuelo
          </S.StepsTitle>
          <S.StepsSubTitle>
            Pagar sua fatura em dia garante esse benefício e muito mais!
          </S.StepsSubTitle>
          <S.StepsSubTitle>Confira as regras e como participar</S.StepsSubTitle>
        </S.StepsTexts>
        <S.StepsDesktop src={StepDesktop} />
        <S.StepsMobile src={StepMobile} />
      </S.StepsLeftSection>
      <S.StepsRightSection>
        <S.StepsImg src={StepImg} />
      </S.StepsRightSection>
    </S.StepsSection>
  );
};

const PaymentSteps = () => {
  const images = [
    PaymentStep0,
    PaymentStep1,
    PaymentStep2,
    PaymentStep3,
    PaymentStep4,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 1000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <S.PaymentStepsSection>
        <S.PaymentStepsTexts>
          <S.PaymentStepsSubTitle>
            Depois, é muito simples!
          </S.PaymentStepsSubTitle>
          <S.PaymentStepsTitle>
            Pague sua fatura em dia e sem parcelar
          </S.PaymentStepsTitle>
          <S.PaymentStepsText>nos 3 primeiros meses de uso</S.PaymentStepsText>
        </S.PaymentStepsTexts>
        <S.PaymentStepsImg
          src={images[currentIndex]}
          alt={`Imagem ${currentIndex + 1}`}
        />
      </S.PaymentStepsSection>
      <S.CicleCard>
        <S.CicleCardContent>
          <S.CicleMobile src={CardImage} />
          <S.CicleText>
            <S.CicleTitle>Completou o ciclo?</S.CicleTitle>
            <S.CicleSubTitle>
              A gente aumenta o limite do seu cartão em até 60 dias!
            </S.CicleSubTitle>
          </S.CicleText>
          <S.CicleDesktop src={CardImage} />
        </S.CicleCardContent>
      </S.CicleCard>
    </>
  );
};

const HowTo = () => {
  return (
    <S.HowToSection>
      <S.HowToPanel>
        <S.ContentPanel>
          <S.HowToTexts>
            <S.HowToTitle>Como desbloquear meu cartão?</S.HowToTitle>
            <S.HowToText fontSize="16px" fontWeight={500}>
              Você pode desbloquear através do app Midway ou Riachuelo
            </S.HowToText>
            <S.HowToText paddingLeft="16px">
              • Acesse a aba de cartões
            </S.HowToText>
            <S.HowToText paddingLeft="16px">
              • Encontre a funcionalidade de desbloquear cartão
            </S.HowToText>
            <S.HowToText paddingLeft="16px">
              • Siga a jornada e, pronto, seu cartão está desbloqueado e pronto
              para uso!
            </S.HowToText>
            <S.HowToText fontSize="14px">
              Para o cartão de loja (Private Label), não é necessário
              desbloquear e você pode retirar o cartão físico em loja.
            </S.HowToText>
          </S.HowToTexts>
        </S.ContentPanel>
        <S.Device>
          <S.DeviceImage src={Iphone} />
          <S.DeviceVideo autoPlay loop muted>
            <source src={UnlockVideo} type="video/mp4" />
            Seu navegador não suporta o elemento de vídeo.
          </S.DeviceVideo>
        </S.Device>
      </S.HowToPanel>

      <S.HowToPanel>
        <S.ContentPanel>
          <S.HowToTexts>
            <S.HowToTitle>Como pagar minha fatura?</S.HowToTitle>
            <S.HowToText fontSize="16px" fontWeight="500">
              Você pode pagar sua fatura através do app Midway ou Riachuelo
            </S.HowToText>
            <S.HowToText paddingLeft="16px">
              • Acesse a aba de cartões
            </S.HowToText>
            <S.HowToText paddingLeft="16px">
              • Clique no botão pagar fatura
            </S.HowToText>
            <S.HowToText paddingLeft="16px">
              • Escolha a opção de pagar total
            </S.HowToText>
            <S.HowToText paddingLeft="16px">
              • Siga a jornada para finalizar o pagamento da sua fatura
            </S.HowToText>
            <S.HowToText fontSize="14px">
              É possível realizar o pagamento somente quando a fatura está
              fechada. Portanto, aguarde o vencimento.
            </S.HowToText>
          </S.HowToTexts>
        </S.ContentPanel>
        <S.Device>
          <S.DeviceImage src={Iphone} />
          <S.DeviceVideo autoPlay loop muted>
            <source src={PaymentVideo} type="video/mp4" />
            Seu navegador não suporta o elemento de vídeo.
          </S.DeviceVideo>
        </S.Device>
      </S.HowToPanel>
    </S.HowToSection>
  );
};

const OurBenefits = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    trackingSelectContent('saiba-mais-em-nossa-area-de-beneficios', FLOW);
    history.push('/beneficios');
  };

  return (
    <S.OurBenefits>
      <S.OurBenefitsWrapper>
        <S.OurBenefitsContainer>
          <S.OurBenefitsContent>
            <S.OurBenefitsTitle>Nossos benefícios</S.OurBenefitsTitle>
            <S.OurBenefitsSubTitle>
              Conheça todos as vantagens de ter um Cartão Riachuelo!
            </S.OurBenefitsSubTitle>
            <S.OurBenefitsImgWapper>
              <S.OurBenefitsImg src={Benefits} />
              <S.OurBenefitsDesktop src={BrandsDesktop} />
              <S.OurBenefitsMobile src={BrandsMobile} />
              <S.OurBenefitsButton onClick={handleButtonClick}>
                Saiba mais em nossa área de benefícios
              </S.OurBenefitsButton>
            </S.OurBenefitsImgWapper>
          </S.OurBenefitsContent>
        </S.OurBenefitsContainer>
      </S.OurBenefitsWrapper>
      <S.OurBenefitsModelWrapper>
        <S.OurBenefitsModel src={Model} />
      </S.OurBenefitsModelWrapper>
    </S.OurBenefits>
  );
};

const Questions = () => {
  const ITEMS = [
    {
      key: 1,
      title: 'Quem é elegível a participar?',
      content:
        '• Clientes que contrataram o Cartão Riachuelo Private Label com até R$1.000,00 de limite</br></br>• Clientes que contrataram o Cartão Riachuelo Mastercard com até R$3.000,00 de limite',
    },
    {
      key: 2,
      title: 'O que preciso fazer para participar da campanha?',
      content:
        '• Contratar o Cartão Riachuelo dentro das regras de elegibilidade</br></br>• Desbloquear o cartão e realizar a primeira compra, sem valor mínimo, em até 30 dias após a contratação</br></br>• Pagar integralmente 3 faturas contínuas até a data do vencimento, sem atraso.',
    },
    {
      key: 3,
      title: 'O que cancela a minha participação na campanha?',
      content:
        '• Não desbloquear o cartão no período de 30 dias após a contratação;</br></br>• Atrasar o pagamento da fatura;</br></br>• Realizar o pagamento de valor parcial da fatura durante o ciclo;</br></br>• Possuir alguma inadimplência com a Riachuelo;</br></br>• CPF com restrições: Ter pendências financeiras ou dívidas em nome do titular junto à Riachuelo.',
    },
    {
      key: 4,
      title: 'Se eu não gastar nada durante o mês, continuo participando?',
      content:
        'Sim, mas é necessário possuir fatura para pagamento durante os 3 primeiros meses após a contratação do cartão, que podem ser geradas através de:</br></br>• Compras parceladas;</br>• Produtos financeiros contratados;</br>• Compras recorrentes durante os meses.</br></br>Não há valor mínimo de compras.',
    },
    {
      key: 5,
      title: 'O que acontece se atingir o limite de uso máximo do meu cartão?',
      content:
        'Sem problemas! O importante é seguir cumprindo todas as regras e fazer o pagamento das faturas em seu valor total e até o vencimento, sem atraso.',
    },
    {
      key: 6,
      title: 'Não recebi o aumento de limite, o que aconteceu?',
      content:
        'Podemos considerar 3 cenários:</br></br>1. Não ter terminado o ciclo de pagamento das 3 faturas;</br></br>2. Se você concluiu o ciclo de pagamento, mas o prazo para análise e concessão do aumento de limite (60 dias) ainda não terminou;</br></br>3. Desclassificação da campanha devido ao não cumprimento de um ou mais dos requisitos.</br></br>Lembre-se: Após identificarmos o cumprimento dos requisitos, o aumento de limite será realizado em até 60 dias.',
    },
    {
      key: 7,
      title: 'Preciso pedir o aumento de limite?',
      content:
        'Não. O aumento de limite é feito automaticamente em até 60 dias após o cumprimento das regras da campanha e você será comunicado por e-mail. Portanto, não há necessidade de fazer qualquer solicitação.',
    },
    {
      key: 8,
      title: 'Onde consulto meu novo limite?',
      content:
        'Consulte seu limite no Aplicativo Riachuelo ou Midway através da aba Cartões.',
    },
    {
      key: 9,
      title: 'O limite conquistado é temporário ou expira?',
      content:
        'O aumento de limite não possui prazo de validade. Contudo, o limite do seu cartão poderá ter aumento ou redução após a finalização da participação na campanha, conforme análise de crédito da Midway.',
    },
    {
      key: 10,
      title: 'Em quanto tempo o aumento de limite acontece?',
      content:
        'O aumento de limite é feito automaticamente em até 60 dias após o cumprimento das regras da campanha.',
    },
  ];

  return (
    <S.Questions>
      <S.QuestionsContainer>
        <S.QuestionsTitle>Perguntas frequentes</S.QuestionsTitle>
        {ITEMS.map(item => (
          <Accordion
            title={item.title}
            content={item.content}
            onToggle={isOpenAccordion => {
              trackingSelectContent(
                `faq:${isOpenAccordion ? 'abrir' : 'fechar'}:${slugify(item.title)}`,
                FLOW
              );
            }}
          />
        ))}
      </S.QuestionsContainer>
    </S.Questions>
  );
};

const LimitIncreaseCampaign = () => {
  return (
    <S.Container>
      <S.ContainerGlobal>
        <Banner />
        <Steps />
        <PaymentSteps />
        <HowTo />
        <OurBenefits />
        <Questions />
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default LimitIncreaseCampaign;

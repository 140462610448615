import styled, { keyframes } from 'styled-components';

export const show = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 1;
  animation: ${show} 0.5s ease;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Modal = styled.div`
  border-radius: 11px;
  width: 899px; /* Largura padrão para telas pequenas */
  background-color: #fff;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 1rem;
  height: 643px;

  & p:last-of-type {
    margin-bottom: 0;
  }
`;

export const LeftImagePanel = styled.img`
  width: 492px;
  height: 730px;
  margin-left: -120px;
  margin-top: -102px;
`;

export const QRcode = styled.img`
  width: 176px;
  height: 176px;
`;

export const Container = styled.div`
  display: flex;
  width: 899px;
`;

export const TextPanel = styled.p`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 900;
  line-height: 39.01px;
  text-align: left;
`;

export const ContentText = styled.div`
  width: 438.88px;
  height: 524.08px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const Text = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.26px;
  text-align: left;
`;

export const TextCLose = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  border: none;
  background: transparent;
`;

import React from 'react';
import IconWelcome from '~/assets/img/Financial/lending/icon-welcome.svg';
import * as S from './styles';

const Welcome = () => {
  return (
    <S.Forms>
      <h1>Seja bem-vindo ao</h1>
      <h2>Simulador de Empréstimo</h2>
      <p>A sua liberdade é da nossa conta.</p>
      <p>Descubra qual oferta cabe no seu bolso.</p>
      <img src={IconWelcome} alt="Duas notas de dinheiro" />
    </S.Forms>
  );
};

export default Welcome;

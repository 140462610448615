import React from 'react';
import * as S from './styles';

const GridCards = ({ img, title, description }) => {
  return (
    <S.Container>
      <S.Cards>
        <S.Img src={img} />
        <S.GroupsContent>
          <S.Title>{title}</S.Title>
          <S.LineCards />
          <S.Description>{description}</S.Description>
        </S.GroupsContent>
      </S.Cards>
    </S.Container>
  );
};
export default GridCards;

import produce from 'immer';

const INITIAL_STATE = {
  showModal: false,
};

export default function calltoaction(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@calltoaction/SHOW_CALL_TO_ACTION': {
        console.log(action);
        draft.showModal = action.payload;
        break;
      }
      default:
    }
  });
}

export const Banner = [
  {
    name: 'BannerSeguroMaisProtecao',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-bolsa-pix-e-cartao-protegido-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-bolsa-pix-e-cartao-protegido-desktop.png',
    title:
      'segurança em cada movimento. Descubra como nosso seguro de bolsa, PIX e cartão protegido',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

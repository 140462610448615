import React from 'react';
import Modal from '~/components/NegotiationCampaign/Modal';
import { useNegotiation } from '~/context/negotiationV2';
import useIsMobile from '~/hooks/useIsMobile';
import format from '~/services/format';
import Tag from '~/pages/NewNegotiationCampaign/components/Tag';

import { Item, ContainerInvoicement } from './styles';

const ModalDetails = () => {
  const { handleModalDetails, showModalDetails, negotiationData, system } =
    useNegotiation();
  const isMobile = useIsMobile();

  const { balance, details } = negotiationData;

  const calcTotalTsys = () => {
    return (
      details?.financingTotal -
      details?.entry +
      (details?.composition?.financialExpensesAmount +
        details?.composition?.iofAmount)
    );
  };

  const financingAmount =
    system === 'TSYS'
      ? calcTotalTsys()
      : details?.financingAmount || details?.financingTotal;

  const total =
    details?.type === 'invoicement' || system === 'SIPF'
      ? balance?.balance?.total
      : balance?.total;

  const installment =
    details?.installmentQuantity || details?.installmentsNumbers;

  const totalNext = balance?.total || 0;
  const earlyDiscount = balance?.overcome?.earlyDiscount || 0;

  return (
    <Modal
      show={showModalDetails}
      invoiceInstallment
      handleModal={handleModalDetails}
      centered
      invoicement={details?.type === 'invoicement'}
    >
      {details?.type === 'invoicement' ? (
        <ContainerInvoicement isMobile={isMobile}>
          <div className="title">
            <Tag color="#9EFAF6" text="Aproveite" />
            <span>
              {installment} parcelas de{' '}
              {format.currency(details?.installmentAmount)}
            </span>
          </div>
          <div className="content">
            <div>
              <span>Taxa</span>
              <span>
                {system === 'SICC'
                  ? `${details?.composition?.calculationBasis?.percentageRateMonth}% a.m`
                  : format.currency(
                      details?.composition?.financialExpensesAmount
                    )}
              </span>
            </div>
            <div>
              <span>IOF</span>
              <span>{format.currency(details?.composition?.iofAmount)}</span>
            </div>
            <div>
              <span>Valor final</span>
              <span>{format.currency(financingAmount + details?.entry)}</span>
            </div>
          </div>
        </ContainerInvoicement>
      ) : (
        <>
          <Item>
            <span>Valor em atraso</span>
            <span>{format.currency(total)}</span>
          </Item>
          {details?.entry && (
            <Item>
              <span>Entrada</span>
              <span>{format.currency(details?.entry)}</span>
            </Item>
          )}
          {details?.discount && (
            <Item>
              <span>Desconto</span>
              <span>
                {format.currency(
                  details?.discount?.maxTotal +
                    (details?.discount?.antecipatedDiscountValue || 0)
                )}
              </span>
            </Item>
          )}
          {system === 'SIPF' && (
            <>
              <Item>
                <span>Próximas parcelas</span>
                <span>{format.currency(totalNext)}</span>
              </Item>
              <Item>
                <span>Desconto por antecipação</span>
                <span>{format.currency(earlyDiscount)}</span>
              </Item>
            </>
          )}
          <Item>
            <span>Valor a parcelar</span>
            <span>
              {format.currency(
                financingAmount === 0 ? total - details?.entry : financingAmount
              )}
            </span>
          </Item>
          {system !== 'TOPAZ' && system !== 'SIPF' && (
            <Item>
              <span>Encargos de financiamento</span>
              <span>
                {format.currency(
                  details?.composition?.financialExpensesAmount +
                    details?.composition?.iofAmount ||
                    details?.composition?.feeAmount +
                      details?.composition?.safeAmount
                )}
              </span>
            </Item>
          )}

          <Item noBorder>
            <span>Quantidade de parcelas</span>
            <span>{installment}</span>
          </Item>
        </>
      )}
    </Modal>
  );
};

export default ModalDetails;

// TO-DO pegar url das redes sociais da empresa.
const links = [
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/midway.digital/',
  },
  {
    label: 'Facebook',
    url: 'https://www.facebook.com/midwaydigital',
  },
  {
    label: 'Linkedin',
    url: 'https://www.linkedin.com/company/financeiramidway/',
  },
];

export default links;

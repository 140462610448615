import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import closeButtom from '~/assets/img/call-to-action/mids-close.svg';
import qrcode from '~/assets/img/new-home/qrcode.svg';
import modelImage from '~/assets/img/call-to-action/image.png';
import { showCallToAction } from '~/store/modules/calltoaction/actions';

import * as S from './styles';

const CallToAction = () => {
  const dispatch = useDispatch();
  const { showModal } = useSelector(state => state.calltoaction);

  const handleCloseModal = e => {
    e.preventDefault();
    dispatch(showCallToAction(false));
  };
  return (
    showModal && (
      <S.Modal id="modal-action-to-call">
        <S.ModalContent className="modalContent">
          <S.CloseButton onClick={handleCloseModal}>
            <img src={closeButtom} alt="fechar" />
          </S.CloseButton>
          <S.ModalImageWrapper>
            <img src={modelImage} alt="Já baixou seu aplicativo midway?" />
          </S.ModalImageWrapper>
          <S.ModalContenWrapper>
            <S.ModalContentHeader>
              <S.Title>Já baixou seu aplicativo Midway?</S.Title>
            </S.ModalContentHeader>
            <S.ModalContentBody>
              <S.ModalContentText>
                Mais vantagens e soluções financeiras para você.
              </S.ModalContentText>
              <S.ModalContentText>
                Leia o QR CODE com seu celular e baixe o app.
              </S.ModalContentText>
              <S.ModalQrContainer>
                <img src={qrcode} alt="QrCode" width={178} height={178} />
              </S.ModalQrContainer>
            </S.ModalContentBody>
          </S.ModalContenWrapper>
        </S.ModalContent>
      </S.Modal>
    )
  );
};

export default CallToAction;

import styled from 'styled-components';

export const Page = styled.div`
  background-color: #fff;
`;

export const PageTitle = styled.h1`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
  @media (max-width: 992px) {
    padding-top: 32px;
    padding-bottom: 15px;
    font-size: 22px;
  }
`;

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const Section = styled.section`
  padding-top: 60px;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : '30px'};
  @media (max-width: 992px) {
    padding-top: 12px;
    padding-bottom: 30px;
  }
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
`;

export const Row = styled.div``;

export const Col = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  color: #000;
  font-size: 32px;
  font-weight: bold;
  display: block;
  margin-bottom: 20px;
`;

export const Subtitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Text = styled.p`
  ${props => (props.maxWidth ? 'max-width: 500px;' : '')};
  white-space: pre-wrap;
  line-height: 1.8;
  color: #707070;
`;

export const ContentModal = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
  padding: 20px 20px 0 20px;
`;

export const TitleModal = styled.p`
  font-size: 20px;
  padding-left: 16px;
  font-weight: bold;
  color: #252525;
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

export const ContentTable = styled.div`
  padding: 0 0 30px 0;
  .table-map {
    padding: 0 0 15px 0;
  }
  .--not-first {
    table-layout: fixed !important;
    @media (max-width: 992px) {
      table-layout: auto !important;
    }
  }
`;

export const Th = styled.th`
  vertical-align: top !important;
`;

export const Td = styled.td`
  padding-top: 0 !important;
  span {
    ${props => (props.minWidth ? 'white-space: pre;' : '')}
  }
`;

export const Obs = styled.div`
  margin-top: -18px;
  padding: 0 0 0 12px;
`;

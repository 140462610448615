import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { scroll } from '~/services/helpers';
import { keyboard } from '~/constants';
import { usingTab, usingMouse } from '~/store/modules/accessibility/actions';

import Footer from '~/components/Footer';
import { Wrapper } from './styles';

const DefaultLayout = ({ children, isWhite }) => {
  const dispatch = useDispatch();
  const { isTabbing } = useSelector(state => state.accessibility);
  const [webView, setWebView] = useState(false);
  const [landingPage, setLandingPage] = useState(false);

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      scroll(hash.replace('#', ''));
      if (hash.replace('#', '') === 'registration-form') {
        const element = document.getElementById(hash.replace('#', ''));
        if (element) element.focus();
      }
    }
    if (isTabbing) {
      const element = document.getElementById('ready-page-for-screen-reader');
      if (element) element.focus();
    }
  });

  let handleFirstTab;

  const handleMouseDownOnce = useCallback(() => {
    dispatch(usingMouse());
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
  }, [dispatch, handleFirstTab]);

  handleFirstTab = useCallback(
    e => {
      if (e.keyCode === keyboard.TAB) {
        dispatch(usingTab());
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
      }
    },
    [dispatch, handleFirstTab, handleMouseDownOnce]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab);
    return () => {
      window.removeEventListener('keydown', handleFirstTab);
      window.removeEventListener('mousedown', handleMouseDownOnce);
    };
  }, [handleFirstTab, handleMouseDownOnce]);

  useEffect(() => {
    webViewVerify();
    verifyLandingPage();
  }, []);

  const webViewVerify = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const webViewParam = !!queryParams.get('webView');
    setWebView(webViewParam);
  };

  const verifyLandingPage = () => {
    const pathName = '/meunovomastercard';
    const url = window.location.pathname;
    setLandingPage(url === pathName);
  };

  return (
    <Wrapper isWhite={isWhite}>
      {children}
      {!webView && !landingPage && <Footer />}
    </Wrapper>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DefaultLayout;

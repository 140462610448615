import styled from 'styled-components';
import { Button as BaseButton } from '~/components/Button';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  padding-right: ${({ hasBackButton, isMobile }) => {
    let rightPadding = '12px';
    if (hasBackButton) {
      rightPadding = '100px';
      if (isMobile) {
        rightPadding = '48px';
      }
    }
    return rightPadding;
  }};
  background: #f7f7f7;
  height: 88px;
`;

export const HeaderBackButton = styled(BaseButton)`
  width: ${({ isMobile }) => (isMobile ? '48px' : '100px')};
  height: 48px;
  padding: 16px 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const HeaderCloseButton = styled(HeaderBackButton)`
  font-size: 24px;
  font-weight: 600;
  padding: 10px;
`;

export const HeaderTitle = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  flex: 1;
`;

import React, { useEffect, useState } from 'react';
import LoanApi from '~/services/LoanApi';
import * as S from './styles';
import LoanNotAvailable from './loan-not-available';
import Fail from '../../../components/Fail';

const ValueLoan = props => {
  const [otherValue, setOtherValue] = useState(false);
  const [loanAvailable, setLoanAvailable] = useState(0);
  const [loading, setLoad] = useState(true);
  const [error, setError] = useState(false);
  const [desirableAmount, setDesirableAmount] = useState('R$ 100,00');
  const [, setTokenCaptcha] = useState('');

  const combos = () => {
    const ensureMinimumValue = value => (value >= 100 ? value : 100);
    const combo5 = loanAvailable;
    const combo4 = ensureMinimumValue(Math.ceil(combo5 - combo5 * 0.3));
    const combo3 = ensureMinimumValue(Math.ceil(combo4 - combo4 * 0.3));
    const combo2 = ensureMinimumValue(Math.ceil(combo3 - combo3 * 0.3));
    const combo1 = ensureMinimumValue(Math.ceil(combo2 - combo2 * 0.3));
    return {
      combo1,
      combo2,
      combo3,
      combo4,
      combo5,
    };
  };
  const maskCurrency = value =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  const onChange = type => {
    let eventData = {
      target: {
        name: 'loanValue',
        value: combos()[type],
      },
    };

    if (type === 'OTHER') {
      setOtherValue(true);
      eventData = {
        target: {
          name: 'loanValue',
          value: 100,
        },
      };
      props.onChange(eventData);

      return;
    }

    props.onChange(eventData);

    setOtherValue(false);
  };

  const onChangeDesirableAmount = e => {
    const { value } = e.target;

    if (!value) {
      return setDesirableAmount(maskCurrency(0));
    }
    const moneyMaskNormalize = moneyValue => {
      return moneyValue && `${moneyValue}`.replace(/\D/g, '');
    };
    const rawValue = moneyMaskNormalize(value);

    const floatValue = `${rawValue.slice(
      0,
      rawValue.length - 2
    )}.${rawValue.slice(rawValue.length - 2)}`;

    setDesirableAmount(maskCurrency(floatValue));

    const stringToDecimal = (string = '') =>
      Number(string.replace(/\D/g, '')) / 100;

    const eventData = {
      target: {
        name: 'loanValue',
        value: stringToDecimal(maskCurrency(floatValue)),
      },
    };

    props.onChange(eventData);
  };

  const refreshCaptcha = async () => {
    await window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
        .then(token => {
          setTokenCaptcha(token);
          props.onSetToken(token);
          return token;
        });
    });
  };

  const getSimulateLimitValidation = async token => {
    setError(false);
    setLoad(true);
    const documentNumber = props.form.cpf.replace(/[^0-9]/g, '');
    const firstName = props?.form?.fullname.split(' ')[0];
    const birthDate = props?.form?.birthdate.split('/').reverse().join('-');
    const response = await LoanApi.getCustomerLimitValidation({
      documentNumber,
      token: token || props.token,
      firstName,
      birthDate,
    });
    if (response) {
      setLoanAvailable(response.available);
      props.showNavigation(response.available > 0);
      if (props.onSetCustomer) {
        props.onSetCustomer(prevState => ({ ...prevState, ...response }));
        setError(false);
      }
    }
    if (response.status !== 200) {
      setError(true);
    }
    setLoad(false);
  };

  useEffect(() => {
    (async () => {
      const response = await refreshCaptcha();
      getSimulateLimitValidation(response);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoanNotAvailable loading={loading} loanAvailable={loanAvailable > 0}>
      <S.Forms>
        {error ? (
          <Fail onTryAgainClick={getSimulateLimitValidation} />
        ) : (
          <>
            <h1>Qual valor de empréstimo você precisa?</h1>
            <h2>
              Valor máximo para simulação:{' '}
              <span>{maskCurrency(loanAvailable)}</span>
            </h2>

            <S.WrapperButton>
              {Object.keys(combos()).map((combo, key) => {
                const type = combo;
                const currency = combos()[type];

                if (
                  props.form.loanValue === currency &&
                  loanAvailable > 0 &&
                  !otherValue
                ) {
                  return (
                    <S.ButtonActive key={key}>
                      {maskCurrency(props.form.loanValue)}
                    </S.ButtonActive>
                  );
                }

                return (
                  <S.Button onClick={() => onChange(type)} key={key}>
                    {maskCurrency(currency)}
                  </S.Button>
                );
              })}
              {otherValue ? (
                <S.ButtonActive>Outro valor</S.ButtonActive>
              ) : (
                <S.Button onClick={() => onChange('OTHER')}>
                  Outro valor
                </S.Button>
              )}
            </S.WrapperButton>

            {otherValue && (
              <S.OtherValue>
                <h6>Valor do Empréstimo</h6>
                <input
                  type="text"
                  className="no-border"
                  value={desirableAmount}
                  onChange={onChangeDesirableAmount}
                />
                <h6>
                  O valor do empréstimo deve ser <br /> no mínimo R$ 100,00
                </h6>
              </S.OtherValue>
            )}
          </>
        )}
      </S.Forms>
    </LoanNotAvailable>
  );
};

export default ValueLoan;

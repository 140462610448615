import styled from 'styled-components';
import IconSearch from '~/assets/img/Financial/faqloans/iconSearch.svg';

export const Container = styled.div`
  background-color: #ffffff;
  padding: 0 !important;
`;

export const MidwayLogo = styled.div`
  width: 100%;
  height: 77px;
  display: flex;
  align-self: center;
  justify-content: flex-start;

  @media (max-width: 768px) {
    height: 56px;
    img {
      height: 56px;
    }
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  min-height: 300px;
  max-height: 300px;
  padding: 0 !important;

  @media (max-width: 768px) {
    min-height: 300px;
    max-height: 300px;
  }
  img {
    width: 100%;
    min-height: 300px;
    max-height: 300px;
    object-fit: cover;

    @media (max-width: 768px) {
      min-height: 300px;
      max-height: 300px;
    }
  }
`;

export const BannerSearch = styled.div`
  width: 60%;
  height: 46%;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: baseline;
  padding: 0px 46px;

  position: absolute;
  left: 20%;
  top: 82px;

  @media (max-width: 768px) {
    width: 100%;
    left: 0;
  }

  h1 {
    font-weight: bold;
    color: #000000 !important;
    font-size: 32px !important;

    @media (max-width: 768px) {
      margin-top: 5%;
      font-size: 30px !important;
    }
  }
`;

export const HeaderSearch = styled.div`
  position: relative;
  width: 100%;
  border-radius: 32px;
  height: 56px;
  background-color: #ffffff;
  display: flex;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const SearchInput = styled.input`
  height: 56px;
  width: 80%;
  border-radius: 32px;
  border: none;
  padding-left: 40px;
  display: flex;
  flex-grow: 1;

  input::placeholder {
    color: #606060;
    font-weight: bold;
    font-size: 17px !important;
  }

  @media (max-width: 768px) {
    padding: 18px;
  }
`;

export const SearchIcon = styled.div`
  background-image: url(${IconSearch});
  background-position: center;
  border-radius: 32px;
  height: 56px;
  width: 56px;
  position: inherit;
  border: none;

  @media (max-width: 768px) {
  }
`;

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  h2 {
    margin-top: 48px;
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-bottom: 32px;
  }

  h2 {
    font-weight: bold !important;
  }

  h3 {
    font-size: 24px !important;
  }

  a {
    font-weight: bold;
    color: #00726d;
    text-decoration: underline;
  }
`;

export const ContainerBreadcrumb = styled.div`
  display: flex;

  .breadcrumb-content {
    padding-top: 25px;

    width: 88%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    font: inherit;
    vertical-align: baseline;

    .breadcrumb-item {
      display: flex;
      justify-content: space-evenly;
    }

    .breadcrumb-item-mobile {
      display: flex;
      justify-content: space-evenly;
      display: none;
    }

    @media (max-width: 500px) {
      width: 44%;
      .breadcrumb-item {
        display: none;
      }
      .breadcrumb-item-mobile {
        display: flex;
        align-items: center;
        width: 144px;

        span {
          font-size: 14px !important;
        }
      }
    }
    @media (max-width: 1024px) {
      .breadcrumb-item {
        display: none;
      }
      .breadcrumb-item-mobile {
        display: flex;
        align-items: center;
        width: 150px;

        span {
          font-size: 14px !important;
          font-weight: bold !important;
        }
      }
    }
  }
  span {
    margin-right: 21px;
    font-size: 14px;
  }

  span:hover {
    cursor: pointer;
    font-weight: bold !important;
  }

  span:last-child {
    font-weight: bold !important;
  }
`;

export const Category = styled.div`
  .category {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 768px) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      padding-bottom: 24px;
    }
  }
  .item-category {
    text-align: initial;
    background-color: #ffffff !important;
    height: 77px;
    width: 176px;
    border: 1px solid #00726d;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    padding: 8px;

    @media (max-width: 768px) {
      min-width: 156px;
      white-space: pre-wrap;
    }

    h3 {
      color: #00726d;
      font-size: 16px !important;
      font-weight: bold !important;
      margin: 0;
    }
  }

  h2 {
    margin-top: 48px;
    color: #252525 !important;
    font-size: 36px !important;
    font-weight: bold !important;
    margin-bottom: 26px;
  }
`;

import * as S from './styles';

import { IconBrandWhatsapp } from '@tabler/icons-react';
import InnerHtml from '../InnerHtml';
import React from 'react';
import { useAttendancePortalState } from '~/pages/AttendancePortal/context/AttendancePortalContext';

export default function Contact() {
  const { category } = useAttendancePortalState();

  const contacts = category.contacts.list;
  const whatsapp = category.contacts.wpp;

  return (
    <S.Container>
      <S.Title>Contatos</S.Title>

      <S.AccordionsContainer>
        {contacts.map((contact, index) => (
          <S.Accordion
            key={index}
            title={contact.title}
            triggerChevron
            headerContent={<S.AccordionTitle>{contact.title}</S.AccordionTitle>}
            subheaderContent={
              contact.subTitle && (
                <S.AccordionSubheader>
                  <span>{contact.subTitle}</span>
                </S.AccordionSubheader>
              )
            }
            bodyContent={
              <S.ContactInfoContainer>
                {index === 0 && whatsapp && (
                  <S.WhatsappButton
                    onClick={() => {
                      window.open(whatsapp.value, '_blank');
                    }}
                  >
                    <IconBrandWhatsapp />
                    <span>{whatsapp.label}</span>
                  </S.WhatsappButton>
                )}

                {contact.items.map((item, index) => (
                  <S.ContactInfoItem
                    key={index}
                    onClick={() => {
                      if (item.type === 'phone') {
                        window.location.href = `tel:${item.label}`;
                        return;
                      }
                      if (item.type === 'email') {
                        window.location.href = `mailto:${item.label}`;
                        return;
                      }
                    }}
                  >
                    <strong>{item.label}</strong>
                    <InnerHtml raw={item.value} />
                  </S.ContactInfoItem>
                ))}
              </S.ContactInfoContainer>
            }
          />
        ))}
      </S.AccordionsContainer>
    </S.Container>
  );
}

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';
import Fallback from './components/Fallback';
import GlobalStyle from './styles/global';
import FirebaseProvider from './providers/Firebase';
import store from './store';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Fallback />}>
        <FirebaseProvider>
          <Router history={history}>
            <Routes />
            <GlobalStyle />
            <ToastContainer
              position="bottom-right"
              pauseOnFocusLoss={false}
              theme="dark"
            />
          </Router>
        </FirebaseProvider>
      </Suspense>
    </Provider>
  );
};

export default App;

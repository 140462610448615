/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { Image } from '@midway/web-ui-component';
import iconConfirm from '~/assets/img/icons/iconConfirmGreen.svg';

import {
  MaskedInput,
  MASK_TYPES,
} from '~/components/Common/Inputs/MaskedInput';
import { isValidAnsName, validarCPF, validateEmailFormat } from '~/utils/utils';

import success from '~/assets/img/Indications/success.svg';
import qrcode from '~/assets/img/new-home/qrcode.svg';
import service from '~/services/MGMApi';

import AppleStore from '~/assets/img/apple-store.png';
import GooglePlay from '~/assets/img/google-play.png';
import Modal from '~/components/Modal';
import * as S from './styles';

const SectionForm = ({ cpf }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 712);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const [preCadastrado, setPreCadastrado] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [guestFullName, setGuestFullName] = useState('');
  const [guestEmail, setGuestEmail] = useState('');
  const [guestDocumentNumber, setGuestDocumentNumber] = useState('');
  const [check, setCheck] = useState(false);

  const isValid =
    guestDocumentNumber &&
    guestFullName &&
    guestEmail &&
    check &&
    typeof isValidAnsName(guestFullName) === 'string' &&
    validateEmailFormat(guestEmail);

  const handleSubmit = async () => {
    if (!isValid || isLoading) return 'Error';
    setLoading(true);
    try {
      const sv = await service.postMemberGetMember({
        promoterDocumentNumber: cpf,
        guestDocumentNumber,
        guestDocumentType: 'CPF',
        guestEmail,
        guestFullName,
      });
      setLoading(false);

      if (sv === '') {
        setPreCadastrado(true);
      }
    } catch (error) {
      if (error === 'alreadyexists') {
        setShowModal(true);
      }
    }
  };

  return (
    <>
      <S.SectionForm className="container-fluid">
        {preCadastrado ? (
          <div className="container pre">
            {isMobile && (
              <Image src={success} alt="success" className="success" />
            )}
            <h2>
              <b>Você já está pré-cadastrado!</b>
            </h2>
            <h2 className="text-align">
              Baixe o app Midway agora mesmo para
              <br />
              concluir seu cadastro e resgatar
              <br />o cupom!
            </h2>

            {isMobile ? (
              <S.LinkApp>
                <a
                  href="https://apps.apple.com/br/app/midway-app/id1548732480"
                  aria-label="Baixe o nosso app na App Store (abre uma nova janela)"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gtm-event-category="midway:home"
                  data-gtm-event-action="clique:nosso-aplicativo"
                  data-gtm-event-label="botao:apple-store"
                >
                  <img
                    src={AppleStore}
                    alt="botão redirecionador para download do app da midway na apple store."
                    aria-hidden
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.midway"
                  aria-label="Baixe o nosso app na Play Store (abre uma nova janela)"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-gtm-event-category="midway:home"
                  data-gtm-event-action="clique:nosso-aplicativo"
                  data-gtm-event-label="botao:play-store"
                >
                  <img
                    src={GooglePlay}
                    alt="botão redirecionador para download do app da midway na google play."
                    aria-hidden
                  />
                </a>
              </S.LinkApp>
            ) : (
              <Image src={qrcode} className="qrcode" alt="qrCode" />
            )}
          </div>
        ) : (
          <div className="container">
            <h2>
              <b>VAMOS NESSA?</b>
            </h2>
            <h2>
              Faça seu cadastro abaixo e não esqueça de baixar o app Midway no
              seu celular.
            </h2>
            <h2 className="espacamento">
              <b>PRÉ-CADASTRO:</b>
            </h2>

            <div className="label-float">
              <input
                type="text"
                placeholder=" "
                onChange={e => setGuestFullName(e.target.value)}
                value={guestFullName}
              />
              <label>Digite seu nome completo</label>
              <div className="text-error">
                {guestFullName &&
                  isValidAnsName(guestFullName)?.valid === false &&
                  isValidAnsName(guestFullName)?.erro}
              </div>
            </div>

            <div className="label-float">
              <MaskedInput
                maskType={MASK_TYPES.CPF}
                placeholder="Informe seu CPF"
                maxLength="14"
                pressOnKeyDown={validarCPF(guestDocumentNumber)}
                value={guestDocumentNumber}
                setValue={setGuestDocumentNumber}
              />
              <label>Digite seu CPF</label>

              <div className="text-error">
                {guestDocumentNumber &&
                  !validarCPF(guestDocumentNumber) &&
                  guestDocumentNumber.length >= 2 &&
                  'CPF inválido'}
              </div>
            </div>

            <div className="label-float">
              <input
                type="email"
                inputMode="email"
                placeholder=" "
                onChange={e => setGuestEmail(e.target.value)}
                value={guestEmail}
              />
              <label>Digite seu email</label>
              <div className="input-email-text">
                {guestEmail && !validateEmailFormat(guestEmail)
                  ? 'E-mail inválido!'
                  : ''}
              </div>
            </div>

            <div className="ahl-checkbox">
              <div
                className={`ahl-check ${check ? 'checked' : ''}`}
                onClick={() => {
                  setCheck(prevState => {
                    return !prevState;
                  });
                }}
              >
                {check && (
                  <div className="ahl-check-selected">
                    <img src={iconConfirm} alt="ícone de confirmação" />
                  </div>
                )}
              </div>
              <label>
                Estou ciente que em caso de impossibilidade de término
                cadastral, a Midway poderá entrar em contato através dos dados
                pessoais informados para finalização do processo.
              </label>
            </div>
            <a
              aria-label="Enviar"
              href="javascript:;"
              onClick={handleSubmit}
              className={!isValid || isLoading ? 'disabled' : ''}
            >
              Enviar
            </a>
          </div>
        )}
      </S.SectionForm>
      {showModal ? (
        <Modal onClose={() => setShowModal(false)}>
          <S.ModalBody>
            <div className="indication__modal-header">
              <p>Atenção</p>
            </div>
            <div className="indication__modal-body">
              <p>Já existe um convite enviado para este documento!</p>
            </div>
          </S.ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

export default SectionForm;

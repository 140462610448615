import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding: 0 !important;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  position: relative;
  min-height: 200px;
  max-height: 400px !important;
  padding: 0 !important;

  margin-bottom: 50px;

  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }

  div.ahl-title {
    width: 43%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 23px;
    text-align: center;

    @media (max-width: 886px) {
      width: 60%;
    }

    @media (max-width: 415px) {
      width: 90%;
    }
  }

  div.ahl-body-grey {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: #efefef;

    border-radius: 5px;

    padding: 15px 8px;

    margin: 20px 0px;

    @media (max-width: 900px) {
      width: 70%;
    }

    @media (max-width: 520px) {
      width: 90%;
    }

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      text-align: center;

      padding: 5px 15px;

      div {
        font-size: 16px;

        @media (max-width: 590px) {
          font-size: 13px;
        }

        @media (max-width: 359px) {
          font-size: 10px;
        }

        &:first-child {
          color: #9f9f9f;
        }

        &:last-child {
          color: #4c4c4c;
          font-weight: bold;
        }
      }
    }
  }

  div.ahl-text-bottom {
    color: #9f9f9f;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  padding: 40px;

  font-size: 20px;

  button {
    width: 38% !important;
    margin-top: 30px;
  }

  div.ahl-title {
    width: 100%;
    font-weight: bold;
    font-size: 23px !important;
  }

  div.ahl-subtitle {
    width: 55%;
    color: #9f9f9f;
    font-size: 11px !important;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  div.ahl-middle {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;

    padding-top: 15px;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    &-body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      &-text-small {
        width: 70%;
        color: #9f9f9f;
        font-size: 11px !important;

        @media (max-width: 480px) {
          width: 100%;
        }

        &-deficient {
          width: 70%;
          color: #9f9f9f;
          font-size: 11px !important;

          padding-bottom: -10px;
          padding-top: 8px;
        }
      }

      &-text-bold {
        font-weight: bold;
        font-size: 16px !important;

        margin: 3px 0px;
      }

      &-images {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-arround;
        flex-direction: row;

        a {
          border-style: none;
        }

        img {
          width: 110px;
          height: 30px;
          padding-right: 16px;
          cursor: pointer;
        }
      }
    }
  }

  div.ahl-contract-app-top {
    padding-bottom: 15px;

    @media (min-width: 480px) {
      display: none;
    }
  }

  div.ahl-contract-app-bottom {
    @media (max-width: 480px) {
      display: none;
    }
  }
  div.ahl-icon-closed {
    position: absolute;

    top: 0;
    right: 0;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

export const FooterButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 400px) {
    flex-direction: column;
  }

  div.ahl-button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 400px) {
      width: 70%;
    }

    &:last-child {
      justify-content: flex-start;
      margin-left: 10px;

      @media (max-width: 400px) {
        justify-content: center;

        order: 1;
      }
    }

    &:first-child {
      justify-content: flex-end;

      @media (max-width: 400px) {
        justify-content: center;

        margin-top: 8px;

        order: 2;
      }
    }
  }
`;

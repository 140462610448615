import React from 'react';
import HeroCarousel from './components/HeroCarousel';
import {
  FaqSection,
  SessionHeader,
  SessionProducts,
  SessionOurServices,
} from './components';

import * as S from './styles';

const Assistance = () => {
  return (
    <S.Container className="container-fluid" id="ready-page-for-screen-reader">
      <S.BannerSpace>
        <HeroCarousel />
      </S.BannerSpace>
      <S.Content className="container">
        <SessionHeader />
        <SessionProducts />
        <SessionOurServices />
      </S.Content>
      <FaqSection />
    </S.Container>
  );
};

export default Assistance;

import React from 'react';
import StepNumber from '../StepNumber';
import * as S from './styles';

const ListStepNumber = ({ data }) => {
  return (
    <>
      {data.map((value, index) => (
        <S.Container>
          <S.StepNumberContainer>
            <S.VerticalLine visible={index === 0} />
            <StepNumber size={70} value={value.value} color="#242424" />
            <S.VerticalLine visible={index === data.length - 1} />
          </S.StepNumberContainer>
          <S.TextContainer>
            <S.ParagraphSection>{value.text}</S.ParagraphSection>
          </S.TextContainer>
        </S.Container>
      ))}
    </>
  );
};

export default ListStepNumber;

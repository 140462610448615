import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #006464;
  div.m-drawer-items-container {
    height: auto !important;
  }

  @media (max-width: 992px) {
    justify-content: start !important;
  }
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #006464;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

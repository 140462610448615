import styled from 'styled-components';

export const Content = styled.div``;
export const PageWrapper = styled.div``;

export const ItemsContainer = styled.div`
  /* overflow-x: auto; */
  position: relative;
  background-color: #221f20;
  height: 250px;
  margin-top: 220px;

  @media screen and (max-width: 492px) {
    margin-top: 180px;
  }
  .slick-dots {
    display: none !important;
  }
  .slick-list {
    overflow: visible;
  }
  .slick-slide {
    padding-right: 20px;
  }

  .CardCarouselRoot {
    width: 273px;
    @media screen and (max-width: 492px) {
      border: 1px solid #3b4443;
    }
  }
  .CardTitleContainerRoot {
    height: 5em;
  }
  .ActionButtonRoot {
    padding-left: max(10%, 16px);
    padding-right: max(10%, 16px);

    @media screen and (max-width: 320px) {
      padding-left: 26px;
      padding-right: 26px;
    }
  }
  .CardContentTextRoot {
    margin-top: 1em;
    min-height: 5em;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NavButton = styled.button`
  border: none;
  width: 30px;
  height: 30px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(45px);
  color: #fff;
`;

export const ItemsTrail = styled.div`
  transform: translateY(-50%);
`;

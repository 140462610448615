import React from 'react';
import appleStoreIcon from '~/assets/img/apple-store.png';
import googlePlayIcon from '~/assets/img/google-play.png';
import CartoesRCHLO from '~/assets/img/cartoes/cartoes-rchlo.png';
import { tagSimulacaoEmprestimoRCHLOButton } from '~/services/tagging';
import { NotCartoonistLoanHeader } from '../components/header/index';
import * as S from './styles';

const NewNCLCardRCHLO = () => {
  localStorage.removeItem('keyValidatedCpf');

  return (
    <S.Container className="container-fluid">
      <NotCartoonistLoanHeader />

      <S.Content>
        <div className="ncl-title">
          Você ainda não tem um cartão de crédito Riachuelo ou conta digital da
          Midway?{' '}
        </div>
        <div className="ncl-subTitle">
          O empréstimo é exclusivo para quem tem um dos dois. E por isso,
          queremos convidar você para ser <strong>nosso cliente.</strong>
        </div>

        <div className="ncl-compDiv">
          <div className="ncl-comp">
            <div className="ncl-text">
              Aproveite todas as vantagens de Empréstimos:
            </div>
            <div className="ncl-li">
              <ul>
                <li>- Taxas promocionais de lançamento</li>
                <li>- Dinheiro na hora</li>
                <li>- Simplicidade na hora de acompanhar o seu empréstimo</li>
                <li>- Limite de até R$ 7 mil e 90 dias para começar a pagar</li>
                <li>- Conta Gratuita</li>
              </ul>
            </div>
            <div className="ncl-text">Esperamos você!</div>

            <div className="ncl-app-images">
              <span className="ncl-text-bold">Baixe o aplicativo:</span>
              <div className="ncl-app-images-box">
                <a
                  href="https://apps.apple.com/br/app/midway-app/id1548732480"
                  onClick={() => tagSimulacaoEmprestimoRCHLOButton('app-store')}
                >
                  <img src={appleStoreIcon} alt="applestoreicon" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.midway"
                  onClick={() =>
                    tagSimulacaoEmprestimoRCHLOButton('google-play')
                  }
                >
                  <img src={googlePlayIcon} alt="googlePlayIcon" />
                </a>
              </div>
            </div>
          </div>

          <div className="ncl-comp">
            <div className="ncl-comp-img-cartoes">
              <img src={CartoesRCHLO} alt="" />
            </div>
          </div>
        </div>
      </S.Content>
    </S.Container>
  );
};

export default NewNCLCardRCHLO;

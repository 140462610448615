import styled from 'styled-components';

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 80px;
  @media (max-width: 1000px) {
    margin-bottom: 35px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;

export const CentralizeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1000px) {
  }
`;

export const CentralizeGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  padding-left: 120px;
  @media (max-width: 1000px) {
    width: 90%;
    padding-left: 0px;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 130px;
  margin-top: 86px;
  display: flex;
  align-items: center;
  justify-content: left-start;

  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 80px;
    margin-top: 32px;
  }
`;
export const Title = styled.h2`
  font-family: Montserrat;
  font-size: 32px !important;
  font-weight: 500;
  line-height: 39px;

  @media (max-width: 1000px) {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 22px;
  }
`;

export const SubTitle = styled.p`
  margin-top: 16px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  color: #3b4443;
  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const CentralizePanel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0px 40px 132px;
  width: 100%;
  height: 130px;
  background-color: #f7f7f7;
  overflow-y: auto;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 16px 0px 26px 16px;
  }
`;

export const InformationPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const TextFinal = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`;
export const TextFinalLink = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #221f20;

  a {
    text-decoration: underline;
    color: #221f20;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

import React from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import Icons from './icons';
import links from './content';
import * as S from './styles';

const SocialIcons = ({ color, lighten }) => {
  return (
    <S.SocialLinksWrapper>
      <S.SocialLinksList>
        {links.map(link => {
          const Icon = Icons[link.label];
          return (
            <S.SocialLinksItem key={shortid.generate()}>
              <S.SocialLinksLink
                color={color}
                lighten={lighten}
                onClick={() => {
                  window.open(link.url, '_blank');
                }}
                title={link.label}
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.IconWrapper>
                  <Icon />
                </S.IconWrapper>
              </S.SocialLinksLink>
            </S.SocialLinksItem>
          );
        })}
      </S.SocialLinksList>
    </S.SocialLinksWrapper>
  );
};

SocialIcons.propTypes = {
  color: PropTypes.string,
  lighten: PropTypes.bool,
};

SocialIcons.defaultProps = {
  color: '#FFF',
  lighten: false,
};

export default SocialIcons;

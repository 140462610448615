/* eslint-disable no-unused-vars */
import React from 'react';
import { isAndroid } from 'react-device-detect';

export default function RedirectToStores() {
  return isAndroid
    ? window.location.replace(
        'https://play.google.com/store/apps/details?id=br.com.midway&hl=pt_BR&gl=BR'
      )
    : window.location.replace(
        'https://apps.apple.com/br/app/midway-é-riachuelo/id1548732480'
      );
}

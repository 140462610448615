/* eslint-disable array-callback-return */
import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import loanService from '~/services/LoanApi';
import cardService from '~/services/CardApi';
import format from '~/services/format';

import { useAuth } from '~/context/auth';

const DEFAULT_LINK_NEGOTIATION =
  '/campanha-quitacao?utm_source=Conta-Online&utm_medium=Website&utm_campaign=quitacao-de-divida&utm_term=Conta-Online';

const parseInvoice = type => response =>
  response.cardInvoices.map(invoice => ({
    success: invoice.success,
    payline: invoice.payline,
    dueDate: moment(invoice.invoiceDueDate).format('DD/MM/YYYY'),
    nextDueDate: moment(invoice.invoiceNextDueDate).format('DD/MM/YYYY'),
    id: invoice.maskedPan.substr(-4),
    valueInvoice: invoice.valueInvoice,
    value: invoice.valueToPay,
    flag: invoice.flag,
    type,
    maskedPan: invoice.maskedPan,
    product: invoice.product,
  }));

const parseLoan = type => response =>
  response.map(loan => ({
    payline: loan.linhaDigitavel,
    dueDate: moment(loan.loanDueDate).format('DD/MM/YYYY'),
    id: loan.numeroContrato,
    financedAmount: format.currency(loan.valorFinanciado),
    valueBankSlip: format.currency(loan.valorTitulo),
    type,
    fourDigitsOrContract: loan.numeroContrato,
  }));

const useGetBankSlips = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [
    showCallToActionNegotiationCampaign,
    setShowCallToActionNegotiationCampaign,
  ] = useState(false);
  const [linkToNegotiation, setLinkToNegotiation] = useState(
    DEFAULT_LINK_NEGOTIATION
  );
  const { setDocumentNumber } = useAuth();

  const handleReCaptchaVerify = useCallback(() => {
    return executeRecaptcha('contaonline');
  }, [executeRecaptcha]);

  const memoized = useCallback(
    (type, params) => {
      setLoading(true);

      const service = {
        loan: {
          request: ({ birthDate, documentNumber, eventCaptcha }) =>
            loanService.getBilletLoan({
              cpf: documentNumber,
              dataNascimento: birthDate,
              event: eventCaptcha,
            }),
          parse: parseLoan(type),
        },
        invoice: {
          request: ({ birthDate, documentNumber, eventCaptcha }) =>
            cardService.getBilletCreditCard({
              documentNumber,
              birthDate,
              eventCaptcha,
            }),
          parse: parseInvoice(type),
        },
      };

      const verifyShowCallToActionNegotiation = dataToFormat => {
        dataToFormat.map(invoice => {
          if (moment().diff(moment(invoice.dueDate, 'DD/MM/YYYY'), 'days') > 1)
            setShowCallToActionNegotiationCampaign(true);
        });
      };

      handleReCaptchaVerify().then(eventCaptcha => {
        service[type]
          .request({ ...params, eventCaptcha })
          .then(response => {
            const formattedData = service[type].parse(response);
            verifyShowCallToActionNegotiation(formattedData);
            setData(formattedData);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            setError(err?.response?.status);
          });
      });
    },
    [handleReCaptchaVerify]
  );

  const cleanup = () => {
    setLoading(false);
    setError(false);
    setData(false);
    setShowCallToActionNegotiationCampaign(false);
  };

  const updateUserData = ({ documentNumber, birthDate }) => {
    setUserData(prevState => ({ ...prevState, documentNumber, birthDate }));
  };

  useEffect(() => {
    if (userData?.documentNumber && userData?.birthDate) {
      setDocumentNumber(userData?.documentNumber);
      setLinkToNegotiation(
        `${linkToNegotiation}&documentNumber=${userData.documentNumber}&birthDate=${userData.birthDate}`
      );
    } else setLinkToNegotiation(linkToNegotiation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDocumentNumber, userData]);

  return [
    memoized,
    {
      loading,
      error,
      data,
      showCallToActionNegotiationCampaign,
      linkToNegotiation,
      updateUserData,
    },
    cleanup,
  ];
};

export default useGetBankSlips;

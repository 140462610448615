export const banner = [
  {
    name: 'BannerSaqueFGTS',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-fgts-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-fgts-desktop.jpeg',
    title:
      'Antecipe seu saldo do fgts. Adiante seus sonhos com até 10 parcelas do seu saque-aniversário do fgts',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1260px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 1000px) {
    width: 100% !important;
    max-width: 100%;
  }
`;

export const Cards = styled.div`
  max-width: 377px;
  min-height: 392px;
  margin: 0 24px 34px 0;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 6px -1px #1f2b2a1f;

  @media (max-width: 1000px) {
    width: 100% !important;
    max-width: 100% !important;
  }
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 16px 16px 0px 0px;
`;

export const GroupsContent = styled.div`
  padding: 24px;

  @media (max-width: 1000px) {
    padding: 16px;
  }
`;
export const Title = styled.p`
  font-family: Montserrat;
  line-height: 28px;
  text-align: left;
  color: #221f20;
  font-size: 20px;
  font-weight: 700;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

export const LineCards = styled.div`
  width: 37px;
  border: 1px solid #a06d2c;
  margin-top: 16px;
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-top: 21px;
`;

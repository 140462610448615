import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const DescriptionLink = styled.a`
  color: #000000;
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  text-align: left;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

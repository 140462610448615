import { useState } from 'react';
import cmsService from '~/services/cms/pages';

const useCMS = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});

  const getDataFromCms = permalink => {
    setLoading(true);
    setError(false);
    cmsService
      .getDataByPermalink(permalink)
      .then(response => {
        const json = JSON.parse(response.data.response.draft.content);
        setData(json);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  return [getDataFromCms, { loading, error, data }];
};

export default useCMS;

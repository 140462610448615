import React, { useState, useMemo, useCallback } from 'react';
import { Button } from '@midway/web-ui-component';
import {
  MaskedInput,
  MASK_TYPES,
} from '~/components/Common/Inputs/MaskedInput';
import history from '~/services/history';
import { validateEmailFormat } from '~/utils/utils';
import { NotCartoonistLoanHeader } from './components/header/index';
import * as S from './styles';
import {
  tagSimulacaoEmprestimoDadosPessoais,
  tagSimulacaoEmprestimoDadosPessoaisErro,
} from '../../services/tagging';

const NotCartoonistLoan = () => {
  const [valuePhone, setValuePhone] = useState('');
  const [valueName, setValueName] = useState('');
  const [valueBirth, setValueBirth] = useState('');
  const [valueEmail, setValueEmail] = useState('');

  const ValidatedPhone = useCallback(() => {
    if (valuePhone) {
      if (valuePhone.length >= 10) return true;
    }
    return false;
  }, [valuePhone]);

  const fieldVerification = useMemo(() => {
    if (
      valueName &&
      valueBirth &&
      validateEmailFormat(valueEmail) &&
      ValidatedPhone()
    )
      return false;
    return true;
  }, [valueName, valueBirth, valueEmail, ValidatedPhone]);

  if (!localStorage.getItem('keyValidatedCpf'))
    return history.push('/emprestimos');

  return (
    <S.Container className="container-fluid">
      <S.Content>
        <NotCartoonistLoanHeader />

        <div className="ahl-title">Dados pessoais </div>

        <input
          placeholder="Nome completo"
          type="text"
          value={valueName}
          onFocus={() =>
            tagSimulacaoEmprestimoDadosPessoais(
              'clique-campo-nome-completo',
              false
            )
          }
          onChange={e => {
            const { value } = e.target;
            const regex = /^\D+$/;

            if (regex.test(value) || !value) {
              setValueName(value);
            }
          }}
        />

        <S.BirthDateInput
          placeholder="Data de nascimento"
          type="date"
          inputMode="none"
          value={valueBirth}
          onChange={e => setValueBirth(e.target.value)}
          onFocus={_ => {
            tagSimulacaoEmprestimoDadosPessoais(
              'clique-campo-data-de-nascimento',
              false
            );
          }}
        />

        <>
          <input
            placeholder="E-mail"
            type="email"
            value={valueEmail}
            onFocus={() =>
              tagSimulacaoEmprestimoDadosPessoais('clique-campo-email', false)
            }
            onChange={e => setValueEmail(e.target.value)}
            className="input-email"
            inputMode="email"
          />
          <div className="input-email-text">
            {valueEmail && !validateEmailFormat(valueEmail)
              ? 'e-mail inválido!'
              : ''}
          </div>{' '}
        </>

        <MaskedInput
          maskType={MASK_TYPES.PHONE_NUMBER}
          placeholder="Celular"
          maxLength="14"
          value={valuePhone}
          setValue={setValuePhone}
          onFocus={() =>
            tagSimulacaoEmprestimoDadosPessoais('clique-campo-telefone', false)
          }
        />
      </S.Content>

      <S.FooterButton>
        <div className="ahl-button">
          <Button
            aria-label="voltar"
            state="primary"
            block
            outline
            title="Voltar"
            onClick={() => {
              history.goBack();
            }}
          />
        </div>
        <div className="ahl-button">
          {fieldVerification ? (
            <Button
              aria-label="Prosseguir com o empréstimo"
              state="primary"
              block
              title="Prosseguir"
              onClick={() => {
                if (!valueName)
                  tagSimulacaoEmprestimoDadosPessoaisErro(
                    'nome-completo-invalido'
                  );
                if (!valueBirth)
                  tagSimulacaoEmprestimoDadosPessoaisErro(
                    'data-nascimento-invalido'
                  );
                if (!validateEmailFormat(valueEmail))
                  tagSimulacaoEmprestimoDadosPessoaisErro('email-inexistente');
                if (!ValidatedPhone())
                  tagSimulacaoEmprestimoDadosPessoaisErro('telefone-invalido');
              }}
            />
          ) : (
            <Button
              aria-label="Prosseguir com o empréstimo"
              state="primary"
              block
              title="Prosseguir"
              onClick={() => {
                tagSimulacaoEmprestimoDadosPessoais('prosseguir');
                history.push('/emprestimos/nao-correntista/cartao-rchlo');
              }}
              disabled={fieldVerification}
            />
          )}
        </div>
      </S.FooterButton>
    </S.Container>
  );
};

export default NotCartoonistLoan;

import React from 'react';
import info from '~/assets/img/Icones/triangle-info.svg';

import { ImageError, Text, UnavailableInstallmentContainer } from './styles';

export const UnavailableInstallmentView = () => {
  return (
    <UnavailableInstallmentContainer>
      <ImageError src={info} aria-hidden="true" />
      <Text>Parcelamento indisponível no momento.</Text>
    </UnavailableInstallmentContainer>
  );
};

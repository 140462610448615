import axios from 'axios';
import OAuthRentabilizacao from './OAuthRentabilizacao';

const ENV = process.env.REACT_APP_ENV;
const xApiKey = process.env.REACT_APP_RENTABILIZACAO_OAF_API_KEY;
let authToken;

export const instance = axios.create({
  baseURL: process.env.REACT_APP_MIDWAY_URL,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
  async config => {
    if (ENV !== 'dev' && !authToken) {
      const OAuthToken = await OAuthRentabilizacao.getToken();
      authToken = OAuthToken?.IdToken;
    }
    config.headers['x-app-token'] = authToken;
    config.headers['x-api-key'] = xApiKey;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

import React from 'react';
import { isAndroid } from 'react-device-detect';
import { Image } from '@midway/web-ui-component';
import { downloadApp } from '~/constants';
import LogoMidway from '~/assets/img/logo_m_smart.png';
import IconXSmart from '~/assets/img/icons/IconXSmart.png';
import * as S from './styles';

const SmartBanner = params => {
  const handleOpenStoreMidway = () => {
    if (isAndroid) window.open(downloadApp.googlePlayStoreUrl);
    else window.open(downloadApp.appleAppStoreUrl, '_blank');

    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:smartbanner',
      eventAction: 'clique:botao',
      eventLabel: `botao:baixar`,
    });
  };

  return (
    <S.Container>
      <S.CloseButton onClick={params.closeButton}>
        <Image alt="Ícone do botão fechar" src={IconXSmart} />
      </S.CloseButton>
      <S.Logo>
        <Image alt="Imagem do app da Midway" src={LogoMidway} />
      </S.Logo>
      <S.Title>Pague sua fatura pelo app</S.Title>
      <S.Description>
        Aproveite, agora o limite é <br /> liberado em instantes
      </S.Description>
      <S.DownloadButton onClick={handleOpenStoreMidway}>
        <S.DescriptionDownloadButton>Baixar</S.DescriptionDownloadButton>
      </S.DownloadButton>
    </S.Container>
  );
};

export default SmartBanner;

import React from 'react';
import Intro from './Intro';
import HowToParticipate from './HowToParticipate';
import HowToUseYourPoints from './HowToUseYourPoints';
import SaveWithoutUsingPoints from './SaveWithoutUsingPoints';
import GlobalAssistance from './GlobalAssistance';
import DownloadTheApp from './DownloadTheApp';

const MastercardBenefits = () => {
  return (
    <>
      <Intro />
      <HowToParticipate />
      <HowToUseYourPoints />
      <SaveWithoutUsingPoints />
      <GlobalAssistance />
      <DownloadTheApp />
    </>
  );
};

export default MastercardBenefits;

import styled from 'styled-components';

export const StyledButton = styled.button`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 225px;
  min-height: 54px;
  border: 1px solid #000;
  border-radius: 9px;
  font-size: 14px;
  font-family: Montserrat;
  margin-bottom: 16px;

  margin-right: 10px;
  padding: 6px;
  @media (max-width: 1000px) {
  }
`;

export const IconDownload = styled.img`
  max-width: 25px;
  margin-right: 20px;
  color: #616b6a;
`;

export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
  color: #616b6a;
`;

export const storesData = [
  {
    id: 1,
    store: 'L002 - SP SAO BERNARDO CAMPO CT',
    address: 'RUA MARECHAL DEODORO, 919',
    state: 'São Paulo',
    phone: '(11) 2122-6401',
  },
  {
    id: 2,
    store: 'L004 - SP SANTOS CT',
    address: 'RUA AMADOR BUENO, 101',
    state: 'São Paulo',
    phone: '(13) 2101-1200',
  },
  {
    id: 3,
    store: 'L005 - SP OSASCO PLAZA SH',
    address: 'RUA TEN. AVELAR PÍRES DE AZEVEDO, 81',
    state: 'São Paulo',
    phone: '(11) 3336-7700',
  },
  {
    id: 4,
    store: 'L006 - SPA SP MARKET SH',
    address: 'AV DAS NACOES UNIDAS, 22540',
    state: 'São Paulo',
    phone: '(11) 2321-4900',
  },
  {
    id: 5,
    store: 'L007- SP SAO VICENTE BRISAMAR SH',
    address: 'RUA FREI GASPAR, 365',
    state: 'São Paulo',
    phone: '(13) 3325-3700',
  },
  {
    id: 6,
    store: 'L008 - SP OSASCO UNIAO SH',
    address: 'AV AUTONOMISTAS, 1400	',
    state: 'São Paulo',
    phone: '(11) 3927-0400',
  },
  {
    id: 7,
    store: 'L009 - SPA MOOCA PLAZA SH',
    address: 'RUA CAPITAO PACHECO E CHAVES, 313',
    state: 'São Paulo',
    phone: '(11) 3503-6510',
  },
  {
    id: 8,
    store: 'L013 - SP BARUERI PARQUE SH',
    address: 'RUA GENERAL DE DIVISAO DE PEDRO RODRIGUES DA SILVA, 400',
    state: 'São Paulo',
    phone: '(11) 2699-2800',
  },
  {
    id: 9,
    store: 'L016 - SP SANTO ANDRE PLAZA SH',
    address: 'AV INDUSTRIAL, 600',
    state: 'São Paulo',
    phone: '(11) 2173- 4450 ',
  },
  {
    id: 10,
    store: 'L064 - SP SANTOS PRAIAMAR SH',
    address: 'RUA ALEXANDRE MARTINS, 80',
    state: 'São Paulo',
    phone: '(13) 2101-1300',
  },
  {
    id: 11,
    store: 'L097 - SP DIADEMA PRACA DA MOCA SH',
    address: 'RUA MANOEL DA NOBREGA, 712',
    state: 'São Paulo',
    phone: '(11) 3905-6900',
  },
  {
    id: 12,
    store: 'L099 - SP TAMBORE SH',
    address: 'AV PIRACEMA, 669',
    state: 'São Paulo',
    phone: '(11) 3728-8560',
  },
  {
    id: 13,
    store: 'L120 - SPA INTERLAGOS SH',
    address: 'AV INTERLAGOS, 2255',
    state: 'São Paulo',
    phone: '(11) 2122-4400',
  },
  {
    id: 14,
    store: 'L126 - SPA ARICANDUVA SH',
    address: 'AV ARICANDUVA, 5555',
    state: 'São Paulo',
    phone: '(11) 3927-6500',
  },
  {
    id: 15,
    store: 'L128 - SP TABOAO SH',
    address: 'RODO REGIS BITTENCOURT, KM 271,5',
    state: 'São Paulo',
    phone: '(11) 2123-6500',
  },
  {
    id: 16,
    store: 'L187 - SPA VILA OLIMPIA SH',
    address: 'RUA OLIMPIADAS, 360',
    state: 'São Paulo',
    phone: '(11) 2575-1950',
  },
  {
    id: 17,
    store: 'L232 - SPA ITAQUERA',
    address: 'AV JOSE PINHEIRO BORGES, 390',
    state: 'São Paulo',
    phone: '(11) 2281-2200',
  },
  {
    id: 18,
    store: 'L234 - SP COTIA GRANJA VIANNA SH',
    address: 'RODO RAPOSO TAVARES, KM 23',
    state: 'São Paulo',
    phone: '(11) 3728-7300',
  },
  {
    id: 19,
    store: 'L268 - PRAIA GRANDE LITORAL PLAZA SH',
    address: 'AV AYRTON SENNA DA SILVA, 1511',
    state: 'São Paulo',
    phone: '(13) 3346-6761',
  },
  {
    id: 20,
    store: 'L288 - SPA ADOLFO PINHEIRO CT',
    address: 'AV ADOLFO PINHEIRO, 55',
    state: 'São Paulo',
    phone: '(11) 2871-0600',
  },
  {
    id: 21,
    store: 'L366 - SPA MORUMBI SH',
    address: 'AV ROQUE PETRONI JÚNIOR, 1089',
    state: 'São Paulo',
    phone: '(11) 3728-8920',
  },
  {
    id: 22,
    store: 'L392 - SP CARAPICUIBA PLAZA SH 1',
    address: 'ESTR ERNESTINA VIEIRA, 149',
    state: 'São Paulo',
    phone: '(11) 2222-1271',
  },
  {
    id: 23,
    store: 'L033 - GO VALPARAISO SUL SH',
    address: 'QUAD QUADRA 1',
    state: 'Goiás',
    phone: '(61) 2196-8810',
  },
  {
    id: 24,
    store: 'L138 - CE FORTALEZA NORTH SH',
    address: 'AV BEZERRA DE MENEZES, 2450',
    state: 'Ceará',
    phone: '(85) 4011-3100',
  },
  {
    id: 25,
    store: 'L166 - BR PATIO BRASIL SH 1',
    address: 'QUADRA 7',
    state: 'Distrito Federal',
    phone: '(61) 2109-3100',
  },
  {
    id: 26,
    store: 'L294 - PE CAMARAGIBE CAMARA SH',
    address: 'RUA MANOEL HONORATO DA COSTA, 555',
    state: 'Pernambuco',
    phone: '(81) 2127-3050',
  },
];

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 80px;
  width: 100%;
`;

export const Title = styled.p`
  font-family: Montserrat !important;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1f2b2a;
  padding-bottom: 40px;
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  padding-bottom: 24px;
  color: #1f2b2a;
`;

import styled from 'styled-components';
import * as CommonStyles from '../styles';

export const { Text } = CommonStyles;
export const { TooltipText } = CommonStyles;

export const DarkBackground = styled.div`
  width: 100%;
  background-color: #221f20;
`;

export const Title = styled(CommonStyles.Title)`
  color: #fff;
  margin-bottom: 16px;

  @media screen and (min-width: 900px) {
    font-size: 1.5rem !important;
  }
`;

export const TextContainer = styled.div`
  grid-area: txt;

  @media screen and (min-width: 900px) {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 328px;
  height: 391px;
  margin: 48px auto;
  grid-area: image;

  @media screen and (min-width: 1176px) {
    width: 459px;
    height: 547px;
    margin: auto;
  }
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  width: 188px;
  height: 230px;
  top: 140px;
  z-index: 2;

  @media screen and (min-width: 1176px) {
    width: 263px;
    height: 322px;
    top: 188px;
    right: 0;
  }
`;

export const Mockup = styled.img`
  position: absolute;
  width: 168px;
  height: 324px;
  top: 33px;
  right: 115px;
  z-index: 4;

  @media screen and (min-width: 1176px) {
    width: 235px;
    height: 454px;
    top: 46px;
    right: 161px;
  }
`;

export const Video = styled.video`
  position: absolute;
  width: 142px;
  height: 308px;
  top: 44px;
  right: 128px;
  border-radius: 27px 27px;
  z-index: 3;

  @media screen and (min-width: 1176px) {
    width: 200px;
    height: 433px;
    top: 61px;
    right: 178px;
    object-fit: contain;
  }
`;

export const Container = styled(CommonStyles.Container)`
  display: grid;
  grid-template-columns: minmax(min-content, 580px) 1fr;
  grid-template-rows: minmax(0, min-content) minmax(0, auto) minmax(0, 1fr);
  grid-template-areas:
    'txt txt'
    'image image'
    'list list';

  @media screen and (min-width: 900px) {
    grid-template-areas:
      'txt image'
      'list image'
      'list image';
  }

  @media screen and (min-width: 1176px) {
    padding: 56px 0;
  }
`;

export const List = styled.ul`
  all: unset;
  width: 100%;
  max-width: 580px;
  margin: auto;
  grid-area: list;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1176px) {
    margin-bottom: 35px;
  }
`;

export const TooltipWrapper = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Tooltip = styled.div`
  z-index: 10;
  background-color: #3b4443;
  padding: 16px;
  border-radius: 8px;
`;

export const TooltipArrow = styled.div`
  background-color: #3b4443;
  margin-top: -10px;
  margin-bottom: 10px;
  width: 18px;
  height: 18px;
  transform: rotate(45deg);
`;

export const ListItem = styled.li`
  all: unset;
  padding: 16px 16px;
  background-color: #f7f7f7;
  color: #1f2b2a;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    color: #1f2b2a;
    text-decoration: underline;
  }

  svg {
    width: 24px;
    height: 24px;
    stroke: #e4602f;
    stroke-width: 1.5px;
    margin-right: 16px;
    flex: 0 0 24px;

    &:nth-of-type(2) {
      stroke: #000;
      margin-right: 0;
      margin-left: auto;
    }
  }

  @media screen and (min-width: 1176px) {
    padding: 24px 40px;

    svg {
      width: 32px;
      height: 32px;
      flex: 0 0 32px;

      &:nth-of-type(2) {
        height: 24px;
        width: 24px;
        flex: 0 0 24px;
      }
    }
  }
`;

import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import Card from './components/Card';
import ListStep from './components/ListStep';
import Anchor from './components/Anchor';
import RenderBannerImage from './components/RenderBannerImage';
import { banner } from './data';
import cmsService from '~/services/cms/pages';

import ModeloMidwayDesktop from '../../assets/img/Financial/financial-education/modeloMidwayDesktop.png';
import ModeloMidwayMobile from '../../assets/img/Financial/financial-education/modeloMidwayMobile.png';
import ArrowUpTo from '../../assets/img/Financial/financial-education/arrow-up-to.svg';
import ArrowUp from '../../assets/img/Financial/financial-education/arrow-up.svg';
import CheckIcon from '../../assets/img/Financial/financial-education/checkIcon.svg';
import receiptIcon from '../../assets/img/Financial/financial-education/receipt-.svg';
import bankNoteIcon from '../../assets/img/Financial/financial-education/cash-banknote.svg';
import percentageIcon from '../../assets/img/Financial/financial-education/percentage.svg';
import creditCardOfIcon from '../../assets/img/Financial/financial-education/credit-card-off.svg';

const STEP_LIST = [
  {
    value: '1',
    icons: CheckIcon,
    text: 'Administre as parcelas do cartão de crédito',
    description:
      'É fundamental para evitar dívidas excessivas e manter suas finanças saudáveis',
  },
  {
    value: '2',
    icons: receiptIcon,
    text: 'Acompanhe seu limite de crédito',
    description:
      'Através do aplicativo você está sempre atualizado do seu limite',
  },
  {
    value: '3',
    icons: bankNoteIcon,
    text: 'Não gaste além do limite',
    description:
      'Conheça seu limite de crédito disponível e tenha cuidado em gastar somente o que pode ser pago no mês',
  },
  {
    value: '4',
    icons: percentageIcon,
    text: 'Entenda as taxas de juros',
    description:
      'Em casos de compras parceladas, as taxas podem ser altas e isso pode aumentar significativamente o valor final da compra. Considere se vale a pena pagar juros adicionais pela conveniência de parcelar',
  },
  {
    value: '5',
    icons: creditCardOfIcon,
    text: 'Não empreste seu cartão de crédito',
    description:
      'Seu cartão é de uso pessoal e intransferível. Lembre-se que isso pode colocar suas finanças em risco, mesmo que seja para amigos próximos ou familiares',
  },
];

const LendingFinancialEducation = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerFinancialEducation(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banners = bannerData[0] || banner;

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BackgroundContainer>
          <S.BannerContainer>
            <RenderBannerImage {...banners} />
          </S.BannerContainer>
        </S.BackgroundContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitle>
            <S.LendingTitle>
              O dinheiro surge em nossas vidas muito cedo, mas será que sabemos
              como lidar com ele?
            </S.LendingTitle>
            <S.LendingSubTitle>
              Saber mais sobre dinheiro é como ganhar um super poder.
            </S.LendingSubTitle>
            <S.LendingTitleDescription>
              Com conhecimento é possível organizar as finanças pessoais, ter
              uma reserva financeira que garanta possíveis imprevistos,
              construir um futuro mais tranquilo e realizar sonhos.
            </S.LendingTitleDescription>
          </S.AdjustamentTitle>
          <S.CardsGrouping>
            <S.GroupingTop>
              <S.ContentTop>
                <S.CardsTitle>Dica da Midway</S.CardsTitle>
                <S.CardsDescription>
                  Aproveite e aprenda mais sobre educação financeira
                </S.CardsDescription>
              </S.ContentTop>
              <S.BottomContent>
                <S.CardTopLeft>
                  <S.TitleCardTop>
                    Entenda seu momento financeiro
                  </S.TitleCardTop>
                  <S.TitleSubCardTop>
                    Relacione todos os gastos e ganhos fixos que possui
                  </S.TitleSubCardTop>
                  <S.Agroup>
                    <S.ImageCardLeft src={ArrowUpTo} />
                    <S.TextImageCardLeft>
                      Ganhos fixos podem ser salário ou aluguel
                    </S.TextImageCardLeft>
                  </S.Agroup>
                  <S.Agroup>
                    <S.ImageCardLeft src={ArrowUp} />
                    <S.TextImageCardLeft>
                      Gastos fixos podem ser conta de água, luz ou gás
                    </S.TextImageCardLeft>
                  </S.Agroup>
                </S.CardTopLeft>
                <S.CardTopRight>
                  <S.TitleCardTop>Equilibre suas despesas</S.TitleCardTop>
                  <S.TitleSubCardTop>
                    Separe seus ganhos para não precisar se privar de muito{' '}
                  </S.TitleSubCardTop>
                  <S.AgroupCardRight>
                    <S.RectangleCard backgroundColor="#221f20">
                      <S.TextPercentageCard color="#ffffff">
                        50%
                      </S.TextPercentageCard>
                      <S.DescriptionPercentageCard color="#ffffff">
                        Gastos fixos
                      </S.DescriptionPercentageCard>
                    </S.RectangleCard>
                    <S.RectangleCard backgroundColor="#b8c5bd">
                      <S.TextPercentageCard>35%</S.TextPercentageCard>
                      <S.DescriptionPercentageCard>
                        Lazer
                      </S.DescriptionPercentageCard>
                    </S.RectangleCard>
                    <S.RectangleCard backgroundColor="#f1ebdf">
                      <S.TextPercentageCard>15%</S.TextPercentageCard>
                      <S.DescriptionPercentageCard>
                        Investimento
                      </S.DescriptionPercentageCard>
                    </S.RectangleCard>
                  </S.AgroupCardRight>
                </S.CardTopRight>
              </S.BottomContent>
            </S.GroupingTop>
            <S.GroupingBottom>
              <S.ContentTop>
                <S.CardsTitle>Mantenha-se alerta!</S.CardsTitle>
              </S.ContentTop>
              <S.BottomContent>
                <Card
                  title="Desperdício de dinheiro"
                  descriptionOne="Compras por impulso ou de itens que não são necessários. Lembre-se sempre de pensar no uso. "
                  descriptionTwo="Como matricular-se na academia ou contratar a assinatura de tv a cabo. Tenha certeza que utilizará o serviço."
                />
                <Card
                  widthCard="776px"
                  title="Parcelamentos muito extensos"
                  descriptionOne="Pense no valor total da dívida e não somente no valor da parcela. Dívidas longas de itens com baixa durabilidade tendem a complicar o orçamento."
                  descriptionTwo="Um exemplo são produtos eletrônicos que podem se tornar obsoletos num curto período de tempo. Pode acontecer ao decorrer do pagamento que o item não tenha mais utilidade ou precise ser substituído."
                />
              </S.BottomContent>
            </S.GroupingBottom>
          </S.CardsGrouping>
          <S.LeftImagePanelMobile
            src={ModeloMidwayMobile}
          ></S.LeftImagePanelMobile>
        </S.CentralizeContent>
        <S.BackgroundInformationPanel>
          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanelDesktop
                src={ModeloMidwayDesktop}
              ></S.LeftImagePanelDesktop>
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Use o cartão de crédito com responsabilidade
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStep data={STEP_LIST} />
                </S.GroupLeft>
              </S.CentralizeRightPanel>
              <S.RectangleCenter>
                <S.Rectangle>
                  <S.TextMidway>
                    Saiba mais sobre educação financeira acessando <br />
                    <Anchor href="https://meubolsoemdia.com.br/">
                      Meu Bolso em Dia
                    </Anchor>
                  </S.TextMidway>
                </S.Rectangle>
              </S.RectangleCenter>
            </S.RightPanel>
          </S.InformationPanel>
        </S.BackgroundInformationPanel>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default LendingFinancialEducation;

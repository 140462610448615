import React, { useEffect } from 'react';
import * as S from './styles';
import Breadcrumb from './components/Breadcrumb/index';
import Stage from './FormSimulate/FormSteps';

const SimulateLoan = () => {
  useEffect(() => {
    window.location.href = '/';

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      () => {}
    );
  }, []);

  return (
    <S.Container className="container-fluid">
      <Breadcrumb />
      <Stage />
    </S.Container>
  );
};

export default SimulateLoan;

import React, { useCallback } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import {
  IconCreditCard,
  IconInfoCircle,
  IconListDetails,
  IconPointer,
  IconTicket,
} from '@tabler/icons-react';
import HowToParticipateImg from '~/assets/img/CardBenefits/how-to-participate-card.png';
import VideoSrc from '~/assets/img/CardBenefits/how-to-participate.webm';
import MockupImg from '~/assets/img/CardBenefits/mastercard-mockup.png';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const HowToParticipate = () => {
  const addCreditCardTooltip = useCallback(
    () => (
      <S.TooltipWrapper id="AddCreditCardTooltip">
        <S.Tooltip>
          <S.TooltipText bold white>
            Não solicitamos CVV do cartão
          </S.TooltipText>
          <S.TooltipText white>Isso garante sua segurança</S.TooltipText>
        </S.Tooltip>
        <S.TooltipArrow />
      </S.TooltipWrapper>
    ),
    []
  );

  return (
    <S.DarkBackground>
      <S.Container>
        <S.TextContainer>
          <S.Title>Como participar?</S.Title>
          <S.Text white>É simples e você já começa a acumular pontos!</S.Text>
        </S.TextContainer>
        <S.ImageContainer>
          <S.Image src={HowToParticipateImg} role="presentation" alt="" />
          <S.Mockup src={MockupImg} role="presentation" alt="" />
          <S.Video loop autoPlay muted>
            <source src={VideoSrc} type="video/webm" />
          </S.Video>
        </S.ImageContainer>
        <S.List>
          <S.ListItem>
            <IconPointer />
            <S.Text>
              <a
                href=""
                onClick={e => {
                  e.preventDefault();
                  const divElement = document.getElementById(
                    'download-surpreenda'
                  );
                  divElement.scrollIntoView({ behavior: 'smooth' });
                  trackingSelectContent('baixe-o-aplicativo', 'beneficios');
                }}
              >
                Baixe o aplicativo
              </a>{' '}
              ou{' '}
              <a
                href="https://surpreenda.naotempreco.com.br/como-funciona/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  trackingSelectContent('acesse-o-site', 'beneficios')
                }
              >
                acesse o site
              </a>{' '}
              do Surpreenda
            </S.Text>
          </S.ListItem>
          <S.ListItem>
            <IconListDetails />
            <S.Text>Faça o cadastro preenchendo os dados solicitados</S.Text>
          </S.ListItem>
          <S.ListItem>
            <IconCreditCard />
            <S.Text>Adicione o seu Cartão Riachuelo Mastercard</S.Text>
            <OverlayTrigger overlay={addCreditCardTooltip()}>
              <IconInfoCircle
                onMouseEnter={() =>
                  trackingSelectContent(
                    'informacoes:adicione-o-seu-cartao-riachuelo-mastercard',
                    'beneficios'
                  )
                }
              />
            </OverlayTrigger>
          </S.ListItem>
          <S.ListItem>
            <IconTicket />
            <S.Text>Pronto! Suas compras já começam acumular pontos</S.Text>
          </S.ListItem>
        </S.List>
      </S.Container>
    </S.DarkBackground>
  );
};

export default HowToParticipate;

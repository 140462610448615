import React, { memo } from 'react';
import { Icon } from '@midway/web-ui-component';
import { toast } from 'react-toastify';

import error from '~/assets/img/Icones/error.svg';
import Calendar from '~/assets/img/Financial/BilletInvoiceOrLendingV2/calendar.svg';
import Cash from '~/assets/img/Financial/BilletInvoiceOrLendingV2/cash.svg';
import CircleInfo from '~/assets/img/Financial/BilletInvoiceOrLendingV2/info-circle.svg';

import { FLAGS } from '~/constants/cards';
import { Button } from '~/components/Button';
import format from '~/services/format';

import * as Styles from './styles';

const InvoiceBankSlipItem = ({
  dueDate,
  id,
  flag,
  success,
  value,
  maskedPan,
  onClick,
  onError,
}) => {
  const handleOnClick = () => onClick(id);

  const handleEventInfo = event => {
    event.stopPropagation();
    toast(
      'O valor total a pagar no boleto poderá vir com acréscimo de juros e multa em caso de atraso.'
    );
  };

  const handleTryAgainButton = () => onError && onError();

  const title = `Cartão ${FLAGS[flag]} - Final ${maskedPan.substr(
    maskedPan.length - 4,
    maskedPan.length
  )}`;

  const ButtonInfo = () => {
    return (
      <Styles.InfoButton onClick={handleEventInfo}>
        <Styles.Image src={CircleInfo} />
      </Styles.InfoButton>
    );
  };

  return (
    <Styles.Container>
      {success ? (
        <Styles.TopContainer
          onClick={handleOnClick}
          data-testid="invoice-bank-slip-item"
        >
          <div>
            <Styles.Title>{title}</Styles.Title>

            <Styles.ContainerLine>
              <Styles.Image src={Calendar} />
              <Styles.PointLabel>Data de vencimento:</Styles.PointLabel>
              <Styles.PointValue>{dueDate}</Styles.PointValue>
            </Styles.ContainerLine>

            {value && (
              <Styles.ContainerLine>
                <Styles.Image src={Cash} />
                <Styles.PointLabel>Valor:</Styles.PointLabel>
                <Styles.PointValue>{format.currency(value)}</Styles.PointValue>
                <ButtonInfo />
              </Styles.ContainerLine>
            )}
          </div>
          <Icon icon="chevron-right" size="lg" />
        </Styles.TopContainer>
      ) : (
        <Styles.ContainerTryAgain>
          <Styles.ContainerLine>
            <Styles.Image src={error} aria-hidden="true" />
            <Styles.TitleError>{title}</Styles.TitleError>
          </Styles.ContainerLine>
          <Styles.TitleErrorDescription>
            Infelizmente não conseguimos pegar os dados dessa fatura no momento,
            tente novamente mais tarde.
          </Styles.TitleErrorDescription>
          <Button
            aria-label="Tentar novamente"
            state="secondary"
            onClick={handleTryAgainButton}
          >
            TENTAR NOVAMENTE
          </Button>
        </Styles.ContainerTryAgain>
      )}
    </Styles.Container>
  );
};

export default memo(InvoiceBankSlipItem);

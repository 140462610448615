import {
  WizardSubtitle,
  WizardText,
  WizardTitleContainer,
  highlightedStyle,
} from './styles';

const WizardTitle = ({ subtitle, title, highlighted, ...props }) => (
  <WizardTitleContainer {...props}>
    {subtitle && <WizardSubtitle>{subtitle}</WizardSubtitle>}
    <WizardText>
      {title?.split(' ').map((word, index) => {
        if (highlighted && highlighted.includes(word)) {
          return (
            <span key={index} style={highlightedStyle}>
              {word}{' '}
            </span>
          );
        }
        return <span key={index}>{word} </span>;
      })}
    </WizardText>
  </WizardTitleContainer>
);

export default WizardTitle;

import React from 'react';
import * as S from './styles';

const StepNumber = ({ value, color, size }) => {
  return (
    <S.Container color={color} size={size}>
      <S.Text color={color}>{value}</S.Text>
    </S.Container>
  );
};

export default React.memo(StepNumber);

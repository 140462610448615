import WrapperBase from '../../components/Wrapper';
import { breakpoints } from '../../utils';
import { colors } from '~/constants';
import styled from 'styled-components';

export const CardsInvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const ShortcutsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(99px, 1fr));
  gap: 0.5rem;
`;

export const MainQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MainQuestionsAccordionsContainer = styled(MainQuestionsContainer)`
  margin-bottom: 2rem;

  @media (${breakpoints[1176]}) {
    margin-bottom: 0;
  }
`;

export const MainQuestionsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 1.5rem;
  }
`;

export const MainQuestionIcon = styled.div`
  min-width: 1.5rem;
  width: 1.5rem;
  min-height: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: ${colors.chumbo};
  color: ${colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 0.875rem;
    height: 0.875rem;
  }
`;

export const MainQuestionsTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #221f20;
`;

export const MainQuestionsAccordionTitle = styled.strong`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #221f20;
`;

export const IconCircle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid #221f20;
  background-color: #fff;
  color: #221f20;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperContacts = styled(WrapperBase)`
  background: ${colors.white};
  padding-bottom: 5rem;

  @media (${breakpoints[1176]}) {
    background: none;
    padding-top: 0;
  }
`;

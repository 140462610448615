import React, { useEffect, useState } from 'react';
import { Button, Image } from '@midway/web-ui-component';
import { useDispatch } from 'react-redux';
import AppMidway from '~/assets/img/Financial/lending/apps-midway.svg';
import CircleChecked from '~/assets/img/Financial/lending/circle-check.svg';
import { showCallToAction } from '~/store/modules/calltoaction/actions';
import QrCode from '../components/QRcode';

import * as S from './styles';

const NotAvailableLoan = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 712);
  const dispatch = useDispatch();

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleShowModal = () => {
    if (isMobile) {
      window.open('http://onelink.to/ngrm9z', '_blank');
    } else {
      dispatch(showCallToAction(true));
    }
  };

  return (
    <>
      <QrCode />
      <S.Forms>
        <div>
          <h1>No momento, não temos uma oferta para você</h1>
          <h2>
            Estamos sempre evoluindo a nossa análise de crédito e em breve, você
            poderá fazer uma nova solicitação.
          </h2>
          <h2>
            <span>Importante:</span> para ter limite você precisa ter um cartão
            de crédito Riachuelo ou conta digital da Midway.
          </h2>
          <S.WrapperContent>
            <div>
              <h2 className="midway-advantages">
                Aproveite todas as vantagens de ser um cliente Midway!
              </h2>
              <ul>
                <li>
                  <Image
                    src={CircleChecked}
                    className="CircleChecked"
                    alt="CircleChecked"
                  />{' '}
                  Conta 100% gratuita
                </li>
                <li>
                  <Image
                    src={CircleChecked}
                    className="CircleChecked"
                    alt="CircleChecked"
                  />
                  Sorteio de R$ 10 mil todo mês
                </li>
                <li>
                  <Image
                    src={CircleChecked}
                    className="CircleChecked"
                    alt="CircleChecked"
                  />
                  Acesso a empréstimo facilitado e direto na conta
                </li>
                <li>
                  <Image
                    src={CircleChecked}
                    className="CircleChecked"
                    alt="CircleChecked"
                  />
                  Limite de até R$ 15 mil e até 90 dias para começar a pagar
                </li>
                <li>
                  <Image
                    src={CircleChecked}
                    className="CircleChecked"
                    alt="CircleChecked"
                  />
                  Gerencie o empréstimo pelo aplicativo
                </li>
              </ul>

              <Button
                aria-label="Baixe agora"
                state="primary"
                title="Baixe agora"
                onClick={handleShowModal}
              />
            </div>
            <Image src={AppMidway} className="AppMidway" alt="AppMidway" />
          </S.WrapperContent>
        </div>
      </S.Forms>
    </>
  );
};

export default NotAvailableLoan;

import styled from 'styled-components';
import * as CommonStyles from '../styles';

export const Container = styled(CommonStyles.Container)`
  display: grid;
  grid-template-columns: minmax(min-content, 679px) 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'txt txt'
    'image image'
    'list list';

  @media screen and (min-width: 900px) {
    grid-template-areas:
      'txt image'
      'list image'
      'list image';
  }
`;

export const ImageContainer = styled.div`
  width: 328px;
  height: 349px;
  margin: 56px auto;
  position: relative;
  grid-area: image;

  @media screen and (min-width: 1176px) {
    width: 427px;
    height: 454px;
    margin: 23px auto;
  }
`;

export const Ilustration = styled.img`
  width: 231px;
  height: 245px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;

  @media screen and (min-width: 1176px) {
    width: 300px;
    height: 319px;
  }
`;

export const Mockup = styled.img`
  width: 181px;
  height: 348px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;

  @media screen and (min-width: 1176px) {
    width: 236px;
    height: 454px;
  }
`;

export const Video = styled.video`
  width: 160px;
  height: 310px;
  position: absolute;
  bottom: 2px;
  right: 11px;
  z-index: 4;
  border-radius: 0 0 30px 30px;

  @media screen and (min-width: 1176px) {
    width: 209px;
    height: 407px;
    bottom: 3px;
    right: 13px;
  }
`;

export const HowToUseTitle = styled(CommonStyles.Title)`
  grid-area: txt;

  @media screen and (min-width: 900px) {
    margin-bottom: 56px;
  }

  @media screen and (min-width: 1176px) {
    font-size: 1.5rem;
  }
`;

export const ListContainer = styled.div`
  grid-area: list;
`;

export const List = styled.ol`
  all: unset;
  display: flex;
  gap: 40px;
  flex-direction: column;
  margin-bottom: 56px;
`;

export const Counter = styled.span`
  flex: 0 0 auto;
  background-color: #221f20;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  color: white;
  z-index: 11;

  svg {
    stroke: #fff;
    stroke-width: 1.5px;
    width: 24px;
    height: 24px;
  }
`;

export const VerticalLine = styled.hr`
  position: absolute;
  display: block;
  bottom: 40px;
  left: 19px;
  width: 2px;
  height: 60px;
  background-color: #221f20;
  margin: auto;
  z-index: 1;
`;

export const ListItem = styled.li`
  all: unset;
  color: #1f2b2a;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &:last-of-type {
    ${Counter} {
      background-color: #e4602f;
    }

    ${VerticalLine} {
      background-color: #e4602f;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  background: #f7f7f7;
  border-radius: 8px;

  svg {
    stroke: #e4602f;
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
  }
`;

export const InfoTextContainer = styled.div``;

export const InfoText = styled(CommonStyles.Text)`
  margin-bottom: 8px !important;
`;

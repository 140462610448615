import styled from 'styled-components';
import { Button } from '@midway/web-ui-component';

export const BodyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 6px 14px -4px rgb(148 148 148 / 49%);
  border-radius: 16px;
`;

export const CardContent = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  flex-grow: 1;
`;

export const CardAction = styled.div`
  margin-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const TitleCard = styled.p`
  font-size: 24px !important;
  margin-top: 24px;
  text-align: left;
  font-weight: 700;
  color: #3b4443;
`;
export const SubtitleCard = styled.p`
  margin-bottom: 10px !important;
  font-size: 16px !important;
  text-align: left;
  font-weight: 500;
  color: #3b4443;

  &::after {
    content: '';
    display: block;
    width: 37px;
    height: 3px;
    background-color: #a06d2c;
    margin-top: 10px;
    margin-bottom: 24px;
  }
`;

export const DescriptionCard = styled.div`
  line-clamp: 8;
  flex: 1;
  font-size: 15px;
  color: #3b4443;
  text-align: left;
  min-height: ${props =>
    props.contentHeight ? `${props.contentHeight}px` : 'inherit'};
  margin: 0 auto;
  p {
    font-weight: 500;
    color: #3b4443;
  }
  p:last-child {
    margin-top: 16px;
  }
`;

export const CardImageContainer = styled.div`
  height: 226px;
  width: 100%;
`;

export const CardImageIconContainer = styled.div`
  height: 32px;
  width: 100%;
  padding: 32px 20px;
`;
export const CardImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const CardImageIcon = styled.img`
  height: 32px;
  width: 32px;
`;

export const ActionButton = styled(Button)`
  width: 100%;
  padding: 16px;
  border-radius: 50px;
  font-family: Montserrat;
  font-size: 14px !important;
  font-weight: 500;
  background-color: #000;
  border: 0px;
  transition: backgroud-color 0.5s ease;
  cursor: pointer;
  color: #fff !important;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover {
    background-color: #222;
  }
`;

import React, { useState, useRef } from 'react';
import { Icon } from '@midway/web-ui-component';
import { List, IconChevron, ButtonWrapper } from './style';

const Faq = props => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setActiveTitleColor, setActiveTitleColorState] = useState('');

  const content = useRef(null);

  function toggleFaq() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`
    );
    setActiveTitleColorState(
      setActiveTitleColor === '' ? 'faq-title-active' : ''
    );
  }

  return (
    <List>
      <button className={`faq ${setActive}`} onClick={toggleFaq} type="button">
        <div
          className={`faq-title ${setActiveTitleColor}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
        <ButtonWrapper>
          <IconChevron>
            <Icon
              icon={['fa', 'chevron-down']}
              rotate180={setActive === 'active'}
              color="#00726d"
              size="2x"
            />
          </IconChevron>
        </ButtonWrapper>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="faq-content"
      >
        {props.content}
      </div>
    </List>
  );
};

export default Faq;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { instance } from '~/services/cms/cmsApiInstance';

const getDataByPermalink = async (
  permalink,
  uri = 'https://api-dc-rchlo-prd.riachuelo.com.br/ciano-pages'
) => {
  const baseUri = `${uri}/v1/find/${permalink}?Environment=1`;

  return instance.get(baseUri);
};

export const Page = styled.div`
  background-color: #fff;
  flex: 1;
  display: flex;
  min-height: 600px;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 1200px;
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
`;

export const Input = styled.input`
  margin-top: 10px;
  width: 100%;
`;

const TestCms = () => {
  const inputRef = useRef(null);
  const tokenRef = useRef(null);

  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseUri, setBaseUri] = useState(
    'https://api-dc-rchlo-prd.riachuelo.com.br/ciano-pages/'
  );
  const [error, setError] = useState(false);
  const [data, setData] = useState({});

  const handleLogin = () => {
    const token = tokenRef.current.value;
    console.log(token);
    if (token === '@Riachu2024') {
      setLogged(true);
    }
  };

  const handleFetch = async () => {
    const permalink = inputRef.current.value;

    try {
      setLoading(true);
      setError(false);
      const response = await getDataByPermalink(permalink, baseUri || null);
      setData(response);
      setLoading(false);
      setError(false);
    } catch (e) {
      console.log('Erro ao buscar', e);
      setError(error);
    } finally {
      setLoading(false);
      setError(false);
    }
  };

  if (!logged) {
    return (
      <Page>
        <Container>
          <div>
            <Input placeholder="Token" name="token" ref={tokenRef} />
          </div>
          <button type="button" onClick={handleLogin}>
            Logar
          </button>
        </Container>
      </Page>
    );
  }

  return (
    <Page>
      <Container>
        {loading && <div>Carregando...</div>}
        {error && <div>Erro ao buscar</div>}
        <div>
          <Input
            name="base"
            value={baseUri}
            onChange={evt => setBaseUri(evt.target.value)}
          />
        </div>
        <div>
          <Input placeholder="Permalink" name="permalink" ref={inputRef} />
          <button type="button" onClick={handleFetch}>
            Buscar
          </button>
        </div>
        <div>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      </Container>
    </Page>
  );
};

export default TestCms;

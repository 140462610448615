import styled from 'styled-components';

export const Forms = styled.div`
  height: 340px;
  align-self: baseline;

  @media (max-width: 768px) {
    height: fit-content;
  }

  h1 {
    font-weight: bold;
    color: #000000;
    font-size: 32px !important;

    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }

  h2 {
    font-size: 18px !important;
    color: #606060;
    padding: 10px 0px 0px 0px;
  }

  p {
    color: #908d8d;
    padding-top: 10px;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 30px;

  input {
    border: 0;
    border-bottom: 1px solid #606060;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
  }

  label {
    top: 0;
    left: 0;
    right: 0;
    color: #606060;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 1rem;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
  }

  input,
  label {
    width: 60%;
    height: 3rem;
    font-size: 1rem;
  }

  input:valid + label,
  input:focus + label {
    font-size: 14px;
    top: -30px;
    color: #606060;
  }
`;

export const WrapperSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

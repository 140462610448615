import produce from 'immer';

const INITIAL_STATE = {
  userInfo: {},
  limitInfo: {},
};

export default function loan(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@loan/SAVE_LIMIT_INFO': {
        draft.limitInfo = action.payload;
        break;
      }
      case '@loan/SAVE_USER_INFO': {
        draft.userInfo = action.payload;
        break;
      }
      default:
    }
  });
}

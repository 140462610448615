export const mockData = [
  {
    name: 'BannerCartoes',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/cartaoMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/cartaoWeb.png',
    title: 'Link Banner Cartões',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/cartoes',
  },
  {
    name: 'BannerEmprestimos',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/emprestimoMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/emprestimosWeb.png',
    title: 'Link Banner de Empréstimos',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/emprestimos',
  },
  {
    name: 'BannerSegurosAssistencias',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/saMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/saWeb.png',
    title: 'Link Banner Seguros e Assistências',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/seguros',
  },
  {
    name: 'BannerContas',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/contaDigitalMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/contaDigitalWeb.png',
    title: 'Link Banner de Contas',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: '',
  },
];

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import { banner } from './data';
import RenderBannerImage from '~components/RenderBannerImage';
import CardFinancialAssistance from '~/components/CardFinancialAssistance';
import TextPhoneNumber from '~/components/TextPhoneNumber';
import DownloadStore from '~/components/DownloadStore';
import ListStepNumber from '~/components/ListStepNumber';

import SOSIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-sos.svg';
import ReboqueIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-reboque.svg';
import ChaveIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-chave.svg';
import DomicilioIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-domicilio.svg';
import EstradaIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-estrada.svg';
import PneuIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-pneu.svg';
import CombustivelIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-combustivel.svg';
import DespachanteIcon from '~/assets/img/Financial/assistance-automovel-24h/icon-despachante.svg';
import CDFLogo from '~/assets/img/Financial/logo-assistance/cdf.svg';
import Iphone from '~/assets/img/Financial/assistance-automovel-24h/iphone.svg';
import PlayStore from '~/assets/img/lending/play-store.svg';
import AppleStore from '~/assets/img/lending/apple-store.svg';
import { trackingSelectContent } from '~/analytics';

const PHONE_CAPITALS_AND_REGIONS = '3004 5417';
const PHONE_OTHER_LOCATIONS = '0800 727 4417';
const PHONE_SUPPORT = '0800 704 5025';
const CNPJ = '52.570.231/0001-34.';
const FLOW = 'assistencia-automovel-24h';

const STEP_LIST = [
  {
    value: '1',
    text: 'Baixe o nosso aplicativo Midway;',
  },
  {
    value: '2',
    text: 'Encontre a opção Serviços e procure por Assistências;',
  },
  {
    value: '3',
    text: 'Encontre a Assistência Automóvel 24h;',
  },
  {
    value: '4',
    text: 'Selecione o plano e a forma de pagamento;',
  },
  {
    value: '5',
    text: 'Confira o resumo, aceite os termos e finalize a contratação.',
  },
];

const AssistanceCar24h = () => {
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerAssistanceAutomovel24h(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banners = bannerData[0] || banner;

  const handleClickSeeGeneralConditionsSuper = () => {
    trackingSelectContent(
      'consulte-nossos-termos-e-condicoes-gerais-24h-automovel-super',
      FLOW
    );
  };

  const handleClickSeeGeneralConditionsMega = () => {
    trackingSelectContent(
      'consulte-as-condicoes-gerais-da-assistencia-24h-automovel-mega',
      FLOW
    );
  };

  const handleClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
      '_blank'
    );
    trackingSelectContent('download:google-play', FLOW);
  };

  const handleClickAppleStore = () => {
    window.open(
      'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
      '_blank'
    );
    trackingSelectContent('download:apple-store', FLOW);
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Assistência Auto</S.TitleCards>
            <S.subTitleCards>
              Tenha acesso aos principais benefícios para o seu automóvel!{' '}
            </S.subTitleCards>
          </S.AdjustamentTitleCard>
          <S.ContainerCards>
            <CardFinancialAssistance
              icon={SOSIcon}
              title="Socorro elétrico e mecânico"
              description="Em caso de pane de seu veículo, será enviado o socorro para, a princípio, realizar o conserto no próprio local."
            />
            <CardFinancialAssistance
              icon={ReboqueIcon}
              title="Reboque"
              description="Em caso de imobilização total do veículo, será disponibilizado o serviço de Reboque até a oficina mais próxima."
            />
            <CardFinancialAssistance
              icon={ChaveIcon}
              title="Chaveiro"
              description="Cobertura em caso de perda, roubo, furto, quebra de chave na fechadura e/ou miolo de contato."
            />
            <CardFinancialAssistance
              icon={DomicilioIcon}
              title="Retorno a domicílio"
              description="Em caso imobilização de seu veículo para reparo, por mais de 48 horas, garantimos seu retorno ao domicílio. "
            />
            <CardFinancialAssistance
              icon={EstradaIcon}
              title="Transporte para retirada do veículo"
              description="Serviço para que você retire o seu veículo do conserto, quando utilizado o retorno a domicílio."
            />
            <CardFinancialAssistance
              icon={PneuIcon}
              title="Substituição de pneu furado"
              description="Um prestador será enviado para a troca de pneu devido a imobilização do veículo."
            />
            <CardFinancialAssistance
              icon={CombustivelIcon}
              title="Auxílio na falta de combustível"
              description="Em caso de imobilização de seu veículo por falta de combustível, enviaremos um reboque."
            />
            <CardFinancialAssistance
              icon={DespachanteIcon}
              title="Despachante"
              description="Orientações ilimitadas para caso você possua algum problema com seu veículo."
            />
          </S.ContainerCards>
          <S.AdditionalInfoCard>
            <S.AdditionalInfoText>
              Esses e mais diversos serviços, como:{' '}
              <S.AdditionalText>
                Táxi; Motorista seguro; Carro reserva; Remoção inter-hospitalar;
                Hospedagem em hotel; Acompanhante em caso de hospitalização;
                Prolongamento da estada em hotel; Transmissão de mensagens
                urgentes.
              </S.AdditionalText>
            </S.AdditionalInfoText>
            <S.CardWrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://midway.com.br/static/documents/tags/condicoes-particulares/condicoes-gerais-assistencia-veiculo-pl-super-c-v2.pdf"
                  onClick={handleClickSeeGeneralConditionsSuper}
                  target="_blank"
                >
                  Consulte as condições gerais da Assistência 24h Automóvel
                  Super
                </S.CardWrapperUnderline>
                Comercializado a partir de 09 de agosto de 2019{' '}
              </S.WrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://midway.com.br/static/documents/tags/condicoes-particulares/condicoes-gerais-assistencia-veiculo-pl-mega-c-v2.pdf"
                  onClick={handleClickSeeGeneralConditionsMega}
                  target="_blank"
                >
                  Consulte as condições gerais da Assistência 24h Automóvel Mega
                </S.CardWrapperUnderline>
                Comercializado a partir de 09 de agosto de 2019{' '}
              </S.WrapperText>
            </S.CardWrapperText>
          </S.AdditionalInfoCard>
          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanel src={Iphone} />
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Para contratar a Assistência Auto 24h é simples!{' '}
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStepNumber data={STEP_LIST} />
                </S.GroupLeft>
                <S.GroupColumn>
                  <S.TextMidway>Baixe o aplicativo Midway</S.TextMidway>
                  <S.Agroup>
                    <DownloadStore
                      iconSrc={PlayStore}
                      onClick={handleClickPlayStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Google Play</S.TextBold>
                    </DownloadStore>
                    <DownloadStore
                      iconSrc={AppleStore}
                      onClick={handleClickAppleStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                    </DownloadStore>
                  </S.Agroup>
                </S.GroupColumn>
              </S.CentralizeRightPanel>
            </S.RightPanel>
          </S.InformationPanel>
          <S.ContentText>
            <S.TextContent>
              Você também pode contratar nas Lojas Riachuelo mais próximas ou,
              se preferir, pode optar pelas centrais de atendimento através dos
              telefones:
            </S.TextContent>
            <TextPhoneNumber
              text="Capitais e regiões metropolitanas:"
              phoneNumber={PHONE_CAPITALS_AND_REGIONS}
              trackingId={PHONE_CAPITALS_AND_REGIONS}
              trackingFlow={FLOW}
            />
            <TextPhoneNumber
              text="Demais localidades:"
              phoneNumber={PHONE_OTHER_LOCATIONS}
              trackingId={PHONE_OTHER_LOCATIONS}
              trackingFlow={FLOW}
            />
          </S.ContentText>
          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar a Assistência{' '}
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText>
              Fale com a CDF através do telefone:{' '}
            </S.CardBlackBottomText>
            <S.CardBlackBottomText>
              <S.UnderlineText>{PHONE_SUPPORT}</S.UnderlineText>
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>
          <S.ContentZurich>
            <S.ZurichLeft src={CDFLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                A CDF é uma plataforma de serviços que oferece soluções para
                consumidores finais por meio de parceria com Midway. Provendo
                uma experiência digital para facilitar o acesso aos serviços
                disponíveis, além de garantir o acompanhamento de toda jornada
                do consumidor.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>
          <S.ContentFinal>
            <S.ContentTextFinal>
              A Assistência é prestada pela CDF Assistências Ltda inscrita no
              CNPJ: {CNPJ}
            </S.ContentTextFinal>
            <S.ContentTextFinal>
              Restrições se aplicam. Mais informações consulte as condições
              gerais, o regulamento e as características essenciais. Vigência
              inicia-se a partir do dia seguinte a realização do pagamento.
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default AssistanceCar24h;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 120px;
`;

export const StepNumberContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export const ParagraphSection = styled.p`
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 600;
  color: black;
  line-height: 28px;
  color: #fff;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    margin-top: 30px;
    padding-bottom: 30px;
  }
`;
export const VerticalLine = styled.div`
  width: 3px;
  flex-grow: 1;
  background: #fff;

  ${({ visible }) =>
    visible &&
    `
    background: #242424;
  `}

  @media (max-width: 1000px) {
    width: 3px;
  }
`;

import React from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import Points1Img from '~/assets/img/CardBenefits/points1.svg';
import ArrowSvg from '~/assets/img/CardBenefits/seta.svg';
import Points2Img from '~/assets/img/CardBenefits/points2.svg';
import Points3Img from '~/assets/img/CardBenefits/points3.svg';
import * as S from './styles';

const Intro = () => {
  return (
    <S.Container>
      <S.Title>Programa Mastercard Surpreenda</S.Title>
      <S.Text>
        Com seu <b>Cartão Riachuelo Mastercard</b>, você aproveita todas as
        vantagens do programa de benefícios da Mastercard.
      </S.Text>
      <S.Text>
        Quanto mais pontos acumular, mais chances você tem de aproveitar os
        benefícios e <b>trocar por ofertas em diversas marcas!</b>
      </S.Text>
      <S.PointsContainer>
        <S.PointsContainerItem>
          <S.PointsImage src={Points1Img} role="presentation" alt="" />
          <S.PointsText>
            Em cada compra <br />
            realizada no cartão
          </S.PointsText>
        </S.PointsContainerItem>
        <S.PointsArrowWrapper>
          <S.PointsArrow src={ArrowSvg} role="presentation" alt="" first />
        </S.PointsArrowWrapper>
        <S.PointsContainerItem>
          <S.PointsImage src={Points2Img} role="presentation" alt="" />
          <S.PointsText>
            Você ganha <br />1 ponto
          </S.PointsText>
        </S.PointsContainerItem>
        <S.PointsArrowWrapper>
          <S.PointsArrow src={ArrowSvg} role="presentation" alt="" />
        </S.PointsArrowWrapper>
        <S.PointsContainerItem>
          <S.PointsImage src={Points3Img} role="presentation" alt="" />
          <S.PointsText>
            A partir de 10 pontos, <br />
            você já pode trocar!
          </S.PointsText>
        </S.PointsContainerItem>
      </S.PointsContainer>
      <S.PointsInfoContainer>
        <S.PointsInfoIconWrapper>
          <IconInfoCircle />
        </S.PointsInfoIconWrapper>
        <S.Text>
          Os pontos começam a acumular somente após o cadastro no programa
        </S.Text>
      </S.PointsInfoContainer>
    </S.Container>
  );
};

export default Intro;

import React from 'react';
import { Modal } from 'react-bootstrap';
import IconRchlo from '~/assets/img/logo_rchlo.png';
import * as S from './styles';

const ModalComponent = ({
  handleModal,
  show,
  storeModal,
  addressModal,
  stateModal,
  phoneStoreModal,
}) => {
  const phones = phoneStoreModal?.split('/');
  let phone1;
  let phone2;
  let telFormat1;
  let telFormat2;

  if (phones?.length === 1) {
    phone1 = phones[0]?.replace(/[^0-9]/g, '');
    telFormat1 = `tel:+55${phone1}`;
  } else if (phones?.length === 2) {
    phone1 = phones[0]?.replace(/[^0-9]/g, '');
    phone2 = phones[1]?.replace(/[^0-9]/g, '');
    telFormat1 = `tel:+55${phone1}`;
    telFormat2 = `tel:+55${phone2}`;
  }

  return (
    <Modal show={show} onHide={handleModal}>
      <S.HeaderModal>
        <img alt="icone Rchlo" src={IconRchlo} />
      </S.HeaderModal>
      <S.ContentModal>
        <S.TextContentModal>
          <S.TextBold>LOJA</S.TextBold>
          <S.Description>{storeModal}</S.Description>
        </S.TextContentModal>
        <S.TextContentModal>
          <S.TextBold>ENDEREÇO</S.TextBold>
          <S.Description>{addressModal}</S.Description>
        </S.TextContentModal>
        <S.TextContentModal>
          <S.TextBold>ESTADO</S.TextBold>
          <S.Description>{stateModal}</S.Description>
        </S.TextContentModal>
        <S.TextContentModal>
          {phoneStoreModal !== '-' && <S.TextBold>TELEFONE</S.TextBold>}
          {phones?.length > 0 && phoneStoreModal !== '-' && (
            <div>
              <S.OrangeButton href={telFormat1}>{phones[0]}</S.OrangeButton>
              <br />
              <S.OrangeButton href={telFormat2}>{phones[1]}</S.OrangeButton>
            </div>
          )}
        </S.TextContentModal>
      </S.ContentModal>
      <S.FooterModal>
        <S.BackButton data-dismiss="modal" onClick={handleModal}>
          Voltar
        </S.BackButton>
      </S.FooterModal>
      <style>{S.css}</style>
    </Modal>
  );
};

export default ModalComponent;

import { Image } from '@midway/web-ui-component';
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import closeButtom from '~/assets/img/new-home/close-button.svg';
import qrcode from '~/assets/img/AppDifference/qrcode_app_riachuelo.svg';
import { showCallToActionRiachuelo } from '~/store/modules/calltoactionriachuelo/actions';

import * as S from './styles';

const CallToActionRiachuelo = () => {
  const dispatch = useDispatch();
  const { showModal } = useSelector(state => state.calltoactionriachuelo);

  const handleCloseModal = e => {
    e.preventDefault();
    dispatch(showCallToActionRiachuelo(false));
  };
  return (
    showModal && (
      <S.Modal>
        <div id="background" />
        <div className="content">
          <a href="#" onClick={handleCloseModal}>
            <Image
              src={closeButtom}
              className="closeButtom"
              alt="Botão de fechar"
            />
          </a>
          <h2>Continue acessando seus serviços financeiros</h2>
          <p>
            Posicione a câmera do celular no QR Code abaixo.
            <p>Agora está fácil!</p>
          </p>
          <p>
            Você vai ser redirecionado para a loja de aplicativos e já consegue
            baixar o <S.Bold>app Riachuelo.</S.Bold>
          </p>

          <Image src={qrcode} className="qrcode" alt="qrCode" />
        </div>
      </S.Modal>
    )
  );
};

export default CallToActionRiachuelo;

import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  align-items: center;
  gap: 30px;
  padding: 1em;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ContentWrapper = styled.div`
  flex: 1;
`;
export const Title = styled.h2`
  font-size: 3.2em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 2.2rem !important;
    margin-bottom: 1em;
  }

  @media (max-width: 492px) {
    font-size: 1.2rem !important;
    margin-bottom: 1em;
  }
`;
export const Content = styled.p`
  font-size: 1.72rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  margin-bottom: 1.71rem !important;
  strong {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem !important;
    margin-bottom: 1em;
  }

  @media (max-width: 492px) {
    font-size: 1rem !important;
    margin-bottom: 1em;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    .Button-root {
      padding: 10px 20px;
    }
  }
`;

export const Badge = styled.span`
  border-radius: 4px;
  background: var(--green-200, #a5df9f);
  font-size: 1.45rem;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  padding: 0.4rem 0.8rem;
  margin-bottom: 14px;
  display: inline-block;
`;

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import { banner } from './data';
import RenderBannerImage from '~components/RenderBannerImage';
import CardFinancialAssistance from '~/components/CardFinancialAssistance';
import TextPhoneNumber from '~/components/TextPhoneNumber';
import DownloadStore from '~/components/DownloadStore';
import ListStepNumber from '~/components/ListStepNumber';

import LampadaIcon from '~/assets/img/Financial/assistance-residence-24h/icon-lampada.svg';
import ChaveIcon from '~/assets/img/Financial/assistance-residence-24h/icon-chave.svg';
import EncanadorIcon from '~/assets/img/Financial/assistance-residence-24h/icon-encanador.svg';
import JanelaIcon from '~/assets/img/Financial/assistance-residence-24h/icon-janela.svg';
import CheckIcon from '~/assets/img/Financial/assistance-residence-24h/icon-check.svg';
import BanheiraIcon from '~/assets/img/Financial/assistance-residence-24h/icon-banheira.svg';
import GeladeiraIcon from '~/assets/img/Financial/assistance-residence-24h/icon-geladeira.svg';
import DesentupimentoIcon from '~/assets/img/Financial/assistance-residence-24h/icon-desentupimento.svg';
import CDFLogo from '~/assets/img/Financial/logo-assistance/cdf.svg';
import Iphone from '~/assets/img/Financial/assistance-residence-24h/iphone.svg';
import PlayStore from '~/assets/img/lending/play-store.svg';
import AppleStore from '~/assets/img/lending/apple-store.svg';
import { trackingSelectContent } from '~/analytics';

const PHONE_CAPITALS_AND_REGIONS = '3004 5417';
const PHONE_OTHER_LOCATIONS = '0800 727 4417';
const PHONE_SUPPORT = '0800 704 5025';
const CNPJ = '52.570.231/0001-34.';
const FLOW = 'assistencia-residencia-24h';

const STEP_LIST = [
  {
    value: '1',
    text: 'Baixe o nosso aplicativo Midway;',
  },
  {
    value: '2',
    text: 'Encontre a opção Serviços e procure por Assistências;',
  },
  {
    value: '3',
    text: 'Encontre a Assistência Residência 24h;',
  },
  {
    value: '4',
    text: 'Selecione o plano e a forma de pagamento;',
  },
  {
    value: '5',
    text: 'Confira o resumo, aceite os termos e finalize a contratação.',
  },
];

const AssistanceResidence24h = () => {
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerAssistanceResidence24h(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banners = bannerData[0] || banner;

  const handleClickSeeGeneralConditionsSuper = () => {
    trackingSelectContent(
      'consulte-nossos-termos-e-condicoes-gerais-24h-residencia-super',
      FLOW
    );
  };

  const handleClickSeeGeneralConditionsMega = () => {
    trackingSelectContent(
      'consulte-as-condicoes-gerais-da-assistencia-24h-residencia-mega',
      FLOW
    );
  };

  const handleClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
      '_blank'
    );
    trackingSelectContent('download:google-play', FLOW);
  };

  const handleClickAppleStore = () => {
    window.open(
      'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
      '_blank'
    );
    trackingSelectContent('download:apple-store', FLOW);
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Assistência Residência</S.TitleCards>
            <S.subTitleCards>
              Tenha acesso aos principais benefícios para o seu lar!
            </S.subTitleCards>
          </S.AdjustamentTitleCard>
          <S.ContainerCards>
            <CardFinancialAssistance
              icon={LampadaIcon}
              title="Eletricista"
              description="Se o seu lar sofrer danos elétricos, um profissional é enviado para minimizar o problema e reestabelecer a energia."
            />
            <CardFinancialAssistance
              icon={ChaveIcon}
              title="Encanador"
              description="Se no seu lar surgirem vazamentos, um profissional auxiliará para drenagem da água e obstrução do vazamento."
            />
            <CardFinancialAssistance
              icon={EncanadorIcon}
              title="Chaveiro"
              description="Cobertura em caso de perda, roubo, furto, quebra de chave na fechadura e/ou miolo de contato.."
            />
            <CardFinancialAssistance
              icon={JanelaIcon}
              title="Vidraceiro"
              description="Cobertura em caso de quebra ou danificação de vidros que levem à área externa de sua residência."
            />
            <CardFinancialAssistance
              icon={CheckIcon}
              title="Check-up lar"
              description="Cobertura com inspeção domiciliar preventiva/manutenção de alguns itens do imóvel."
            />
            <CardFinancialAssistance
              icon={BanheiraIcon}
              title="Troca resistência de chuveiro"
              description="Se necessário, providenciaremos um profissional para realizar a troca de chuveiro e/ou resistência."
            />
            <CardFinancialAssistance
              icon={GeladeiraIcon}
              title="Assistência a linha branca"
              description="Cobertura para reparos em eletrodomésticos como: Geladeira, máquina de lavar, fogão e microondas."
            />
            <CardFinancialAssistance
              icon={DesentupimentoIcon}
              title="Desentupimento"
              description="Cobertura em caso de entupimento da caixa de gordura ou tubulação de esgoto."
            />
          </S.ContainerCards>
          <S.AdditionalInfoCard>
            <S.AdditionalInfoText>
              Esses e mais diversos serviços, como:
              <S.AdditionalText>
                Limpeza de caixa d’água; Cobertura provisória de telhado;
                Orientação jurídica preliminar; Limpeza; Transporte de móveis;
                Guarda de móveis; Estadia em hotel; Amparo de criança; Guarda de
                animais; Transmissão de mensagens urgentes.
              </S.AdditionalText>
            </S.AdditionalInfoText>
            <S.CardWrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://midway.com.br/static/documents/tags/condicoes-particulares/condicoes-gerais-assistencia-residencia-pl-super-res-v2.pdf"
                  onClick={handleClickSeeGeneralConditionsSuper}
                  target="_blank"
                >
                  Consulte as condições Gerais da Assistência 24h Residência
                  Super{' '}
                </S.CardWrapperUnderline>
                Comercializado a partir de 04 de novembro de 2019{' '}
              </S.WrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://midway.com.br/static/documents/tags/condicoes-particulares/condicoes-gerais-assistencia-residencia-pl-mega-res-v2.pdf"
                  onClick={handleClickSeeGeneralConditionsMega}
                  target="_blank"
                >
                  Consulte as condições Gerais da Assistência 24h Residência
                  Mega{' '}
                </S.CardWrapperUnderline>
                Comercializado a partir de 04 de novembro de 2019{' '}
              </S.WrapperText>
            </S.CardWrapperText>
          </S.AdditionalInfoCard>
          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanel src={Iphone} />
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Para contratar a Assistência Residência 24h é simples!{' '}
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStepNumber data={STEP_LIST} />
                </S.GroupLeft>
                <S.GroupColumn>
                  <S.TextMidway>Baixe o aplicativo Midway</S.TextMidway>
                  <S.Agroup>
                    <DownloadStore
                      iconSrc={PlayStore}
                      onClick={handleClickPlayStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Google Play</S.TextBold>
                    </DownloadStore>
                    <DownloadStore
                      iconSrc={AppleStore}
                      onClick={handleClickAppleStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                    </DownloadStore>
                  </S.Agroup>
                </S.GroupColumn>
              </S.CentralizeRightPanel>
            </S.RightPanel>
          </S.InformationPanel>
          <S.ContentText>
            <S.TextContent>
              Você também pode contratar nas Lojas Riachuelo mais próximas ou,
              se preferir, pode optar pelas centrais de atendimento através dos
              telefones:
            </S.TextContent>
            <TextPhoneNumber
              text="Capitais e regiões metropolitanas:"
              phoneNumber={PHONE_CAPITALS_AND_REGIONS}
              trackingId={PHONE_CAPITALS_AND_REGIONS}
              trackingFlow={FLOW}
            />
            <TextPhoneNumber
              text="Demais localidades:"
              phoneNumber={PHONE_OTHER_LOCATIONS}
              trackingId={PHONE_OTHER_LOCATIONS}
              trackingFlow={FLOW}
            />
          </S.ContentText>
          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar a Assistência{' '}
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText>
              Fale com a CDF através do telefone:{' '}
            </S.CardBlackBottomText>
            <S.CardBlackBottomText>
              <S.UnderlineText>{PHONE_SUPPORT}</S.UnderlineText>
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>
          <S.ContentZurich>
            <S.ZurichLeft src={CDFLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                A CDF é uma plataforma de serviços que oferece soluções para
                consumidores finais por meio de parceria com Midway. Provendo
                uma experiência digital para facilitar o acesso aos serviços
                disponíveis, além de garantir o acompanhamento de toda jornada
                do consumidor.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>
          <S.ContentFinal>
            <S.ContentTextFinal>
              A Assistência é prestada pela CDF Assistências Ltda inscrita no
              CNPJ: {CNPJ}
            </S.ContentTextFinal>
            <S.ContentTextFinal>
              Restrições se aplicam. Mais informações consulte as condições
              gerais, o regulamento e as características essenciais. Vigência
              inicia-se a partir do dia seguinte a realização do pagamento.
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default AssistanceResidence24h;

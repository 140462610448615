import { colors } from '~/constants';
import styled from 'styled-components';

export const TriggerContainer = styled.button`
  background: none;
  border: none;
  transition: transform 300ms;
  transform: ${({ open, isAnimated }) =>
    isAnimated && open ? 'rotate(180deg)' : 'rotate-(180deg)'};

  svg {
    color: ${colors.chumbo};
  }
`;

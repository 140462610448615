import styled from 'styled-components';
import { ArrowIosDownwardOutline } from '@styled-icons/evaicons-outline/ArrowIosDownwardOutline';

export const Container = styled.footer`
  background-color: #252525;
  margin-top: 24px;
  margin-bottom: ${props => (props.isMobile ? '170px' : '18px')};
  a {
    display: block;
    transition: 0.2 all;
    border: 1px solid transparent;
    &:focus,
    &:hover {
      border-color: #fff;
      border-radius: 5px;
    }
  }
`;

export const Arrow = styled(ArrowIosDownwardOutline)`
  color: #fff;
  width: 30px;
  height: 30px;
`;

export const Nav = styled.nav`
  padding-top: 72px;
  @media (max-width: 390px) {
    max-width: 310px;
  }
  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  h2 {
    color: #fff;
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

export const Row = styled.div`
  padding-top: 55px;
  div > h3 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 26px;
    font-size: 18px !important;
  }
`;

export const Section = styled.div`
  margin-bottom: 26px;
  p.h3 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 26px;
    font-size: 21px !important;
  }
  p.h4 {
    color: #fff;
    font-weight: ${props => (props.boldTitle ? 'bold' : 'regular')};
    font-size: ${props => (props.boldTitle ? '21px' : '14px')} !important;
    line-height: 150%;
  }
  b {
    color: #fff;
    font-size: 21px;
    font-weight: bold;
    margin-top: 10px;
  }
  u {
    color: #fff;
    font-weight: regular;
    margin-bottom: 15px;
    cursor: pointer;
  }
  a {
    color: #fff;
    text-decoration: underline;
    font-weight: regular;
    margin-bottom: 15px;
    cursor: pointer;
  }
`;

export const Phone = styled.p`
  color: #fff;
  font-size: 21px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 14px !important;
  font-weight: normal !important;
  margin-bottom: 8px !important;
  color: #fff;
`;

export const DownloadApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  p {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const DownloadAppContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  outline: none;
  a {
    border: none;
    width: 150px;
    margin-right: 30px;
    border: 1px solid transparent;
    cursor: pointer;
  }
  a:hover,
  a:active,
  a::selection {
    border: 1px solid #fff;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  @media (max-width: 767px) {
    flex-direction: column !important;
  }
`;

export const SocialLinksMobile = styled.div`
  h3 {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding-top: 10px;
    margin-bottom: 17px;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const DownloadAppMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  p {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    margin-right: 280px;
    width: 100%;
  }
  div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const Line = styled.div`
  display: flex;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
  }
  hr {
    width: 100%;
    background-color: #fff;
    margin-bottom: 30px;
  }
`;

export const Copyright = styled.p`
  color: #fff;
  margin-top: 32px;
  padding-bottom: 32px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding-bottom: 66px;
  }
`;

export const HideMobile = styled.div`
  h3 {
    margin-bottom: 10px !important;

    svg {
      margin-top: -8px;
    }
  }
  a {
    margin-bottom: 40px !important;
  }
`;

export const ShowMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const Accessibility = styled.div`
  display: flex;
  flex-direction: column-reverse;

  > p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 26px;
    color: #fff;
  }
  & > h3 {
    font-size: 15px !important;
  }
`;

export const DeveloperPortal = styled.a`
  margin-top: 12px;

  &:hover,
  &:focus {
    border: 1px solid transparent !important;
    /* margin: 12px 0 0 0 !important; */
  }

  > p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 26px;
    color: #fff;
  }

  > div {
    display: inline-block;
    background: #fff;
    border-radius: 2px;

    > img {
      width: 28px;
      height: 28px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

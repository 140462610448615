import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
import * as S from './styles';

SwiperCore.use([Pagination]);

const PaginatedList = ({ itens, handleSwiper, handleModal, isMobile }) => {
  const itemsSlide = [];
  const itemsPerSlide = 20;

  for (let i = 0; i < itens.length; i += itemsPerSlide) {
    itemsSlide.push(itens.slice(i, i + itemsPerSlide));
  }

  return (
    <ul>
      <li>
        <S.TableHeader>
          <S.HeaderFirstColumn>
            <S.Description>Loja</S.Description>
          </S.HeaderFirstColumn>
          {!isMobile && (
            <S.HeaderSecondColumn>
              <S.Description>Endereço</S.Description>
            </S.HeaderSecondColumn>
          )}
          {!isMobile && (
            <S.HeaderThirdColumn>
              <S.Description>Estado</S.Description>
            </S.HeaderThirdColumn>
          )}
          {!isMobile && (
            <S.HeaderLastColumn>
              <S.Description>Telefone</S.Description>
            </S.HeaderLastColumn>
          )}
        </S.TableHeader>
      </li>
      {itemsSlide?.length === 0 && (
        <S.NoPageItems isMobile={isMobile}>
          Nenhuma loja encontrada
        </S.NoPageItems>
      )}
      {!isMobile && (
        <Swiper
          spaceBetween={60}
          slidesPerView={1}
          pagination={{ clickable: true, dynamicBullets: true }}
          onSwiper={handleSwiper}
          noSwiping
          noSwipingClass="swiper-slide"
        >
          {itemsSlide?.map((slide, index) => (
            <SwiperSlide key={index} className="slide">
              <ul>
                {slide?.map((item, i) => (
                  <S.BorderTable key={i}>
                    <S.ContentTable>
                      <S.ContentFirstColumn>
                        <S.Description>{item?.storeName}</S.Description>
                      </S.ContentFirstColumn>
                      <S.ContentSecondColumn>
                        <S.Description>{item?.address}</S.Description>
                      </S.ContentSecondColumn>
                      <S.ContentThirdColumn>
                        <S.Description>{item?.state}</S.Description>
                      </S.ContentThirdColumn>
                      <S.ContentLastColumn>
                        <S.PhoneDescription phoneStore={item?.phoneStore}>
                          {item?.phoneStore}
                        </S.PhoneDescription>
                      </S.ContentLastColumn>
                    </S.ContentTable>
                  </S.BorderTable>
                ))}
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {isMobile && (
        <Swiper
          spaceBetween={60}
          slidesPerView={1}
          pagination={{ clickable: true, dynamicBullets: true }}
          onSwiper={handleSwiper}
        >
          {itemsSlide?.map((slide, index) => (
            <SwiperSlide key={index}>
              <ul>
                {slide?.map((item, i) => (
                  <S.BorderTable key={i}>
                    <S.ContentTable>
                      <S.ContentFirstColumn>
                        <S.MobileDescription>
                          {item?.storeName}
                        </S.MobileDescription>
                      </S.ContentFirstColumn>
                      <S.ContentOrangeButton onClick={() => handleModal(item)}>
                        <S.OrangeButton>Consultar</S.OrangeButton>
                      </S.ContentOrangeButton>
                    </S.ContentTable>
                  </S.BorderTable>
                ))}
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <style>{S.css}</style>
    </ul>
  );
};

export default PaginatedList;

import React, { useEffect, useState, memo } from 'react';
import circle from '~/assets/img/Financial/BilletInvoiceOrLendingV2/circle.png';
import celular1 from '~/assets/img/Financial/BilletInvoiceOrLendingV2/celular1.png';
import celular2 from '~/assets/img/Financial/BilletInvoiceOrLendingV2/celular2.png';
import * as Styles from './styles';

const ImagesParallax = () => {
  const [animationx, setAnimationx] = useState(0);
  const [animationy, setAnimationy] = useState(0);

  useEffect(() => {
    window.addEventListener('mousemove', parallax);
    return () => window.removeEventListener('mousemove', parallax);
  }, []);

  const parallax = event => {
    const x = (window.innerWidth - event.pageX) / 100;
    const y = (window.innerHeight - event.pageY) / 100;

    setAnimationx(x);
    setAnimationy(y);
  };

  return (
    <Styles.Container>
      <Styles.CircleImage src={circle} />
      <Styles.Celular1Image
        style={{
          transform: `translate(${animationx * 0.5 - 150}px, ${
            animationy * 1.5 - 40
          }px)`,
        }}
        src={celular1}
        alt="Foto de um celular exibindo o aplicativo da Midway"
      />
      <Styles.Celular2Image
        style={{
          transform: `translate(${animationx * 1 + 50}px, ${
            animationy * 0.5 - 60
          }px)`,
        }}
        src={celular2}
        alt="Foto de um celular exibindo o aplicativo da Riachuelo"
      />
    </Styles.Container>
  );
};

export default memo(ImagesParallax);

import React, { useCallback, useEffect, useState } from 'react';
import { Image } from '@midway/web-ui-component';
import RenderBannerImage from './components/RenderBannerImage';
import { banner } from './data';
import cmsService from '~/services/cms/pages';
import { trackingSelectContent } from '~/analytics';
import Accordion from '~/components/Accordion';
import MidsHeartRateMonitor from '~/assets/img/Health/heart-rate-monitor.svg';
import MidsMedicalExam from '~/assets/img/Health/mids-medical-exam.svg';
import MidsPill from '~/assets/img/Health/pill.svg';
import MidsHealth from '~/assets/img/Health/mids-health.svg';
import ButtonFull from '~/assets/img/odonto/button-full.svg';
import MidsCheck from '~/assets/img/odonto/mids-check.svg';
import MidsCircleX from '~/assets/img/odonto/circle-x.svg';
import IphoneMockup from '~/assets/img/Health/iPhoneMockup.png';
import Store from '~/assets/img/Health/btn-store.svg';
import StoreApple from '~/assets/img/Health/btn-store-apple.svg';
import Woman from '~/assets/img/Health/Seguro-Conta-Paga.png';
import valeSaude from '~/assets/img/Health/vale-saude.png';
import * as S from './styles';

const PHONE_CAPITALS_AND_REGIONS = '3004 5417';
const PHONE_OTHER_LOCATIONS = '0800 727 4417';
const CNPJ = ' 514.336.330/0001-67';

const CardWrapper = ({ title, icon, widthCard, href, onClick }) => {
  return (
    <S.ContainerCardWrapper>
      <S.CardWrapper minWidth={widthCard}>
        <S.CardAnchor onClick={onClick} href={href} target="_blank">
          <S.Title>{title}</S.Title>
          <S.Icon src={icon} />
        </S.CardAnchor>
      </S.CardWrapper>
    </S.ContainerCardWrapper>
  );
};

export default function AssistanceOdontological24h() {
  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerMoreHealth(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banners = bannerData[0] || banner;

  const toggleAccordion = () => {
    setIsOpenAccordion(!isOpenAccordion);
  };

  const handleClickSeeOurTerms = () => {
    trackingSelectContent(
      'consulte-nossos-termos-e-condicoes-gerais',
      'assistencia-mais-saude'
    );
  };

  const handleLocatePartnerPharmacies = () => {
    trackingSelectContent(
      'localize-as-farmacias-parceiras',
      'assistencia-mais-saude'
    );
  };

  const handleFindServiceNetwork = () => {
    trackingSelectContent(
      'encontre-uma-rede-de-atendimentos-e-laboratorios-disponiveis',
      'assistencia-mais-saude'
    );
  };

  const handleClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
      '_blank'
    );
    trackingSelectContent('download:google-play', 'assistencia-mais-saude');
  };

  const handleClickAppleStore = () => {
    window.open(
      'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
      '_blank'
    );
    trackingSelectContent('download:apple-store', 'assistencia-mais-saude');
  };

  const handleClickCustomerArea = () => {
    trackingSelectContent('area-do-cliente', 'assistencia-mais-saude');
  };

  return (
    <>
      <S.Container id="ready-page-for-screen-reader">
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
          <div>
            <div className="background-benefits" />
            <div className="container benefits">
              <div className="row">
                <h2>Principais benefícios</h2>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="row">
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsMedicalExam}
                        alt="icone Estetoscopio."
                        aria-hidden="true"
                      />
                      <p>
                        Acesso a informações do Plano direto pelo aplicativo
                      </p>
                    </S.Benefits>
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsHeartRateMonitor}
                        alt="icone Monitor de frequência cardíaca."
                        aria-hidden="true"
                      />
                      <p>Conforto e liberdade com dentista online</p>
                    </S.Benefits>
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsPill}
                        alt="icone Pilula."
                        aria-hidden="true"
                      />
                      <p>Descontos exclusivos em lojas parceiras</p>
                    </S.Benefits>
                    <S.Benefits className="col-sm-6 col-md-6 col-lg-3 col-xl-3">
                      <Image
                        src={MidsHealth}
                        alt="icone bolsa de remedio."
                        aria-hidden="true"
                      />
                      <p>A maior rede credenciada do Brasil</p>
                    </S.Benefits>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </S.BannerContainer>

        <S.Session className="container">
          <S.Content className="row">
            <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h4 className="--title-page bold">Conheça o Mais Saúde</h4>
              <p className="subtitle">
                Agora você e sua família terão acesso a consultas médicas
                presenciais, por telemedicina e realização de exames por um
                preço acessível em uma rede de atendimento particular e de
                qualidade.
              </p>
              <p className="listmoretext">Mais vantagens:</p>
              <S.ListContainer>
                <S.ListItem>
                  <S.ListItemContent>
                    <S.Bullet>•</S.Bullet>
                    <S.GroupsItems>
                      <S.Text>
                        Cartão familiar: Titular com até 3 dependentes
                      </S.Text>
                      <S.Description>
                        Cônjuge, filho(a), enteado(a), pais, sogro(a), avô(ó),
                        neto(a) e funcionário(a) doméstico(a)
                      </S.Description>
                    </S.GroupsItems>
                  </S.ListItemContent>
                </S.ListItem>
                <S.ListItem>
                  <S.ListItemContent>
                    <S.Bullet>•</S.Bullet>
                    <S.GroupsItems>
                      <S.Text>Terapias</S.Text>
                      <S.Description>
                        Fisioterapia, RPG, Acupuntura, Fonoaudiologia,
                        Nutricionista e Psicóloga por preços baixos
                      </S.Description>
                    </S.GroupsItems>
                  </S.ListItemContent>
                </S.ListItem>
                <S.ListItem>
                  <S.Bullet>•</S.Bullet>
                  <S.Text>
                    Desconto de até 35% em medicamentos em milhares de farmácias
                    parceiras
                  </S.Text>
                </S.ListItem>
                <S.ListItem>
                  <S.Bullet>•</S.Bullet>
                  <S.Text>
                    {' '}
                    Pacotes cirúrgicos no Hospital Oswaldo Cruz (Unidade
                    Vergueiro - SP) e outros parceiros
                  </S.Text>
                </S.ListItem>
                <S.ListItem>
                  <S.Bullet>•</S.Bullet>
                  <S.Text>
                    Sem restrições para doenças pré-existentes, idade e gravidez
                  </S.Text>
                </S.ListItem>
              </S.ListContainer>
              <h5 className="listmoretextFinal">
                Além de todas as vantagens, você e seus dependentes podem ter
                acesso ao Pronto Atendimento online 24h, todos os dias, com
                médico generalista sempre que precisar. Esta facilidade está
                disponível na opção com Telemedicina por um pequeno acréscimo na
                mensalidade.
              </h5>
              <S.ColAnchor>
                <S.StyleAnchor
                  className="subtitle link"
                  href="https://www.midway.com.br/contrato/Condicoes-Gerais-VSS"
                  target="_blank"
                  onClick={handleClickSeeOurTerms}
                >
                  Consulte nossos termos e condições gerais
                </S.StyleAnchor>
              </S.ColAnchor>
              <S.ContentCardWrapper>
                <CardWrapper
                  widthCard="420px"
                  title="Localize as farmácias parceiras"
                  icon={ButtonFull}
                  href="https://maissaude.valesaudesempre.com.br/farmacias/"
                  onClick={handleLocatePartnerPharmacies}
                />
                <CardWrapper
                  widthCard="740px"
                  title="Encontre uma rede de atendimento e laboratórios disponíveis"
                  icon={ButtonFull}
                  href="https://maissaude.valesaudesempre.com.br/rede/"
                  onClick={handleFindServiceNetwork}
                />
              </S.ContentCardWrapper>
            </S.Col>
            <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <br />
              <br />
              <h4 className="--title-page bold">
                Escolha a opção ideal para você e sua família
              </h4>
            </S.Col>
            <div className="rowPlan">
              <S.Col className="planTable">
                <p className="title">Mais Saúde Essencial</p>
                <ul>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Cartão familiar: Titular com até 3 dependentes;
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Consultas presenciais e por telemedicina disponíveis por uma
                    taxa adicional;
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Mais de 3.000 tipos de exames por um preço acessível;
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Rede de atendimento de qualidade;
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Até 35% de desconto em medicamentos em farmácias
                    credenciadas;
                  </li>
                  <li>
                    <S.Image
                      src={MidsCircleX}
                      alt="check"
                      aria-hidden="false"
                    />{' '}
                    Pronto atendimento médico online 24 horas! Gratuito e
                    ilimitado.
                  </li>
                </ul>
              </S.Col>
              <S.Col className="planTable">
                <p className="title">Mais Saúde Essencial + Telemedicina</p>

                <ul>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Cartão familiar: Titular com até 3 dependentes;
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Consultas presenciais e por telemedicina disponíveis por uma
                    taxa adicional;{' '}
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Mais de 3.000 tipos de exames por um preço acessível;{' '}
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Rede de atendimento de qualidade;{' '}
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Até 35% de desconto em medicamentos em farmácias
                    credenciadas;{' '}
                  </li>
                  <li>
                    <S.Image src={MidsCheck} alt="check" aria-hidden="true" />{' '}
                    Pronto atendimento médico online 24 horas! Gratuito e
                    ilimitado.{' '}
                  </li>
                </ul>
              </S.Col>
            </div>
          </S.Content>
        </S.Session>

        <S.Session className="container mock">
          <div className="BG" />
          <S.Content className="row">
            <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6 iphoneMock">
              <Image
                src={IphoneMockup}
                alt="Mockup iphone"
                aria-hidden="true"
              />
            </S.Col>
            <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6 assistData">
              <p className="title">Para contratar o Mais Saúde é simples! </p>
              <ol>
                <li>Baixe o nosso aplicativo Riachuelo;</li>
                <li>
                  Acesse a aba Cartões e procure por Seguros e Assistências;
                </li>
                <li>Selecione o Mais Saúde e clique em simular e contratar;</li>
                <li>Confirme os dados pessoais e selecione sua opção;</li>
                <li>
                  Aceite os termos e condições e finalize a contratação da
                  assistência.
                </li>
              </ol>
              <p className="subtitleStores">Baixe o aplicativo Midway</p>
              <div className="store-buttons">
                <S.StoreButtons onClick={handleClickPlayStore}>
                  <Image src={Store} alt="Mockup iphone" aria-hidden="true" />
                </S.StoreButtons>
                <S.StoreButtons onClick={handleClickAppleStore}>
                  <Image
                    src={StoreApple}
                    alt="Mockup iphone"
                    aria-hidden="true"
                  />
                </S.StoreButtons>
              </div>
            </S.Col>
          </S.Content>
        </S.Session>
        <S.Session className="container">
          <S.Content className="row">
            <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p className="subtitle">
                Você também pode contratar nas Lojas Riachuelo mais próximas ou,
                se preferir, pode optar pelas centrais de atendimento pelos
                telefones:
              </p>
              <p className="subtitle">
                Capitais e regiões metropolitanas: <br />
                <span className="underline">{PHONE_CAPITALS_AND_REGIONS}</span>
              </p>
              <p className="subtitle">
                Demais localidades: <br />
                <span className="underline">{PHONE_OTHER_LOCATIONS}</span>
              </p>
            </S.Col>
          </S.Content>
        </S.Session>
      </S.Container>
      <S.BlackSession className="container-fluid">
        <S.Content className="row">
          <S.Col className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <Image
              src={Woman}
              alt="Mulher segurando tablet"
              aria-hidden="true"
            />
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-7 col-xl-7 center">
            <div className="content">
              <h4>Já é nosso cliente?</h4>
              <p>
                Acesse a área do cliente e encontre clínicas, laboratórios e
                farmácias próximos de você!
              </p>
              <p className="textcol">
                Agende consultas e exames de forma rápida.
              </p>
            </div>
            <S.ButtonBlackSession
              href="https://maissaude.midway.com.br/"
              target="_blank"
              onClick={handleClickCustomerArea}
            >
              Área do cliente
            </S.ButtonBlackSession>
          </S.Col>
        </S.Content>
      </S.BlackSession>
      <S.Session className="container">
        <S.Content className="row faq">
          <S.AccordionTitle>Perguntas frequentes</S.AccordionTitle>
          <S.HandleShow
            onClick={() => {
              trackingSelectContent(
                isOpenAccordion
                  ? 'faq:fechar:o-que-o-mais-saude-oferece'
                  : 'faq:abriro-que-o-mais-saude-oferece',
                'saude'
              );
              toggleAccordion();
            }}
          >
            <Accordion
              key="1"
              title="O que o Mais Saúde oferece?"
              content="O Mais Saúde oferece consultas médicas presenciais e por telemedicina, exames laboratoriais e de imagem, descontos em medicamentos e atendimento 24 horas."
            />
          </S.HandleShow>
          <S.HandleShow
            onClick={() => {
              trackingSelectContent(
                isOpenAccordion
                  ? 'faq:fechar:como-utilizar-o-mais-saude'
                  : 'faq:abrir:como-utilizar-o-mais-saude',
                'saude'
              );
              toggleAccordion();
            }}
          >
            <Accordion
              key="2"
              title="Como utilizar o Mais Saúde?"
              content="Você pode agendar consultas e exames através da rede credenciada ou usar o pronto atendimento 24 horas, tanto presencialmente quanto por telemedicina com o aplicativo Vale Saúde Sempre."
            />
          </S.HandleShow>
          <S.HandleShow
            onClick={() => {
              trackingSelectContent(
                isOpenAccordion
                  ? 'faq:fechar:tenho-direito-a-telemedicina'
                  : 'faq:abrir:tenho-direito-a-telemedicina',
                'saude'
              );
              toggleAccordion();
            }}
          >
            <Accordion
              key="3"
              title="Tenho direito a telemedicina?"
              content="Caso você tenha escolhido o plano Essencial + Telemedicina você tem acesso a consultas por telemedicina ilimitados e sem custo adicional, agora caso seu plano seja o Essencial você também pode utilizar a telemedicina pagando por uso diretamente no aplicativo Vale Saúde Sempre."
            />
          </S.HandleShow>
        </S.Content>
      </S.Session>
      <S.Session className="container">
        <S.Content className="row">
          <S.Col className="col-sm-12 col-md-12 col-lg-5 col-xl-5 odontoprevImg">
            <Image src={valeSaude} alt="logo Valele Saúde" aria-hidden="true" />
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-7 col-xl-7 valeSaudeTxt">
            <p>
              A Vale Saúde é uma empresa da Vivo, que por meio de assinaturas,
              garante acesso a consultas médicas presenciais e online, exames,
              medicamentos, vacinas, terapias e pacotes cirúrgicos com desconto
              e qualidade.
            </p>
          </S.Col>
        </S.Content>
        <S.Content className="row">
          <S.SpaceFinal>
            <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p className="subtitle">
                O produto Mais Saúde não é um plano de saúde. É um cartão
                pré-pago que pode ser utilizado como meio de pagamento de
                consultas médicas e exames laboratoriais em locais selecionados.
              </p>
              <p className="subtitle">
                Os serviços de consultas médicas e exames clínicos são prestados
                pela Vale Saúde, Soluções de Pagamento inscrita no CNPJ
                {CNPJ}.
              </p>
            </S.Col>
          </S.SpaceFinal>
        </S.Content>
      </S.Session>
    </>
  );
}

import React from 'react';

import closeIcon from '~/assets/img/GuaranteedLimitLP/close.svg';

import {
  RootContainer,
  Container,
  Content,
  Header,
  Details,
  BackButton,
  DetailsContainer,
} from './styles';

export const StoreDetailsModal = ({ store, onClose }) => {
  const storeDetailsLabels = [
    { label: 'Loja', value: store?.store },
    { label: 'Endereço', value: store?.address },
    { label: 'Estado', value: store?.state },
  ];

  return (
    <RootContainer>
      <Container>
        <Content>
          <Header>
            <h3>Informações da loja</h3>

            <img src={closeIcon} alt="Icone de fechar" onClick={onClose} />
          </Header>

          <DetailsContainer>
            {storeDetailsLabels.map(({ label, value }) => (
              <Details key={label}>
                <strong>{label}</strong>

                <span>{value}</span>
              </Details>
            ))}
            <Details key="Telefone">
              <strong>Telefone</strong>

              <a href={`tel://${store?.phone}`}>{store?.phone}</a>
            </Details>
          </DetailsContainer>

          <div>
            <BackButton onClick={onClose}>Voltar</BackButton>
          </div>
        </Content>
      </Container>
    </RootContainer>
  );
};

import React from 'react';
import styled from 'styled-components';
import trackEventGa from '../GoogleAnalytics';

const FooterContainer = styled.footer`
  background: #000;
  position: relative;
  inset: 0;
  padding: 38px 26px;

  @media (min-width: 1024px) {
    padding: 63px 226px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  max-width: 1440px;
  margin: 0 auto;
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
`;

const Terms = styled.p`
  color: white;
  font-size: 10px;
  text-align: justify;
  padding: 10px;
  padding-bottom: 120px;

  a {
    color: white;
  }
`;

const Img = styled.img`
  max-height: 57px;

  @media (min-width: 1024px) {
    max-height: 57px;
  }
`;

const NavItems = styled.ul`
  display: flex;
  justify-content: center;
  gap: 7.5px;
`;

const NavItem = styled.li`
  display: flex;
  align-items: center;
  gap: 7.5px;
  font-size: 0.875rem;
  p {
    color: #f0c324;
  }

  &.with-before-after::before,
  &.with-before-after::after {
    content: '';
    width: 1px;
    height: 14px;
    background: #f0c324;
    display: block;
  }
`;

const MidwayLink = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;

  a {
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
`;

const ImgWrapper = styled.div``;

const handleClick = itemName => {
  itemName = itemName
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/\s/g, '-');
  trackEventGa(`footer: ${itemName}`);
};

const Footer = () => {
  return (
    <FooterContainer>
      <FlexContainer>
        <ImgContainer>
          <ImgWrapper>
            <Img src="/logo-riachuelo.png" alt="" />
          </ImgWrapper>
          <ImgWrapper>
            <Img src="/logo-midway.png" alt="" />
          </ImgWrapper>
        </ImgContainer>

        <NavItems>
          <NavItem>
            <p onClick={() => handleClick('Empréstimos')}>Empréstimos</p>
          </NavItem>
          <NavItem className="with-before-after">
            <p onClick={() => handleClick('Seguros e Assistências')}>
              Seguros e Assistências
            </p>
          </NavItem>
          <NavItem>
            <p onClick={() => handleClick('Cartões')}>Cartões</p>
          </NavItem>
        </NavItems>

        <MidwayLink>
          <a
            onClick={() => {
              handleClick('www.midway.com.br');
            }}
            href="https://www.midway.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.midway.com.br
          </a>
        </MidwayLink>

        <Terms>
          *O seu novo cartão Riachuelo será Mastercard e substituirá o atual
          Visa. O envio do novo cartão está sujeito à nova análise de crédito.
          Após o recebimento, efetue o desbloqueio do seu novo cartão via App
          Midway ou App Riachuelo. Após o desbloqueio do seu novo cartão
          Mastercard, o seu cartão atual (VISA) será automaticamente cancelado e
          substituído. Para o novo cartão será necessário o registro de uma nova
          senha. Anuidade e vencimento permanecerão inalterados. No período de
          troca do cartão, caso receba duas faturas, o pagamento de ambas deverá
          ser efetuado, não se trata de duplicidade. Para maiores informações,
          entre em contato Central de Atendimento: 3004 5417 (Capitais e Regiões
          Metropolitanas) e 0800 727 4417 (Demais Localidades) - 24h. SAC: 0800
          721 3344. Ouvidoria: 0800 727 3255 - de 2ª a 6ª feira, das 10h às 16h,
          exceto feriados.
          <br />
          <br />
          O Programa Mastercard Surpreenda é um programa por meio do qual os
          participantes podem converter em vouchers pontos obtidos no Programa
          Mastercard Surpreenda - Programa de Relacionamento Mastercard. Para
          resgatar seu voucher é necessário ser cadastrado no Mastercard
          Surpreenda, estar logado no seu perfil e ter saldo de pontos igual ou
          superior à quantidade de pontos requerida pela oferta. O voucher
          Surpreenda deve ser resgatado na página da parceria. Para cada oferta
          disponibilizada pelo Parceiro haverá um voucher correspondente. Além
          das regras estabelecidas neste Regulamento, deverão ser
          necessariamente observadas as disposições do Regulamento Principal do
          Programa Mastercard Surpreenda - Programa de Relacionamento
          Mastercard, disponível no site. A participação neste Programa
          implicará a aceitação total e irrestrita de todos os termos deste
          Regulamento. ²Todos os participantes deverão observar as condições,
          formas e prazos de participação, sendo sumariamente excluídos os
          participantes que cometerem qualquer tipo de fraude, ficando, ainda,
          sujeitos à responsabilização penal e civil. A Promotora não se
          responsabiliza pela autenticidade dos dados cadastrais e das demais
          informações fornecidas pelo participante durante a execução deste
          Programa. Os vouchers objeto deste Programa não poderão ser, de
          qualquer forma, comercializados ou convertidos em dinheiro. A
          Promotora não se responsabiliza por eventuais indisponibilidades do
          sistema de computação/telefonia do Parceiro e demais prejuízos
          experimentados pelo participante ou por terceiros portadores de
          vouchers na utilização dos vouchers, bem como por eventuais vícios e
          defeitos na prestação de entrega dos produtos desse Programa, de
          responsabilidade única e exclusiva do Parceiro. Poderá participar
          deste Programa qualquer pessoa devidamente inscrita no programa
          Mastercard Surpreenda. O Parceiro poderá, desde que de comum acordo
          com a Promotora, alterar os Produtos desse Programa, desde que essa
          alteração seja devidamente comunicada no site. Os prazos indicados
          nesse Regulamento poderão ser alterados durante o Programa, desde que
          previamente comunicados no Site do Programa. O Programa nunca solicita
          e não envia e-mails solicitando ao participante o código de segurança
          de seu cartão e/ou suas senhas. Em caso de dúvidas e controvérsias
          relacionadas a este regulamento, o titular ou adicional do cartão
          participante poderá entrar em contato com o Serviço de Atendimento,
          através do Fale Conosco, disponível no site do Programa Mastercard
          Surpreenda - Programa de Relacionamento Mastercard em
          [http://www.mastercard.com.br/surpreenda](http://www.mastercard.com.br/surpreenda).
          O regulamento completo do Programa está disponível em
          [http://www.mastercard.com.br/surpreenda](http://www.mastercard.com.br/surpreenda).
          <br />
          <br />
          CashBack 20%: Promoção não cumulativa, válida para compras realizadas
          nas lojas físicas Riachuelo, Fanlab, Carter&apos;s e Casa Riachuelo de
          todo todo o Brasil e pagas exclusivamente com os Cartões Riachuelo,
          sem limite mínimo de valor e respeitado o limite de crédito do seu
          cartão Riachuelo. O crédito de 20% será aplicado sobre o valor total
          do cupom fiscal, exceto produtos das categorias de Eletrônicos,
          Eletroportáteis, Celulares, Relógios e Recarga de Celulares. Para a
          utilização do crédito, o valor ficará limitado a 40% do valor da
          próxima compra. O crédito estará atrelado ao CPF do comprador durante
          o período de cada mês e será aplicado em sua próxima compra nas lojas
          físicas a partir do primeiro dia após a compra que originou o crédito
          e até o dia 15 de cada mês subsequente. O crédito não poderá ser
          utilizado nas compras de produtos das categorias de Eletrônicos,
          Eletroportáteis, Celulares, Relógios e Recarga de Celulares. Em caso
          de cancelamento da compra, o valor do crédito gerado será estornado
          automaticamente. Cartão sujeito à análise e aprovação de crédito para
          clientes selecionados. O limite disponível poderá sofrer alterações
          mediante uso do cartão ou política vigente. Para conferir o seu,
          acesse o aplicativo Riachuelo. SAC: 0800 721 3344. Ouvidoria Fone 0800
          727 3255 - de 2ª a 6ª feira, das 10h às 16h, exceto feriados.
        </Terms>
      </FlexContainer>
    </FooterContainer>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { Modal } from '@midway/web-ui-component';

import Spinner from '~/components/Loading';
import { PAYMENT_METHOD } from '~/constants/installment';

import { ModalHeader } from '../ModalHeader';
import { HeaderContainer, Header } from './styles';

export const CHANGE_HEADER_TIME = 4000;

const getFirstHeaderText = paymentMethod => (
  <>
    <Header>Processando</Header>
    <Header>
      o {paymentMethod === PAYMENT_METHOD.PIX ? 'PIX' : 'boleto'}, aguarde
    </Header>
  </>
);
const SECOND_HEADER_TEXT = (
  <>
    <Header>Falta pouco,</Header>
    <Header>estamos quase lá</Header>
  </>
);

export const Loading = ({ paymentMethod }) => {
  const [isTakingTooLong, setIsTakingTooLong] = useState(false);
  const header = isTakingTooLong
    ? SECOND_HEADER_TEXT
    : getFirstHeaderText(paymentMethod);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTakingTooLong(true);
    }, CHANGE_HEADER_TIME);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Modal
      title={<ModalHeader title="PARCELAMENTO DE FATURA" />}
      size="lg"
      openModal
      onHide={() => {}}
    >
      <Spinner size={12} />
      <HeaderContainer>{header}</HeaderContainer>
    </Modal>
  );
};

import React, { useEffect } from 'react';
import Circle from '~/assets/img/Financial/BilletInvoiceOrLendingV2/circle.png';
import CardGreen from '~/assets/img/Financial/BilletInvoiceOrLendingV2/credit-card-preto.png';
import CardBlack from '~/assets/img/Financial/BilletInvoiceOrLendingV2/credit-card-verde.png';
import CardsMobile from '~/assets/img/Financial/BilletInvoiceOrLendingV2/cards-mobile.png';
import {
  tagContaOnlineConsultaBoletoLink,
  tagContaOnlineUpsellLink,
} from '~/services/tagging';
import * as Styles from './styles';

const openWhatsappLink = () => {
  tagContaOnlineConsultaBoletoLink('botao:solicite-seu-cartao-aqui');
  window.open(
    'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1%2C%20quero%20solicitar%20meu%20novo%20cart%C3%A3o%20Midway.&app_absent=0'
  );
};

export const UpsellDesktop = () => {
  useEffect(() => {
    tagContaOnlineUpsellLink();
  }, []);

  return (
    <Styles.Container>
      <Styles.ContainerAnimated>
        <Styles.CircleImage src={Circle} />
        <Styles.CardBlack
          src={CardBlack}
          alt="Foto de um celular exibindo o aplicativo da Midway"
        />
        <Styles.CardGreen
          src={CardGreen}
          alt="Foto de um celular exibindo o aplicativo da Riachuelo"
        />
      </Styles.ContainerAnimated>
      <Styles.Text>Você tem um novo cartão esperando por você!</Styles.Text>
      <Styles.Button
        aria-label="Clique e será redirecionado para o WhatsApp para falar com um de nossos especialistas."
        onClick={openWhatsappLink}
      >
        Peça seu cartão agora
      </Styles.Button>
    </Styles.Container>
  );
};

export const UpsellMobile = () => {
  return (
    <Styles.MobileButton
      onClick={openWhatsappLink}
      aria-label="Clique e será redirecionado para o WhatsApp para falar com um de nossos especialistas."
    >
      <Styles.MobileContainer>
        <Styles.MobileLeft>
          <Styles.MobileImg
            src={CardsMobile}
            alt="Imagem ilustrativa de um cartão de crédito"
          />
        </Styles.MobileLeft>
        <Styles.MobileRight>
          <Styles.MobileTitle>Solicite seu cartão aqui!</Styles.MobileTitle>
          <Styles.MobileDescription>
            Você tem um novo cartão esperando por você. Toque aqui e saiba mais!
          </Styles.MobileDescription>
        </Styles.MobileRight>
      </Styles.MobileContainer>
    </Styles.MobileButton>
  );
};

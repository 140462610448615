import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 0 0;
  display: flex;
  background-color: transparent;

  @media screen and (min-width: 1200px) {
    padding: 40px 0 0;
    max-width: 1176px;
    margin: auto;
  }
`;

export const TabWrapper = styled.button`
  flex: 1;
  border: transparent;
  background-color: transparent;
  border-bottom: ${props => (props.active ? '2px' : '1px')}
    ${props => (props.active ? '#1f2b2a' : '#cacecd')} solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
`;

export const Text = styled.p`
  font-weight: ${props => (props.active ? 500 : 400)};
  font-size: 0.9rem;
  word-wrap: break-word;
  flex: 1;
`;

export const IconWrapper = styled.div`
  margin-bottom: 8px;
  flex: 1;

  svg {
    width: 16px;
    height: 16px;

    @media screen and (min-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }
`;

export const Faq = [
  {
    id: 1,
    title: 'Sobre o produto',
    questions: [
      {
        id: 1,
        title: 'O que é o Empréstimo Pessoal?',
        content: `O Empréstimo Pessoal é uma linha de crédito desenhada para quem precisa de uma ajuda financeira sem objetivo específico.
          Ao contrário de outras, como o Empréstimo imobiliário, por exemplo, no Empréstimo Pessoal você pode usar o valor para qualquer finalidade - como pagamento de contas, fazer um curso ou reforma.
          Além disso, a contratação do crédito na Midway é rápida e você ainda escolhe se quer receber o dinheiro na conta (Crédito Pessoal) ou sacar o valor na hora (Saque Fácil).`,
      },
      {
        id: 2,
        title: 'O que é Saque Fácil e Crédito Pessoal?',
        content: `O Saque Fácil e o Crédito Pessoal são os apelidos para a maneira como você vai receber o crédito do seu Empréstimo Pessoal. Ambos são exclusivos para clientes que tem o cartão Riachuelo ou cliente da conta Midway. Em ambos as condições são individuais para cada cliente e dependem da análise de crédito. O limite máximo é de R$ 7 mil e possível pagar em até 21 parcelas fixas, com o pagamento da primeira parcela em até 90 dias, mediante análise. O valor mínimo de cada parcela deve ser R$ 50. Consulte suas condições.
          Em breve disponível para clientes que não tem o cartão Riachuelo ou conta Midway.
          `,
      },
      {
        id: 3,
        title: 'Qual a diferença entre Saque Fácil e Crédito Pessoal?',
        content: `A diferença entre os dois é que no Saque Fácil o cliente saca o dinheiro no caixa da loja Riachuelo (dinheiro em mãos) e no Crédito Pessoal o dinheiro é transferido para a conta do cliente (conta corrente, de pagamento ou poupança), e você recebe em até 1 dia útil. Se o Crédito Pessoal for contratado pelo app da conta Midway, você recebe o valor na hora na sua conta.`,
      },
      {
        id: 4,
        title: 'Em quanto tempo recebo o Crédito Pessoal?',
        content: `Se você for cliente da conta Midway e contratar por lá, você recebe o valor na hora na sua conta, mas se você indicar outra conta de sua titularidade, o valor será creditado no momento da contratação e estará disponível na sua conta em até 1 dia útil. Lembre-se de informar corretamente os dados bancários e evite a necessidade de refazer a operação.`,
      },
      {
        id: 5,
        title: 'Qual é o limite máximo?',
        content: `O limite máximo é algo pessoal e depende do seu perfil, podendo chegar até R$7 mil. Você pode fazer uma simulação agora mesmo e conhecer as condições. `,
      },
      {
        id: 6,
        title: 'O que é carência? E qual é a carência máxima?',
        content: `A carência é o intervalo que é dado entre a contratação do Empréstimo e o pagamento da 1ª parcela. Atualmente, disponibilizamos carências entre 30 e 90 dias, dependendo do perfil de crédito. Conforme o período de carência escolhido, o sistema calcula a data de vencimento da 1ª parcela.`,
      },
      {
        id: 7,
        title: 'O que é prazo? E qual é o prazo máximo?',
        content: `O prazo é o período de tempo pelo qual você pagará pelo Empréstimo, sendo que a cada mês terá uma parcela a vencer. Os prazos para pagamento do valor emprestado variam entre 4 e 21 meses, de acordo com o seu perfil de crédito. Se você optar por pagar o Saque Fácil em 21 parcelas fixas, terá uma nova parcela para pagar por 21 meses.`,
      },
      {
        id: 8,
        title: 'Em qual o tipo de conta posso receber o meu Crédito Pessoal?',
        content: `Conta corrente, conta de pagamento ou conta poupança, desde que seja de sua titularidade. Você ainda pode receber na conta Midway e fazer o pagamento em débito automático, assim não precisa se preocupar em lembrar todo mês do pagamento da parcela.`,
      },
      {
        id: 9,
        title: 'Quais bancos são aceitos para o Crédito Pessoal?',
        content: `Atualmente trabalhamos com todos os bancos, inclusive bancos digitais.`,
      },
      {
        id: 10,
        title: 'O que é IOF?',
        content: `O IOF é a sigla para Imposto sobre Operações Financeiras. Esse imposto é pago por pessoas físicas e jurídicas (empresas) que efetuarem/contrataram operações de crédito, como o Empréstimo. A porcentagem do imposto sobre o valor depende do tipo de operação. Nas operações de Empréstimo para pessoas físicas a tributação é de 0,38% sobre a operação somado a 0,0082% ao dia. E por isso, dependendo da simulação feita o valor do IOF muda.`,
      },
      {
        id: 11,
        title: 'O que é CET?',
        content: `CET é a sigla para Custo Efetivo Total. A taxa que corresponde a todas as despesas e encargos incidentes nas operações de crédito. O Custo Efetivo Total precisa ser expresso na forma de taxa percentual anual, incluindo todos os encargos e despesas das operações. Isto significa que o CET deve englobar não somente a taxa de juros, como também tarifas, seguros, tributos e outras despesas que sejam cobradas do cliente. `,
      },
      {
        id: 12,
        title: 'Tenho limites distintos para Saque Fácil e Crédito Pessoal?',
        content: `Não, existe um único limite para o Empréstimo, sendo Saque Fácil ou Crédito Pessoal. Se você tem um limite pré-aprovado de R$ 7 mil e pegou emprestado R$ 3 mil na modalidade Saque Fácil, você ainda pode realizar uma nova contratação no Crédito Pessoal e terá à disposição o restante (R$ 4 mil).`,
      },
      {
        id: 13,
        title: 'Como sei o meu limite disponível?',
        content: `Você pode consultar o seu limite no app Midway, na central de relacionamento ou em qualquer Loja Riachuelo. <br/>
        App: Outros > Empréstimos > Empréstimo Pessoal > Meus contratos
        `,
      },
      {
        id: 14,
        title: 'O limite é reestabelecido conforme o pagamento das parcelas? ',
        content: `Sim, conforme as parcelas vão sendo pagas, o limite vai voltando. Sujeito a análise de crédito.`,
      },
      {
        id: 15,
        title:
          'Em quanto tempo tenho um limite pré- aprovado após a aquisição do cartão Riachuelo (PL ou Bandeira) ou criação da conta Midway?',
        content: `A liberação do limite para Empréstimo fica disponível no momento da contratação do cartão ou da criação da conta.
        `,
      },
      {
        id: 16,
        title:
          'A Midway cobra seguro atrelado ao Empréstimo (Seguro Prestamista)?',
        content: `Atualmente não realizamos a contratação de seguro prestamista vinculado ao Empréstimo Pessoal.`,
      },
    ],
  },

  {
    id: 2,
    title: 'Elegibilidade do cliente',
    questions: [
      {
        id: 1,
        title: `Quem tem direito ao produto do Empréstimo (Saque Fácil e Crédito Pessoal)?`,
        content: `Os produtos do Empréstimo são exclusivos para clientes do cartão Riachuelo (PL ou Bandeira) ou clientes da conta Midway, desde que aprovados na análise de crédito.
          Em breve teremos novidades.`,
      },
      {
        id: 2,
        title: 'Como é feita análise de crédito?',
        content:
          'Antes de definir o limite, taxa, carência e prazo ideal para você, fazemos uma avaliação da sua saúde financeira, levando em conta vários critérios, como por exemplo, histórico de crédito no mercado. ',
      },
      {
        id: 3,
        title:
          'Preciso de dinheiro, mas não tenho limite pré-aprovado. O que fazer?',
        content:
          'Sentimos por não poder ajudar nesse momento. A análise de crédito é refeita mensalmente, sugerimos que você tente de novo daqui a 30 dias.',
      },
      {
        id: 4,
        title:
          'Se atraso a fatura do cartão de crédito, perco o limite disponível do Empréstimo?',
        content: `Sim, a inadimplência no cartão de crédito Riachuelo torna o cliente inelegível para a contratação do Empréstimo.`,
      },
      {
        id: 5,
        title: 'Se atraso o Empréstimo, perco o limite do cartão de crédito?',
        content: `Sim, a inadimplência do Empréstimo causa o bloqueio do limite do cartão Riachuelo. `,
      },
      {
        id: 6,
        title: `O limite do Empréstimo influencia no limite do meu cartão de crédito?`,
        content: `Não, os limites são independentes. A utilização de um não influencia no limite do outro.`,
      },
      {
        id: 7,
        title: `Tenho limite disponível, porém na simulação meu Empréstimo foi negado. O que acontece?`,
        content: `Após a simulação para contratação de Empréstimo, ainda realizamos uma validação final com dados internos e externos, o que pode ocasionar na não aprovação do Empréstimo Pessoal.`,
      },
      {
        id: 8,
        title: `Existe uma renda mínima para solicitação de Empréstimo?`,
        content: `Não, a Midway leva em consideração outros fatores na hora de conceder crédito.`,
      },
    ],
  },

  {
    id: 3,
    title: 'Contratação',
    questions: [
      {
        id: 1,
        title: `Onde posso contratar o Empréstimo (Saque Fácil e Crédito Pessoal)?`,
        content: `Saque Fácil: A contratação do Saque Fácil pode ser feita nas lojas Riachuelo, onde você já sai com o dinheiro em mãos.
          Crédito Pessoal: o Crédito Pessoal pode ser contratado nas lojas, no aplicativo ou pela Central de Atendimento.`,
      },
      {
        id: 2,
        title: `Quais documentos são necessários para a realização do Empréstimo?`,
        content: `Em loja: Você vai precisar de um documento de identificação (original com foto) e do cartão Riachuelo. <br/>
          CCR: Não precisa do documento. <br/>
          App:  Não precisa do documento. `,
      },
      {
        id: 3,
        title: `Quanto tempo leva a contratação do Empréstimo na Midway?`,
        content: `Apenas alguns minutos. Com o crédito pré-aprovado, a contratação é muito rápida. Você pode fazer uma simulação agora mesmo e contratar.`,
      },
      {
        id: 4,
        title: `Preciso informar os meus dados bancários para fazer um Empréstimo?`,
        content: `Atenção! A Midway só pede os dados bancários em caso de contratação do Crédito Pessoal, uma vez que você opta por receber o valor creditado na conta. Nunca pedimos senhas pessoais ou dígitos do cartão Riachuelo.`,
      },
      {
        id: 5,
        title: `É necessário ter conta corrente para contratar um Empréstimo?`,
        content: `Você consegue contratar um Empréstimo mesmo não tendo uma conta corrente, utilizando a opção de Saque Fácil, o dinheiro é retirado na loja no momento da contratação.
          Além disso, se você tiver uma conta de pagamento na Midway ou em um banco digital ou uma conta poupança, você também consegue contratar o Crédito Pessoal.`,
      },
      {
        id: 6,
        title: `Posso indicar a conta de outra pessoa para receber o Empréstimo?`,
        content: `Por questões de segurança, não é permitido indicar conta de terceiros. Para a efetivação da contratação, é necessário que a conta indicada seja do mesmo CPF do contratante.`,
      },
      {
        id: 7,
        title: `Consigo fazer um Empréstimo mesmo tendo restrições no meu nome?`,
        content: `Mesmo estando negativado ou com alguma restrição, você ainda pode receber uma oferta de Empréstimo, isso vai depender do tipo de restrição. Você pode fazer uma simulação agora mesmo.
        Lembre-se que restrições afetam o seu perfil, impactando nas condições da oferta, assim como taxa de juros, carência, prazo e limite.`,
      },
      {
        id: 8,
        title: `Já tenho um contrato com a Midway e preciso pegar mais dinheiro, é possível?`,
        content: `Se você já fez um Empréstimo e não utilizou todo o limite aprovado, você consegue fazer mais Empréstimos, até a utilização total do limite aprovado.
        Caso já tenha utilizado todo o limite aprovado, você só consegue fazer um novo Empréstimo com a Midway a medida que for pagando o contrato atual e o limite sendo liberado.`,
      },
      {
        id: 9,
        title: `Quais são as tarifas aplicadas e taxa de juros cobrada?`,
        content: `A Midway não cobra tarifas, apenas a taxa de juros e impostos.
        A taxa de juros para o Empréstimo Pessoal varia de acordo com o perfil, sendo a partir de 2,99%. Você pode fazer uma simulação agora mesmo e conhecer as condições.`,
      },
      {
        id: 10,
        title: `É cobrada alguma taxa para a contratação do Empréstimo Pessoal?`,
        content: `A Midway não cobra taxa de pagamento para a contratação do Empréstimo Pessoal. A cobrança de taxa de cadastro (TAC – Taxa de Abertura de Cadastro) é proibida pelo banco central. Geralmente, quando se cobra esse tipo de taxa se trata de um golpe, tome cuidado. `,
      },
      {
        id: 11,
        title: `A Midway solicita alguma antecipação para liberação do valor do Empréstimo?`,
        content: `A Midway não solicita pagamento antecipado para a liberação do seu Empréstimo e a cobrança desse tipo de taxa é ilegal. Geralmente, quando se cobra esse tipo de taxa se trata de um golpe, tome cuidado.`,
      },
      {
        id: 12,
        title: `O que acontece se eu informar dados bancários incorretos na contratação do Crédito Pessoal?`,
        content: `Se os dados bancários informados estiverem incorretos, o valor não será creditado na conta e a operação será cancelada. Para receber o crédito será necessário refazer a operação.
        Por isso, é muito importante que você informe os dados bancários corretos.`,
      },
    ],
  },
  {
    id: 4,
    title: 'Pagamento',
    questions: [
      {
        id: 1,
        title: `Como posso pagar as parcelas do meu Empréstimo?`,
        content: `Você pode efetuar o pagamento do Empréstimo em qualquer loja física Riachuelo, com carnê ou CPF, ou através de boleto bancário em qualquer instituição que aceite o recebimento de boletos (bancos, lotéricas, etc).
        Se você fez a contratação do Empréstimo pelo app da conta Midway, o pagamento acontece via débito automático.`,
      },
      {
        id: 2,
        title: `Quando recebo os boletos?`,
        content: `O valor do Empréstimo Pessoal não vem na sua fatura do cartão. Você pode gerar boleto para pagamento da parcela a qualquer momento através do app, Central de Atendimento ou site. O pagamento pode ser realizado em qualquer instituição que aceite o recebimento de boletos (bancos, lotéricas, etc).
        Além disso, você pode fazer o pagamento em qualquer loja física Riachuelo informando o seu CPF. <br/>
        App: Outros > Empréstimos > Empréstimo Pessoal > Meus contratos <br/>
        Site: <a href="https://contaonline.midwayfinanceira.com.br/contaonline/home" target="_blank" style="word-break: break-all"> https://contaonline.midwayfinanceira.com.br/contaonline/home </a>`,
      },
      {
        id: 3,
        title: `Qual é a data de vencimento do Empréstimo? Consigo alterar essa data?`,
        content: `A data de vencimento do Empréstimo é definida conforme a carência escolhida no momento da contratação. Ex. Você realiza a contratação do Empréstimo no dia 1º de Setembro, com carência de 30 dias. A data de vencimento acontecerá dali a 30 dias corridos, considerando o vencimento em dia útil. Nessa situação, o vencimento aconteceria no 1º dia útil de Outubro. Os demais vencimentos acompanham a data da primeira parcela, ou seja, 1º dia útil de cada mês.
        Nas contratações pelo app da conta Midway, é possível selecionar datas de vencimento diferentes, lembrando que depois de selecionada uma data de vencimento não é possível alterá-la posteriormente.`,
      },
      {
        id: 4,
        title: `Quanto tempo demora para pagar a 1ª parcela?`,
        content: `O prazo para início do pagamento é definido no momento da contratação e vai depender da carência disponibilizada para o seu perfil, podendo variar entre 30 e 90 dias conforme o período de carência escolhido.`,
      },
      {
        id: 5,
        title: `Onde acompanho as parcelas pagas e as parcelas em aberto do meu Empréstimo?`,
        content: `Você pode acompanhar pelo app da conta Midway, Central de Atendimento ou em qualquer loja física Riachuelo. <br />
        App: Outros > Empréstimos > Empréstimo Pessoal > Meus contratos <br />
        Site: <a href="https://contaonline.midwayfinanceira.com.br/contaonline/home" target="_blank" style="word-break: break-all"> https://contaonline.midwayfinanceira.com.br/contaonline/home </a>`,
      },
      {
        id: 6,
        title: `Pagando antecipadamente, tenho desconto na parcela?`,
        content: `Sim, você tem desconto dos juros, proporcional aos dias antecipados.`,
      },
      {
        id: 7,
        title: `Como posso antecipar uma das parcelas?`,
        content: `Você pode antecipar ou até mesmo liquidar o contrato a qualquer momento. A antecipação pode ser feita em qualquer loja física Riachuelo, Central de Atendimento ou app Midway (exclusivo para clientes da conta). No site, você consegue antecipar apenas a próxima parcela. <br/>
        App: Outros > Empréstimos > Empréstimo Pessoal > Meus contratos <br/>
        Site: <a href="https://contaonline.midwayfinanceira.com.br/contaonline/home" target="_blank" style="word-break: break-all"> https://contaonline.midwayfinanceira.com.br/contaonline/home </a>`,
      },
      {
        id: 8,
        title: `O que acontece se atrasar o pagamento da parcela?`,
        content: `Se você não conseguir pagar a parcela até a data de vencimento, estará sujeito ao pagamento de juros por atraso de 1% a.m. e multa de 2%. Além disso, atrasar o pagamento da sua parcela impacta no seu perfil. Tente manter o seu pagamento sempre em dia.`,
      },
      {
        id: 9,
        title: `Como funciona a renegociação do Empréstimo para clientes inadimplentes?`,
        content: `Se você estiver com dificuldade de pagar as parcelas, é possível renegociar a dívida ligando para a nossa Central de Atendimento ou pela nossa parceria com o <a href="https://www.serasa.com.br/limpa-nome-online/parceiros/riachuelo/?utm_source=midia-externa-parceiro&utm_medium=referral&utm_campaign=pagina-parceiro"  target="_blank" > Limpa Nome do Serasa<a/><span style="font-weight:normal">, disponível no site.<span/>`,
      },
    ],
  },
  {
    id: 5,
    title: 'Cancelamento',
    questions: [
      {
        id: 1,
        title: `Em quais situações é possível cancelar um Empréstimo contratado?`,
        content: `Você tem o direito de cancelar Empréstimos apenas para situações onde a contratação ocorreu por meio de mídias digitais (Central de Atendimento ou app), em até 7 dias corridos após a contratação.`,
      },
      {
        id: 2,
        title: `Como é feito o cancelamento do Empréstimo?`,
        content: `Se você contratou o Empréstimo por meio de mídias digitais (Central de Atendimento ou app) e ainda está dentro do prazo de cancelamento (até 7 dias corridos) você pode pedir o cancelamento entrando em contrato com a Central de Atendimento.`,
      },
    ],
  },
  {
    id: 6,
    title: 'Sobre os canais de atendimento',
    questions: [
      {
        id: 1,
        title: `Quais são os serviços relacionados a Empréstimo que a Central de Atendimento realiza para o cliente?`,
        content: `Na Central de Atendimento é possível realizar a simulação, contratação do Empréstimo, geração de boletos antecipados, em dia ou em atraso, renegociação para clientes inadimplentes, cancelamento e atendimento de dúvidas no geral.`,
      },
      {
        id: 2,
        title: `Quais os serviços relacionados a Empréstimo que o cliente pode efetuar no app da conta Midway?`,
        content: `No aplicativo é possível realizar a simulação, contratação do Empréstimo, verificar o limite disponível, consultar histórico de contratos antigos, consultar as parcelas em aberto e as pagas e também gerar boletos.`,
      },
    ],
  },
];

export const FaqPrincipaisDuvidas = [
  {
    id: 1,
    title:
      'O limite do Empréstimo influencia no limite do meu cartão de crédito?',
    content:
      'Não, os limites são independentes. A utilização de um não influencia no limite do outro.',
  },
  {
    id: 2,
    title: 'Como é feita análise de crédito?',
    content:
      'Antes de definir o limite, taxa, carência e prazo ideal para você, fazemos uma avaliação da sua saúde financeira, levando em conta vários critérios, como por exemplo, histórico de crédito no mercado. ',
  },
  {
    id: 3,
    title:
      'Preciso de dinheiro, mas não tenho limite pré-aprovado. O que fazer?',
    content:
      'Sentimos por não poder ajudar nesse momento. A análise de crédito é refeita mensalmente, sugerimos que você tente de novo daqui a 30 dias.',
  },
  {
    id: 4,
    title:
      'Em quanto tempo tenho um limite pré-aprovado após a aquisição do cartão Riachuelo (PL ou Bandeira) ou criação da conta Midway?',
    content:
      'A liberação do limite para Empréstimo fica disponível no momento da contratação do cartão ou da criação da conta.',
  },
  {
    id: 5,
    title: 'Quando recebo os boletos?',
    content: `O valor do Empréstimo Pessoal não vem na sua fatura do cartão. Você pode gerar boleto para pagamento da parcela a qualquer momento através do app, Central de Atendimento ou site. O pagamento pode ser realizado em qualquer instituição que aceite o recebimento de boletos (bancos, lotéricas, etc).
      Além disso, você pode fazer o pagamento em qualquer loja física Riachuelo informando o seu CPF. <br/>
      App: Outros > Empréstimos > Empréstimo Pessoal > Meus contratos <br/>
      Site: <a href="https://contaonline.midwayfinanceira.com.br/contaonline/home" target="_blank" style="word-break: break-all"> https://contaonline.midwayfinanceira.com.br/contaonline/home </a>`,
  },
];

export const faqNegotiationCampaing = [
  {
    title: 'Consigo desconto para negociar minha dívida?',
    description:
      'Sim! Além de poder parcelar em até 24x*, existe a possibilidade de você obter descontos de até 90%* para pagar o débito em atraso. *Mediante condições.',
    visible: false,
    ga4TagName: 'consigo-desconto-para-negociar-minha-divida?',
  },
  {
    title: 'Posso parcelar a minha fatura?',
    description:
      'Sim, você pode parcelar sua fatura por aqui, pelo app RCHLO ou pelo app Midway, além de ter a disposição a nossa central de relacionamento. É só ligar para 3004 5417 ou 0800 727 4417',
    visible: false,
    ga4TagName: 'posso-parcelar-a-minha-fatura?',
  },
  {
    title: 'Posso utilizar meu cartão após quitar ou parcelar minha dívida?',
    description:
      'Sim! Após quitar a sua fatura Riachuelo, o seu cartão pode ser desbloqueado para novas compras. Dependendo do tempo em atraso da dívida, pode ser necessária uma nova avaliação de crédito. Para mais detalhes, é só ligar para a nossa central de relacionamento, nos números 3004 5417 ou 0800 727 4417.',
    visible: false,
    ga4TagName:
      'posso-utilizar-meu-cartão-após-quitar-ou-parcelar-minha-dívida?',
  },
  {
    title: 'Posso solicitar a 2ª via do Boleto?',
    description:
      'Sim, você-consegue gerar a 2ª via do boleto por aqui. Caso precise, você também pode contar com a nossa central de relacionamento nos números 3004 5417 ou 0800 727 4417',
    visible: false,
    ga4TagName: 'posso-solicitar-a-2-via-do-boleto?',
  },
  {
    title: 'Por que utilizamos diferentes assessorias de cobrança?',
    description:
      'Para facilitar e agilizar nosso atendimento, trabalhamos com diversas assessorias de cobrança. Isso garante um serviço mais eficiente e rápido para você.',
    visible: false,
    ga4TagName: 'porque-utilizamos-diferentes-assessorias-de-cobranca',
  },
  {
    title: 'Como verificar a autenticidade da assessoria de cobrança?',
    description:
      'Para sua segurança, verifique se a assessoria que entrou em contato com você está na nossa lista oficial. <a href="https://blog.riachuelo.com.br/promocoes-e-regulamentos" target="_blank" style="word-break: break-all">[Confira aqui a lista de assessorias]</a>',
    visible: false,
    ga4TagName: 'como-verificar-a-autenticidade-da-assessoria-de-cobranca',
  },
  {
    title: 'O que fazer se a assessoria não estiver na lista?',
    description:
      'Se a assessoria que entrou em contato não estiver na nossa lista, entre em contato com a nossa central de atendimento, para verificar a autenticidade.',
    visible: false,
    ga4TagName: 'o-que-fazer-se-a-assessoria-não-estiver-na-lista',
  },
];

export const FaqSaqueAniversarioFGTS = [
  {
    id: 1,
    title: 'O que seria a antecipação do FGTS e suas vantagens ?',
    content: `
      O empréstimo com garantia no FGTS permite a antecipação do saldo do FGTS, sem precisar esperar o mês do seu aniversário pra resgatar. Você consegue antecipar seus próximos saque-aniversários e recebe todo o dinheiro na hora.
      <br><br>
      Aqui na Midway você conta com facilidade e agilidade no processo de contratação, que é 100% digital. Além disso, não possui restrição de crédito e não tem prestação mensal.
      `,
  },
  {
    id: 2,
    title: 'Como eu autorizo a Midway a consultar o saldo do meu FGTS?',
    content:
      '<b>Resposta:</b> O cliente deve acessar o APP FGTS, optar pela modalidade Saque Aniversário, e depois incluir a Midway como bancos autorizados a consultar o seu FGTS.',
  },
  {
    id: 3,
    title: 'Quem pode realizar a antecipação do FGTS na Midway ?',
    content:
      '<b>Resposta:</b> Clientes correntistas Midway, que possuem conta saldo no FGTS na modalidade saque aniversário e que tenha autorizado a Midway no APP do FGTS.',
  },
  {
    id: 4,
    title: 'Os valores das parcelas são iguais ?',
    content:
      '<b>Resposta:</b> Não, como trata-se de uma antecipação, o valor de cada parcela se altera de acordo com o disponível pela Caixa Econômica Federal no seu saldo do FGTS, por isso você irá observar que cada parcela tem um valor diferente da outra.',
  },
  {
    id: 5,
    title: 'Qual o Valor mínimo de contratação ?',
    content: `<b>Resposta:</b> O valor total mínimo do empréstimo é de R$ 200,00. É necessário também que o valor de cada saque a ser antecipado seja igual ou maior que R$200,00.`,
  },
  {
    id: 6,
    title: 'Em quanto tempo o valor cai na minha conta Midway ?',
    content: `<b>Resposta:</b> Em até 24 horas úteis o valor entre em sua conta Midway.`,
  },
  {
    id: 7,
    title: 'Como consulto o valor disponível para realizar a antecipação ?',
    content: `<b>Resposta:</b> Você pode realizar a consulta no próprio APP Midway, realizando a simulação do produto Antecipação do FGTS.`,
  },
  {
    id: 8,
    title: 'Como é realizado o pagamento das parcelas ?',
    content: `<b>Resposta:</b> Ao fazer a antecipação do saque-aniversário, você autoriza a Midway a bloquear o valor dos saques antecipados. Por isso, você não precisa se preocupar com o pagamento da parcela, ele será feito automaticamente pela Caixa no primeiro dia útil do mês do seu saque-aniversário.`,
  },
  {
    id: 10,
    title:
      'Qual é o processo para realizar o comando de cancelamento do empréstimo da antecipação do FGTS ?',
    content: `<b>Resposta:</b> Você pode solicitar o cancelamento da operação em até 7 dias corridos após a contratação. Para isso, é necessário ter o saldo disponível em sua conta Midway, onde o comando de Cancelamento pode ser feito direto no APP Midway. Após 7 dias não é mais possível realizar o cancelamento.`,
  },
];

export const faqCampanhaFgts = [
  {
    id: 1,
    title: 'O que seria a antecipação do FGTS e suas vantagens?',
    content:
      'O empréstimo com garantia no FGTS permite a antecipação do saldo do FGTS, sem precisar esperar o mês do seu aniversário pra resgatar. Você consegue antecipar seus próximos saque-aniversários e recebe todo o dinheiro na hora.Aqui na Midway você conta com facilidade e agilidade no processo de contratação, que é 100% digital. Além disso, não possui restrição de crédito e não tem prestação mensal.',
  },
  {
    id: 2,
    title: 'Como eu autorizo a Midway a consultar o saldo do meu FGTS?',
    content:
      'O cliente deve acessar o APP FGTS, optar pela modalidade Saque Aniversário, e depois incluir a Midway como bancos autorizados a consultar o seu FGTS.',
  },
  {
    id: 3,
    title: 'Quem pode realizar a antecipação do FGTS na Midway?',
    content:
      'Clientes correntistas Midway, que possuem conta saldo no FGTS na modalidade saque aniversário e que tenha autorizado a Midway no APP do FGTS.',
  },
  {
    id: 4,
    title: 'Os valores das parcelas são iguais?',
    content:
      'Não, como trata-se de uma antecipação, o valor de cada parcela se altera de acordo com o disponível pela Caixa Econômica Federal no seu saldo do FGTS, por isso você irá observar que cada parcela tem um valor diferente da outra.',
  },
  {
    id: 5,
    title: 'Qual o valor mínimo de contratação?',
    content:
      'O valor total mínimo do empréstimo é de R$ 200,00. É necessário também que o valor de cada saque a ser antecipado seja igual ou maior que R$200,00.',
  },
  {
    id: 6,
    title: 'Em quanto tempo o valor cai na minha conta Midway?',
    content: 'Em até 24 horas úteis o valor entre em sua conta Midway.',
  },
  {
    id: 7,
    title: 'Como consulto o valor disponível para realizar a antecipação?',
    content:
      'Você pode realizar a consulta no próprio APP Midway, realizando a simulação do produto Antecipação do FGTS.',
  },
  {
    id: 8,
    title: 'Como é realizado o pagamento das parcelas?',
    content:
      'Ao fazer a antecipação do saque-aniversário, você autoriza a Midway a bloquear o valor dos saques antecipados. Por isso, você não precisa se preocupar com o pagamento da parcela, ele será feito automaticamente pela Caixa no primeiro dia útil do mês do seu saque-aniversário.',
  },
  {
    id: 9,
    title: 'Como cancelar o empréstimo da antecipação do FGTS?',
    content:
      'Você pode solicitar o cancelamento da operação em até 7 dias corridos após a contratação. Para isso, é necessário ter o saldo disponível em sua conta Midway, onde o comando de Cancelamento pode ser feito direto no APP Midway. Após 7 dias não é mais possível realizar o cancelamento.',
  },
];

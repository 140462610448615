import styled from 'styled-components';

export const FaqSection = styled.div`
  background-color: #f7f7f7;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;

  .title {
    font-weight: 500px;
    font-size: 32px !important;
    line-height: 38px;
    margin-bottom: 40px;
  }

  .questions {
    .accordion {
      border: 1px solid #221f20;
      background: #fff;
      margin-bottom: 0;
      .accordion-title {
        margin-left: 0;
      }
    }

    .accordion-content.active {
      border-color: #221f20;
    }

    .accordion-content {
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      position: relative;
      top: -8px;
      border: 1px solid transparent;
      &:before {
        content: '';
        width: calc(100% - 32px);
        margin-left: 16px;
        margin-right: 16px;
        height: 1px;
        background-color: #cacecd;
        position: absolute;
      }
    }
  }
`;

import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const SocialLinksWrapper = styled.div`
  margin: 2rem auto;
  width: 100%;
  @media (max-width: 767px) {
    margin: 17px auto;
  }
`;

export const SocialLinksList = styled.ul`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  list-style: none;
  max-width: 180px;
`;

export const SocialLinksItem = styled.li``;

export const SocialLinksLink = styled.div`
  color: ${props => (props.color ? props.color : '#fff')};
  text-decoration: none;
  transition: color 0.5s;
  cursor: pointer;
  &:hover {
    color: ${props =>
      props.lighten ? lighten(0.1, props.color) : darken(0.2, props.color)};
  }

  &:focus,
  &:hover {
    border: 1px solid white;
    border-radius: 5px;
  }
`;

export const IconWrapper = styled.div`
  fill: #bbb;
  width: 30px;
  height: 30px;
`;

import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import Container from '~/components/NewUi/Container';
import { CardProduct, Typography } from '~/components/NewUi';
import {
  ItemsContainer,
  ItemsTrail,
  NavButton,
  NavigationContainer,
  PageWrapper,
} from './style';
import items from './data';

const InsuranceAndAssistance = () => {
  const sliderRef = useRef();

  const handleNextSlide = () => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrevSlide = () => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    accessibility: true,
    className: 'SliderRoot',
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    centerPadding: '10px',
    slideWidth: '273px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <PageWrapper>
      <Container>
        <Typography variant="h2" styleType="title">
          Seguros e Assistências
        </Typography>
        <Typography variant="p" color="#616B6A" fontSize="14px">
          Mais proteção para o que é importante para você
        </Typography>
      </Container>
      <ItemsContainer>
        <Container>
          <ItemsTrail>
            <Slider {...settings} ref={sliderRef}>
              {items.map(item => (
                <CardProduct
                  className="CardCarouselRoot"
                  key={item.key}
                  title={item.title}
                  description={item.description}
                  actionText="Conhecer"
                  aria-label={item.title}
                  link={item.link}
                />
              ))}
            </Slider>
            <NavigationContainer aria-label="Controles do carrousel de seguros e assistências">
              <NavButton
                aria-label="Anterior"
                role="button"
                onClick={handlePrevSlide}
              >
                <IconArrowLeft />
              </NavButton>
              <NavButton
                aria-label="Proximo"
                role="button"
                onClick={handleNextSlide}
              >
                <IconArrowRight />
              </NavButton>
            </NavigationContainer>
          </ItemsTrail>
        </Container>
      </ItemsContainer>
    </PageWrapper>
  );
};

export default InsuranceAndAssistance;

import { trackingSelectContent } from '~/analytics';
import { contentType, flow } from '../../../../analytics/home';

const faq = [
  {
    title: 'Como posso consultar a fatura do Cartão Riachuelo?',
    content: [
      'Você pode consultar a fatura fechada e os últimos lançamentos do seu Cartão Riachuelo no app Riachuelo. Disponível na APP Store (iOS) e no Google Play (Android).',
      'No seu 1° acesso, é só clicar na opção Cartões e preencher as informações solicitadas até a confirmação do vínculo.',
      'Se preferir, baixe o aplicativo Midway. Lá, além de acesso a fatura, você tem a Conta Digital da Riachuelo, com saldo rendendo e vários outros benefícios. Disponível na APP Store (iOS) e no Google Play (Android).',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanICheckMyRiachueloCardInvoice,
        flow.home
      );
    },
  },
  {
    title: 'Como posso desbloquear meu Cartão Riachuelo?',
    content: [
      'No aplicativo Riachuelo (disponível para Android e iOS), realizando o vínculo do cartão, você pode desbloquear o seu Cartão Riachuelo.',
      'Importante! Se você ainda não cadastrou o cartão no aplicativo Riachuelo, por segurança, será necessário realizar o vínculo de cartão.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanIUnBlockMyRiachueloCard,
        flow.home
      );
    },
  },
  {
    title: 'Como aumentar o limite do meu Cartão Riachuelo?',
    content: [
      'O aumento de limite do Cartão Riachuelo ocorre automaticamente. Pagar a fatura de acordo com o prazo de vencimento e usar o cartão com frequência são formas de aumentar as suas chances.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowToIncreaseTheLimitOfMyRiachueloCard,
        flow.home
      );
    },
  },
  {
    title: 'Como posso pagar a minha fatura do Cartão Riachuelo?',
    content: [
      'Se a sua fatura estiver fechada e no prazo de vencimento, clique aqui para gerar e copiar o número do código de barras (linha digitável). Basta informar o seu CPF e data de nascimento.',
      'Se preferir, consulte a fatura e gere o boleto no aplicativo Riachuelo. Disponível na APP Store (iOS) e no Google Play (Android).',
      'No seu 1° acesso, é só clicar na opção Cartões e preencher as informações solicitadas até a confirmação do vínculo.',
      'Ou ainda, baixe o aplicativo Midway. Lá, além de acesso, você pode parcelar e fatura e ainda tem a Conta Digital da Riachuelo, com saldo rendendo e vários outros benefícios. Disponível na APP Store (iOS) e no Google Play (Android).',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanIPayMyInvoiceForTheRiachueloCardInInstallment,
        flow.home
      );
    },
  },
  {
    title: 'Como posso parcelar a minha fatura do Cartão Riachuelo?',
    content: [
      'Se a sua fatura estiver fechada e no prazo de vencimento, você poderá parcelar o pagamento no aplicativo Riachuelo. Disponível na APP Store (iOS) e no Google Play (Android).',
      'No seu 1° acesso, é só clicar na opção Cartões e preencher as informações solicitadas até a confirmação do vínculo.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanIPayMyRiachueloCardInvoice,
        flow.home
      );
    },
  },
  {
    title: 'Como posso consultar o limite do Cartão Riachuelo?',
    content: [
      'Você pode consultar o limite do seu Cartão Riachuelo no aplicativo Riachuelo. Disponível na APP Store (iOS) e no Google Play (Android).',
      'No seu 1° acesso, é só clicar na opção Cartões e preencher as informações solicitadas até a confirmação do vínculo.',
      'Ou ainda, baixe o aplicativo Midway. Lá, além de acesso ao limite, você tem a Conta Digital da Riachuelo, com saldo rendendo e vários outros benefícios. Disponível na APP Store (iOS) e no Google Play (Android).',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanICheckTheRiachueloCardLimit,
        flow.home
      );
    },
  },
  {
    title: 'Como posso alterar a senha do Cartão Riachuelo?',
    content: [
      'Você pode alterar a senha do seu cartão em uma das Lojas Riachuelo. Vá ao ATM - Terminal de Autoatendimento - insira o cartão, selecione a opção Troca de senha e siga o passo a passo indicado. Para consultar o endereço da loja mais próxima, acesse riachuelo.com.br/lojas.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanIChangeMyRiachueloCardPassword,
        flow.home
      );
    },
  },
  {
    title: 'O que fazer em caso de perda, roubo ou furto do Cartão Riachuelo?',
    content: [
      'Em caso de perda, roubo ou furto do seu cartão Riachuelo solicite o bloqueio por telefone imediatamente. Em capitais e regiões metropolitanas, ligue para 3004 5417. Nas demais localidades, ligue para 0800 727 4417.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqWhatToDoIfYourRiachueloCardIsLostStolenOrStolen,
        flow.home
      );
    },
  },
  {
    title: 'Como posso solicitar um Cartão Riachuelo?',
    content: [
      'Você pode solicitar um Cartão Riachuelo de uso exclusivo nas lojas no aplicativo Riachuelo. Disponível na APP Store (iOS) e no Google Play (Android)',
      'Se preferir, você também pode solicitar nas Lojas Riachuelo, apresentando documento oficial com foto e CPF. Para consultar o endereço da loja mais próxima.',
      '<b>Importante</b>: para solicitar o Cartão Riachuelo Visa ou Mastercard, você precisa ter um Cartão Riachuelo de uso exclusivo nas lojas. Então, no aplicativo Riachuelo, você consegue consultar se a mudança está disponível.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanIRequestARiachueloCard,
        flow.home
      );
    },
  },
  {
    title: 'Como posso pagar a parcela do meu empréstimo?',
    content: [
      'Se você contratou o empréstimo na loja física ou por telefone, clique aqui para gerar e copiar o número do código de barras (linha digitável). É só informar o seu CPF e data de nascimento.',
      'Se preferir, baixe o aplicativo Midway. Lá, além de Empréstimos, você tem a Conta Digital da Riachuelo, com saldo rendendo e vários outros benefícios. Disponível na APP Store (iOS) e no Google Play (Android).',
      'Importante: se você contratou o empréstimo no aplicativo Midway, o pagamento é em débito automático na sua conta digital. Lembre-se de ter saldo suficiente no dia do vencimento.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanIPayMyLoanInstallMent,
        flow.home
      );
    },
  },
  {
    title: 'Como posso negociar minha dívida de empréstimo?',
    content: [
      'Se você estiver com dificuldade de pagar as parcelas, é possível renegociar a dívida no site Mi Ajuda, informando CPF e data de nascimento. Ou ainda negociar direto pelo nosso WhatsApp.Se preferir, também pode negociar por telefone. Em capitais e regiões metropolitanas, ligue para 3004 5417. Nas demais localidades, ligue para 0800 727 4417.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanINegotiateMyLoanDebt,
        flow.home
      );
    },
  },
  {
    title: 'Como posso simular e contratar um empréstimo?',
    content: [
      'Você pode simular e contratar Empréstimos no aplicativo Midway. Disponível na APP Store (iOS) e no Google Play (Android). Lá, além de Empréstimos, você tem a Conta Digital da Riachuelo, com saldo rendendo e vários outros benefícios. Disponível na APP Store (iOS) e no Google Play (Android).',
      'Se preferir, pode simular e contratar por telefone. Em capitais e regiões metropolitanas, ligue para 3004 5417. Nas demais localidades, ligue para 0800 727 4417.',
      'Ou ainda, você pode simular e contratar nas Lojas Riachuelo, apresentando documento oficial com foto, CPF e o seu cartão. Para consultar o endereço da loja mais próxima acesse riachuelo.com.br/lojas.',
    ],
    onClickFn: () => {
      trackingSelectContent(
        contentType.faqHowCanISimulateAndTakeOutALoan,
        flow.home
      );
    },
  },
];

export default faq;

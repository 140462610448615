import React from 'react';
import PropTypes from 'prop-types';
import { DrawerItems, Button } from '@midway/web-ui-component';
import { menuItems } from '~/constants';
import * as S from './styles';

export default function Card({ closeDrawer }) {
  return (
    <S.Container>
      <DrawerItems items={menuItems} closeDrawer={closeDrawer} />
      <S.Button>
        <Button
          title="Pagamentos"
          state="white"
          outline
          size="lg"
          aria-label="Pagamentos"
          onClick={() => {
            window.open('https://www.midway.com.br/consulta-boleto', '_blank');
          }}
        />
      </S.Button>
    </S.Container>
  );
}

Card.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
};

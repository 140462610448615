import axios from 'axios';

const tenant = process.env.REACT_APP_CMS_TENANT || 'MIDWAY';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_SITE_CMS_URL,
  headers: {
    'content-type': 'application/json',
    businessId: tenant,
    originId: tenant,
  },
});

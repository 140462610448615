import React from 'react';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

import Cards from './components/Cards';
import AccordionInfo from './components/AccordionInfo';

import CoinIcon from '../../assets/img/loan-facilitated/coin.svg';
import WhiteWhatsappIcon from '../../assets/img/loan-facilitated/white_whatsapp.svg';
import WhatsappIcon from '../../assets/img/loan-facilitated/whatsapp.svg';
import LojaIcon from '../../assets/img/loan-facilitated/loja.svg';
import PorcenIcon from '../../assets/img/loan-facilitated/porcentagem.svg';
import CardsIcon from '../../assets/img/loan-facilitated/cards.svg';
import CheckIcon from '../../assets/img/loan-facilitated/check.svg';

import BannerDesktop from '../../assets/img/loan-facilitated/banner.png';
import BannerMobile from '../../assets/img/loan-facilitated/banner_mobile.png';

const loanFacilitated = () => {
  const anoCorrente = new Date().getFullYear();

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.Main>
          <S.BannerContainer>
            <S.BannerDesktop src={BannerDesktop} />
            <S.BannerMobile src={BannerMobile} />
            <S.OverlayBanner>
              <S.TextBanner>
                {' '}
                <S.TextBold fontSize="36px">
                  precisando de um alívio?
                </S.TextBold>{' '}
                conte com o Empréstimo Pessoal Midway rápido e fácil!
              </S.TextBanner>
              <S.ButtonCardBanner
                onClick={() =>
                  trackingSelectContent(
                    'banner:contratar-via-whatsapp',
                    'emprestimo-facilitado'
                  )
                }
                href="https://wa.me/message/FZU6SWZOZUD3E1"
                color="#fff"
                backgroundColor="#A06D2C"
              >
                <S.IconCardBanner src={WhiteWhatsappIcon} />
                Contratar via whatsapp
              </S.ButtonCardBanner>
              <S.ButtonCardBanner
                onClick={() =>
                  trackingSelectContent(
                    'banner:encontrar-lojas-proximas',
                    'emprestimo-facilitado'
                  )
                }
                href="https://www.midway.com.br/buscar-lojas"
              >
                <S.IconCardBanner src={LojaIcon} />
                Encontrar lojas próximas
              </S.ButtonCardBanner>
            </S.OverlayBanner>
          </S.BannerContainer>
          <S.CentralizeContent>
            <S.ContentTextCard>
              <S.ContentTop>
                <S.TitleContent>
                  Transforme seus sonhos em realidade com o Empréstimo Pessoal
                  Midway!
                </S.TitleContent>
                <S.TextContent>
                  A oportunidade ideal para colocar seus planos em prática
                </S.TextContent>
                <S.TextContent>
                  Quitar suas dívidas, investir em um projeto pessoal ou viajar.
                  Seja qual for o seu sonho, o Empréstimo Pessoal Midway é a sua
                  solução.
                </S.TextContent>
              </S.ContentTop>
              <S.ContentBottom>
                <S.OverlayCards />
                <S.ContainerCards>
                  <Cards
                    icon={CoinIcon}
                    title="Limite de até"
                    description="R$ 17.000"
                  />
                  <Cards
                    icon={CoinIcon}
                    title="Pagamento em até"
                    description="36 vezes"
                  />
                  <Cards
                    icon={CoinIcon}
                    title="Comece a pagar em"
                    description="até 90 dias"
                  />
                </S.ContainerCards>
              </S.ContentBottom>
            </S.ContentTextCard>
            <S.ContainerCardBlackTop>
              <S.CardLeft>
                <S.CardTitle>Contrate pelo WhatsApp</S.CardTitle>
                <S.CardText>
                  Converse com nossa equipe de especialistas para fazer a
                  contratação do empréstimo
                </S.CardText>
                <S.ButtonCard
                  onClick={() =>
                    trackingSelectContent(
                      'contrate-pelo-whatsapp:falar-com-especialista',
                      'emprestimo-facilitado'
                    )
                  }
                  href="https://wa.me/message/FZU6SWZOZUD3E1"
                >
                  <S.IconCard src={WhatsappIcon} />
                  Falar com especialistas
                </S.ButtonCard>
              </S.CardLeft>
              <S.CardRight>
                <S.CardTitle>Contrate em uma Loja Riachuelo</S.CardTitle>
                <S.CardText>
                  Visite uma de nossas lojas e contrate com nossa equipe de
                  atendentes
                </S.CardText>
                <S.ButtonCard
                  onClick={() =>
                    trackingSelectContent(
                      'contrate-em-uma-loja-riachuelo:falar-com-especialistas',
                      'emprestimo-facilitado'
                    )
                  }
                  href="https://wa.me/message/FZU6SWZOZUD3E1"
                >
                  <S.IconCard src={LojaIcon} />
                  Falar com especialistas
                </S.ButtonCard>
              </S.CardRight>
            </S.ContainerCardBlackTop>
            <S.ContentCardBottom>
              <S.IconInfoBlock>
                <S.AgroupInfo>
                  <S.IconBlock src={CheckIcon} />
                  <S.TitleBlock>Não precisa de garantia</S.TitleBlock>
                </S.AgroupInfo>
                <S.descriptionBlock>
                  Não pedimos bens, como carros e imóveis de garantia.
                </S.descriptionBlock>
              </S.IconInfoBlock>
              <S.IconInfoBlock>
                <S.AgroupInfo>
                  <S.IconBlock src={CardsIcon} />
                  <S.TitleBlock>Condições feitas para você</S.TitleBlock>
                </S.AgroupInfo>
                <S.descriptionBlock>
                  Crédito de acordo com sua necessidade, prazo flexível para
                  iniciar o pagamento em até 90 dias!
                </S.descriptionBlock>
              </S.IconInfoBlock>
              <S.IconInfoBlock>
                <S.AgroupInfo>
                  <S.IconBlock src={PorcenIcon} />
                  <S.TitleBlock>Com taxas de juros fixa</S.TitleBlock>
                </S.AgroupInfo>
                <S.descriptionBlock>
                  Justo e transparente! Você não tem surpresas na hora de pagar
                  as parcelas.
                </S.descriptionBlock>
              </S.IconInfoBlock>
            </S.ContentCardBottom>
            <S.ContentAccordion>
              <S.ContainerAccordionTitle>
                <S.AccordionTitle>
                  Saiba mais sobre o Empréstimo Midway
                </S.AccordionTitle>
              </S.ContainerAccordionTitle>
              <AccordionInfo />
              <S.ContentBottomAccordion>
                <S.AnchorTitle
                  onClick={() =>
                    trackingSelectContent(
                      'acesse-nossa-pagina-principal-e-encontre-mais-informacoes',
                      'emprestimo-facilitado'
                    )
                  }
                  href="https://www.midway.com.br/"
                >
                  Acesse nossa página principal e encontre mais informações!{' '}
                </S.AnchorTitle>
                <S.TextContentBottom>
                  O cliente tem a possibilidade de transferir a dívida de um
                  empréstimo ou financiamento contratado em uma determinada
                  instituição financeira para outra. Com a transferência, a taxa
                  de juros pode sofrer alterações, mas o valor e o prazo do
                  contrato devem ser idênticos ao saldo devedor e ao prazo
                  remanescente da operação.
                </S.TextContentBottom>
                <S.TextSecondaryBottom>
                  Caso o cliente queira fazer a portabilidade de seus contratos
                  para outro banco é necessário ir até a agência onde fez o
                  empréstimo para solicitar o saldo devedor e os dados do
                  contrato, conforme estabelece a Resolução 4.292, do Bacen.
                </S.TextSecondaryBottom>
                <S.FinalText>
                  <S.FinalTextBold fontSize="12px">
                    {' '}
                    © Copyright {anoCorrente}. Todos os direitos reservados
                    Midway
                  </S.FinalTextBold>{' '}
                  CNPJ 09.464.032/0001-12
                  <br />
                  R. Lemos Monteiro, 120 - Butantã, São Paulo - SP - Brasil -
                  CEP: 05501-050
                </S.FinalText>
              </S.ContentBottomAccordion>
            </S.ContentAccordion>
          </S.CentralizeContent>
        </S.Main>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default loanFacilitated;

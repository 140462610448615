import React from 'react';
import iconError from '~/assets/img/icons/internet_error.svg';
import * as S from './styles';

export default function Error({ errorTitle, errorBody, refreshPage }) {
  return (
    <S.Container>
      <div className="error-body">
        <img src={iconError} alt="Ícone de erro" />
        <p>{errorTitle}</p>
        <span>{errorBody}</span>
        {refreshPage ? <span>Tente atualizar a tela novamente.</span> : null}
      </div>
    </S.Container>
  );
}

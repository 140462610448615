import React, { useState } from 'react';
import { Image, Button, Breadcrumb } from '@midway/web-ui-component';
import Collapse from 'react-bootstrap/Collapse';
import { useSelector } from 'react-redux';
import { generate } from 'shortid';

import { starupsCategories, keyboard } from '~/constants';
import history from '~/services/history';
import Midway from '~/assets/img/BePartOfIt/midway.png';
import RCHLO from '~/assets/img/BePartOfIt/rchlo.png';

import BannerSection from '~/components/BannerSection';
import * as S from './styles';

const Startup = () => {
  const [isOpen, setIsOpen] = useState(starupsCategories.map(() => false));
  const { isTabbing } = useSelector(state => state.accessibility);

  const checkOpen = i =>
    setIsOpen(isOpen.map((elm, ind) => (ind === i ? !isOpen[i] : false)));

  const MountStartupsCategories = () => {
    return starupsCategories.map((elm, i) => (
      <div key={generate()}>
        <S.DropDown
          aria-label={`${elm.categorie}, clique para expandir`}
          onClick={() => checkOpen(i)}
          onFocus={() => {}}
          onKeyPress={event => {
            event.preventDefault();
            const { charCode } = event;
            if (charCode === keyboard.ENTER || charCode === keyboard.SPACE) {
              checkOpen(i);
            }
          }}
        >
          <span>{elm.categorie}</span>
          <S.Arrow />
        </S.DropDown>
        <Collapse in={isOpen[i]} timeout={1000}>
          <S.List>
            {elm.subCategories.map(item => (
              <li>{item.title}</li>
            ))}
          </S.List>
        </Collapse>
      </div>
    ));
  };

  return (
    <>
      <Breadcrumb
        routeBack="/faca-parte"
        textRouteBack="Faça parte"
        actualPageText="Cadastre sua Startup"
        history={history}
      />
      <div
        className="container-fluid"
        style={{ backgroundColor: '#fff' }}
        id="ready-page-for-screen-reader"
      >
        <S.Container className="container">
          <div>
            <h1>Tem uma startup?</h1>
            <p>Queremos ver o seu trabalho!</p>
          </div>
          <S.Content>
            <S.FirstSection className="row">
              <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                <Image src={Midway} alt="Imagem da recepção da Midway." />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                <p>
                  A Midway está em busca de parceiros que queiram crescer junto
                  com a gente.
                </p>
                <Button
                  block
                  aria-label="Clique para cadastrar sua Startup"
                  title="Cadastre sua Startup"
                  onClick={() => {
                    history.push('/faca-parte/startup#registration-form');
                    if (isTabbing) {
                      const element =
                        document.getElementById('registration-form');
                      if (element) {
                        element.focus();
                      }
                    }
                  }}
                />
              </div>
            </S.FirstSection>
            <S.SecondSection className="row flex-column-reverse flex-lg-row">
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <h2>Quem somos?</h2>
                <p>
                  O LAB Riachuelo nasceu com o objetivo de conectar o Grupo
                  Guararapes com as melhores e mais inovadoras tecnologias do
                  mercado. Através de ações constantes de pesquisa e
                  desenvolvimento, nosso time estrutura diferentes iniciativas
                  que otimizam a experiência de nossos clientes, colaboradores e
                  parceiros em todas as nossas vertentes de negócio: Varejo,
                  Banco, Financeira, Fábricas, Têxtil, Transportadoras, Centros
                  de Distribuição, Call Center e Empreendimentos.
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <Image
                  src={RCHLO}
                  alt="Imagem de uma das lojas da Riachuelo, mostrando uma nova coleção de roupas."
                />
              </div>
            </S.SecondSection>
          </S.Content>
        </S.Container>
      </div>
      <BannerSection>
        <div>
          <S.BannerTitle>
            <h2>{`Olhamos e buscamos\nno futuro soluções\npara o presente.`}</h2>
          </S.BannerTitle>
          <S.BannerDescription>
            <p>
              Hoje, com mais de 300 lojas e mais de 40 mil funcionários, somos a
              maior empresa de moda do Brasil e uma das três maiores redes de
              varejo do País. Queremos conectar nossos negócios ao mundo das
              startups, conhecer produtos e entender propostas. Queremos chegar
              mais longe e formar novas parcerias. Se você acredita que a sua
              startup propõe entrega de valor em viés inovador, cadastre-se para
              que possamos conhecer melhor você e o seu trabalho.
            </p>
          </S.BannerDescription>
        </div>
      </BannerSection>
      <div className="container-fluid" style={{ backgroundColor: '#fff' }}>
        <S.Container className="container">
          <S.FourthSection>
            <h2>Áreas de interesse</h2>
            <p>
              Se sua startup atua em algumas das áreas listadas abaixo e possui
              tecnologias inovadoras, cadastre-se!
            </p>
            <MountStartupsCategories />
          </S.FourthSection>
          <S.FifthSection
            id="registration-form"
            aria-label="Cadastre-se no formulário abaixo, peça ajuda caso necessite, pois ainda estamos trabalhando na acessibilidade dele."
          >
            <h2>Cadastre-se</h2>
            <iframe
              title="cadatre a sua startup"
              src="https://rchlo.typeform.com/to/KazcVy?typeform-embed=embed-widget&amp;typeform-embed-id=ofkug"
            />
          </S.FifthSection>
        </S.Container>
      </div>
    </>
  );
};

export default Startup;

import styled from 'styled-components';

export const Forms = styled.div`
  height: 340px;
  @media (max-width: 768px) {
    height: fit-content;
  }

  input {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 10px;
    margin-top: 30px;

    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 1px;
  }

  input::placeholder {
    color: #000000;
    font-size: 16px !important;
  }

  h1 {
    font-weight: bold;
    color: #000000;
    font-size: 32px !important;

    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }
  h2 {
    font-size: 18px !important;
    color: #606060;
    padding: 10px 0px 0px 0px;
  }
  span {
    font-weight: bold;
  }
`;

export const WrapperButton = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  padding-right: 150px;

  @media (max-width: 767px) {
    justify-content: center;
    padding: 0;
  }
`;

export const Button = styled.button`
  min-height: 48px;
  color: white;
  border: none;
  border-radius: 8px;
  min-width: 161px;
  background-color: #00726d;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;

  @media (max-width: 767px) {
    min-width: 152px;
  }
`;
export const ButtonActive = styled.button`
  min-height: 48px;
  color: white;
  border: none;
  border-radius: 8px;
  min-width: 161px;
  background-color: #004c4d;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;

  @media (max-width: 767px) {
    min-width: 152px;
  }
`;

export const OtherValue = styled.div`
  width: 50%;
  margin-top: 40px;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h6 {
    font-size: 14px !important;
  }

  input {
    width: 100%;
    font-size: 16px !important;
    margin-top: 0px;
    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 1px;

    @media (max-width: 767px) {
      line-height: 2.5;
    }
  }
`;

import styled from 'styled-components';

import Phone from '~/assets/img/ethic-governance/mids-telephone.svg';
import Chevron from '~/assets/img/ethic-governance/chevron.svg';

export const ContainerPage = styled.div`
  .title {
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 40px;
  }
`;

export const Section = styled.section`
  background-color: #fff;
  padding-bottom: 40px;
  padding-left: 0;
  padding-right: 0;
  p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #4d4d4d;
    padding-bottom: 30px;
    strong {
      font-weight: 600;
    }

    a {
      color: #ff5224;
    }
    a.decorated {
      text-decoration: underline;
    }

    .cardsImage {
      margin-top: -30px;
    }
  }
  @media (max-width: 992px) {
    p .cardsImage {
      margin-top: 30px;
    }
  }
`;

export const Card = styled.div`
  border: 3.49px solid #dae5e3;
  border-radius: 18.06px;
  padding: 25px 25px 0;
  min-height: 220px;

  @media (max-width: 992px) {
    margin-bottom: 10px;
    p {
      font-size: 16px;
    }
  }
`;

export const Banner = styled.div`
  background-color: #f7f7f7;
  padding: 50px 0;

  .contentBanner {
    padding: 50px 0 50px 100px;
    h2 {
      font-family: Montserrat;
      font-size: 40px;
      font-weight: 900;
      line-height: 49px;
      margin-bottom: 25px;
    }

    @media (max-width: 992px) {
      padding: 10px;
    }
  }
`;

export const Container = styled.div`
  @media (max-width: 992px) {
    padding-top: 30px;
  }
`;

export const ServiceCenterLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #4d4d4d !important;

  &::before {
    content: ' ';
    width: 36px;
    height: 36px;
    background-image: url(${Phone});
    display: flex;
    margin-right: 10px;
  }
  &::after {
    content: ' ';
    width: 17px;
    height: 33px;
    background-image: url(${Chevron});
    display: flex;
    margin-left: 10px;
  }

  @media (max-width: 992px) {
    font-size: 16px;
    &::after {
      width: 11px;
      height: 21px;
      background-size: contain;
    }
  }
`;

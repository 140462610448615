import styled from 'styled-components';
import TransicaoWhatsApp from '~/assets/img/NegotiationCampaign/redirectWhatsApp/transicao-whatsapp.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.div`
  display: flex;
  background-image: url(${TransicaoWhatsApp});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 600px;
  margin: 40px auto;

  @media (max-width: 900px) {
    height: 450px;
  }
`;

import React from 'react';
import * as S from './styles';

export const CardAssistanceImageIcon = ({ imagePath, imageAlt }) => {
  return (
    <S.CardImageIconContainer>
      <S.CardImageIcon src={imagePath} alt={imageAlt} className="card-image" />
    </S.CardImageIconContainer>
  );
};

export default CardAssistanceImageIcon;

import styled, { css } from 'styled-components';

import { colors } from '~/constants';

export const Container = styled.section`
  background-color: ${colors.white};
  padding: 144px 16px;

  @media (max-width: 900px) {
    margin: 0px;
    padding: 40px 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1176px;
  width: 100%;
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 600px;
  width: 100%;

  @media (max-width: 900px) {
    text-align: left;
    max-width: none;
  }
`;

export const Title = styled.h2`
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 40px !important;
  margin-bottom: 16px;
  width: 100%;
  text-align: left;

  @media (max-width: 720px) {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: left !important;
    width: 100%;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  text-align: left;
`;

export const SearchStore = styled.div`
  max-width: 384px;
  width: 100%;
  border-radius: 40px;
  padding: 16px 24px;
  background: ${colors.light_gray};
  height: 56px;

  display: flex;
  justify-content: center;

  ${({ focused }) =>
    focused &&
    css`
      border: 1px solid ${colors.chumbo};
    `}

  @media (max-width: 900px) {
    margin-top: 48px;
    max-width: none;
  }

  @media (max-width: 720px) {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: left !important;
  }
`;

export const Input = styled.input`
  border: none;
  outline: none;
  background: inherit;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500 !important;

  &::placeholder {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500 !important;
  }
`;

export const SearchIcon = styled.img`
  margin-left: auto;
  width: 20px;
  height: 20px;
`;

export const TableContainer = styled.div`
  border: 1px solid ${colors.dark_gray_2};
  border-radius: 8px;
  margin-top: 64px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (max-width: 720px) {
    margin-top: 32px;
    margin-bottom: 0px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 0.5rem;
  border-radius: 8px;
`;

export const Head = styled.th`
  background: ${colors.dark_gray_2};
  color: ${colors.gray800};
  font-weight: 600;
  padding: 16px 22px;
  text-align: left;
  font-size: 16px;
  line-height: 20px;
`;

export const TableHead = styled.thead`
  tr {
    th {
      background-color: ${colors.light_gray};
    }
  }
`;

export const TableBody = styled.tbody`
  tr {
    td {
      &:nth-child(3) {
        width: 160px;
        text-transform: uppercase;
      }
      &:last-child {
        width: 160px;
      }
    }
  }
`;

export const Row = styled.tr`
  border-bottom: 1px solid ${colors.dark_gray_2};

  &:last-child {
    border-bottom: none;
  }

  td {
    padding: 16px 22px;
    border: 0;
    color: ${colors.gray800};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    &:last-child,
    a {
      color: ${colors.orangeRoof};

      &:hover,
      &:visited,
      &:active {
        color: ${colors.orangeRoof};
        text-decoration: underline;
      }
    }
  }
`;

export const Span = styled.span`
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  @media (max-width: 720px) {
    margin-top: 32px;
  }

  .ant-pagination {
    .ant-pagination-item-link {
      color: ${colors.orangeRoof};
      border: none;
    }

    .ant-pagination-item {
      border: none;
      color: ${colors.dark_gray_2};
      vertical-align: baseline;

      &:hover {
        a {
          color: ${colors.dark_gray_2};
        }
      }

      a {
        line-height: unset;
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        background: ${colors.dark_gray_2};
      }
    }

    .ant-pagination-item-active {
      border-radius: 100px;
      font-weight: 700 !important;

      &:hover {
        a {
          color: ${colors.orangeRoof};
        }
      }

      a {
        background: ${colors.orangeRoof};
        color: ${colors.orangeRoof};
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
      }
    }
  }
`;

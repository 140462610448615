export const mockData = [
  {
    name: 'BannerAssistenciaAutomovel',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-lp-assistencia-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-lp-assistencia-desktop.png',
    title: 'Conte com nossa assistência auto em cada quilômetro da sua jornada',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/assistencia-automovel',
  },
  {
    name: 'BannerAssistenciaResidencia',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-mobile-residencia.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-assis-residencia.png',
    title:
      'Sua casa sempre em boas mãos. Conte com nossa assistência resiDÊNCIA 24h',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/assistencia-residencial',
  },
  {
    name: 'BannerAssistenciaMoto',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-mobile-moto.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-assis-moto.png',
    title: 'Sua moto merece o melhor cuidado. Conte com nossa assistência 24h',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/assistencia-moto',
  },
];

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { first, isEmpty, orderBy } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import { gtagSelectContent, gtagViewContent } from '~/utils/TaggingGA4';
import ContainerFadeIn from '../../ContainerFadeIn';
import CardContractsItem from './CardContractsItem';
import Help from '../../Help';
import DescriptionHelp from '../../DescriptionHelp';
import { Container, HelperButton, WhatsAppIcon } from './styles';

const GTAG_CONTENT_PAGE = 'selecione-o-contrato-que-deseja-negociar';
const GTAG_FLOW = 'miajuda';
const tagGa4Button = (content_type, params) => {
  gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, content_type, params);
};

const Contracts = () => {
  const {
    negotiationData,
    eligibility,
    setHistoryPages,
    setNegotiationData,
    duplicateData,
    clientData,
    setSystem,
    setNonNegotiableContracts,
    setHideAgreement,
  } = useNegotiation();
  const { contracts } = negotiationData;
  const [helperActive, setHelperActive] = useState(false);

  const verifyAlredyExistsElegibility = (type, contract) => {
    const system = first(
      contracts?.filter(c => c.contract === contract)
    )?.system;
    setSystem(system);

    if (system === 'TSYS') {
      setNonNegotiableContracts({
        has: true,
        contracts: contracts.filter(contrac => contrac?.system === system),
      });
      return;
    }
    if (
      negotiationData?.selectedContract?.contract === contract &&
      negotiationData?.registered
    ) {
      setHistoryPages(prevHistory => [...prevHistory, 'eligibility']);
      return;
    }
    if (negotiationData?.selectedContract?.contract) {
      const newContracts = negotiationData.contracts;
      setNegotiationData({ contracts: newContracts });
    }
    if (!isEmpty(duplicateData)) {
      const duplicated = duplicateData?.filter(
        billet => billet.contract === contract
      );
      if (!isEmpty(duplicated)) {
        const selected = first(
          contracts.filter(newContract => newContract.contract === contract)
        );
        setNegotiationData({ ...negotiationData, selectedContract: selected });
        eligibility({
          typeSystem: type,
          contractProps: contract,
          hasDuplicate: true,
          dn: clientData?.document,
          system,
        });
        return setHistoryPages(prevHistory => [...prevHistory, 'duplicate']);
      }
    }

    eligibility({
      typeSystem: type,
      contractProps: contract,
      dn: clientData?.document,
      system,
    });
  };

  const newContracts = orderBy(contracts, ['overdueDays'], ['desc']);
  const gtagParams = {
    proposta: negotiationData?.gaParamProposta,
  };

  const handleHelperButton = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'lp-midway:quitacao-de-divida',
      eventAction: 'view',
      eventLabel: 'redirect-CCR',
    });
    tagGa4Button('precisa-de-ajuda?', gtagParams);
    setHelperActive(true);
  };

  useEffect(() => {
    if (newContracts?.length > 2) {
      setHelperActive(true);
      return;
    }

    gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW, gtagParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContracts?.length]);

  useEffect(() => {
    setHideAgreement(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerFadeIn>
      <Container>
        {!helperActive ? (
          <>
            <h3 className="bold">Selecione o contrato que deseja negociar</h3>
            <span>É necessário negociar um contrato por vez.</span>

            {newContracts?.map(contract => {
              const days = contract?.overdueDays;
              const description =
                days <= 0
                  ? `Em dia`
                  : `em atraso (desde ${moment()
                      .subtract(days, 'days')
                      .format('DD/MM/YYYY')})`;
              const key = Math.ceil(Math.random() * 1000);
              return (
                <CardContractsItem
                  key={key}
                  id={key}
                  title={contract?.productDescription?.replace(/[0-9]/g, '')}
                  description={description}
                  days={days}
                  onClick={() => {
                    tagGa4Button(contract?.type, gtagParams);
                    verifyAlredyExistsElegibility(
                      contract?.type,
                      contract.contract
                    );
                  }}
                />
              );
            })}
            {newContracts.length < 2 ? (
              <Help />
            ) : (
              <HelperButton onClick={handleHelperButton}>
                <WhatsAppIcon /> Precisa de ajuda?
              </HelperButton>
            )}
          </>
        ) : (
          <DescriptionHelp
            setHelperActive={setHelperActive}
            contracts={contracts}
          />
        )}
      </Container>
    </ContainerFadeIn>
  );
};

export default Contracts;

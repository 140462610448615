import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  background: ${colors.light_gray};
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const TopContainer = styled.div`
  width: 100%;
  background: ${colors.light_gray};
  padding: 16px 16px 8px 16px;
  border-radius: 8px;
  transition: background 0.2s linear;

  :hover {
    cursor: ${({ isExpanded }) => (isExpanded ? 'normal' : 'pointer')};
    background: ${({ isExpanded }) =>
      isExpanded ? colors.light_gray : colors.gray};
  }
`;

export const BottomContainer = styled.div`
  padding: 0 16px 0 16px;
`;

export const InputRadioContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

export const LabelRadio = styled.p`
  font-weight: 700;
  color: ${colors.primary};
  transition: color 0.3s linear;
  font-size: 16px;
  padding-left: 14px;
`;

export const LabelTextContainer = styled.p`
  padding: 4px 8px;
  background-color: ${colors.light_green};
  border-radius: 4px;
  margin-left: 20px;
  font-size: 12px;
  color: ${colors.dark_green};
`;

export const DotBackground = styled.div`
  width: 25px;
  height: 25px;
  border: solid 2px ${colors.primary};
  border-radius: 50%;
  background: ${colors.white};
`;

export const InnerDot = styled.div`
  height: 100%;
  width: 100%;
  border: solid 2px white;
  background-color: ${({ isChecked }) =>
    isChecked ? colors.primary : 'transparent'};
  border-radius: 50%;
`;

export const AnimatedCard = styled.div`
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? 500 : 0)}px;
  transition:
    max-height 0.3s,
    display 0.3s;
`;

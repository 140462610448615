export const mockData = [
  {
    name: 'BannerCartoes',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/bannerCashbackMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/home-banner-cashback.jpeg',
    title:
      'Compre com o seu cartão riachuelo e ganhe 20% de cashback em sua próxima compra. Peça agora o seu cartão e consulte as condições',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/cartoes',
  },
  {
    name: 'BannerEmprestimos',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/emprestimoMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/home-banner-emprestimo.jpeg',
    title:
      'Realize seu sonhos com o empréstimo pessoal midway. Conheça agora mesmo.',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/emprestimos',
  },
  {
    name: 'BannerSegurosAssistencias',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/saMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/home-banner-seguros.jpeg',
    title:
      'Cuide de você e de quem você ama com nossos seguros e assistências. Saiba mais.',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/seguros',
  },
  {
    name: 'BannerContas',
    type: 'image',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/home-banner-cdi.jpeg',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/contaDigitalMobile.png',
    title: 'Simplifique suas finanças com a conta digital midway. Saiba mais.',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/conta-digital',
  },
  {
    name: 'BannerReneg',
    type: 'image',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/impresso-feirao-online-banner-site-midway-desktop.png',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/CB962_banner_renegociacao_mobile.png',
    title: 'Adeus boletos, olá tranquilidade',
    subtitle: 'Resolva suas dívidas sem complicações de forma rápida e fácil',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/campanha-quitacao',
  },
];

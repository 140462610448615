import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isReverse ? 'row-reverse' : 'row')};
  gap: 60px;
  width: 100%;
  padding: 22px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 40px;

    img {
      max-width: 80%;
      max-height: 80%;
    }
  }

  @media (max-width: 492px) {
    flex-direction: column;
    gap: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
export const SectionTitle = styled.h2`
  font-size: 2.85rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 2.2rem !important;
  }

  @media (max-width: 492px) {
    font-size: 1.2rem !important;
  }
`;

export const SectionPhrase = styled.div`
  p {
    font-size: 1.42rem !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #221f20;
    margin-bottom: 1rem !important;

    @media (max-width: 768px) {
      font-size: 1.2rem !important;
      margin-bottom: 1em;
    }

    @media (max-width: 492px) {
      font-size: 1rem !important;
      margin-bottom: 1em;
    }
  }
`;
export const SectionContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
`;
export const SectionImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ActionsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;

  .Button-root {
    padding: 20px 29px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    .Button-root {
      width: 100%;
      font-size: 1rem !important;
    }
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  display: flex;
  max-width: 1270px;
  width: 100%;
  padding: 0 40px;
  @media (max-width: 768px) {
    padding: 0 17px;
  }
`;

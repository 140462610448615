import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerGlobal = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
`;

export const Main = styled.main`
  background-color: white;
`;

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const BannerDesktop = styled.img`
  width: 100%;
  object-fit: cover;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const BannerMobile = styled.img`
  width: 100%;
  object-fit: cover;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const OverlayBanner = styled.div`
  position: absolute;
  top: 15%;
  left: 8%;
  width: 100%;
  height: 100%;
  max-width: 668px;
  max-height: 267px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 800px) {
    top: 5%;
    left: 5%;
    max-width: 364px;
    max-height: 66px;
  }
`;

export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;

export const TextBanner = styled.p`
  font-family: sans-serif !important;
  font-size: 36px;
  font-weight: 200 !important;
  line-height: 43px;
  text-align: left;
  color: white;
  text-transform: uppercase;
  max-width: 612px;
  padding-bottom: 18px;

  @media (max-width: 1000px) {
    font-size: 18px;
    padding-bottom: 10px;
    line-height: 22px;
    max-width: 326px;
  }
`;

export const ButtonCardBanner = styled.a`
  width: 100%;
  max-width: 325px;
  max-height: 52px;
  color: ${props => props.color || '#221f20'};
  background-color: ${props => props.backgroundColor || '#fff'};
  padding: 16px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 14px;
  font-family: Larsseit;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border: none;
  transition: backgroud-color 0.5s ease;

  &:hover {
    color: inherit;
    opacity: 0.6;
  }

  @media (max-width: 1000px) {
    margin-left: 40px;
    margin-bottom: 10px;
    max-width: 260px;
    max-height: 41px;
    border-radius: 24px;
    font-size: 16px;
  }
`;

export const IconCardBanner = styled.img`
  width: 24px;
  margin-right: 10px;
`;

export const CentralizeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
  }
`;

export const ContentTextCard = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ContentTop = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  width: 90%;
  max-width: 1176px;
  max-width: 1180px;
  margin-bottom: 50px;
  @media (max-width: 1000px) {
  }
`;

export const TitleContent = styled.h1`
  font-family: Montserrat;
  font-size: 40px !important;
  font-weight: 700;
  line-height: 49px;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 80px;

  @media (max-width: 1000px) {
    font-size: 20px !important;
    line-height: 24px;
  }
`;

export const TextContent = styled.p`
  font-family: Montserrat;
  font-size: 24px !important;
  font-weight: 500;
  line-height: 29px;
  text-align: left;
  padding-bottom: 30px;
  color: #3b4443;

  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 20px;
  }
`;

export const ContentBottom = styled.div`
  margin-top: 80px;
  width: 100%;
  min-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1219px) {
    margin-top: 180px;
    flex-direction: column;
    max-width: 90%;
  }
`;

export const OverlayCards = styled.div`
  width: 100%;
  min-width: 1440px;
  min-height: 206px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a06d2c;
  margin-bottom: -244px;
  @media (max-width: 1219px) {
    width: 100%;
    min-height: 511px;
    margin-bottom: -753px;
  }
`;

export const ContainerCards = styled.div`
  width: 90%;
  max-width: 1173px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 1219px) {
    flex-direction: column;
    width: 90%;
    margin-top: 120px;
  }
`;

export const ContainerCardBlackTop = styled.div`
  width: 100%;
  max-width: 1438px;
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CardLeft = styled.div`
  width: 50%;
  max-width: 719px;
  max-height: 368px;
  min-height: 292px;
  margin-right: 2px;
  padding: 56px 132px 56px 132px;
  background-color: #221f20;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 56px 24px 56px 36px;
    margin-right: 0;
    margin-bottom: 2px;
  }
`;

export const CardRight = styled.div`
  width: 50%;
  max-width: 719px;
  max-height: 368px;
  min-height: 292px;
  padding: 56px 132px 56px 132px;
  background-color: #221f20;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 56px 24px 56px 36px;
  }
`;

export const CardTitle = styled.p`
  color: #f7f7f7;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

export const CardText = styled.p`
  color: #f7f7f7;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-top: 24px;
  @media (max-width: 1000px) {
  }
`;

export const ButtonCard = styled.a`
  width: 242px;
  height: 56px;
  color: #221f20;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 24px;
  border: none;
  transition: backgroud-color 0.5s ease;

  &:hover {
    color: inherit;
    background-color: #cdcdcd;
  }

  @media (max-width: 1000px) {
  }
`;

export const IconCard = styled.img`
  width: 24px;
  margin-right: 10px;
`;

export const ContentCardBottom = styled.div`
  width: 95%;
  max-width: 1176px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 120px;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-top: 70px;
  }
`;

export const IconInfoBlock = styled.div`
  width: 100%;
  max-width: 382px;
  max-height: 232px;
  padding: 40px 24px 40px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 16px;

  @media screen and (max-width: 1000px) {
    width: 100% !important;
  }
`;

export const AgroupInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const IconBlock = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 16px;
`;

export const TitleBlock = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
`;

export const descriptionBlock = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-top: 32px;
`;

export const ContentAccordion = styled.section`
  width: 90%;
  max-width: 1176px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 120px;
  margin-bottom: 98px;

  @media (max-width: 1000px) {
    width: 90%;
    margin-top: 55px;
  }
`;

export const ContainerAccordionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: left;
  @media (max-width: 1000px) {
  }
`;

export const AccordionTitle = styled.h1`
  font-size: 32px !important;
  font-weight: 500;
  font-family: Montserrat;

  @media (max-width: 1000px) {
    width: 90%;
    font-size: 20px !important;
    line-height: 28px;
  }
`;

export const ContentBottomAccordion = styled.div`
  width: 100%;
  margin-top: 56px;
`;

export const AnchorTitle = styled.a`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  color: #1f2b2a;

  @media (max-width: 1000px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #4e4b59;
  }
`;

export const TextContentBottom = styled.p`
  margin-top: 74px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TextSecondaryBottom = styled.p`
  margin-top: 24px;
  margin-bottom: 56px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const FinalTextBold = styled.span`
  font-family: Poppins;
  font-weight: bold;
`;

export const FinalText = styled.p`
  margin-top: 36px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

import React from 'react';

import * as S from './styles';

const ListStepperNumber = ({ data }) => {
  return (
    <>
      {data.map((value, index) => (
        <S.Container key={index}>
          <S.StepNumberContainer>
            <StepNumber size={70} value={index + 1} color="#242424" />
          </S.StepNumberContainer>
          <S.TextContainer>
            <S.ParagraphSection>{value.text}</S.ParagraphSection>
          </S.TextContainer>
        </S.Container>
      ))}
    </>
  );
};

const StepNumber = ({ value, color, size }) => {
  return (
    <S.ContainerRound color={color} size={size}>
      <S.Text color={color}>{value}</S.Text>
    </S.ContainerRound>
  );
};

export default ListStepperNumber;

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import cardService from '~/services/CardApi';
import { LocalStorage } from '~/utils/localStorage';

export const BI_CALLS_METADATA_STORAGE_KEY = 'BI_CALLS_METADATA_STORAGE_KEY';

const useSendInvoiceDataToBI = () => {
  const {
    toogles: { isEnabledBiCardsIntegration },
  } = useSelector(state => state.featuretoggle);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayInMillis = today.getTime();

  const saveBiMetadataInStorage = (body, lastBiCalls) => {
    let metadata = [
      {
        maskedPan: body.maskedPan,
        calledAt: todayInMillis,
      },
    ];
    if (lastBiCalls) {
      metadata = [...lastBiCalls, ...metadata];
    }
    LocalStorage.setItem(
      BI_CALLS_METADATA_STORAGE_KEY,
      JSON.stringify(metadata)
    );
  };

  const getBiMetadataFromStorage = () => {
    const lastBiCalls = LocalStorage.getItem(BI_CALLS_METADATA_STORAGE_KEY);
    return lastBiCalls && JSON.parse(lastBiCalls);
  };

  const verifyIfHasCalledSameBiEvent = (body, biCalls) =>
    biCalls.some(metadata => metadata.maskedPan === body.maskedPan);

  const getTodayBiLastCalls = biCalls =>
    biCalls.filter(metadata => {
      const metadataEventCalledAt = new Date(metadata.calledAt);
      return metadataEventCalledAt.getTime() === todayInMillis;
    });

  const memoized = useCallback(
    async body => {
      if (!isEnabledBiCardsIntegration) return;

      const lastBiCalls = getBiMetadataFromStorage();
      if (!lastBiCalls) {
        const hasSuccess = await cardService.sendInvoiceDataToBI(body);
        if (hasSuccess) {
          saveBiMetadataInStorage(body, null);
        }
        return;
      }

      const todayBiLastCalls = getTodayBiLastCalls(lastBiCalls);
      const hasCalledSameBiEventToday = verifyIfHasCalledSameBiEvent(
        body,
        todayBiLastCalls
      );
      if (!hasCalledSameBiEventToday) {
        const hasSuccess = await cardService.sendInvoiceDataToBI(body);
        if (hasSuccess) {
          saveBiMetadataInStorage(body, todayBiLastCalls);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEnabledBiCardsIntegration]
  );

  return memoized;
};

export default useSendInvoiceDataToBI;

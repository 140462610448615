import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  background: ${colors.white};
  padding: 24px;
  width: 100%;
  min-width: 232px;
  min-height: 100px;
  border-radius: 24px;
  box-shadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);

  @media (min-width: 1024px) {
    width: 422px;
  }
`;

export const HeaderContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 18px;
`;

export const HeaderText = styled.p`
  font-weight: 700;
  font-size: 18px;

  padding-bottom: 14px;
`;

export const Text = styled.p`
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const InfoButton = styled.button`
  padding: 0 4px;
  border: 0;
  background: transparent;
`;

export const Image = styled.img`
  width: 24px;
  margin-right: 8px;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const BankSlipItemContainer = styled.div`
  margin-bottom: 12px;
`;

export const PaylineBox = styled.div`
  margin: 16px 0;
  padding: 16px;
  background: ${colors.white};
  border-radius: 8px;
`;

export const Payline = styled.p`
  font-size: 12px;
  text-align: center;
`;

import React, { useEffect } from 'react';
import * as S from './styles';
// import Privacy from '~/assets/files/Politica_de_privacidade_aplicativo_cartao_riachuelo_vfinalpdf.pdf';

const AcceptTerm = () => {
  useEffect(() => {
    window.location.replace(
      '/contrato/Politica_de_privacidade_aplicativo_cartao_riachuelo_vfinalpdf'
    );
  }, []);

  return <S.Container />;
};

export default AcceptTerm;

import { v4 as uuidv4 } from 'uuid';
import { instanceMidway } from './LoanApiInstance';
import { instance } from './RentabilizacaoApiInstance';

const getBilletCreditCard = async body => {
  const uri = '/conta-online/boletos/v2/linha-digitavel';
  const headers = {
    Channel: 'MOBILE',
    'x-midway-transaction-id': uuidv4(),
  };

  return instanceMidway
    .post(uri, body, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

const getUpsell = async ({ birthDate, documentNumber }) => {
  const uri = '/conta-online/cartao/pre-aprovado/limite-credito';
  const headers = {
    Channel: 'MOBILE',
    'x-midway-transaction-id': uuidv4(),
    'x-document-number': documentNumber,
    'x-birth-date': birthDate,
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

/**
 * Send invoice data to BI
 * @param {Object} body - request body
 * @param {string} body.documentNumber
 * @param {string} body.maskedPan
 * @param {number | null} body.valueInvoice
 * @param {number | null} body.valuePay
 * @param {string | null} body.product
 * @param {string | null} body.flag
 */
const sendInvoiceDataToBI = async body => {
  const uri = '/site-midway/v1/integration/bi/customer-invoice-data';

  return instance
    .post(uri, { ...body, context: 'siteboletos_midway_login' })
    .then(() => true)
    .catch(() => false);
};

export default {
  getBilletCreditCard,
  getUpsell,
  sendInvoiceDataToBI,
};

import { createGlobalStyle } from 'styled-components';
import '@midway/web-ui-component/src/theme.scss';
import '@brainhubeu/react-carousel/lib/style.css';
import '../assets/fonts/Larsseit/Larsseit-ExtraBold.otf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Larsseit-ExtraBold';
    src: local('Larsseit-ExtraBold'), url("../assets/fonts/Larsseit/Larsseit-ExtraBold.otf") format('otf')
  }

  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html {
    overflow: hidden;
  }
  body {
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
  }
  p {
    margin-bottom: 0 !important;
  }
  img {
    display: block;
  	width: 100%;
  	height: auto;
  }
  html, body, #root {
    height: 100%;
  }
  body, input, button {
    font: 14px 'Montserrat', sans-serif;
  }
  a {
    text-decoration: none;
  }
  button {
    cursor: pointer;
  }
  span, p, a {
    line-height: 1.5;
  }
  .space-layout {
    margin-right: 18px;
    margin-left: 18px;
    border-radius: 20px;
    @media (max-width: 390px) {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  .effectCard {
    margin-bottom: 10px;
    transition: 0.2s all;
    @media (min-width: 600px) {
      &:hover {
        margin-top: -5px;
        margin-bottom: 15px;
      }
    }
  }
  div#m-modal-id-ready-modal-for-screen-reader {
      max-width: 80% !important;
    @media (max-width: 772px) {
      max-width: 95% !important;
    }
  }

  a:focus {
    border: 1px solid #0071eb;
  }

  .public-elements-for-website {
    display: none;
  }

  .--toggle-contract-routes {
    display: none !important;
  }

  .containermenu {
      position: relative ;
      width: 100%;
      margin: 0 auto;
    }

  @media (min-width: 1300px){
    .containermenu {

      max-width: 1270px;
      margin: 0 auto;
    }
  }

`;

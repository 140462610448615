import styled from 'styled-components';

export const MidwayContent = styled.div`
  padding-bottom: 50px;
  @media (max-width: 992px) {
    padding-bottom: 104px;
  }
  h1 {
    font-size: 40px;
    font-weight: bold;
    padding-top: 128px;
    padding-bottom: 23px;
    color: #252525;
    @media (max-width: 992px) {
      padding-top: 40px;
    }
  }
  img {
    padding-top: 128px;
    @media (max-width: 992px) {
      padding-top: 30px;
    }
  }
  p {
    font-size: 16px;
    color: #707070;
    line-height: 35px;
    @media (max-width: 992px) {
      line-height: 24px;
    }
  }
`;

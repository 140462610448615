import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f3f3f3;

  .cards {
    margin-bottom: 10px;
  }
`;

export const Header = styled.div`
  background-color: #006464;
  color: #f3f3f3;
  padding: 16px;
  -webkit-box-shadow: 0px -6px 32px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px -6px 32px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 0px -6px 32px 0px rgba(0, 0, 0, 0.44);
`;

export const Body = styled.div`
  padding: 16px 16px 0;

  h1 {
    font-weight: bold;
    font-size: 26px !important;
  }

  p {
    padding: 10px 0;
    color: #666;
  }
`;

export const Table = styled.div`
  margin-bottom: 58px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row !important;
  padding: 10px;
  border-radius: 10px;

  &.grey {
    background-color: #e4e4e4;
  }

  div {
    width: 50%;

    p {
      font-weight: bold;
    }
  }
  div.check {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #006464;
      font-weight: bold;
      text-align: center;
      line-height: 1.1;
    }
  }
  div.nope {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: #851b1b;
      font-weight: bold;
      text-align: center;
      line-height: 1.1;
    }
  }
`;

export const Card = styled.div`
  position: relative;
  background-color: #ffffff;
  border: 4px solid #006464;
  border-radius: 20px;
  margin-top: 30px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14);

  .cardBody {
    border-radius: 10px;
  }

  h2 {
    background-color: #006464;
    color: #f3f3f3;
    font-weight: bold;
    font-size: 18px !important;
    padding: 12px 0 13px;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  p {
    font-size: 14px !important;
    padding: 16px;
  }

  .row-grey,
  .row-white {
    background: #f0f0f0;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;

    div {
      width: 50%;
      align-items: center;
      display: flex;
    }

    p {
      text-align: right;
      padding: 0 16px;
      flex: 1;
    }
    p.green {
      color: #006464;
      font-weight: bold;
      text-align: left;
    }
  }

  .row-grey.last {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .row-white {
    background: #fff;
  }
`;

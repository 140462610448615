import React from 'react';
import CardParcel from '~/components/CardParcel';

const CardParcelCredit = () => {
  return (
    <CardParcel
      title="Parcelamento de fatura no cartão de crédito Riachuelo"
      texts={[
        'Agora você passará a contar com a opção de parcelar o valor total da fatura em até 21 X com juros e parcelas que cabem no bolso, trazendo assim maior flexibilidade de pagamento.',
        'O parcelamento de fatura possibilita você saber o valor exato e por quanto tempo irá pagar a sua fatura, além de restabelecer o seu limite a cada parcela paga, deixando assim o seu Cartão Riachuelo disponível para novas compras.',
        'Optando pelo parcelamento de fatura você não tem surpresas no final do mês e escolhe a melhor plano de parcelamento para ter sua vida financeira organizada.',
      ]}
    />
  );
};

export default CardParcelCredit;

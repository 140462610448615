import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import { banner } from './data';
import RenderBannerImage from '~components/RenderBannerImage';
import Card from './components/Card';
import GridCards from './components/GridCards';
import DownloadStore from '~/components/DownloadStore';
import ListStep from './components/ListStep';
import TextPhoneNumber from '~/components/TextPhoneNumber';
import CardsZurich from '~/components/CardsZurich';
import { trackingSelectContent } from '~/analytics';

import BoneIcon from '~/assets/img/Financial/personal-accident-insurance/icon-bone.svg';
import AccidentIcon from '~/assets/img/Financial/personal-accident-insurance/icon-accident.svg';
import CrossIcon from '~/assets/img/Financial/personal-accident-insurance/icon-cross.svg';
import AmbulanceIcon from '~/assets/img/Financial/personal-accident-insurance/icon-ambulance.svg';
import TelemedicineIcon from '~/assets/img/Financial/personal-accident-insurance/icon-telemedicine.svg';
import StethoscopeIcon from '~/assets/img/Financial/personal-accident-insurance/icon-stethoscope.svg';
import MedicinesIcon from '~/assets/img/Financial/personal-accident-insurance/icon-medicines.svg';
import FruitIcon from '~/assets/img/Financial/personal-accident-insurance/icon-fruit.svg';
import AssistanceIcon from '~/assets/img/Financial/personal-accident-insurance/icon-assistance.svg';
import Telemedicine from '~/assets/img/Financial/personal-accident-insurance/telemedicine.svg';
import MedicalConsultation from '~/assets/img/Financial/personal-accident-insurance/medical-consultation.svg';
import DiscountMedicines from '~/assets/img/Financial/personal-accident-insurance/discount-on-medicines.svg';
import NutritionalGuidance from '~/assets/img/Financial/personal-accident-insurance/nutritional-guidance.svg';
import FuneralAssistance from '~/assets/img/Financial/personal-accident-insurance/funeral-assistance.svg';
import Iphone from '~/assets/img/Financial/personal-accident-insurance/iphone.svg';
import PlayStore from '~/assets/img/lending/play-store.svg';
import AppleStore from '~/assets/img/lending/apple-store.svg';
import ButtonFull from '~/assets/img/Financial/ProtectionInsurance/button-full.svg';
import ZurichLogo from '~/assets/img/Financial/account-paid-insurance/zurich-logo.svg';

const PHONE_CAPITALS_AND_REGIONS = '3004 5417';
const PHONE_OTHER_LOCATIONS = '0800 727 4417';
const PHONE_INSURANCE_CAPITALS_AND_REGIONS = '4020 4345 ';
const PHONE_INSURANCE_OTHER_LOCATIONS = '0800 285 4345 ';
const PHONE_INSURANCE_SAC = '0800 284 4848 ';
const PHONE_INSURANCE_HEARING_IMPAIRED = '0800 275 8585';
const PHONE_INSURANCE_CAPITALS_AND_REGIONS_TWO = '4020 8986 ';
const PHONE_INSURANCE_OTHER_LOCATIONS_TWO = '0800 085 8986 ';
const PHONE_INSURANCE_HEARING_IMPAIRED_TWO = '0800 275 8585';
const CNPJ = '17197.385/0001-21 ';
const FLOW = 'seguro-acidentes-pessoais';

const STEP_LIST = [
  {
    icon: TelemedicineIcon,
    text: 'Serviço de telemedicina;',
  },
  {
    icon: StethoscopeIcon,
    text: 'Consulta médica;',
  },
  {
    icon: MedicinesIcon,
    text: 'Desconto em medicamentos;',
  },
  {
    icon: FruitIcon,
    text: 'Orientação nutricional;',
  },
  {
    icon: AssistanceIcon,
    text: 'Assistência funeral;',
  },
];

const PersonalAccidentInsurance = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerPersonalAccidentInsurance(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banners = bannerData[0] || banner;

  const handleClickGeneralConditionsPersonalAccidentInsurance = () => {
    trackingSelectContent(
      'condicoes-gerais-do-seguro-protecao-e-cuidados-pessoais',
      FLOW
    );
  };

  const handleClickSummaryGeneralConditionsPersonalAccidentInsurance = () => {
    trackingSelectContent(
      'resumo-das-condicoes-gerais-do-seguro-protecao-e-cuidados-pessoais',
      FLOW
    );
  };

  const handleServiceZurich = () => {
    trackingSelectContent('central-de-atendimento-zurich', FLOW);
  };

  const handleVirtualAssistantZurich = () => {
    trackingSelectContent('fale-com-a-lais-assistente-virtual-zurich', FLOW);
  };

  const handleClickAnchor = () => {
    trackingSelectContent('www-consumidor-gov-br', FLOW);
  };

  const handleClickAnchorZurich = () => {
    trackingSelectContent('www-zurich-com-br', FLOW);
  };

  const handleClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
      '_blank'
    );
    trackingSelectContent('download:google-play', FLOW);
  };

  const handleClickAppleStore = () => {
    window.open(
      'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
      '_blank'
    );
    trackingSelectContent('download:apple-store', FLOW);
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Seguro Proteção e Cuidados Pessoais</S.TitleCards>
            <S.SubTitleCards>
              Nas horas em que mais precisar, estaremos com você. Conheça as
              principais coberturas.
            </S.SubTitleCards>
          </S.AdjustamentTitleCard>
          <S.ContainerCards>
            <Card
              icon={AmbulanceIcon}
              title="Diária de internação hospitalar por acidente"
              description="Cobertura diária para ajudar com despesas em caso de internação após um acidente."
            />
            <Card
              icon={BoneIcon}
              title="Quebra de ossos"
              description="Indenização em caso de fratura, ajudando nas despesas durante o período de recuperação."
            />
            <Card
              icon={AccidentIcon}
              title="Invalidez permanente total ou parcial por acidente"
              description="Suporte financeiro em caso de invalidez causada por acidente, garantindo segurança para o titular e o cônjuge."
            />
            <Card
              icon={CrossIcon}
              title="Morte acidental"
              description="Proteção financeira para a família em caso de perda por acidente, com cobertura para titular, cônjuge e filhos."
            />
          </S.ContainerCards>
          <S.SectionAssistance>
            <S.TitleCards>Assistências</S.TitleCards>
            <S.SubTitleCards>
              As assistências do Seguro Proteção e Cuidados Pessoais oferecem
              suporte prático e especializado em momentos inesperados, com
              serviços que garantem segurança e bem-estar.
            </S.SubTitleCards>
            <S.AssistanceCard>
              <GridCards
                img={Telemedicine}
                title="Telemedicina"
                description="Atendimento médico a qualquer momento, facilitando o cuidado com
                a saúde diretamente de casa."
              />
              <GridCards
                img={MedicalConsultation}
                title="Consulta médica"
                description="Acesso a consultas presenciais com profissionais de saúde, garantindo atenção personalizada."
              />
              <GridCards
                img={DiscountMedicines}
                title="Desconto em medicamentos"
                description="Economia em medicamentos
                essenciais para cuidar da saúde com mais tranquilidade."
              />
              <GridCards
                img={NutritionalGuidance}
                title="Orientação nutricional"
                description="Consultoria especializada para
                melhorar a alimentação e alcançar
                mais bem-estar."
              />
              <GridCards
                img={FuneralAssistance}
                title="Assistência funeral"
                description="Suporte financeiro para ajudar com despesas funerárias e proporcionar tranquilidade em momentos delicados."
              />
            </S.AssistanceCard>
            <S.TextAssistence>
              Para saber mais sobre as coberturas consulte planos e condições
            </S.TextAssistence>
          </S.SectionAssistance>
          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanel src={Iphone} />
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Para utilizar as assistências de saúde baixe o app Tem Saúde
                  Digital
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStep data={STEP_LIST} />
                </S.GroupLeft>
                <S.GroupColumn>
                  <S.TextMidway>
                    Baixe o aplicativo Tem Saúde Digital
                  </S.TextMidway>
                  <S.Agroup>
                    <DownloadStore
                      iconSrc={PlayStore}
                      onClick={handleClickPlayStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Google Play</S.TextBold>
                    </DownloadStore>
                    <DownloadStore
                      iconSrc={AppleStore}
                      onClick={handleClickAppleStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                    </DownloadStore>
                  </S.Agroup>
                </S.GroupColumn>
              </S.CentralizeRightPanel>
            </S.RightPanel>
          </S.InformationPanel>
          <S.AdditionalInfoCard>
            <S.CardWrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://midway.com.br/static/documents/tags/condicoes-particulares/cg-processo-protecao-e-cuidado-pessoal.pdf"
                  onClick={
                    handleClickGeneralConditionsPersonalAccidentInsurance
                  }
                  target="_blank"
                >
                  Condições gerais do Seguro Proteção e Cuidados Pessoais{' '}
                </S.CardWrapperUnderline>
              </S.WrapperText>
              <S.WrapperText marginRight={'60px'}>
                <S.CardWrapperUnderline
                  href="https://midway.com.br/static/documents/tags/condicoes-particulares/resumo-condicoes-gerais-e-assistencias-protecao-e-cuidados-pessoais.pdf"
                  onClick={
                    handleClickSummaryGeneralConditionsPersonalAccidentInsurance
                  }
                  target="_blank"
                >
                  Resumo das condições gerais do Seguro Proteção e Cuidados
                  Pessoais
                </S.CardWrapperUnderline>
              </S.WrapperText>
            </S.CardWrapperText>
          </S.AdditionalInfoCard>
          <S.ContainerCardsZurich>
            <S.ContentCardWrapper>
              <CardsZurich
                title="Central de atendimento Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/atendimento"
                onClick={handleServiceZurich}
              />
              <CardsZurich
                widthCard="531px"
                title="Fale com a Laiz - Assistente virtual Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/chatonline"
                onClick={handleVirtualAssistantZurich}
              />
            </S.ContentCardWrapper>
          </S.ContainerCardsZurich>
          <S.ContentText>
            <S.TextContent>
              Você pode contratar nas Lojas Riachuelo mais próximas ou, se
              preferir, pode optar pelas centrais de atendimento através dos
              telefones:
            </S.TextContent>
            <TextPhoneNumber
              text="Capitais e regiões metropolitanas:"
              phoneNumber={PHONE_CAPITALS_AND_REGIONS}
              trackingId={PHONE_CAPITALS_AND_REGIONS}
              trackingFlow={FLOW}
            />
            <TextPhoneNumber
              text="Demais localidades:"
              phoneNumber={PHONE_OTHER_LOCATIONS}
              trackingId={PHONE_OTHER_LOCATIONS}
              trackingFlow={FLOW}
            />
          </S.ContentText>
          <S.ContainerCardBlackBottom>
            <S.GroupBlackBottom>
              <S.CardBlackBottomTitle>
                Para utilizar o Seguro{' '}
              </S.CardBlackBottomTitle>
              <S.CardBlackBottomText>
                Ligue para Central de Atendimento Zurich Horário de Atendimento:
                Segunda a Sexta-feira, das 8h ás 20h e aos Sábados das 8h ás
                18h, exceto feriados.
              </S.CardBlackBottomText>
              <S.CardBlackBottomText paddingTop="25px">
                <S.UnderlineText>
                  {PHONE_INSURANCE_CAPITALS_AND_REGIONS}
                </S.UnderlineText>
                (capitais e regiões metropolitanas)
                <br />
                <S.UnderlineText>
                  {PHONE_INSURANCE_OTHER_LOCATIONS}
                </S.UnderlineText>
                (demais localidades)
                <br />
                SAC: <S.UnderlineText>{PHONE_INSURANCE_SAC}</S.UnderlineText>
                <br />
                Deficiente auditivo:{' '}
                <S.UnderlineText>
                  {PHONE_INSURANCE_HEARING_IMPAIRED}
                </S.UnderlineText>
                . Horário de atendimento: <br />
                24 horas, 7 dias na semana.
              </S.CardBlackBottomText>
              <S.CardBlackBottomText fontWeight="700" paddingTop="25px">
                Para consultar ou acionar as assistências
              </S.CardBlackBottomText>
              <S.CardBlackBottomText paddingTop="25px">
                Ligue para{' '}
                <S.UnderlineText>
                  {PHONE_INSURANCE_CAPITALS_AND_REGIONS_TWO}
                </S.UnderlineText>{' '}
                capitais e regiões metropolitanas <br />
                <S.UnderlineText>
                  {PHONE_INSURANCE_OTHER_LOCATIONS_TWO}
                </S.UnderlineText>{' '}
                (Demais Localidades)
                <br />
                SAC: <S.UnderlineText>{PHONE_INSURANCE_SAC}</S.UnderlineText>
                <br />
                Deficiente auditivo:{' '}
                <S.UnderlineText>
                  {PHONE_INSURANCE_HEARING_IMPAIRED_TWO}
                </S.UnderlineText>
              </S.CardBlackBottomText>
            </S.GroupBlackBottom>
          </S.ContainerCardBlackBottom>
          <S.ContentZurich>
            <S.ZurichLeft src={ZurichLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                Fundada na Suíça em 1872, com sede na cidade de Zurique, somos
                uma das seguradoras globais mais experientes do mundo. Com cerca
                de 56 mil funcionários, oferecemos uma ampla gama de produtos e
                serviços de ramos elementares e de vida em mais de 210 países e
                territórios. Atendemos pessoas físicas e jurídicas – de pequenas
                empresas a multinacionais.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>
          <S.ContentFinal>
            <S.ContentTextFinal>
              Seguro garantido pela Zurich Minas Brasil Seguros S.A. {CNPJ} –
              Código SUSEP: 05495, processo SUSEP Riscos Pessoais:
              15414.901061/2014-13. Consulte a íntegra das Condições Gerais do
              Seguro em{' '}
              <S.Anchor
                href="www.zurich.com.br"
                onClick={handleClickAnchorZurich}
                target="_blank"
              >
                www.zurich.com.br
              </S.Anchor>
              . O registro do produto é automático e não representa aprovação ou
              recomendação por parte da SUSEP. Central de Atendimento Zurich –
              SAC: 0800 254 4848. Deficiente auditivo: 0800275 8585, horário de
              atendimento: 24 horas, 7 dias por semana. Ouvidoria: 0800 770
              1061. O seguro é opcional e é proibido condicionar desconto no
              preço à aquisição do seguro. Link da plataforma digital oficial
              para registro de reclamações dos consumidores dos mercados
              supervisionados:
              <S.Anchor
                href="https://www.consumidor.gov.br"
                onClick={handleClickAnchor}
                target="_blank"
              >
                (www.consumidor.gov.br)
              </S.Anchor>
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default PersonalAccidentInsurance;

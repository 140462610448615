import styled from 'styled-components';

export const Container = styled.div`
  background: transparent linear-gradient(180deg, #c1fd95 0%, #46cbd0 100%) 0%
    0% no-repeat padding-box;
  padding: 0 !important;
  display: flex;
  align-self: center;
  min-height: 450px;
  flex-direction: column;
`;

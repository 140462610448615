import React from 'react';
import { Icon } from '@midway/web-ui-component';
import { dropRight, first, isEmpty, last } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import { gtagSelectContent } from '~/utils/TaggingGA4';
import history from '~/services/history';
import { Container } from './styles';

const GTAG_FLOW = 'miajuda';
const ArrowBack = ({ gtagContentPage }) => {
  const {
    historyPages,
    setHistoryPages,
    setShowFlow,
    negotiationData,
    newClient,
    duplicateData,
    system,
  } = useNegotiation();

  const getGtagContentPageByHistory = () => {
    if (last(historyPages) === 'contracts')
      return 'selecione-o-contrato-que-deseja-negociar';
    if (last(historyPages) === 'eligibility') return 'visualizacao-de-proposta';
    if (last(historyPages) === 'paymentMethod') return 'tela-de-pagamento';
    if (last(historyPages) === 'registered') return 'tela-de-comprovante';
    if (last(historyPages) === 'duplicate')
      return 'pegue-aqui-a-segunda-via-do-boleto-do-seu-acordo';
  };

  const gaContract = () => {
    if (last(historyPages) === 'duplicate') {
      const isTopaz = system === 'TOPAZ';
      const duplicate =
        (!isTopaz &&
          first(
            duplicateData?.filter(
              d => d.contract === negotiationData?.selectedContract?.contract
            )
          )) ||
        first(duplicateData);
      return duplicate?.contract;
    }
    return negotiationData?.selectedContract?.contract;
  };

  const handleBack = async () => {
    gtagSelectContent(
      getGtagContentPageByHistory() || gtagContentPage,
      GTAG_FLOW,
      'voltar',
      {
        proposta: negotiationData?.gaParamProposta,
        contrato: gaContract(),
      }
    );
    const newHistoryPages = dropRight(historyPages);
    if (first(historyPages) === 'duplicate' && newClient && !duplicateData) {
      setHistoryPages([]);
      setShowFlow(false);
      return;
    }

    if (negotiationData?.registered && negotiationData?.contracts.length > 1) {
      if (last(historyPages) === 'contracts') return setShowFlow(false);
      setHistoryPages(['contracts']);
      return;
    }

    if (isEmpty(newHistoryPages)) {
      history.push('/campanha-quitacao');
      return;
    }

    setHistoryPages(newHistoryPages);
  };

  return (
    <Container onClick={() => handleBack()} aria-label="Voltar">
      <div>
        <Icon icon="angle-left" />
      </div>
      Voltar
    </Container>
  );
};
export default ArrowBack;

import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Spinner } from '@midway/web-ui-component';
import { useSelector } from 'react-redux';

import format from '~/services/format';
import { ReactComponent as BarCode } from '~/assets/img/Financial/BilletInvoiceOrLendingV2/barcode.svg';
import { ReactComponent as Pix } from '~/assets/img/Financial/BilletInvoiceOrLendingV2/pix.svg';
import useSimulateInstallment from '~/hooks/useSimulateInstallment';
import { useAuth } from '~/context/auth';
import {
  trackingSelectContent,
  trackingException,
  trackingViewContent,
} from '~/analytics';
import { flow, contentPage, contentType, description } from '~/analytics/cards';
import { PAYMENT_METHOD } from '~/constants/installment';

import { ModalHeader } from '../ModalHeader';
import { Details } from '../Details';
import { EntryValueInput, INPUT_STATE } from './components/EntryValueInput';
import { InstallmentOptionsInput } from './components/InstallmentOptionsInput';
import {
  Container,
  HeaderContainer,
  StepNumber,
  Header,
  Box,
  Badge,
  Divider,
  Text,
  Bold,
  InstallmentOptionSelectedContainer,
  InstallmentOptionsInputContainer,
  FeeText,
  Row,
  PaymentMethodTitle,
  PaymentMethodContainer,
  PaymentMethodBox,
  ButtonContainer,
  SpinnerContainer,
  LoadingText,
} from './styles';
import { GenericError } from '../GenericError';

let entryValueTimer;
export const InstallmentSimulation = ({
  onBack,
  entryValueMin,
  entryValueMax,
  onConfirmInstallment,
}) => {
  const {
    toogles: {
      isEnabledInvoiceInstallmentPixPaymentMethod: isPaymentByPixEnabled,
    },
  } = useSelector(state => state.featuretoggle);
  const { maskedPan } = useAuth();
  const [getInstallments, { loading, error, data }] = useSimulateInstallment();

  const [entryValue, setEntryValue] = useState({
    value: entryValueMin,
    inputFormatted: String(entryValueMin * 100),
  });
  const [selectedInstallment, setSelectedInstallment] = useState();
  const [isEntryValueGreaterThanMaxValue, setIsEntryValueGreaterThanMaxValue] =
    useState(false);
  const [isEntryValueLessThanMinValue, setIsEntryValueLessThanMinValue] =
    useState(false);
  const [
    isInitialInstallmentOptionsRequest,
    setIsInitialInstallmentOptionsRequest,
  ] = useState(true);

  const handleSelectInstallment = id => {
    const selected = data?.options.find(option => option.idInstallment === id);
    if (selected) {
      setSelectedInstallment(selected);
    }
  };

  const handleNewEntryValue = rawValue => {
    const value = rawValue < 100 ? rawValue : rawValue / 100;
    const inputFormatted = String(parseFloat(rawValue));
    setEntryValue({
      value: parseFloat(value),
      inputFormatted,
    });

    setIsEntryValueLessThanMinValue(false);
    setIsEntryValueGreaterThanMaxValue(false);
    clearTimeout(entryValueTimer);

    if (value < entryValueMin) {
      setIsEntryValueLessThanMinValue(true);
      return;
    }
    if (value > entryValueMax) {
      setIsEntryValueGreaterThanMaxValue(true);
      return;
    }

    entryValueTimer = setTimeout(() => {
      getInstallments(value);
    }, 1000);
  };

  const getEntryValueInputMessage = () => {
    if (isEntryValueLessThanMinValue) {
      return `Mínimo de ${format.currency(
        entryValueMin
      )}. Digite um valor maior.`;
    }
    if (isEntryValueGreaterThanMaxValue) {
      return `Máximo de ${format.currency(
        entryValueMax
      )}. Digite um valor menor.`;
    }
  };

  const confirmInstallment = paymentMethod => {
    onConfirmInstallment({
      idInstallment: selectedInstallment?.idInstallment,
      entryValue: entryValue.value,
      installmentDescription: selectedInstallment?.description,
      simulationId: data.simulationId,
      paymentMethod,
    });
  };

  const handleConfirmBillet = () => {
    trackingSelectContent(
      contentType.generateBillet,
      flow.payments,
      contentPage.installmentSimulation,
      {
        valor_de_entrada: entryValue.value,
        numero_de_parcelas: `${selectedInstallment?.amountOf}x`,
        valor_das_parcelas: selectedInstallment?.value,
        valor_do_pagamento: data.totalValue,
      }
    );
    confirmInstallment(PAYMENT_METHOD.BILLET);
  };

  const handleConfirmPix = () => {
    trackingSelectContent(
      contentType.begetPix,
      flow.payments,
      contentPage.installmentSimulation,
      {
        valor_de_entrada: entryValue.value,
        numero_de_parcelas: `${selectedInstallment?.amountOf}x`,
        valor_das_parcelas: selectedInstallment?.value,
        valor_do_pagamento: data.totalValue,
      }
    );
    confirmInstallment(PAYMENT_METHOD.PIX);
  };

  const handleTryAgain = () => {
    trackingSelectContent(
      contentType.retry,
      flow.payments,
      contentPage.genericError
    );
    getInstallments(entryValue.value);
  };

  const handleGoBack = analyticsContentPage => {
    trackingSelectContent(
      contentType.goBack,
      flow.payments,
      analyticsContentPage
    );
    onBack();
  };

  useEffect(() => {
    getInstallments();
  }, [getInstallments]);

  useEffect(() => {
    if (data) {
      if (isInitialInstallmentOptionsRequest) {
        trackingViewContent(contentPage.installmentSimulation, flow.payments, {
          valor_de_entrada: data.entryValue,
          numero_de_parcelas: `${data.options[0].amountOf}x`,
          valor_das_parcelas: data.options[0].value,
          valor_do_pagamento: data.totalValue,
        });
        setIsInitialInstallmentOptionsRequest(false);
      }
      setSelectedInstallment(data.options[0]);
    }
  }, [data, isInitialInstallmentOptionsRequest]);

  useEffect(() => {
    if (error) {
      trackingException(description.getOptionsError, flow.payments);
    }
  }, [error]);

  const isEntryValueInvalid =
    isEntryValueLessThanMinValue || isEntryValueGreaterThanMaxValue;
  const isPaymentOptionButtonDisabled = isEntryValueInvalid || loading;
  const installmentDescription = useMemo(
    () =>
      `${selectedInstallment?.amountOf}x de ${format.currency(
        selectedInstallment?.value
      )}`,
    [selectedInstallment]
  );
  const isRecommendedOption = useMemo(
    () => selectedInstallment?.idInstallment === data?.options[0].idInstallment,
    [data, selectedInstallment]
  );
  const taxAndTotalFinanced = useMemo(
    () =>
      `Juros de ${format.percentage(
        selectedInstallment?.taxPercentage
      )}% ao mês: ${format.currency(selectedInstallment?.totalFinanced)}`,
    [selectedInstallment]
  );
  const confirmButtonState = useMemo(
    () => (isPaymentOptionButtonDisabled ? 'dark' : 'primary'),
    [isPaymentOptionButtonDisabled]
  );
  const canShowPixPaymentMethod = useMemo(() => {
    const isSiccCard = maskedPan.includes('X');
    return isPaymentByPixEnabled && isSiccCard;
  }, [isPaymentByPixEnabled, maskedPan]);

  if (error) {
    return (
      <GenericError
        onTryAgain={handleTryAgain}
        onBack={() => handleGoBack(contentPage.genericError)}
      />
    );
  }

  return (
    <Modal
      title={
        <ModalHeader
          title="PARCELAMENTO DE FATURA"
          showBackButton
          onBack={() => handleGoBack(contentPage.installmentSimulation)}
        />
      }
      size="lg"
      openModal
      onHide={() => {}}
    >
      <Container>
        <HeaderContainer>
          <StepNumber>1</StepNumber>
          <Header>Digite um valor de entrada</Header>
        </HeaderContainer>
        <Box>
          <EntryValueInput
            label="Entrada"
            message={getEntryValueInputMessage()}
            state={isEntryValueInvalid && INPUT_STATE.ERROR}
            value={entryValue.inputFormatted}
            onInputChange={handleNewEntryValue}
          />
          <Badge>Esse valor deverá ser pago para efetivar o parcelamento</Badge>
        </Box>
        <HeaderContainer>
          <StepNumber>2</StepNumber>
          <Header>Selecione as parcelas</Header>
        </HeaderContainer>
        <Box>
          {loading ? (
            <SpinnerContainer>
              <Spinner />
              <LoadingText>Carregando, aguarde.</LoadingText>
            </SpinnerContainer>
          ) : (
            <>
              <Row>
                <InstallmentOptionSelectedContainer>
                  {isRecommendedOption && (
                    <Badge variant="primary">Recomendado</Badge>
                  )}
                  <Header>{installmentDescription}</Header>
                  <FeeText>{taxAndTotalFinanced}</FeeText>
                  <Text>
                    Primeira parcela{' '}
                    <Bold>{format.date(selectedInstallment?.dueDate)}</Bold>
                  </Text>
                </InstallmentOptionSelectedContainer>
                <InstallmentOptionsInputContainer>
                  <InstallmentOptionsInput
                    onChangeInstallment={handleSelectInstallment}
                    value={0}
                    installments={data?.options}
                  />
                </InstallmentOptionsInputContainer>
              </Row>
              <Divider />
              <Details
                taxValue={selectedInstallment?.taxValue}
                taxRate={selectedInstallment?.taxPercentage}
                iofValue={selectedInstallment?.iof}
                iofRate={selectedInstallment?.iofRate}
                CetRatePerYear={selectedInstallment?.maxCetPercentage}
              />
            </>
          )}
        </Box>
        <HeaderContainer>
          <StepNumber>3</StepNumber>
          <Header>Escolha como deseja pagar a entrada</Header>
        </HeaderContainer>

        <PaymentMethodContainer>
          {canShowPixPaymentMethod && (
            <PaymentMethodBox>
              <PaymentMethodTitle>
                <Pix />
                Pix
              </PaymentMethodTitle>
              <Text>Pagamento confirmado em instantes</Text>
              <ButtonContainer>
                <Button
                  block
                  data-testid="pix-installment-button"
                  title="Gerar Pix"
                  disabled={isPaymentOptionButtonDisabled}
                  state={confirmButtonState}
                  onClick={handleConfirmPix}
                />
              </ButtonContainer>
            </PaymentMethodBox>
          )}
          <PaymentMethodBox>
            <PaymentMethodTitle>
              <BarCode /> Boleto
            </PaymentMethodTitle>
            <Text>Pagamento confirmado em até 3 dias úteis</Text>
            <ButtonContainer>
              <Button
                outline
                block
                data-testid="billet-installment-button"
                title="Gerar boleto"
                disabled={isPaymentOptionButtonDisabled}
                state={confirmButtonState}
                onClick={handleConfirmBillet}
              />
            </ButtonContainer>
          </PaymentMethodBox>
        </PaymentMethodContainer>
      </Container>
    </Modal>
  );
};

import { isEmpty, cloneDeep } from 'lodash';
import { isNowTimeBetween } from '~/utils/utils';

/**
 * @typedef {{
 *     financingAmount: number,
 *     firstReleaseDate: string,
 *     installmentQuantity: number,
 *     installmentAmount: number
 *   }} SelectedInstallmentData
 */

/**
 * @typedef {{
 *     totalAmountContract: number,
 *     entryDate: string,
 *     entryAmount: number,
 *     discountAmount: number,
 *     selectedInstallmentData: SelectedInstallmentData,
 *     simulationToken: string
 *   }} SimulationData
 */

const invoicementSimulationParametersMapper = (invoicement, type) => {
  const {
    minEntryValue: entry,
    invoiceInstallmentToken: token,
    maxInstallmentNumber,
  } = invoicement;

  return {
    entry,
    token,
    type,
    maxInstallmentNumber,
  };
};

const _agreementEntryValue = (agreementData, system) => {
  if (system === 'AGREEMENTS') return agreementData?.minEntryValue;

  return (
    agreementData?.minEntryValueApplyAllDiscounts ||
    agreementData?.minEntryValue
  );
};

const agreementSimulationParametersMapper = (agreementData, system, type) => {
  const {
    installmentToken: token,
    discount,
    maxInstallmentNumber,
  } = agreementData;

  const entry = _agreementEntryValue(agreementData, system);

  return {
    entry,
    token,
    system,
    type,
    discount,
    maxInstallmentNumber,
  };
};

const topazInstallmenMapper = data => {
  const {
    installmentsNumbers: installmentQuantity,
    installmentAmount,
    financingTotal: financingAmount,
    firstInstallmentDate: firstReleaseDate,
  } = data;

  return {
    installmentQuantity,
    installmentAmount,
    financingAmount,
    firstReleaseDate,
  };
};

/**
 * @param {object} data
 * @param {string} loanOrCard
 * @param {number} installmentSelected
 * @returns {SelectedInstallmentData}
 */
const selectedInstallmentMapper = (data, loanOrCard, installmentSelected) => {
  let selectedInstallment;
  if (loanOrCard === 'loan') {
    selectedInstallment = data?.installmentPlans?.find(
      sim => sim.installmentsNumbers === installmentSelected
    );

    if (selectedInstallment) {
      selectedInstallment = topazInstallmenMapper(selectedInstallment);
    }
  } else {
    selectedInstallment = data?.installments?.find(
      sim => sim.installmentQuantity === installmentSelected
    );
  }

  return selectedInstallment;
};

/**
 * @param {object} data
 * @param {string} loanOrCard
 * @param {number} installmentSelected
 * @returns {SimulationData}
 */
const simulationResponseMapper = (data, loanOrCard, installmentSelected) => {
  const clonedData = cloneDeep(data);
  if (isEmpty(clonedData)) return clonedData;

  const selectedInstallmentData = selectedInstallmentMapper(
    clonedData,
    loanOrCard,
    installmentSelected
  );

  if (loanOrCard === 'loan') {
    clonedData.totalAmountContract = clonedData?.financingTotalAmount;
  }

  const {
    simulationToken,
    totalAmountContract,
    discountAmount,
    entryAmount,
    entryDate,
  } = clonedData;

  return {
    simulationToken,
    totalAmountContract,
    discountAmount,
    entryAmount,
    entryDate,
    selectedInstallmentData,
  };
};

const percent = (val, baseValue) => {
  return ((val * 100) / baseValue).toFixed(0);
};

const isWorkSchedule = () => {
  const today = new Date();
  if (today.getDay() === 0 || today.getDay() === 6) return false;

  return isNowTimeBetween('8:00:00', '19:59:59');
};

export {
  invoicementSimulationParametersMapper,
  agreementSimulationParametersMapper,
  simulationResponseMapper,
  percent,
  isWorkSchedule,
};

import styled, { css } from 'styled-components';

export const Container = styled.button`
  cursor: pointer !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: max-content;
  min-width: 390px;
  box-shadow: 2px 2px 4px rgba(37, 37, 37, 0.14902);
  padding: 18px;
  margin-top: 24px;
  border-radius: 8px;
  border: none;
  background: #f7f7f7;
  text-align: left;

  ${({ isMobile }) =>
    isMobile &&
    css`
      min-width: 95%;
    `} div {
    display: flex;
    flex-direction: column;
    span {
      white-space: pre-line;
      color: #616b6a;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      ${({ inDay }) =>
        inDay &&
        css`
          font-weight: bold;
          color: #107906;
          font-size: 14px;
        `}
    }
  }
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

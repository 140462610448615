import styled from 'styled-components';

export const PageWrapper = styled.main`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 80px;
  margin-top: ${props => (props.hasMobile ? '68px' : '0')};
`;

export const Header = styled.div`
  background: #f7f7f7;
`;

export const HeaderBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 120px 0;
  gap: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 60px 0;
  }
`;
export const HeaderContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const HeaderImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HeaderImage = styled.img``;
export const HeaderTitle = styled.h1`
  font-size: 3.4rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2.6rem !important;
    margin-bottom: 1em;
  }

  @media (max-width: 492px) {
    font-size: 1.6rem !important;
    margin-bottom: 1em;
  }
`;
export const HeaderText = styled.p`
  font-size: 1.42rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1em !important;

  @media (max-width: 768px) {
    font-size: 1.2rem !important;
    margin-bottom: 1em;
  }

  @media (max-width: 492px) {
    font-size: 1rem !important;
    margin-bottom: 1em;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  display: flex;
  max-width: 1270px;

  @media (max-width: 768px) {
    padding: 0 17px;
  }
`;

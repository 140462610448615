import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  border: none;
  margin-bottom: 1rem;
  font-weight: 500;
  div {
    display: flex;
    border: 1.5px solid #1f2b2a;
    border-radius: 50%;
    padding: 2px 6px;
    margin-right: 4px;
  }
`;

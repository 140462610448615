import React, { useState } from 'react';
import Accordion from '~/components/Accordion';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const AccordionInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <S.ContainerAccordion>
      <S.SpadeAccordion>
        <S.HandleAccordion
          onClick={() => {
            trackingSelectContent(
              isOpen
                ? 'faq:fechar:o-limite-do-emprestimo-influencia-no-limite-do-meu-cartao-de-credito'
                : 'faq:abrir:o-limite-do-emprestimo-influencia-no-limite-do-meu-cartao-de-credito',
              'emprestimofacilitado'
            );
            toggleAccordion();
          }}
        >
          <Accordion
            key="1"
            title="O limite do Empréstimo influencia no limite do meu cartão de crédito?"
            content="Não. Os limites são independentes e a utilização de um não influencia no limite do outro."
          />
        </S.HandleAccordion>
      </S.SpadeAccordion>
      <S.HandleAccordion
        onClick={() => {
          trackingSelectContent(
            isOpen
              ? 'faq:fechar:preciso-de-dinheiro-mas-nao-tenho-limite-pre-aprovado-o-que-fazer'
              : 'faq:abrir:preciso-de-dinheiro-mas-nao-tenho-limite-pre-aprovado-o-que-fazer',
            'emprestimofacilitado'
          );
          toggleAccordion();
        }}
      >
        <Accordion
          key="2"
          title="Preciso de dinheiro, mas não tenho limite pré-aprovado. O que fazer?"
          content="Sentimos por não poder ajudar nesse momento, mas a análise de crédito pode ser refeita mensalmente! Sugerimos que você tente de novamente daqui a 30 dias."
        />
      </S.HandleAccordion>
      <S.HandleAccordion
        onClick={() => {
          trackingSelectContent(
            isOpen
              ? 'faq:fechar:em-quanto-tempo-tenho-um-limite-pre-aprovado-apos-a-aquisicao-do-cartao-riachuelo-pl-ou-bandeira-ou-criacao-da-conta-midway'
              : 'faq:abrir:em-quanto-tempo-tenho-um-limite-pre-aprovado-apos-a-aquisicao-do-cartao-riachuelo-pl-ou-bandeira-ou-criacao-da-conta-midway',
            'emprestimofacilitado'
          );
          toggleAccordion();
        }}
      >
        <Accordion
          key="3"
          title="Em quanto tempo tenho um limite pré-aprovado após a aquisição do cartão Riachuelo (PL ou Bandeira) ou criação da conta Midway?"
          content="A liberação de limite pré-aprovado após a aquisição do cartão Riachuelo, seja Private Label (PL) ou Bandeira (BD), pode variar dependendo das políticas internas. Geralmente, a análise ocorre a partir de 30 dias de aquisição do cartão."
        />
      </S.HandleAccordion>
      <S.HandleAccordion
        onClick={() => {
          trackingSelectContent(
            isOpen
              ? 'faq:fechar:como-realizo-o-pagamentos-das-parcelas'
              : 'faq:abrir:como-realizo-o-pagamentos-das-parcelas',
            'emprestimofacilitado'
          );
          toggleAccordion();
        }}
      >
        <Accordion
          key="4"
          title="Como realizo o pagamentos das parcelas?"
          content="Você pode gerar boleto para pagamento da parcela a qualquer momento através do aplicativo Midway, Central de Atendimento, no site ou em qualquer Loja Riachuelo informando o seu CPF. Caso você tenha realizado o Empréstimo no aplicativo Midway, as parcelas são debitadas direto da sua Conta Digital conforme data de pagamento definida das parcelas."
        />
      </S.HandleAccordion>
    </S.ContainerAccordion>
  );
};

export default AccordionInfo;

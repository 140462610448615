import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1176px;
  display: flex;
  margin-bottom: 40px;

  @media screen and (max-width: 1310px) {
    width: 100% !important;
    max-width: 100%;
    margin-bottom: 20px;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Card = styled.div`
  max-width: 578px;
  min-height: 166px;
  padding: 24px 16px 24px 16px;
  background-color: #f7f7f7;
  border-radius: 16px;
  box-shadow: 0px 4px 6px -1px #1f2b2a1f;

  @media (max-width: 1310px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const Icon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  margin-right: 16px;
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  padding-bottom: 16px;
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

import { useSelector } from 'react-redux';
import { useNegotiation } from '~/context/negotiationV2';

const useCorrectText = () => {
  const { negotiationData, type } = useNegotiation();
  const days = negotiationData.overDueDays;
  const { toogles } = useSelector(state => state.featuretoggle);
  const enabledUseCorrectText = toogles.isEnabledUseCorrectText;

  const defaultTexts = {
    delayIn: 'Atraso no ',
    overdueTotal: 'Total em atraso',
    delayedDays: 'Dias em atraso',
    debitInOverdue: 'em atraso',
    acquittanceInOverdue: 'em atraso + próximas parcelas',
    acquittanceInOverdueButton: 'em atraso',
    invoicementInOverdue: 'em atraso',
  };

  const getCorrectText = textType => {
    if (!enabledUseCorrectText) {
      return defaultTexts[textType];
    }

    switch (textType) {
      case 'delayIn':
        return days <= 0 ? '' : defaultTexts.delayIn;
      case 'overdueTotal':
        return days <= 0
          ? type === 'cards'
            ? 'Valor da fatura'
            : 'Total em atraso'
          : defaultTexts.overdueTotal;
      case 'delayedDays':
        return days <= 0 ? 'Vencimento' : defaultTexts.delayedDays;
      case 'debitInOverdue':
        return days <= 0
          ? type === 'cards'
            ? 'total da fatura'
            : 'do próximo vencimento'
          : defaultTexts.debitInOverdue;
      case 'acquittanceInOverdue':
        return days <= 0
          ? 'total das próximas parcelas'
          : defaultTexts.acquittanceInOverdue;
      case 'acquittanceInOverdueButton':
        return days <= 0
          ? 'total das próximas parcelas'
          : defaultTexts.acquittanceInOverdueButton;
      case 'invoicementInOverdue':
        return days <= 0 ? '' : defaultTexts.invoicementInOverdue;
      default:
        return defaultTexts[textType];
    }
  };

  return {
    delayIn: () => getCorrectText('delayIn'),
    overdueTotal: () => getCorrectText('overdueTotal'),
    delayedDays: () => getCorrectText('delayedDays'),
    debitInOverdue: () => getCorrectText('debitInOverdue'),
    acquittanceInOverdue: () => getCorrectText('acquittanceInOverdue'),
    acquittanceInOverdueButton: () =>
      getCorrectText('acquittanceInOverdueButton'),
    invoicementInOverdue: () => getCorrectText('invoicementInOverdue'),
  };
};

export default useCorrectText;

// This wrapper was created to allow to easily mock the localStorage in jest tests.
// An example can be found in src/hooks/useSendInvoiceDataToBI.spec.js test

const getItem = key => localStorage.getItem(key);

const setItem = (key, value) => localStorage.setItem(key, value);

const removeItem = key => localStorage.removeItem(key);

export const LocalStorage = {
  getItem,
  setItem,
  removeItem,
};

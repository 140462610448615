import styled, { css } from 'styled-components';

import { colors } from '~/constants';

export const Container = styled.section`
  padding: 16px 16px 0px;
  background: ${colors.offWhite};
`;

export const Content = styled.div`
  max-width: 1176px;
  max-height: 432px;
  width: 100%;

  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 850px) {
    flex-direction: column;
    gap: 16px;
    max-height: none;

    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h1`
  color: ${colors.chumbo};
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 16px;

  @media (max-width: 720px) {
    font-size: 24px !important;
    line-height: 36px !important;
  }
`;

export const Text = styled.p`
  max-width: 560px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Image = styled.img`
  margin-right: -100px;
  width: 668px;

  ${({ mobile }) =>
    mobile &&
    css`
      width: 100%;
      margin: 0 auto;
    `};
`;

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import RenderBannerImage from './components/RenderBannerImage';
import { Banner } from './data';
import Card from './components/Card';
import TextPhoneNumber from './components/TextPhoneNumber';

import RouboIcon from '../../assets/img/Financial/cell-phone-insurance/rouboIcon.svg';
import FurtoSimplesIcon from '../../assets/img/Financial/cell-phone-insurance/furtoSimplesIcon.svg';
import FurtoQualificadoIcon from '../../assets/img/Financial/cell-phone-insurance/furtoQualificadoIcon.svg';
import DanosIcon from '../../assets/img/Financial/cell-phone-insurance/danosIcon.svg';
import ButtonFull from '../../assets/img/Financial/cell-phone-insurance/button-full.svg';
import SeguroBolsa from '../../assets/img/Financial/cell-phone-insurance/Seguro-Bolsa.png';
import ZurichLogo from '../../assets/img/Financial/cell-phone-insurance/zurichLogo.png';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor onClick={onClick} href={href} target="_blank">
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const CardWrapper = ({ title, icon, widthCard, href }) => {
  return (
    <S.ContainerCardWrapper>
      <S.CardWrapper minWidth={widthCard}>
        <S.CardAnchor href={href}>
          <S.Title>{title}</S.Title>
          <S.Icon src={icon} />
        </S.CardAnchor>
      </S.CardWrapper>
    </S.ContainerCardWrapper>
  );
};

const CellPhoneInsurance = () => {
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerCellPhoneInsurance(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(Banner);
    } catch (error) {
      setBannerData(Banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banner = bannerData[0] || Banner;

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banner} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Seguro Celular e Portáteis</S.TitleCards>
            <S.subTitleCards>
              Agora, com o Seguro Celular e Portáteis, você está protegido 24
              horas por dia! Planos para aparelhos novos, proteja seu Celular,
              Caixa de som portátil, Smartwatch e Tablet. Conheça todos os
              benefícios:
            </S.subTitleCards>
          </S.AdjustamentTitleCard>
          <S.ContainerCards>
            <Card
              icon={FurtoSimplesIcon}
              title="Furto simples"
              description="Quando o aparelho é levado e não há vestígios do crime, ou seja, naqueles casos em que o aparelho é furtado de cima de uma mesa por exemplo, sem que o segurado perceba."
            />
            <Card
              icon={FurtoQualificadoIcon}
              title="Furto qualificado"
              description="Quando o aparelho é levado, mas existem vestígios do crime, como corte na bolsa ou vidro do carro quebrado."
            />
            <Card
              icon={RouboIcon}
              title="Roubo"
              description="Quando o aparelho é levado mediante ameaça física e verbal ou uso de foça bruta."
            />
            <Card
              icon={DanosIcon}
              title="Danos acidentais por queda e líquidos"
              description="Quando o aparelho sofre um dano acidental decorrente de queda e colisão ou derramamento de líquidos que torne o aparelho inutilizável."
            />
          </S.ContainerCards>

          <S.AdjustamentTitleCard>
            <S.TitleCards>Seguro Celulares Usados</S.TitleCards>
            <S.subTitleCards>
              Com o Seguro Celulares Usados, você fica protegido! <br />
              Conheça todos os benefícios:
            </S.subTitleCards>
          </S.AdjustamentTitleCard>
          <S.ContainerCards>
            <Card
              icon={FurtoQualificadoIcon}
              title="Furto qualificado"
              description="Quando o aparelho é levado, mas existem vestígios do crime, como corte na bolsa ou vidro do carro quebrado."
            />
            <Card
              icon={RouboIcon}
              title="Roubo"
              description="Quando o aparelho é levado mediante ameaça física e verbal ou uso de foça bruta."
            />
            <S.ContentCardWrapper>
              <CardWrapper
                title="Central de atendimento Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/atendimento"
              />
              <CardWrapper
                widthCard="531px"
                title="Fale com a Laiz - Assistente virtual Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/chatonline"
              />
              <S.CardWrapperText>
                <S.WrapperText>
                  <S.CardWrapperUnderline href="https://midway.com.br/static/documents/tags/condicoes-particulares/2024.01.17_Condições Particulares do Seguro Proteção de Bens_Novos 2.pdf">
                    Condições particulares do Seguro Proteção de Bens - Novos
                  </S.CardWrapperUnderline>
                  Comercializado a partir de fevereiro de 2024
                </S.WrapperText>
                <S.WrapperText>
                  <S.CardWrapperUnderline href="https://midway.com.br/static/documents/tags/condicoes-particulares/2024.01.17_Condições Particulares do Seguro Proteção de Bens_Usados 2.pdf">
                    {' '}
                    Condições particulares do Seguro Proteção de Bens - Usados
                  </S.CardWrapperUnderline>
                  Comercializado a partir de fevereiro de 2024
                </S.WrapperText>
                <S.WrapperText>
                  <S.CardWrapperUnderline href="https://midway.com.br/static/documents/tags/condicoes-particulares/Condições Gerais Seguro Celular.pdf">
                    Condições gerais do Seguro Celular e Portáteis{' '}
                  </S.CardWrapperUnderline>
                  Comercializado a partir de fevereiro de 2024
                </S.WrapperText>
              </S.CardWrapperText>
            </S.ContentCardWrapper>
          </S.ContainerCards>

          <S.ContainerCardBlackTop>
            <S.CardLeft src={SeguroBolsa} />
            <S.CardRight>
              <S.Groups>
                <S.CardTitle>
                  Cobertura também para eletroportáteis!
                </S.CardTitle>
                <S.CardText paddingBottom="32px" fontWeight="500">
                  Proteção total para seus dispositivos com nosso Seguro Celular
                  e portáteis.
                </S.CardText>
                <S.CardText fontWeight="700">
                  Cobertura para aparelhos novos: Celular, Caixa de som
                  portátil, Smartwatch e Tablet.
                </S.CardText>
              </S.Groups>
            </S.CardRight>
          </S.ContainerCardBlackTop>
          <S.ContentText>
            <S.TextContent>
              Você pode contratar nas Lojas Riachuelo mais próximas ou, se
              preferir, pode optar pelas centrais de atendimento através dos
              telefones:{' '}
            </S.TextContent>
            <TextPhoneNumber
              text="Capitais e regiões metropolitanas:"
              phoneNumber="3004 5417 "
            />
            <TextPhoneNumber
              text="Demais localidades:"
              phoneNumber="0800 727 4417"
            />
            <TextPhoneNumber
              text="Deficiente auditivo:"
              phoneNumber="0800 722 0626"
            />
          </S.ContentText>

          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar o Seguro
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText paddingBottom="16px">
              Atendimento sinistro Zurich <br />
              Para avisar e acompanhar seu sinistro de forma rápida e prática
              acesse o site: <br />
              <Anchor
                href="https://www.zurich.com.br/pt-br/atendimento"
                fontSize="20px"
              >
                www.zurich.com.br/pt-br/atendimento
              </Anchor>
            </S.CardBlackBottomText>
            <S.CardBlackBottomText>
              Ou ligue para: <S.UnderlineText> 4020 8986 </S.UnderlineText>{' '}
              (Capitais e região metropolitana) ou{' '}
              <S.UnderlineText> 0800 085 8986 </S.UnderlineText> <br /> de
              segunda à sexta-feira, das 8h às 20h e aos sábados das 8h às 18h.{' '}
              <br /> WhatsApp: 11 2890 2121 <br />
              SAC: <S.UnderlineText>0800 284 4848 </S.UnderlineText>-
              atendimento 24h. Deficiente Auditivo: 0800 275 8585 <br />{' '}
              Ouvidoria: <S.UnderlineText> 0800 770 1061</S.UnderlineText>
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>

          <S.ContentZurich>
            <S.ZurichLeft src={ZurichLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                Fundada na Suíça em 1872, com sede na cidade de Zurique, somos
                uma das seguradoras globais mais experientes do mundo. Com cerca
                de 56 mil funcionários, oferecemos uma ampla gama de produtos e
                serviços de ramos elementares e de vida em mais de 210 países e
                territórios. Atendemos pessoas físicas e jurídicas – de pequenas
                empresas a multinacionais.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>
          <S.ContentFinal>
            <S.ContentTextFinal>
              Seguro Celular garantido pela Zurich Minas Brasil Seguros S/A –
              CNPJ 17.197.385/0001-21 – Código Susep: 05495 e Processo SUSEP
              15414.005122/2011-69.O registro do produto é automático e não
              representa aprovação ou recomendação por parte da Susep. Central
              de atendimento Zurich: SAC: 0800 284 4848, Deficiente Auditivo:
              0800 275 8585, Horário de atendimento: 24 horas, 7 dias na semana;
              Ouvidoria: 0800 770 1061, Horário de atendimento: segunda a
              sexta-feira, das 8h30 às 18h, exceto feriados. Link da plataforma
              digital oficial para registro de reclamações dos consumidores dos
              mercados supervisionados{' '}
              <Anchor href="https://www.consumidor.gov.br" fontSize="16px">
                (www.consumidor.gov.br)
              </Anchor>
              . Saiba mais em{' '}
              <Anchor href="https://www.midway.com.br" fontSize="16px">
                www.midway.com.br
              </Anchor>
              . Vigência inicia-se a partir do dia de contratação do produto.
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default CellPhoneInsurance;

import Axios from 'axios';
import { useState, useCallback, useRef } from 'react';

import { simulate } from '~/services/InvoiceInstallment';
import { useAuth } from '~/context/auth';

const useSimulateInstallment = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const { documentNumber, maskedPan, machineId: deviceId } = useAuth();
  const cancelRequest = useRef(null);

  const memoized = useCallback(
    async entryValue => {
      setLoading(true);
      setError(false);
      // eslint-disable-next-line no-unused-expressions
      cancelRequest.current && cancelRequest.current();
      const cancelToken = new Axios.CancelToken(source => {
        cancelRequest.current = source;
      });

      try {
        const response = await simulate({
          maskedPan,
          documentNumber,
          deviceId,
          entryValue,
          cancelToken,
        });
        setData(response.data);
        setLoading(false);
      } catch (err) {
        if (Axios.isCancel(err)) {
          return;
        }
        setError(true);
        setLoading(false);
      }
    },
    [deviceId, documentNumber, maskedPan]
  );

  return [
    memoized,
    {
      loading,
      error,
      data,
    },
  ];
};

export default useSimulateInstallment;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  @media (max-width: 1000px) {
    justify-content: flex-start;
    margin-top: 16px;
  }
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #cacecd;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 1000px) {
    justify-content: flex-start;
  }
`;

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 1000px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;
export const TableGroups = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 400px;
  @media (max-width: 1000px) {
    max-width: 600px;
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  max-width: 400px;
  @media (max-width: 1000px) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;
    max-width: 600px;
  }
`;

export const Title = styled.p`
  font-family: Montserrat !important;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1f2b2a;
  padding-bottom: 24px;
`;

export const SubTitle = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  @media (max-width: 1000px) {
  }
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;
export const NumberStyle = styled.span`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #a06d2c;
`;

export const Promotion = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  color: #107906;
  @media (max-width: 1000px) {
    text-align: left;
  }
`;

export const ValueBold = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  color: #1f2b2a;
  margin-right: 2px;
`;
export const ValueDescription = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
`;

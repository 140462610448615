export const banner = [
  {
    name: 'BannerCartaoMasterCard',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-cartao-mastercard-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-cartao-mastercard.jpeg',
    title:
      'Com o cartão riachuelo mastercard você tem inúmeros benefícios com a gente e com vários parceiros.',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

import React from 'react';
import * as S from './styles';

const DownloadStoreButton = ({ storeName, link, icon, onClick }) => {
  return (
    <S.StyledButton
      onClick={() => {
        window.open(link, '_blank');
        onClick();
      }}
    >
      {icon && <S.IconDownload src={icon} alt="" />}
      Download via <br />
      <S.TextBold fontSize="18px">{storeName}</S.TextBold>
    </S.StyledButton>
  );
};

export default DownloadStoreButton;

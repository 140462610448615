import styled, { css } from 'styled-components';

export const ItemText = styled.span`
  display: flex;
  padding: 15px;
  font-weight: 700;
  color: #00726d;
  cursor: pointer;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #252525;
  border-bottom: 1.2px solid #cacecd;

  ${props =>
    props.noBorder &&
    css`
      border-bottom: none;
    `}

  margin-top: 20px;
  span {
    text-align: start;

    & + span {
      text-align: end;
      font-weight: bold;
    }
    small {
      font-size: 11px;
    }
    margin-bottom: 20px;
  }
`;

export const ContainerInvoicement = styled.div`
  width: 100%;

  .title {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    ${({ isMobile }) =>
      isMobile &&
      css`
        flex-direction: column;
        align-items: center;
      `}
    span {
      color: #000;
      font-weight: 700;
      font-size: 1rem;
      white-space: nowrap;
    }
  }
  .content {
    flex-direction: column;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 0 12px;
    color: #000;
    div {
      padding: 4px;
      display: flex;
      justify-content: space-between;
    }
  }
`;

import styled from 'styled-components';

export const SectionForm = styled.div`
  margin: 30px 0;

  .container {
    display: flex;
    flex-direction: column;
  }

  .success {
    margin-bottom: 20px;
  }

  .qrcode {
    width: 240px;
    margin-top: 20px;
    border-radius: 3px;
  }

  b {
    font-weight: bold;
  }

  h1 {
    font: bold 50px Montserrat !important;
    color: #000;
    line-height: 50px;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font: bold 36px Montserrat !important;
      letter-spacing: 0px;
      color: #000000;
      width: 290px;
    }
  }

  h2 {
    font-size: 25px !important;
    margin: 0;
    color: #000000;

    @media screen and (max-width: 768px) {
      padding-right: 0px;
      font: 16px Montserrat !important;
      letter-spacing: 0px;
      width: 100%;
    }
  }

  h2.espacamento {
    margin: 35px 0 20px 0;
  }

  a {
    background: #000000;
    padding: 15px 80px;
    border-radius: 50px;
    color: #47c8cd;
    font-size: 15px;
    max-width: 300px;
    text-align: center;
    transition: all 0.2s ease-out;
    align-self: flex-end;
    margin-top: 10px;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 30px;
    }

    &:hover {
      text-decoration: none;
      color: #47c8cd;
    }
  }

  .disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }

  div.ahl-checkbox {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px !important;
      margin: 0;

      @media (max-width: 630px) {
        font-size: 13px !important;
      }
      @media (max-width: 530px) {
        font-size: 11px !important;
      }
    }
  }

  .checked {
    background-color: #065a5c;
  }

  div.ahl-check {
    width: 23px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-color: #000;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;

    cursor: pointer;

    margin-right: 5px;

    @media screen and (max-width: 768px) {
      width: 50px;
    }

    &-selected {
      img {
        width: 13px;
      }
    }
  }

  .pre {
    padding: 30px 0;
    align-items: center;

    h2 {
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }

  input[type='checkbox'] {
    margin-right: 10px;
    margin-top: 4px;
  }

  .label-float {
    position: relative;
    padding-top: 22px;
    margin-top: 10px;
  }

  .label-float input {
    border: 0;
    border-bottom: 2px solid lightgrey;
    outline: none;
    min-width: 180px;
    width: 100%;
    font-size: 16px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-appearance: none;
    border-radius: 0;
  }

  .label-float input:focus {
    border-bottom: 2px solid #00595b;
  }

  .label-float input::placeholder {
    color: transparent;
  }

  .label-float label {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 16px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
  }

  /* .label-float input:required:invalid + label {
    color: red;
  }
  .label-float input:focus:required:invalid {
    border-bottom: 2px solid red;
  }
  .label-float input:required:invalid + label:before {
    content: '*';
  } */
  .label-float input:focus + label,
  .label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    margin-top: 0;
    color: #00595b;
  }

  /* @media screen and (max-width: 768px) {
    min-height: 100vh;
  } */
`;

export const LinkApp = styled.div`
  display: flex;

  a {
    padding: 0;
    margin: 30px 5px;
  }
`;

export const ModalBody = styled.div`
  .indication__modal-header {
    padding: 16px;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    p {
      color: #006464;
      font-size: ${props => props.headerFontSize};
      font-weight: bold;
      padding-bottom: 0;
      line-height: 35px;
      @media (max-width: 992px) {
        line-height: 20px;
        font-size: 15px;
      }
    }
  }
  .indication__modal-body {
    padding: 16px;
    background-color: #efefef;
    border-radius: 0 0 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: #252525;
      font-size: ${props => props.contentFontSize};
      line-height: 35px;
      @media (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

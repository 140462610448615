export const Banner = [
  {
    name: 'BannerSeguroCelularPortateis',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/seguro-celular-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/Seguro-Celular-web.png',
    title:
      'Seguro celular, proteção para aparelhos novos e usados mantenha seus dispositivos protegidos',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/seguro-celular-e-portateis',
  },
];

import React from 'react';
import { Button } from '@midway/web-ui-component';
import * as S from './styles';

const Accessbility = () => (
  <S.Accessibility
    id="ready-page-for-screen-reader"
    aria-label="Página de acessibilidade carregada"
  >
    <S.AccessibilityContent>
      <S.Title>Acessibilidade Midway</S.Title>
      <S.Text>
        A Midway acredita que independente de suas necessidades, todos possuem o
        direito de navegar em nosso site com a melhor experiência, oferecendo
        moda de um jeito amplamente acessível.
      </S.Text>
      <S.AccessibilityBox>
        <S.Text>
          Clique no botão para baixar gratuitamente o app de tecnologia
          assistiva NVDA, para pessoas com deficiência física e baixa visão. O
          software está disponível apenas para sistema operacional Windows.
        </S.Text>
        <S.Text>
          Para a Midway, promover inclusão e diversidade são prioridades.
        </S.Text>
        <S.AccessibilityDownloadContent>
          <S.AccessibilityPatternIcon icon={['mid', 'nv-access']} />
          <Button
            fontSize="20"
            title="Clique para fazer download"
            onClick={() => {
              window.open(
                'https://www.nvaccess.org/files/nvda/releases/2019.1.1/nvda_2019.1.1.exe',
                '_blank'
              );
            }}
          />
        </S.AccessibilityDownloadContent>
      </S.AccessibilityBox>
    </S.AccessibilityContent>
  </S.Accessibility>
);

export default Accessbility;

import React from 'react';
import iconConfirm from '~/assets/img/icons/iconConfirmGreen.svg';
import * as S from './styles';

const PersonalData = props => {
  const maskPhone = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1');
  };

  const maskDate = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1');
  };

  const onChange = e => {
    if (e.target?.name === 'birthdate') {
      const value = maskDate(e.target.value);
      e.target.value = value;
    } else if (e.target?.name === 'contactNumber') {
      const value = maskPhone(e.target.value);
      e.target.value = value;
    }
    props.onChange(e);
  };
  const toggleCheck = () => {
    const eventData = {
      target: {
        name: 'acceptReceiveNews',
        value: props.form.acceptReceiveNews === 'Y' ? 'N' : 'Y',
      },
    };
    props.onChange(eventData);
  };

  return (
    <S.Forms>
      <h1>Simulador de Empréstimo</h1>
      <h2>Para simular o empréstimo, precisamos de alguns dados seus:</h2>
      <S.FormWrapper>
        <div>
          <S.InputWrapper>
            <input
              id="date"
              value={props.form.birthdate}
              onChange={onChange}
              name="birthdate"
              pattern=".+"
              required
              className="no-border-focus"
            />
            <label id="teste" className="label" htmlFor="date">
              Data de nascimento
            </label>
          </S.InputWrapper>

          <S.FloatingLabel>
            <select
              className="floating-select no-border-focus"
              value={props.form.gender}
              onChange={onChange}
              name="gender"
              id="gender"
            >
              <option value="" />
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
              <option value="Outro">Outro</option>
            </select>

            <label
              htmlFor="gender"
              className={props.form.gender.length ? 'Active' : ''}
            >
              Gênero{' '}
            </label>
          </S.FloatingLabel>
        </div>

        <div>
          <S.InputWrapper>
            <input
              pattern=".+"
              required
              id="phone"
              value={props.form.contactNumber}
              onChange={onChange}
              name="contactNumber"
              className="no-border-focus"
            />
            <label className="label" htmlFor="phone">
              Celular
            </label>
          </S.InputWrapper>

          <S.InputWrapper>
            <input
              pattern=".+"
              required
              id="email"
              value={props.form.email}
              onChange={onChange}
              name="email"
              className="no-border-focus"
            />
            <label className="label" htmlFor="email">
              E-mail
            </label>
          </S.InputWrapper>
        </div>
      </S.FormWrapper>

      <div className="ahl-checkbox">
        <div className="ahl-check" onClick={toggleCheck}>
          {props.form.acceptReceiveNews === 'Y' && (
            <div className="ahl-check-selected">
              <img src={iconConfirm} alt="" />
            </div>
          )}
        </div>
        <label>
          {' '}
          Desejo receber ofertas e novidades da Midway via app, mensagem ou
          e-mail.
        </label>
      </div>
    </S.Forms>
  );
};

export default PersonalData;

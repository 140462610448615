import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.section`
  padding: 40px 16px;
  max-width: 1176px;

  @media screen and (min-width: 480px) {
    padding: 48px 32px;
  }
  @media screen and (min-width: 900px) {
    padding: 72px 132px;
  }
  @media screen and (min-width: 1200px) {
    padding: 72px 0;
    margin: auto;
  }
`;

export const Title = styled.h2`
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2;
  margin-right: auto;
  margin-bottom: 32px;

  @media screen and (min-width: 900px) {
    font-size: 1.5rem !important;
    margin-bottom: 48px;
  }
`;

export const SessionHeader = styled.div`
  padding-top: 60px;
`;

export const InputWrapper = styled.div`
  padding: 18px 24px;
  background-color: #f7f7f7;
  border-radius: 40px;
  margin-bottom: 32px;
  justify-content: space-between;
  display: flex;
  border: 1px solid #f7f7f7;

  &:focus-within {
    border: 1px solid #000000;
  }

  svg {
    height: 18px;
    width: 48px;
    stroke: #49454f;
  }
`;

export const Input = styled.input`
  all: unset;
  font-size: 1rem;
  font-weight: 500;
  color: #49454f;
  width: 100%;

  &::placeholder {
    font-size: 1rem;
    font-weight: 500;
    color: #49454f;
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: auto;
  flex-wrap: nowrap;
  scrollbar-width: none;
  margin-bottom: 32px;
`;

export const CategoryButton = styled.button`
  all: unset;
  background-color: ${props => (props.active ? '#e4602f14' : 'transparent')};
  border: 2px ${props => (props.active ? '#e4602f' : '#3b4443')} solid;
  border-radius: 80px;
  color: ${props => (props.active ? '#e4602f' : '#3b4443')};
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 14px;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 6px;
  }
`;

export const IconLoading = styled.div`
  width: 100%;

  svg {
    display: block;
    width: 76px;
    height: 76px;
    margin: auto;
    animation: ${rotate} 2s linear infinite;
  }
`;

import { useSelector } from 'react-redux';

function useDynamics() {
  const { toogles } = useSelector(state => state.featuretoggle);
  const sendDynamics = ({ event, client, token }) => {
    if (toogles.isEnabledSendToDynamics) {
      window.msdynmkt.setUser({ authId: client });
      window.msdynmkt.trackEvent({
        name: process.env.REACT_APP_DynamicsNameKey,
        ingestionKey: process.env.REACT_APP_DynamicsIngestionKey,
        version: '1.0.0',
        properties: {
          evento: event,
          marca: 'Midway',
          interacao: 0,
          campanha: 'miajuda',
          bindingid: token,
          detalhes: '',
        },
      });
    }
  };

  return [sendDynamics];
}

export default useDynamics;

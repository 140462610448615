import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Button, Icon, Image } from '@midway/web-ui-component';
import Select from 'react-select';
import moment from 'moment';
import { toast } from 'react-toastify';
import history from '~/services/history';
import Banner from '~/assets/img/Financial/lending/banner.png';
import error from '~/assets/img/Icones/error.svg';
import empty from '~/assets/img/Icones/empty.svg';
import BannerMobile from '~/assets/img/monthly-winners/banner_mobile.png';
import {
  getLastLuckyNumbers,
  getLuckyNumbers,
} from '~/services/luckNumbersApi';
import { winnersIcons, optionsMonths, optionsYears } from './constants';
import * as S from './styles';
import 'moment/locale/pt-br';

const BannerWithText = ({ text, boldText, isMobile }) => {
  return (
    <S.BannerWithTextContainer>
      <div className="container h-100">
        <div className="row h-100 icon-text-container">
          <div className="col-lg-1 col-md-12 col-sm-12 h-100 d-flex align-items-center justify-content-start icon-container">
            <Icon
              icon={['mid', 'trophy']}
              color="#006464"
              size={isMobile ? '6x' : '3x'}
            />
          </div>
          <div className="col-lg-11 col-md-12 col-sm-12 h-100 d-flex align-items-center text-container">
            <span>
              {text}
              <b>{boldText}</b>
            </span>
          </div>
        </div>
      </div>
    </S.BannerWithTextContainer>
  );
};

BannerWithText.propTypes = {
  text: PropTypes.string.isRequired,
  boldText: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
};

BannerWithText.defaultProps = {
  boldText: '',
};

const CardCircleMonthlyWinner = ({
  title,
  icon,
  luckyNumber,
  name,
  location,
  cpf,
  index,
  theme,
}) => {
  const ariaLuckyNumber = luckyNumber ? `Número da sorte: ${luckyNumber}` : '';
  const [firstThreeDigitsCpf] = cpf.split('.');
  const ariaLabelCard = `${title},: ${ariaLuckyNumber}, nome: ${name}, de ${location}, cujos 3 primeiros dígitos do cpf são ${firstThreeDigitsCpf}`;
  return (
    <S.CardCircleMonthlyWinnerContainer
      index={index}
      aria-label={ariaLabelCard}
      theme={theme}
    >
      <div>
        <S.IconContainer>
          <Icon icon={icon} color="#006464" size="2x" />
        </S.IconContainer>
      </div>
      <S.CardTitle>{title}</S.CardTitle>
      <div className="d-flex justify-content-center lucky-number-container">
        {!!luckyNumber && (
          <>
            <span>Número da sorte: &nbsp;</span>
            <S.LuckyNumber>{luckyNumber}</S.LuckyNumber>
          </>
        )}
      </div>
      <S.PersonContainer theme={theme}>
        <S.Name>{name}</S.Name>
        <S.Location>{location}</S.Location>
      </S.PersonContainer>
      <span className="text-center">
        <b>CPF</b>
      </span>
      <p className="text-center">{cpf}</p>
    </S.CardCircleMonthlyWinnerContainer>
  );
};

CardCircleMonthlyWinner.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.array.isRequired,
  luckyNumber: PropTypes.string,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  cpf: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
};

CardCircleMonthlyWinner.defaultProps = {
  luckyNumber: '',
};

const MessageText = ({ title, subTitle, icon }) => {
  return (
    <S.ErrorContainer>
      <S.ErrorContent>
        <S.ImageWrapper>
          <Image src={icon} alt="erro" />
        </S.ImageWrapper>

        <S.ErrorText>{title}</S.ErrorText>
        <S.TitleErrorMessage>{subTitle}</S.TitleErrorMessage>
      </S.ErrorContent>
    </S.ErrorContainer>
  );
};

export default function MonthlyWinners() {
  const [selectMoth, setSelectedMoth] = useState(null);
  const [selectYear, setSelecteYear] = useState(null);
  const [showErrorMessage, setErrorMessage] = useState(false);
  const [search, setSearch] = useState(false);
  const [lastLuckNumbers, setlastLuckNumbers] = useState([]);
  const [luckNumbers, setLuckNumbers] = useState([]);
  const [monthAndYear, setMonthAndYear] = useState({
    month: '',
    year: '',
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  const myRef = useRef(null);
  const textNormalize = m => m?.charAt(0)?.toUpperCase() + m?.slice(1);

  const executeScrollTop = () => myRef.current.scrollIntoView();

  const setMonthAndFocus = () => {
    window.location.reload();
  };

  const getLast = async () => {
    try {
      const response = await getLastLuckyNumbers();

      setErrorMessage(false);
      setlastLuckNumbers(response.data);
      setSearch(false);
    } catch {
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    getLast();
  }, []);

  const handlerGetWinners = async (y, m) => {
    try {
      const reponse = await getLuckyNumbers(y, m);
      setErrorMessage(false);
      setLuckNumbers(reponse.data);
    } catch {
      setErrorMessage(true);
    }
  };

  const searchByMonthAndYear = () => {
    if (selectMoth?.value && selectYear?.value) {
      setMonthAndYear({ month: selectMoth?.label, year: selectYear.value });
      handlerGetWinners(selectYear.value, selectMoth.value);
      setSearch(true);
      executeScrollTop();
    } else {
      toast.warn('Precisa selecionar o mês e ano');
      // alert('Precisa selecionar o mês e ano');
    }
  };

  const hasLineBreak = isMobile ? '\n' : '';
  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 576);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const formatDate = (date, type) => {
    return moment(date).format(type);
  };

  const MONTH = monthAndYear.month
    ? monthAndYear.month
    : textNormalize(formatDate(lastLuckNumbers[0]?.sortitionDate, 'MMMM'));

  const YEAR = monthAndYear.year
    ? monthAndYear.year
    : formatDate(lastLuckNumbers[0]?.sortitionDate, 'YYYY');

  const lastYear = formatDate(lastLuckNumbers[0]?.sortitionDate, 'YYYY');
  const list = search ? luckNumbers : lastLuckNumbers;

  return (
    <S.Container
      id="ready-page-for-screen-reader"
      aria-label="Página de Sorteados carregada"
    >
      <S.ImageContainer>
        <Image
          src={isMobile ? BannerMobile : Banner}
          alt="Imagem com uma mulher sorrindo olhando diretamente para câmera."
        />
      </S.ImageContainer>
      <Breadcrumb
        routeBack="/seguros"
        textRouteBack="Seguros e assistência"
        actualPageText="Sorteados"
        history={history}
      />
      <div ref={myRef} className="container content-container">
        <S.Section>
          <S.Title className="content-container-title">
            Sorteados {'\n'} pela Midway
          </S.Title>
          <S.Subtitle>
            Confira aqui todos os sorteios do mês de {'\n'} {MONTH} de {YEAR}, e
            dos meses anteriores.
          </S.Subtitle>
        </S.Section>
        <S.Instructions>
          <S.InstructionsTitle>
            Procedimento para recebimento do prêmio
          </S.InstructionsTitle>
          <p>
            {' '}
            Serão feitas diversas tentativas de contato com o cliente
            contemplado. Nos casos que não obtivermos sucesso telefônico
            enviaremos um e-mail e um SMS contando sobre o prêmio e orientando o
            procedimento para resgate, solicitando que envie a documentação para
            o e-mail:{' '}
            <a href="mailto:documentosincentivo@capitalizacao.com">
              documentosincentivo@{hasLineBreak}capitalizacao.com.{' '}
            </a>
            O e-mail é exclusivo para envio de documentos.
            <br /> Em caso de dúvidas o ganhador poderá procurar uma de nossas
            filias ou ligar em nossa central de atendimento, número que consta
            atrás do cartão Riachuelo ou no próprio site.
          </p>
        </S.Instructions>
      </div>
      <BannerWithText
        text="Clientes sorteados no mês de "
        boldText={`${MONTH} de ${YEAR}`}
        isMobile={isMobile}
      />

      {list.length > 0 ? (
        <S.CardsGrid className="container">
          {list?.map((elm, index) => {
            return (
              <CardCircleMonthlyWinner
                title={elm?.product}
                luckyNumber={elm?.luckyNumber}
                name={elm.name}
                location={elm?.location}
                cpf={elm?.taxId}
                icon={winnersIcons(elm?.product)}
                key={index}
                index={index}
                theme="gray"
              />
            );
          })}
        </S.CardsGrid>
      ) : (
        <>
          {showErrorMessage ? (
            <MessageText
              icon={error}
              title="Algo deu errado..."
              subTitle=" Infelizmente não podemos exibir a lista de sorteados no momento,
       tente novamente mais tarde"
            />
          ) : (
            <MessageText
              icon={empty}
              title="Não temos sorteados!"
              subTitle="Neste mês não tivemos sorteados."
            />
          )}
        </>
      )}

      <BannerWithText
        text="Sorteados dos meses anteriores"
        isMobile={isMobile}
      />

      <S.Row>
        <>
          <S.LastMonthsContainer>
            <S.LastMonthsTitle>Sorteados pela Midway</S.LastMonthsTitle>
            <S.TitleSearch>Procure pelos campos de ano e mês</S.TitleSearch>
            <S.MonthsSearch>
              <S.Row>
                <div style={{ marginBottom: 10 }}>
                  <S.Row>
                    <S.dividerSearch>
                      <div style={{ marginBottom: 10 }}>
                        <S.TitleSearch>Escolha o ano</S.TitleSearch>
                      </div>
                      <Select
                        styles={S.customStyles}
                        defaultValue={selectYear}
                        onChange={setSelecteYear}
                        placeholder="Selecione"
                        options={optionsYears(lastYear)}
                      />
                    </S.dividerSearch>
                    <S.dividerSearch>
                      <div style={{ marginBottom: 10 }}>
                        <S.TitleSearch>Escolha o mês</S.TitleSearch>
                      </div>
                      <Select
                        placeholder="Selecione"
                        styles={S.customStyles}
                        defaultValue={selectMoth}
                        onChange={setSelectedMoth}
                        options={optionsMonths}
                      />
                    </S.dividerSearch>
                  </S.Row>
                </div>
                <S.dividerSearch>
                  <div style={{ marginTop: 40 }}>
                    <Button
                      aria-label="Buscar sorteados"
                      state="primary"
                      block
                      title="Buscar"
                      onClick={searchByMonthAndYear}
                    />
                  </div>
                </S.dividerSearch>
              </S.Row>
            </S.MonthsSearch>
          </S.LastMonthsContainer>
        </>
        <S.BarSpace />
        <>
          <S.LastMonthsContainer>
            <div style={{ paddingBottom: 50 }}>
              <S.LastMonthsTitle>últimos Sorteados</S.LastMonthsTitle>
              <S.TitleSearch>Busca rápida pelos útimos sorteados</S.TitleSearch>
            </div>
            <S.MonthsSearch>
              <S.MonthContainer
                onClick={() => {
                  setMonthAndFocus(
                    textNormalize(
                      formatDate(lastLuckNumbers[0]?.sortitionDate, 'MMMM')
                    ),
                    formatDate(lastLuckNumbers[0]?.sortitionDate, 'YYYY')
                  );
                }}
                aria-label={`Item clicável- Mês: ${MONTH}, número de ganhadores: ${lastLuckNumbers.length} `}
              >
                <span className="month-name">
                  {textNormalize(
                    formatDate(lastLuckNumbers[0]?.sortitionDate, 'MMMM')
                  )}{' '}
                  - {formatDate(lastLuckNumbers[0]?.sortitionDate, 'YYYY')}
                </span>
                <span className="winner-number">
                  {lastLuckNumbers.length} ganhadores
                </span>
              </S.MonthContainer>
            </S.MonthsSearch>
          </S.LastMonthsContainer>
        </>
      </S.Row>
    </S.Container>
  );
}

import React, { useState, useEffect } from 'react';
import FilteredPartners from 'src/pages/CardBenefits/tabs/MidwayPartners/FilteredPartners';
import { IconSearch, IconLoader, IconX } from '@tabler/icons-react';
import FeaturedPartners from 'src/pages/CardBenefits/tabs/MidwayPartners/FeaturedPartners';
import useCardBenefits from '~/pages/CardBenefits/hooks/useCardBenefits';
import { trackingSelectContent } from '~/analytics';
import { slugify } from '~/utils/utils';
import * as S from './styles';

const MidwayPartners = () => {
  const {
    filteredPartners,
    categories,
    featuredPartners,
    setSearch,
    selectedCategories,
    setSelectedCategories,
    loading,
  } = useCardBenefits();

  const [usedSearch, setUsedSearch] = useState(false);

  const handleSearchChange = ev => {
    setSearch(ev.target.value);
    validateSearch(ev.target.value);
  };

  const validateSearch = search => {
    if (search.length > 0) {
      return setUsedSearch(true);
    }
    setUsedSearch(false);
  };

  useEffect(() => {
    if (usedSearch) {
      trackingSelectContent('buscar', 'beneficios');
    }
  }, [usedSearch]);

  const handleCategoryClick = category => {
    setSelectedCategories(prevState => {
      const copyArr = [...prevState];
      const position = copyArr.indexOf(category);

      if (position === -1) copyArr.push(category);
      else copyArr.splice(position, 1);

      return copyArr;
    });
    trackingSelectContent(`filtro:${slugify(category)}`, 'beneficios');
  };

  const handleCleanCategories = () => {
    setSelectedCategories([]);
    trackingSelectContent('filtro:todos', 'beneficios');
  };

  const haveNoCategoriesSelected = selectedCategories.length === 0;
  const categoryKeys = Object.keys(categories);

  if (loading)
    return (
      <S.Container>
        <S.IconLoading>
          <IconLoader />
        </S.IconLoading>
      </S.Container>
    );

  return (
    <S.Container>
      <S.Title>Parceiros em destaque</S.Title>
      <FeaturedPartners partners={featuredPartners} />
      <S.SessionHeader>
        <S.Title>Todos parceiros</S.Title>
      </S.SessionHeader>
      <S.InputWrapper>
        <S.Input
          type="text"
          onChange={handleSearchChange}
          placeholder="Buscar parceria"
        />
        <IconSearch />
      </S.InputWrapper>
      <S.CategoryWrapper>
        <S.CategoryButton
          active={haveNoCategoriesSelected}
          onClick={handleCleanCategories}
        >
          Todos
        </S.CategoryButton>
        {categoryKeys.map(key => (
          <S.CategoryButton
            key={key}
            active={selectedCategories.includes(key)}
            onClick={() => handleCategoryClick(key)}
          >
            {categories[key]}
            {selectedCategories.includes(key) && <IconX />}
          </S.CategoryButton>
        ))}
      </S.CategoryWrapper>
      <FilteredPartners partners={filteredPartners} />
    </S.Container>
  );
};

export default MidwayPartners;

import React, { useEffect } from 'react';
import useIsMobile from '~/hooks/useIsMobile';
import { tagMiAjudaButton } from '~/services/tagging';
import { gtagSelectContent, gtagViewContent } from '~/utils/TaggingGA4';
import { isWorkSchedule } from '~/utils/negotiationHelpers';
import {
  ContractsContainer,
  ContractsInformation,
  ContractsDetails,
  HelpRedirect,
  HelpReturn,
  WhatsAppIcon,
} from './styles';

const GTAG_CONTENT_PAGE = 'seus-contratos-negocie-pelo-whatsapp';
const GTAG_CONTENT_PAGE_LIST = 'selecione-o-contrato-que-deseja-negociar';

const GTAG_FLOW = 'miajuda';

function DescriptionHelp({ setHelperActive, contracts }) {
  const { isMobile } = useIsMobile();
  const gtagParams = {
    proposta: 'multipla',
  };

  const handleRedirectWhatsApp = () => {
    const contentTypeGA = isWorkSchedule()
      ? 'negociar-via-whatsapp'
      : 'negociar-via-Whatsapp:monest';
    gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, contentTypeGA, gtagParams);

    const urlWPRedirect = isWorkSchedule()
      ? 'https://api.whatsapp.com/send/?phone=551130030950&text=01%C3%A1%21+Estava+no+portal+negocia%C3%A7%C3%B5es+mi+ajuda+e+gostaria+de+atendimento.&type=phone number&app absent=0'
      : 'https://api.whatsapp.com/message/UEC33LPBGBQBJ1?autoload=1&app_absent=0';

    tagMiAjudaButton('ajuda');
    window.open(urlWPRedirect, '_blank');
  };

  const handleReturnAutoAtendimento = () => {
    gtagSelectContent(
      GTAG_CONTENT_PAGE,
      GTAG_FLOW,
      'continuar-no-autoatendimento',
      gtagParams
    );

    gtagViewContent(GTAG_CONTENT_PAGE_LIST, GTAG_FLOW, gtagParams);

    setHelperActive(false);
  };

  useEffect(() => {
    gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW, gtagParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContractsContainer>
        <h2>Seus contratos</h2>
        <ContractsInformation>
          <p>
            {isWorkSchedule() ? (
              <>
                Você possui <span>{contracts.length}</span> a negociar. Negocie
                diretamente com nossos consultores via WhatsApp para receber uma
                oferta personalizada.
              </>
            ) : (
              <>
                Você possui <span>{contracts.length}</span> a negociar. Negocie
                via Whatsapp para receber uma oferta personalizada.
              </>
            )}
          </p>
        </ContractsInformation>
        <ContractsDetails>
          {contracts.map(contract => {
            return (
              <p key={contract.contract}>
                <span>{contract?.productDescription}:</span>
                {contract.overdueDays <= 0
                  ? 'Em dia'
                  : `${contract.overdueDays} dias em atraso`}
              </p>
            );
          })}
          <p className="contacts">
            Ou Você pode entrar em contato pelos canais de atendimento:
            <span>3004-5417</span> capitais e regiões metropolitanas
            <span> 0800-727-4417</span> demais localidades
          </p>
        </ContractsDetails>
        <HelpRedirect isMobile={isMobile} onClick={handleRedirectWhatsApp}>
          <WhatsAppIcon /> Negociar via WhatsApp
        </HelpRedirect>
      </ContractsContainer>
      <HelpReturn onClick={handleReturnAutoAtendimento}>
        Continuar no autoatendimento
      </HelpReturn>
    </>
  );
}
export default DescriptionHelp;

import { breakpoints } from '../../utils';
import styled from 'styled-components';

export const WrapperContainer = styled.div`
  width: 100%;
  padding: 3rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
`;

export const WrapperInner = styled.div`
  width: 100%;
  max-width: 30rem;

  @media (${breakpoints[1176]}) {
    max-width: 73.5rem;
  }
`;

import styled from 'styled-components';

export const ContainerBreadcrumb = styled.div`
  display: flex;

  .breadcrumb-content {
    padding: 36px;
    padding-right: 15px;
    padding-left: 15px;
    font: inherit;
    vertical-align: baseline;

    .breadcrumb-item {
      display: flex;
      justify-content: space-evenly;
    }

    .breadcrumb-item-mobile {
      display: flex;
      justify-content: space-evenly;
      display: none;
    }

    @media (max-width: 500px) {
      width: 44%;
      .breadcrumb-item {
        display: none;
      }
      .breadcrumb-item-mobile {
        display: flex;
        align-items: center;
        width: 144px;

        span {
          font-size: 14px !important;
        }
      }
    }
    @media (max-width: 1024px) {
      .breadcrumb-item {
        display: none;
      }
      .breadcrumb-item-mobile {
        display: flex;
        align-items: center;
        width: 150px;

        span {
          font-size: 14px !important;
          font-weight: bold !important;
        }
      }
    }
  }
  span {
    margin: 10px;
    font-size: 14px;
  }

  span:hover {
    cursor: pointer;
    font-weight: bold !important;
  }

  span:last-child {
    font-weight: bold !important;
    margin-top: auto;
  }
`;

import produce from 'immer';
import {
  SHOW_DRAWER,
  HIDE_DRAWER,
  SHOW_MODAL_PL,
  HIDE_MODAL_PL,
  SHOW_MODAL_CREDIT,
  HIDE_MODAL_CREDIT,
} from './patterns';

const INITIAL_STATE = {
  drawer: false,
  modalPL: false,
  modalCredit: false,
  toggleContractRoutes: true,
};

export default function header(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SHOW_DRAWER: {
        draft.drawer = true;
        break;
      }
      case HIDE_DRAWER: {
        draft.drawer = false;
        break;
      }
      case SHOW_MODAL_PL: {
        draft.modalPL = true;
        break;
      }
      case HIDE_MODAL_PL: {
        draft.modalPL = false;
        break;
      }
      case SHOW_MODAL_CREDIT: {
        draft.modalCredit = true;
        break;
      }
      case HIDE_MODAL_CREDIT: {
        draft.modalCredit = false;
        break;
      }
      default:
    }
  });
}

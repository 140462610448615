/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import miIndicaBanner from '~/assets/img/Indications/miIndica.png';

import * as S from './styles';

const FirstBanner = ({ nome, onClick }) => {
  return (
    <S.FirstBanner className="container-fluid">
      <div className="container">
        <div className="side">
          <img alt="Logo Mi Indica" src={miIndicaBanner} id="app" />
          <p className="title">
            <b>
              {nome} acabou
              <br />
              de te indicar
              <br />
              para a conta digital Midway.
            </b>
          </p>
          <p className="subtitle">
            Ela é gratuita, digital e com vantagens que foram pensadas para
            melhorar a saúde do seu bolso e facilitar sua vida.
          </p>

          <a aria-label="Participar" href="javascript:;" onClick={onClick}>
            Participar
          </a>
        </div>
      </div>
    </S.FirstBanner>
  );
};

export default FirstBanner;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;
export const ContainerGlobal = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
`;
export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const BannerDesktop = styled.img`
  width: 100%;
  object-fit: cover;
  @media (max-width: 800px) {
    display: none;
  }
`;
export const CentralizeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
  }
`;
export const AdjustamentTitleCard = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  padding-bottom: 50px;
  margin-top: 50px;
  width: 90%;
  max-width: 1149px;

  @media (max-width: 1000px) {
    padding-bottom: 27px;
    margin-top: 37px;
  }
`;

export const TitleCards = styled.h1`
  font-family: Montserrat;
  font-size: 32px !important;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  padding-bottom: 24px;

  @media (max-width: 1000px) {
    font-size: 24px !important;
    font-weight: 700;
    line-height: 28px;
  }
`;
export const subTitleCards = styled.h6`
  font-family: Montserrat;
  font-size: 20px !important;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 19px;
  }
`;

export const AdditionalInfoList = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  margin-top: 50px;
  width: 90%;
  max-width: 1149px;
`;

export const AdditionalInfoTitle = styled.h6`
  font-family: Montserrat;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 19px;
  }
`;

export const OrderedList = styled.ul`
  margin-top: 32px;
  padding-bottom: 16px;
  list-style-type: disc;
  margin-left: 62px;
  @media (max-width: 1000px) {
    margin-left: 42px;
  }
`;
export const ListItem = styled.li`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding-bottom: 16px;
`;
export const TextGroup = styled.div`
  margin-top: 64px;
`;
export const RunningText = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding-bottom: 10px;
  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const ContainerCards = styled.div`
  width: 90%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
`;

export const ContainerCardsZurich = styled.div`
  width: 90%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ContentCardWrapper = styled.div`
  width: 100%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

export const ContainerCardWrapper = styled.div`
  max-width: 1176px;
  display: flex;

  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: cente;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 8px;
  min-width: ${props => props.minWidth || '629px'};
  min-height: 71px;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 6px -1px #1f2b2a1f;

  @media screen and (max-width: 1000px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const CardAnchor = styled.a`
  width: 100% !important;
  display: flex;
  padding: 12px 16px 12px 16px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
`;

export const CardWrapperText = styled.div`
  width: 100%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  margin-right: 50px;
  margin-top: 44px;
  margin-bottom: 54px;

  @media (max-width: 1000px) {
    gap: 1rem;
    margin-top: 33px;
  }
`;

export const WrapperText = styled.p`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

export const CardWrapperUnderline = styled.a`
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px;
  text-align: left;
  padding-bottom: 32px;
  color: #1f2b2a !important;
`;

export const Icon = styled.img`
  width: 48px;
  height: 47px;
`;

export const Title = styled.a`
  font-size: 18px;
  font-family: Montserrat;
  line-height: 32px;
  font-weight: bold;
  color: #1f2b2a !important;
  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const ContainerCardBlackTop = styled.div`
  margin-top: 80px;
  width: 100%;
  height: 470px;
  max-height: 470px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column;
    max-height: 530px;
  }
`;

export const CardLeft = styled.img`
  width: 40%;
  height: 470px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const CardRight = styled.div`
  width: 60%;
  height: 470px;
  padding: 132px;
  background-color: #221f20;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 32px 16px 32px 16px;
  }
`;

export const Groups = styled.div`
  max-width: 525px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CardTitle = styled.p`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  color: white;
  padding-bottom: 16px;

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

export const CardText = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: ${props => props.fontWeight || '500'};
  line-height: 20px;
  text-align: left;
  color: white;
  padding-bottom: ${props => props.paddingBottom || '32px'};
`;
export const ContentText = styled.div`
  width: 90%;
  max-width: 1180px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-top: 156px;

  @media (min-width: 526px) and (max-width: 1000px) {
    margin-top: 300px;
    width: 90%;
  }
  @media (min-width: 300px) and (max-width: 526px) {
    margin-top: 200px;
    width: 90%;
  }
`;

export const TextContent = styled.p`
  font-size: 20px;
  color: #1f2b2a;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-bottom: 16px;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const UnderlineText = styled.span`
  text-decoration: underline;
`;
export const ContainerCardBlackBottom = styled.div`
  margin-top: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: #1f2b2a;
  width: 90%;
  max-width: 1180px;
`;

export const CardBlackBottomTitle = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #ffff;
  padding-bottom: 16px;
`;

export const CardBlackBottomText = styled.p`
  font-family: Montserrat;
  color: #ffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding-bottom: ${props => props.paddingBottom || '16'};
`;

export const ContentZurich = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 90%;
  max-width: 1180px;

  @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 46px;
  }
`;

export const ZurichLeft = styled.img`
  max-width: 139px;
  max-height: 87px;
  margin-right: 40px;

  @media (max-width: 1000px) {
    margin-right: 0px;
  }
`;

export const ZurichRight = styled.div`
  max-width: 622px;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Line = styled.div`
  height: 140px;
  border: 1px solid #959d9d;
  margin-bottom: 10px;
  margin-right: 40px;

  @media (max-width: 1000px) {
    width: 100%;
    height: 1px;
    margin-bottom: 0px;
    margin: 24px 0px 24px 0px;
  }
`;

export const TextZurich = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const TextTop = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  padding-bottom: 32px;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 17px;
  }
`;
export const ContentTextFinalTop = styled.div`
  margin-bottom: 16px;
  margin-top: 45px;
  width: 90%;
  max-width: 1180px;
`;

export const ContentFinal = styled.div`
  margin-bottom: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 90%;
  max-width: 1180px;
  color: #242424;
`;

export const ContentTextFinal = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  @media (max-width: 1000px) {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
`;
export const StyleAnchor = styled.a`
  color: inherit;
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
  font-size: ${props => props.fontSize || '16px'};
`;

import React from 'react';
import AppleStore from '~/assets/img/apple-store.png';
import GooglePlay from '~/assets/img/google-play.png';
import DownloadModelImg from '~/assets/img/CardBenefits/download-model.png';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const DownloadTheApp = () => {
  return (
    <S.WhiteTopContainer id="download-surpreenda">
      <S.Container>
        <S.Image src={DownloadModelImg} />
        <S.Title>Baixe o aplicativo Surpreenda by Mastercard</S.Title>
        <S.Text white>
          Tenha fácil acesso a todas as ofertas e benefícios do seu Riachuelo
          Mastercard!
        </S.Text>
        <S.ButtonsContainer>
          <S.LinkButton
            href="https://play.google.com/store/apps/details?id=com.mastercard.hanzo.surpreenda"
            title="Baixe o app Surpreenda by Mastercard na Google Play Store"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Baixe o app Surpreenda by Mastercard na Google Play Store (abre uma nova janela)"
            onClick={() =>
              trackingSelectContent(
                'baixe-o-app-surpreenda:google-play',
                'beneficios'
              )
            }
          >
            <img src={GooglePlay} alt="" role="presentation" />
          </S.LinkButton>
          <S.LinkButton
            href="https://apps.apple.com/br/app/surpreenda-by-mastercard/id1326301655"
            title="Baixe o app Surpreenda by Mastercard na App Store"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Baixe o app Surpreenda by Mastercard na App Store (abre uma nova janela)"
            onClick={() =>
              trackingSelectContent(
                'baixe-o-app-surpreenda:app-store',
                'beneficios'
              )
            }
          >
            <img src={AppleStore} alt="" role="presentation" />
          </S.LinkButton>
        </S.ButtonsContainer>
      </S.Container>
    </S.WhiteTopContainer>
  );
};

export default DownloadTheApp;

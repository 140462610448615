import React from 'react';
import * as S from './styles';

const Cards = ({ icon, title, description }) => {
  return (
    <S.Container>
      <S.Card>
        <S.Centralize>
          <S.Icon src={icon} />
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.Centralize>
      </S.Card>
    </S.Container>
  );
};

export default Cards;

import styled from 'styled-components';
import HeroImage from '~/assets/img/CardBenefits/hero.png';
import Circle from '~/assets/img/CardBenefits/circulo.svg';

export const Section = styled.section`
  background-color: #f7f7f7;
`;

export const Container = styled.div`
  padding: 24px 16px;
  position: relative;

  @media screen and (min-width: 480px) {
    padding: 48px 32px;
  }
  @media screen and (min-width: 768px) {
    padding: 48px 32px;
  }
  @media screen and (min-width: 900px) {
    padding: 72px 132px;
  }
  @media screen and (min-width: 1200px) {
    padding: 72px 0;
    max-width: 1176px;
    margin: auto;
  }

  // trick to overflow the background
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    background-image: url(${HeroImage}), url(${Circle});
    background-position:
      bottom right -40px,
      bottom right -62px;
    background-size: 202px, 214px;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px) {
      background-size: 220px, 256px;
      background-position:
        bottom right 62px,
        bottom right 40px;
    }
    @media screen and (min-width: 900px) {
      background-size: 270px, 315px;
      background-position:
        bottom right -40px,
        bottom right -62px;
    }
    @media screen and (min-width: 1200px) {
      background-size: 366px, 427px;
      background-position:
        bottom right 0,
        bottom right 0;
    }
    @media screen and (min-width: 1200px) {
      background-size: 366px, 427px;
      background-position:
        bottom right 0,
        bottom right 22px;
      right: -22px;
      top: 0;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem !important;
  padding: 0.2rem 0 0.5rem;
  font-weight: 500;

  @media screen and (min-width: 900px) {
    font-size: 2rem !important;
    padding: 0 0 1rem;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  line-height: 1.25;

  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
  }
`;

export const Content = styled.div`
  min-width: 180px;
  margin-right: auto;
  width: 50%;

  @media screen and (min-width: 480px) {
    width: 70%;
  }
  @media screen and (min-width: 768px) {
    width: 460px;
  }
  @media screen and (min-width: 900px) {
    width: 560px;
  }
`;

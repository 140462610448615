import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;

  .benefits {
    @media (max-width: 767px) {
      h2 {
        font-size: 24px !important;
        font-weight: 700;
        line-height: 28px;
        margin: 34px 0 16px 8px;
      }
    }
  }
`;

export const BlackSession = styled.div`
  background-color: #221f20;
  max-height: 470px;
  overflow: hidden;
  padding: 0;

  div {
    margin: 0;
    padding: 0;
    color: #ffffff;

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 470px;
    }

    &.content {
      max-width: 580px;
      max-height: 325;
    }

    h4 {
      color: #ffffff;
      font-family: Montserrat;
      font-size: 32px !important;
      font-weight: 500;
      line-height: 39.01px;
      text-align: left;
      margin-bottom: 16px;
    }
    p {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      margin-bottom: 24px !important;
      strong {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 767px) {
    max-height: fit-content;
    overflow: auto;

    div .content {
      padding-top: 16px;
      margin: 32px 16px;
      h4 {
        font-size: 18px !important;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  padding: 0 !important;

  h2 {
    margin: 64px 8px 0;
    font-family: Montserrat;
    font-size: 32px !important;
    font-weight: 500;
    line-height: 39px;
    color: #ffffff;
    z-index: 1;
  }

  .color-white {
    color: #f1f1f1 !important;
  }

  p.uppercase {
    text-transform: uppercase;
  }

  p.bold {
    font-weight: bold;
  }

  p.--title-page {
    font-size: 36px;
    line-height: 43.2px;
    @media (max-width: 767px) {
      line-height: 28px;
      font-size: 24px !important;
    }
  }

  p.--subtitle-page {
    font-size: 22px !important;
    @media (max-width: 767px) {
      font-size: 18px !important;
    }
  }

  @media (max-width: 767px) {
    min-height: 500px;
    max-height: auto;
  }
  img {
    max-height: 600px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      min-height: 480px;
      max-height: 480px;
    }
  }
  div.banner-title {
    position: absolute;
    white-space: pre-wrap;
    left: 10%;
    top: 170px;
    max-width: 680px;

    @media (max-width: 767px) {
      top: 20px !important;
    }

    h2.bold {
      font-weight: bold;
      @media (max-width: 767px) {
        font-size: 18px !important;
      }
    }

    h3.is-primary-default {
      @media (max-width: 767px) {
        font-size: 18px !important;
        width: 65%;
      }
    }
    @media (max-width: 767px) {
      left: 4%;
      top: 50px;
    }
  }

  .background-benefits {
    background: #221f20;
    width: 100%;
    height: 205px;
    position: absolute;
    @media (max-width: 767px) {
      height: 360px;
    }
  }
`;

export const Benefits = styled.div`
  border: 2px solid var(--Colors-Menta, #b8c5bd);
  margin: 32px 8px;
  max-width: calc(25% - 16px);
  border-radius: 8px;
  background-color: #ffffff;
  @media (max-width: 767px) {
    max-width: calc(50% - 16px);
    margin: 8px;
  }

  img {
    width: 40px;
    height: 40px;
    margin: 24px 0 16px 4px !important;

    @media (max-width: 767px) {
      width: 32px;
      height: 32px;
      max-width: 32px;
      min-width: 32px;
      max-height: 32px;
      min-height: 32px;
    }
  }
  p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 0px 24px 4px;
  }
`;

export const AnchorText = styled.p`
  font-family: Montserrat;
  color: #4e4b59;
  font-size: 18px;
  padding-bottom: 30px;
`;

export const HandleShow = styled.div``;

export const AccordionTitle = styled.p`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  line-height: 39.01px;
  padding-top: 120px;
  padding-bottom: 41px;

  @media (max-width: 767px) {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    padding-top: 64px;
  }
`;

export const BarLink = styled.a`
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  margin-top: 48px;
  transition: all 0.2s ease-out;
  box-shadow: 0px 0px 0px rgba(31, 43, 42, 0.16);

  span {
    transition: all 0.2s ease-out;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
  }
  div {
    transition: all 0.2s ease-out;
    width: 48px;
    height: 48px;
  }

  &:hover {
    box-shadow: 0px 5px 10px rgba(31, 43, 42, 0.16);
    span {
      margin-left: 3px;
    }
    div {
      margin-right: 3px;
    }
  }

  @media (max-width: 767px) {
    span {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }

    &:hover {
      box-shadow: none;
      span {
        margin-left: 0;
      }
      div {
        margin-right: 0;
      }
    }
  }
`;

export const Session = styled.section`
  min-height: 400px;
  margin-top: 10px;
  padding-top: 50px;
  @media (max-width: 992px) {
    margin-top: 20px;
  }

  &.mock {
    position: relative;
    margin-top: 125px;
  }

  .rowPlan {
    display: flex;
    padding: 0 8px;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  p.title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    padding: 0 0px 24px 0;
    margin: 0;
    color: #1f2b2a;
  }

  .planTable {
    border: 2px solid var(--Colors-Menta, #b8c5bd);
    border-radius: 8px;
    padding: 48px;
    margin: 8px;
    width: calc(50% - 16px);
    @media (max-width: 767px) {
      width: calc(100% - 16px);
    }

    p.title {
      text-align: center;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0;
      color: #1f2b2a;

      div {
        width: 32px;
        margin-right: 16px;
      }
    }
  }
  .iphoneMock {
    div {
      width: 325px;
      margin: auto;
    }
    @media (max-width: 767px) {
      margin-bottom: 0px;
      div {
        width: 220px;
      }
    }
  }
  .BG {
    width: calc(100% - 32px);
    height: 600px;
    background-color: #f7f7f7;
    position: absolute;
    top: 60px;
    border-radius: 9px;

    @media (max-width: 767px) {
      top: 225px;
      height: 980px;
    }
  }

  .assistData {
    margin-top: 28px;
    padding-right: 80px;

    @media (max-width: 767px) {
      padding: 0 32px;
      p.title {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }

  p.subtitleStores {
    color: #1f2b2a !important;
    font-family: Montserrat;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 24px !important;
    margin-top: 65px;
    @media (max-width: 767px) {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .store-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 225px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      div {
        margin-bottom: 16px;
      }
    }
  }

  ol {
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      padding: 16px 0 4px;

      &::before {
        content: counter(my-awesome-counter);
        display: flex;
        width: 40px;
        min-width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: #a06d2c;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
        margin-right: 24px;
        @media (max-width: 767px) {
          margin-right: 16px;
        }
      }
    }
  }

  h4 {
    font-family: Montserrat;
    font-size: 32px !important;
    font-weight: 500 !important;
    line-height: 39.01px;
  }
  .subtitle {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    color: #3b4443;
    @media (max-width: 767px) {
      font-size: 16px !important;
      line-height: 20px;
    }

    .underline {
      text-decoration: underline;
    }
  }
  .link {
    text-decoration: underline;
  }

  .--plan-page {
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .--title-page {
    font-size: 25px;
    @media (max-width: 767px) {
      font-size: 24px !important;
      line-height: 28px;
    }
  }

  .--subtitle-page {
    font-size: 18px !important;
    @media (max-width: 767px) {
      font-size: 18px !important;
    }
  }

  .odontoprevImg {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 767px) {
      justify-content: center;
    }
    div {
      width: 239px;
    }
  }
  .odontoprevTxt {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      padding-left: 30px;
      border-left: 2px solid #959d9d;
      padding-right: 50px;
      @media (max-width: 767px) {
        color: #3b4443;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border-left: none;
        border-top: 2px solid #959d9d;
        padding: 24px 0 0;
        margin: 0px;
      }
    }
  }
  .faq {
    padding: 16px;
  }
`;

export const Content = styled.div``;

export const Col = styled.div`
  margin-bottom: 60px;
  & > h2 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  & > h3 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  & > p {
    color: #707070;
    margin-top: 20px;
    white-space: pre-wrap;
    &.bold {
      font-weight: bold !important;
    }
    b {
      font-weight: bold !important;
    }
  }
`;

export const StyleAnchor = styled.a`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 22px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  color: black;
  padding-bottom: 48px;

  &:hover {
    color: blue;
  }
`;

export const ColAnchor = styled.p`
  display: flex;
  align-items: flex-start;
  margin-top: 48px;
  justify-content: flex-start;
  flex-direction: column;
`;

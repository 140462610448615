import styled from 'styled-components';

import { colors } from '~/constants';

import digitalAccountImg from '~/assets/img/GuaranteedLimitLP/digital-account.svg';
import mobileDigitalAccountImg from '~/assets/img/GuaranteedLimitLP/digital-account.png';

const BACKGROUND_IMAGE_OFFSET = 224;

export const Container = styled.section`
  padding: 80px;
  background: ${colors.light_gray};

  @media (max-width: 720px) {
    background-image: url(${mobileDigitalAccountImg});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: ${BACKGROUND_IMAGE_OFFSET}px;
  }

  @media (min-width: 1160px) {
    background-image: url(${digitalAccountImg});
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
  }

  @media (max-width: 900px) {
    padding: 40px 16px;
  }
`;

export const Content = styled.div`
  max-width: 1176px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    gap: 16px;
    justify-content: flex-start;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 585px;
  width: 50%;

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 960px) {
    max-width: none;
    width: 100%;
  }
`;

export const AccountBenefits = styled.div`
  max-width: 585px;
  width: 100%;
  margin-bottom: 56px;

  @media (max-width: 1200px) {
    max-width: 550px;
  }

  @media (max-width: 1170px) {
    max-width: 500px;
  }

  @media (max-width: 720px) {
    max-width: none;
    margin-bottom: 0;
  }
`;

export const Info = styled.header`
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    margin-bottom: 48px;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 16px;

  @media (max-width: 720px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

export const Text = styled.p`
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 30px;

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const BenefitsAccountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 24px;

  @media (max-width: 720px) {
    margin-top: ${BACKGROUND_IMAGE_OFFSET + 120}px;
    gap: 16px;
  }
`;

export const BenefitAccountCardRow = styled.div`
  display: flex;
  gap: 16px;
`;

export const LastRow = styled(BenefitAccountCardRow)`
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const BenefitAccountCard = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  border: 1px solid ${colors.orangeRoof};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;

  @media (max-width: 720px) {
    width: 24px;
    height: 24px;
  }
`;

import { breakpoints } from '../../utils';
import { colors } from '~/constants';
import styled from 'styled-components';

export const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 3rem 0.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  gap: 1.5rem;
  background: ${colors.white};
  background: ${props => `url(${props.bannerUrl})`};
  background-position: center;
  background-color: gray;
  height: 12.5rem;

  background-size: 140%;
  background-position-x: -80px;
  background-position-y: 10px;

  @media (${breakpoints[480]}) {
    background-position-x: -160px;
    background-position-y: 0;
  }

  @media (${breakpoints[480]}) {
    background-size: cover;
    background-position-x: 0;
    justify-content: center;
    height: 14rem;
  }

  @media (${breakpoints[768]}) {
    padding-left: 8.25rem;
  }

  @media (${breakpoints[900]}) {
    padding-left: 8.25rem;
    background-position-y: -100px;
  }

  @media (${breakpoints[1176]}) {
    background-position-y: -200px;
  }

  @media (${breakpoints[1900]}) {
    background-position-y: -310px;
  }
`;

export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  z-index: 2;
`;

export const ScrollTo = styled.div`
  position: absolute;
  background: red;
  top: 12.5rem;

  @media (${breakpoints[480]}) {
    top: 14rem;
  }
`;

export const SearchTitle = styled.h1`
  font-weight: 800;
  font-size: 1.75rem !important;
  color: ${colors.white};
  line-height: 32px;
  z-index: 2;

  @media (${breakpoints[768]}) {
    font-size: 2.75rem !important;
  }
`;

export const SearchFieldsContainer = styled.div`
  width: 100%;
  max-width: 26.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SearchInput = styled.div`
  position: relative;
  border-radius: 2rem;
  width: 100%;

  input {
    width: inherit;
    background-color: ${colors.light_gray};
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border: none;
    border-radius: inherit;

    font-weight: 700;
    color: ${colors.dark_gray_3};
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.75rem;
    color: ${colors.dark_gray_3};
    width: 1.25rem;
  }
`;

export const SearchResults = styled.div`
  border-radius: 1.25rem;
  width: 100%;
  background-color: ${colors.light_gray};
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > strong {
    font-weight: 600;
    color: ${colors.chumbo};
  }
`;

export const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchResultItem = styled.div`
  padding: 1rem 0;
  cursor: pointer;
  &:hover {
    font-weight: 600;
    transition: ease-in-out 0.1s;
  }

  & + div {
    border-top: 1px solid #0000001a;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

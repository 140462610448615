import React, { memo, useEffect } from 'react';
import { Icon } from '@midway/web-ui-component';

import useIsMobile from '~/hooks/useIsMobile';

import {
  Header,
  HeaderTitle,
  HeaderBackButton,
  HeaderCloseButton,
} from './styles';
import './styles.css';

export const ModalHeader = memo(
  ({ onBack, title, showBackButton, showCloseButton = false }) => {
    const isMobile = useIsMobile();

    useEffect(() => {
      if (document.querySelector('.modal-dialog-centered')) {
        document
          .querySelector('.modal-dialog-centered')
          .classList.remove('modal-dialog-centered');
      }
    }, []);

    return (
      <Header
        data-testid="modal-header-container"
        hasBackButton={!showCloseButton && showBackButton}
        isMobile={isMobile}
      >
        {showBackButton && !showCloseButton && (
          <HeaderBackButton
            isMobile={isMobile}
            aria-label="Voltar"
            onClick={onBack}
          >
            <Icon color="#FFF" icon="angle-left" size="lg" />
            {!isMobile && 'Voltar'}
          </HeaderBackButton>
        )}
        <HeaderTitle>{title}</HeaderTitle>
        {showCloseButton && (
          <HeaderCloseButton
            isMobile={isMobile || showCloseButton}
            aria-label="Fechar"
            onClick={onBack}
          >
            x
          </HeaderCloseButton>
        )}
      </Header>
    );
  }
);

import styled from 'styled-components';

export const Container = styled.div`
  thead {
    background-color: #006464;
    th {
      color: #fff;
      font-size: 24px;
      @media (max-width: 992px) {
        font-size: 16px;
      }
      &:first-child {
        border-radius: 12px 0 0 0;
      }
      &:last-child {
        border-radius: 0 12px 0 0;
      }
    }
  }
  tbody {
    background-color: #efefef;
    tr {
      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 12px;
          }
          &:last-child {
            border-radius: 0 0 12px 0;
          }
        }
      }
    }
  }
`;

import { combineReducers } from 'redux';

import accessibility from './accessibility/reducer';
import tools from './tools/reducer';
import loan from './loan/reducer';
import calltoaction from './calltoaction/reducer';
import calltoactionriachuelo from './calltoactionriachuelo/reducer';
import featuretoggle from './featuretoggle/reducer';
import smartbanner from './smartbanner/reducer';

export default combineReducers({
  accessibility,
  tools,
  loan,
  calltoaction,
  calltoactionriachuelo,
  featuretoggle,
  smartbanner,
});

import React from 'react';
import { Breadcrumb } from '@midway/web-ui-component';
import history from '~/services/history';

import BaseFinancialProducts from '~/components/_layout/BaseFinancialProducts';
import * as S from './styles';

export default function EasyWithdrawal() {
  return (
    <S.Container
      id="ready-page-for-screen-reader"
      aria-label="Página de Saque Fácil carregada"
    >
      <Breadcrumb
        routeBack="/emprestimos"
        textRouteBack="Empréstimos"
        actualPageText="Saque fácil"
        history={history}
      />
      <BaseFinancialProducts
        pageName="Saque Fácil"
        session1={
          <>
            <h1>Saque Fácil</h1>
            <b>
              O Saque Fácil é um benefício exclusivo para quem tem o Cartão
              Riachuelo - Midway, e foi pensado pra quem precisa de dinheiro
              rápido, sem burocracia e com tranquilidade pra pagar. Saque até R$
              6.000,00* em qualquer loja Riachuelo, na hora, sem burocracia,
              direto no caixa e ainda parcele em até 21x fixas, com o pagamento
              da primeira parcela em até 60* dias.
            </b>
          </>
        }
        tabCircle={12}
        featured1={`Até R$ 6.000,00* na mão,\ndireto nas lojas RCLHO`}
        featured2={`Até 60* dias pra começar a\npagar e parcelamento em até\n21x** fixas.`}
        session21={
          <>
            <div className="divider">
              <p>Simplificamos até no pagamento.</p>
              <span>
                Você pode pagar o seu Empréstimo através do boleto bancário,
                pelo internet banking, correspondentes bancários ou em qualquer
                loja Riachuelo. É simples, rápido e muito fácil.
              </span>
            </div>
          </>
        }
        session22={
          <>
            <p>Vantagem exclusiva para clientes</p>
            <span>
              Benefício exclusivo para clientes cartão Midway e Riachuelo.
            </span>
          </>
        }
        tabCentral={18}
        session4={
          <>
            <p>
              *- Limite Pré-Aprovado refere-se ao valor máximo concedido,
              sujeito a alteração, no momento da contratação, sem aviso prévio.
              Crédito sujeito a análise e aprovação.
              <br />
              ** - Parcelamento e prazo do primeiro pagamento sujeito a
              critérios de elegibilidade. Consulte taxas, encargos, Custo
              Efetivo Total (CET) e condições gerais no momento da simulação e
              da contratação do produto. Informações de limite disponível nos
              seguintes canais: Atendimento ao Cliente em uma das Lojas
              Riachuelo, Central de Relacionamento pelos telefones 3003-4342
              (Cartão Riachuelo) ou 3004-5417 (Cartão Riachuelo Visa ou Master),
              ou através do aplicativo Cartão Riachuelo. Ouvidoria Midway: 0800
              7273255 (atendimento mediante protocolo anterior obtido na Central
              de Atendimento), de segunda à sexta das 10:00 as 16:00 (exceto
              feriados nacionais).
            </p>
          </>
        }
        colorCircle="#FFD25E"
        links={[
          {
            link: '/contrato/Proposta-de-contrato-emprestimo-pessoal-e-saque-facil',
            text: 'Acesso ao contrato de Saque Fácil',
          },
        ]}
      />
    </S.Container>
  );
}

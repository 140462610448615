import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import Container from '~/components/NewUi/Container';
import Button from '~/components/NewUi/Button';
import Typography from '~/components/NewUi/Typography';
import CardProduct from '~/components/NewUi/CardProduct';

import ImageEmprestimo from '~/assets/img/home/emprestimo.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { trackingSelectContent } from '~/analytics';
import {
  ActionButton,
  CardWrapper,
  Content,
  FeatureList,
  FeaturesContainer,
  Image,
  ImageContainer,
} from './style';

const Loans = () => {
  const sliderRef = useRef();

  const settings = {
    accessibility: true,
    className: 'SliderRoot',
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.6,
        },
      },
      {
        breakpoint: 492,
        settings: {
          slidesToShow: 1.4,
        },
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(
          sliderRef.current.innerSlider.state.currentSlide
        );
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <Content>
        <ImageContainer>
          <Image src={ImageEmprestimo} alt="Empréstimo pessoal no app" />
        </ImageContainer>
        <FeaturesContainer aria-label="Veja as modalidades de crédito">
          <Typography variant="h2" styleType="title">
            Empréstimos
          </Typography>
          <Typography variant="p" color="#616B6A" fontSize="14px">
            Escolha a modalidade de crédito que combina mais com a sua
            necessidade
          </Typography>
          <FeatureList>
            <Slider {...settings} ref={sliderRef}>
              <CardWrapper>
                <CardProduct
                  className="CardProductPadded"
                  title="Empréstimo pessoal"
                  description="<p>Empréstimo sem pedido de garantia.</p> <p>Precisa de dinheiro? A gente te ajuda.</p>"
                />
              </CardWrapper>
              <CardWrapper>
                <CardProduct
                  className="CardProductPadded"
                  title="Antecipação Saque-Aniversário FGTS"
                  description="Adiante até 10 parcelas do Saque-Aniversário FGTS de uma vez e tire os planos do papel."
                />
              </CardWrapper>
            </Slider>
          </FeatureList>
          <ActionButton
            onClick={() =>
              trackingSelectContent('emprestimos:conhecer-emprestimos', 'home')
            }
          >
            <Button
              role="button"
              aria-label="Clique para ver mais sobre empréstimos"
              tabIndex={0}
              to="/emprestimos"
            >
              Conhecer empréstimos
            </Button>
          </ActionButton>
        </FeaturesContainer>
      </Content>
    </Container>
  );
};

export default Loans;

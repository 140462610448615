import React from 'react';
import SobreAMidway from '~/assets/img/midway/sobre-a-midway.png';
import DadosQueNosFazem from '~/assets/img/midway/dados-que-nos-fazem.png';
import JuntosRumoAoFuturo from '~/assets/img/midway/juntos-rumo-ao-futuro.png';
import Header from './components/Header';
import Section from './components/Section';
import { Container, SectionContainer } from './styles';

const data = {
  sections: [
    {
      title: 'Sobre a Midway',
      description: `Acreditamos que o apoio e respeito a cada pessoa faz parte da nossa missão. Quando pensamos em nossos clientes, pensamos em seus estilos de vida próprios. E por isso, oferecemos serviços sob medida para você.`,
      image: SobreAMidway,
      key: 'sobre-a-midway',
    },
    {
      title: 'Dados que nos fazem',
      description: `Nossos serviços têm conquistado corações ao longo dos anos. Assim, mais de 31 milhões de clientes depositaram sua confiança em nós.
      Isso nos faz a maior emissora de cartões private label, o famoso cartão de loja, do Brasil. E nos coloca entre os 10 maiores emissores de cartões bandeirados do país.
      Enfim, escolher a Midway é escolher um serviço que pessoas do país todo escolheram`,
      image: DadosQueNosFazem,
      key: 'dados-que-nos-fazem',
      direction: 'left',
    },
    {
      title: 'Juntos rumo ao futuro',
      description: `A nossa dedicação incessante nos impulsiona a buscar sempre inovar e encontrar caminhos e soluções inteligentes.`,
      image: JuntosRumoAoFuturo,
      key: 'juntos-rumo-ao-futuro',
    },
  ],
};

const Sections = ({ sections }) => {
  return (
    <SectionContainer>
      {sections.map(section => {
        return <Section {...section} />;
      })}
    </SectionContainer>
  );
};

const Midway = () => {
  return (
    <Container>
      <Header />
      <Sections sections={data.sections} />
    </Container>
  );
};

export default Midway;

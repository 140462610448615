import styled from 'styled-components';
import Background from '~/assets/img/Financial/assistance/container.png';

export const Container = styled.div`
  background-color: #fff;
  padding: 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const BannerGeneric = styled.div`
  padding: 0 !important;
  position: relative;
  background-color: #c9c9c9;
  min-height: 400px;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BannerSpace = styled.div`
  width: 100%;
  max-width: 2000px;
  position: relative;
`;
export const BannerContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#E8E8E8')};
  position: relative;
  min-height: 400px;
  max-height: 400px;
  padding: 0 !important;
  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }
  img {
    min-height: 400px;
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      min-height: 300px;
      max-height: 300px;
    }
  }
  div.banner-title {
    position: absolute;
    white-space: pre-wrap;
    left: 10%;
    top: 170px;
    p {
      font-size: 32px;
    }
    p.bold {
      font-weight: bold;
    }
    max-width: 500px;
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export const Content = styled.div``;

export const Session1 = styled.section`
  h1 {
    font-family: Montserrat;
    line-height: 39px;
    color: #1f2b2a !important;
    font-size: 32px !important;
    font-weight: 500 !important;
    margin-top: 95px;
    margin-bottom: 16px;
    @media (max-width: 992px) {
      line-height: 25px;
      text-align: left;
      font-size: 20px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    color: #1f2b2a;
    font-size: 20px;
    line-height: 24.38px;
    font-weight: 500;
    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
`;

export const Session2 = styled.section`
  padding-top: 30px;

  @media screen and (max-width: 991px) {
    .card-assistance {
      margin-bottom: 24px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px; /* Espaçamento entre os cards */
  padding: 16px 0; /* Espaçamento interno do container */
`;

export const Col = styled.div`
  background-color: transparent;
`;

export const CardCircleContainer = styled.div`
  max-width: ${props => (props.width ? props.width : '310px')};
  margin-left: auto;
  margin-right: auto;
`;

export const Session3 = styled(Session1)`
  margin-top: 88px;
  padding-bottom: 88px;
  h2 {
    color: #1f2b2a !important;
    font-size: 32px !important;
    font-weight: 500 !important;
    margin-top: 95px;
    margin-bottom: 16px;
    text-align: left;
    @media (max-width: 992px) {
      font-size: 20px !important;
      margin-top: 25px;
      margin-bottom: 15px;
      font-family: Montserrat;
      line-height: 25px;
      text-align: center;
    }
  }
  p {
    color: #1f2b2a;
    font-size: 20px;
    line-height: 24.38px;
    font-weight: 500;
    @media (max-width: 992px) {
      font-size: 16px;
      font-family: Montserrat;
      line-height: 20px;
      text-align: left;
    }
  }
  h6 {
    color: #1f2b2a;
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 500 !important;
    text-align: left;

    @media (max-width: 992px) {
      font-size: 16px !important;
      font-family: Montserrat;
      line-height: 20px !important;
      text-align: center;
    }
  }
  @media (max-width: 992px) {
    margin-top: 48px;
    padding-bottom: 32px;
  }
`;

export const FooterCard = styled.div`
  background-color: #1b7071;
  padding: 35px 0;
  text-align: center;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  span {
    color: white !important;
    line-height: 35px !important;
    font-size: 22px !important;
  }
  u {
    color: white !important;
    font-size: 22px !important;
    cursor: pointer;
  }
`;

export const Banner = styled.div`
  background-image: url(${Background});
  min-height: 400px;
  margin-top: -200px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 712px) {
    max-height: unset;
    min-height: unset;
    background-attachment: unset;
    background-position: ${props => (props.isMobile ? 'initial' : 'center')};
  }
`;

export const ResumeComent = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 30px;
    font-weight: bold;
    margin-top: 120px;
    padding-left: 120px;
    white-space: pre-wrap;
    color: #fff;
    margin-bottom: 50px;
    @media (max-width: 992px) {
      margin-top: 160px;
      margin-bottom: 32px;
    }
    @media (max-width: 580px) {
      font-size: 22px;
      text-align: center;
      margin-top: 400px;
      margin-bottom: 0;
    }
  }
`;

export const Session4 = styled(Session1)`
  margin-top: 48px;
  h2 {
    color: #221f20 !important;
    font-weight: 700;
    font-size: 18px !important;
    line-height: 22px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
  }
  a {
    font-weight: 500;
    font-size: 16px;
    color: #221f20 !important;
    text-decoration: underline;
  }
`;

export const Spacer = styled.div`
  display: block;
  height: ${props => (props.size ? props.size : '100px')};
`;

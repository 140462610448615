import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 32px;
`;

export const LogoWrapper = styled.div`
  background-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;

  @media screen and (min-width: 768px) {
    width: 78px;
    height: 78px;
  }
  @media screen and (min-width: 1200px) {
    height: 88px;
    width: 88px;
  }
`;

export const LogoImage = styled.img`
  object-fit: contain;
`;

export const PartnerContainer = styled.div`
  flex: 1 1 100%;
  padding: 24px 16px;
  background-color: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  gap: 27px;
  cursor: pointer;

  @media screen and (min-width: 900px) {
    flex-basis: calc(50% - 16px);
    max-width: calc(50% - 16px);
    min-height: 220px;
  }
`;

export const PartnerContent = styled.div`
  flex: 1;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

export const NavigateButton = styled.button`
  background-color: transparent;
  border-radius: 50px;
  border: 0;

  svg {
    stroke: #221f20;
    margin-left: 8px;
    border: 2px #221f20 solid;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    stroke-width: 3px;
    padding: 1px 1px 1px 2px;
  }
`;

export const Title = styled.h3`
  font-size: 1.125rem !important;
  font-weight: 500;
  color: #1f2b2a;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #221f20;
  padding-bottom: 16px;
  white-space: break-spaces;

  b {
    font-weight: 500;
  }
`;

export const Rule = styled.p`
  font-size: 0.85rem;
  line-height: 1.25;
  color: #3b4443;
  padding-bottom: 8px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const NotFoundImg = styled.img`
  margin: 24px auto 32px;
  max-height: 228px;
  object-fit: contain;
`;

export const NotFoundText = styled(Text)`
  text-align: center;
  max-width: 328px;
  margin: auto;
`;

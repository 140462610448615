import 'swiper/swiper-bundle.min.css';
import 'swiper/components/pagination/pagination.min.css';
import styled from 'styled-components';

export const TableHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
  text-align: center;
`;

export const NoPageItems = styled.p`
  ${props => (props.isMobile ? 'padding-top: 20px;' : 'padding-top: 40px;')};
  text-align: center;
  font-size: 18px;
`;

export const HeaderFirstColumn = styled.div`
  float: left;
  width: 387px;
  margin-right: 50px;
  font-weight: bold;
  text-align: left;
`;

export const Description = styled.p`
  font-size: 14px;
`;

export const MobileDescription = styled.p`
  font-size: 14px;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PhoneDescription = styled.p`
  ${props =>
    props.phoneStore?.length !== 13 ? 'font-size: 12px;' : 'font-size: 14px;'};
`;

export const HeaderSecondColumn = styled.div`
  float: left;
  width: 400px;
  margin-right: 90px;
  font-weight: bold;
  text-align: left;
`;

export const HeaderThirdColumn = styled.div`
  float: left;
  width: 200px;
  margin-right: 50px;
  font-weight: bold;
  text-align: center;
`;

export const HeaderLastColumn = styled.div`
  width: 300px;
  font-weight: bold;
  text-align: center;
`;

export const BorderTable = styled.li`
  border-bottom: 1px solid #dcdcdc;
`;

export const ContentTable = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
`;

export const ContentFirstColumn = styled.div`
  float: left;
  width: 400px;
  margin-right: 50px;
  text-align: left;
`;

export const ContentSecondColumn = styled.div`
  float: left;
  width: 400px;
  margin-right: 100px;
  text-align: left;
`;

export const ContentThirdColumn = styled.div`
  float: left;
  width: 200px;
  margin-right: 50px;
  text-align: center;
`;

export const ContentLastColumn = styled.div`
  width: 300px;
  text-align: center;
`;

export const ContentOrangeButton = styled.div`
  width: 180px;
  text-align: right;
`;

export const OrangeButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #ff5224;
  font-size: 18px;
`;

export const css = `
  .swiper-container {
    padding-bottom: 50px;
  }
  .swiper-pagination-bullet {
    background-color: #D9D9D9;
    opacity: 1;
    width: 12px;
    height: 12px;
    position: relative;
  }
  .swiper-pagination-bullet-active {
    background-color: #FF5224;
  }
  .slide {
    height: 950px;
    overflow-y: scroll;
  }
  .slide::-webkit-scrollbar {
    background: #f1f1f1;
    width: 8px;
  }
  .slide::-webkit-scrollbar-thumb {
    background: #D9D9D9;
  }
  .slide::-webkit-scrollbar-thumb:hover {
    background: #A3A3A3;
  }
`;

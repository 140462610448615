import React from 'react';
import styled from 'styled-components';
import { scroll } from '~/services/helpers';
import scrollUp from '~/assets/img/Footer/scrollup.svg';
import useIsMobile from '../../../hooks/useIsMobile';

const StyledContainer = styled.div`
  height: 56px;
  width: 56px;
  position: ${props => (props.isMobile ? 'initial' : 'absolute')};
  bottom: 50px;
  right: 0;
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    bottom: 53px;
  }
`;

const BackToTop = () => {
  const isMobile = useIsMobile();

  const scrollToTop = () => {
    scroll(null);
  };
  return (
    <StyledContainer
      onClick={scrollToTop}
      isMobile={isMobile}
      role="button"
      aria-label="Voltar ao topo da pagina"
    >
      <img src={scrollUp} alt="Voltar ao topo da página" />
    </StyledContainer>
  );
};

export default BackToTop;

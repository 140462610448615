import React, { useEffect } from 'react';
import moment from 'moment';
import { kebabCase } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import { tagMiAjudaView } from '~/services/tagging';

import { Content, Description, DigitableLine, ContainerButton } from './styles';
import Important from '../Important';
import ButtonCopy from '../../../ButtonCopy';

function Billet() {
  const { negotiationData, typeNegotiationRegistered } = useNegotiation();
  const { registered } = negotiationData;

  const getDescription = () => {
    switch (typeNegotiationRegistered) {
      case 'debit':
      case 'acquittance': {
        return `Utilize o código abaixo para efetuar o pagamento <b>(vencimento em ${moment(
          registered.dueDate
        ).format('DD/MM')})</b>`;
      }
      case 'invoicement': {
        return 'Utilize o código abaixo para efetuar o pagamento da entrada';
      }
      case 'agreement': {
        return 'Utilize o código abaixo para efetuar o pagamento da entrada do acordo';
      }
      default:
        return 'Utilize o código abaixo para efetuar o pagamento';
    }
  };

  useEffect(() => {
    tagMiAjudaView(kebabCase(`${typeNegotiationRegistered} sucesso`));
  }, [typeNegotiationRegistered]);

  return (
    registered && (
      <>
        <Description dangerouslySetInnerHTML={{ __html: getDescription() }} />
        <Content>
          <DigitableLine>{registered?.formatTypeableLine}</DigitableLine>
          <ContainerButton>
            <ButtonCopy
              type="billet"
              title="Copiar código do boleto"
              toCopy={registered?.typeableLine}
            />
            <span>Também enviamos a linha digitável por SMS</span>
          </ContainerButton>

          <Important />
        </Content>
      </>
    )
  );
}

export default Billet;

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import { mockData } from './data';

const SubTitle = () => {
  const [showDocumentLink, setShowDocumentLink] = useState([]);

  const handleGetDocumentGeneralAccountConditions = useCallback(async () => {
    try {
      const response = await cmsService.getDocumentGeneralAccountConditions(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setShowDocumentLink(response);
        return;
      }
      setShowDocumentLink(mockData);
    } catch (error) {
      setShowDocumentLink([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetDocumentGeneralAccountConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetDocumentGeneralAccountConditions]);

  return (
    <S.Container>
      {showDocumentLink.map(item => (
        <div key={item.id}>
          {item.children.map((child, index) => (
            <S.Description key={index}>
              <S.DescriptionLink href={child.link} target="_blank">
                {child.linkTitle}
              </S.DescriptionLink>
            </S.Description>
          ))}
        </div>
      ))}
    </S.Container>
  );
};
export default React.memo(SubTitle);

import styled from 'styled-components';

export const Container = styled.div`
  white-space: nowrap;
  padding: 4px;
  border-radius: 4px;
  width: max-content;
  height: max-content;
  background-color: ${props =>
    props.type === 'inDay' ? '#b2ebf2' : '#ffcdd2'};
  color: #616161;
  font-size: 12px;
`;

export const banner = [
  [
    {
      name: 'BannerTarifasContaMidway',
      type: 'image',
      imgMobile:
        'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-tarifas-conta-midway-mobile.png',
      img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-tarifas-conta-midway.png',
      title:
        'Minhas tarifas e taxas Confira as taxas e tarifas que podem ter na sua conta',
      subtitle: '',
      icon: '',
      enabled: true,
    },
  ],
];

import styled, { css, keyframes } from 'styled-components';
import { Icon } from '@midway/web-ui-component';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 232px;

  @media (min-width: 1024px) {
    width: 422px;
  }
`;

export const Card = styled.div`
  background: #fff;
  padding: 32px;
  width: 100%;
  min-height: 100px;
  border-radius: 24px;
  box-shadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${opacity} 0.2s linear;
`;

export const Title = styled.p`
  color: #1f2b2a;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  padding-bottom: 18px;
`;

export const ImageContainer = styled.div`
  width: 96px;
  padding: 24px 0;
`;

export const SubTitle = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3b4443;
  text-align: center;
`;

export const Button = styled.button`
  background: #fff;
  padding: 11px 10px 12px;
  font-family: 'Montserrat';
  border: 1px solid #00726d;
  font-weight: bold;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
  color: #00726d;
  border-radius: 24px;
  width: 100%;
  transition:
    width 0.3s ease-in-out 0s,
    background 0.2s linear,
    color 0.2s linear;

  :hover {
    color: #00726d;
    background: #d6fffe;
  }

  @media (min-width: 540px) {
    width: 80%;
  }

  @media (min-width: 0px) {
    ${({ loading }) =>
      loading &&
      css`
        width: 40px;
        background: #00726d;
      `};
  }
`;

export const IconLoading = styled.div`
  position: absolute;
  bottom: 6px;
  animation: ${rotate} 2s linear infinite;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  width: 100%;
`;

export const ButtonIcon = styled(Icon)`
  margin-right: 6px;
`;

import styled from 'styled-components';
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HelperButton = styled.button`
  color: #00726d;
  font-weight: 400;
  margin: 120px auto 0 auto;
  width: 70%;
  padding: 10px;
  border: 1px solid #00726d;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
`;
export const WhatsAppIcon = styled(Whatsapp)`
  color: #00726d;
  width: 25px;
`;

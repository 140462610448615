import React from 'react';
import Desktop from './Desktop';
import Mobile from './Mobile';
import useIsMobile from '../../hooks/useIsMobile';

const Footer = props => {
  const isMobile = useIsMobile();
  return isMobile ? <Mobile {...props} /> : <Desktop {...props} />;
};

export default Footer;

import React, { useState } from 'react';
import { Icon } from '@midway/web-ui-component';
import Container from '~/components/NewUi/Container';
import Typography from '~/components/NewUi/Typography';
import Accordion from '~/components/Accordion';
import { trackingSelectContent } from '~/analytics';
import data from './data';
import { Content, PageWrapper, ShowMoreButton, ShowMoreWrapper } from './style';

const formatContent = arrayOfStrings => {
  return arrayOfStrings
    .map(string => {
      return `<p>${string}</p>`;
    })
    .join('');
};

const Faq = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const { title } = {
    title: showMore ? 'Mostrar menos' : 'Mostrar mais perguntas frequentes',
  };
  const displayedData = showMore ? data : data.slice(0, 5);

  return (
    <PageWrapper>
      <Container>
        <Content>
          <Typography className="TitleRoot" variant="h2" styleType="title">
            Perguntas frequentes
          </Typography>
          <div>
            <div>
              {displayedData.map(item => (
                <div key={item.title} onClick={item.onClickFn}>
                  <Accordion
                    title={item.title}
                    content={formatContent(item.content)}
                  />
                </div>
              ))}
            </div>
          </div>
          <ShowMoreWrapper
            onClick={() => trackingSelectContent('faq:ver-mais', 'home')}
          >
            <ShowMoreButton aria-label={title} onClick={toggleShowMore}>
              <span> {title} </span>
              <Icon
                icon={['fa', 'chevron-down']}
                size="2x"
                rotate180={showMore}
                color="#704810"
              />
            </ShowMoreButton>
          </ShowMoreWrapper>
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default Faq;

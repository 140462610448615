import styled from 'styled-components';
import { Star as StarRegular } from '@styled-icons/boxicons-regular/Star';
import { Star as StarSolid } from '@styled-icons/boxicons-solid/Star';
import { ArrowIosBackOutline } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import { ArrowIosForwardOutline } from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';

export const Container = styled.div`
  min-height: 100%;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  @media (max-width: 992px) {
    padding-top: 10px;
    padding-bottom: 30px;
  }
  li {
    padding-bottom: 5px;
  }
  .card {
    @media (max-width: 712px) {
      width: 300px !important;
      height: 300px !important;
    }
    @media (max-width: 414px) {
      min-width: 300px !important;
      max-width: 300px !important;
    }
    @media (max-width: 320px) {
      min-width: 300px !important;
      max-width: 300px !important;
    }
    border-radius: 50% !important;
  }
`;

export const StarEmpty = styled(StarRegular)`
  color: #ffd500;
  width: 24px;
  height: 23px;
`;

export const StarFull = styled(StarSolid)`
  color: #ffd500;
  width: 24px;
  height: 23px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 40px 42px 32px 42px;
  @media (max-width: 712px) {
    margin: 16px;
  }
  p {
    padding: 60px 20px 0 20px;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    @media (max-width: 712px) {
      font-size: 14px;
    }
  }
  div.card-text {
    padding: 0px 20px 0 20px;
    span {
      font-size: 16px;
      color: #707070;
      line-height: 35px;
      @media (max-width: 712px) {
        font-size: 12px;
        line-height: 19px;
      }
    }
  }
  b {
    padding-top: 30px;
    text-align: center;
    font-size: 14px;
    color: #707070;
    @media (max-width: 712px) {
      font-size: 12px;
    }
  }
`;

export const StarList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 20px;
  margin-top: 14px;
  margin-bottom: 36px;
  @media (max-width: 992px) {
    margin-top: 10px;
    margin-bottom: 12px;
  }
`;

export const Navigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    display: none;
  }
`;

export const NavigationContainer = styled.div`
  height: 60px;
  width: 60px;
  background-color: #a3a3a380;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
  &:hover {
    background-color: #006766;
    svg {
      color: #fff;
    }
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const PrevIcon = styled(ArrowIosBackOutline)`
  color: #7d7d7d;
  width: 30px;
  height: 30px;
`;

export const NextIcon = styled(ArrowIosForwardOutline)`
  color: #7d7d7d;
  width: 30px;
  height: 30px;
`;

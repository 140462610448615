import React from 'react';
import useIsMobile from '~/hooks/useIsMobile';
import { trackingSelectPromotion } from '~/analytics';
import { flow } from '../../../analytics/home';

const CarouselItem = ({ name, img, imgMobile, title, redirectUrl }) => {
  const isMobile = useIsMobile();
  const ImageComponent = (
    <img
      src={isMobile && imgMobile ? imgMobile : img}
      alt={title}
      height="484px"
      width="1440px"
      role={redirectUrl ? undefined : 'presentation'}
    />
  );

  const onClick = () => {
    trackingSelectPromotion(redirectUrl, flow.home, title, name);

    window.open(redirectUrl);
  };

  if (redirectUrl) {
    return (
      <a onClick={onClick} aria-label={title}>
        {ImageComponent}
      </a>
    );
  }
  return ImageComponent;
};

export default CarouselItem;

import { colors } from '~/constants';
import styled from 'styled-components';

export const ShortcutLinkContainer = styled.button`
  border-radius: 0.75rem;
  padding: 8px;
  background: ${colors.white};
  min-height: 5rem;
  height: 100%;
  border: 0;

  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 0.5rem;

  svg {
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
`;

export const Title = styled.strong`
  font-weight: 500;
`;

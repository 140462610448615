import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;

  @media screen and (min-width: 900px) {
    margin-bottom: 40px;
  }
`;

export const Title = styled.h4`
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  color: #e4602f;
`;

export const ModalContainer = styled.div`
  background: #fff;
  width: 100%;
  height: 60%;
  border-radius: 20px 20px 0 0;
  padding: 34px 24px;
  position: absolute;
  bottom: 0;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;

  @media (min-width: 900px) {
    width: 1080px;
    height: auto;
    max-width: 80%;
    border-radius: 8px;
    position: relative;
    bottom: auto;
    padding: 32px;
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    stroke: #000000;
  }
`;

export const BottomSheetRetangle = styled.div`
  width: 48px;
  height: 6px;
  background: #cacecd;
  border-radius: 100px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 12px;

  @media screen and (min-width: 900px) {
    display: none;
  }
`;

import styled from 'styled-components';

export const ContainerColor = styled.div`
  background: linear-gradient(247.03deg, #22c9c3 -38.12%, #a5df9f 100%);
`;

export const ContainerGray = styled.div`
  background: #f7f7f7;
`;

export const ContainerAdvertising = styled.div`
  font-size: calc(100vw / 50);
  display: flex;
  flex-direction: row;
  background: linear-gradient(200deg, #a5df9f, #22c9c3);
  min-width: 100vw;
  .firstBox {
    flex: 1;
  }
  ${props =>
    props.isMobile &&
    `
  .secondBox {
    .title {
      font-size: 3.7em !important;
      line-height: 1.2em;
    }
    .subtitle {
      font-size: 2.2em !important;
    }
    .badges img {
      width: 20em !important;
      height: auto !important;
    }
  }
  `}

  .secondBox {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0px 80px;
  }

  .title {
    font-size: 60px;
    font-weight: 800;
    color: #1f2b2a;
    margin-bottom: 30px;
  }
  .subtitle {
    font-weight: 700;
    font-size: 30px;
    line-height: 125%;
    color: #1f2b2a;
  }
  .badges {
    display: flex;
    flex-direction: row;

    img {
      width: 160px;
      height: 50px;
      margin-top: 30px;
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;

    .secondBox {
      padding: 80px 20px;
    }
    .title {
      font-size: 24px;
      line-height: 29px;
    }
    .subtitle {
      font-size: 18px;
    }

    #img-fluid {
      width: 360px;
      height: 282px;
    }

    .badges {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Container = styled.div`
  margin-bottom: 80px;
  p {
    font-size: 40px;
    font-weight: bold;
    padding-top: 78px;
    margin-bottom: ${props =>
      props.marginBottom ? props.marginBottom : '112px'};
  }

  .titleFaq {
    font-size: 36px;
    font-weight: bold;
    padding-top: 78px;
    margin-bottom: ${props =>
      props.marginBottom ? props.marginBottom : '112px'};
  }

  .bgMdRchlo {
    .mid-is-rchlo {
      margin-top: 30px;
      @media screen and (max-width: 320px) {
        margin-top: -30px;
      }
    }
  }

  span {
    font: normal normal normal 16px/22px Montserrat;
    color: #000000;
    @media (max-width: 800px) {
      font: normal normal normal 14.5px Montserrat;
      color: #000000;
      width: 30%;
      max-width: 10%;
    }
  }

  .align {
    display: flex;
    align-items: center;
  }

  .background-table-gray {
    background-color: rgba(0, 0, 0, 0.05) !important;
    padding: 15px 10px;
  }

  .background-table {
    padding: 15px 10px;
  }

  .content-buttons {
    margin-top: 10px;
  }

  .check {
    margin-inline: auto;
    img {
      display: block;
      width: 2rem;
      margin-inline: auto;
      @media (max-width: 400px) {
        width: 2rem;
        margin-top: 1.3rem !important;
        margin-inline: auto !important;
      }
    }
  }

  .retangulo {
    margin-inline: auto;
    width: 2.5rem;

    img {
      display: block;
      /* margin-top: 0.7rem !important; */
      @media (max-width: 400px) {
        margin-top: 1.9rem !important;
        margin-inline: auto !important;
      }
    }
  }

  a {
    flex-direction: column;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    background: #00726d;
    padding: 10px 0px;
    border-radius: 30px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin-top: 10px;
    transition: all 0.2s ease-out;
    &:hover {
      margin-top: 7px;
      margin-bottom: 2px;
      text-decoration: none;
      color: #fff;
    }
  }
  .botaoR {
    a {
      flex-direction: column;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      background: #000;
      padding: 10px 0px;
      border-radius: 30px;
      color: #fff;
      font-size: 15px;
      text-align: center;
      margin-top: 10px;
      transition: all 0.2s ease-out;
      &:hover {
        margin-top: 7px;
        margin-bottom: 2px;
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .title-top {
    display: flex;
    align-items: center;
    padding: 30px 0;
  }

  &.container-comparative-mobile {
    .container-icon-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon-mobile {
      width: 33px;
      height: 33px;
    }
  }
`;

export const Content = styled.div`
  padding-bottom: 64px;

  p.title {
    text-align: left;
    font: normal normal normal 24px/32px Montserrat;
    color: #606060;
  }
`;

export const ColTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.justifycontent ? props.justifycontent : 'space-between'};
  padding-bottom: 20px;

  b {
    font: normal normal bold 32px/39px Montserrat;
    color: #00726d;
  }

  span {
    text-align: left;
    font: normal normal normal 20px/24px Montserrat;
    color: #606060;
  }
`;

export const SectionLogos = styled.div`
  margin-top: -10px;
  margin-bottom: 150px;
  min-height: 200px;
  max-height: 200px;
  @media (max-width: 800px) {
    margin-top: -10px;
    margin-bottom: -80px;
    min-height: 100px;
    max-height: 100px;
  }
`;

export const CardAccess = styled.div`
  .headerCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #3b4443;
    min-height: 70px;
    max-height: 70px;
  }

  .mobileCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #3b4443;
    min-height: 70px;
    max-height: 70px;
    margin-top: 20px;
    padding: 5px 10px 15px;
    border-radius: 10px;
  }

  .botaoAR {
    a {
      flex-direction: column;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      background: #fff;
      padding: 8px 17px;
      border-radius: 30px;
      color: #00726d;
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
      transition: all 0.2s ease-out;
      &:hover {
        margin-top: 7px;
        margin-bottom: 2px;
        text-decoration: none;
        color: #000;
      }
    }
  }

  .displayWidth {
    margin: 0px 15px;
    padding-top: 12px;

    span {
      color: #fff;
      font-weight: bold;
      font-size: 11px;
    }

    p {
      color: #fff;
      font-weight: normal;
      font-size: 11px;
      padding-top: 0px;
    }
  }

  #icon {
    padding-top: 10px;
    width: 50px;
    height: 50px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  @media screen and (max-width: 425px) {
    .headerCard {
      padding-bottom: 10px;
    }
    .botaoAR {
      a {
        padding: 5px 0px;
        width: 100px;
      }
    }
    #icon {
      width: 50px;
      height: 50px;
    }
    .displayWidth {
      text-align: left;
      width: 180px;
      font-size: 12px;
    }
  }
`;

export const Banner = styled.div`
  min-height: 679px;
  padding: 63px 0;
  margin-bottom: 50px;

  h2 {
    white-space: pre-wrap;
    margin-top: 80px;
    margin-bottom: 25px;
    color: #252525;
  }

  p {
    font-size: 18px;
    text-align: left;
    margin-bottom: 120px;
    width: 100%;
    color: #000000;
  }

  .button-conhecer-mais {
    display: inline-block;
    background: #fff;
    padding: 12px 90px;
    border-radius: 50px;
    margin-top: 40px;
    cursor: pointer;

    p {
      color: #00726d;
      font-size: 14px;
    }
  }

  .badges {
    display: flex;
    flex-direction: row;

    img {
      width: 160px;
      height: 50px;
      margin-top: 30px;
      margin-right: 30px;
    }
  }

  .celulares {
    display: flex;
    justify-content: center;

    #mockupAppMidway {
      width: 700px;
    }
  }

  .badgesMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 160px;
      height: 50px;
      margin-top: 30px;
    }
  }
`;
export const AccessibleH1 = styled.h1`
  font-size: 40px !important;
  margin-top: 140px !important;
  margin-bottom: 20px !important;
  border-radius: 11px;
  font-weight: bold;
  color: #252525;

  @media (max-width: 768px) {
    margin-top: 0 !important;
    font-size: 24px !important;
  }
`;

export const AccessibleH2 = styled.h2`
  border-radius: 11px;
  font-weight: bold;
  color: #252525;
`;

export const AccessBanner = styled.div`
  display: flex;
  padding: 20px 50px;
  background: #1f2b2a;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  margin-top: 100px;

  p.title {
    margin-top: 0 !important;
    padding: 0 !important;
    font-size: 25pt;
    color: #fff;
    opacity: 1;
  }

  .subtitle {
    font-size: 20px;
    color: #fff;
    opacity: 1;
  }

  .botaoAR {
    a {
      flex-direction: column;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      background: #fff;
      padding: 10px 0px;
      border-radius: 30px;
      color: #00726d;
      font-size: 15px;
      text-align: center;
      margin-top: 10px;
      transition: all 0.2s ease-out;
      width: 300px;
      &:hover {
        margin-top: 7px;
        margin-bottom: 2px;
        text-decoration: none;
        color: #000;
      }
    }
  }

  .boxDisplay {
    display: flex;
    flex-direction: row;
  }

  .displayWidth {
    margin-left: 60px;
  }

  #icon {
    width: 80px;
    height: 80px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  #logo1 {
    width: 300px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
    /* transform: rotate(-8.27deg); */
  }
`;

export const Link = styled.strong`
  font-weight: bold;
  text-decoration: underline !important;
  color: #00726d;
  font-size: 16px;
  cursor: pointer;
`;

export const Bold = styled.strong`
  font-weight: bold !important;
`;

export const Comparative = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 46px;

  .choice {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
    }
  }

  .celulares {
    display: flex;
    column-gap: 20px;

    #logo1 {
      top: 894px;
      left: 960px;
      width: 70px;
      height: 70px;
      background: transparent 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    #logo2 {
      top: 894px;
      left: 1346px;
      width: 70px;
      height: 70px;
      background: transparent 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    h3 {
      text-align: left;
      font: normal normal bold 40px/60px Montserrat;
      color: #252525;
      opacity: 1;
    }

    p {
      text-align: center;
      font: normal normal normal 16px Montserrat;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }

    .title {
      text-align: left;
      font: bold 25px / 60px Montserrat;
      color: #000;
    }
  }

  .subtitle-logo {
    margin-top: 10px;
    text-align: center;
    font: normal normal normal 16px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .logo {
    display: flex;
    justify-content: flex-start;

    #logo-rchlo-branco {
      top: 398px;
      left: 200px;
      width: 76px;
      height: 76px;
      background: #fff 0% 0% no-repeat padding-box;
      border-radius: 16px;
    }
    p {
      left: 200px;
      text-align: left;
      color: #000000;
    }
  }
`;

export const ProductsAndServices = styled.div`
  padding: 115px 0;
  display: flex;
  gap: 120px 60px;
  justify-content: center;
  flex-wrap: wrap;

  .item {
    width: 328px;

    img {
      width: 56px;
      height: 56px;
    }
    .title {
      font-weight: 700;
      font-size: 18px !important;
      padding: 12px 0;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      padding-top: 0;
      max-width: 240px;
    }
  }
`;

export const Doubts = styled.div`
  padding: 100px 0;
  color: #606060;

  h1 {
    font-weight: 700;
    font-size: 40px;
  }

  > p {
    font-weight: 400;
    font-size: 18px;
    padding: 24px 0;
  }

  .contact {
    display: flex;
    column-gap: 80px;

    p {
      font-weight: 400;
      font-size: 16px;
      padding: 0 0 8px;
    }

    span {
      font-size: 24px;
      font-weight: 700;
      color: #00726d;
    }
  }
`;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useClipboard from 'react-hook-clipboard';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Button } from '@midway/web-ui-component';
import BackButton from '~/components/BackButton';
import { FLAGS } from '~/constants/cards';
import CreditCard from '~/assets/img/Financial/BilletInvoiceOrLendingV2/credit-card.svg';
import Calendar from '~/assets/img/Financial/BilletInvoiceOrLendingV2/calendar.svg';
import Cash from '~/assets/img/Financial/BilletInvoiceOrLendingV2/cash.svg';
import CircleInfo from '~/assets/img/Financial/BilletInvoiceOrLendingV2/info-circle.svg';
import { trackingSelectContent, trackingViewContent } from '~/analytics';
import { flow, contentPage, contentType } from '~/analytics/cards';
import format from '~/services/format';

import SelectCardRadioBox from './components/SelectCardRadioBox';
import InvoiceInstallmentDetails from '../InvoiceInstallmentDetails';
import * as Styles from './styles';

const InvoiceBankSlipDetails = ({
  details,
  onGoBackDetails,
  onConfirmSuggestedInstallment,
  onSimulateOtherOptions,
  onAccessActiveInstallment,
}) => {
  const { payline, dueDate, value, maskedPan, flag } = details;
  const [selected, setSelected] = useState(null);

  const {
    toogles: { isEnabledInvoiceInstallment },
  } = useSelector(state => state.featuretoggle);
  const [, copyToClipboard] = useClipboard();

  const fourDigits = maskedPan.substring(
    maskedPan.length - 4,
    maskedPan.length
  );

  const fireSelectContentEvent = (analyticsContentType, otherParams = null) => {
    trackingSelectContent(
      analyticsContentType,
      flow.payments,
      contentPage.invoiceDetails,
      otherParams
    );
  };

  const handlePressCopyPayLine = () => {
    toast('Código do boleto copiado com sucesso!');
    fireSelectContentEvent(contentType.copyPayline, {
      valor_do_pagamento: value,
    });
    copyToClipboard(payline);
  };

  const cardText = useMemo(
    () => `Cartão ${FLAGS[flag]} - Final ${fourDigits}`,
    [fourDigits, flag]
  );

  const handleEventInfo = event => {
    event.stopPropagation();
    fireSelectContentEvent(contentType.info);
    toast(
      'O valor total a pagar no boleto poderá vir com acréscimo de juros e multa em caso de atraso.'
    );
  };

  const handleChangeSelect = id => setSelected(id);

  const handleGoBackDetails = () => {
    fireSelectContentEvent(contentType.goBack);
    onGoBackDetails();
  };

  const isInvoiceInstallmentSelected = selected === 1;
  const isBankSlipSelected = selected === 2;
  const isPixSelected = selected === 3;

  const ButtonInfo = () => {
    return (
      <Styles.InfoButton onClick={handleEventInfo} data-testid="info-icon">
        <Styles.Image src={CircleInfo} />
      </Styles.InfoButton>
    );
  };

  useEffect(() => {
    if (value) {
      trackingViewContent(contentPage.invoiceDetails, flow.payments, {
        valor_do_pagamento: value,
      });
    }
  }, [value]);

  useEffect(() => {
    if (isInvoiceInstallmentSelected) {
      fireSelectContentEvent(contentType.invoiceInstallmentChecked);
    }
  }, [isInvoiceInstallmentSelected]);

  useEffect(() => {
    if (isBankSlipSelected) {
      fireSelectContentEvent(contentType.invoiceBilletChecked);
    }
  }, [isBankSlipSelected]);

  return (
    <Styles.Container>
      <BackButton onGoBack={handleGoBackDetails} />
      <Styles.HeaderContainer>
        <Styles.HeaderText>Detalhes da fatura</Styles.HeaderText>
        <Styles.Text>
          <Styles.Image src={CreditCard} />
          <Styles.Bold>{cardText}</Styles.Bold>
        </Styles.Text>
        <Styles.Text>
          <Styles.Image src={Calendar} />
          <span>
            Data de vencimento: <Styles.Bold>{dueDate}</Styles.Bold>
          </span>
        </Styles.Text>
        <Styles.Text>
          <Styles.Image src={Cash} />
          <span>
            Valor: <Styles.Bold>{format.currency(value)}</Styles.Bold>
          </span>
          <ButtonInfo />
        </Styles.Text>
      </Styles.HeaderContainer>
      <Styles.HeaderText>Escolha uma forma de pagamento:</Styles.HeaderText>

      {isEnabledInvoiceInstallment && (
        <SelectCardRadioBox
          title="Parcelar fatura"
          onSelect={handleChangeSelect}
          id={1}
          selected={isInvoiceInstallmentSelected}
        >
          <InvoiceInstallmentDetails
            selected={isInvoiceInstallmentSelected}
            onConfirmSuggestedInstallment={onConfirmSuggestedInstallment}
            onSimulateOtherOptions={onSimulateOtherOptions}
            onAccessActiveInstallment={onAccessActiveInstallment}
          />
        </SelectCardRadioBox>
      )}

      <SelectCardRadioBox
        title="Pagar total com boleto"
        onSelect={handleChangeSelect}
        id={2}
        selected={isBankSlipSelected}
      >
        <Styles.BankSlipItemContainer>
          <Styles.PaylineBox>
            <Styles.Payline>{payline}</Styles.Payline>
          </Styles.PaylineBox>
          <Button
            title="Copiar código do boleto"
            block
            onClick={handlePressCopyPayLine}
            leftIcon={['far', 'clone']}
            rotate="90"
            stateIcon="secundary"
          />
        </Styles.BankSlipItemContainer>
      </SelectCardRadioBox>

      <SelectCardRadioBox
        title="Pagar total com PIX"
        onSelect={handleChangeSelect}
        id={3}
        selected={isPixSelected}
        disabled
        labelText="Em breve"
      />

      <Button
        title="Voltar para faturas"
        outline
        block
        onClick={handleGoBackDetails}
      />
    </Styles.Container>
  );
};

InvoiceBankSlipDetails.propTypes = {
  details: PropTypes.shape({
    payline: PropTypes.string,
    dueDate: PropTypes.string,
    value: PropTypes.string,
    fourDigits: PropTypes.string,
    flag: PropTypes.oneOf(Object.keys(FLAGS)),
  }),
  onGoBackDetails: PropTypes.func,
};

export default React.memo(InvoiceBankSlipDetails);

import React from 'react';
import { Button } from '~/components/NewUi';
import {
  ActionsWrapper,
  Container,
  SectionContentWrapper,
  SectionImageWrapper,
  SectionPhrase,
  SectionTitle,
  SectionWrapper,
} from './styles';

const Section = ({ title, phrase, actions = [], index, image }) => {
  const text = phrase.map(item => {
    return <p>{item}</p>;
  });
  const isReverse = index % 2 === 0;
  return (
    <Container>
      <SectionWrapper id={`section-${title}`} isReverse={isReverse}>
        <SectionImageWrapper>
          <img src={image} alt={title} title={title} />
        </SectionImageWrapper>
        <SectionContentWrapper>
          <SectionTitle>{title}</SectionTitle>
          <SectionPhrase>{text}</SectionPhrase>
          <ActionsWrapper>
            {actions.map(({ label, url, key, outlined, external }) => (
              <Button
                outlined={outlined}
                key={key}
                to={url}
                external={external}
              >
                {label}
              </Button>
            ))}
          </ActionsWrapper>
        </SectionContentWrapper>
      </SectionWrapper>
    </Container>
  );
};

export default Section;

import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${({ left }) => (left ? 'row' : 'row-reverse')};
  align-items: center;
  gap: 66px;
  margin-bottom: 100px;
  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;
export const ImageWrapper = styled.div``;
export const ContentWrapper = styled.div`
  padding: 0 33px;
  flex: 1;
  h2 {
    color: #252525;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
  }
  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
  }

  @media screen and (max-width: 900px) {
    padding: 0 15px !important;
    padding-top: 40px;
  }
`;

import styled from 'styled-components';
import { Link as LinkDOM } from 'react-router-dom';
import IconSearch from '~/assets/img/icons/IconSearch.png';

export const Page = styled.div`
  background-color: #fff;
`;

export const Header = styled.div`
  ${props => (props.isMobile ? 'padding-top: 2px;' : 'padding-top: 20px;')};
  ${props =>
    props.isMobile ? 'padding-bottom: 2px;' : 'padding-bottom: 50px;'};
  background-color: #f7f7f7;
  width: 100%;
`;

export const InputContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const ContainerHeader = styled.div`
  padding-top: 40px;
`;

export const ContainerImageHeader = styled.div`
  display: flex;
`;

export const TitleHeaderMobile = styled.h1`
  font-weight: bold;
`;

export const TitleHeader = styled.h1`
  font-size: 40px !important;
  font-weight: bold;
`;

export const ImageHeaderMobile = styled.div`
  justify-content: 'center';
  margin-top: 40px;
`;

export const ImageHeader = styled.div`
  justify-content: center;
  max-width: 429px;
`;

export const ContentHeader = styled.div`
  margin-left: 35px;
  margin-top: 30px;
`;

export const StoreListTitle = styled.h2`
  padding-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
`;

export const TextHelpSection = styled.h3`
  margin-bottom: 30px;
  font-weight: bold;
`;

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 60px;
`;

export const Section = styled.section`
  padding-top: 30px;
  padding-bottom: 10px;
`;

export const Input = styled.input`
  ${props => (props.isMobile ? 'width: 100%;' : 'width: 412px;')};
  ${props => (props.isMobile ? 'margin-bottom: 10px;' : 'margin-bottom: 0px;')};
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #c8c8c8;
  border-radius: 30px;
  background-image: url(${IconSearch});
  background-position: 15px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
`;

export const h2 = styled.h2`
  font-size: 16px !important;
  margin-top: 40px;
  padding-bottom: 35px;
`;

export const ButtonsPaginationContainer = styled.div`
  position: relative;
`;

export const ButtonsPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -15px;
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ButtonLeftPagination = styled.button`
  margin-right: 70px;
  width: 30px;
  border: none;
  border-radius: 5px;
  height: 24px;
  font-size: 17px;
  background-color: #d9d9d9;

  :disabled {
    background-color: #f7f7f7;
  }
`;

export const ButtonRightPagination = styled.button`
  margin-left: 70px;
  width: 30px;
  border: none;
  border-radius: 5px;
  height: 24px;
  font-size: 17px;
  background-color: #d9d9d9;

  :disabled {
    background-color: #f7f7f7;
  }
`;

export const ContainerHelpSection = styled.div`
  padding-top: 60px;
`;

export const HelpSection = styled.div`
  ${props => (props.isMobile ? 'display: inline;' : 'display: flex;')};
  justify-content: space-between;
  margin-top: 30px;
`;

export const CardHelpSection = styled.div`
  ${props => (props.isMobile ? 'width: 100%;' : 'width: 261px;')};
  ${props => (props.isMobile ? 'margin-bottom: 20px;' : 'margin-bottom: 0px;')};
  min-height: 220px;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 15px;
  border: 2px solid #dae5e3;
`;

export const HeaderCardHelpSection = styled.div`
  border-bottom: 1px solid #cacecd;
  padding-bottom: 20px;
`;

export const TitleCardHelpSection = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export const LinkCardHelpSection = styled(LinkDOM)`
  display: flex;
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const ExternalLinkCardHelpSection = styled.a`
  display: flex;
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;
`;

export const ImageCardHelpSection = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const LastCardHelpSection = styled.div`
  ${props => (props.isMobile ? 'width: 100%;' : 'width: 534px;')};
  min-height: 220px;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid #dae5e3;
`;

export const TextLinkCardHelpSection = styled.p`
  font-size: 16px;
  color: black;

  :hover {
    color: #ff5224;
  }
`;

export const TitleLastCardHelpSection = styled.p`
  font-weight: bold;
  ${props => (props.isMobile ? 'font-size: 18px;' : 'font-size: 20px;')};
`;

export const SubTitleLastCardHelpSection = styled.p`
  ${props => (props.isMobile ? 'font-size: 18px;' : 'font-size: 20px;')};
  ${props => (props.isMobile ? 'margin-top: 18px;' : 'margin-top: 20px;')};
`;

export const LinkLastCardHelpSection = styled(LinkDOM)`
  ${props => (props.isMobile ? 'margin-top: 18px;' : 'margin-top: 20px;')};
  display: flex;
  cursor: pointer;
  color: black;
`;

export const TextLinkLastCardHelpSection = styled.p`
  ${props => (props.isMobile ? 'font-size: 20px;' : 'font-size: 22px;')};
  ${props => (props.isMobile ? 'padding-top: 3px;' : 'padding-top: 0px;')};
  color: black;

  :hover {
    color: #ff5224;
  }
`;

export const ImageLastCardHelpSection = styled.div`
  ${props => (props.isMobile ? 'margin-top: 0px;' : 'margin-top: 1px;')};
  width: 35px;
  height: 35px;
  margin-right: 10px;
`;

export const OrangeButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #ff5224;
  font-size: 18px;
`;

export const Modal = styled.div``;

export const ContentModal = styled.div`
  padding: 20px;
`;

export const TextContentModal = styled.div`
  margin-bottom: 30px;
`;

export const TextBold = styled.p`
  font-weight: bold;
  font-size: 18px;
`;

export const Description = styled.p`
  font-size: 18px;
`;

export const HeaderModal = styled.div`
  padding-top: 50px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 30px;
`;

export const FooterModal = styled.div`
  width: 100%;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 100px;
`;

export const BackButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 30px;
  background-color: #242424;
  color: white;
  border: none;
`;

import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const CardAssistance = ({
  title,
  subtitle,
  btnAriaLabel,
  description,
  btnTitle,
  action,
  col,
  gtmCategory,
  gtmAction,
  gtmLabel,
  children,
  contentHeight,
}) => {
  return (
    <div>
      <S.BodyCard id="card" className="body-card" col={col}>
        {children}
        <S.CardContent>
          <S.TitleCard>{title}</S.TitleCard>
          <S.SubtitleCard>{subtitle}</S.SubtitleCard>
          <S.DescriptionCard contentHeight={contentHeight}>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </S.DescriptionCard>
        </S.CardContent>
        <S.CardAction className="action">
          {btnTitle !== '' ? (
            <S.ActionButton
              aria-label={btnAriaLabel}
              state="primary"
              title={btnTitle}
              block
              onClick={() => {
                action();
              }}
              data-gtm-event-category={gtmCategory}
              data-gtm-event-action={gtmAction}
              data-gtm-event-label={gtmLabel}
            />
          ) : null}
        </S.CardAction>
      </S.BodyCard>
    </div>
  );
};

CardAssistance.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  btnTitle: PropTypes.string,
  col: PropTypes.bool,
  action: PropTypes.func,
  btnAriaLabel: PropTypes.any,
  gtmCategory: PropTypes.string,
  gtmAction: PropTypes.string,
  gtmLabel: PropTypes.string,
  children: PropTypes.any,
};

CardAssistance.defaultProps = {
  subtitle: '',
  title: '',
  btnAriaLabel: '',
  description: '',
  btnTitle: '',
  col: false,
  action: () => {},
  gtmCategory: '',
  gtmAction: '',
  gtmLabel: '',
  children: null,
};

export default CardAssistance;

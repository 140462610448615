import React from 'react';
import * as S from './styles';

const ListStep = ({ data }) => {
  return (
    <>
      {data.map(value => (
        <S.Container>
          <S.StepContainer>
            <S.Icon src={value.icon}></S.Icon>
          </S.StepContainer>
          <S.TextContainer>
            <S.ParagraphSection>{value.text}</S.ParagraphSection>
          </S.TextContainer>
        </S.Container>
      ))}
    </>
  );
};

export default React.memo(ListStep);

import styled from 'styled-components';

export const ContainerPage = styled.div``;

export const Section = styled.section`
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 40px;
  @media (max-width: 992px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const Title = styled.div`
  h1 {
    font-size: 28px !important;
    font-weight: bold;
    color: #252525;
    padding-bottom: 22px;
    @media (max-width: 992px) {
      font-size: 22px;
      padding-top: 30px;
      padding-bottom: 15px;
    }
  }
`;

export const Text = styled.div`
  padding-bottom: 20px;
  span {
    font-size: 14px;
    color: #707070;
    line-height: 24px;
    white-space: pre-wrap;
    b {
      font-weight: bold;
    }
    @media (max-width: 992px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

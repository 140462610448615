import React, { useEffect, useState, useCallback } from 'react';
import {
  validateEmailFormat,
  validarDataNascimento,
  validateDueDate,
  validarCPF,
} from '~/utils/utils';
import * as S from './styles';
import Steppers from '../components/Steppers';
import StageStep from './StageStep';
import StageButtons from './StageButtons';

const Stage = () => {
  const [step, setStep] = useState(1);
  const [nextStepEnabled, setNextStepEnabled] = useState(false);
  const [showNavigation, setShowNavigation] = useState(true);
  const [tokenCaptcha, setTokenCaptcha] = useState('');

  localStorage.removeItem('keyDispLimite');
  localStorage.removeItem('keyCpf');
  localStorage.removeItem('keyValidatedCpf');

  const [customer, setCustomer] = useState({
    limit: '',
    available: 0,
    profile: 0,
    gracePeriodMax: 0,
    gracePeriodMin: 0,
    installmentMin: 0,
    installmentMax: 0,
  });

  const [form, setForm] = useState({
    fullname: '',
    cpf: '',
    birthdate: '',
    gender: '',
    nationatily: '',
    contactNumber: '',
    email: '',
    loanValue: 0,
    firstPaymentDate: '',
    installments: 0,
    acceptReceiveNews: 'N',
  });

  const onSetCustomer = data => {
    setCustomer(data);
  };

  const checkStepData = useCallback(() => {
    if (step === 1 || step === 7) {
      return true;
    }
    if (step === 2) {
      const isValid = form.fullname.length > 1 && validarCPF(form.cpf);
      if (isValid) {
        refreshCaptcha();
      }
      return isValid;
    }
    if (step === 3) {
      const isValid =
        validarDataNascimento(form.birthdate) &&
        form.gender.length > 0 &&
        form.contactNumber.length > 14 &&
        validateEmailFormat(form.email);
      return isValid;
    }
    if (step === 4) {
      const isValid =
        form.loanValue >= 100 && form.loanValue <= customer.available;
      return isValid;
    }
    if (step === 5) {
      const isValid = validateDueDate(
        form.firstPaymentDate,
        customer.gracePeriodMin,
        customer.gracePeriodMax
      );
      return isValid;
    }
    if (step === 6) {
      const isValid = form.installments >= 2;
      return isValid;
    }

    return false;
  }, [
    customer.available,
    customer.gracePeriodMax,
    customer.gracePeriodMin,
    form.birthdate,
    form.contactNumber.length,
    form.cpf,
    form.email,
    form.firstPaymentDate,
    form.fullname.length,
    form.gender.length,
    form.installments,
    form.loanValue,
    step,
  ]);

  const onPrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onNext = () => {
    const isOkay = checkStepData();
    if (!isOkay) return false;
    if (step < 7) {
      setStep(step + 1);
    }
  };

  const refreshCaptcha = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
        .then(token => {
          setTokenCaptcha(token);
        });
    });
  };

  const onNewSimulation = () => {
    refreshCaptcha();
    setStep(4);
  };

  const onSubmit = e => {
    e.preventDefault();
  };
  const onChange = e => {
    const { target } = e;
    setForm(prev => {
      return {
        ...prev,
        [target.name]: target.value,
      };
    });
    setNextStepEnabled(checkStepData());
  };

  const onChangeNavigationVisibility = visible => {
    setShowNavigation(visible);
  };

  useEffect(() => {
    setNextStepEnabled(checkStepData());
  }, [checkStepData, step]);

  return (
    <S.Content>
      <S.Form onSubmit={onSubmit}>
        {step !== 1 && showNavigation && <Steppers select={step} />}
        <StageStep
          showNavigation={onChangeNavigationVisibility}
          step={step}
          form={form}
          onChange={onChange}
          onSetCustomer={onSetCustomer}
          customer={customer}
          token={tokenCaptcha}
          onSetToken={setTokenCaptcha}
        />
        {showNavigation && (
          <StageButtons
            step={step}
            nextStepEnabled={nextStepEnabled}
            onPrev={onPrev}
            onNext={onNext}
            onNew={onNewSimulation}
          />
        )}
      </S.Form>
    </S.Content>
  );
};

export default Stage;

import React from 'react';
import {
  IconZoomMoney,
  IconSettings,
  IconTrees,
  IconBuildingStore,
  IconCreditCard,
  IconPresentation,
} from '@tabler/icons-react';
import AccessibleLink from '~/components/AccessibleLink';
import Desktop from '~/components/Responsive/Desktop';
import Mobile from '~/components/Responsive/Mobile';
import * as S from './styles';
import BannerImage from '../../assets/img/Risk/gerenciamento-riscos-banner.svg';
import Accordion from '../../components/Accordion';

const Risk = () => {
  return (
    <S.Page
      id="ready-page-for-screen-reader"
      aria-label="Página de Gerenciamento de riscos carregada"
    >
      <Desktop>
        <S.HeroSection>
          <div className="container">
            <S.HeroContent>
              <div>
                <img
                  alt="imagem banner"
                  src={BannerImage}
                  width={509}
                  height={444}
                />
              </div>
              <div>
                <h1>Conheça o nosso modelo de governança e gestão de risco</h1>
                <div>
                  <p>
                    O modelo de gestão está orientado pelas boas práticas de
                    Governança Corporativa e de Gestão de Riscos, com foco na
                    transparência, responsabilidade pelos resultados e
                    disponibilização de informações adequadas para a tomada de
                    decisão.
                  </p>
                  <p>
                    A Midway gerencia o risco por meio de uma abordagem
                    abrangente em suas decisões, sempre aprimorando seus
                    processos e controles internos para alcançar os padrões e
                    exigências estabelecidos pelo Banco Central do Brasil
                    (BACEN). A seguir um breve descritivo desses tipos de
                    riscos:
                  </p>
                  <AccessibleLink
                    styleDefault="orangeLink"
                    route="/contrato/Relatorio-de-Gestao-de-Risco"
                    text="Relatório de gestão de Risco 1T20"
                    aria-label="Relatório de gestão de Risco do primeiro trimestre de 2020"
                    hasUnderline
                  />
                </div>
              </div>
            </S.HeroContent>
          </div>
        </S.HeroSection>
      </Desktop>
      <Mobile>
        <S.HeroSectionMobile>
          <div className="container">
            <S.HeroContentMobile>
              <div>
                <h1>Conheça o nosso modelo de governança e gestão de risco</h1>
                <h3>GERENCIAMENTO DE RISCOS</h3>
              </div>
              <div>
                <img
                  alt="imagem banner"
                  src={BannerImage}
                  width={509}
                  height={444}
                />
              </div>
              <div>
                <div>
                  <p>
                    O modelo de gestão está orientado pelas boas práticas de
                    Governança Corporativa e de Gestão de Riscos, com foco na
                    transparência, responsabilidade pelos resultados e
                    disponibilização de informações adequadas para a tomada de
                    decisão.
                  </p>
                  <p>
                    A Midway gerencia o risco por meio de uma abordagem
                    abrangente em suas decisões, sempre aprimorando seus
                    processos e controles internos para alcançar os padrões e
                    exigências estabelecidos pelo Banco Central do Brasil
                    (BACEN). A seguir um breve descritivo desses tipos de
                    riscos:
                  </p>
                  <AccessibleLink
                    styleDefault="orangeLink"
                    route="/contrato/Relatorio-de-Gestao-de-Risco"
                    text="Relatório de gestão de Risco 1T20"
                    aria-label="Relatório de gestão de Risco do primeiro trimestre de 2020"
                    hasUnderline
                  />
                </div>
              </div>
            </S.HeroContentMobile>
          </div>
        </S.HeroSectionMobile>
      </Mobile>
      <S.Container className="container">
        <Accordion
          title="Risco operacional"
          rightIcon={<IconSettings />}
          content="Conforme determinações do Conselho Monetário Nacional, por meio da
              vigente, a Midway Financeira implementou sua estrutura de
              Gerenciamento de Risco Operacional de forma compatível com a
              natureza de suas operações e com o seu porte. Segundo norma
              vigente, o Risco Operacional é definido como a possibilidade de
              perdas resultantes de falha, deficiência ou inadequação de
              processos internos, pessoas e sistemas, ou de eventos externos que
              impactem na realização dos objetivos estratégicos e operacionais.
              Inclui também o risco legal, associado à inadequação ou
              deficiência em contratos firmados pela Instituição, bem como a
              sanções em razão de descumprimento de dispositivos legais e a
              indenizações por danos a terceiros decorrentes das atividades
              desenvolvidas pela Instituição. Com base nesta definição, o
              gerenciamento deste risco é feito pela área de Compliance e
              Controles Internos, a qual tem como objetivo dar o suporte aos
              gestores das áreas da Instituição nas decisões, buscando a correta
              identificação, avaliação, monitoramento e mitigação dos riscos.
              Para isso, a estrutura possui uma metodologia e instrumentos com a
              finalidade de garantir a qualidade do ambiente de controles
              internos e gestão de riscos operacionais aderentes às políticas
              internas e regulamentações vigentes."
        />
      </S.Container>
      <S.Container className="container">
        <Accordion
          rightIcon={<IconBuildingStore />}
          title="Risco de mercado"
          content={` O risco de mercado é definido como a possibilidade de ocorrência de
          perdas financeiras decorrentes da flutuação nos valores de mercado de
          exposições detidas por uma Instituição Financeira. O objetivo do
          controle de risco de mercado é apoiar a gestão do negócio, estabelecer
          os processos e implementar as ferramentas necessárias para avaliação e
          controle dos riscos de mercado, possibilitando a mensuração e
          acompanhamento dos níveis de apetite a risco definidos pela
          administração. O processo de gerenciamento e controle de risco de
          mercado é regido pela Política de Risco de Mercado, aprovada pela
          Diretoria, a qual estabelece os limites máximos de exposição por fator
          de risco.`}
        />
      </S.Container>
      <S.Container className="container">
        <Accordion
          rightIcon={<IconCreditCard />}
          title="Risco de crédito"
          content={`O gerenciamento de risco de crédito é regido pela Política de Risco de
          Crédito, aprovada pela Diretoria, tem o objetivo de apoiar a Alta
          Administração no processo decisório, definindo estratégias e
          políticas, estabelecendo limites operacionais, mecanismos de mitigação
          de risco e procedimentos destinados a manter a exposição ao risco de
          crédito em níveis considerados aceitáveis pela administração.`}
        />
      </S.Container>
      <S.Container className="container">
        <Accordion
          rightIcon={<IconPresentation />}
          title="Risco de liquidez"
          content={` O risco de liquidez é definido como: A possibilidade de não ser capaz
          de honrar eficientemente suas obrigações esperadas e inesperadas,
          correntes e futuras, inclusive as decorrentes de vinculação de
          garantias, sem afetar suas operações diárias e sem incorrer em perdas
          significativas; e a possibilidade de não conseguir negociar a preço de
          mercado uma posição, devido ao seu tamanho elevado em relação ao
          volume negociado. O gerenciamento de risco de Liquidez é regido pela
          Política de Risco de Liquidez, aprovada pela Diretoria e visa
          organizar, avaliar e monitorar o risco de liquidez da Instituição,
          estabelecendo os processos, ferramentas e limites necessários para
          análise de cenários prospectivos de liquidez e o acompanhamento dos
          níveis de apetite aos riscos estabelecidos pela Alta Administração.
        `}
        />
      </S.Container>
      <S.Container className="container">
        <Accordion
          rightIcon={<IconZoomMoney />}
          title="Gerenciamento de capital"
          content={`
          <p> A Midway implementou sua Estrutura de Gerenciamento de Capital cujo os
          principais objetivos são:</p>
        <ul>
          <li>  Avaliar e adequar à necessidade de capital face aos riscos que a
              Instituição está sujeita;</li>
              <li>
              Apurar e controlar o capital mínimo exigido, segundo as disposições
              do BACEN;
              </li>
              <li>Apurar, monitorar e controlar o capital disponível;</li>
              <li> Planejar a necessidade de capital em função dos objetivos
              estratégicos e orçamentários;</li>
              <li> Implementar controles internos para adequação da necessidade e
              gerenciamento do capital.</li>
        </ul>
      `}
        />
      </S.Container>
      <S.Container className="container">
        <Accordion
          rightIcon={<IconTrees />}
          title="Responsabilidade Socioambiental"
          content={` Conforme determinações do Conselho Monetário Nacional, a Midway possui
          Política de Responsabilidade Socioambiental que está em acordo com a
          Resolução 4327/14 que dispõe sobre as diretrizes que devem ser
          observadas no estabelecimento e na implementação da referida política
          pelas instituições financeiras. Nossa Política de Responsabilidade
          Socioambiental tem como objetivo reafirmar o compromisso do Grupo com
          os princípios de desenvolvimento sustentável, que permeiam todas as
          suas atividades e operações. A Midway se compromete a contribuir com
          soluções financeiras que levem em conta os resultados
          econômico-financeiros das operações, bem como os aspectos sociais e
          ambientais correlacionados. Os aspectos socioambientais referem-se aos
          impactos diretos de suas atividades e indiretos das operações
          financeiras realizadas pela Midway, não restritos aos requisitos
          legais, mas considerando a adoção do princípio da relevância e
          proporcionalidade ao mapear os impactos aqui referidos. O
          gerenciamento de nossas ações está atrelado ao grupo Guararapes e é
          realizado por meio de ações compatíveis com a natureza da operação e o
          porte da Midway.`}
        />
      </S.Container>
    </S.Page>
  );
};

export default Risk;

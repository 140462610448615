import styled, { css } from 'styled-components';
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp';

export const ContractsContainer = styled.div`
  text-align: center;
  border: 1px solid #e4e3e3;
  border-radius: 8px;
  padding: 16px 32px;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
`;
export const ContractsInformation = styled.div`
  margin: 10px;
  span {
    color: #00726d;
    font-weight: bold;
  }
`;
export const ContractsDetails = styled.div`
  span {
    font-weight: bold;
    margin-right: 5px;
  }
  .contacts {
    margin: 10px;
    span {
      text-decoration: underline;
      font-weight: 400;
    }
  }
`;
export const HelpRedirect = styled.div`
  padding: 10px;
  width: 70%;
  border-radius: 20px;
  color: #fff;
  background: #00726d;
  position: relative;
  font-weight: 400;
  margin: 30px auto 0 auto;
  border: 1px solid #00726d;
  cursor: pointer;
  text-align: center;
  ${({ isMobile }) =>
    isMobile &&
    css`
      text-align: center;
    `}
`;
export const HelpReturn = styled.div`
  padding: 10px;
  width: 70%;
  border-radius: 20px;
  color: #00726d;
  font-weight: bold;
  margin: 30px auto 0 auto;
  cursor: pointer;
  text-align: center;
`;

export const WhatsAppIcon = styled(Whatsapp)`
  color: #fff;
  width: 25px;
`;

import Collapsible from 'react-collapsible';
import { breakpoints } from '../../utils';
import { colors } from '~/constants';
import styled from 'styled-components';

export const Dropdown = styled(Collapsible)``;

export const HeaderAndSubheader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderImageContainer = styled.div`
  width: 100%;
  padding: 1rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const HeaderImage = styled.div`
  width: 100%;
  height: 6.25rem;
  background-image: ${props => `url(${props.headerImage})`};
  background-size: 480px 300px;
  background-position: center;
  border-radius: 0.5rem;

  @media (${breakpoints[1176]}) {
    height: 7.5rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionContainer = styled.div`
  background: ${colors.white};
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  height: max-content;
  border: 1px solid #0000001a;
`;

export const BodyContainer = styled.div`
  padding: 0 1rem;
`;

import styled from 'styled-components';
import * as CommonStyles from '../styles';

export const Container = styled(CommonStyles.Container)`
  border-radius: 8px;
  padding: 24px 16px;
  border: 1px solid #e4602f;
  width: calc(100% - 32px);
  margin: 64px auto;

  @media screen and (min-width: 900px) {
    padding: 32px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  svg {
    flex: 0 0 auto;
    stroke: #e4602f;
    stroke-width: 1.5px;
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled(CommonStyles.Title)`
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.25;
  color: #221f20;
  margin-bottom: 0;

  @media screen and (min-width: 900px) {
    font-size: 1.25rem !important;
  }
`;

export const Text = styled(CommonStyles.Text)`
  &:last-of-type {
    margin-bottom: 32px !important;
  }

  @media screen and (min-width: 900px) {
    max-width: 845px;
  }
`;

export const Button = styled(CommonStyles.Button)``;

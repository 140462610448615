import styled from 'styled-components';

export const Container = styled.div`
  strong {
    font-weight: bold;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 22px;
  img {
    width: 96px;
    height: 96px;
  }

  p {
    color: #00726d;
    font-size: 24px;
    width: 80%;
    margin-top: 16px;
  }
`;

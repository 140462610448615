const normalizeString = str => {
  return (
    str &&
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove acentos
      .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
      .replace(/--+/g, '-') // Substitui multiplos hífens por um único hífen
      .replace(/(^-+|-+$)/, '') // Remove hífens extras do final ou do inicio da string
      .toLowerCase()
  );
};

const percentage = value => {
  if (!value) {
    return '0';
  }

  return value.toString().replace('.', ',');
};

const currency = value => {
  if (!value) {
    return 'R$ 0,00';
  }

  const formattedCurrency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formattedCurrency.format(value);
};

const clearDocument = value => {
  return value.replace(/[-.]/g, '');
};

const date = (dated, format = 'DD/MM/YYYY') => {
  const regex = /(\d{4})-(\d{2})-(\d{2})/g;
  const result = regex.exec(dated);

  if (format === 'DD/MM') {
    return result ? `${result[3]}/${result[2]}` : '-';
  }

  return result ? `${result[3]}/${result[2]}/${result[1]}` : '-';
};

export default {
  normalizeString,
  clearDocument,
  percentage,
  currency,
  date,
};

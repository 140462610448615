import React, { useState, useCallback } from 'react';
import iconConfirm from '~/assets/img/icons/iconConfirmGreen.svg';
import history from '~/services/history';
import { tagSimulacaoEmprestimoWellComeScreenButton } from '~/services/tagging';
import * as S from './styles';
import AccountHolderLoanHeader from './components/header/index';
import { AccountHolderLoanButtons } from './components/buttons/index';

const AccountHolderLoan = () => {
  const [checkSelected, setCheckSelected] = useState();

  const toggleCheck = useCallback(() => {
    if (checkSelected)
      tagSimulacaoEmprestimoWellComeScreenButton('checkbox-check');
    setCheckSelected(!checkSelected);
    localStorage.setItem('loanCheckSelected', checkSelected);
  }, [checkSelected]);

  if (!localStorage.getItem('keyCpf')) return history.push('/emprestimos');

  return (
    <S.Container className="container-fluid">
      <S.Content>
        <AccountHolderLoanHeader />

        <div className="ahl-title">
          Seja Bem Vindo ao Simulador do Empréstimo Midway
        </div>

        <div className="ahl-subtitle">
          No final da simulação, você será informado sobre os canais disponíveis
          para contratação.
        </div>

        <div className="ahl-checkbox">
          <div className="ahl-check" onClick={toggleCheck}>
            {checkSelected && (
              <div className="ahl-check-selected">
                <img src={iconConfirm} alt="" />
              </div>
            )}
          </div>
          <label>
            {' '}
            Desejo receber ofertas e novidades da Midway via app, mensagem ou
            e-mail.
          </label>
        </div>
      </S.Content>

      <AccountHolderLoanButtons
        disabledButton={false}
        changeButtonNext={() => {
          tagSimulacaoEmprestimoWellComeScreenButton('prosseguir');
          history.push(`/emprestimos/correntista-steps`);
        }}
        nextPage="emprestimos/correntista-steps"
        changeButtonReturn={() => {
          tagSimulacaoEmprestimoWellComeScreenButton('voltar');
          history.goBack();
        }}
      />
    </S.Container>
  );
};

export default AccountHolderLoan;

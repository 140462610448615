export const banner = [
  {
    name: 'BannerAssistênciaMoto',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-mobile-moto.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-assis-moto.png',
    title:
      'Cuidamos da sua moto como você cuida das suas aventuras. Conte com a nossa Assistência Moto',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

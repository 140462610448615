import React from 'react';
import InvoicePaymentUnavailable from '~/assets/img/cartoes/invoice-payment-unavailable.png';

import * as Styles from './styles';

const SystemUnavailable = () => {
  return (
    <Styles.Container>
      <Styles.Title>Estamos realizando melhorias em nosso site.</Styles.Title>
      <Styles.SubTitle>
        Por isso estamos com os serviços indisponíveis no momento.
      </Styles.SubTitle>
      <Styles.UnavailableImage
        src={InvoicePaymentUnavailable}
        alt="Não se preocupe! Você também consegue fazer tudo pelos nossos aplicativos, Midway e Riachuelo. Baixe agora e aproveite."
      />
    </Styles.Container>
  );
};

export default SystemUnavailable;

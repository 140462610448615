import styled from 'styled-components';

export const Description = styled.div`
  color: #00726d;
  font-size: 16px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  b {
    font-weight: bold;
  }
`;

export const Content = styled.div`
  color: #606060;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  button {
    margin: 16px 0;
  }
  span {
    font-size: 12px;
    text-align: center;
  }
`;

export const DigitableLine = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  color: #606060;
`;

import styled from 'styled-components';

export const ContainerProposals = styled.div`
  margin-top: 24px;

  .container-card {
    margin-top: 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const Others = styled.div`
  margin-bottom: 16px;
`;

import React, { useEffect, useState } from 'react';
import { Spinner } from '@midway/web-ui-component';
import moment from 'moment';
import LoanApi from '~/services/LoanApi';
import * as S from './styles';
import Fail from '../../../components/Fail';

const FirstPayment = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const maskDate = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1');
  };

  const getSimulationPaymentDate = async () => {
    try {
      setError(false);
      setLoading(true);
      const response = await LoanApi.getLoanProfileRate({
        profileCode: props.customer?.profile || 900,
      });
      if (props.onSetCustomer) {
        props.onSetCustomer(prevState => {
          return { ...prevState, ...response };
        });
        setError(false);
      }
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getSimulationPaymentDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <S.WrapperSpinner>
        <Spinner animation="border" state="primary" />
      </S.WrapperSpinner>
    );
  }

  if (error) {
    return <Fail onTryAgainClick={getSimulationPaymentDate} />;
  }

  return (
    <S.Forms>
      <h1>Data de vencimento</h1>
      <h2>Escolha a melhor data para o vencimento da 1ª parcela:</h2>

      <S.InputWrapper>
        <input
          id="date"
          value={maskDate(props.form.firstPaymentDate)}
          onChange={e => props.onChange(e)}
          name="firstPaymentDate"
          pattern=".+"
          required
          className="no-border-focus"
        />
        <label id="teste" className="label" htmlFor="date">
          Data do primeiro pagamento
        </label>
      </S.InputWrapper>
      <p>
        Escolha uma data entre{' '}
        {moment()
          .add(props.customer.gracePeriodMin, 'days')
          .format('DD/MM/YYYY')}{' '}
        e{' '}
        {moment()
          .add(props.customer.gracePeriodMax, 'days')
          .format('DD/MM/YYYY')}
        .
      </p>
    </S.Forms>
  );
};

export default FirstPayment;

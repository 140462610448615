import React from 'react';
import { Button } from '@midway/web-ui-component';
import PropTypes from 'prop-types';
import history from '~/services/history';
import format from '~/services/format';

import AccessibleLink from '~/components/AccessibleLink';
import * as S from './styles';

export default function BaseFinancialProducts({
  session1,
  featured1,
  featured2,
  session21,
  session22,
  session4,
  colorCircle,
  links,
  optionalLinks,
  pageName,
  title,
}) {
  return (
    <>
      <S.Session1 className="container">{session1}</S.Session1>
      <S.Session2 className="container">
        <div className="row">
          {featured1 && (
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.ContainerCircle>
                <S.Circle borderColor={colorCircle}>
                  <S.CircleContent>{featured1}</S.CircleContent>
                </S.Circle>
              </S.ContainerCircle>
              {session21}
            </div>
          )}
          {featured2 && (
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.ContainerCircle>
                <S.Circle borderColor={colorCircle}>
                  <S.CircleContent>{featured2}</S.CircleContent>
                </S.Circle>
              </S.ContainerCircle>
              {session22}
            </div>
          )}
        </div>
        <div className="row pt-0 mt-0">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-5">
            <S.Title className="p-0">{title}</S.Title>
            {optionalLinks.map((elm, i) => (
              <>
                {elm.file ? (
                  <a
                    key={`key-contract-${i}`}
                    href={elm.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-gtm-event-category={`midway:${format.normalizeString(
                      pageName
                    )}`}
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label={`link:${format.normalizeString(
                      elm.text
                    )}`}
                  >
                    {elm.text}
                  </a>
                ) : (
                  <AccessibleLink
                    key={`key-contract-${i}`}
                    route={elm.link}
                    text={elm.text}
                    data-gtm-event-category={`midway:${format.normalizeString(
                      pageName
                    )}`}
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label={`link:${format.normalizeString(
                      elm.text
                    )}`}
                  />
                )}
                <br />
              </>
            ))}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {links.map((elm, i) => (
              <>
                {elm.file ? (
                  <a
                    key={`key-contract-${i}`}
                    href={elm.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-gtm-event-category={`midway:${format.normalizeString(
                      pageName
                    )}`}
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label={`link:${format.normalizeString(
                      elm.text
                    )}`}
                  >
                    {elm.text}
                  </a>
                ) : (
                  <AccessibleLink
                    key={`key-contract-${i}`}
                    route={elm.link}
                    text={elm.text}
                    data-gtm-event-category={`midway:${format.normalizeString(
                      pageName
                    )}`}
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label={`link:${format.normalizeString(
                      elm.text
                    )}`}
                  />
                )}
                <br />
              </>
            ))}
          </div>
        </div>
      </S.Session2>
      <S.Session3>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <h2>CONTRATE JÁ:</h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.Col2>
                <Button
                  title="Lojas RCHLO"
                  aria-label="Lojas Riachuelo"
                  onClick={() =>
                    window.open(
                      'https://www.midway.com.br/buscar-lojas/',
                      '_blank'
                    )
                  }
                  data-gtm-event-category={`midway:${format.normalizeString(
                    pageName
                  )}`}
                  data-gtm-event-action="clique:botao"
                  data-gtm-event-label="lojas-riachuelo"
                />
                <Button
                  title="Central de relacionamento"
                  onClick={() => history.push('/atendimento')}
                  data-gtm-event-category={`midway:${format.normalizeString(
                    pageName
                  )}`}
                  data-gtm-event-action="clique:botao"
                  data-gtm-event-label="central-de-relacionamento"
                />
              </S.Col2>
            </div>
          </div>
        </div>
      </S.Session3>
      <S.Session4>
        <div className="container">{session4}</div>
      </S.Session4>
    </>
  );
}

BaseFinancialProducts.propTypes = {
  session1: PropTypes.element.isRequired,
  featured1: PropTypes.string,
  featured2: PropTypes.string,
  session21: PropTypes.element.isRequired,
  session22: PropTypes.element.isRequired,
  session4: PropTypes.element.isRequired,
  colorCircle: PropTypes.string.isRequired,
  links: PropTypes.array,
  optionalLinks: PropTypes.array,
  pageName: PropTypes.string,
  title: PropTypes.string,
};

BaseFinancialProducts.defaultProps = {
  pageName: 'produtos financeiros',
  featured1: null,
  featured2: null,
  links: [],
  optionalLinks: [],
  title: '',
};

import React, { useCallback, useEffect } from 'react';
import { Icon, Image } from '@midway/web-ui-component';
import { tagMiAjudaButton } from '~/services/tagging';
import error from '~/assets/img/NegotiationCampaign/Status/error.svg';
import no_debt from '~/assets/img/NegotiationCampaign/Status/no_debt.svg';
import positive from '~/assets/img/NegotiationCampaign/Status/positive.svg';
import negative from '~/assets/img/NegotiationCampaign/Status/negative.svg';
import { useNegotiation } from '~/context/negotiationV2';
import { gtagSelectContent, gtagViewContent } from '~/utils/TaggingGA4';
import * as Styles from './styles';

const GTAG_FLOW = 'miajuda';

// eslint-disable-next-line react/prop-types
export const Status = ({ onAction, status, onBackPress, footer }) => {
  const { setShowAlertStatus } = useNegotiation();

  const getTagGa4PageName = useCallback(() => {
    switch (status) {
      case 'error':
        return 'algo-deu-errado';
      case 'no_debt':
        return 'nao-encontramos-valores-a-negociar';
      default:
        return null;
    }
  }, [status]);

  const tagGa4Button = (content_type, params) => {
    gtagSelectContent(getTagGa4PageName(), GTAG_FLOW, content_type, params);
  };

  const statusMessages = {
    error: {
      icon: error,
      title: 'Ops, algo deu errado',
      description:
        'Infelizmente não podemos seguir com a sua solicitação. Tente novamente mais tarde.',
      buttons: [
        {
          text: 'Voltar',
          outline: true,
          action: () => {
            tagGa4Button('voltar');
            tagMiAjudaButton('back');
            setShowAlertStatus('');
          },
        },
      ],
    },
    no_debt: {
      icon: positive,
      title: 'Não localizamos pendências para esse CPF',
      description:
        'Pelo aplicativo Midway você consegue gerenciar as faturas do seu <b>Cartão Riachuelo</b> e as parcelas do seu <b>Empréstimo Pessoal</b>',
      getApp: 'Baixe agora mesmo!',
      buttons: [
        {
          text: 'Baixar app Midway',
          tag: () => {
            tagMiAjudaButton('baixar-app-midway');
            tagGa4Button('baixar-app-midway');
          },
          action: () => window.open('https://www.midway.com.br/apps', '_blank'),
        },
        {
          text: 'Preciso de ajuda',
          outline: true,
          tag: () => {
            tagMiAjudaButton('redirect-pag-ajuda');
            tagGa4Button('preciso-de-ajuda');
          },
          action: () =>
            window.open('https://atendimento.riachuelo.com.br/', '_blank'),
        },
        {
          text: 'Voltar',
          outline: true,
          action: () => {
            tagGa4Button('voltar');
            tagMiAjudaButton('back');
            setShowAlertStatus('');
          },
        },
      ],
    },
    no_debt_new: {
      icon: negative,
      title:
        'Desculpe, não foi possível localizar o cliente com os dados informados',
      description:
        'Caso precise de ajuda, entre em contato com nossa central de relacionamento',
      buttons: [
        {
          text: 'Voltar',
          outline: true,
          action: () => {
            tagGa4Button('voltar');
            tagMiAjudaButton('back');
            setShowAlertStatus('');
          },
        },
      ],
    },
    confirm: {
      icon: no_debt,
      title: 'Importante',
      description:
        'As demais parcelas do acordo serão lançadas automaticamente na fatura do seu cartão RCHLO.',
      buttons: [
        {
          text: 'Voltar',
          tag: () => tagMiAjudaButton('voltar-parcelamento'),
        },
        {
          text: 'Confirmar parcelamento',
          tag: () => tagMiAjudaButton('confirmar-parcelamento'),
        },
      ],
    },
    pre: {
      icon: no_debt,
      title: 'Você possui contratos não negociáveis nesse canal',
      description:
        'Em nosso aplicativo você consegue gerar um código para pagamento ou parcelar a fatura do seu cartão',
      buttons: [
        {
          text: 'Ir para o aplicativo',
          tag: () => tagMiAjudaButton('abrir-app-riachuelo'),
        },
        {
          text: 'Voltar',
        },
      ],
    },
    pos: {
      icon: no_debt,
      title: 'Você possui contratos não negociáveis nos canais digitais',
      description:
        'Para negociar esses produtos, entre em contato com nossa Central de Atendimento via WhatsApp',
      buttons: [
        {
          text: 'Negociar via WhatsApp',
          tag: () => tagMiAjudaButton('negociar-whatsapp'),
        },
        {
          text: 'Voltar',
        },
      ],
    },
    erro412: {
      icon: no_debt,
      title: 'Você possui contratos não negociáveis nos canais digitais',
      description:
        'Para negociar esses produtos, entre em contato com nossa Central de Atendimento via WhatsApp',
      buttons: [
        {
          text: 'Negociar via WhatsApp',
          tag: () => tagMiAjudaButton('negociar-whatsapp'),
          action: () => window.open('https://rchlo.love/negocieEP', '_blank'),
        },
      ],
    },
  };

  useEffect(() => {
    const pageName = getTagGa4PageName();
    if (pageName) gtagViewContent(pageName, GTAG_FLOW);
  }, [getTagGa4PageName]);

  return (
    <Styles.ContainerStatus>
      <Styles.ContainerContent>
        <Styles.ImageContainer>
          <Image src={statusMessages[status]?.icon} alt="Erro" />
        </Styles.ImageContainer>
        <Styles.Title>{statusMessages[status]?.title}</Styles.Title>
        <Styles.Description
          dangerouslySetInnerHTML={{
            __html: statusMessages[status]?.description,
          }}
        />
        <Styles.GetApp>{statusMessages[status]?.getApp}</Styles.GetApp>
        <Styles.ButtonContainer confirm={status === 'confirm'}>
          {statusMessages[status]?.buttons?.map(button => (
            <Styles.Button
              key={button.text}
              onClick={() => {
                if (button.tag) button.tag();
                if (button.action) return button.action();
                if (button.text === 'Voltar') return onBackPress();

                if (onAction) onAction();
              }}
              outline={button?.outline}
              aria-label={button.text}
            >
              {button.text}
            </Styles.Button>
          ))}
        </Styles.ButtonContainer>

        {footer && <Styles.Footer>{footer}</Styles.Footer>}
      </Styles.ContainerContent>
    </Styles.ContainerStatus>
  );
};

export const LoadingStatus = ({ text }) => {
  return (
    <Styles.ContainerStatus>
      <Styles.ContainerLoader>
        <Icon color="#fff" icon="spinner" size="4x" />
        <Styles.Title>{text}</Styles.Title>
      </Styles.ContainerLoader>
    </Styles.ContainerStatus>
  );
};

import styled from 'styled-components';

export const Page = styled.div`
  background-color: #fff;
`;

export const Container = styled.div`
  padding-top: 20px;
`;

export const Row = styled.div``;

export const Col = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    font-size: 28px !important;
  }
`;

export const Subtitle = styled.h2`
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Text = styled.p`
  ${props => (props.maxWidth ? 'max-width: 500px;' : '')};
  white-space: pre-wrap;
  line-height: 1.8;
  color: #707070;
  font-size: 16px !important;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-left: 15px;
  color: #707070;
`;

export const ListItem = styled.li`
  font-size: 16px;
`;

export const HeroSection = styled.div`
  display: flex;
  flex-shrink: 0;
  background: #f7f7f7;
  flex-direction: row;
  width: 100%;
`;

export const HeroSectionMobile = styled.div`
  display: flex;
  flex-shrink: 0;
  background: #f7f7f7;
  flex-direction: column;
  width: 100%;
`;

export const HeroContentMobile = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 605px;
  padding: 3em 0;
  align-items: center;
  gap: 4em;
  > div {
    flex: 1;
    h3 {
      font-size: 12px !important;
      color: #4d4d4d !important;
    }
    h1 {
      font-size: 22px !important;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 2em;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin-bottom: 1.3em !important;
    }
    a.styleDefault {
      color: #e4602f !important;
      margin-top: 1em;
      font-weight: bold;
    }
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  min-height: 605px;
  padding: 3em 0;
  align-items: center;
  gap: 4em;
  > div {
    flex: 1;

    h1 {
      font-size: 32px !important;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 2em;
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      margin-bottom: 1.3em !important;
    }
    a.styleDefault {
      color: #e4602f !important;
      margin-top: 1em;
      font-weight: bold;
    }
  }
`;

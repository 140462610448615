import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1173px;

  @media screen and (max-width: 1219px) {
    margin-bottom: 24px;
  }
`;

export const Centralize = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 16px;
`;

export const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  max-width: ${props => props.maxWidth || '381px'};
  min-height: 230px;
  max-height: 311px;
  background-color: #221f20;
  padding: 32px;
  @media (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
    max-height: 400px;
  }
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: white;
  padding-bottom: 16px;

  @media (max-width: 1219px) {
    font-size: 19px;
  }
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: white;
  padding-bottom: 8px;
`;

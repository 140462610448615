import { v4 as uuidv4 } from 'uuid';
import { instance } from './MGMInstance';

const faileds = ['Request failed with status code 422'];

const postMemberGetMember = async ({
  promoterDocumentNumber,
  guestDocumentNumber,
  guestDocumentType,
  guestEmail,
  guestFullName,
}) => {
  const uri = `/member-get-member/v1/guest`;

  const headers = {
    'x-midway-transaction-id': uuidv4(),
  };

  const body = {
    promoter: {
      documentNumber: promoterDocumentNumber,
    },
    guest: {
      documentNumber: guestDocumentNumber,
      documentType: guestDocumentType,
      email: guestEmail,
      fullName: guestFullName,
    },
  };

  return instance
    .post(uri, body, {
      headers,
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error?.message && error?.message === faileds[0]) {
        return 'alreadyexists';
      }
      return error;
    });
};

export default {
  postMemberGetMember,
};

import React from 'react';
import { Icon } from '@midway/web-ui-component';
import styled, { css } from 'styled-components';

const ContainerDayOffer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 24px;
  font-weight: 500;
  max-width: max-content;
  padding: 10px !important;

  ${({ green }) =>
    green &&
    css`
      background: linear-gradient(247.03deg, #22c9c3 -38.12%, #a5df9f 100%);
      color: #1f2b2a;
      border-radius: 4px;
      svg {
        color: #1f2b2a;
      }
    `}
  ${({ purple }) =>
    purple &&
    css`
      background: #9f5bd9;
      color: #fff;
      border-radius: 17px;
      svg {
        color: #fff !important;
      }
    `}

  @media (max-width: 768px) {
    font-size: 2vmin;
  }
  svg {
    margin-right: 5px;
  }
`;

const DayOffer = ({ green, purple }) => (
  <ContainerDayOffer green={green} purple={purple}>
    <Icon icon="bell" />
    Oferta do dia
  </ContainerDayOffer>
);

export default DayOffer;

import React, { useState } from 'react';
import { Image, Icon } from '@midway/web-ui-component';
import Accordion from '~/components/Accordion';
import history from '~/services/history';
import { colors } from '~/constants';
import { Faq } from '~/constants/faq';
import Banner from '~/assets/img/Financial/faqloans/banner.png';
import logoSmall from '~/assets/img/Financial/lending/logoSmall.svg';
import * as S from './styles';

const Breadcrumb = () => {
  return (
    <div className="breadcrumb-content">
      <div className="breadcrumb-item-mobile">
        <Icon
          icon={['fa', 'chevron-left']}
          size="1x"
          color={colors.breadcrumb_dark}
        />
        <div onClick={() => history.push('/emprestimos')}>
          <span>Empréstimos</span>
        </div>
      </div>
      <div className="breadcrumb-item">
        <div onClick={() => history.push('/')}>
          <span>Início</span>
          <Icon
            icon={['fa', 'chevron-right']}
            size="1x"
            color={colors.breadcrumb_dark}
          />
        </div>
        <div onClick={() => history.push('/produtos-financeiros')}>
          <span>Produtos Financeiros</span>
          <Icon
            icon={['fa', 'chevron-right']}
            size="1x"
            color={colors.breadcrumb_dark}
          />
        </div>
        <div id="1" onClick={() => history.push('/emprestimos')}>
          <span>Empréstimos</span>
          <Icon
            icon={['fa', 'chevron-right']}
            size="1x"
            color={colors.breadcrumb_dark}
          />
        </div>
        <span>Perguntas Frequentes</span>
      </div>
    </div>
  );
};

const FaqLoan = () => {
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState(false);

  const handleInput = event => {
    setSearch(true);
    const { value } = event.target;
    const newArray = Faq.flatMap(item => item.questions);
    const resultQuestions = [];

    if (value === '') {
      setSearch(false);
      resultQuestions.length = 0;
      setFilters([]);
    } else {
      newArray.forEach(elem => {
        const regex = new RegExp(`(${value})`.toLowerCase(), 'g');
        const title = regex.test(`${elem.title}`.toLowerCase());
        return title && resultQuestions.push(elem);
      });

      setFilters(resultQuestions);
      setSearch(true);
    }
  };

  return (
    <S.Container className="container-fluid" id="ready-page-for-screen-reader">
      <S.BannerContainer className="container-fluid">
        <Image
          src={Banner}
          alt="Fundo com cor verde com nuances em tons diferentes da cor verde"
          aria-hidden="true"
          responsive="true"
        />
        <S.BannerSearch>
          <h1 className="is-primary-default">Perguntas Frequentes</h1>
          <S.HeaderSearch>
            <S.SearchInput
              type="search"
              placeholder="Qual é a sua dúvida?"
              onChange={event => handleInput(event)}
            />
            <S.SearchIcon />
          </S.HeaderSearch>
        </S.BannerSearch>
      </S.BannerContainer>

      <S.ContainerBreadcrumb>
        <Breadcrumb />
        <S.MidwayLogo>
          <Image src={logoSmall} />
        </S.MidwayLogo>
      </S.ContainerBreadcrumb>

      {search ? (
        <S.FaqContainer>
          <div className="container">
            {Object.keys(filters).length > 0 ? (
              filters.map(item => {
                return (
                  <Accordion
                    id={item.id}
                    title={item.title}
                    content={item.content}
                  />
                );
              })
            ) : (
              <h2>Nenhum resultado</h2>
            )}
          </div>
        </S.FaqContainer>
      ) : (
        <>
          <S.Category className="container">
            <h2>Categorias</h2>
            <div className="category">
              {Faq.map(item => (
                <a
                  className="item-category"
                  href={`/emprestimos/faq#${item.id}`}
                  key={item.id}
                >
                  <h3>{item.title}</h3>
                </a>
              ))}
            </div>
          </S.Category>
          <S.FaqContainer>
            <div className="container">
              {Faq.map(item => (
                <>
                  <h2 id={item.id} key={item.id}>
                    {item.title}
                  </h2>
                  {item.questions.map(question => (
                    <Accordion
                      id={question.id}
                      title={question.title}
                      content={question.content}
                    />
                  ))}
                </>
              ))}
            </div>
          </S.FaqContainer>
        </>
      )}
    </S.Container>
  );
};

export default FaqLoan;

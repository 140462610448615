import { useState, useCallback } from 'react';

import { confirm } from '~/services/InvoiceInstallment';
import { useAuth } from '~/context/auth';

const useConfirmInstallment = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const { documentNumber, maskedPan, machineId: deviceId } = useAuth();

  const memoized = useCallback(
    async payload => {
      setLoading(true);
      setError(false);
      try {
        const response = await confirm({
          maskedPan,
          documentNumber,
          deviceId,
          payload,
        });
        setData(response.data);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [deviceId, documentNumber, maskedPan]
  );

  const cleanup = () => {
    setLoading(false);
    setError(false);
    setData(null);
  };

  return [
    memoized,
    {
      loading,
      error,
      data,
    },
    cleanup,
  ];
};

export default useConfirmInstallment;

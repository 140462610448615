import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';

import BankSlipFormEntry from '~/components/BankSlipFormEntry';
import useGetBankSlips from '~/hooks/useGetBankSlips';
import useGetUpsell from '~/hooks/useGetUpsell';
import { AuthProvider } from '~/context/auth';
import InvoiceInstallment from '~/pages/InvoiceInstallment';
import { tagContaOnlineConsultaBoletoCallback } from '~/services/tagging';
import {
  trackingViewContent,
  trackingSelectContent,
  trackingException,
} from '~/analytics';
import { flow, contentPage, contentType, description } from '~/analytics/cards';

import Status from './components/Status';
import BankSlipList from './components/BankSlipList';
import ImagesParallax from './components/ImagesParallax';
import SystemUnavailable from './components/SystemUnavailable';
import { UpsellDesktop, UpsellMobile } from './components/Upsell';
import {
  CallToActionHeader,
  CallToActionCard,
} from './components/CallToActions/NegotiationCampaingn';
import * as Styles from './styles';

const Container = ({
  children,
  hasLimitPreApproved,
  linkToNegotiation,
  showCallToActionNegotiationCampaign,
}) => {
  const { toogles } = useSelector(state => state.featuretoggle);
  const history = useHistory();

  const handleKnowMoreButtonClick = () => {
    trackingSelectContent(
      contentType.knowMore,
      flow.payments,
      contentPage.billetInvoiceOrLending
    );
    history.push('/apps');
  };

  return (
    <>
      {toogles.isEnabledContaOnline ? (
        <>
          {!showCallToActionNegotiationCampaign &&
            toogles.isEnabledCallToActionsNegotiation && (
              <CallToActionHeader link={linkToNegotiation} />
            )}
          <Styles.Container>
            {hasLimitPreApproved && <UpsellMobile />}
            <Styles.LeftContainer>
              {showCallToActionNegotiationCampaign &&
                toogles.isEnabledCallToActionsNegotiation && (
                  <CallToActionCard link={linkToNegotiation} />
                )}
              {children}
            </Styles.LeftContainer>
            <Styles.RightContainer>
              {hasLimitPreApproved ? (
                <UpsellDesktop />
              ) : (
                <>
                  <ImagesParallax />
                  <Styles.Text>
                    Compare nossos apps e veja os benefícios de cada um!
                  </Styles.Text>
                  <Styles.Button
                    aria-label="Saiba mais"
                    onClick={handleKnowMoreButtonClick}
                  >
                    Saiba mais
                  </Styles.Button>
                </>
              )}
            </Styles.RightContainer>
          </Styles.Container>
        </>
      ) : (
        <SystemUnavailable />
      )}
    </>
  );
};

const BilletInvoiceOrLendingV2 = () => {
  const [
    getUpsell,
    {
      data: { hasLimitPreApproved },
    },
  ] = useGetUpsell();
  const [
    service,
    {
      data,
      loading,
      error,
      showCallToActionNegotiationCampaign,
      updateUserData,
      linkToNegotiation,
    },
    cleanup,
  ] = useGetBankSlips();
  const [documentNumber, setDocumentNumber] = useState('');
  const [birthDate, setBithDate] = useState('');
  const [type, setType] = useState('invoice');
  const [installmentData, setInstallmentData] = useState({
    visible: false,
    entryValueMin: 0,
    entryValueMax: 0,
    isSimulating: false,
    installmentOffer: undefined,
    activeInstallment: undefined,
  });

  const handleSimulateOtherOptions = ({ entryValueMin, entryValueMax }) => {
    setInstallmentData(current => ({
      ...current,
      visible: true,
      isSimulating: true,
      entryValueMin,
      entryValueMax,
    }));
  };

  /**
   * This state update causes a side effect in InvoiceInstallment component
   * that leads to a installment confirmation */
  const handleConfirmInstallmentOffer = ({
    installmentOffer,
    entryValueMin,
    entryValueMax,
  }) => {
    setInstallmentData(current => ({
      ...current,
      visible: true,
      installmentOffer,
      entryValueMin,
      entryValueMax,
    }));
  };

  const handleAccessActiveInstallment = ({
    activeInstallment,
    entryValueMin,
    entryValueMax,
  }) => {
    setInstallmentData(current => ({
      ...current,
      visible: true,
      activeInstallment,
      entryValueMin,
      entryValueMax,
    }));
  };

  const handleCloseInstallmentModal = () => {
    setInstallmentData(current => ({
      ...current,
      visible: false,
      entryValueMin: 0,
      entryValueMax: 0,
      installmentOffer: undefined,
      activeInstallment: undefined,
    }));
  };

  const handleConfirmInstallment = () => {
    setInstallmentData(current => ({
      ...current,
      isSimulating: false,
    }));
  };

  const handleSimulateNewInstallment = () => {
    setInstallmentData(current => ({
      ...current,
      isSimulating: true,
      installmentOffer: undefined,
      activeInstallment: undefined,
    }));
  };

  const handleNoBilletStatusAction = () => {
    trackingSelectContent(
      contentType.goBack,
      flow.payments,
      contentPage.invoiceUnavailable
    );
    cleanup();
  };

  const handleErrorStatusAction = () => {
    trackingSelectContent(
      contentType.retry,
      flow.payments,
      contentPage.billetInvoiceOrLending
    );
    cleanup();
  };

  useEffect(() => {
    trackingViewContent(contentPage.billetInvoiceOrLending, flow.payments);
  }, []);

  useEffect(() => {
    if (data && data.length === 0) {
      trackingViewContent(contentPage.invoiceUnavailable, flow.payments);
    }

    if (data && data.length) {
      if (type === 'loan') {
        tagContaOnlineConsultaBoletoCallback('boleto-emprestimo', 'enviado');
      }
    }
  }, [data, type]);

  useEffect(() => {
    updateUserData({ documentNumber, birthDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentNumber, birthDate]);

  useEffect(() => {
    if (error) {
      trackingException(description.somethingWentWrong, flow.payments);
    }
  }, [error]);

  const onSubmit = ({ documentNumber: dn, birthDate: bd, type: tp }) => {
    setDocumentNumber(dn);
    setBithDate(bd);
    setType(tp);

    const formatedDate = moment(bd, 'DD/MM/YYYY').format('YYYY-MM-DD');
    service(tp, {
      documentNumber: dn,
      birthDate: formatedDate,
    });

    getUpsell({ birthDate: formatedDate, documentNumber: dn });
  };

  if (data && data.length === 0) {
    return (
      <Container
        hasLimitPreApproved={hasLimitPreApproved}
        linkToNegotiation={linkToNegotiation}
      >
        <Status status="no_billet" onAction={handleNoBilletStatusAction} />
      </Container>
    );
  }

  if (data && data.length > 0) {
    return (
      <Container
        hasLimitPreApproved={hasLimitPreApproved}
        linkToNegotiation={linkToNegotiation}
        showCallToActionNegotiationCampaign={
          showCallToActionNegotiationCampaign
        }
      >
        {installmentData.visible && (
          <InvoiceInstallment
            installmentData={installmentData}
            onBack={handleCloseInstallmentModal}
            onSimulateNewInstallment={handleSimulateNewInstallment}
            onConfirmInstallment={handleConfirmInstallment}
          />
        )}
        <BankSlipList
          documentNumber={documentNumber}
          billetType={type}
          onError={cleanup}
          items={data}
          onConfirmSuggestedInstallment={handleConfirmInstallmentOffer}
          onSimulateOtherOptions={handleSimulateOtherOptions}
          onAccessActiveInstallment={handleAccessActiveInstallment}
        />
      </Container>
    );
  }

  if (error) {
    return (
      <Container
        hasLimitPreApproved={hasLimitPreApproved}
        linkToNegotiation={linkToNegotiation}
      >
        <Status status="error" onAction={handleErrorStatusAction} />
      </Container>
    );
  }

  return (
    <Container
      hasLimitPreApproved={hasLimitPreApproved}
      linkToNegotiation={linkToNegotiation}
    >
      <BankSlipFormEntry
        documentNumber={documentNumber}
        birthDate={birthDate}
        type={type}
        loading={loading}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
    <AuthProvider>
      <BilletInvoiceOrLendingV2 />
    </AuthProvider>
  </GoogleReCaptchaProvider>
);

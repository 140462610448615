import styled from 'styled-components';
import bgCallToAction from '~/assets/img/new-home/bg-call-to-action.svg';

export const Modal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: ${({ visible }) => (visible ? 'all' : 'none')};

  .background {
    width: 100%;
    height: 100%;
    background: #00000090;
    position: fixed;
    z-index: 5;
    top: 0;
  }

  .background-open {
    transition: 0.25s ease-in-out;
    opacity: 1;
  }

  .background-closed {
    transition: 0.25s ease-in-out;
    opacity: 0;
  }

  .content {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-image: url(${bgCallToAction});
    width: 50%;
    max-width: 670px;
    z-index: 5;
    display: flex;
    padding: 50px;
    flex-direction: column;

    a {
      width: 10px;
      align-self: flex-end;
      border-radius: 50%;
      .closeButtom {
        width: 50px;
      }
    }
  }

  .content-open {
    transition: 0.5s ease-in-out;
    transform: translateX(0px);
  }

  .content-closed {
    transition: 0.5s ease-in-out;
    transform: translateX(670px);
  }
`;

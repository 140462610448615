import styled, { css } from 'styled-components';

export const TagContainer = styled.div`
  background: #e5fffe;
  border-radius: 4px;
  margin-right: 10px;
  color: #00726d;
  min-width: max-content;
  max-width: max-content;
  height: max-content;
  font-weight: 500;
  padding: 4px;
  @media screen and (max-width: 580px) {
    font-size: 3vw;
  }

  ${({ color }) =>
    color &&
    css`
      background: ${color};
      color: #004d49;
    `}

  ${({ discount }) =>
    discount &&
    css`
      margin-bottom: 16px;
    `}
`;

import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { Facebook } from '@styled-icons/boxicons-logos/Facebook';

const Icons = {
  Linkedin,
  Instagram,
  Facebook,
};

export default Icons;

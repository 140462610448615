export const flow = {
  payments: 'pagamentos',
};

export const contentPage = {
  billetInvoiceOrLending: 'pagar-fatura-ou-emprestimos',
  invoiceUnavailable: 'sem-fatura-disponivel',
  bankSlipList: 'encontramos-faturas-para-pagamentos:fatura',
  genericError: 'erro-generico',
  installmentSuccess: 'simulacao-parcelamento-gerado:fatura',
  installmentSuccessFromActiveInstallment:
    'simulacao-parcelamento-gerado:fatura:pendente',
  installmentSuccessFromSimulation: 'simulacao-parcelamento-gerado:simulado',
  installmentSimulation: 'simulacao:fatura',
  invoiceDetails: 'detalhes-da-fatura:fatura',
};

export const description = {
  somethingWentWrong:
    'algo-deu-errado-nao-podemos-seguir-com-sua-solicitacao-no-momento',
  getBilletError: 'algo-deu-errado-ao-obter-o-boleto',
  getOptionsError: 'algo-deu-errado-ao-obter-a-simulacao-do-parcelamento',
  getAvailabilityError:
    'algo-deu-errado-ao-obter-a-disponibilidade-do-parcelamento',
  installmentUnavailable: 'oferta-de-parcelamento-indisponivel-para-o-cliente',
  getPixQRCodeError: 'algo-deu-errado-ao-obter-o-qrcode-pix',
};

export const contentType = {
  checkInvoice: 'check:boleto-fatura-cartao-rchlo-midway',
  checkLoan: 'check:boleto-emprestimo',
  generateBilletInvoice: 'gerar-boleto:fatura-cartao-rchlo-midway',
  generateBilletLoan: 'gerar-boleto:emprestimo',
  paymentNegociation: 'negociacoes-de-pagamento',
  knowMore: 'saiba-mais',
  retry: 'tentar-novamente',
  goBack: 'voltar',
  newInstallment: 'novo-parcelamento',
  copyPayline: 'copiar-codigo-do-boleto',
  copyPixCopyAndPasteCode: 'copiar-codigo-do-pix',
  moreOptions: 'mais-opcoes',
  installmentOptionSelected: amountOf => `parcela-selecionada:${amountOf}`,
  generateBillet: 'gerar-boleto',
  invoiceInstallmentChecked: 'check-parcelar-fatura',
  invoiceBilletChecked: 'check-pagar-total-com-boleto',
  info: 'informacoes',
  confirmInstallment: 'contratar-parcelamento',
  simulateOtherOptions: 'simular-opcoes',
  begetPix: 'gerar-pix',
  selectInvoice: type => `clique-fatura:${type}`,
};

import React from 'react';
import Button from '~/components/NewUi/Button';
import { trackingSelectContent } from '~/analytics';

import {
  ActionButton,
  Badge,
  Content,
  ContentWrapper,
  ImageWrapper,
  SectionWrapper,
  Title,
} from './styles';

const Section = ({
  title,
  description,
  action,
  actionText,
  image,
  index,
  badge,
}) => {
  const reverse = index % 2 === 0;
  return (
    <SectionWrapper reverse={reverse}>
      <ImageWrapper>
        <img src={image} alt="" title="" />
      </ImageWrapper>
      <ContentWrapper>
        {badge && <Badge>{badge}</Badge>}
        <Title>{title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: description }} />
        {action && (
          <ActionButton>
            <Button
              onClick={() => {
                action();
                const trackingName = actionText
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/\s+/g, '-');
                trackingSelectContent(trackingName, 'conta-digital');
              }}
              aria-label={actionText}
              role="button"
              tabIndex={0}
            >
              {actionText}
            </Button>
          </ActionButton>
        )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default Section;

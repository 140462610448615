import React, { useEffect, useState } from 'react';
import { Banner } from './components/Banner';
import { HowItWorks } from './components/HowItWorks';
import { Advantages } from './components/Advantages';
import { Benefits } from './components/Benefits';
import { DigitalAccount } from './components/DigitalAccount';
import { Stores } from './components/Stores';
import { FrequentlyQuestions } from './components/FrequentlyQuestions';

import './styles.css';

const GuaranteedLimitLandingPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 850);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <>
      <Banner isMobile={isMobile} />
      <HowItWorks isMobile={isMobile} />
      <Advantages isMobile={isMobile} />
      <Benefits isMobile={isMobile} />
      <DigitalAccount isMobile={isMobile} />
      <Stores isMobile={isMobile} />
      <FrequentlyQuestions />
    </>
  );
};

export default GuaranteedLimitLandingPage;

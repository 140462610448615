import styled from 'styled-components';

export const TutorialContainer = styled.div`
  max-height: ${props => props.maxHeight && props.maxHeight};
  background-color: #7ee6e6;
  @media (max-width: 992px) {
    max-height: unset;
  }
  span {
    white-space: pre-wrap;
    max-width: 530px;
    font-size: 23px;
    color: #008d8c;
    @media (max-width: 992px) {
      max-width: 290px;
      font-size: 14px;
    }
  }
  a {
    margin-right: 50px;
    @media (max-width: 992px) {
      margin-right: 15px;
    }
  }
`;

export const Title = styled.h2`
  white-space: pre-wrap;
  font-size: 40px;
  font-weight: bold;
  color: #008d8c;
  padding-top: 60px;
  padding-bottom: 30px;
  @media (max-width: 992px) {
    font-size: 22px;
    padding-top: 28px;
    padding-bottom: 16px;
  }
`;

export const TutorialMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AppCardRCHLO = styled.div`
  max-width: 443px;
  height: 600px;
  margin-top: -48.5px;
  transform: rotate(20deg);
  @media (max-width: 992px) {
    max-width: 280px;
    height: 410px;
    margin-top: 20px;
    transform: rotate(15deg);
    & > div > img {
      height: 430px !important;
    }
  }
  & > div > img {
    height: 570px;
  }
`;

export const LinkApp = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const Term = styled.div`
  margin-top: 30px;
  font-size: 11pt;
`;

import React from 'react';

import annuityIllustration from '~/assets/img/GuaranteedLimitLP/annuity-illustration.svg';
import approvedIllustration from '~/assets/img/GuaranteedLimitLP/approved-illustration.svg';
import creditIllustration from '~/assets/img/GuaranteedLimitLP/credit-illustration.svg';
import {
  Container,
  Content,
  Header,
  Title,
  Text,
  AdvantagesWrapper,
  AdvantageContainer,
  AdvantageImageContainer,
  AdvantageImage,
  AdvantageTitle,
  AdvantageText,
} from './styles';

export const Advantages = ({ isMobile }) => {
  const advantages = [
    {
      id: 1,
      title: 'Sem anuidade',
      text: 'Feito para você economizar em todas as suas compras.',
      alt: 'Na ilustração, vemos um personagem masculino sorridente segurando uma ilustração de uma moeda cortada na cor vermelha.',
      image: annuityIllustration,
    },
    {
      id: 2,
      title: 'Aprovado na hora',
      text: 'Seu cartão pronto para uso em instantes.',
      alt: 'Na ilustração, uma mulher segura um cartão Riachuelo com um check verde logo acima.',
      image: approvedIllustration,
    },
    {
      id: 3,
      title: 'Sem análise de crédito',
      text: 'Você garante o seu limite mesmo sem crédito aprovado!',
      alt: "Na ilustração, uma mulher segura um documento com um sorriso no rosto. Acima do documento, está o texto '+50%'.",
      image: creditIllustration,
    },
  ];

  return (
    <Container id="beneficios">
      <Content>
        <Header>
          <Title>São muitas vantagens</Title>
          <Text>
            Só com o Limite Garantido, você aproveita todas as facilidades de
            ter um cartão:
          </Text>
        </Header>

        <AdvantagesWrapper>
          {advantages.map(({ id, image, title, text, alt }) => (
            <AdvantageContainer key={id} mobile={isMobile}>
              <AdvantageImageContainer>
                <AdvantageImage mobile={isMobile} src={image} alt={alt} />
              </AdvantageImageContainer>

              <AdvantageTitle>{title}</AdvantageTitle>
              <AdvantageText>{text}</AdvantageText>
            </AdvantageContainer>
          ))}
        </AdvantagesWrapper>
      </Content>
    </Container>
  );
};

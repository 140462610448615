import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Spinner } from '@midway/web-ui-component';
import moment from 'moment';
import IconClosed from '~/assets/img/Financial/lending/icon-closed.svg';
import LoanApi from '~/services/LoanApi';
import Fail from '../../../components/Fail';
import * as S from './styles';

const SimulationSummary = _props => {
  const [showModalContract, setShowModalContract] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    cetAnual: 0,
    valorDaParcela: 0,
    taxaJurosAnual: 0,
    taxaJurosMensal: 0,
    valorTarifaCadastro: 0,
    quantidadeParcelas: 0,
    valorTotalEmprestimo: 0,
    valorTotalParcelas: 0,
    cetMensal: 0,
    valorSolicitado: 0,
    percLiquidoLiberar: 0,
    percTarifaCadastro: 0,
    percTotalEmprestimo: 0,
    valorFinanciado: 0,
    percIOF: 0,
    valorIof: 0,
    valorLiquidoLiberar: 0,
    dadosSimulacoes: [],
  });

  const maskCurrency = value =>
    new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

  const init = useCallback(async () => {
    setLoading(true);
    const response = await LoanApi.getDetailedLoanSimulation({
      firstDueDate: moment(
        _props.form.firstPaymentDate,
        'DD/MM/YYYY'
      ).toString(),
      contractedValue: _props.form.loanValue,
      installments: _props.form.installments,
      profileCode: _props.customer?.profile,
      cpf: _props.form.cpf.replace(/[^0-9]/g, ''),
    });
    if (response.valorDaParcela) {
      setData(response);
      setError(false);
    }
    setLoading(false);
  }, [_props.customer, _props.form]);

  useEffect(() => {
    if (init()?.response?.status !== 200) {
      setError(true);
    }
    init();
  }, [init]);

  return (
    <>
      {loading ? (
        <S.WrapperSpinner>
          <Spinner animation="border" state="primary" />
        </S.WrapperSpinner>
      ) : !error ? (
        <S.Forms>
          <h1>Resumo da simulação de empréstimo</h1>
          <S.WrapperSummary>
            <div>
              <p>Valor total solicitado:</p>
              <p>R$ {maskCurrency(data.valorSolicitado)}</p>
            </div>
            <div>
              <p>Valor total financiado:</p>
              <p>R$ {maskCurrency(data.valorFinanciado)}</p>
            </div>
            <div>
              <p>Quantidade de parcelas:</p>
              <p>
                {data.quantidadeParcelas}x de R${' '}
                {maskCurrency(data.valorDaParcela)}
              </p>
            </div>
            <div>
              <p>Primeiro pagamento:</p>
              <p>
                {moment(
                  new Date(
                    data.dadosSimulacoes.length
                      ? data.dadosSimulacoes[0].dataVenctoParcela
                      : ''
                  )
                ).format('DD/MM/YYYY')}
              </p>
            </div>
            <div>
              <p>IOF:</p>
              <p>R$ {maskCurrency(data.valorIof)}</p>
            </div>
            <div>
              <p>Taxa de juros:</p>
              <p>
                {maskCurrency(data.taxaJurosMensal)}% a.m{' '}
                <span>{maskCurrency(data.taxaJurosAnual)}% a.a </span>
              </p>
            </div>
            <div>
              <p>CET:</p>
              <p>
                {maskCurrency(data.cetMensal)}% a.m{' '}
                <span>{maskCurrency(data.cetAnual)}% a.a</span>
              </p>
            </div>
            <p className="link" onClick={() => setShowModalContract(true)}>
              *Detalhamento do CET
            </p>
          </S.WrapperSummary>

          {showModalContract && (
            <Modal
              state="white-lighter"
              openModal={setShowModalContract}
              onHide={() => setShowModalContract(false)}
            >
              <S.ModalContent>
                <div
                  className="ahl-icon-closed"
                  onClick={() => setShowModalContract(false)}
                >
                  <img src={IconClosed} alt="Botão de fechar, ícone com um x" />
                </div>

                <h2>*Detalhamento do CET</h2>
                <p>
                  O CET (Custo Efetivo Total) é um valor percentual que
                  representa a soma de todos os encargos e despesas incidentes
                  nas operações de crédito.
                </p>

                <S.GridRowHeader>
                  <S.GridHeaderItem>Discriminação dos valores</S.GridHeaderItem>
                  <S.GridHeaderItem>R$</S.GridHeaderItem>
                  <S.GridHeaderItem>%</S.GridHeaderItem>
                </S.GridRowHeader>
                <S.GridRow>
                  <S.GridItem>Valor solicitado</S.GridItem>
                  <S.GridItem>{maskCurrency(data.valorSolicitado)}</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>Valor financiado</S.GridItem>
                  <S.GridItem>{maskCurrency(data.valorFinanciado)}</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>Taxa de juros</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                  <S.GridItem>
                    {maskCurrency(data.taxaJurosMensal)}% a.m. /{' '}
                    {maskCurrency(data.taxaJurosAnual)}% a.a.
                  </S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>Quantidade de parcelas</S.GridItem>
                  <S.GridItem>{data.quantidadeParcelas}</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>Valor da parcela</S.GridItem>
                  <S.GridItem>{maskCurrency(data.valorDaParcela)}</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>Valor total das parcelas</S.GridItem>
                  <S.GridItem>
                    {maskCurrency(data.valorTotalParcelas)}
                  </S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>
                    a) valor total devido do empréstimo ou financiamento ou
                    arrendamento mercantil financeiro no ato da contratação:
                  </S.GridItem>
                  <S.GridItem>{maskCurrency(data.valorFinanciado)}</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>
                    b) valor liberado ao cliente ou vendedor:
                  </S.GridItem>
                  <S.GridItem>
                    {maskCurrency(data.valorLiquidoLiberar)}{' '}
                  </S.GridItem>
                  <S.GridItem>
                    {maskCurrency(
                      100 * (data.valorSolicitado / data.valorFinanciado)
                    )}
                    %(b/a)
                  </S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>
                    c) despesas vinculadas à concessão do crédito:
                  </S.GridItem>
                  <S.GridItem>-</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>c1) tarifas:</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>c2) tributos:</S.GridItem>
                  <S.GridItem>{maskCurrency(data.valorIof)} </S.GridItem>
                  <S.GridItem>
                    {maskCurrency(100 * (data.valorIof / data.valorFinanciado))}
                    %(c1/a)
                  </S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>c3) seguro:</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
                <S.GridRow>
                  <S.GridItem>4) outros:</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                  <S.GridItem>-</S.GridItem>
                </S.GridRow>
              </S.ModalContent>
            </Modal>
          )}
        </S.Forms>
      ) : (
        <Fail onTryAgainClick={init} />
      )}
    </>
  );
};

export default SimulationSummary;

import React from 'react';

import midwayERiachuelo from '~/assets/img/Indications/midwayERiachuelo.png';

import * as S from './styles';

const ThirdBanner = () => {
  return (
    <S.ThirdBanner className="container-fluid">
      <div className="container">
        <img
          alt="Imagem do app com cartão midway"
          src={midwayERiachuelo}
          id="app"
        />
        <div className="conteudo">
          <div className="left-side">
            <div>
              <p className="title">
                O SEU
                <br />
                BEM-ESTAR
                <br />É DA NOSSA
                <br />
                CONTA.
              </p>
            </div>
            <p className="espacamento subtitle">
              Com a Midway você tem a segurança, praticidade e rapidez que a sua
              vida pede.
            </p>
          </div>
          <div className="right-side">
            <p className="no-espacamento subtitle">
              <b>São vantagens e serviços sem burocracia</b> e a apenas alguns
              cliques de distância.
            </p>
            <p className="subtitle">
              <b>Olha só:</b>
            </p>
            <ul>
              <li>Empréstimos facilitados,</li>
              <li>Rendimento diário do saldo,</li>
              <li>Sorteio de 20 prêmios de R$ 500,00 todos os meses,</li>
              <li>App super fácil de usar,</li>
              <li>Muito mais.</li>
            </ul>
          </div>
        </div>
      </div>
    </S.ThirdBanner>
  );
};

export default ThirdBanner;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import { Container, Image } from './styles';

const RedirectWhatsApp = () => {
  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const utm_term = queryParams.get('utm_term');
    const typeTerm = _.split(utm_term, '-', 1);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:whatsapp-negociacao',
      eventAction: 'redirect:whatsapp-cobranca',
      eventLabel: utm_term,
    });

    if (
      _.first(typeTerm) === 'ept' ||
      _.first(typeTerm) === 'tp' ||
      _.first(typeTerm) === 'bannercobtp'
    )
      return (window.location.href =
        'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1!%20Quero%20renegociar%20minha%20d%C3%ADvida%20de%20empr%C3%A9stimo.');

    if (_.first(typeTerm) === 'cep' || _.first(typeTerm) === 'cc')
      return (window.location.href =
        'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1!+Quero+negociar+minha+d%C3%ADvida.&app_absent=0?utm_source=MiAjuda&utm_medium=Website&utm_campaign=quitacao-de-divida&utm_term=Miajuda&utm_content=Home');

    if (_.first(typeTerm) === 'ts' || _.first(typeTerm) === 'ag')
      return (window.location.href =
        'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1%21+Quero+renegociar+a+minha+divida+de+cart%C3%A3o.&type=phone_number&app_absent=0');

    if (utm_term === 'EG1')
      return (window.location.href =
        'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1%21+Estava+no+portal+negocia%C3%A7%C3%B5es+mi+ajuda+e+gostaria+de+atendimento.&type=phone_number&app_absent=0');

    if (utm_term === 'EP1')
      return (window.location.href =
        'https://api.whatsapp.com/send?phone=551130030950&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20contrata%C3%A7%C3%A3o%20de%20empr%C3%A9stimo');

    if (utm_term === 'LojasSIPFBN')
      return (window.location.href =
        'https://api.whatsapp.com/send?phone=551130030950&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20contrata%C3%A7%C3%A3o%20de%20empr%C3%A9stimo');

    return (window.location.href =
      'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1!+Quero+negociar+minha+d%C3%ADvida.&app_absent=0');
  }, [search]);

  return (
    <Container>
      <Image />
    </Container>
  );
};

export default RedirectWhatsApp;

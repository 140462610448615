/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import history from '~/services/history';
import * as S from './styles';

export const CartoonistLoanHeader = () => {
  return (
    <S.ContentHeader>
      <div className="ahl-content-header">
        <h2
          className="is-primary-default"
          onClick={() => history.push('/midway')}
        >
          {' Início >'}
        </h2>
        <h2
          className="is-primary-default"
          onClick={() => history.push('/produtos-financeiros')}
        >
          {' Produtos Financeiros > '}
        </h2>
        <h2>{' Empréstimo '}</h2>
      </div>
    </S.ContentHeader>
  );
};

import styled from 'styled-components';

export const FooterButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 400px) {
    flex-direction: column;
  }

  div.ahl-button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 400px) {
      width: 70%;
    }

    &:last-child {
      justify-content: flex-start;
      margin-left: 10px;

      @media (max-width: 400px) {
        justify-content: center;

        order: 1;
      }
    }

    &:first-child {
      justify-content: flex-end;

      @media (max-width: 400px) {
        justify-content: center;

        margin-top: 8px;

        order: 2;
      }
    }
  }
`;

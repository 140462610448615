import styled from 'styled-components';

import { colors } from '~/constants';

const getTextAlignByPosition = ({ position }) =>
  position === 'right' ? 'right' : 'left';

export const Step = styled.span`
  background: ${colors.white};
  width: 100%;
  height: 100%;
  color: ${colors.orangeRoof};
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  overflow: hidden;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${colors.chumbo};
  font-weight: 500;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: ${getTextAlignByPosition};

  @media (max-width: 1170px) {
    text-align: left;
  }

  @media (max-width: 720px) {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px;
  }
`;

export const Text = styled.p`
  color: ${colors.chumbo};
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 20px !important;
  text-align: ${getTextAlignByPosition};

  @media (max-width: 1170px) {
    text-align: left;
  }
`;

import React, { useEffect, useMemo } from 'react';
import { Button } from '@midway/web-ui-component';
import { useSelector } from 'react-redux';

import format from '~/services/format';
import useSimulateInstallment from '~/hooks/useSimulateInstallment';
import useGetInstallmentAvailability from '~/hooks/useGetInstallmentAvailability';
import { trackingSelectContent, trackingException } from '~/analytics';
import {
  flow,
  contentPage,
  contentType,
  description as analyticsDescription,
} from '~/analytics/cards';
import { PAYMENT_METHOD } from '~/constants/installment';
import { useAuth } from '~/context/auth';

import { Loading } from './components/Loading';
import { UnavailableInstallmentView } from './components/UnavailableInstallmentView';
import { InstallmentErrorView } from './components/InstallmentErrorView';
import {
  Box,
  Label,
  Value,
  Divider,
  InstallmentDescriptionText,
  TaxText,
  FirstInstallmentDateText,
  Bold,
  BottomContainer,
} from './styles';

const InvoiceInstallmentDetails = ({
  selected,
  onConfirmSuggestedInstallment,
  onSimulateOtherOptions,
  onAccessActiveInstallment,
}) => {
  const { maskedPan, nextDueDate } = useAuth();
  const {
    toogles: {
      isEnabledInvoiceInstallmentPixPaymentMethod: isPaymentByPixEnabled,
    },
  } = useSelector(state => state.featuretoggle);
  const [
    getAvailability,
    {
      loading: availabilityLoading,
      error: availabilityError,
      data: availabilityData,
    },
  ] = useGetInstallmentAvailability();
  const [
    getInstallments,
    { loading: optionsLoading, error: optionsError, data: optionsData },
  ] = useSimulateInstallment();

  const handleConfirmSuggestedInstallment = () => {
    trackingSelectContent(
      contentType.confirmInstallment,
      flow.payments,
      contentPage.invoiceDetails,
      {
        valor_de_entrada: optionsData?.entryValue,
        numero_de_parcelas: recommendedOption?.amountOf,
        valor_das_parcelas: recommendedOption?.value,
        valor_do_pagamento: optionsData?.totalValue,
      }
    );

    const { entryValueMin, entryValueMax } = availabilityData;
    const isSiccCard = maskedPan.includes('X');
    const canPayByPIX = isPaymentByPixEnabled && isSiccCard;
    const paymentMethod = canPayByPIX
      ? PAYMENT_METHOD.PIX
      : PAYMENT_METHOD.BILLET;

    onConfirmSuggestedInstallment({
      installmentOffer: {
        idInstallment: recommendedOption?.idInstallment,
        entryValue: optionsData?.entryValue,
        installmentDescription: recommendedOption?.description,
        simulationId: optionsData?.simulationId,
        paymentMethod,
      },
      entryValueMin,
      entryValueMax,
    });
  };

  const handleSimulateOtherOptions = () => {
    trackingSelectContent(
      contentType.simulateOtherOptions,
      flow.payments,
      contentPage.invoiceDetails
    );

    const { entryValueMin, entryValueMax } = availabilityData;
    onSimulateOtherOptions({
      entryValueMin,
      entryValueMax,
    });
  };

  const handlePressRetryButton = retryOperation => {
    trackingSelectContent(
      contentType.retry,
      flow.payments,
      contentPage.invoiceDetails
    );
    retryOperation();
  };

  useEffect(() => {
    if (selected) {
      getAvailability();
    }
  }, [getAvailability, selected]);

  useEffect(() => {
    if (availabilityData?.canSimulateInstallment === false) {
      trackingException(
        analyticsDescription.installmentUnavailable,
        flow.payments
      );
    }

    if (availabilityData?.canSimulateInstallment) {
      getInstallments();
    }

    if (
      availabilityData?.canSimulateInstallment &&
      availabilityData.lastInstallment?.simulationId
    ) {
      const {
        lastInstallment: activeInstallment,
        entryValueMin,
        entryValueMax,
      } = availabilityData;
      onAccessActiveInstallment({
        activeInstallment,
        entryValueMin,
        entryValueMax,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilityData]);

  useEffect(() => {
    if (optionsError) {
      trackingException(analyticsDescription.getOptionsError, flow.payments);
    }
    if (availabilityError) {
      trackingException(
        analyticsDescription.getAvailabilityError,
        flow.payments
      );
    }
  }, [availabilityError, optionsError]);

  const recommendedOption = optionsData?.options[0];
  const loading = availabilityLoading || optionsLoading;

  const entryValue = useMemo(
    () => format.currency(optionsData?.entryValue),
    [optionsData]
  );
  const description = useMemo(
    () =>
      `${recommendedOption?.amountOf}x de ${format.currency(
        recommendedOption?.value
      )}`,
    [recommendedOption]
  );
  const taxPercentage = useMemo(
    () =>
      `Juros de ${format.percentage(recommendedOption?.taxPercentage)}% ao mês`,
    [recommendedOption]
  );

  if (availabilityData?.canSimulateInstallment === false) {
    return <UnavailableInstallmentView />;
  }

  if (availabilityError) {
    return (
      <InstallmentErrorView
        onPressRetryButton={() => handlePressRetryButton(getAvailability)}
      />
    );
  }

  if (optionsError) {
    return (
      <InstallmentErrorView
        onPressRetryButton={() => handlePressRetryButton(getInstallments)}
      />
    );
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Label>Entrada</Label>
          <Value>{entryValue}</Value>
          <Divider />
          <Label>Parcelamento</Label>
          <InstallmentDescriptionText>{description}</InstallmentDescriptionText>
          <TaxText>{taxPercentage}</TaxText>
          <FirstInstallmentDateText>
            Primeira parcela <Bold>{nextDueDate}</Bold>
          </FirstInstallmentDateText>
        </Box>
      )}
      <BottomContainer>
        <Button
          block
          disabled={loading}
          title="Contratar parcelamento"
          onClick={handleConfirmSuggestedInstallment}
        />
        <Button
          block
          outline
          disabled={loading}
          title="Simular outras opções"
          onClick={handleSimulateOtherOptions}
        />
      </BottomContainer>
    </>
  );
};

export default InvoiceInstallmentDetails;

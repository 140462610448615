import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f3f3f3;
`;

export const Header = styled.div`
  background-color: #006464;
  color: #f3f3f3;
  padding: 16px;
  -webkit-box-shadow: 0px -6px 32px 0px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px -6px 32px 0px rgba(0, 0, 0, 0.44);
  box-shadow: 0px -6px 32px 0px rgba(0, 0, 0, 0.44);

  h1 {
    color: #f3f3f3;
    font-weight: bold;
    font-size: 23px;
  }
  p {
    padding-top: 16px;
    font-size: 18px;
  }
`;

export const Body = styled.div`
  padding: 0 16px;

  p {
    text-align: center;
    padding: 10px 0;
  }
`;

export const List = styled.ul`
  padding: 26px 0;
`;

export const Card = styled.li`
  position: relative;
  background-color: #ffffff;
  border: 3px solid #22c9c3;
  border-top-width: 10px;
  border-radius: 10px;
  padding: 16px;
  margin: 0 16px;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.14);

  h2 {
    color: #22c9c3;
    font-weight: bold;
    font-size: 18px;
    border-bottom: 2px solid #dddddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  ul {
    padding-left: 18px;

    li {
      color: #666;
      margin-right: 5px;
      margin-bottom: 18px;

      &::marker {
        content: '✔ ';
        color: #777;
        font-size: 1.2em;
      }
    }
  }

  h4 {
    color: #3c8484;
    font-size: 14px !important;
    font-weight: bold;
    text-align: center;
    padding: 6px 0;
  }

  p {
    font-size: 14px !important;
    font-weight: bold;
    text-align: center;
  }
  p.parcelas {
    font-size: 14px !important;
    text-align: center;
    font-weight: normal;
    padding-bottom: 10px;
    strong {
      font-weight: bold;
    }
  }

  h3 {
    color: #47b37d;
    font-weight: bold;
    font-size: 30px !important;
    text-align: center;
    span {
      font-size: 20px !important;
    }
  }

  span.check {
    background: #006464;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50%;
    position: absolute;
    left: 47%;
  }
`;

export const Tag = styled.div`
  background: #ffda79;
  position: absolute;
  font-size: 9px;
  font-weight: bold;
  color: #5d8159;
  padding: 10px 20px;
  top: -25px;
  left: 28%;
`;

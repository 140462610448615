import styled from 'styled-components';
import Background from '~/assets/img/monthly-winners/background_1.png';
import BackgroundMobile from '~/assets/img/monthly-winners/background_2.png';

const instructionsPaddingLeft = '20px';
const instructionsPaddingLeftMobile = '10px';

export const Container = styled.div`
  background-color: '#FFF';
  b {
    font-weight: bold;
  }

  @media (max-width: 992px) {
    .m-breadcrumb-container {
      display: none;
    }
  }

  @media (max-width: 576px) {
    .content-container {
      margin-top: 400px;
    }
  }

  @media (max-width: 375px) {
    .content-container {
      margin-top: 200px;
    }
  }
`;

export const ImageContainer = styled.div`
  min-height: 400px;
  max-height: 400px;
  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }
  img {
    min-height: 400px;
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      min-height: 300px;
      max-height: 300px;
    }

    @media (max-width: 576px) {
      max-height: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }
`;

export const Section = styled.section`
  padding-top: 60px;
  padding-bottom: 10px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFFFFF')};
  @media (max-width: 992px) {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
  }
`;

export const Title = styled.h1`
  padding-top: 20px;
  font-size: 36px;
  font-weight: bold;
  @media (max-width: 992px) {
    white-space: pre-wrap;
    text-align: center;
    font-size: 30px;
  }
`;

export const TitleSearch = styled.p`
  padding-top: 8px;
  font-size: 20px;
  margin-bottom: 20px;
  @media (max-width: 992px) {
    white-space: pre-wrap;
    text-align: center;
    font-size: 17px;

    margin-bottom: 40px;
  }
`;

export const Subtitle = styled.p`
  @media (max-width: 992px) {
    white-space: pre-wrap;
    text-align: center;
    font-size: 13px;
    margin-bottom: 50px;
  }
`;

export const InstructionsTitle = styled.div`
  border-radius: 8px;
  width: 100%;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : '#f2f2f2'};
  padding: 10px 10px 10px ${instructionsPaddingLeft};
  font-weight: 600;
  margin-bottom: 30px;
  color: ${props => props.color || '#252525'};

  @media (max-width: 992px) {
    padding: 10px 10px 10px ${instructionsPaddingLeftMobile};
  }
`;

export const Instructions = styled.div`
  margin-bottom: 50px;

  p,
  li {
    font-size: 14px;
    padding-left: ${instructionsPaddingLeft};
    margin-bottom: 20px;
    color: #707070;

    a {
      color: #006cff;
      font-weight: bold;
    }
  }

  @media (max-width: 992px) {
    p,
    li {
      padding-left: ${instructionsPaddingLeftMobile};
      white-space: pre-wrap;
    }
  }
`;

export const TitleErrorMessage = styled.p`
  padding-top: 8px;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  width: 600px;
  @media (max-width: 992px) {
    white-space: pre-wrap;
    text-align: center;
    font-size: 17px;
    width: 300px;
    margin-bottom: 40px;
  }
`;

export const Text = styled.p`
  margin: 10px 0px;
`;

export const TextUpperCase = styled.p`
  margin: 10px 0px;
  color: rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
`;

export const BannerWithTextContainer = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 136px;

  span {
    color: #006464;
    font-size: 30px;
  }

  svg {
    align-self: center;
  }

  @media (max-width: 992px) {
    margin: 0 auto;
    background-image: url(${BackgroundMobile});
    background-position: center;
    background-size: cover;
    height: 387px;
    border-radius: 10px;

    margin-left: 15px;
    margin-right: 15px;

    .icon-text-container {
      flex-direction: column;
    }

    .icon-container {
      flex: 1;
      height: auto !important;
      justify-content: center !important;
    }

    .text-container {
      height: auto !important;
      flex: 1;
      text-align: center;
      align-items: center !important;
      justify-content: center !important;
    }

    span {
      font-size: 22px;
    }

    svg {
      align-self: flex-end;
    }
  }
`;

export const ErrorText = styled.p`
  padding-top: 20px;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  @media (max-width: 992px) {
    white-space: pre-wrap;
    text-align: center;
    font-size: 30px;
  }
`;

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(200px, 250px));
    justify-content: center;
  }

  .row-border {
    border-top: 2px solid gray;
    grid-column: 1 / 5; /* this code makes the row stretch to entire width of the container */
  }
`;

export const CardCircleMonthlyWinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  background-color: ${props =>
    props.theme === 'gray' ? '#f7f7f7' : '#FDFDFD'};
  margin-top: 150px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.4);

  .lucky-number-container {
    font-size: 14px;
    height: 20px;
  }
`;

export const IconContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #00efe7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -50px;
  margin-bottom: 35px;
  -webkit-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.4);
`;

export const CardTitle = styled.p`
  text-align: center;
  color: #006464;
  font-weight: bold;
  font-size: 21px !important;
  min-height: 92px;
  margin-left: 10px;
  margin-right: 10px;
`;

export const LuckyNumber = styled.span`
  text-align: center;
  font-weight: bold;
`;

export const PersonContainer = styled.div`
  background-color: ${props =>
    props.theme === 'gray' ? '#FDFDFD' : '#f7f7f7'};
  margin: 20px 10px 20px 10px;
  border-radius: 5px;
  border: ${props => (props.theme === 'gray' ? '2px solid #d1d1d1' : '')};
  min-height: 82px;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Name = styled.p`
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
`;

export const Location = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: 12px;
`;

export const LastMonthsContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media (max-width: 992px) {
    align-items: center;
  }
`;

export const LastMonthsTitle = styled.p`
  padding-top: 20px;
  font-size: 36px;
  font-weight: bold;
  @media (max-width: 992px) {
    white-space: pre-wrap;
    text-align: center;
    font-size: 30px;
  }
`;

export const LastMonthsSubtitle = styled.p`
  display: none;
  @media (max-width: 992px) {
    display: block;
    font-size: 14px;
  }
`;

export const MonthContainer = styled.div`
  background-color: ${props => (props.actualMonth ? '#006464;' : '#FFFFFF')};
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #d1d1d1;
  max-width: 200px;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  .month-name {
    font-weight: bold;
    font-size: 18px;
    color: ${props => (props.actualMonth ? '#FFFFFF;' : '#006464;')};
  }

  .winner-number {
    font-size: 18px;
    color: ${props => (props.actualMonth ? '#FFFFFF;' : '#252525;')};
  }

  @media (max-width: 992px) {
    max-width: 300px;
    width: 100%;

    span {
      text-align: center !important;
    }
  }
`;

export const MonthsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  grid-template-rows: repeat(auto-fit, 1fr);
  width: 100%;
  grid-row-gap: 20px;

  @media (max-width: 992px) {
    place-content: center;
  }
`;

export const MonthsSearch = styled.div`
  display: flex;
  margin-top: 50px;
  @media (max-width: 992px) {
    place-content: center;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const ErrorContent = styled.div`
  display: block;
  margin: 0 auto;
`;
export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: block;
  margin: 0 auto;
`;
export const dividerSearch = styled.div`
  width: 200px;
  margin-top: 30px;
  margin-right: 20px;
  @media (max-width: 992px) {
    margin-right: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BarSpace = styled.div`
  display: flex;
  width: 5px;
  height: 300px;
  background-color: '#d1d1d1';
  @media (max-width: 992px) {
    display: none;
  }
`;

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '0px',
    color: state.isSelected ? '#FFF' : '#252525',
  }),

  theme: _ => ({
    colors: 'red',
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 900px) {
    column-gap: 16px;
  }

  @media screen and (min-width: 1200px) {
    column-gap: 104px;
  }
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 12px;
  align-items: center;
  margin-bottom: -120px;
  justify-items: center;
  justify-content: space-between;
  flex: 1 1 100%;

  @media screen and (min-width: 480px) {
    margin-bottom: -170px;
    grid-gap: 16px;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: -210px;
  }
  @media screen and (min-width: 900px) {
    flex: 1 1 calc(50% - 10px);
    margin-bottom: -240px;
  }
  @media screen and (min-width: 1200px) {
    flex: 1 1 calc(50% - 52px);
  }
`;

export const LogoWrapper = styled.button`
  background-color: #f7f7f7;
  border-radius: 50%;
  border: 1.5px ${props => (props.active ? '#e4602f' : '#f7f7f7')} solid;
  max-width: 124px;
  max-height: 124px;
  transition: all ease-in-out 0.2s;

  @media screen and (min-width: 768px) {
    border: 3px ${props => (props.active ? '#e4602f' : '#f7f7f7')} solid;
  }
`;

export const LogoImage = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const ContentContainer = styled.div`
  flex: 1 1 100%;
  padding: 24px;
  background-color: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (min-width: 900px) {
    flex: 1 1 calc(50% - 9px);
  }
  @media screen and (min-width: 1200px) {
    flex: 1 1 calc(50% - 52px);
  }
`;

export const NavigateButton = styled.button`
  background-color: transparent;
  border-radius: 50px;
  border: 2px #221f20 solid;
  padding: 8.5px 14px 7.5px 14px;
  font-weight: 500;
  font-size: 0.9rem;
  margin-top: auto;

  svg {
    stroke: #221f20;
    margin-left: 8px;
    border: 2px #221f20 solid;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    stroke-width: 3px;
    padding: 1px 1px 1px 2px;
  }
`;

export const Title = styled.h3`
  font-size: 1.25rem !important;
  font-weight: 600;
  color: #e4602f;
  padding-bottom: 16px;
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #221f20;
  padding-bottom: 16px;
  white-space: break-spaces;

  b {
    font-weight: 500;
  }
`;

export const Rule = styled.p`
  font-size: 0.85rem;
  line-height: 1.25;
  color: #3b4443;
  padding-bottom: 8px;

  &:last-of-type {
    padding-bottom: 16px;
  }
`;

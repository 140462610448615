import moment from 'moment';

// Conta os dias faltantes a partir de uma data inicial x data final
export const daysCount = (dateStart, dateEnd) => {
  if (dateEnd === undefined || dateEnd === '' || dateEnd === null) return null;

  const days =
    moment(moment.utc(dateEnd).toDate()).diff(
      moment.utc(dateStart).toDate(),
      'days'
    ) + 1;

  return days;
};

export const slugify = text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

// verifica se um CPF é válido
export const validarCPF = cpf => {
  let add;
  let i;
  let rev;
  if (cpf !== undefined) {
    // tira quaisquer resquícios de caracter especial
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length === 11) {
      if (cpf === '') return false;
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length !== 11 ||
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
      )
        return false;
      add = 0;
      for (i = 0; i < 9; i++) add += parseInt(cpf.charAt(i), 10) * (10 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(9), 10)) return false;
      add = 0;
      for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i), 10) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(10), 10)) return false;

      return true;
    }
  }
  return false;
};

export const formatterCurrency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const validateEmailFormat = email => {
  const regularExpressForValidateEmail =
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regularExpressForValidateEmail.test(email);
};

export const getPaymentDate = paymentDate => {
  let date = moment(paymentDate, 'YYYY-MM-DD');
  if (!date) {
    date = moment().add(1, 'days');
  }

  return date.format('DD/MM');
};

export const validarDataNascimento = birthDate => {
  const isValid = moment(birthDate, 'DD/MM/YYYY', true).isValid();
  const birthday = moment(birthDate, 'DD/MM/YYYY').format('YYYYMMDD');
  const now = moment().format('YYYYMMDD');
  const idade = moment().diff(moment(birthDate, 'DD/MM/YYYY'), 'years');

  if (isValid && birthday < now && idade >= 18) return true;

  return false;
};

export const validateDueDate = (dueDate, minDays, maxDays) => {
  const isValid = moment(dueDate, 'DD/MM/YYYY', true).isValid();
  const minDate = moment().add(minDays, 'days').format('YYYYMMDD');
  const maxDate = moment().add(maxDays, 'days').format('YYYYMMDD');
  const currentDate = moment(dueDate, 'DD/MM/YYYY').format('YYYYMMDD');

  if (isValid && currentDate >= minDate && currentDate <= maxDate) return true;

  return false;
};

export const convertStringTagbook = input => {
  let result = input.toLowerCase();

  let questionMark = '';
  if (result.endsWith('?')) {
    questionMark = '?';
    result = result.slice(0, -1);
  }
  result = result.replace(/ /g, '-');

  result += questionMark;

  return result;
};

export const isValidAnsName = name => {
  if (name) {
    const splitedName = name.trim().split(/\s+/g);
    const onlyExpectedLetters = new RegExp(
      /^[A-Za-záàéèíïóöúçÁÀÉÈÍÏÓÖÚÇ'\s]+$/
    );
    const validNameWithOneLetter = new RegExp(/^[DIÍOÓUÚY]/);
    const validLastNameWithOneLetter = new RegExp(/^[IÍOÓUÚY]/);
    const repeatedSequence = new RegExp(/(.)\1{2}/);
    const firstName = splitedName[0];
    const lastName = splitedName[splitedName.length - 1];

    if (!onlyExpectedLetters.test(name)) {
      return {
        erro: 'Não são permitidos números ou caracteres especiais',
        valid: false,
      };
    }
    if (splitedName.length === 1) {
      return {
        erro: 'Necessário informar ao menos um sobrenome',
        valid: false,
      };
    }
    if (
      splitedName.some(
        splitName => repeatedSequence.test(splitName?.toLowerCase()) === true
      )
    ) {
      return {
        erro: 'Não é permitido utilizar sequências de caracteres repetidos',
        valid: false,
      };
    }

    if (firstName.length === 1 && !validNameWithOneLetter.test(firstName)) {
      return {
        erro: 'Primeiro nome com apenas uma letra só é permitido caso seja: D, I, O, U, Y',
        valid: false,
      };
    }

    if (lastName.length === 1 && !validLastNameWithOneLetter.test(lastName)) {
      return {
        erro: 'Último nome com apenas uma letra só é permitido caso seja:  I, O, U, Y',
        valid: false,
      };
    }

    return name;
  }
  return { erro: 'Preenchimento de campo obrigatório', valid: false };
};

/**
 * Checa se o horário atual está entre um período específico GMT-3 (hora de brasília/sp)
 * @return {boolean}
 * @param initialTime {string} hora no formato HH:mm:ss
 * @param finalTime {string} hora no formato HH:mm:ss
 */
export const isNowTimeBetween = (initialTime, finalTime) => {
  const timeToUTC = timeStr => {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    const date = new Date();
    date.setUTCHours(hours + 3); // Converte GMT-3 pra UTC
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);
    return date;
  };

  const initialUTCDate = timeToUTC(initialTime);
  const finalUTCDate = timeToUTC(finalTime);
  const nowUTCDate = new Date();

  return initialUTCDate <= nowUTCDate && nowUTCDate <= finalUTCDate;
};

// eslint-disable-next-line no-console
const dataLayer = window.dataLayer || [];
const isDevEnvironment = process.env.NODE_ENV === 'development';

/**
 * Parâmetros devem seguir a configuração estipulada no Tagbook GA4
 * @param {string} content_page Nome da tela
 * @param {string} flow Módulo, ou fluxo
 * @param {object} [params] Demais parâmetros, nomeados conforme Tagbook
 *
 * @example
 * gtagViewContent('selecione-o-contrato-que-deseja-negociar', 'miajuda', { proposta: 'unica' });
 */
const gtagViewContent = (content_page, flow, params = {}) => {
  const payload = {
    event: 'view_content',
    flow,
    content_page,
    ...params,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};

/**
 * Parâmetros devem seguir a configuração estipulada no Tagbook GA4
 * @param {string} content_page Nome da tela
 * @param {string} flow Módulo, ou fluxo
 * @param {string} content_type Identificação do botão, link, etc
 * @param {object} [params] Demais parâmetros, nomeados conforme Tagbook
 *
 * @example
 * gtagSelectContent('selecione-o-contrato-que-deseja-negociar', 'miajuda', 'voltar', { proposta: 'unica' });
 */
const gtagSelectContent = (content_page, flow, content_type, params = {}) => {
  const payload = {
    event: 'select_content',
    content_type,
    content_page,
    flow,
    ...params,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};

/**
 * Parâmetros devem seguir a configuração estipulada no Tagbook GA4
 * @param {string} content_page Nome da tela
 * @param {string} flow Módulo, ou fluxo
 * @param {string} description Descrição do erro
 *
 * @example
 * gtagException('visualizacao-de-proposta', 'miajuda', 'valor da parcela maior que o permitido');
 */
const gtagException = (content_page, flow, description) => {
  const payload = {
    event: 'exception',
    content_page,
    flow,
    description,
  };
  if (isDevEnvironment) console.log(payload);
  dataLayer.push(payload);
};

export { gtagViewContent, gtagSelectContent, gtagException };

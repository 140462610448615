import React from 'react';
import { Breadcrumb } from '@midway/web-ui-component';
import AccessibleLink from '~/components/AccessibleLink';
import history from '~/services/history';
import { Container, MidwayContent } from './styles';

const OmbudsmanReports = () => {
  return (
    <>
      <Container
        id="ready-page-for-screen-reader"
        aria-label="Página de Cartão da Loja Riachuelo carregada"
      >
        <Breadcrumb
          routeBack="/midway"
          textRouteBack="Nossa História"
          actualPageText="Relatórios ouvidoria"
          history={history}
        />
        <MidwayContent className="container">
          <h1>Relatórios ouvidoria</h1>
          <p>
            Disponibilizamos aqui nossos Relatórios de Atividades da Ouvidoria
          </p>
          <h2>Publicações Semestrais</h2>
          <ul>
            <li>
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 1º semestre/2023"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2023"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 2º semestre/2022"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2022"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 1º semestre/2022"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2022"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 2º semestre/2021"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2021"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 1º semestre/2021"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2021"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 2º semestre/2020"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2020_vs2"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 1º semestre/2020"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2020"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 2º semestre/2019"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2019"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 1º semestre/2019"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2019"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 2º semestre/2018"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2018"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 1º semestre/2018"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2018"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 2º semestre/2017"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2017"
              />
              <AccessibleLink
                hasUnderline={false}
                text="Relatório ouvidoria 1º semestre/2017"
                route="/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2017"
              />
            </li>
          </ul>
        </MidwayContent>
      </Container>
    </>
  );
};

export default OmbudsmanReports;

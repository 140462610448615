export const mockData = [
  {
    id: 1,
    children: [
      {
        description:
          '1- Saque em terminal de autoatendimento além do número de saques permitidos gratuitamente por mês. Nas contas de depósitos cujos contratos prevejam utilizar exclusivamente meios eletrônicos (“contas eletrônicas”) não pode ser cobrada tarifa para este canal de entrega.',
      },
      {
        description:
          '2- Saque na Loja Riachuelo - Saque 24horas - Saque em empresa que atua como correspondente no País de instituição financeira.',
      },
      {
        description:
          '3- Depósito com registro de informações necessárias à identificação, a qualquer tempo, da operação e/ou do depositante, por este solicitado.',
      },
      {
        description:
          '4- Extrato 24hrs - Fornecimento de extrato com a movimentação dos últimos trinta dias em terminal de autoatendimento ou por outras formas de atendimento eletrônico automatizado, sem intervenção humana, além do número permitido gratuitamente por mês. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
      },
      {
        description:
          '5 - Extrato no APP - Fornecimento de extrato com a movimentação de um período em terminal de autoatendimento ou por outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
      },
      {
        description:
          '6- TED (Transferência por meio TED): Realização de transferência de recursos por meio de TED em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
      },
      {
        description:
          '7-Transferência entre contas na própria instituição: Realização de transferência de recursos entre contas na própria instituição em guichê de caixa ou mediante outras formas de atendimento pessoal, tais como atendimento telefônico realizado por atendente, além do número permitido gratuitamente por mês. Nas “contas eletrônicas” não há gratuidade para este canal de entrega.',
      },
      {
        description:
          '8 - Anuidade cartão básico - (internacional):  AnuidadeDisponibilização de rede de estabelecimentos afiliados, instalada no País e no exterior, para pagamentos de bens e serviços, cobrada no máximo uma vez a cada doze meses, admitido o parcelamento da cobrança.',
      },
      {
        description:
          '9- Fornecimento de 2ª via de cartão com função débito: Confecção e emissão de novo cartão com função débito, restrito a casos de pedidos de reposição formulados pelo detentor da conta, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis à instituição emitente.',
      },
      {
        description:
          '10- Fornecimento de 2ª via de cartão com função crédito: Confecção e emissão de novo cartão com função crédito, restrito a casos de pedidos de reposição formulados pelo detentor do cartão, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis à instituição emitente.',
      },
      {
        description:
          '11-Utilização de canais de atendimento para retirada em espécie no país: Disponibilização e utilização pelo cliente de canais de atendimento disponíveis no País para retirada em espécie na função crédito.',
      },
      {
        description:
          '12-Utilização de canais de atendimento para retirada em espécie no exterior.',
      },
      {
        description:
          '13-Pagamento de contas utilizando a função crédito: Disponibilização e utilização pelo cliente de canais de atendimento disponíveis no exterior para retirada em espécie na função crédito ou débito.',
      },
      {
        description:
          '14-Avaliação emergencial de crédito: Avaliação de viabilidade e de riscos para a concessão de crédito em caráter emergencial, a pedido do cliente, por meio de atendimento pessoal, para realização de despesa acima do limite do cartão, cobrada no máximo uma vez nos últimos trinta dias.',
      },
    ],
  },
];

import styled from 'styled-components';

export const Forms = styled.div`
  display: flex;
  flex-direction: inherit;
  align-items: center;
  height: 340px;
  justify-content: center;

  @media (max-width: 768px) {
    height: fit-content;
  }

  h1 {
    font-weight: bold;
    color: #000000;
    font-size: 32px !important;
    @media (max-width: 767px) {
      align-self: baseline;
      margin: 0;
      font-size: 24px !important;
    }
  }

  h2 {
    font-weight: bold;
    color: #000000;
    @media (max-width: 767px) {
      align-self: baseline;
      margin: 0;
      font-size: 24px !important;
    }
  }

  h2:nth-child(2) {
    margin-bottom: 16px;
  }

  p {
    color: #606060;
    font-size: 20px;
    @media (max-width: 767px) {
      align-self: baseline;
      margin: 0;
      font-size: initial;
    }
  }
  button {
    width: 26%;
    margin-top: 100px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 40px;
    }
  }

  img {
    height: 60%;
    margin-top: 40px;
  }
`;

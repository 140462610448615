import React, { useState } from 'react';
import {
  IconBarcode,
  IconCoin,
  IconMessageCircle2,
  IconPhone,
  IconTicket,
} from '@tabler/icons-react';
import Card from '~/components/NewUi/Card';
import Typography from '~/components/NewUi/Typography';
import Container from '~/components/NewUi/Container';
import { trackingSelectContent } from '~/analytics';
import CardLink from './CardLink';

import * as S from './style';

const QuickAccess = () => {
  const [maxHeight, setMaxHeight] = useState(80);

  return (
    <S.Section maxHeight={maxHeight}>
      <Container>
        <S.SectionHeader>
          <Typography variant="h2" styleType="title">
            Acesso rápido
          </Typography>
        </S.SectionHeader>
        <S.SectionBody>
          <Card
            onClick={() =>
              trackingSelectContent('acesso-rapido:pagar-cartao-midway', 'home')
            }
            to="/consulta-boleto"
          >
            <CardLink
              maxHeight={maxHeight}
              setMaxHeight={setMaxHeight}
              title={`Pagar Cartão \nRiachuelo`}
              icon={<IconBarcode />}
            />
          </Card>
          <Card
            onClick={() =>
              trackingSelectContent('acesso-rapido:pagar-emprestimo', 'home')
            }
            to="/consulta-boleto"
          >
            <CardLink
              maxHeight={maxHeight}
              setMaxHeight={setMaxHeight}
              title={`Pagar \nEmpréstimo`}
              icon={<IconCoin />}
            />
          </Card>
          <Card
            onClick={() =>
              trackingSelectContent('acesso-rapido:negociar-divida', 'home')
            }
            to="/campanha-quitacao?utm_source=MiAjuda&utm_medium=Header&utm_campaign=quitacao-de-divida&utm_term=Header&utm_content=midwayheader"
          >
            <CardLink
              maxHeight={maxHeight}
              setMaxHeight={setMaxHeight}
              title={`Negociar \ndívida`}
              icon={<IconMessageCircle2 />}
            />
          </Card>
          <Card
            onClick={() =>
              trackingSelectContent(
                'acesso-rapido:portal-de-atendimento',
                'home'
              )
            }
            to="atendimento"
          >
            <CardLink
              maxHeight={maxHeight}
              setMaxHeight={setMaxHeight}
              title={`Portal de \natendimento`}
              icon={<IconPhone />}
            />
          </Card>
          <Card
            onClick={() =>
              trackingSelectContent(
                'acesso-rapido:beneficios-do-cartao',
                'home'
              )
            }
            to="beneficios"
          >
            <CardLink
              maxHeight={maxHeight}
              setMaxHeight={setMaxHeight}
              title={`Benefícios \ndo cartão`}
              icon={<IconTicket />}
            />
          </Card>
        </S.SectionBody>
      </Container>
    </S.Section>
  );
};

export default QuickAccess;

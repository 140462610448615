import styled from 'styled-components';
import { colors } from '~/constants';

export const IconContainer = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateX(-16px);
  transition: 0.3s ease-in-out;

  :hover {
    background-color: ${colors.light_gray};
    cursor: pointer;
  }
`;

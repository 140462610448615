import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@midway/web-ui-component';

import * as Styles from './styles';

const BackButton = ({ onGoBack }) => {
  return (
    <Styles.IconContainer
      onClick={onGoBack}
      aria-label="voltar"
      data-testid="goback-button"
    >
      <Icon icon="chevron-left" />
    </Styles.IconContainer>
  );
};

BackButton.propTypes = {
  onGoBack: PropTypes.func,
};

BackButton.defaultProps = {
  onGoBack: () => {},
};

export default React.memo(BackButton);

import { takeLatest, put, all } from 'redux-saga/effects';
import {
  SHOW_DRAWER,
  HIDE_DRAWER,
  SHOW_MODAL_PL,
  HIDE_MODAL_PL,
  SHOW_MODAL_CREDIT,
  HIDE_MODAL_CREDIT,
} from './patterns';
import {
  showDrawer,
  hideDrawer,
  showModalPL,
  hideModalPL,
  showModalCredit,
  hideModalCredit,
} from './actions';

const show = () => {
  put(showDrawer());
};

const hide = () => {
  put(hideDrawer());
};

const showPL = () => {
  put(showModalPL());
};

const hidePL = () => {
  put(hideModalPL());
};

const showCredit = () => {
  put(showModalCredit());
};

const hideCredit = () => {
  put(hideModalCredit());
};

export default all([
  takeLatest(SHOW_DRAWER, show),
  takeLatest(HIDE_DRAWER, hide),
  takeLatest(SHOW_MODAL_PL, showPL),
  takeLatest(HIDE_MODAL_PL, hidePL),
  takeLatest(SHOW_MODAL_CREDIT, showCredit),
  takeLatest(HIDE_MODAL_CREDIT, hideCredit),
]);

import styled from 'styled-components';

export const Container = styled.footer`
  height: 297px;
  width: 100%;
  background-color: #f3f3f3;
`;

export const Content = styled.div`
  p {
    font-size: 40px;
    font-weight: bold;
    color: #252525;
    margin-top: 68px;
    margin-bottom: 35px;
  }
  span {
    font-size: 18px;
    color: #252525;

    u {
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;

export const HideMobile = styled.div`
  width: 100%;
  @media (max-width: 767px) {
    display: none;
  }
`;

import {
  CategoryContainer,
  CategoryTitle,
  CategoryTitleContainer,
  WrapperContacts,
} from './styles';
import React, { useEffect } from 'react';

import Accordion from '~/pages/AttendancePortal/components/Accordion';
import BackButton from '~/pages/AttendancePortal/components/BackButton';
import Contact from '~/pages/AttendancePortal/components/Contact';
import InnerHtml from '~/pages/AttendancePortal/components/InnerHtml';
import SubjectItem from '~/pages/AttendancePortal/components/SubjectItem';
import WizardTitle from '~/pages/AttendancePortal/components/WizardTitle';
import Wrapper from '~/pages/AttendancePortal/components/Wrapper';
import { trackUserEvent } from '~/pages/AttendancePortal/analytics';
import { useAttendancePortalState } from '~/pages/AttendancePortal/context/AttendancePortalContext';
import { useWizard } from 'react-use-wizard';

const Category = () => {
  const { previousStep, nextStep } = useWizard();
  const { category, setSubCategory } = useAttendancePortalState();
  const highlight = category.title.split(' ');

  useEffect(() => {
    const titleElement = document.getElementById('wizard-title');
    if (titleElement && window.innerWidth < 720) {
      titleElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleNextStep = subCategory => {
    trackUserEvent({
      contentType: `${subCategory.title}:${category.title}`,
    });
    setSubCategory(subCategory);
    nextStep();
  };

  return (
    <>
      <Wrapper>
        <CategoryContainer>
          <BackButton onClick={previousStep} />

          <WizardTitle
            id="wizard-title"
            role="title"
            title={`Agora, selecione um assunto relacionado a ${category.title}`}
            highlighted={['assunto', ...highlight]}
          />

          <Accordion
            isOpen
            headerContent={
              <CategoryTitleContainer>
                <InnerHtml raw={category.icon} />
                <CategoryTitle>{category.title}</CategoryTitle>
              </CategoryTitleContainer>
            }
            bodyContent={category.subCategories.list.map(
              (subCategory, index) => (
                <SubjectItem
                  key={index}
                  title={subCategory.title}
                  onClick={() => {
                    handleNextStep(subCategory);
                  }}
                />
              )
            )}
          />
        </CategoryContainer>
      </Wrapper>

      <WrapperContacts>
        <Contact />
      </WrapperContacts>
    </>
  );
};

export const MemoizedCategory = React.memo(Category);

import styled from 'styled-components';
import FirstBackground from '~/assets/img/Indications/bg.png';

export const FirstBanner = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url(${FirstBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;

  b {
    font-weight: bold;
  }

  .container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  .side {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  img {
    object-fit: contain;
  }

  .side {
    width: 60%;

    img {
      align-self: flex-start;
      width: 45%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;

      img {
        width: 100%;
      }

      a {
        max-width: 100%;
      }
    }
  }

  p.title {
    margin: 30px 0;
    font-size: 30px !important;
    padding: 0;
    color: #000000;
  }

  p.subtitle {
    margin: 0px;
    color: #000000;

    @media screen and (max-width: 480px) {
      padding-right: 0px;
      letter-spacing: 0px;
    }
  }

  a {
    background: #000000;
    padding: 15px 0px;
    border-radius: 50px;
    color: #47c8cd;
    font-size: 15px;
    max-width: 300px;
    text-align: center;
    margin-top: 40px;
    transition: all 0.2s ease-out;

    &:hover {
      text-decoration: none;
      color: #47c8cd;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  background: transparent linear-gradient(180deg, #c1fd95 0%, #46cbd0 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  min-height: 450px;
`;

export const Content = styled.div`
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  min-width: 60%;
  max-width: 80%;
  min-height: 100%;
  display: flex;
  align-self: center;
  flex-direction: column;

  position: relative;
  min-height: 350px;
  /* max-height: 400px !important; */
  padding: 25px 50px !important;
  border-radius: 20px;

  margin-bottom: 30px;

  @media (max-width: 767px) {
    max-height: 550px;
  }

  @media (max-width: 778px) {
    flex-direction: column;
  }

  div.ncl-title {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    font-weight: bold;
    font-size: 2rem;
    text-align: left;
    letter-spacing: 0px;
    color: #252525;
    opacity: 1;

    @media (max-width: 415px) {
      width: 90%;
    }
  }

  div.ncl-subTitle {
    width: 100%;
    padding: 5px;
    text-align: flex-start;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    letter-spacing: 0px;
    color: #606060;
    opacity: 1;
    font-size: 1.5rem;
  }

  div.ncl-text {
    width: 90%;
    color: #606060;
    text-align: flex-start;
    font-size: 1rem;
  }

  div.ncl-li {
    font: var(--unnamed-font-style-normal) normal normal 1rem /
      var(--unnamed-line-spacing-24) var(--unnamed-font-family-montserrat);
    width: 90%;
    font: normal normal normal 1rem Montserrat;
    color: #606060;
    opacity: 1;
  }

  div.ncl-app-images {
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding-top: 15px;
    span {
      font-weight: bold;
      font-size: 16px;
      text-align: start;
    }

    a {
      border-style: none;
    }

    &-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      img {
        width: 110px;
        height: 30px;
        padding-right: 15px;
        cursor: pointer;
      }
    }
  }

  div.ncl-compDiv {
    display: flex;
    flex-direction: row;
  }

  div.ncl-comp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-img-cartoes {
      /* width: 100%; */
      height: 100%;

      @media (max-width: 778px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }
  }
`;

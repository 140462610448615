// Typography.js
import React from 'react';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';

const styles = {
  title: css`
    font-size: 1%.8 !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media screen and (max-width: 768px) {
      font-size: 1.6rem !important;
    }

    @media screen and (max-width: 492px) {
      font-size: 1.28rem !important;
    }
  `,
  cardTitle: css`
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
  subtitle: css`
    font-weight: normal;
    color: #666;
  `,
  normal: css`
    font-weight: normal;
    color: #333;
  `,
};

const Typography = ({
  variant = 'span',
  strong,
  className,
  children,
  styleType = 'normal',
  fontSize,
  color,
  fontWeight,
  ...rest
}) => {
  const Tag = variant;

  return (
    <StyledTypography
      as={Tag}
      className={`TypograpyRoot ${className} ${styleType}`}
      fontSize={fontSize}
      color={color}
      fontWeight={fontWeight}
      {...rest}
      styleType={styleType}
      dangerouslySetInnerHTML={{
        __html: strong ? <strong>{children}</strong> : children,
      }}
    />
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'p', 'span']),
  styleType: PropTypes.oneOf(['title', 'subtitle', 'normal', 'cardTitle']),
  strong: PropTypes.bool,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.oneOf([
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ]),
};

const StyledTypography = styled.div`
  font-size: ${props => props.fontSize};
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight};
  ${props => styles[props.styleType] || ''}

  &.h1 {
    font-size: 2rem;
  }
  &.h2 {
    font-size: 1.75rem;
    @media screen and (max-width: 768px) {
      font-size: 1.5rem !important;
    }
    @media screen and (max-width: 492px) {
      font-size: 1.2rem !important;
    }
  }
  &.h3 {
    font-size: 1.5rem;
  }
  &.h4 {
    font-size: 1.25rem;
  }
  &.p {
    font-size: 1rem;
  }

  &[role='heading'][aria-level='1'] {
    font-size: 2rem;
  }
  &[role='heading'][aria-level='2'] {
    font-size: 1.75rem;
  }
  &[role='heading'][aria-level='3'] {
    font-size: 1.5rem;
  }
  &[role='heading'][aria-level='4'] {
    font-size: 1.25rem;
  }
`;

export default Typography;

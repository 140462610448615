import React, { useEffect, useRef, useCallback } from 'react';
import isNil from 'lodash/fp/isNil';

import closeButtom from '~/assets/img/call-to-action/mids-close.svg';

import modelo from '~/assets/img/lending/modelo-card.png';
import qrAndroid from '~/assets/img/lending/qr-midway.svg';
import * as S from './styles';

const ModalApp = ({ onCloseRequest }) => {
  const modal = useRef(null);

  const handleKeyUp = useCallback(
    e => {
      const keys = {
        27: () => {
          e.preventDefault();
          onCloseRequest();
          window.removeEventListener('keyup', handleKeyUp, false);
        },
      };

      if (keys[e.keyCode]) {
        keys[e.keyCode]();
      }
    },
    [onCloseRequest]
  );

  const handleOutsideClick = useCallback(
    e => {
      if (!isNil(modal)) {
        if (!modal.current.contains(e.target)) {
          onCloseRequest();
          document.removeEventListener('click', handleOutsideClick, false);
        }
      }
    },
    [onCloseRequest]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [handleKeyUp, handleOutsideClick]);

  return (
    <S.ModalOverlay onClose={onCloseRequest}>
      <S.Modal ref={modal}>
        <S.TextCLose onClick={onCloseRequest}>
          <img src={closeButtom} alt="fechar" />
        </S.TextCLose>

        <S.Container>
          <S.LeftImagePanel src={modelo} />
          <S.ContentText>
            <S.TextPanel>Baixe o app Midway!</S.TextPanel>
            <br />
            <br />
            <S.Text>
              Para realizar a contratação da antecipação do Saque-Aniversário
              FGTS baixe o app Midway e tenha mais vantagens e soluções
              financeiras.
            </S.Text>
            <br />
            <br />
            <S.Text>Leia o QR CODE com seu celular e baixe o app.</S.Text>
            <br />
            <br />
            <S.QRcode src={qrAndroid} />
          </S.ContentText>
        </S.Container>
      </S.Modal>
    </S.ModalOverlay>
  );
};

export default ModalApp;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & ~ & {
    margin-top: 16px;
  }
`;

export const Label = styled.p`
  font-size: 1rem;
  font-weight: bold;
`;

export const Value = styled.p`
  margin-left: 1rem;
`;

export const RoundButton = styled.button`
  border-radius: 50%;
  background-color: #dedede;
  border-width: 0;
  padding: 8px 10px;
  width: 100%;
  margin-right: 10px;

  &[disabled] {
    background-color: #f0f0f0;
    cursor: default;
  }
`;

export const InfoButton = styled.button`
  border: 1px solid #616b6a;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 0.7em;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

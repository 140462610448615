import React from 'react';
import PropTypes from 'prop-types';
import { trackingSelectContent } from '~/analytics';
import * as S from './style';
import Card from '../Card';
import Typography from '../Typography';

function formatStringToButtonStyle(input) {
  const formattedString = input.toLowerCase().replace(/\s+/g, '-');
  return `${formattedString}`;
}

const RenderButton = ({ link, actionText, action, title }) => {
  const handleAction = () => {
    if (action) return action;
    trackingSelectContent(
      `seguros-e-assistencias:${formatStringToButtonStyle(title)}`,
      `home`
    );
  };

  if (link) {
    return (
      <a href={link} tabIndex={0} aria-label={`${actionText}`}>
        <S.ActionButton
          tabIndex={-1}
          className="ActionButtonRoot"
          role="button"
          onClick={handleAction}
        >
          {actionText}
        </S.ActionButton>
      </a>
    );
  }

  return (
    <S.ActionButton
      tabIndex={0}
      role="button"
      aria-label={`${actionText}`}
      className="ActionButtonRoot"
      onClick={action}
    >
      {actionText}
    </S.ActionButton>
  );
};

const CardProduct = ({
  icon,
  title,
  description,
  actionText,
  action = null,
  link,
  ...rest
}) => {
  return (
    <Card {...rest}>
      <S.CardContent className="CardContentRoot">
        {icon && <S.IconContainer>{icon}</S.IconContainer>}
        <S.Title className="CardTitleContainerRoot">
          <Typography styleType="cardTitle" variant="h3">
            {title}
          </Typography>
        </S.Title>
        <S.Description
          className="CardContentTextRoot"
          arial-aria-label="descrição"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {action ||
          (link && (
            <RenderButton
              link={link}
              actionText={actionText}
              action={action}
              title={title}
            />
          ))}
      </S.CardContent>
    </Card>
  );
};

export default CardProduct;

CardProduct.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  action: PropTypes.func,
};

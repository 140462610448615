import React from 'react';
import * as S from './styles';

const CardsZurich = ({ title, icon, widthCard, href, onClick }) => {
  return (
    <S.ContainerCardWrapper>
      <S.CardWrapper minWidth={widthCard}>
        <S.CardAnchor href={href} onClick={onClick} target="_blank">
          <S.Title>{title}</S.Title>
          <S.Icon src={icon} />
        </S.CardAnchor>
      </S.CardWrapper>
    </S.ContainerCardWrapper>
  );
};
export default React.memo(CardsZurich);

import React from 'react';
import {
  IconCoin,
  IconBusinessplan,
  IconDeviceMobileVibration,
  IconBuildingStore,
  IconGift,
} from '@tabler/icons-react';
import { Pix } from 'styled-icons/fa-brands';
import Container from '~/components/NewUi/Container';
import Button from '~/components/NewUi/Button';

import Typography from '~/components/NewUi/Typography';
import DigitalAccountImage from '~/assets/img/home/mid-app-graph.png';
import { trackingSelectContent } from '~/analytics';
import {
  Content,
  PhoneContainer,
  PhoneImage,
  Feature,
  FeatureIcon,
  FeaturesContainer,
  FeatureText,
  FeatureList,
  ActionButton,
} from './style';

const DigitalAccount = ({ handleOpenAccount }) => {
  return (
    <Container>
      <Content>
        <PhoneContainer>
          <PhoneImage
            role="img"
            title="Aplicativo da conta digital Midway em um telefone"
            src={DigitalAccountImage}
          />
        </PhoneContainer>
        <FeaturesContainer
          onClick={() =>
            trackingSelectContent(
              'conta-digital-midway:abrir-conta-gratis',
              'home'
            )
          }
          aria-label="Vantagens da conta digital Midway"
        >
          <Typography variant="h2" styleType="title">
            Conta digital Midway
          </Typography>
          <Typography variant="p" color="#616B6A" fontSize="18px">
            Conheça algumas vantagens da conta digital Midway
          </Typography>
          <FeatureList aria-label="Lista de vantagens">
            <Feature>
              <FeatureIcon>
                <IconCoin />
              </FeatureIcon>
              <FeatureText>Conta gratuita</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconBusinessplan />
              </FeatureIcon>
              <FeatureText>Seu saldo rende mais do que a poupança</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconDeviceMobileVibration />
              </FeatureIcon>
              <FeatureText>Recarga de celular</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <Pix name="pix" />
              </FeatureIcon>
              <FeatureText>Pix no crédito</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconBuildingStore />
              </FeatureIcon>
              <FeatureText>Saque e depósito nas lojas Riachuelo</FeatureText>
            </Feature>
            <Feature>
              <FeatureIcon>
                <IconGift />
              </FeatureIcon>
              <FeatureText>Descontos exclusivos na Riachuelo</FeatureText>
            </Feature>
          </FeatureList>
          <ActionButton>
            <Button
              role="button"
              aria-label="Clique para abrir uma conta grátis"
              tabIndex={0}
              onClick={handleOpenAccount}
            >
              Abrir conta grátis
            </Button>
          </ActionButton>
        </FeaturesContainer>
      </Content>
    </Container>
  );
};

export default DigitalAccount;

import React from 'react';
import { Image } from '@midway/web-ui-component';

import Midway from '~/assets/img/conheca-midway/midway-historia.png';
import * as S from './styles';

export default function Error404() {
  return (
    <S.MidwayContent className="container">
      <div className="row flex-lg-row">
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h1>Ops...</h1>
          <p>
            A página que você está procurando foi renomeada, removida ou talvez
            nunca existiu.
          </p>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <Image
            aria-hidden="true"
            alt="Imagem da recepção da Midway."
            src={Midway}
          />
        </div>
      </div>
    </S.MidwayContent>
  );
}

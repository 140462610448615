import React from 'react';
import * as S from './styles';

const CardFgts = ({ title, description }) => {
  return (
    <S.Container>
      <S.Card>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.Card>
    </S.Container>
  );
};

export default CardFgts;

import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_LOAN_URL,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const instanceMidway = axios.create({
  baseURL: process.env.REACT_APP_MIDWAY_URL,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

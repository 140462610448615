import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@midway/web-ui-component';
import * as S from './styles';

const Fallback = ({ color }) => {
  return (
    <S.Container color={color}>
      <Spinner animation="border" state="primary" />
    </S.Container>
  );
};

Fallback.propTypes = {
  color: PropTypes.string,
};

Fallback.defaultProps = {
  color: undefined,
};

export default Fallback;

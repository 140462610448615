import React from 'react';
import PropTypes from 'prop-types';
import useRemoteConfig from '~/hooks/useRemoteConfig';

const FirebaseProvider = ({ children }) => {
  const { loading } = useRemoteConfig();

  if (loading) {
    return <></>;
  }

  return children;
};

FirebaseProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default FirebaseProvider;

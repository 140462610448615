import styled, { keyframes } from 'styled-components';

const opacity = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -40px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const animation1 = keyframes`
  0% {
    transform: translate(-100px, 60px);
  }
  50% {
    transform: rotate(2deg) translate(-105px, 65px) scale(0.98);
  }
  100% {
    transform: translate(-100px, 60px);
  }
`;

const animation2 = keyframes`
  0% {
    transform: translate(120px, -40px);
  }
  50% {
    transform: rotate(-2deg) translate(125px, -45px) scale(1.02);
  }
  100% {
    transform: translate(120px, -40px);
  }
`;

export const Container = styled.div`
  padding-top: 16px;
  display: none;
  animation: ${opacity} 0.2s ease;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerAnimated = styled.div`
  position: relative;
  width: 450px;
  height: 450px;
`;

export const CircleImage = styled.img`
  position: absolute;
  z-index: 1;
`;

export const CardBlack = styled.img`
  position: absolute;
  width: 450px;
  z-index: 3;
  animation: ${animation2} 5s ease infinite;
`;

export const CardGreen = styled.img`
  position: absolute;
  width: 450px;
  animation: ${animation1} 5s ease infinite;
  z-index: 2;
`;

export const Text = styled.p`
  padding: 24px 0;
  width: 60%;
  text-align: center;
  color: #1f2b2a;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
`;

export const Button = styled.button`
  padding: 16px;
  border: 0;
  border-radius: 24px;
  color: #00726d;
  font-weight: bold;
  transition: background 0.3s ease 0s;

  :hover {
    background: #d6fffe;
  }
`;

export const MobileButton = styled.button`
  margin: 32px 0;
  border: 0;
  background: transparent;
  display: block;
  width: 100%;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);
  display: flex;
  min-width: 232px;
  animation: ${opacity} 0.2s linear;
`;

export const MobileLeft = styled.div`
  padding: 16px;
`;

export const MobileRight = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  text-align: left;
  justify-content: center;
`;

export const MobileTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #009992;
  padding-bottom: 8px;
`;

export const MobileDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #616b6a;
`;

export const MobileImg = styled.img`
  width: 120px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;

  @media (max-width: 1600px) {
    padding: 0 40px;
  }

  @media (max-width: 900px) {
    padding: 0 32px;
  }

  @media (max-width: 768px) {
    padding: 0 22px;
  }

  @media (max-width: 492px) {
    padding: 0 14px;
  }
`;

const Container = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;

export const Banner = [
  {
    name: 'BannerSeguroProtecaoFinanceira',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-protecao-financeira-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-protecao-financeira-desktop.png',
    title:
      'Proteja seus sonhos, proteja suas finanças, contrate o seguro proteção financeira',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://www.midway.com.br/protecao-financeira',
  },
];

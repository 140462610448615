import styled from 'styled-components';

const getColor = props => {
  if (props.black) return '#221f20';
  if (props.white) return '#fff';
  if (props.gray) return '#3b4443';
  return '#1f2b2a';
};

export const Container = styled.section`
  padding: 40px 16px;
  max-width: 1176px;

  @media screen and (min-width: 480px) {
    padding: 48px 32px;
  }
  @media screen and (min-width: 900px) {
    padding: 72px 132px;
  }
  @media screen and (min-width: 1200px) {
    padding: 72px 0;
    margin: auto;
  }
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 24px;
  color: ${props => (props.light ? '#fff' : '#221f20')};
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: ${props => (props.bold ? 500 : 400)};
  line-height: 1.25 !important;
  margin-bottom: 16px !important;
  color: ${props => getColor(props)};

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  b {
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
`;

export const TooltipText = styled.p`
  font-size: 1rem;
  font-weight: ${props => (props.bold ? 600 : 400)};
  color: ${props => getColor(props)};
`;

export const Button = styled.button`
  background-color: transparent;
  border-radius: 50px;
  border: 2px #221f20 solid;
  padding: 8px 14px;
  font-weight: 500;
  font-size: 0.9rem;
  margin-top: auto;
`;

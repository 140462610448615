import React from 'react';
import { Image } from '@midway/web-ui-component';
import { useDispatch, useSelector } from 'react-redux';
import IconClosed from '~/assets/img/Financial/lending/iconClosed.svg';
import qrcode from '~/assets/img/new-home/qrcode.svg';
import { showCallToAction } from '~/store/modules/calltoaction/actions';
import * as S from './styles';

const QrCode = () => {
  const dispatch = useDispatch();
  const { showModal } = useSelector(state => state.calltoaction);

  const handleCloseModal = e => {
    e.preventDefault();
    dispatch(showCallToAction(false));
  };

  return (
    showModal && (
      <S.Modal>
        <div id="background" />
        <S.ModalContent>
          <div className="ahl-icon-closed" onClick={handleCloseModal}>
            <img src={IconClosed} alt="Icone de x, para fechar o qrcode" />
          </div>

          <h1>Abra sua conta digital gratuita</h1>
          <p>Posicione a câmera do celular no QR Code abaixo.</p>
          <p>Agora está fácil!</p>
          <p>
            Você vai ser redirecionado para a loja de aplicativos e já consegue
            baixar o <S.Bold>app Midway</S.Bold> para abrir a sua conta digital
            gratuita.
          </p>

          <Image src={qrcode} className="qrcode" alt="qrCode" />
        </S.ModalContent>
      </S.Modal>
    )
  );
};

export default QrCode;

import styled from 'styled-components';

export const ContentModal = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
  padding: 20px 20px 0 20px;
`;

export const TitleModal = styled.p`
  font-size: 20px;
  padding-left: 16px;
  font-weight: bold;
  color: #252525;
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;

export const ContentTable = styled.div`
  padding: 0 0 30px 0;
  .table-map {
    padding: 0 0 15px 0;
  }
  .--not-first {
    table-layout: fixed !important;
    @media (max-width: 992px) {
      table-layout: auto !important;
    }
  }
`;

export const Th = styled.th`
  vertical-align: top !important;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : '20px'};
  font-size: 15px !important;
  font-weight: bold;
  color: #252525;
  white-space: pre;
  @media (max-width: 992px) {
    font-size: 13px;
  }
`;

export const Td = styled.td`
  padding-top: 10px !important;
  font-size: 16px;
  color: #707070;
  @media (max-width: 992px) {
    font-size: 11px;
  }

  ${props => (props.minWidth ? 'white-space: pre-line;' : '')}
`;

export const Obs = styled.div`
  margin-top: -18px;
  padding: 0 0 0 12px;
`;

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ImageContainer = styled.div`
  @media (max-width: 768px) {
    margin: auto;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 500px;
  @media (max-width: 768px) {
    margin: auto;
  }
`;

export const FeaturesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const FeatureList = styled.div`
  width: 100%;

  .CardProductPadded {
    cursor: default;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
    border: none;
    padding: 24px;
    min-height: 240px;
    .CardContentRoot {
      padding-right: 55px;
      padding-bottom: 22px;
    }
    .CardContentTextRoot {
      margin-top: 1em;
    }

    p {
      margin-bottom: 0.2 !important;
    }

    @media screen and (max-width: 1024px) {
      min-height: 374px;
    }

    @media screen and (max-width: 492px) {
      padding: 8px;
      border: 1px solid #3b4443;
      .cardTitle {
        font-size: 1rem !important;
      }
      min-height: 250px;
    }

    @media screen and (max-width: 768px) {
      min-height: 200px;
    }
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CardWrapper = styled.div`
  padding: 5px;
`;

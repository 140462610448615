import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Fallback from '~/components/Fallback';
import { scroll } from '~/services/helpers';

const Container = styled.div`
  background-color: transparent;
  max-width: ${props => (props.enlarged ? `1300px` : `800px`)};
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.css}
`;

const HtmlContainer = styled.div``;

const BaseContract = () => {
  const params = useParams();
  const [html, setHtml] = useState('');
  const [css, setCss] = useState('');
  const [enlarged, setEnlarged] = useState(false);
  const [loading, setLoading] = useState(false);

  const importFile = useCallback(async () => {
    try {
      setLoading(true);
      const file = await import(`./contractsHtml/${params.contractName}`);
      setHtml(file.html);
      setCss(file.css);
      setEnlarged(file.enlarged);
      setTimeout(() => {
        scroll(null);
      }, 500);
    } catch (e) {
      // página de erro
    } finally {
      setLoading(false);
    }
    return null;
  }, [params.contractName]);

  useEffect(() => {
    scroll(null);
    importFile();
  }, [importFile]);

  return (
    <Container
      id="ready-page-for-screen-reader"
      aria-label="Página carregada"
      css={css}
      enlarged={enlarged}
    >
      {loading ? (
        <Fallback color="transparent" />
      ) : (
        <HtmlContainer dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </Container>
  );
};

export default BaseContract;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Description = styled.span`
  font-weight: bold;
  color: #00726d;
  margin: 22px 0 15px 0;
`;
export const Content = styled.div`
  margin-top: 16px;
  border: 1.2px solid #cacecd;
  border-radius: 8.79px;
  box-sizing: border-box;
  padding: 16px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

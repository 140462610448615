import styled, { css } from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  strong,
  .bold {
    font-weight: bold;
  }

  button {
    background-color: #1f2b2a !important;
    border: none;
    margin-top: 16px;
    width: 100%;
    &:hover {
      background: ${darken(0.2, '#1f2b2a')} !important;
      color: ${lighten(0.2, '#FFF')} !important;
    }
    &:disabled {
      background: rgba(0, 128, 122, 0.2);
      cursor: not-allowed;

      :hover {
        background: #1f2b2a !important;
      }
    }
  }
  section {
    padding: 30px 16px;
    display: flex;
    flex-direction: column;
    color: #1f2b2a;
    max-width: 1400px;
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      section {
        width: 80%;
        margin: 0 auto;
        button {
          width: 400px;
          margin: 30px auto 0 auto;
        }
      }
    `};
`;

export const HeroContainer = styled.div`
  flex: 1;
  flex-direction: row;
  background-color: #3b4443;

  width: 100%;

  .container-content-hero {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;

    .container-image {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      max-height: 480px;

      img {
        max-width: 90%;
      }
    }

    .container-desenrola {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;

      .logo {
        max-width: 13rem;
        max-height: 13.25rem;
        ${({ isMobile }) =>
          isMobile &&
          css`
            margin-top: -30px;
          `};
      }
      .texto {
        max-width: 424px;
        max-height: 347px;
        margin-top: -140px;
        ${({ isMobile }) =>
          isMobile &&
          css`
            margin-bottom: -50px;
          `};
      }
    }

    .container-form {
      padding: 30px 16px;

      .form {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 8px 12px rgba(31, 43, 42, 0.16);
        border-radius: 8px;
        background: #ffffff;
        padding: 12px;
        margin: 30px 0 24px 0;
        z-index: 1;
        position: relative;
        h2 {
          font-size: 14px !important;
        }

        input {
          padding: 16px 8px 8px 8px;
          margin-top: 12px;
          width: 100%;
          height: 48px;
          font-size: 14px !important;
          display: flex;

          border-top-style: hidden;
          border-left-style: hidden;
          border-right-style: hidden;
          border-width: 2px;
          border-color: #959d9d;

          transition:
            border-color 0.3s ease 0s,
            color 0.3s ease 0s;

          :focus {
            border-color: #212121;
            color: #212121;
          }
        }

        button {
          background-color: #959d9d;
          border: none;
          margin-top: 16px;
        }
        .channel-rchlo {
          margin: 8px auto;
          text-align: center;
          span {
            font-size: 8px;
          }
          img {
            width: 80px;
          }
        }
      }
    }

    .rightSide {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      ${({ isMobile, feirao }) =>
        isMobile &&
        feirao &&
        css`
          flex-direction: column-reverse;
        `};

      ${({ isMobile, feirao }) =>
        !isMobile &&
        feirao &&
        css`
          width: 50%;
        `};

      .container-message {
        width: 60%;
        background-color: #262b2b;
        padding: 1rem;
        color: #fff;
        border-radius: 8px;

        ${({ isMobile }) =>
          isMobile &&
          css`
            width: 90%;
          `};

        ${({ isMobile, feirao }) =>
          isMobile &&
          feirao &&
          css`
            margin-bottom: 1rem;
          `};
      }

      .alert-icon-message {
        display: flex;
        align-items: center;
        width: max-content;
        background: #3b4443;
        padding: 4px 8px;
        border-radius: 4px;
        margin-bottom: 1rem;
        font-size: 12px;

        svg {
          margin-right: 6px;
          stroke: #fff;
          width: 16px;
          height: 16px;
          flex: 0 0 auto;
        }
      }
      img {
        margin-top: 2rem;
        max-width: 203.92px;
        max-height: 206.9px;

        ${({ feirao }) =>
          feirao &&
          css`
            margin-top: 2rem;
            width: 60%;
            max-width: none;
            max-height: none;
          `};

        ${({ isMobile, feirao }) =>
          isMobile &&
          feirao &&
          css`
            width: 90%;
            margin: -2rem 0 2rem 1rem;
            max-width: none;
            max-height: none;
          `};
      }
    }

    ${({ isMobile }) =>
      !isMobile &&
      css`
        flex-direction: row;
        justify-content: space-between;
        max-height: 480px;

        .container-form {
          padding-left: 10%;
          width: 50%;

          .form {
            max-width: 450px;
          }
        }
        .container-image {
          width: 50%;
          margin-top: 0;

          position: relative;

          img {
            margin: 0 auto;
            max-width: 480px;
          }
        }
      `};

    ${({ isMobile }) =>
      isMobile &&
      css`
        flex-direction: column;
      `};
  }
`;

export const TitleSection = styled.h2`
  font-size: 24px !important;
  font-weight: 800;
  line-height: 28px;
  margin: 16px 0 !important;
  color: #1f2b2a;
`;
export const SubtitleForm = styled.h3`
  font-size: 17px !important;
  font-weight: 800;
  line-height: 28px;
  color: #f7f7f7;
  text-align: center;
  padding: 0 0 32px 0 !important;
  margin-top: -20px;
`;

export const TitleHero = styled.h1`
  font-weight: 800;
  font-size: 30px !important;
  line-height: 33px;
  color: ${({ color }) => color};
`;

export const Decoration = styled.span`
  color: #47c8cd;
`;

export const MaskedInputLabel = styled.label`
  color: #c0392b;
  font-weight: 600;
  padding-top: 4px;
  font-size: 12px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const ContainerBenefits = styled.section`
  .container-list-benefits {
    .benefit {
      padding: 16px;
      border: 1px solid #cacecd;
      border-radius: 8px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      img {
        width: 40px;
        margin-bottom: 4px;
      }
      strong {
        margin-bottom: 4px;
      }
    }

    ${({ isMobile }) =>
      !isMobile &&
      css`
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .benefit {
          width: 22%;
          margin-right: 16px;
        }
      `};
  }
`;

export const ContainerHIW = styled.section`
  background: #f7f7f7;
  justify-content: center;
  align-items: center;

  .container-image {
    img {
      width: 328px;
      height: 456px;
    }
  }

  .content {
    .hiw {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;

      .title {
        font-weight: bold;
      }
      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      flex-direction: row !important;
      justify-content: space-around;
      width: 100% !important;
    `};
`;

export const ContainerFaq = styled.section`
  & > button {
    background-color: #1f2b2a !important;
    border: none;
    margin-top: 30px;
  }
  ${({ isMobile }) =>
    !isMobile &&
    css`
      width: 80%;
      margin: 0 auto;
      & > button {
        margin: 30px auto 0 auto;
        width: 400px;
      }
    `};
`;

export const ContainerContact = styled.section`
  .content {
    .contact {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;

      .title {
        font-weight: bold;
      }
      div {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
      img {
        width: 40px;
        height: 40px;
      }
    }
  }
  button {
    background-color: #1f2b2a !important;
    border: none;
    margin-top: 30px;
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      width: 80%;
      margin: 0 auto;
      .content {
        display: flex;
        justify-content: space-between;
        .contact {
          width: 30%;
          align-items: flex-start;
        }
      }
    `};
`;

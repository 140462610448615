// eslint-disable-next-line no-unused-vars
import React from 'react';
import useIsMobile from '~/hooks/useIsMobile';

const Desktop = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile) return null;
  return children;
};

export default Desktop;

import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  padding: 32px;
  padding-top: 0px;
`;

export const HeaderContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 22px;

  display: flex;
  align-items: center;
`;

export const Header = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 4px;
`;

export const StepNumber = styled(Header)`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${colors.cian};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 12px;
`;

export const Box = styled.div`
  padding: 20px 12px;
  background-color: ${colors.light_gray};
  border-radius: 4px;
`;

export const Badge = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;

  padding: 3px 8px 4px;

  background-color: ${({ variant }) => {
    if (variant === 'primary') return colors.cian;
    if (variant === 'success') return colors.light_green;
    return colors.light_brown;
  }};
  color: ${({ variant }) => {
    if (variant === 'primary') return colors.primary;
    if (variant === 'success') return colors.primary;
    return colors.dark_brown;
  }};
  border-radius: 4px;

  width: fit-content;

  @media (max-width: 992px) {
    width: ${({ variant }) => {
      if (!variant) return '100%';
    }};
    margin-bottom: 12px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const InstallmentOptionSelectedContainer = styled.div`
  flex-grow: 1;
`;

export const FeeText = styled.p`
  font-size: 14px;
  font-weight: 400;

  color: ${colors.dark_gray_3};
  padding-bottom: 8px;
`;

export const InstallmentOptionsInputContainer = styled.div`
  margin-right: 22px;

  @media (max-width: 992px) {
    margin-top: 20px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.dark_gray_2};

  margin-top: 12px;
`;

export const Text = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.dark};
`;

export const Bold = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${colors.dark};
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

export const PaymentMethodBox = styled(Box)`
  width: 100%;
  max-width: 50%;

  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

export const PaymentMethodTitle = styled.p`
  font-size: 16px;
  font-weight: 600;

  color: ${colors.primary};

  display: flex;
  gap: 8px;
  align-items: center;

  padding-bottom: 8px;
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 155px;
`;

export const LoadingText = styled.div`
  font-size: 14px;
  font-weight: 500;

  color: ${colors.black};
`;

import React from 'react';
import { Container } from './styles';

const DescriptionTag = ({ text, type, id }) => (
  <Container id={id} type={type}>
    {text}
  </Container>
);

export default DescriptionTag;

import styled from 'styled-components';
import { Icon, Button } from '@midway/web-ui-component';

export const Accessibility = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 60px 0;
`;

export const AccessibilityContent = styled.div`
  width: 60%;
  @media (max-width: 992px) {
    width: 80%;
  }
`;

export const Title = styled.h1`
  padding-bottom: 40px;
  font-size: 40px;
  font-weight: bold;
  color: #252525;
  @media (max-width: 992px) {
    font-size: 22px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  padding-top: 5px;
  margin-bottom: 26px;
  color: #707070;
  line-height: 34px;
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const AccessibilityBox = styled.div`
  padding: 30px 30px;
  background-color: #f4f4f4;
  text-align: left;
`;

export const AccessibilityDownloadContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

export const AccessibilityPatternIcon = styled(Icon)`
  display: block;
  margin: 40px 0;
  font-size: 145px;
  color: #661e9a;
  @media (max-width: 992px) {
    font-size: 60px;
  }
`;

export const AccessibilityDownloadButton = styled(Button)``;

import React from 'react';
import {
  IconBrandMastercard,
  IconCreditCard,
  IconGift,
} from '@tabler/icons-react';
import * as S from './styles';
import { TABS } from '../constants';

const Tabs = ({ selectedTab, setSelectedTab = () => {} }) => {
  const Tab = ({ tabId, text, icon }) => {
    const onClick = () => setSelectedTab(tabId);
    const active = selectedTab === tabId;

    return (
      <>
        <S.TabWrapper active={active} onClick={onClick}>
          <S.IconWrapper>{icon}</S.IconWrapper>
          <S.Text active={active}>{text}</S.Text>
        </S.TabWrapper>
      </>
    );
  };

  return (
    <>
      <S.Container>
        <Tab
          tabId={TABS.PARCEIROS_MIDWAY}
          text="Parceiros Midway"
          icon={<IconGift />}
        />
        <Tab
          tabId={TABS.MASTERCARD_SURPREENDA}
          text="Mastercard Surpreenda"
          icon={<IconBrandMastercard />}
        />
        <Tab
          tabId={TABS.NOSSOS_CARTOES}
          text="Nossos cartões"
          icon={<IconCreditCard />}
        />
      </S.Container>
    </>
  );
};

export default Tabs;

import styled from 'styled-components';
import { Button as BaseButton } from '~/components/Button';

export const Content = styled.div`
  padding: 36px;
  margin: 0 auto;
`;

export const Title = styled.p`
  color: #252525;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
`;

export const Description = styled.p`
  color: #707070;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 24px !important;
`;

export const Button = styled(BaseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonImage = styled.img`
  width: 24px;
  margin-right: 8px;
`;

export const ImageError = styled.img`
  width: 96px;
  margin: 0 auto 16px;
`;

import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ContainerCardProposal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  background: #ffffff;
  box-shadow: -2px -2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;

  b {
    font-weight: bold;
  }

  span {
    margin-right: 4px;
  }

  .btn {
    width: 80%;
    margin: 24px auto 0 auto;
  }

  .container-select-item {
    display: flex;

    svg {
      animation: ${rotate} 2s linear infinite;
    }
    .loader {
      width: 2px !important;
      height: 2px !important;
      margin-right: 7px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  width: 100%;
  margin-bottom: 16px;
`;

export const Title = styled.span`
  font-weight: 500;
`;

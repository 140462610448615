import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Mobile from '~/components/Responsive/Mobile';
import { trackingSelectContent } from '~/analytics';

import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';
import * as S from './style';

const PAYMENT_CAMPAIGN_URL =
  '/campanha-quitacao?utm_source=MiAjuda&utm_medium=Header&utm_campaign=quitacao-de-divida&utm_term=Header&utm_content=midwayheader';

const MenuCartoes = () => (
  <ul className="submenu" data-header="Cartões" tabIndex={0}>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent('header:cartoes:conheca-nossos-cartoes', 'home')
        }
        id="ico-cards"
        className="MenuLinkMobile"
        to="/cartoes"
        tabIndex={0}
      >
        Conheça nossos cartões
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:cartoes:cartao-richuelo-mastercard',
            'home'
          )
        }
        id="ico-master"
        className="MenuLinkMobile"
        to="/cartao-de-credito"
      >
        Cartão Riachuelo Mastercard
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:cartoes:cartao-da-loja-riachuelo',
            'home'
          )
        }
        id="ico-rchlo"
        className="MenuLinkMobile"
        to="/cartao-rchlo"
      >
        Cartão da loja Riachuelo
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent('header:cartoes:limite-garantido', 'home')
        }
        id="ico-single-card"
        className="MenuLinkMobile"
        to="/limite-garantido"
      >
        Limite Garantido
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent('header:cartoes:parcerias', 'home')
        }
        id="ico-ticket"
        className="MenuLinkMobile"
        to="/beneficios"
      >
        Benefícios
      </Link>
    </li>
  </ul>
);

const MenuEmprestimos = () => (
  <ul className="submenu" data-header="Empréstimos" tabIndex={0}>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent('header:emprestimos:emprestimo-pessoal', 'home')
        }
        id="ico-cash"
        className="MenuLinkMobile"
        to="emprestimos"
      >
        Empréstimo pessoal
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:emprestimos:antecipacao-saque-aniversario-fgtsl',
            'home'
          )
        }
        id="ico-money"
        className="MenuLinkMobile"
        to="/antecipacao-saque-fgts"
      >
        Antecipação Saque-Aniversário FGTS
      </Link>
    </li>
  </ul>
);

const MenuSeguros = () => (
  <ul className="submenu" data-header="Seguros e assistências" tabIndex={0}>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:segredos-e-assistencias:todos-os-produtos',
            'home'
          )
        }
        id="ico-plus"
        className="MenuLinkMobile"
        to="/produtos-financeiros"
      >
        Todos os produtos
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent('header:seguros-e-assistencias:seguros', 'home')
        }
        id="ico-shield"
        className="MenuLinkMobile"
        to="/seguros"
      >
        Seguros
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:segredos-e-assistencias:assistencias',
            'home'
          )
        }
        id="ico-block"
        className="MenuLinkMobile"
        to="/assistencias"
      >
        Assistências
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:segredos-e-assistencias:servicos-para-saude',
            'home'
          )
        }
        id="ico-heart"
        className="MenuLinkMobile"
        to="/saude"
      >
        Serviços para saúde
      </Link>
    </li>
    <li>
      <Link
        onClick={() =>
          trackingSelectContent(
            'header:segredos-e-assistencias:plano-odontologico',
            'home'
          )
        }
        id="ico-dental"
        className="MenuLinkMobile"
        to="/plano-odontologico"
      >
        Plano Odontológico
      </Link>
    </li>
  </ul>
);

const Header = () => {
  const {
    toogles: { isDisableHeaderButtonBilletInvoice },
  } = useSelector(state => state.featuretoggle);

  const hasAppDownloadWidget = useHasMobileDownloadHeader();
  const [, setIsMobile] = useState(window.innerWidth <= 712);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isPaymentCampaign =
    isDisableHeaderButtonBilletInvoice &&
    window.location.pathname === '/campanha-quitacao';

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const firePaymentsButtonClickToGTM = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway-geral',
      eventAction: 'clique:menu-e-sub-menu',
      eventLabel: 'pagamentos',
    });
  };

  const handlePaymentsButtonClick = () => {
    firePaymentsButtonClickToGTM();
  };

  return (
    <S.MenuWrapper>
      <S.FirstMenu>
        <div className="containermenu">
          <nav>
            <ul>
              <li className="header-logo">
                <Link tabIndex={0} id="midway-logo" to="/">
                  Midway
                </Link>
              </li>
              <li
                onClick={() =>
                  trackingSelectContent('mega-menu:riachuelo', 'home')
                }
              >
                <Link
                  tabIndex={0}
                  id="rchlo-logo"
                  to={{ pathname: 'http://www.riachuelo.com.br' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Riachuelo
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    trackingSelectContent('mega-menu:casa-riachuelo', 'home')
                  }
                  tabIndex={0}
                  id="casa-logo"
                  to={{
                    pathname:
                      'https://www.riachuelo.com.br/campanha/r/lp/casa/colecao',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Casa Riachuelo
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    trackingSelectContent('mega-menu:carters', 'home')
                  }
                  tabIndex={0}
                  id="carters-logo"
                  to={{
                    pathname:
                      'https://www.riachuelo.com.br/campanha/r/marca/carters',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Carters
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    trackingSelectContent('mega-menu:fanlab', 'home')
                  }
                  tabIndex={0}
                  id="fan-logo"
                  to={{
                    pathname:
                      'https://www.fanlab.com.br/?utm_source=riachuelo&utm_medium=web&utm_campaign=topo-multisites&utm_term=topo-multisites-fun-lab_riachuelo-web',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fan lab
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    trackingSelectContent('mega-menu:blog', 'home')
                  }
                  tabIndex={0}
                  id="blog-logo"
                  to={{ pathname: 'https://blog.riachuelo.com.br/' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  .blog
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </S.FirstMenu>
      <div className="containermenu">
        <S.PrincipalMenu hasWidget={hasAppDownloadWidget}>
          <S.MenuRow>
            {!isPaymentCampaign && (
              <Link id="download-buttom" to="/app/">
                Baixe o App
              </Link>
            )}
            <S.MobileRow>
              {!showMobileMenu && (
                <button
                  type="button"
                  tabIndex={0}
                  onClick={() => setShowMobileMenu(true)}
                  id="sandwichMenu"
                >
                  Menu
                </button>
              )}
              {showMobileMenu && (
                <button
                  type="button"
                  tabIndex={0}
                  onClick={() => setShowMobileMenu(false)}
                  id="closeMenu"
                >
                  Fechar
                </button>
              )}
              <Mobile>
                {!isPaymentCampaign && (
                  <Link
                    tabIndex={0}
                    onClick={handlePaymentsButtonClick}
                    to="/consulta-boleto"
                    id="paybill-buttom"
                  >
                    <span>Pagar fatura</span>
                  </Link>
                )}
              </Mobile>
            </S.MobileRow>
          </S.MenuRow>

          <ul id="menu">
            <li tabIndex={0} aria-label="Cartões">
              <a>
                Cartões <span className="chevron" />
              </a>
              <MenuCartoes />
            </li>
            <li tabIndex={0} aria-label="Conta digital">
              <Link
                to="/conta-digital"
                onClick={() =>
                  trackingSelectContent(
                    'header:conta-digital:conta-digital-midway',
                    'home'
                  )
                }
              >
                Conta Digital
              </Link>
            </li>
            <li tabIndex={0} aria-label="Empréstimos">
              <Link
                onClick={() =>
                  trackingSelectContent('header:emprestimos', 'home')
                }
                to="/emprestimos"
              >
                Empréstimos <span className="chevron" />
              </Link>
              <MenuEmprestimos />
            </li>
            <li tabIndex={0} aria-label="Seguros e assistência">
              <Link
                to="/produtos-financeiros"
                onClick={() =>
                  trackingSelectContent('header:seguros-e-assistencias', 'home')
                }
              >
                Seguros e Assistências <span className="chevron" />
              </Link>
              <MenuSeguros />
            </li>
            <li tabIndex={0} aria-label="Negociação de Dívidas">
              <Link
                onClick={() =>
                  trackingSelectContent(
                    'header:negociacoes-de-dividas:negociar-divida',
                    'home'
                  )
                }
                to={PAYMENT_CAMPAIGN_URL}
              >
                Negociação de Dívidas
              </Link>
            </li>
            {!isPaymentCampaign && (
              <li
                onClick={() =>
                  trackingSelectContent('header:pagar-fatura', 'home')
                }
              >
                <Link
                  tabIndex={0}
                  onClick={handlePaymentsButtonClick}
                  to="/consulta-boleto"
                  id="paybill-buttom"
                >
                  <span>Pagar fatura</span>
                </Link>
              </li>
            )}
          </ul>
          {showMobileMenu && (
            <nav id="menuMobile">
              <ul className="submenu" data-header="Negociação de dívidas">
                <li tabIndex={0}>
                  <Link
                    id="ico-cards"
                    className="MenuLinkMobile"
                    to={PAYMENT_CAMPAIGN_URL}
                  >
                    Negociação de dívidas
                  </Link>
                </li>
              </ul>
              <MenuCartoes />
              <ul className="submenu" data-header="Conta Digital">
                <li tabIndex={0}>
                  <Link
                    id="ico-trending-up"
                    className="MenuLinkMobile"
                    to="/conta-digital"
                  >
                    Conta digital Midway
                  </Link>
                </li>
              </ul>
              <MenuEmprestimos />
              <MenuSeguros />
            </nav>
          )}
        </S.PrincipalMenu>
      </div>
    </S.MenuWrapper>
  );
};

Header.defaultProps = {
  menuItems: [],
};

export default Header;

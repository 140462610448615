import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding: 0 !important;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    flex-direction: column;
    
    position: relative;
    min-height: 200px;
    max-height: 400px !important;
    padding: 0 !important;

    @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
    }

    div.ahl-title{
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content:center;
        font-weight: bold;
        font-size: 23px !important;
        text-align: center;

        @media (max-width: 1305px) {
            width: 60%
        }

        @media (max-width: 425px) {
            width: 100%
            font-size: 13px !important;
        }
    }

    div.ahl-choice{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px !important;
        padding: 12px;
        margin-bottom: 32px;

        border-bottom-style: solid;
        border-width: 1px;
        border-color: #B2BABB;

        cursor: pointer;
        
        @media (max-width: 978px) {
            width: 45%;
           font-size: 14px !important; 
        }

        @media (max-width: 625px) {
            width: 55%;
           font-size: 14px !important; 
        }

        @media (max-width: 625px) {
            width: 70%;
        }

        @media (max-width: 625px) {
            font-size: 11px !important;
        }
    }


`;

export const ModalContent = styled.div`
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        padding: 40px;
        position: relative;

        Button{
            width: 38% !important;
            margin-top: 30px;
        }

        div.ahl-title{
            width: 100% !important;
            font-weight: bold;
            font-size: 23px !important;
            text-align: center;
    
            @media (max-width: 886px) {
                width: 60%
            }
    
            @media (max-width: 425px) {
                width: 100%
                font-size: 13px !important;
            }
        }

        div.ahl-options{
            width: 100%;
            display:flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            padding: 30px;

            div:first-child{
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }

            div:last-child{
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            div.ahl-option{
                width: 40%;
                display:flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                
                text-align: center !important;
                font-size: 16px !important;
    
                
                border-style: solid;
                border-width: 0.5px;
                border-color: #B2BABB;
    
                cursor: pointer;

                padding: 7px;

                span{
                    line-height: 1.5;
                    font-size: 18px;
                }
                @media (max-width: 480px) {
                    width: 100%
                    
                }

                div {
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
            
                    
                    img{
                        width: 22px;
                        height: 22px;
                    }
                }
    
            }
        }


        div.ahl-icon-closed{

            position: absolute;

            top: 0;
            right: 0;
            cursor: pointer;

            img{
                width: 50px;
                height: 50px;
            }
        }
`;

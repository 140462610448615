import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useIsMobile from '~/hooks/useIsMobile';
import { trackingViewPage, trackingSelectContent } from '~/analytics';
import { showCallToAction } from '~/store/modules/calltoaction/actions';
import HeroCarousel from '~/components/HeroCarousel';
import QuickAccess from '~/components/QuickAccess';
import ProductsMidway from '~/components/ProductsMidway';
import CallToAction from '~/components/CallToAction';
import DigitalAccount from './sections/DigitalAccount';
import RiachueloCard from './sections/RiachueloCard';
import Loans from './sections/Loans';
import InsuranceAndAssistance from './sections/InsuranceAndAssistance';
import Faq from './sections/Faq';
import { SectionsWrapper } from './styles';

const Home = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    const home = {
      pageLocation: 'http://www.midway.com.br',
      pageTitle: 'Home',
      pageReferrer: 'http://www.midway.com.br',
      pagePath: '/',
    };

    trackingViewPage(
      home.pageLocation,
      home.pageTitle,
      home.pageReferrer,
      home.pagePath
    );
  }, []);

  const handleShowOpenAccount = ({ fluxo = '' }) => {
    trackingSelectContent(
      'produtos-midway:abrir-conta-digital-gratis',
      `home${fluxo}`
    );

    if (isMobile) {
      window.open('http://onelink.to/ngrm9z', '_blank');
    } else {
      dispatch(showCallToAction(true));
    }
  };

  return (
    <main>
      <HeroCarousel />
      <QuickAccess />
      <SectionsWrapper>
        <ProductsMidway handleOpenAccount={handleShowOpenAccount} />
        <DigitalAccount handleOpenAccount={handleShowOpenAccount} />
        <RiachueloCard />
        <Loans />
        <InsuranceAndAssistance />
        <Faq />
      </SectionsWrapper>

      <CallToAction />
    </main>
  );
};

export default Home;

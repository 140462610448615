import { useCallback, useEffect, useState } from 'react';

const useHasMobileDownloadHeader = (text = 'Pague sua fatura pelo app') => {
  const [hasText, setHasText] = useState(false);

  const checkTextInHeader = useCallback(() => {
    const headerElement = document.querySelector('header');
    if (headerElement) {
      const textExists = Array.from(headerElement.querySelectorAll('*')).some(
        el => el.textContent === text
      );
      setHasText(textExists);
    }
  }, [text]);

  useEffect(() => {
    checkTextInHeader();
    window.addEventListener('resize', checkTextInHeader);
    window.addEventListener('popstate', checkTextInHeader);

    // Limpeza na desmontagem
    return () => {
      window.removeEventListener('resize', checkTextInHeader);
      window.removeEventListener('popstate', checkTextInHeader);
    };
  }, [checkTextInHeader, text]);

  return hasText;
};

export default useHasMobileDownloadHeader;

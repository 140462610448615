import styled from 'styled-components';
import { colors } from '~/constants';

export const LoadingContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  height: 212px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

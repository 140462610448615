import { useState, useCallback } from 'react';

import { getAvailability } from '~/services/InvoiceInstallment';
import { useAuth } from '~/context/auth';

const useGetInstallmentAvailability = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const { documentNumber, maskedPan, machineId: deviceId } = useAuth();

  const memoized = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getAvailability({
        maskedPan,
        documentNumber,
        deviceId,
      });
      setData(response.data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [deviceId, documentNumber, maskedPan]);

  return [
    memoized,
    {
      loading,
      error,
      data,
    },
  ];
};

export default useGetInstallmentAvailability;

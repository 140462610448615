import {
  CardsInvoiceContainer,
  MainQuestionsAccordionsContainer,
  MainQuestionsContainer,
  MainQuestionsTitle,
  ShortcutsContainer,
  WrapperContacts,
} from './styles';

import BackButton from '~/pages/AttendancePortal/components/BackButton';
import Contact from '~/pages/AttendancePortal/components/Contact';
import { IconPlanet } from '@tabler/icons-react';
import { Questions } from './Questions';
import React from 'react';
import ShortcutLink from '~/pages/AttendancePortal/components/ShortcutLink';
import WizardTitle from '~/pages/AttendancePortal/components/WizardTitle';
import Wrapper from '~/pages/AttendancePortal/components/Wrapper';
import { trackUserEvent } from '~/pages/AttendancePortal/analytics';
import { useAttendancePortalState } from '~/pages/AttendancePortal/context/AttendancePortalContext';
import { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';

const SubCategory = () => {
  const { previousStep } = useWizard();
  const { subCategory, category } = useAttendancePortalState();
  const highLight = subCategory.title.split(' ');

  useEffect(() => {
    const titleElement = document.getElementById('wizard-title');
    if (titleElement && window.innerWidth < 720) {
      titleElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      <Wrapper>
        <CardsInvoiceContainer>
          <BackButton onClick={previousStep} />

          <WizardTitle
            id="wizard-title"
            title={`Veja o que encontramos sobre ${subCategory.title} em ${category.title}`}
            highlighted={[category.title, ...highLight]}
          />

          <ShortcutsContainer>
            {subCategory?.links?.map((link, index) => (
              <ShortcutLink
                key={index}
                title={link.title}
                url={link.url}
                onClick={() => {
                  trackUserEvent({
                    contentType: `${link.title}:${subCategory.title}:${category.title}`,
                  });
                  window.open(link.url, '_blank');
                }}
                icon={<IconPlanet />}
              />
            ))}
          </ShortcutsContainer>

          <MainQuestionsContainer>
            {subCategory.isMainQuestions && (
              <>
                <MainQuestionsTitle>Principais dúvidas</MainQuestionsTitle>
                <MainQuestionsAccordionsContainer>
                  <Questions items={subCategory.questions.slice(0, 3)} isStar />
                </MainQuestionsAccordionsContainer>
              </>
            )}

            <MainQuestionsTitle>Todas as dúvidas</MainQuestionsTitle>
            <MainQuestionsAccordionsContainer>
              <Questions items={subCategory.questions} />
            </MainQuestionsAccordionsContainer>
          </MainQuestionsContainer>
        </CardsInvoiceContainer>
      </Wrapper>

      <WrapperContacts>
        <Contact />
      </WrapperContacts>
    </>
  );
};

export const MemoizedSubCategory = React.memo(SubCategory);

import styled from 'styled-components';
import { colors } from '~/constants';

export const Box = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 16px 12px;
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
`;

export const Value = styled.p`
  font-size: 16px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 14px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.dark_gray_2};
  margin-bottom: 10px;
`;

export const InstallmentDescriptionText = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.darken};
  padding-top: 8px;
`;

export const TaxText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.dark_gray_3};
  padding-bottom: 8px;
`;

export const FirstInstallmentDateText = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.darken};
`;

export const Bold = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: ${colors.darken};
`;

export const BottomContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

import styled from 'styled-components';
import * as CommonStyles from '../styles';

export const { Container, Title, Text } = CommonStyles;

export const PointsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 328px;
  margin: 48px auto;
  flex-direction: column;

  @media screen and (min-width: 1176px) {
    flex-direction: row;
    max-width: 100%;
    margin: 96px auto;
    justify-content: space-between;
    align-items: center;
  }
`;

export const PointsContainerItem = styled.div`
  margin-bottom: 36px;

  @media screen and (min-width: 1176px) {
    width: 380px;
  }
`;

export const PointsArrowWrapper = styled.div`
  height: 0;

  @media screen and (min-width: 1176px) {
    margin-bottom: 140px;
  }
`;

export const PointsArrow = styled.img`
  width: 72px;
  transform: rotate(90deg) scale(1, ${props => (props.first ? -1 : 1)});
  margin-left: ${props => (props.first ? 20 : 206)}px;
  margin-top: -18px;

  @media screen and (min-width: 1176px) {
    transform: none;
    margin-left: -55px;
    margin-right: -55px;
    margin-top: 0;
    width: 111px;
  }
`;

export const PointsImage = styled.img`
  height: 140px;
  object-fit: contain;
  margin-bottom: 32px;

  @media screen and (min-width: 1176px) {
    height: 200px;
  }
`;

export const PointsText = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  font-weight: 500;
  width: 100%;

  @media screen and (min-width: 1176px) {
    font-size: 1.25rem;
  }
`;

export const PointsInfoContainer = styled.div`
  padding: 12px 16px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;

  @media screen and (min-width: 1176px) {
    justify-content: center;
    padding: 12px 16px;
  }
`;

export const PointsInfoIconWrapper = styled.div`
  margin-right: 16px;

  svg {
    stroke: #e4602f;
    width: 24px;
    height: 24px;
    stroke-width: 1px;
  }
`;

import React, { createContext, useEffect, useState } from 'react';

import Error from '~/components/Error';
import Loading from '~/components/Loading';
import { useAttendancePortal } from '../hooks';

const initialState = {
  subCategory: null,
  category: null,
  attendanceState: {
    banner:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/attendace-portal-banner.jpeg',
    categories: [
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/attendance-portal-cards-min.jpeg',
        icon: `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 7C19 5.34315 17.6569 4 16 4H4C2.34315 4 1 5.34315 1 7V15C1 16.6569 2.34315 18 4 18" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 7H8C6.34315 7 5 8.34315 5 10V18C5 19.6569 6.34315 21 8 21H20C21.6569 21 23 19.6569 23 18V10C23 8.34315 21.6569 7 20 7Z" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12H23" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 17H9.01" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13 17H15" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        title: 'Cartões',
        contacts: {
          wpp: {
            label: 'Falar pelo Whatsapp',
            value: 'https://wa.me/551130030950',
          },
          list: [
            {
              title: 'Atendimento Cartão Riachuelo',
              subTitle: 'De segunda à domingo, das 7h às 22h.',
              items: [
                {
                  type: 'phone',
                  label: '3004 5417',
                  value: 'Capitais e Regiões Metropolitanas (apenas ligações)',
                },
                {
                  type: 'phone',
                  label: '0800 727 4417',
                  value: 'Demais localidades',
                },
                {
                  type: 'phone',
                  label: '0800 721 3344',
                  value: 'SAC 24h',
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: 'Atendimento 24h para Deficientes Auditivos',
                },
              ],
            },
            {
              title: 'Lojas físicas',
              subTitle: 'De segunda à sábado, das 8h às 22h.',
              items: [
                {
                  type: 'phone',
                  label: '0800 721 4033',
                  value: `
                    <p>
                      Atendimento
                    </p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: `
                    <p>
                      Atendimento 24h para Deficientes Auditivos
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Ouvidoria',
              subTitle: 'De segunda à sexta, das 10h às 16h (exceto feriados).',
              items: [
                {
                  type: 'phone',
                  label: '0800 727 3255',
                  value: `
                    <p>
                      É preciso informar o número do protocolo registrado na Central de Atendimento.
                    </p>

                    <p>
                      A Ouvidoria (Resolução CMN 4.860/2020) atua em última instância nos assuntos financeiros relacionados à Midway, tratados anteriormente sem solução satisfatória.
                    </p>
                  `,
                },
              ],
            },
            {
              type: 'phone',
              title: 'Whatsapp de negociação',
              subTitle: null,
              items: [
                {
                  label: '(11) 3003 0950',
                  value:
                    '<p>Adicione o número em sua lista de contatos e negocie sua dívida via Whatsapp</p>',
                },
              ],
            },
          ],
        },
        links: [
          {
            title: 'Pagar fatura (linha digitável)',
            url: 'https://www.midway.com.br/consulta-boleto',
          },
          {
            title: 'Negociar dívidas',
            url: 'https://www.midway.com.br/campanha-quitacao?utm_source=pagina_ajuda_rchlo&utm_medium=FAQ_rchlo&utm_campaign=quitacao-de-divida',
          },
          {
            title: 'Pedir Cartão RCHLO Mastercard',
            url: 'https://www.midway.com.br/cartao-de-credito',
          },
        ],
        subCategories: {
          feedback: {
            yes: {
              description: '<p>Ficamos felizes em ajudar!</p>',
            },
            no: {
              description: `
                <p>Sentimos muito! Que tal entrar em contato através dos nossos outros canais.</p>
                <p>De segunda à domingo, das 7h às 22h:</p>
              `,
              contacts: [
                {
                  type: 'phone',
                  label: '3004 5417',
                  value: `
                    <p>Capitais e Regiões Metropolitanas <br /> (apenas ligações)</p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 727 4417',
                  value: `
                    <p>Demais localidades</p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 721 3344',
                  value: `
                    <p>SAC 24h</p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: `
                    <p>Atendimento 24h para Deficientes Auditivos</p>
                  `,
                },
              ],
            },
          },
          list: [
            {
              title: 'Cancelamentos',
              isMainQuestions: false,
              questions: [
                {
                  label: 'Como cancelar um seguro no Cartão Riachuelo?',
                  value: `
                    <p>Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, você pode cancelar um seguro no Cartão Riachuelo.</p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417</li>
                      <li>Demais localidades: 0800 727 4417</li>
                      <li>Capitais e regiões metropolitanas: 4003 0515</li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>
                  `,
                },
                {
                  label: 'Como cancelar o Cartão Riachuelo?',
                  value: `
                    <p>
                      Você pode solicitar o cancelamento do Cartão Riachuelo acessando a aba cartões e clicando no ícone Suporte.
                      Logo em seguida, encontrará a opção de cancelamento, localizada abaixo dos canais de atendimento. Ou, também, na Central de Atendimento,
                      de segunda a sábado (exceto feriados), das 08h às 22h, você pode solicitar o cancelamento do Cartão Riachuelo, em um dos nossos canais:
                    </p>

                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417</li>
                      <li>Demais localidades: 0800 727 4417 (24h, 7 dias por semana)</li>
                    </ul>

                  `,
                },
              ],
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao?utm_source=pagina_ajuda_rchlo&utm_medium=FAQ_rchlo&utm_campaign=quitacao-de-divida',
                },
                {
                  title: 'Pedir Cartão RCHLO Mastercard',
                  url: 'https://www.midway.com.br/cartao-de-credito',
                },
              ],
            },
            {
              title: 'Cartão RCHLO',
              isMainQuestions: true,
              questions: [
                {
                  label:
                    'Como contestar uma compra desconhecida no Cartão Riachuelo?',
                  value: `
                    <p>Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, você pode contestar uma compra desconhecida no Cartão Riachuelo.</p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417</li>
                      <li>Demais localidades: 0800 727 4417</li>
                    </ul>
                  `,
                },
                {
                  label: 'Como desbloquear o Cartão Riachuelo?',
                  value: `
                    <p>
                      No aplicativo Riachuelo (disponível para Android e iOS), realizando o vínculo do cartão, você pode desbloquear o seu Cartão Riachuelo.
                    </p>
                    <p>
                      Importante! Se você ainda não cadastrou o cartão no aplicativo Riachuelo, por segurança, será necessário realizar o vínculo de cartão.
                    </p>
                  `,
                },
                {
                  label: 'Como solicitar um cartão Riachuelo Master?',
                  value: `
                    <p>
                      No aplicativo Riachuelo (disponível para Android e iOS), realizando o vínculo do cartão de uso exclusivo na Riachuelo, você pode consultar se a mudança para o Cartão Riachuelo Visa ou Mastercard está pré-aprovada e solicitar a migração.
                    </p>
                    <p>
                      Importante! Se você ainda não cadastrou o cartão no aplicativo Riachuelo, por segurança, será necessário realizar o vínculo de cartão.
                    </p>
                  `,
                },
                {
                  label: 'Como pagar a fatura do Cartão Riachuelo?',
                  value: `
                    <ul>
                      <li>No site Conta Online, informando CPF e data de nascimento, você pode gerar e copiar o código de barras para pagamento da fatura fechada</li>
                      <li>Ou, no aplicativo Riachuelo (disponível para Android e iOS), realizando o vínculo do cartão, você pode gerar o boleto para pagamento da fatura fechada.</li>
                      <li>
                        Ou ainda, na loja Riachuelo mais próxima, informando o CPF ou usando o cartão, você pode pagar a fatura no caixa ou terminal de autoatendimento.
                        Importante! Se você ainda não cadastrou o cartão no aplicativo Riachuelo, por segurança, será necessário realizar o  vínculo de cartão.
                      </li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>

                  `,
                },
                {
                  label: 'Como parcelar a fatura do Cartão Riachuelo?',
                  value: `
                    <p>
                      No aplicativo Riachuelo (disponível para Android e iOS), realizando o vínculo do cartão, você pode parcelar a sua fatura do Cartão Riachuelo e gerar o boleto para pagamento da fatura fechada e parcelada.
                    </p>
                    <p>
                      Importante! Se você ainda não cadastrou o cartão no aplicativo Riachuelo, por segurança, será necessário realizar o vínculo de cartão.
                    </p>
                  `,
                },
                {
                  label: 'Como negociar a fatura do Cartão Riachuelo?',
                  value: `
                    <p>No site Mi Ajuda, informando CPF e data de nascimento, você pode consultar as opções de acordo e negociar a fatura do Cartão Riachuelo.</p>
                  `,
                },
                {
                  label: 'Como reduzir o limite do Cartão Riachuelo?',
                  value: `
                    <p>Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, você pode solicitar a redução do limite do Cartão Riachuelo.</p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417</li>
                      <li>Demais localidades: 0800 727 4417</li>
                    </ul>
                  `,
                },
                {
                  label: 'Como solicitar um Cartão Riachuelo?',
                  value: `
                    <ul>
                      <li> No aplicativo Riachuelo (disponível para Android e iOS), após entrar na sua conta informando CPF e senha, clique em cartões no barra inferior. Depois, é só clicar em Solicitar cartão e seguir as orientações;</li>
                      <li>Ou, na loja Riachuelo mais próxima, apresentando documento oficial com foto e CPF, você pode solicitar um Cartão Riachuelo no caixa ou pedir ajuda para uma pessoa atendente;</li>
                    </ul>

                    <p>
                      Para solicitar o Cartão Riachuelo Visa ou Mastercard, você precisa ter um Cartão Riachuelo de uso exclusivo na Riachuelo. Se você já tiver um cartão, no aplicativo Riachuelo, você pode consultar se a mudança está pré-aprovada e solicitar a migração para Visa ou Mastercard.
                    </p>

                    <p>
                      Importante! Se você ainda não cadastrou o cartão no aplicativo Riachuelo, por segurança, será necessário realizar o vínculo de cartão.
                    </p>
                  `,
                },
                {
                  label:
                    'Como fazer o vínculo de cartão no aplicativo Riachuelo?',
                  value: `
                    <p>
                      No aplicativo Riachuelo (disponível para Android e iOS), após entrar na sua conta informando CPF e senha, siga os seguintes passos:
                    </p>

                    <ol>
                      <li>Clique em Cartões no barra inferior;</li>
                      <li>Clique em Já possuo;</li>
                      <li>Clique em Tirar foto.</li>
                    </ol>

                    <p>
                      Após realizar a foto de segurança, conforme solicitado, o seu cartão estará vinculado e será possível consultar fatura, limite e outras informações do Cartão Riachuelo.
                    </p>
                  `,
                },
                {
                  label:
                    'Como proceder em caso de perda ou roubo do Cartão Riachuelo?',
                  value: `
                    <p>
                      Em caso de perda ou roubo do seu cartão Riachuelo, solicite o bloqueio do cartão e emissão de uma nova via através da Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h.
                    </p>

                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417;</li>
                      <li>Demais localidades: 0800 727 4417.</li>
                    </ul>

                    <p>
                      Você também pode bloquear seu cartão enviando um e-mail para online@riachuelo.com.br com o assunto "Bloqueio Perda ou Roubo". No corpo da mensagem, informe o nome completo e o CPF do titular do cartão, assim como o motivo do bloqueio. Seguem exemplos de textos:
                    </p>

                    <ul>
                      <li>Por gentileza bloquear o cartão do Titular(Informar o NOME e CPF do titular);</li>
                      <li>Por gentileza, bloquear o cartão Adicional de (Nome da pessoa que usa o cartão adicional) que está associado ao titular (Informar o NOME e CPF do titular).</li>
                    </ul>
                  `,
                },
              ],
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao?utm_source=pagina_ajuda_rchlo&utm_medium=FAQ_rchlo&utm_campaign=quitacao-de-divida',
                },
                {
                  title: 'Pedir Cartão RCHLO Mastercard',
                  url: 'https://www.midway.com.br/cartao-de-credito',
                },
              ],
            },
            {
              title: 'Cartão de Crédito Mastercard',
              isMainQuestions: false,
              questions: [
                {
                  label: 'Como encontrar o extrato do meu cartão?',
                  value: `
                    <p>
                      No aplicativo Midway, acesse a área de cartões ou clique em seu cartão na página inicial. Acesse o extrato do cartão através das últimas movimentações ou clicando em “Minhas faturas”. No aplicativo Riachuelo, acesse a área de cartões e encontre o extrato em últimas movimentações ou clicando em “Minhas faturas”. Não se esqueça de adicionar o seu cartão para que essa função esteja disponível!
                    </p>
                  `,
                },
                {
                  label: 'Como pagar a fatura do Cartão Riachuelo Mastercard?',
                  value: `
                    <ul>
                      <li>No site e aplicativo Midway, informando CPF e data de nascimento, você pode gerar e copiar o código de barras para pagamento da fatura fechada.</li>
                      <li>No aplicativo Riachuelo, realizando o vínculo do cartão, você pode gerar o boleto para pagamento da fatura fechada.</li>
                      <li>Na Loja Riachuelo mais próxima, informando o CPF ou usando o cartão, você pode pagar a fatura no caixa ou terminal de autoatendimento.</li>
                    </ul>
                  `,
                },
                {
                  label:
                    'Como parcelar a fatura do Cartão Riachuelo Mastercard?',
                  value: `
                    <p>
                      Parcelar a fatura do seu Cartão Riachuelo é fácil! Entre na área de cartões nos aplicativos da Midway ou Riachuelo. Ao acessar a sua fatura, você pode escolher a opção de parcelamento para efetuar o pagamento. Também é possível fazer o parcelamento em qualquer Loja Riachuelo.
                    </p>
                  `,
                },
                {
                  label: 'Como alterar o vencimento da minha fatura?',
                  value: `
                    <p>
                      Você pode solicitar a alteração do vencimento da sua fatura em qualquer Loja Riachuelo ou ligando em nossa central de atendimento.
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Cartão de Débito',
              isMainQuestions: false,
              questions: [
                {
                  label: 'Como solicitar meu cartão de débito?',
                  value: `
                    <p>
                      Para solicitar o seu cartão de débito, é necessário ter uma Conta digital Midway. Com a conta criada, na página inicial do aplicativo, é possível solicitar o seu cartão após realizar um depósito a partir de R$ 20,00 em sua nova conta. Lembre-se, o valor depositado ficará disponível para usar como quiser.
                    </p>
                  `,
                },
                {
                  label: 'Como desbloquear meu cartão?',
                  value: `
                    <p>
                      Você pode desbloquear o seu cartão no aplicativo Midway. Ao acessar a página inicial, quando o seu cartão for entregue, aparecerá a opção de desbloqueio! Siga o passo-a-passo e desbloqueie o seu novo cartão de débito!
                    </p>
                  `,
                },
                {
                  label: 'Qual o prazo de entrega do meu cartão?',
                  value: `
                    <p>
                      O prazo de entrega é de até 7 dias úteis e você pode acompanhar o envio no aplicativo Midway. Ao acessar a página inicial, clique na opção 'Acompanhar o envio' e encontre mais informações sobre a entrega do cartão.
                    </p>
                  `,
                },
                {
                  label:
                    'Perdi o meu cartão, foi roubado ou extraviado, e agora?',
                  value: `
                    <p>
                      Solicite o bloqueio do cartão e emissão de uma nova via através da Central de Atendimento. Você também pode bloquear seu cartão enviando um e-mail para online@riachuelo.com.br com o assunto 'Bloqueio Perda ou Roubo'. No corpo da mensagem, informe o nome completo e o CPF do titular do cartão, assim como o motivo do bloqueio.
                    </p>
                  `,
                },
              ],
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao?utm_source=pagina_ajuda_rchlo&utm_medium=FAQ_rchlo&utm_campaign=quitacao-de-divida',
                },
                {
                  title: 'Pedir Cartão RCHLO Mastercard',
                  url: 'https://www.midway.com.br/cartao-de-credito',
                },
              ],
            },
            {
              title: 'Migração de cartão Mastercard',
              isMainQuestions: true,
              questions: [
                {
                  label: 'Não recebi a senha. O que eu faço?',
                  value: `
                    <p>
                      Para gerar uma nova senha, é necessário ir até a loja Riachuelo mais próxima. No balcão de atendimento, você pode solicitar uma senha provisória via aplicativo Riachuelo ou Central de Relacionamento. Essa senha deverá ser informada ao atendente e realizar o processo de criação de nova senha.
                    </p>
                  `,
                },
                {
                  label: 'A minha fatura permanece igual?',
                  value: `
                    <p>
                      Sim. Não temos alteração de fatura.
                    </p>
                  `,
                },
                {
                  label:
                    'Por que meu VISA está sendo trocado se o meu cartão está longe de vencer?',
                  value: `
                    <p>
                      A bandeira VISA deixará de ser emitida pelo Cartão Riachuelo e por isso todos os cartões passarão a ser da bandeira Mastercard.
                    </p>
                  `,
                },
                {
                  label:
                    'Como ter certeza de que não corro o risco de cair em um caso de fraude?',
                  value: `
                    <p>
                      Sim, a inadimplência no cartão de crédito Riachuelo torna o cliente inelegível para a contratação do Empréstimo.
                      Gostaria de confirmar a veracidade do SMS/e-mail.
                      Realizamos comunicação sobre a troca da bandeira do cartão através de E-mail, SMS e PUSH no App.
                    </p>
                  `,
                },
                {
                  label: 'A senha do cartão é a mesma?',
                  value: `
                    <p>
                      Será encaminhada uma nova senha via SMS em até 3 dias após a emissão do cartão Master. Sendo possível também cadastrar uma nova senha no App.
                    </p>
                  `,
                },
                {
                  label: 'O limite do cartão será o mesmo?',
                  value: `
                    <p>
                      Sim, o limite permanece o mesmo. Caso tenha interesse em um aumento poderá solicitar via App
                    </p>
                  `,
                },
                {
                  label: 'Por que o Visa está bloqueado no App?',
                  value: `
                    <p>
                      O cartão Visa não está bloqueado e poderá ser utilizado para compras até o desbloqueio do novo cartão Master.
                    </p>
                  `,
                },
                {
                  label: 'Qual o prazo de recebimento do cartão?',
                  value: `
                    <p>
                      Até sete dias uteis.
                    </p>
                  `,
                },
                {
                  label: 'Quais os benefícios do cartão Master?',
                  value: `
                    <p>
                      Todos os benefícios poderão ser consultados através do Surpreenda Mastercard.
                    </p>
                  `,
                },
                {
                  label: 'Como vai ficar a anuidade?',
                  value: `
                    <p>
                      A alteração da bandeira do cartão não muda o valor e cobrança da anuidade.
                    </p>
                  `,
                },
              ],
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao?utm_source=pagina_ajuda_rchlo&utm_medium=FAQ_rchlo&utm_campaign=quitacao-de-divida',
                },
                {
                  title: 'Pedir Cartão RCHLO Mastercard',
                  url: 'https://www.midway.com.br/cartao-de-credito',
                },
              ],
            },
          ],
          links: [
            {
              title: 'Pagar fatura (linha digitável)',
              url: 'https://www.midway.com.br/consulta-boleto',
            },
            {
              title: 'Negociar dívidas',
              url: 'https://www.midway.com.br/campanha-quitacao?utm_source=pagina_ajuda_rchlo&utm_medium=FAQ_rchlo&utm_campaign=quitacao-de-divida',
            },
            {
              title: 'Pedir Cartão RCHLO Mastercard',
              url: 'https://www.midway.com.br/cartao-de-credito',
            },
          ],
        },
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/attendance-portal-orders-min.jpeg',
        icon: `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_879_2928)">
            <path d="M6 21C7.10457 21 8 20.1046 8 19C8 17.8954 7.10457 17 6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21Z" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21Z" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17 17H6V3H4" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 5L20 6L19 13H6" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_879_2928">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        `,
        title: 'Pedidos',
        contacts: {
          wpp: null,
          list: [
            {
              title: 'Atendimento Loja Online',
              subTitle:
                'De segunda à sexta, das 8h às 22h, e aos sábados, das 8h às 18h (exceto feriados nacionais)',
              items: [
                {
                  type: 'phone',
                  label: '4003 0515',
                  value:
                    '<p>Capitais e Regiões Metropolitanas (apenas ligações)</p>',
                },
                {
                  type: 'phone',
                  label: '0800 729 0515',
                  value: '<p>Demais localidades</p>',
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: '<p>Atendimento 24h para Deficientes Auditivos</p>',
                },
              ],
            },
            {
              title: 'Lojas físicas',
              subTitle: 'De segunda à sábado, das 8h às 22h.',
              items: [
                {
                  type: 'phone',
                  label: '0800 721 4033',
                  value: `
                    <p>
                      Atendimento
                    </p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: `
                    <p>
                      Atendimento 24h para Deficientes Auditivos
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Ouvidoria',
              subTitle: 'De segunda à sexta, das 10h às 16h (exceto feriados).',
              items: [
                {
                  type: 'phone',
                  label: '0800 727 3255',
                  value: `
                    <p>
                      É preciso informar o número do protocolo registrado na Central de Atendimento.
                    </p>

                    <p>
                      A Ouvidoria (Resolução CMN 4.860/2020) atua em última instância nos assuntos financeiros relacionados à Midway, tratados anteriormente sem solução satisfatória.
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Whatsapp de negociação',
              subTitle: null,
              items: [
                {
                  type: 'phone',
                  label: '(11) 3003 0950',
                  value:
                    '<p>Adicione o número em sua lista de contatos e negocie sua dívida via Whatsapp</p>',
                },
              ],
            },
          ],
        },
        links: [
          {
            title: 'Redefinir senha',
            url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
          },
          {
            title: 'Alterar dados cadastrais',
            url: 'https://www.riachuelo.com.br/cliente',
          },
          {
            title: 'Pedidos',
            url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
          },
        ],
        subCategories: {
          feedback: {
            yes: {
              description: '<p>Ficamos felizes em ajudar!</p>',
            },
            no: {
              description: `
                <p>Sentimos muito! Que tal entrar em contato através dos nossos outros canais.</p>
                <p>De segunda à sexta, das 8h às 22h, e aos sábados, das 8h às 18h (exceto feriados nacionais):</p>
              `,
              contacts: [
                {
                  type: 'phone',
                  label: '4003 0515',
                  value: `
                    <p>Capitais e Regiões Metropolitanas <br /> (apenas ligações)</p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 729 0515',
                  value: `
                    <p>Demais localidades</p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: `
                    <p>Atendimento 24h para Deficientes Auditivos</p>
                  `,
                },
              ],
            },
          },
          list: [
            {
              title: 'Meus pedidos',
              isMainQuestions: true,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label:
                    'O que fazer quando meu pedido consta entregue, mas não recebi?',
                  value: `
                    <p>Você pode verificar se o pedido foi entregue para porteiros, vizinhos ou familiares.</p>

                    <p>
                      A transportadora pode registrar o pedido como "Entregue" de forma acidental, quando, na verdade, ele ainda está em trânsito a sua residência. Nesse cenário, você pode receber o seu pedido no próximo dia útil.
                    </p>

                    <p>
                      Mesmo após essas orientações, se o seu pedido não for localizado/entregue, entre em contato com a Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h. Lembrando que, o contato deve ser em até 15 dias corridos a partir da data de atualização de entrega do pedido.
                    </p>

                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417</li>
                      <li>Demais localidades: 0800 727 4417</li>
                    </ul>
                  `,
                },
                {
                  label: 'O que fazer quando a embalagem está violada?',
                  value: `
                    <p>Recuse a entrega nas seguintes situações:</p>

                    <ul>
                      <li>Embalagem violada, aberta ou avariada;</li>
                      <li>Produto avariado;</li>
                      <li>Produto em desacordo com o pedido;</li>
                      <li>Falta de acessórios;</li>
                    </ul>

                    <p>Se a entrega foi aceita, você pode solicitar a troca ou a devolução do pedido.</p>
                    <p>Lamentamos que você tenha recebido o seu pedido danificado ou violado.</p>
                  `,
                },
                {
                  label: 'Qual é o prazo de retirada em loja?',
                  value: `
                    <p>O pedido permanece disponível para retirada em loja por 15 dias corridos.</p>
                    <p>
                      Caso opte pela não retirada do pedido, o ressarcimento acontece automaticamente da seguinte forma, de acordo com o método de pagamento:
                    </p>

                    <ul>
                      <li>Vale-troca, boleto ou cartão presente: ressarcimento em vale-troca, em até 30 dias corridos, a contar da data de chegada do pedido a loja;</li>
                      <li>PIX: estorno na conta vinculada ao PIX em até 30 dias corridos, a contar da data de chegada do pedido a loja;</li>
                      <li>Cartão Riachuelo ou cartão externo: estorno no cartão (poderá visualizar o ressarcimento em até duas faturas).</li>
                    </ul>

                    <p>
                      Importante! Se a Nota Fiscal foi emitida, não será possível corrigir o número e/ou complemento.
                    </p>
                  `,
                },
                {
                  label: 'O que fazer quando meu pedido está atrasado?',
                  value: `
                    <p>
                      No site ou no aplicativo Riachuelo (disponível para Android e iOS), acessando a sua conta e clicando em “Meus pedidos”, você pode consultar se houve atualização na previsão de entrega.
                      Se ainda precisar de ajuda, entre em contato com a Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h.
                    </p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417</li>
                      <li>Demais localidades: 0800 727 4417</li>
                    </ul>
                  `,
                },
                {
                  label:
                    'É possível alterar o endereço de entrega de um pedido?',
                  value: `
                    <p>Não é possível alterar o endereço de entrega ou a loja de retirada após o fechamento do pedido.</p>
                    <p>
                      Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, informando o número do pedido, você pode consultar a possibilidade de correção do número e/ou complemento do endereço.
                    </p>

                    <ul>
                      <li>Capitais e regiões metropolitanas: 4003 0515</li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>

                    <p>
                      Importante! Se a Nota Fiscal foi emitida, não será possível corrigir o número e/ou complemento.
                    </p>
                  `,
                },
                {
                  label: 'Como solicitar a Nota Fiscal do pedido?',
                  value: `
                    <p>No seu e-mail, você receberá a Nota Fiscal do pedido, assim que ela estiver disponível.</p>
                    <p>
                      Se não conseguiu localizar a nota fiscal em seu e-mail, você poderá obtê-la em sua conta no site Riachuelo. Após acessar a opção "Meus Pedidos", você deve:
                    </p>

                    <ol>
                      <li>Clicar em "Ver pedido";</li>
                      <li>Depois clique no ícone "Nota Fiscal" (caso possua mais de um pacote, no mesmo pedido, é preciso selecionar um deles para visualizar o ícone da nota fiscal).</li>
                    </ol>
                  `,
                },
              ],
            },
            {
              title: 'Trocas',
              isMainQuestions: true,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label: 'Como trocar uma compra realizada nas lojas físicas?',
                  value: `
                    <p>Na loja Riachuelo mais próxima, apresentando documento oficial com foto, CPF e nota fiscal, você pode realizar a troca direto no caixa.</p>
                    <p>Importante! A troca pode ser solicitada em até 30 (trinta) dias corridos após a compra.</p>
                  `,
                },
                {
                  label:
                    'Como trocar uma compra realizada no site ou aplicativo Riachuelo?',
                  value: `
                    <p>Na loja Riachuelo mais próxima, apresentando documento oficial com foto, CPF e nota fiscal, você pode realizar a troca direto no caixa.</p>
                    <p>
                      Não permitem troca:
                      <ul>
                        <li>Calçados e relógios com sinais de uso;</li>
                        <li>Peças íntimas e moda praia;</li>
                        <li>Perfumes e cosméticos sem o lacre original do fabricante.</li>
                      </ul>
                    </p>

                    <p>Importante:</p>
                    <p>
                      A troca pode ser solicitada em até 30 (trinta) dias corridos após a entrega do pedido;
                      Para produtos vendidos/entregues por lojas parceiras, não é possível realizar a troca nas lojas físicas Riachuelo.
                    </p>

                    <p>Você pode solicitar através da nossa Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h.</p>

                    <ul>
                      <li>Capitais e regiões metropolitanas: 4003 0515</li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>
                  `,
                },
                {
                  label: 'Como trocar um produto com defeito ou danificado?',
                  value: `
                    <p>
                      Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, informando o número do pedido e enviando foto do produto conforme as orientações durante o atendimento, você pode realizar a troca por vale-troca via postagem nos Correios.
                    </p>
                    <p>
                      <ul>
                        <li>Capitais e regiões metropolitanas: 4003 0515</li>
                        <li>Demais localidades: 0800 729 0515</li>
                      </ul>
                    </p>

                    <p>Importante:</p>
                    <ol>
                      <li>A troca pode ser solicitada até 90 dias após a entrega ou retirada em loja do pedido;</li>
                      <li>Para produtos vendidos e entregues por lojas parceiras, não é possível realizar a troca nas lojas físicas Riachuelo.</li>
                    </ol>
                    <p>
                      Lamentamos que você tenha recebido o seu pedido danificado ou violado.
                    </p>
                  `,
                },
                {
                  label:
                    'Como trocar eletroportáteis e eletrônicos (smartphones, tablets, som e acessórios)?',
                  value: `
                    <p>
                      Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, informando o número do pedido, você pode realizar a troca por vale-troca via postagem nos Correios.
                    </p>

                    <p>
                      <ul>
                        <li>Capitais e regiões metropolitanas: 4003 0515</li>
                        <li>Demais localidades: 0800 729 0515</li>
                      </ul>
                    </p>

                    <p>
                      Importante! A troca pode ser solicitada até 07 dias após a entrega ou retirada em loja do pedido. Eletroportáteis e eletrônicos não podem ser trocados nas lojas físicas.
                    </p>

                    <p>
                      Em caso de avarias ou defeitos constatados após 7 dias corridos da data da entrega ou retirada em loja, orientamos a busca por uma assistência técnica autorizada. Será permitida a devolução do produto caso ele permaneça na assistência técnica, sem a solução do problema, por mais de 30 dias. Neste caso, você deverá solicitar a troca via postagem nos Correios, desde que haja laudo da vistoria técnica.
                    </p>
                  `,
                },
                {
                  label: 'Como trocar relógios, perfumes e comésticos?',
                  value: `
                    <p>
                      Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, informando o número do pedido, você pode realizar a troca por vale-troca via postagem nos Correios.
                    </p>

                    <p>
                      Importante!
                      <ol>
                        <li>A troca pode ser solicitada até 7 dias após a entrega ou retirada em loja do pedido;</li>
                        <li>Para produtos vendidos e entregues por lojas parceiras, não é possível realizar a troca nas lojas físicas Riachuelo. O mesmo vale para os os pedidos vendidos e entregues pela Riachuelo.</li>
                      </ol>
                    </p>

                    <p>Não permitem troca:</p>
                    <ul>
                      <li>Produtos vendidos/entregues por loja parceira nas lojas físicas Riachuelo;</li>
                      <li>Perfumes e cosméticos sem o lacre original do fabricante.</li>
                    </ul>

                    <p>Para reclamações e trocas relacionadas à qualidade da perfumaria, entre em contato com o fornecedor de acordo com os telefones que constam na embalagem do produto.</p>
                  `,
                },
                {
                  label:
                    'É possível trocar um produto comprado em um lojista parceiro?',
                  value: `
                    <p>
                    Para produtos vendidos/entregues por parceiros da Riachuelo, a troca somente será permitida em caso de vício ou defeito do item. Nessas situações você poderá nos acionar através dos nossos canais de atendimento e junto ao nosso parceiro veremos a melhor forma de atendê-lo.
                    </p>
                    <p>Nossa Central de Atendimento funciona de segunda a sábado (exceto feriados), das 8h às 22h.</p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 4003 0515</li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>
                  `,
                },
              ],
            },
            {
              title: 'Vale-troca',
              isMainQuestions: false,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label: 'Como utilizar um vale-troca?',
                  value: `
                    <p>Na loja Riachuelo mais próxima, apresentando documento oficial com foto e CPF, você pode informar o código do vale-troca no caixa.</p>
                    <p>No site ou aplicativo Riachuelo (disponível para Android e iOS), você pode informar o código do vale-troca no fechamento do pedido.</p>
                    <p>Importante! O vale-troca é vinculado e restrito ao seu CPF. Se o valor da compra for superior ao vale-troca, você pode pagar a diferença. Caso seja inferior, o saldo permanecerá no seu CPF.</p>
                  `,
                },
                {
                  label: 'Como consultar o saldo de um vale-troca?',
                  value: `
                    <p>No site ou no aplicativo Riachuelo (disponível para Android e iOS), acessando a sua conta e clicando em “Meus vales troca”, você pode acompanhar os seus cupons:</p>
                    <p>Ativos - com o saldo disponível para uso + código para usar na sacola. Se você estiver na loja, é só realizar a leitura do código de barras no caixa, ao acessar pelo celular.</p>
                    <p>Usados - com o saldo total que tinha no cartão e o código do vale já utilizado.</p>
                    <p>Importante: só é possível utilizar o vale-troca se a compra for realizada em sua conta, vinculada ao CPF.</p>
                  `,
                },
                {
                  label: 'O que fazer quando o vale-troca está inválido?',
                  value: `
                    <p>Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, informando o número do pedido, você pode solicitar suporte sobre vale-troca inválido.</p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 4003 0515</li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>
                  `,
                },
              ],
            },
            {
              title: 'Devoluções',
              isMainQuestions: true,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label:
                    'É possível devolver pelo site uma compra realizada nas lojas físicas?',
                  value: `
                    <p>Não é possível devolver uma compra realizada no site, nas lojas físicas. Nesse caso, você pode realizar a troca em qualquer loja Riachuelo em até 30 (trinta) dias corridos após a compra.</p>
                  `,
                },
                {
                  label:
                    'Como devolver uma compra realizada no site ou aplicativo Riachuelo?',
                  value: `
                    <p>
                      Para compras realizadas no site, você pode solicitar a devolução clicando na opção "Quero devolver" em "Meus Pedidos" em até 7 (sete) dias corridos após a entrega.
                    </p>
                    <p>
                      Após confirmação do pedido de devolução, você vai receber por e-mail o código de postagem e deve postar o(s) produto(s) em até 10 (dez) dias corridos. Assim que o produto chegar em nosso Centro de Distribuição, ele vai passar por análise de qualidade. Se a análise de qualidade for positiva, o ressarcimento será realizado conforme a forma de pagamento do pedido. Se a análise de qualidade for negativa (Ex: recusa por mau uso), o produto será devolvido para o endereço de entrega, sem comunicação prévia.
                    </p>
                    <p>Não permitem devolução:</p>
                    <ul>
                      <li>Calçados e relógios com sinais de uso;</li>
                      <li>Peças íntimas e moda praia;</li>
                      <li>Perfumes e cosméticos sem o lacre original do fabricante.</li>
                    </ul>
                  `,
                },
                {
                  label:
                    'Quais são as formas de ressarcimento no caso de devolução?',
                  value: `
                    <p>
                      O ressarcimento da devolução será de acordo com a forma de pagamento do pedido:
                    </p>
                    <ul>
                      <li>Cartão de Riachuelo ou demais cartões de crédito: após a aprovação da análise de qualidade no centro de distribuição, o lançamento do estorno poderá ser visualizado em até 2 faturas, de acordo com a data de fechamento do seu cartão.</li>
                      <li>Cartão presente ou vale-troca: em até 05 (cinco) dias úteis após a aprovação da análise de qualidade no centro de distribuição.</li>
                      <li>PIX: estorno na conta utilizada para o PIX em até 05 (cinco) dias úteis após a aprovação da análise de qualidade no centro de distribuição.</li>
                    </ul>
                  `,
                },
                {
                  label:
                    'Como devolver eletroportáteis e eletrônicos (smartphones, tablets, som e acessórios)?',
                  value: `
                    <p>
                      Para compras realizadas no e-commerce, você pode solicitar a devolução clicando na opção "Quero devolver" em "Meus Pedidos" em até 7 (sete) dias corridos após a entrega. Não é possível devolver nas lojas físicas.
                    </p>
                    <p>Após 7 (sete) dias corridos da data da entrega, em caso de avarias ou defeitos, orientamos a busca por uma assistência técnica autorizada. Será permitida a devolução do produto caso ele permaneça na assistência técnica, sem a solução do problema, por mais de 30 (trinta) dias. Neste caso, você deverá solicitar a troca via postagem nos Correios, desde que haja laudo da vistoria técnica.</p>
                  `,
                },
                {
                  label: 'Como devolver relógios, perfumes e cosméticos?',
                  value: `
                    <p>
                      Para compras realizadas no E-commerce, você pode solicitar a devolução clicando na opção "Quero devolver" em "Meus Pedidos" em até 7 (sete) dias corridos após a entrega.
                    </p>
                    <p>O processo de devolução será o mesmo das demais categorias.</p>
                    <p>Não é permitido a devolução de perfumes e cosméticos sem o lacre original do fabricante. Se ainda assim o produto for enviado, ele será devolvido para o endereço de entrega, sem comunicação prévia.</p>
                    <p>Para reclamações e devoluções relacionadas à qualidade da perfumaria, entre em contato com o fornecedor através dos telefones que constam na embalagem do produto.</p>
                  `,
                },
                {
                  label:
                    'É possível devolver uma compra realizada em loja parceira?',
                  value: `
                    <p>
                      Sim, você pode devolver uma compra realizada pelo E-commerce dos produtos vendidos/entregues por loja parceira. Não é possível devolver em lojas físicas.
                    </p>
                    <p>A devolução pode ser solicitada em até 7 (sete) dias corridos após a entrega através da nossa Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h.</p>

                    <ul>
                      <li>Capitais e regiões metropolitanas: 4003 0515</li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>

                    <p>
                      Após confirmação do pedido de devolução, você receberá por e-mail o código de postagem e deverá postar o(s) produto(s) em até 10 (dez) dias corridos. Assim que o produto chegar no Centro de Distribuição da loja parceira, ele passará por análise de qualidade. Se a análise de qualidade for positiva, o ressarcimento será realizado conforme a forma de pagamento do pedido. Se a análise de qualidade for negativa (ex: recusa por mau uso), o produto será devolvido para o endereço de entrega, sem comunicação prévia.
                    </p>

                    <p>Não permitem devolução:</p>
                    <ul>
                      <li>Calçados e relógios com sinais de uso;</li>
                      <li>Peças íntimas e moda praia;</li>
                      <li>Perfumes e cosméticos sem o lacre original do fabricante;</li>
                    </ul>
                  `,
                },
              ],
            },
            {
              title: 'Cancelamentos',
              isMainQuestions: false,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label: 'Como cancelar uma compra?',
                  value: `
                    <p>No momento da entrega, você pode recusar o recebimento do pedido ou devolvê-lo em até 7 dias. Na modalidade retira em loja, a compra será cancelada se não for retirada em até 15 dias.</p>
                    <p>Se precisar de ajuda sobre ressarcimento (reembolso), entre em contato com a Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h.</p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 4003 0515</li>
                      <li>Demais localidades: 0800 729 0515</li>
                    </ul>
                  `,
                },
              ],
            },
            {
              title: 'Ajuda para comprar',
              isMainQuestions: true,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label: 'Como utilizar um Cartão Presente?',
                  value: `
                    <p>No fechamento do pedido, ainda na etapa de "Entrega e pagamento", você pode clicar em Cartão Presente e incluir o número e senha, conforme soliticitado.</p>
                  `,
                },
                {
                  label: 'Quais são as opções de entrega?',
                  value: `
                    <p>No site ou no aplicativo Riachuelo (disponível para Android e iOS), você tem as seguintes opções de entrega do pedido:</p>
                    <ul>
                      <li>Retirar em loja (sem frete);</li>
                      <li>Normal (frete mais econômico e maior prazo de entrega);</li>
                      <li>Expressa (frete menos econômico e menor prazo de entrega);</li>
                      <li>Agendada (com seleção da data e horário - disponível apenas para a cidade de São Paulo).</li>
                    </ul>
                  `,
                },
                {
                  label: 'Como utilizar um cupom de desconto?',
                  value: `
                    <ul>
                      <li>No site ou aplicativo Riachuelo (disponível para Android e iOS), informe o código do cupom de desconto no fechamento do pedido.</li>
                    </ul>
                    <p>Importante! Os cupons não são válidos nas lojas físicas. Além disso, são sujeitos à validade e aplicação em departamentos e produtos específicos. Consulte as promoções e regulamentos.</p>
                  `,
                },
                {
                  label: 'Como consultar o prazo de entrega?',
                  value: `
                    <p>No site ou no aplicativo Riachuelo (disponível para Android e iOS), informando o CEP de entrega na sacola ou fechamento do pedido, você pode consultar o prazo de entrega para o endereço desejado.</p>
                  `,
                },
                {
                  label:
                    'Como consultar se há disponibilidade de entrega para o CEP desejado?',
                  value: `
                    <p>No site ou no aplicativo Riachuelo (disponível para Android e iOS), informando o CEP de entrega na sacola ou fechamento do pedido, você pode consultar se a entrega está disponível para o endereço desejado.</p>
                  `,
                },
                {
                  label: 'Quais são as formas de pagamento?',
                  value: `
                    <p>No site ou no aplicativo Riachuelo (disponível para Android e iOS), você pode pagar com PIX, Cartão Riachuelo, vale-troca, Cartão Presente e demais Cartões de Crédito.</p>
                  `,
                },
                {
                  label: 'É possível solicitar embalagem para presente?',
                  value: `
                    <p>Não é possível solicitar embalagem para presente nas compras realizadas no site ou aplicativo Riachuelo.</p>
                  `,
                },
                {
                  label: 'Quais são os horários de entrega?',
                  value: `
                    <p>No site ou no aplicativo Riachuelo (disponível para Android e iOS), você tem as seguintes opções de horário de entrega:</p>
                    <ul>
                      <li>Nas modalidades Retire rápido e Retira em loja, a retirada pode ser realizada de acordo com o horário de funcionamento da loja escolhida</li>
                      <li>Nas modalidades Normal ou Expressa, a entrega é realizada em horário comercial, de segunda à sexta, das 8h às 18h.</li>
                      <li>Na modalidade Agendada (disponível apenas para a cidade de São Paulo), você pode escolher o período manhã - das 8h às 12h - ou tarde - das 13h às 18h.</li>
                    </ul>
                  `,
                },
              ],
            },
            {
              title: 'Cadastro',
              isMainQuestions: false,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label: 'Como criar uma conta?',
                  value: `
                    <p>Para criar uma conta no site, siga os seguintes passos:</p>
                    <ol>
                      <li>Preencha o CPF, crie e confirme a senha.</li>
                      <li>Clique em Continuar.</li>
                      <li>Preencha os seus dados e clique em Finalizar meu cadastro.</li>
                    </ol>
                  `,
                },
                {
                  label: 'Como redefinir a senha do site Riachuelo?',
                  value: `
                    <p>No site, preencha o CPF e clique em Continuar. Você receberá um link no seu e-mail. Confira o SPAM!</p>
                    <ol>
                      <li>No e-mail, clique em Definir uma nova senha.</li>
                      <li>Preencha a nova senha, conforme solicitado;</li>
                      <li>Clique em Salvar.</li>
                    </ol>
                  `,
                },
                {
                  label:
                    'No aplicativo Riachuelo (disponível para Android e iOS), siga os seguintes passos:',
                  value: `
                    <ol>
                      <li>Na tela inicial, clique em conta;</li>
                      <li>Clique em Redefinição de senha;</li>
                      <li>Clique em Redefinir senha;</li>
                      <li>Clique em Entrar / Criar conta;;</li>
                      <li>Clique em Entrar com CPF;</li>
                      <li>Clique em Esqueci minha senha;</li>
                      <li>Preencha o CPF e clique em Redefinir senha.</li>
                    </ol>

                    <p>Você receberá um link no seu e-mail. Confira o SPAM!</p>

                    <ol>
                      <li>No e-mail, clique em Definir uma nova senha;</li>
                      <li>Preencha a nova senha, conforme solicitado;</li>
                      <li>Clique em Salvar;</li>
                    </ol>
                  `,
                },
              ],
            },
            {
              title: 'Cartão RCHLO',
              isMainQuestions: false,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label:
                    'Como contestar uma compra desconhecida no Cartão Riachuelo?',
                  value: `
                    <p>Na Central de Atendimento, de segunda a sábado (exceto feriados), das 8h às 22h, você pode contestar uma compra desconhecida no Cartão Riachuelo.</p>
                    <ul>
                      <li>Capitais e regiões metropolitanas: 3004 5417</li>
                      <li>Demais localidades: 0800 727 4417</li>
                    </ul>
                  `,
                },
              ],
            },
            {
              title: 'PIX',
              isMainQuestions: false,
              links: [
                {
                  title: 'Redefinir senha',
                  url: 'https://www.riachuelo.com.br/cliente/esqueceu-senha',
                },
                {
                  title: 'Alterar dados cadastrais',
                  url: 'https://www.riachuelo.com.br/cliente',
                },
                {
                  title: 'Pedidos',
                  url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
                },
              ],
              questions: [
                {
                  label:
                    'Como pedir o ressarcimento de um pedido pago com Pix?',
                  value: `
                    <p>
                      Para compras realizadas no e-commerce, você pode solicitar a devolução através da opção "Quero devolver" em "Meus Pedidos" em até 7 (sete) dias corridos após a entrega. Ao realizar a devolução do pedido, o valor será estornado na conta à qual a chave PIX está vinculada, em até 5 dias úteis após o recebimento do produto em nosso Centro de Distribuição.
                    </p>
                  `,
                },
              ],
            },
          ],
        },
      },
      {
        img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/attendance-portal-account-min.jpeg',
        icon: `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_879_4307)">
            <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.8 8.99918C14.6188 8.68494 14.3557 8.42588 14.0386 8.24973C13.7215 8.07358 13.3625 7.98698 13 7.99918H11C10.4696 7.99918 9.96086 8.20989 9.58579 8.58497C9.21071 8.96004 9 9.46875 9 9.99918C9 10.5296 9.21071 11.0383 9.58579 11.4134C9.96086 11.7885 10.4696 11.9992 11 11.9992H13C13.5304 11.9992 14.0391 12.2099 14.4142 12.585C14.7893 12.96 15 13.4687 15 13.9992C15 14.5296 14.7893 15.0383 14.4142 15.4134C14.0391 15.7885 13.5304 15.9992 13 15.9992H11C10.6375 16.0114 10.2785 15.9248 9.96142 15.7486C9.64435 15.5725 9.38115 15.3134 9.2 14.9992" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 16V18M12 6V8V6Z" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.5 17L3 17L3 21L21 21L21 17L20 17" stroke="#221F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_879_4307">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        `,
        title: 'Conta Midway',
        contacts: {
          wpp: null,
          list: [
            {
              title: 'Atendimento Conta Digital',
              subTitle: 'De segunda à domingo, das 7h às 20h.',
              items: [
                {
                  type: 'phone',
                  label: '3003 7729',
                  value: 'Capitais e Regiões Metropolitanas (apenas ligações)',
                },
                {
                  type: 'phone',
                  label: '0800 664 3929',
                  value: 'Demais localidades',
                },
                {
                  type: 'phone',
                  label: '0800 724 3929',
                  value: 'SAC 24h',
                },
                {
                  type: 'email',
                  label: 'atendimento@midway.com.br',
                  value:
                    'Para clientes no exterior, entrar em contato através do e-mail',
                },
              ],
            },
            {
              title: 'Lojas físicas',
              subTitle: 'De segunda à sábado, das 8h às 22h.',
              items: [
                {
                  type: 'phone',
                  label: '0800 721 4033',
                  value: `
                    <p>
                      Atendimento
                    </p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: `
                    <p>
                      Atendimento 24h para Deficientes Auditivos
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Ouvidoria',
              subTitle: 'De segunda à sexta, das 10h às 16h (exceto feriados).',
              items: [
                {
                  type: 'phone',
                  label: '0800 727 3255',
                  value: `
                    <p>
                      É preciso informar o número do protocolo registrado na Central de Atendimento.
                    </p>

                    <p>
                      A Ouvidoria (Resolução CMN 4.860/2020) atua em última instância nos assuntos financeiros relacionados à Midway, tratados anteriormente sem solução satisfatória.
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Whatsapp de negociação',
              subTitle: null,
              items: [
                {
                  type: 'phone',
                  label: '(11) 3003 0950',
                  value:
                    '<p>Adicione o número em sua lista de contatos e negocie sua dívida via Whatsapp</p>',
                },
              ],
            },
          ],
        },
        links: [
          {
            title: 'Pagar fatura (linha digitável)',
            url: 'https://www.midway.com.br/consulta-boleto',
          },
          {
            title: 'Negociar dívidas',
            url: 'https://www.midway.com.br/campanha-quitacao',
          },
          {
            title: 'Produtos e serviços financeiros',
            url: 'https://www.midway.com.br/produtos-financeiros',
          },
        ],
        subCategories: {
          feedback: {
            yes: {
              description: '<p>Ficamos felizes em ajudar!</p>',
            },
            no: {
              description: `
                <p>Sentimos muito! Que tal entrar em contato através dos nossos outros canais.</p>
                <p>De segunda à sexta, das 8h às 22h, e aos sábados, das 8h às 18h (exceto feriados nacionais):</p>
              `,
              contacts: [
                {
                  type: 'phone',
                  label: '4003 0515',
                  value: `
                    <p>Capitais e Regiões Metropolitanas <br /> (apenas ligações)</p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 729 0515',
                  value: `
                    <p>Demais localidades</p>
                  `,
                },
                {
                  type: 'phone',
                  label: '0800 722 0626',
                  value: `
                    <p>Atendimento 24h para Deficientes Auditivos</p>
                  `,
                },
              ],
            },
          },
          list: [
            {
              title: 'Conta Digital Midway',
              isMainQuestions: false,
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao',
                },
                {
                  title: 'Produtos e serviços financeiros',
                  url: 'https://www.midway.com.br/produtos-financeiros',
                },
              ],
              questions: [
                {
                  label: 'Como abrir uma Conta Digital Midway?',
                  value: `
                    <p>Para abrir uma Conta Digital Midway, é simples! Baixe o aplicativo Midway (disponível para Android e iOS), abra o aplicativo em seu dispositivo, insira o seu CPF e faça o cadastro inserindo algumas informações solicitadas. Após realizar todo o processo, sua conta será ativada e você poderá aproveitar todos os benefícios como: Pix ilimitado, Depósito e Saque em Lojas Riachuelo, pagamentos de boletos e ter o seu saldo com rendimento maior que a poupança!</p>
                  `,
                },
                {
                  label: 'Como ter uma Conta Midway Completa?',
                  value: `
                    <p>No aplicativo Midway, acesse seu perfil através da página inicial. Clique em 'Complete o seu cadastro' e siga as instruções. Pronto! Agora você possui uma Conta Digital Midway completa que possui mais vantagens com ofertas personalizadas.</p>
                  `,
                },
                {
                  label: 'Como compartilhar o extrato?',
                  value: `
                    <p>Para enviar o extrato, acesse sua conta e clique em 'Ver extrato'. Em seguida escolha o atalho 'Compartilhar extrato' e selecione o mês que deseja consultar. Agora você pode conferir ou compartilhar o documento referente ao mês escolhido.</p>
                  `,
                },
                {
                  label: 'Como fazer um depósito na minha conta?',
                  value: `
                    <p>Acesse a sua conta e clique no atalho 'Depósito'. Em seguida, escolha a opção desejada para fazer o depósito e selecione o valor. Em caso de depósito via Pix, é necessário ter uma chave cadastrada. Ao fazer um depósito para sua conta, o seu dinheiro não fica parado e rende automaticamente 100% do CDI.</p>
                  `,
                },
              ],
            },
            {
              title: 'PIX',
              isMainQuestions: true,
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao',
                },
                {
                  title: 'Produtos e serviços financeiros',
                  url: 'https://www.midway.com.br/produtos-financeiros',
                },
              ],
              questions: [
                {
                  label: 'Como fazer Pix?',
                  value: `
                    <p>No aplicativo da sua Conta Digital Midway, selecione a opção Pix em pagamentos e em seguida digite a chave ou os dados da conta de quem vai receber. Continue normalmente a transação inserindo o valor e data de transferência.</p>
                  `,
                },
                {
                  label:
                    'Não consigo fazer o PIX ou está demorando para cair em outra conta, e agora?',
                  value: `
                    <p>Em situações normais o PIX é instantâneo e você consegue fazer transações em segundos, porém podem ocorrer alguns erros durante a tentativa de pagar ou receber um Pix:</p>
                    <p>Chave Pix inválida ou inexistente: Verifique a chave inserida durante a transação, digite uma chave válida e tente novamente.</p>
                    <p>Problema com QR Code e Pix copia e cola: Solicite um novo código Pix e tente novamente.</p>
                    <p>O Token pode estar desabilitado: Habilite o token clicando em 'habilitar token' na tela inicial do aplicativo Midway e faça o Pix novamente.</p>
                    <p>Aplicativo desatualizado: Atualize para a última versão do aplicativo Midway e tente novamente.</p>
                    <p>O Pix pode estar com uma instabilidade momentânea: Aguarde alguns instantes e tente novamente.</p>
                    <p>Se a sua conta foi aberta em menos de 24h e as transações ainda estão bloqueadas: Aguarde o prazo de análise da conta e tente novamente.</p>
                  `,
                },
                {
                  label: 'Como criar uma chave Pix?',
                  value: `
                    <p>
                      No aplicativo da sua Conta Digital Midway, clique no atalho Pix no menu inicial do aplicativo. Clique em “Chaves Pix” e depois em “Cadastrar nova chave”. É possível cadastrar até 4 tipos de chaves Pix: CPF/CNPJ, celular, e-mail ou chave aleatória. Para encontrar suas chaves, é só abrir o atalho Pix ao entrar no aplicativo e as informações estarão no botão superior. Você também pode alterar a chave que desejar clicando em 'Chaves Pix'.
                    </p>
                  `,
                },
                {
                  label: 'Como pagar com Pix?',
                  value: `
                    <p>No fechamento do pedido, na etapa de "Entrega e pagamento", você pode:</p>
                    <ul>
                      <li>Clicar na opção "Pix";</li>
                      <li>Finalizar o pedido;</li>
                      <li>O QR code e o código Pix serão disponibilizados;</li>
                    </ul>
                  `,
                },
                {
                  label:
                    'Como pedir o ressarcimento de um pedido pago com Pix?',
                  value: `
                    <p>
                      Para compras realizadas no e-commerce, você pode solicitar a devolução através da opção "Quero devolver" em "Meus Pedidos" em até 7 (sete) dias corridos após a entrega. Ao realizar a devolução do pedido, o valor será estornado na conta à qual a chave PIX está vinculada, em até 5 dias úteis após o recebimento do produto em nosso Centro de Distribuição.
                    </p>
                  `,
                },
                {
                  label: 'Como acessar o comprovante detalhado do Pix?',
                  value: `
                    <p>
                      Clique no atalho ”Pix” no menu inicial do aplicativo, clique em “Extrato Pix” e depois na transação realizada. Outra forma de consultar o comprovante do Pix é acessar o Extrato da Conta no botão 'Ver extrato” ao lado do saldo na tela inicial do aplicativo. Ao clicar na transação, você poderá visualizar e compartilhar o comprovante.
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Empréstimo',
              isMainQuestions: false,
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao',
                },
                {
                  title: 'Produtos e serviços financeiros',
                  url: 'https://www.midway.com.br/produtos-financeiros',
                },
              ],
              questions: [
                {
                  label: 'Como contratar um Empréstimo Pessoal?',
                  value: `
                    <p>
                      Você pode contratar um Empréstimo Pessoal através do aplicativo Midway, em nossa central de atendimento ou em qualquer Loja Riachuelo. Com o crédito pré-aprovado, a contratação é muito rápida! O valor do seu Empréstimo Pessoal pode ser usado para qualquer finalidade - como pagamento de contas, fazer um curso ou reforma.
                    </p>
                  `,
                },
                {
                  label: 'Como pagar a parcela do meu Empréstimo?',
                  value: `
                    <p>
                      No aplicativo Midway, você pode acessar seus contratos, ver as parcelas em aberto e realizar o pagamento gerando um boleto. Se o seu pagamento é via débito automático, o pagamento é automático no dia do vencimento da sua parcela, basta ter saldo correspondente em sua conta; Em qualquer Loja Riachuelo com carnê ou informando o seu CPF; No site Midway, informando seu CPF e data de nascimento.
                    </p>
                  `,
                },
                {
                  label: 'Como fazer a antecipação do FGTS?',
                  value: `
                    <p>
                      Habilite a modalidade Saque-Aniversário no aplicativo FGTS. Você deve aderir ao Saque-Aniversário do FGTS, ler e aceitar os termos e condições. Em seguida, clique em 'autorizar bancos a consultarem seu FGTS', selecione a opção 'Empréstimo Saque-Aniversário' e selecione a Midway. No aplicativo Midway, clique no atalho 'Empréstimo' na página inicial. Contrate o adiantamento e receba em instantes!
                    </p>
                  `,
                },
                {
                  label: 'Como negociar minha dívida?',
                  value: `
                    <p>
                      Acesse o site Mi Ajuda, informe o seu CPF e data de nascimento e consulte as opções de acordo disponíveis para negociar a sua dívida.
                    </p>
                  `,
                },
              ],
            },
            {
              title: 'Seguros e Assistências',
              isMainQuestions: false,
              links: [
                {
                  title: 'Pagar fatura (linha digitável)',
                  url: 'https://www.midway.com.br/consulta-boleto',
                },
                {
                  title: 'Negociar dívidas',
                  url: 'https://www.midway.com.br/campanha-quitacao',
                },
                {
                  title: 'Produtos e serviços financeiros',
                  url: 'https://www.midway.com.br/produtos-financeiros',
                },
              ],
              questions: [
                {
                  label:
                    'Quais seguros são oferecidos pela Midway e como contratar?',
                  value: `
                    <p>
                      A Midway oferece os Seguros: Bolsa, Pix e Cartão Protegido; Residencial; Celular; Conta Paga; Acidentes pessoais; e Proteção Financeira. Todas as assistências podem ser contratados nas Lojas Riachuelo, no aplicativo Midway e por meio dos telefones das centrais de atendimento.
                    </p>
                  `,
                },
                {
                  label: 'Como acionar meu seguro?',
                  value: `
                    <p>
                      Você deve entrar em contato diretamente com a seguradora para obter orientações específicas sobre como acionar seu seguro.
                    </p>
                  `,
                },
                {
                  label:
                    'Quais assistências são oferecidas pela Midway e como contratar?',
                  value: `
                    <p>
                      A Midway oferece as Assistências para a sua Residência, Automóvel e Moto em parceria com a empresa CDF e também conta com serviços para a sua saúde! Todas as assistências podem ser contratadas nas Lojas Riachuelo, no aplicativo Midway e por meio dos telefones das centrais de atendimento.
                    </p>
                  `,
                },
                {
                  label: 'Como acionar minha Assistência?',
                  value: `
                    <p>
                      Você pode ligar para o número 0800 704 5025. A nossa central está a disposição 24 horas por dia e 7 dias na semana para realizar o atendimento.
                    </p>
                  `,
                },
              ],
            },
          ],
          links: [
            {
              title: 'Pagar fatura (linha digitável)',
              url: 'https://www.midway.com.br/consulta-boleto',
            },
            {
              title: 'Negociar dívidas',
              url: 'https://www.midway.com.br/campanha-quitacao',
            },
            {
              title: 'Produtos e serviços financeiros',
              url: 'https://www.midway.com.br/produtos-financeiros',
            },
          ],
        },
      },
    ],
  },
};

export const AttendancePortalContext = createContext({
  category: initialState.category,
  subCategory: initialState.subCategory,
  attendanceState: initialState.attendanceState,
  setCategory: value => value,
  setSubCategory: value => value,
  setAttendanceState: value => value,
});

export const useAttendancePortalState = () =>
  React.useContext(AttendancePortalContext);
export function AttendancePortalProvider({ children }) {
  const [attendanceState, setAttendanceState] = useState(
    initialState.attendanceState
  );
  const [category, setCategory] = useState(initialState.category);
  const [subCategory, setSubCategory] = useState(initialState.subCategory);
  const { isLoading, isError, fetchAttendancePortalData } =
    useAttendancePortal();

  useEffect(() => {
    fetchAttendancePortalData().then(output => setAttendanceState(output));

    return () => {
      setAttendanceState(initialState.attendanceState);
      setCategory(initialState.category);
      setSubCategory(initialState.subCategory);
    };
  }, []); // eslint-disable-line

  if (isLoading) return <Loading />;
  if (isError)
    return (
      <Error
        errorTitle={'Página de Ajuda - FAQ'}
        errorBody={'Ocorreu um erro ao obter os conteúdos.'}
        refreshPage
      />
    );

  return (
    <AttendancePortalContext.Provider
      value={{
        category,
        subCategory,
        attendanceState,
        setAttendanceState,
        setCategory,
        setSubCategory,
      }}
    >
      {children}
    </AttendancePortalContext.Provider>
  );
}

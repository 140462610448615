import { WrapperContainer, WrapperInner } from './styles';

const Wrapper = ({ children, ...props }) => {
  return (
    <WrapperContainer {...props}>
      <WrapperInner>{children}</WrapperInner>
    </WrapperContainer>
  );
};

export default Wrapper;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-height: 40px;
  margin-bottom: 24px;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export const ParagraphSection = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1f2b2a;

  @media (max-width: 1000px) {
    font-weight: 500;
  }
`;

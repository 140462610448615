export const mockData = [
  {
    id: 1,
    children: [
      {
        link: 'https://www.midway.com.br/static/documents/tags/contratos/termos-e-condicoes-de-uso-conta-pagamento-midway-30-10.pdf',
        linkTitle: 'Condições Gerais Conta',
      },
    ],
  },
];

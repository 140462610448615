import React from 'react';
import { Image } from '@midway/web-ui-component';
import error from '~/assets/img/Icones/error.svg';
import success from '~/assets/img/Icones/success.svg';

import * as Styles from './styles';

const Status = ({ onAction, status }) => {
  const statusMessages = {
    error: {
      icon: error,
      title: 'Algo deu errado...',
      description:
        'Infelizmente não podemos seguir com sua solicitação no momento, tente novamente mais tarde.',
      buttonText: 'Tentar novamente',
      buttonIcon: ['fas', 'sync-alt'],
    },
    not_found: {
      icon: error,
      title: 'Usuário não encontrado',
      description:
        'Infelizmente não foi possível encontrar o usuário no nosso sistema com os dados fornecidos.',
      buttonText: 'Voltar',
      buttonIcon: 'arrow-left',
    },
    no_billet: {
      icon: success,
      title: 'Você não tem boletos a pagar!',
      description:
        'O boleto da sua fatura desse mês ainda não fechou, e você não tem nenhuma fatura em aberto :)',
      buttonText: 'Voltar',
      buttonIcon: 'arrow-left',
    },
  };

  return (
    <Styles.Container>
      <Styles.Title>
        Acesse aqui o boleto da sua fatura do cartão RCHLO ou do seu empréstimo!
      </Styles.Title>
      <Styles.Card>
        <Styles.ImageContainer>
          <Image src={statusMessages[status].icon} alt="Erro" />
        </Styles.ImageContainer>
        <Styles.Title>{statusMessages[status].title}</Styles.Title>
        <Styles.SubTitle>{statusMessages[status].description}</Styles.SubTitle>
        <Styles.ButtonContainer>
          <Styles.Button
            onClick={onAction}
            aria-label={statusMessages[status].buttonText}
          >
            {statusMessages[status].buttonIcon && (
              <Styles.ButtonIcon
                icon={statusMessages[status].buttonIcon}
                size="1x"
                data-testid="button-icon"
              />
            )}
            {statusMessages[status].buttonText}
          </Styles.Button>
        </Styles.ButtonContainer>
      </Styles.Card>
    </Styles.Container>
  );
};

export default Status;

import styled from 'styled-components';
import FemaleModel from '~/assets/img/cartoes-pl/female-model.png';

export const Container = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
`;

export const ContentContainer = styled.div`
  max-width: 1195px;
  margin: 0 auto;
`;

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  padding: 0 !important;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ButtonBlack = styled.button`
  width: 100%;
  background-color: #000000;
  color: #ffffff !important;
  border: 1px solid #000000;
  padding: 10px 50px;
  border-radius: 25px;
  text-align: center;
  max-width: 233px;
  @media (max-width: 800px) {
    font-size: 16px;
    max-width: 100%;
    margin-bottom: 15px;
  }
`;

export const ButtonWhite = styled.button`
  width: 100%;
  background-color: #ffffff;
  color: #4e4b59 !important;
  border: 1px solid #4e4b59;
  padding: 10px 50px;
  border-radius: 25px;
  text-align: center;
  max-width: 233px;
  @media (max-width: 800px) {
    max-width: 100%;
    font-size: 16px;
  }
`;

export const BannerDesktop = styled.img`
  width: 100%;
  max-width: 2000px;
  object-fit: cover;
  max-height: 100%;
  cursor: pointer;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const BannerMobile = styled.img`
  width: 100%;
  object-fit: cover;
  cursor: pointer;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const SessionCards = styled.div`
  display: flex;
  margin-top: 130px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin: 40px 25px 0;
  }

  .leftSide,
  .rightSide {
    width: 50%;
    @media (max-width: 800px) {
      width: 100%;
    }

    .card-mobile {
      display: none;
      @media (max-width: 800px) {
        display: block;
        margin-bottom: 40px;
        div > img {
          max-width: 270px;
        }
      }
    }

    h2 {
      font-size: 32px;
      font-weight: 900;
      line-height: 39px;
      color: #1f2b2a;
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: #1f2b2a;
    }

    .list-content {
      margin-top: 65px;

      .list-item {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e1e0e7;
        padding-bottom: 35px;
        margin-bottom: 35px;
        &:last-child {
          border-bottom: none;
        }

        img {
          width: 60px;
          margin-right: 45px;
          @media (max-width: 800px) {
            width: 28px;
            margin-right: 25px;
          }
        }

        h4 {
          font-size: 24px;
          font-weight: 400;
          line-height: 29px;
          color: #4e4b59;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: #7a7786;
        }
      }
    }

    .cardImage {
      div {
        img {
          max-width: 407px;
          @media (max-width: 800px) {
            display: none;
          }
        }
      }
    }
    .cardImage-lft {
      div {
        img {
          max-width: 548px;
        }
      }
    }

    .content-butons,
    .content-butons-lft,
    .content-butons-mbl {
      padding-top: 55px;
      padding-left: 105px;
      justify-content: space-between;
      display: flex;
      @media (max-width: 800px) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0px;
      }
    }
    .content-butons-lft {
      padding-left: 0;
      padding-right: 105px;
      @media (max-width: 800px) {
        display: none;
      }
    }
    .content-butons-mbl {
      display: none;
      @media (max-width: 800px) {
        display: flex;
      }
    }
  }
`;

export const Pacerias = styled.div`
  margin-top: 130px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin: 40px 25px 0;
  }

  h2 {
    font-size: 32px;
    font-weight: 900;
    line-height: 39px;
    color: #1f2b2a;
    margin-bottom: 70px;
    @media (max-width: 800px) {
      margin-bottom: 45px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #1f2b2a;
  }

  .partners-logo {
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 800px) {
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }

  .consult-partners {
    text-align: center;
    padding: 50px 0 100px;
    a {
      @media (max-width: 800px) {
        max-width: 100%;
        font-size: 16px;
      }
    }
  }
`;

export const ModalStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-image: url(${FemaleModel});
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    overflow: scroll;
    background-image: none;
  }
`;

export const LeftModal = styled.div`
  width: 45%;
  display: flex;
  align-items: end;
  justify-content: end;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const LeftImageModal = styled.img`
  width: 100%;
`;

export const RightModal = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const TitleModal = styled.p`
  font-size: 30px;
  font-weight: 800;
  margin-top: 60px;
  font-family: Montserrat;

  @media (max-width: 1000px) {
    font-size: 24px;
    margin-top: 30px;
  }
`;

export const TextModal = styled.p`
  font-size: 25px;
  padding-bottom: 20px;
  font-family: Montserrat;

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

export const AdjustamentRightModal = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
`;

export const CentralizeCardsModal = styled.div`
  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`;

export const ModalOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

export const ContainerBlockModal = styled.div`
  width: 50%;
  display: flex;
  padding-bottom: 5px;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const CardModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1000px) {
  }
`;

export const IconModal = styled.img`
  max-width: 50px;
  margin-right: 14px;

  @media (max-width: 800px) {
    max-width: 28px;
  }
`;

export const CentralizeTextModal = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
`;

export const DescriptionModal = styled.p`
  font-size: 13px;
  font-weight: 600;
  font-family: Montserrat;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const ObservationModal = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-family: Montserrat;

  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

export const TextfinalModal = styled.p`
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const QRCodeImage = styled.img`
  max-width: 190px;
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ModalButton = styled.a`
  background-color: #000;
  padding: 10px;
  width: 80%;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;

  @media (min-width: 1000px) {
    display: none;
  }
`;

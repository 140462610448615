import React, { memo, useState } from 'react';
import { MASK_TYPES } from '~/components/Common/Inputs/MaskedInput';

// eslint-disable-next-line import/no-cycle
import { Container, Input, Label, Message, Real } from './styles';

export const INPUT_STATE = {
  NORMAL: 'NORMAL',
  ERROR: 'ERROR',
};

export const EntryValueInput = memo(
  ({ value, onInputChange, label, message, state }) => {
    const [inputValue, setInputValue] = useState(value);

    const handleChange = newValue => {
      setInputValue(newValue);
      onInputChange(newValue);
    };

    return (
      <Container>
        {label && <Label>{label}</Label>}
        <Real>R$</Real>
        <Input
          data-testid="installment-entry-value-input"
          value={inputValue}
          setValue={handleChange}
          state={state}
          maskType={MASK_TYPES.BR_CURRENCY}
        />
        {message && <Message state={state}>{message}</Message>}
      </Container>
    );
  }
);

import React from 'react';
import { Link } from 'react-router-dom';
import { tagContaOnlineConsultaBoletoLink } from '~/services/tagging';
import MoneyCircle from '~/assets/img/Financial/BilletInvoiceOrLendingV2/money-circle.svg';
import Cards from '~/assets/img/Financial/BilletInvoiceOrLendingV2/cards-mobile.png';
import * as S from './styles';

export const CallToActionHeader = ({ link }) => {
  return (
    <S.ContainerHeader>
      <div className="headerCard">
        <div>
          <img
            alt="Ícone de código de barras"
            src={MoneyCircle}
            className="img-fluid"
            id="icon"
          />
        </div>
        <div className="displayWidth">
          <span>ACESSO RÁPIDO</span>
          <p>
            Negocie suas dívidas nos Cartões RCHLO e Empréstimo Pessoal com
            condições exclusivas
          </p>
        </div>
        <div className="botaoAR">
          <Link
            onClick={() => {
              tagContaOnlineConsultaBoletoLink(
                'call-to-action-home:negociação-de-pagamento'
              );
            }}
            aria-label="Negocie seus debitos"
            to={`${link}&utm_content=Home`}
          >
            Negociar
          </Link>
        </div>
      </div>
    </S.ContainerHeader>
  );
};

export const CallToActionCard = ({ link }) => {
  return (
    <>
      <Link
        onClick={() => {
          tagContaOnlineConsultaBoletoLink(
            'call-to-action-boleto:negociação-de-pagamento'
          );
        }}
        to={`${link}&utm_content=Boleto`}
        style={{ color: '#616B6A' }}
      >
        <S.ContainerCard>
          <img alt="Imagem ilustrativa de um cartão de crédito" src={Cards} />
          <div>
            <span>Precisando de um fôlego?</span>
            <p>
              Confira condições especiais para negociar seus débitos no Cartão
              RCHLO e Empréstimo Pessoal
            </p>
          </div>
        </S.ContainerCard>
      </Link>
    </>
  );
};

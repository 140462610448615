import styled from 'styled-components';

import { colors } from '~/constants';

export const RootContainer = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 900;

  @media (max-width: 600px) {
    top: unset;
    background: none;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${colors.white};
  border-radius: 24px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.07);
  max-width: 600px;
  width: 100%;

  @media (max-width: 500px) {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: 100%;
    width: 100%;
  }
`;

export const Content = styled.div`
  padding: 32px 24px;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h3 {
    font-weight: 700;
    font-size: 18px !important;
    line-height: 22px;
  }

  img {
    width: 32px;
    height: 32px;
  }
`;

export const DetailsContainer = styled.div``;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px;

  color: ${colors.gray800};

  &:last-of-type span,
  &:last-of-type a {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: ${colors.orangeRoof};
    text-decoration: underline;
    margin-top: 8px;
  }

  strong {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  span {
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const BackButton = styled.button`
  background: ${colors.chumbo};
  border: none;
  border-radius: 100px;
  width: 100%;
  padding: 15px 10px 16px 10px;
  margin-top: 32px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`;

import React, { useState, useEffect } from 'react';
import { TABS } from '~/pages/CardBenefits/constants';
import MidwayPartners from '~/pages/CardBenefits/tabs/MidwayPartners';
import MastercardBenefits from '~/pages/CardBenefits/tabs/MastercardBenefits';
import OurCards from '~/pages/CardBenefits/tabs/OurCards';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';
import Tabs from './tabs';
import Hero from './components/Hero';

const CardBenefits = () => {
  const [selectedTab, setSelectedTab] = useState(TABS.PARCEIROS_MIDWAY);

  useEffect(() => {
    trackingSelectContent(selectedTab, 'beneficios');
  }, [selectedTab]);

  return (
    <S.Container>
      <Hero />
      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === TABS.PARCEIROS_MIDWAY && <MidwayPartners />}
      {selectedTab === TABS.MASTERCARD_SURPREENDA && <MastercardBenefits />}
      {selectedTab === TABS.NOSSOS_CARTOES && <OurCards />}
    </S.Container>
  );
};

export default CardBenefits;

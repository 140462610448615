import styled from 'styled-components';

export const Container = styled.div`
  background: transparent linear-gradient(180deg, #c1fd95 0%, #46cbd0 100%) 0%
    0% no-repeat padding-box;
  padding: 0 !important;
  display: flex;
  align-self: center;
  min-height: 450px;
  flex-direction: column;
`;

export const Content = styled.div`
  align-self: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  min-width: 70%;
  max-width: 70%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-around;

  position: relative;
  min-height: 560px;
  max-height: 560px;
  padding: 25px 50px !important;
  border-radius: 20px;

  margin-bottom: 30px;

  @media (max-width: 1190px) {
    max-height: fit-content;
    max-width: 90%;
  }

  @media (max-width: 1020px) {
    max-height: fit-content;
    flex-direction: column;
    padding: 25px 25px !important;
  }
`;

export const Forms = styled.div`
  margin: 40px;
  @media (max-width: 767px) {
    margin: 0;
  }

  input {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 10px;
    margin-top: 30px;

    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 1px;
  }

  input::placeholder {
    color: #000000;
    font-size: 16px !important;
  }

  h1 {
    font-weight: bold;
    color: #000000;
    font-size: 32px !important;
  }

  h2 {
    font-size: 18px !important;
    color: #606060;
    padding: 10px 0px 0px 0px;
  }

  button {
    width: 84%;
    margin-top: 20px;
    @media (max-width: 768px) {
      width: 100%;
      align-self: center;
    }
  }

  img {
    width: 190px;

    @media (max-width: 768px) {
      width: 150px;
      height: 275px;
      align-self: center;
    }
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  div {
    width: 33%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  p {
    margin-top: 10px;
    font-size: 14px;
    color: #606060;
  }

  h2 {
    font-weight: bold;
    color: #000000;
    padding: 20px 0px 0px 0px;
  }
  a {
    font-weight: bold;
    color: #00726d;
    text-decoration: underline;
  }
  button {
    background-color: #00726d;
    margin-bottom: 20px;
  }

  .text-atendimento {
    font-size: 10px !important;
    margin: auto;
    padding: 14px 0px;
  }
`;

export const TextContact = styled.p`
  margin: 0 !important;
  font-weight: bold;
  color: #00726d !important;
  font-size: 18px !important;
`;

export const Modal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  #background {
    width: 100%;
    height: 100%;
    background: #00000090;
    position: fixed;
    z-index: 5;
    top: 0;
  }
`;

export const ModalContent = styled.div`
  background: transparent linear-gradient(180deg, #c1fd95 0%, #46cbd0 100%) 0%
    0% no-repeat padding-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  max-width: 670px;
  z-index: 5;
  display: flex;
  padding: 50px;
  flex-direction: column;
  justify-content: center;

  h2 {
    font: bold 40px Montserrat !important;
    margin-top: 28px;
  }

  p {
    font: 24px Montserrat !important;
    margin: 28px 0;
  }

  div.ahl-icon-closed {
    margin-top: 40px;
    width: 10px;
    align-self: flex-end;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .qrcode {
    width: 180px;
    margin-top: 20px;
    border-radius: 3px;
  }
`;

export const Bold = styled.strong`
  font-weight: bold;
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  background-color: #242424;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 50px;
    height: 50px;
    border: 4px solid #fff;
  }
`;

export const Text = styled.span`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  color: #fff;

  @media (max-width: 1000px) {
    font-size: 22px;
    font-weight: 500;
  }
`;

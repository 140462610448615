import OAuthAwsConfig from './OAuthAwsConfig';

const publicKey = process.env.REACT_APP_RENTABILIZACAO_OAF_PUBLIC_KEY;
const applicationId = process.env.REACT_APP_RENTABILIZACAO_OAF_APPLICATION_ID;
const xApiKey = process.env.REACT_APP_RENTABILIZACAO_OAF_API_KEY;
const username = process.env.REACT_APP_RENTABILIZACAO_OAF_USERNAME;
const password = process.env.REACT_APP_RENTABILIZACAO_OAF_PASSWORD;

const getToken = async () => {
  return OAuthAwsConfig({
    publicKey,
    applicationId,
    xApiKey,
    username,
    password,
  });
};

export default { getToken };

import styled, { css } from 'styled-components';
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp';

export const HelpContainer = styled.div`
  color: #00726d;
  position: relative;
  font-weight: bold;
  margin: 2rem auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  ${({ isMobile }) =>
    isMobile &&
    css`
      text-align: center;
    `}
`;
export const WhatsAppIcon = styled(Whatsapp)`
  color: #00726d;
  width: 25px;
`;

import styled from 'styled-components';
import Background from '~/assets/img/BePartOfIt/look.png';

export const ContentContainer = styled.div`
  background-color: #4eeae5;
  @media (min-width: 712px) {
    min-height: 700px;
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const ThirdSection = styled.section`
  padding: 150px 100px;
  img {
    display: none;
  }
  @media (max-width: 712px) {
    padding: 30px 20px;
    img {
      margin-top: -70px;
      display: block;
      border-radius: 20px;
    }
  }
`;

import AccordionBase from '../Accordion';
import { breakpoints } from '../../utils';
import { colors } from '~/constants';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* @media (${breakpoints[480]}) {
    height: 30.25rem;
    justify-content: center;
  }
  @media (${breakpoints[768]}) {
    padding-left: 8.25rem;
  }
  @media (${breakpoints[900]}) {
    padding-left: 8.25rem;
    background-position-y: -100px;
  } */
`;

export const AccordionSubheader = styled.div`
  margin-top: -0.75rem;
  padding: 0 1rem 1rem 1rem;

  span {
    font-size: 0.875rem;
  }
`;

export const Title = styled.strong`
  font-size: 1.125rem;
  color: #221f20;
  font-weight: 700;
  line-height: 28px;
  font-family: 'Montserrat', sans-serif;

  @media (${breakpoints[1176]}) {
    font-size: 1.5rem;
  }
`;

export const AccordionTitle = styled.h4`
  font-size: 1rem;
  color: #221f20;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
`;

export const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (${breakpoints[1176]}) {
    flex-direction: row;
  }
`;

export const Accordion = styled(AccordionBase)`
  background: ${colors.light_gray} !important;

  @media (${breakpoints[1176]}) {
    background: ${colors.white} !important;
  }
`;

export const ContactInfoContainer = styled.div`
  border-top: 1px solid #0000001a;
  padding-top: 1.875rem;
  padding-bottom: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ContactInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  cursor: pointer;

  strong {
    color: ${colors.orangeRoof};
    font-weight: 500;
  }

  span {
    font-size: 0.875rem;
  }
`;

export const WhatsappButton = styled.button`
  width: max-content;
  border-radius: 100px;
  border: 2px solid #36c637;
  padding: 0.25rem 1rem;
  background: none;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #36c637;
  }

  span {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

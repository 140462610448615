import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Card } from '@midway/web-ui-component';
import Carousel from '@brainhubeu/react-carousel';
import * as S from './styles';

const ResumeCarousel = ({ cards = [] }) => {
  const [indexActive, setIndexActive] = useState(0);
  const [drag, setDrag] = useState(false);
  const handleWindowSizeChange = () => {
    switch (true) {
      case window.innerWidth >= 993 && window.innerWidth <= 1200:
        setDrag(false);
        break;
      case window.innerWidth < 992:
        setDrag(true);
        break;
      default:
        setDrag(false);
        break;
    }
  };
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);
  return (
    <S.Container>
      <Carousel
        slidesPerPage={1}
        arrows={false}
        keepDirectionWhenDragging
        draggable={drag}
        centered
        value={window.innerWidth <= 992 ? null : indexActive}
      >
        {cards.map((elm, i) => {
          return (
            <Card
              key={`key-card-element-${i}`}
              hasMicroContent
              radius={8}
              style={{
                width: '480px',
                height: '480px',
                backgroundColor: 'rgba(254, 254, 254, 0.6)',
              }}
            >
              <S.Content>
                <div>
                  <p>{elm.title}</p>
                  <S.StarList aria-label="4 estrelas de 5">
                    <S.StarFull />
                    <S.StarFull />
                    <S.StarFull />
                    <S.StarFull />
                    <S.StarEmpty />
                  </S.StarList>
                  <div className="card-text">
                    <span>{elm.text}</span>
                  </div>
                </div>
                <b aria-label={`${elm.date} foi a data da postagem`}>
                  {elm.date}
                </b>
              </S.Content>
            </Card>
          );
        })}
      </Carousel>
      <S.Navigation>
        <div style={{ marginRight: '35px' }}>
          <S.NavigationContainer
            onClick={() => {
              setIndexActive(indexActive > 0 ? -1 : indexActive);
            }}
            data-gtm-event-category="midway:home"
            data-gtm-event-action="clique:comentarios"
            data-gtm-event-label="botao:anterior"
          >
            <S.PrevIcon />
          </S.NavigationContainer>
        </div>
        <S.NavigationContainer
          onClick={() => {
            setIndexActive(indexActive < cards.length ? +1 : indexActive);
          }}
          data-gtm-event-category="midway:home"
          data-gtm-event-action="clique:comentarios"
          data-gtm-event-label="botao:proximo"
        >
          <S.NextIcon />
        </S.NavigationContainer>
      </S.Navigation>
    </S.Container>
  );
};

ResumeCarousel.propTypes = {
  cards: PropTypes.any,
};

ResumeCarousel.defaultProps = {
  cards: [],
};

export default ResumeCarousel;

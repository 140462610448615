import React from 'react';

import './index.css';
import AddressUpdate from './components/AddressUpdate';
import Beneficios from './components/Beneficios';
import Cashback from './components/Cashback';
import CentralDeRelacionamento from './components/CentralDeRelacionamento';
import CreditCardUnlock from './components/CreditCardUnlock';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Header from './components/Header';
import HeroBanner from './components/HeroBanner';
import Parceiros from './components/Parceiros';
import SaibaMais from './components/SaibaMais';

function MasterCard() {
  return (
    <div className="App" id="app-lp-master">
      <Header />
      <HeroBanner />
      <SaibaMais />
      <AddressUpdate />
      <Beneficios />
      <Cashback />
      <Parceiros />
      <CreditCardUnlock />
      <Faq />
      <CentralDeRelacionamento />
      <Footer />
    </div>
  );
}

export default MasterCard;

import React from 'react';
import { cardsProductsAssistance } from '~/constants';
import format from '~/services/format';
import * as S from '../styles';
import { CardAssistance, CardAssistanceImage } from '~/components';

const SessionProducts = () => (
  <S.Session2>
    <S.GridContainer>
      {cardsProductsAssistance.map(elm => (
        <div key={elm.id} className="card-assistance">
          <CardAssistance
            title={elm.title}
            btnAriaLabel={elm.btnAriaLabel}
            circle={elm.circle}
            subtitle={elm.subtitle}
            description={elm.text}
            imageAlt={elm.imageAlt}
            imagePath={elm.imagePath}
            btnTitle={elm.btnTitle}
            action={elm.action}
            photo={elm.photo}
            showIcon={elm.photo && false}
            gtmCategory="midway:assistencias"
            gtmAction="clique:botao"
            gtmLabel={`clique-aqui:card:${format.normalizeString(elm.title)}`}
            contentHeight={152}
          >
            <CardAssistanceImage
              imageAlt={elm.imageAlt}
              imagePath={elm.imagePath}
            />
          </CardAssistance>
        </div>
      ))}
    </S.GridContainer>
  </S.Session2>
);

export default SessionProducts;

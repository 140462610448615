import styled from 'styled-components';

export const Content = styled.div`
  align-self: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  max-width: 1170px;
  min-width: 70%;
  height: 100%;
  min-height: 620px;
  display: flex;
  flex-direction: column;

  justify-content: space-around;

  padding: 25px 100px !important;
  border-radius: 20px;

  margin-bottom: 30px;

  @media (max-width: 768px) {
    max-height: fit-content;
    max-width: 90%;
    padding: 23px 22px !important;
    flex-direction: column;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .align-center {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .align-right {
    width: 100%;
    display: flex;
    justify-content: right;
  }

  .align-left {
    width: 100%;
    display: flex;
    justify-content: left;
  }
`;

export const Button = styled.button`
  color: white;
  border-radius: 7rem;
  min-width: 200px !important;
  background-color: ${props => (props.disabled ? '#606060' : '#00726D')};
  border-color: ${props => (props.disabled ? '#606060' : '#00726D')};
  cursor: pointer;
  min-height: 48px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 768px) {
    width: 100%;
    gap: 10px;
  }
`;

export const ButtonContinue = styled.button`
  color: white;
  border-radius: 7rem;
  min-width: 200px !important;
  background-color: ${props => (props.disabled ? '#606060' : '#00726D')};
  border-color: ${props => (props.disabled ? '#606060' : '#00726D')};
  cursor: pointer;
  min-height: 48px;
  display: ${props => (props.disabled ? 'none' : 'inline-block')};
  /* display: inline-block; */
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 768px) {
    width: 100%;
    gap: 10px;
  }
`;

export const ButtonBack = styled.button`
  color: #006464;
  border-radius: 7rem;
  min-width: 200px !important;
  background-color: white;
  border-color: #006464;
  cursor: pointer;
  min-height: 48px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #006464;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 768px) {
    width: 100%;
    gap: 10px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 838px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: flex-start;
  gap: 56px;
  align-self: center;
`;

import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  column-gap: 80px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

export const CreditCardContainer = styled.div`
  @media (max-width: 768px) {
    margin: auto;
  }
`;

export const CreditCardImage = styled.img`
  width: 100%;
  max-width: 500px;
  @media (max-width: 768px) {
    margin: auto;
  }
`;

export const FeaturesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const FeatureList = styled.div`
  margin: 56px 0;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 27px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
`;

export const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #3b4443;
`;

export const FeatureIcon = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 16px;
`;

export const FeatureText = styled.p`
  font-size: 1rem;
  span {
    display: block;
    font-size: 12px;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

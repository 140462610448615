import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 1173px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 16px;

  @media screen and (max-width: 1219px) {
    margin-bottom: 24px;
  }
`;

export const Centralize = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 12px 40px 12px;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 381px;
  max-height: 286px;
  border-radius: 12px;
  background-color: #f1ebdf;

  @media (max-width: 1219px) {
    max-width: 302px;
    max-height: 227px;
    border-radius: 10px;
  }
`;

export const Icon = styled.img`
  width: 80px;
  height: 80px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-family: Montserrat;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 16px;
  @media (max-width: 1219px) {
    font-size: 19px;
    text-align: center;
  }
`;

export const Description = styled.p`
  font-size: 40px;
  font-weight: 700;
  font-family: Montserrat;
  @media (max-width: 1219px) {
    font-size: 32px;
    text-align: center;
  }
`;

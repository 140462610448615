import React, { useCallback, useRef } from 'react';

import { useParams } from 'react-router-dom';
import history from '~/services/history';
import FirstBanner from './components/FirstBanner';
import SectionForm from './components/SectionForm';
import SecondBanner from './components/SecondBanner';
import ThirdBanner from './components/ThirdBanner';

const Indications = () => {
  const { base64 } = useParams();

  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  if (!base64regex.test(base64)) {
    history.push('/midway');
  }

  function JsonString(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return {};
    }
  }

  const { nickName: nome, documentNumber: cpf } = JsonString(atob(base64));

  if (!(nome && cpf)) {
    history.push('/midway');
  }

  const formRef = useRef(null);

  const handleScroll = useCallback(() => {
    formRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  return (
    <main>
      <FirstBanner nome={nome} onClick={handleScroll} />
      <div ref={formRef}>
        <SectionForm cpf={cpf} />
      </div>
      <SecondBanner />
      <ThirdBanner />
    </main>
  );
};

export default Indications;

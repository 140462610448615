/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import DefaultLayout from '../pages/_layouts/default';

export default function RouteWrapper({
  component: Component,
  isContact,
  isWhite,
  ...rest
}) {
  const Layout = DefaultLayout;

  const render = rest.render
    ? rest.render
    : props => (
        <Layout isWhite={isWhite}>
          <Component {...props} />
        </Layout>
      );
  return <Route {...rest} render={render} />;
}

RouteWrapper.propTypes = {
  isContact: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isContact: false,
};

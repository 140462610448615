/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick/lib/slider';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import cmsService from '~/services/cms/pages';
import CarouselItem from '~/components/HeroCarousel/components/CarouselItem';
import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';
import 'slick-carousel/slick/slick.css';
import { trackingViewPromotion } from '~/analytics';
import * as S from './styles';
import { mockData } from './data';

const checkTopStyle = () => {
  const element = document.getElementById('m-main');
  if (element) {
    const style = window.getComputedStyle(element);
    return style.top !== '0px';
  }
  return false;
};

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      aria-label="Ir para o próximo banner"
      tabIndex={0}
      onClick={onClick}
      className={className}
    >
      <IconArrowRight size={30} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      role="button"
      aria-label="Voltar para o banner anterior"
      tabIndex={0}
      onClick={onClick}
      className={className}
    >
      <IconArrowLeft size={30} />
    </div>
  );
}

const HeroCarousel = () => {
  const [bannerData, setBannerData] = useState([]);
  const [height, setHeight] = useState(0);
  const hasMobileHeader = useHasMobileDownloadHeader();

  useEffect(() => {
    const header = document.getElementById('m-main-header');
    const hasTopOnBody = checkTopStyle();

    if (header && !hasTopOnBody) {
      setHeight(header.offsetHeight);

      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target.id === 'm-main-header') {
            setHeight(entry.target.offsetHeight);
          }
        }
      });

      resizeObserver.observe(header);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const settings = {
    afterChange: index => {
      const banner = bannerData[index];
      trackingViewPromotion(
        banner.redirectUrl,
        'homeCartoes',
        banner.title,
        banner.name
      );
    },
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 6000,
    overflow: 'hidden',
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'HeroRoot',
    accessibility: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getHeroBannerCardHome(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(mockData);
    } catch (error) {
      setBannerData(mockData);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);
  if (bannerData.length > 0) {
    return (
      <S.StyledContainer
        aria-label="Banner home"
        bannerHeight={height}
        hasMobileHeader={hasMobileHeader}
      >
        <Slider
          {...settings}
          onInit={() => {
            const banner = bannerData[0];
            trackingViewPromotion(
              banner.redirectUrl,
              'homeCartoes',
              banner.title,
              banner.name
            );
          }}
        >
          {bannerData?.map(item => (
            <CarouselItem key={item.id} {...item} />
          ))}
        </Slider>
      </S.StyledContainer>
    );
  }
  return null;
};

export default HeroCarousel;

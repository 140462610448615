import { instance } from './RentabilizacaoApiInstance';

const RESOURCE = '/site-midway/v1/invoices/installments';
const HEADER_FEATURE_ORIGIN = 'INSTALLMENT_INVOICE';

const getAvailability = ({ documentNumber, deviceId, maskedPan }) =>
  instance.get(`${RESOURCE}/availability`, {
    headers: {
      'x-document-number': documentNumber,
      'x-device-id': deviceId,
      'x-masked-pan': maskedPan,
    },
  });

const simulate = ({
  documentNumber,
  deviceId,
  maskedPan,
  entryValue,
  cancelToken,
}) => {
  let params = {};
  if (entryValue > 0) {
    params = { entryValue };
  }
  return instance.get(`${RESOURCE}/options`, {
    params,
    headers: {
      'x-document-number': documentNumber,
      'x-device-id': deviceId,
      'x-masked-pan': maskedPan,
    },
    cancelToken,
  });
};

const confirm = ({ documentNumber, deviceId, maskedPan, payload }) =>
  instance.post(`${RESOURCE}/confirm`, payload, {
    headers: {
      'x-document-number': documentNumber,
      'x-device-id': deviceId,
      'x-masked-pan': maskedPan,
    },
  });

const getSimulation = ({ documentNumber, deviceId, simulationId }) =>
  instance.get(`${RESOURCE}/simulations/${simulationId}`, {
    headers: {
      'x-document-number': documentNumber,
      'x-device-id': deviceId,
    },
  });

const getPixQRCode = ({
  documentNumber,
  deviceId,
  maskedPan,
  dueDate,
  pixIdentifier,
}) =>
  instance.get(`/site-midway/v1/invoices/payment/pix/${pixIdentifier}`, {
    headers: {
      'x-feature-origin': HEADER_FEATURE_ORIGIN,
      'x-document-number': documentNumber,
      'x-device-id': deviceId,
      'x-masked-pan': maskedPan,
      'x-due-date': dueDate,
    },
  });

export { getAvailability, simulate, confirm, getSimulation, getPixQRCode };

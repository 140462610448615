import React from 'react';
import ComparisonInfo from './ComparisonInfo';
import HowToAsk from './HowToAsk';
import OurCashbacks from './OurCashbacks';
import DownloadTheApp from './DownloadTheApp';

const OurCards = () => {
  return (
    <>
      <ComparisonInfo />
      <HowToAsk />
      <OurCashbacks />
      <DownloadTheApp />
    </>
  );
};

export default OurCards;

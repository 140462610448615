import React from 'react';
import styled from 'styled-components';

const MainBanner = styled.section`
  /* Estilos para MainBanner (classe "main-banner") */
`;

const BannerContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding-top: 79px;
    padding-left: 8%;
    padding-right: 0;
    justify-content: space-between;
    align-items: center;
    max-width: 1720px;
    margin: 0 auto;
    gap: 25px;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.h1`
  font-size: 40px !important;
  line-height: 135% !important;
  text-align: left;

  @media (min-width: 1024px) {
    font-size: 48px !important;
  }

  strong {
    position: relative;
    font-weight: 700;
  }

  span {
    top: -15px;
    position: relative;
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  text-align: left;
  font-size: 25px;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
  }

  strong {
    display: block;
    margin-top: 0.375rem;
    font-weight: 700;
  }
`;

const SecondColumn = styled.div`
  @media (min-width: 1024px) {
    flex-shrink: 0;
    width: 49%;
    max-width: 790px;
  }
`;

const DesktopBannerImage = styled.img`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    margin: 0 auto;
  }
`;

const MobileBannerImage = styled.img`
  display: block;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const HeroBanner = () => {
  return (
    <MainBanner>
      <BannerContainer>
        <FirstColumn>
          <Title>
            AGORA SEU CARTÃO{' '}
            <strong>
              RIACHUELO É MASTERCARD<span>®</span>
            </strong>
          </Title>
          <Subtitle>
            O novo cartão que você vai receber traz vantagens como o programa de
            benefícios Mastercard® Surpreenda, cashback nas lojas Riachuelo e
            descontos em parceiros selecionados.
            <strong>Venha aproveitar um mundo de benefícios incríveis!</strong>
          </Subtitle>
        </FirstColumn>
        <SecondColumn>
          <DesktopBannerImage src="/desktop-banner.png" alt="" />
          <MobileBannerImage src="/mobile-banner.png" alt="" />
        </SecondColumn>
      </BannerContainer>
    </MainBanner>
  );
};

export default HeroBanner;

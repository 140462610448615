import styled from 'styled-components';

export const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  div.ahl-content-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 40px;
    margin-left: 25%;

    @media (max-width: 886px) {
      margin: 30px;
      margin-left: 5%;
    }

    @media (max-width: 415px) {
      margin: 30px 10px;
    }

    span {
      line-height: 1.2;
      font-size: 14px !important;
      margin-left: 10px;

      @media (max-width: 415px) {
        font-size: 10px !important;
      }

      :hover {
        text-decoration: underline;
        cursor: pointer;

        &:last-child {
          text-decoration: none;
          cursor: auto;
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  position: absolute;
  background-color: #fff;
  padding: 18px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 350px;
  min-height: 620px;
  max-width: 1020px;
  border-radius: 11px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;

export const Title = styled.h2`
  font-weight: 900;
  font-size: 2.19rem !important;
`;

export const ModalImageWrapper = styled.div`
  position: relative;
  width: 600px;
  img {
    width: 490px;
    height: auto;
    bottom: -19px;
    right: 10px;
    position: absolute;
    object-fit: contain;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ModalContenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalContentHeader = styled.div`
  padding: 18px 0;
`;

export const ModalContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalContentText = styled.p`
  font-size: 1.38rem !important;
`;

export const ModalQrContainer = styled.div`
  width: 178px;
  height: 178px;
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
`;

export function saveLimitInfo(payload) {
  return {
    type: '@loan/SAVE_LIMIT_INFO',
    payload,
  };
}

export function saveUserInfo(payload) {
  return {
    type: '@loan/SAVE_USER_INFO',
    payload,
  };
}

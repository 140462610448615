import { colors } from '~/constants';
import styled from 'styled-components';

export const BackButtonContainer = styled.button`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: ${colors.white};

  svg {
    width: 1rem;
  }
`;

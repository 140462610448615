import { GetToken } from '@midway/oaf-auth-lib';

const OAuthAwsConfig = async ({
  publicKey,
  applicationId,
  xApiKey,
  username,
  password,
}) =>
  GetToken.build()
    .getToken({
      publicKey,
      applicationId,
      apiKey: xApiKey,
      username,
      password,
    })
    .then(async data => {
      return data;
    })
    .catch(error => {
      console.log(error);
      return error;
    });

export default OAuthAwsConfig;

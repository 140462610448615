import React from 'react';
import PropTypes from 'prop-types';

import AccessibleLink from '~/components/AccessibleLink';
import * as S from './styles';

import SocialIcons from '../SocialIcons';

const LightFooter = ({ onClick }) => {
  return (
    <S.Container>
      <div className="container">
        <div className="row">
          <S.Content className="col-sm-12 col-md-6">
            <p>CHAT</p>
            <span>
              Caro cliente, em caso de dúvidas acesse nosso chat.{' '}
              <AccessibleLink customAction={onClick} text="Acesso ao chat." />
            </span>
          </S.Content>
          <S.Content className="col-md-6">
            <S.HideMobile>
              <p>Siga nas nossas redes</p>
              <SocialIcons color="#252525" lighten />
            </S.HideMobile>
          </S.Content>
        </div>
      </div>
    </S.Container>
  );
};

LightFooter.defaultProps = {
  onClick: () => {},
};

LightFooter.propTypes = {
  onClick: PropTypes.func,
};

export default LightFooter;

import styled from 'styled-components';

export const Forms = styled.div`
  align-self: baseline;
  height: 340px;
  width: 100%;

  @media (max-width: 768px) {
    height: fit-content;
  }

  input {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 10px;
    margin-top: 30px;

    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 1px;
  }

  input::placeholder {
    color: #000000;
    font-size: 16px !important;
  }

  h1 {
    font-size: 32px !important;
    font-weight: bold;
    color: #000000;

    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }
  h2 {
    font-size: 18px !important;
    color: #606060;
    padding: 10px 0px 0px 0px;
  }
  strong {
    font-weight: bold;
    color: #606060;
  }
`;

export const WrapperSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const WrapperContent = styled.div`
  width: 56%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperButton = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    gap: 20px;
    justify-content: center;
  }
`;

export const Button = styled.button`
  margin-bottom: 14px;
  min-height: 48px;
  color: white;
  border: none;
  border-radius: 8px;
  min-width: 146px;
  background-color: #00726d;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;

  @media (max-width: 768px) {
    min-width: 151px;
    margin: 0;
  }
`;

export const ButtonDisable = styled.button`
  margin-bottom: 14px;
  min-height: 48px;
  color: white;
  border: none;
  border-radius: 8px;
  min-width: 146px;
  background-color: #606060;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  @media (max-width: 768px) {
    min-width: 151px;
    margin: 0;
  }
`;

export const ButtonActive = styled.button`
  margin-bottom: 14px;
  min-height: 48px;
  color: white;
  border: none;
  border-radius: 8px;
  min-width: 146px;
  background-color: #00403d;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;

  @media (max-width: 768px) {
    min-width: 151px;
    margin: 0;
  }
`;

export const InstallmentsValue = styled.div`
  align-items: center;
  border: 1px solid;
  min-height: 48px;
  border-radius: 8px;
  text-align: center;
  vertical-align: middle;
  margin-top: 10px;
  font-size: 18px;

  display: flex;
  justify-content: space-between;
  padding: 10px 10px;

  @media (max-width: 768px) {
    font-size: inherit;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  p {
    color: #606060;
    font-size: 14px;
  }
`;
export const InstallmentSelect = styled.select`
  width: 190px;
  text-align: center;
  margin: 0;

  option {
    width: 100px;
    text-align: start;
  }
`;

import styled from 'styled-components';

export const Session1 = styled.section`
  margin-top: 100px;
  @media (max-width: 992px) {
    margin-top: 30px;
  }
  h1 {
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    padding-bottom: 8px;
    @media (max-width: 992px) {
      font-size: 24px !important;
    }
  }
  b {
    color: #707070;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
  p {
    color: #707070;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`;

export const Session2 = styled.section`
  margin-top: 100px;
  @media (max-width: 992px) {
    margin-top: 30px;
    img:last-child {
      margin-top: 30px;
    }
  }
  p {
    padding-top: 100px;
    font-size: 16px;
    font-weight: bold;
    color: #252525;
    @media (max-width: 992px) {
      font-size: 14px;
      padding-top: 10px;
    }
  }
  span {
    color: #707070;
    font-size: 16px;
    line-height: 35px;
    padding-bottom: 100px;
    @media (max-width: 992px) {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 20px;
    }
  }
  a {
    font-size: 16px;
    font-weight: bold;
    color: #006cff;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
  .divider {
    margin-bottom: 100px;
    @media (max-width: 992px) {
      margin-bottom: 30px;
    }
  }
  u {
    font-weight: bold;
  }
`;

export const Session3 = styled.section`
  margin-top: 30px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#50EBE6')};
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    margin-top: 30px;
    div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        margin-top: 8px;
      }
    }
  }
  h2 {
    color: #008d8c;
    font-size: 40px !important;
    font-weight: bold !important;
    @media (max-width: 992px) {
      font-size: 24px !important;
    }
  }
`;

export const Col2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  button:first-child {
    width: 230px;
  }
  button:last-child {
    width: 242px;
  }
`;

export const Session4 = styled.section`
  padding-top: 60px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#F4F4F4')};
  padding-bottom: 30px;
  line-height: 26px;
  @media (max-width: 992px) {
    padding-top: 30px;
    padding-bottom: 15px;
  }
  p {
    color: #707070;
    font-size: 16px;
    padding-bottom: 4px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`;

export const ContainerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const Circle = styled.div`
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: ${props =>
    props.borderColor ? `4px solid ${props.borderColor}` : '4px solid #f17eb1'};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    width: 290px;
    height: 290px;
  }
`;

export const CircleContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 210px;
  white-space: pre-wrap;
  @media (max-width: 992px) {
    max-width: 150px;
  }
`;

export const Title = styled.p`
  margin: 0 0 50px 0;

  padding: 0;
  @media (max-width: 992px) {
    font-size: 14px;
    margin-top: 20px;
  }
`;

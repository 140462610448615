import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_OAF_URL,
  headers: {
    'content-type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
});

export default instance;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { kebabCase } from 'lodash';
import CollectionApi from '~/services/CollectionApi';
import { tagMiAjudaCallback } from '~/services/tagging';

const SIMULATION_KEY = 'simulation';
const STALE_TIME = 5 * 60 * 1000; // 5 minutes
const TIMES_TO_RETRY = 3;

const queryFn = async (body, type) => {
  const response = await CollectionApi.postSimulation(body, type);

  if (response.error) {
    tagMiAjudaCallback('simulation', kebabCase(response.message));
    throw new Error(response.message ?? response.error);
  }

  if (!response?.detail) {
    tagMiAjudaCallback('simulation', kebabCase(response.message));
    return;
  }

  return response.detail;
};

const prefetchNextSimulation = async (queryClient, body, type) => {
  const { tokenEntry, installmentsNumber } = body;

  if (installmentsNumber) {
    await queryClient.prefetchQuery(
      [SIMULATION_KEY, tokenEntry, installmentsNumber],
      { queryFn: () => queryFn(body, type), staleTime: STALE_TIME }
    );
  }
};

const useSimulation = ({
  entry,
  token,
  type,
  discount,
  installmentNumber,
  maxInstallmentNumber,
}) => {
  const installmentsNumberQueryKey = installmentNumber;

  const requestBody = {
    entryAmount: entry,
    installmentsNumber: installmentNumber || 0,
    discount: {
      chargeRate: discount?.maxChargesRate || 0,
      mainRate: discount?.maxMainRate || 0,
    },
    tokenEntry: token,
  };

  const queryClient = useQueryClient();

  if (maxInstallmentNumber > installmentsNumberQueryKey)
    prefetchNextSimulation(
      queryClient,
      {
        ...requestBody,
        installmentsNumber: installmentNumber + 1,
      },
      type
    ).then();

  return useQuery([SIMULATION_KEY, token, installmentsNumberQueryKey], {
    queryFn: () => queryFn(requestBody, type),
    staleTime: STALE_TIME,
    retry: TIMES_TO_RETRY,
  });
};

export default useSimulation;

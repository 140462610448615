export const faqApps = [
  {
    id: 0,
    title:
      "Como posso <strong style='font-weight:900;'>CONSULTAR A MINHA FATURA</strong> do Cartão Riachuelo?",
  },
  {
    id: 1,
    title:
      "Como posso <strong style='font-weight:900;'>PAGAR A MINHA FATURA</strong> do Cartão Riachuelo?",
  },
  {
    id: 2,
    title:
      "Como posso <strong style='font-weight:900;'>PARCELAR A MINHA FATURA</strong> do Cartão Riachuelo?",
  },
  {
    id: 3,
    title:
      "Como posso <strong style='font-weight:900;'>NEGOCIAR A MINHA DÍVIDA</strong> do Cartão Riachuelo?",
  },
  {
    id: 4,
    title:
      "Como posso <strong style='font-weight:900;'>CONSULTAR O LIMITE</strong> do Cartão Riachuelo?",
  },
  {
    id: 5,
    title:
      "Como posso <strong style='font-weight:900;'>ALTERAR A SENHA</strong> do Cartão Riachuelo?",
  },
  {
    id: 6,
    title:
      "O que fazer <strong style='font-weight:900;'>EM CASO DE PERDA, ROUBO OU FURTO</strong> do Cartão Riachuelo?",
  },
  {
    id: 7,
    title:
      "Como posso <strong style='font-weight:900;'>SOLICITAR</strong> um Cartão Riachuelo?",
  },
  {
    id: 8,
    title:
      "Como posso <strong style='font-weight:900;'>PAGAR A PARCELA</strong> do meu Empréstimo?",
  },
  {
    id: 9,
    title:
      "Como posso <strong style='font-weight:900;'>NEGOCIAR A MINHA DÍVIDA</strong> de Empréstimo?",
  },
  {
    id: 10,
    title:
      "Como posso <strong style='font-weight:900;'>SIMULAR E CONTRATAR</strong> um Empréstimo?",
  },
];

export const productsAndServices = [
  {
    id: 0,
    icon: 'recarga',
    title: 'Tudo pelo app',
    description:
      'Consulte o limite de crédito e acompanhe a fatura do seu cartão Riachuelo.',
  },
  {
    id: 1,
    icon: 'pagamentos',
    title: 'Conta Remunerada e Premiada',
    description: 'Rendimento automático de 103% do CDI. E sorteios todo mês.',
  },
  {
    id: 2,
    icon: 'chat',
    title: 'Atendimento',
    description: 'Use o chat no app para tirar dúvidas. Todos os dias.',
  },
  {
    id: 3,
    icon: 'pagamentos',
    title: 'Empréstimos',
    description: 'Contrate empréstimos direto no app.',
  },
  {
    id: 4,
    icon: 'transferencia',
    title: 'Saques e transferências',
    description: 'Faça transferências ilimitadas e saques na rede 24H.',
  },
  {
    id: 5,
    icon: 'pagamentos',
    title: 'Descontos na Riachuelo',
    description: 'Aproveite cupons e descontos da sua marca favorita.',
  },
];

import React, { useState, useRef } from 'react';
import { Icon } from '@midway/web-ui-component';
import { List, IconChevron, ButtonWrapper, RightIconContainer } from './style';

// eslint-disable-next-line react/prop-types
const Accordion = ({ title, content, rightIcon, onToggle = () => {} }) => {
  const [isActive, setIsActive] = useState(false);
  const [height, setHeight] = useState(0);

  const contentRef = useRef(null);

  function toggleAccordion() {
    const willBeActive = !isActive;
    onToggle(willBeActive);
    setIsActive(willBeActive);
    setHeight(willBeActive ? contentRef.current.scrollHeight : 0);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
      event.preventDefault(); // Impede o comportamento padrão da tecla espaço (scroll).
    }
  }

  const accordionClassName = isActive ? 'accordion active' : 'accordion';

  return (
    <List>
      <div
        className={accordionClassName}
        onClick={toggleAccordion}
        onKeyDown={handleKeyPress}
        tabIndex={0}
        role="button"
        aria-expanded={isActive}
      >
        {!!rightIcon && <RightIconContainer>{rightIcon}</RightIconContainer>}
        <p className="accordion-title">{title}</p>
        <ButtonWrapper>
          <IconChevron>
            <Icon
              icon={['fa', 'chevron-down']}
              size="2x"
              rotate180={isActive}
              color="#000"
            />
          </IconChevron>
        </ButtonWrapper>
      </div>
      <div
        ref={contentRef}
        style={{ maxHeight: `${height}px`, marginBottom: 16 }}
        className={`accordion-content ${isActive ? 'active' : ''}`}
      >
        <div
          className="accordion-text"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </List>
  );
};

export default Accordion;

import {
  AccordionContainer,
  BodyContainer,
  Dropdown,
  HeaderAndSubheader,
  HeaderContainer,
  HeaderImage,
  HeaderImageContainer,
} from './style';
import { useEffect, useState } from 'react';

import AccordionTrigger from './AccordionTrigger';

const Accordion = ({
  bodyContent,
  headerContent,
  headerImage,
  onDropdownClick,
  isOpen = false,
  subheaderContent,
  triggerDisabled,
  triggerChevron = false,
  ...props
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleOpen = () => {
    onDropdownClick && onDropdownClick();
    setOpen(prev => !prev);
  };

  return (
    <AccordionContainer {...props}>
      <Dropdown
        transitionTime={50}
        open={isOpen}
        triggerDisabled={triggerDisabled}
        trigger={
          <HeaderAndSubheader onClick={handleOpen}>
            <HeaderImageContainer>
              {headerImage && <HeaderImage headerImage={headerImage} />}
              <HeaderContainer>
                {headerContent}
                {!triggerDisabled && (
                  <AccordionTrigger
                    open={open}
                    triggerChevron={triggerChevron}
                  />
                )}
              </HeaderContainer>
            </HeaderImageContainer>
            {subheaderContent}
          </HeaderAndSubheader>
        }
      >
        <BodyContainer>{bodyContent}</BodyContainer>
      </Dropdown>
    </AccordionContainer>
  );
};

export default Accordion;

import React, { memo, useEffect, useMemo, useState } from 'react';
import useClipboard from 'react-hook-clipboard';
import { Button, Modal } from '@midway/web-ui-component';

import { PAYMENT_METHOD } from '~/constants/installment';
import format from '~/services/format';
import { FLAGS } from '~/constants/cards';
import {
  trackingSelectContent,
  trackingViewContent,
  trackingException,
} from '~/analytics';
import {
  flow,
  contentPage as contentPageConstants,
  contentType,
  description,
} from '~/analytics/cards';
import useGetPixQRCode from '~/hooks/useGetPixQRCode';

import { ModalHeader } from '../ModalHeader';
import {
  Container,
  Title,
  Header,
  Box,
  TotalInstallmentContainer,
  Row,
  Label,
  Value,
  PaylineContainer,
  Badge,
  EntryBilletInfoContainer,
  EntryBilletInfoRow,
  CopyPaylineContainer,
  BottomContainer,
  CreditCardIcon,
  CalendarIcon,
  CashIcon,
  Divider,
  AnimatedBlurredQRCode,
  QRCodeImage,
  ErrorWrapper,
  QRCodeTryAgainIcon,
} from './styles';

export const InstallmentSuccess = memo(
  ({
    flag,
    cardLastFourDigits,
    installmentValue,
    totalValue,
    entryValue,
    amountOf,
    dueDate,
    paymentMethod,
    payline,
    pixId,
    onGoBackButtonPress,
    onNewInstallmentButtonPress,
    isActiveInstallment,
    isSimulatedInstallment,
  }) => {
    const [, copyToClipboard] = useClipboard();
    const [paylineCopied, setPaylineCopied] = useState(false);
    const [fetchPixQRCode, { loading, error: isPixQRCodeError, data: qrCode }] =
      useGetPixQRCode();

    const isPaymentByPix = paymentMethod === PAYMENT_METHOD.PIX;
    const isPaymentByBillet = paymentMethod === PAYMENT_METHOD.BILLET;

    const contentPage = (() => {
      if (isActiveInstallment) {
        return contentPageConstants.installmentSuccessFromActiveInstallment;
      }
      if (isSimulatedInstallment) {
        return contentPageConstants.installmentSuccessFromSimulation;
      }
      return contentPageConstants.installmentSuccess;
    })();
    const analyticsOtherParams = {
      valor_de_entrada: entryValue,
      numero_de_parcelas: `${amountOf}x`,
      valor_das_parcelas: installmentValue,
      valor_do_pagamento: totalValue,
      forma_de_pagamento: isPaymentByPix ? 'pix' : 'boleto',
    };

    useEffect(() => {
      if (isPaymentByPix) {
        fetchPixQRCode({ dueDate, pixId });
      }
    }, [dueDate, fetchPixQRCode, isPaymentByPix, pixId]);

    useEffect(() => {
      if (isPixQRCodeError) {
        trackingException(description.getPixQRCodeError, flow.payments);
      }
    }, [isPixQRCodeError]);

    useEffect(() => {
      trackingViewContent(contentPage, flow.payments, analyticsOtherParams);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entryValue, amountOf, installmentValue, totalValue, isPaymentByPix]);

    const handleFetchPixQRCode = () => {
      fetchPixQRCode({ dueDate, pixId });
    };

    const installmentDescription = useMemo(
      () =>
        `${amountOf}x de ${format.currency(
          installmentValue
        )} (${format.currency(totalValue)})`,
      [amountOf, installmentValue, totalValue]
    );
    const titleFormatted = `Cartão ${FLAGS[flag]} - Final ${cardLastFourDigits}`;
    const copyButtonStyles = paylineCopied
      ? {
          leftIcon: ['fas', 'check'],
          state: 'dark',
          title: 'Código copiado',
          disabled: true,
        }
      : {
          leftIcon: ['far', 'clone'],
          rotate: '90',
          title: `Copiar código ${isPaymentByPix ? 'PIX' : 'do boleto'}`,
        };

    const handleCopyPayline = () => {
      const analyticsContentType = isPaymentByPix
        ? contentType.copyPixCopyAndPasteCode
        : contentType.copyPayline;
      trackingSelectContent(
        analyticsContentType,
        flow.payments,
        contentPage,
        analyticsOtherParams
      );
      copyToClipboard(payline);
      setPaylineCopied(true);
      setTimeout(() => {
        setPaylineCopied(false);
      }, 3000);
    };

    const handleGoBackButtonPress = () => {
      trackingSelectContent(contentType.goBack, flow.payments, contentPage);
      onGoBackButtonPress();
    };

    const handleNewInstallmentButtonPress = () => {
      trackingSelectContent(
        contentType.newInstallment,
        flow.payments,
        contentPage
      );
      onNewInstallmentButtonPress();
    };

    const formattedDueDate = useMemo(() => format.date(dueDate), [dueDate]);
    const isPixQRCodeLoading = isPaymentByPix && loading;

    return (
      <Modal
        title={<ModalHeader title="PARCELAMENTO DE FATURA" />}
        size="lg"
        openModal
        onHide={() => {}}
      >
        <Container>
          <Title>
            <CreditCardIcon />
            {titleFormatted}
          </Title>
          <Header>Informações do parcelamento</Header>
          <Box>
            <Row>
              <TotalInstallmentContainer>
                <Label>Total parcelado</Label>
                <Header>{installmentDescription}</Header>
              </TotalInstallmentContainer>
            </Row>
            <Divider />
          </Box>
          <Header>{`Pagamento ${isPaymentByPix ? 'PIX' : 'Boleto'}`}</Header>
          <Box>
            <Label>Entrada parcelamento de fatura</Label>
            <Row>
              <EntryBilletInfoContainer>
                <EntryBilletInfoRow>
                  <CalendarIcon />
                  <Label>Data de vencimento:</Label>
                  <Value>{formattedDueDate}</Value>
                </EntryBilletInfoRow>
                <EntryBilletInfoRow>
                  <CashIcon />
                  <Label>Valor:</Label>
                  <Value>{format.currency(entryValue)}</Value>
                </EntryBilletInfoRow>
                <EntryBilletInfoRow>
                  <Badge>
                    Esse valor deve ser pago para efetivar o parcelamento
                  </Badge>
                </EntryBilletInfoRow>
              </EntryBilletInfoContainer>
              {isPaymentByBillet && (
                <PaylineContainer>{payline}</PaylineContainer>
              )}
              {isPixQRCodeLoading && (
                <AnimatedBlurredQRCode data-testid="img-animated-qrcode-loading" />
              )}
              {isPixQRCodeError && (
                <ErrorWrapper>
                  <AnimatedBlurredQRCode
                    data-testid="img-animated-qrcode-error"
                    pause="true"
                  />
                  <QRCodeTryAgainIcon
                    icon="download"
                    size="3x"
                    color="black"
                    onClick={handleFetchPixQRCode}
                  />
                </ErrorWrapper>
              )}
              {qrCode && <QRCodeImage src={qrCode} alt="QRCode Pix" />}
            </Row>
            <CopyPaylineContainer>
              <Button
                block
                stateIcon="secundary"
                onClick={handleCopyPayline}
                {...copyButtonStyles}
              />
            </CopyPaylineContainer>
          </Box>
          <BottomContainer>
            <Button outline title="Voltar" onClick={handleGoBackButtonPress} />
            <Button
              outline
              title="Novo parcelamento"
              onClick={handleNewInstallmentButtonPress}
            />
          </BottomContainer>
        </Container>
      </Modal>
    );
  }
);

InstallmentSuccess.displayName = 'InstallmentSuccess';

import styled from 'styled-components';
import SecondBackground from '~/assets/img/Indications/bg2.png';

export const SecondBanner = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url(${SecondBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 50px 0;

  .container {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 30px 0;
    }
  }

  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;

    img {
      object-fit: contain;

      @media screen and (max-width: 768px) {
        align-self: center;
      }
    }
  }

  .left-side {
    width: 40%;

    img {
      width: 50%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;

      img {
        width: 100%;
      }

      a {
        max-width: 100%;
      }
    }
  }

  .right-side {
    align-items: flex-start;
    flex-direction: column;
    width: 60%;

    div h2 {
      width: 100%;
    }
    img {
      width: 60%;

      @media screen and (max-width: 1160px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 1160px) {
      flex-direction: column;
      width: 100% !important;

      div {
        flex-direction: column !important;
        width: 100%;

        h2 {
          width: 100%;
          line-height: 40px !important;
        }
      }

      h2 {
        width: 100%;
      }
    }
  }

  .right-side div {
    display: flex;
    flex-direction: row;
  }

  b {
    font-weight: bold !important;
  }

  p.title {
    font: bold 80px 'Larsseit-ExtraBold' !important;
    color: #000;
    line-height: 70px !important;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
      font: bold 36px 'Larsseit-ExtraBold' !important;
      letter-spacing: 0px;
      color: #000000;
      width: 100% !important;
    }
  }

  p.subtitle {
    margin-top: 50px;
    font-size: 30px !important;
    color: #000000;

    @media screen and (max-width: 768px) {
      line-height: 40px !important;
      padding-right: 0px;
      margin-top: 10px;
      font: 26px Montserrat !important;
      width: 170px;
    }
  }

  p.subtitle-link {
    font-size: 20px !important;
  }

  a {
    background: #000000;
    padding: 20px 0px;
    border-radius: 50px;
    color: #47c8cd;
    font-size: 15px;
    max-width: 300px;
    text-align: center;
    margin-top: 30px;
    transition: all 0.2s ease-out;
    &:hover {
      margin-top: 27px;
      margin-bottom: 2px;
      text-decoration: none;
      color: #47c8cd;
    }
  }
`;

export const LinkApp = styled.div`
  display: flex;

  a {
    padding: 0;
    margin: 30px 5px;
  }
`;

export const Link = styled.strong`
  font-weight: bold;
  text-decoration: underline !important;
  color: #000;
  cursor: pointer;
`;

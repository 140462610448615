import React from 'react';
import { Spinner } from '@midway/web-ui-component';
import NotAvailableLoan from '../../../NotAvailableLoan';

function LoanNotAvailable(props) {
  if (props.loading) {
    return (
      <div className="align-self-center">
        <Spinner animation="border" state="primary" />
      </div>
    );
  }
  if (!props.loading && props.loanAvailable) {
    return props.children;
  }
  if (!props.loading && !props.loanAvailable) {
    return <NotAvailableLoan />;
  }
  return <></>;
}
export default LoanNotAvailable;

import React, { useEffect, useState } from 'react';

import LoanApi from '~/services/LoanApi';
import * as S from './styles';

const initialAdvantages = [
  {
    name: 'Morte',
    description:
      'Garante o pagamento do saldo devedor do cliente junto ao Estipulante, Midway S.A. – Crédito Financiamento e Investimento, limitado ao valor máximo de indenização, em caso de morte do Segurado decorrente de causas naturais ou acidentais.',
    gracePeriod: 'Não há',
    franchise: 'Não há',
    indemnity: 'Quitação saldo até R$ 15 mil',
  },
  {
    name: 'Invalidez Permanente Total por Acidente',
    description:
      'Garante o pagamento do saldo devedor do cliente junto ao Estipulante, Midway S.A. – Crédito Financiamento e Investimento limitado ao valor máximo de indenização, em caso de invalidez total por acidente do Segurado.',
    gracePeriod: 'Não há',
    franchise: 'Não há',
    indemnity: 'Quitação saldo até R$ 15 mil',
  },
  {
    name: 'Incapacidade Física Total e Temporária',
    description:
      'Garante o pagamento do saldo devedor do cliente junto ao Estipulante, Midway S.A. – Crédito Financiamento e Investimento limitado ao valor máximo de indenização, em caso de incapacidade física total e temporária do Segurado.',
    gracePeriod: '60 dias',
    franchise: '15 dias',
    indemnity: 'Quitação até 6 parcelas R$ 1800',
  },
];

export default function LoanAdvantages(_) {
  const [advantages, setAdvantages] = useState(initialAdvantages);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getPlansCoverage = async () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const loanAmount = params.get('loanAmount');
    const categoryId = params.get('categoryId');
    const channelId = params.get('channelId');

    try {
      setError(false);
      setLoading(true);
      const response = await LoanApi.getLoanInsurance(
        loanAmount,
        categoryId,
        channelId
      );

      if (response.error || !response) {
        return;
      }

      setAdvantages(response);
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getPlansCoverage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.Header />

      <S.Body>
        {loading ? (
          <p>Carregando...</p>
        ) : (
          <>
            {error ? (
              <h1>
                Ocorreu um erro! <br />
                tente novamente mais tarde.
              </h1>
            ) : (
              <>
                <h1>Vantagens da Proteção do Empréstimo</h1>

                <p>
                  Com a Proteção Empréstimo você e seus familiares tem a
                  garantia de pagamento das parcelas em caso de imprevistos
                  citados na tabela abaixo
                </p>

                <S.Table>
                  <S.Row>
                    <div>&nbsp;</div>
                    <div className="check">
                      <p>Sem Proteção</p>
                    </div>
                    <div className="check">
                      <p>Com Proteção</p>
                    </div>
                  </S.Row>
                  {!error &&
                    advantages.map((item, i) => (
                      <S.Row className={(i + 1) % 2 !== 0 ? 'grey' : ''}>
                        <div>
                          <p>{item.name}</p>
                        </div>
                        <div className="nope">
                          <p>x</p>
                        </div>
                        <div className="check">
                          <p>✔</p>
                        </div>
                      </S.Row>
                    ))}
                </S.Table>

                <h1>Riscos cobertos</h1>

                <div className="cards">
                  {!error &&
                    advantages.map(item => (
                      <S.Card>
                        <h2>{item.name}</h2>

                        <div className="cardBody">
                          <p>{item.description}</p>

                          <div className="row-grey">
                            <div>
                              <p className="green">Carência:</p>
                            </div>
                            <div>
                              <p>{item.gracePeriod}</p>
                            </div>
                          </div>
                          <div className="row-white">
                            <div>
                              <p className="green">Franquia:</p>
                            </div>
                            <div>
                              <p>{item.franchise}</p>
                            </div>
                          </div>
                          <div className="row-grey last">
                            <div>
                              <p className="green">
                                Limite máximo de indenização:
                              </p>
                            </div>
                            <div>
                              <p>{item.indemnity}</p>
                            </div>
                          </div>
                        </div>
                      </S.Card>
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </S.Body>
    </S.Container>
  );
}

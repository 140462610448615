import React from 'react';

import { Button } from '@midway/web-ui-component';
import * as S from './styles';

export default function LoanBenefits() {
  return (
    <S.Container>
      <S.Header>
        <h1>Empréstimo Pessoal</h1>
        <p>O seu bem-estar financeiro é da nossa conta</p>
      </S.Header>
      <S.Body>
        <S.List>
          <S.Card>
            <S.Tag>Melhor oferta pra você</S.Tag>
            <h2>Emprestimo pessoal com proteção</h2>

            <ul>
              <li>Crédito rápido sem burocracia</li>
              <li>Dinheiro na hora contratando direto no app</li>
              <li>
                Empréstimo sem nenhum tipo de garantia. Taxa e juros fixos
              </li>
              <li>Seguro do seu empréstimo</li>
            </ul>

            <h4>Benefícios de sua proteção</h4>

            <p>Valor do empréstimo</p>
            <h3>
              <span>R$</span>16.500,00
            </h3>
            <p className="parcelas">
              Seguro por <strong>R$6,20</strong> na parcela
              <br />
              Parcelas com seguro:
              <br />
              <strong>24x de R$ 672,86</strong>
            </p>

            <span className="check">✔</span>
          </S.Card>
        </S.List>

        <p>Selecione uma opção para continuar</p>
        <Button
          title="Continuar"
          aria-label="Continuar"
          block
          size="sm"
          onClick={() => {}}
        />
      </S.Body>
    </S.Container>
  );
}

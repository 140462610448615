import React, { memo, useState } from 'react';
import { Icon } from '@midway/web-ui-component';
import format from '~/services/format';

import {
  CollapsableContainer,
  Header,
  HeaderContainer,
  InstallmentFeeContainer,
  Row,
  Bold,
  Text,
  CETBox,
} from './styles';

export const Details = memo(
  ({ taxValue, taxRate, iofValue, iofRate, CetRatePerYear }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleDetailsPress = () => setIsExpanded(prev => !prev);

    return (
      <>
        <HeaderContainer onClick={handleDetailsPress}>
          <Header>Detalhamento</Header>

          <Icon icon="chevron-down" />
        </HeaderContainer>
        {isExpanded && (
          <CollapsableContainer>
            <Row>
              <InstallmentFeeContainer>
                <Row>
                  <Bold>Juros por parcelamento</Bold>
                  <Bold>{format.currency(taxValue)}</Bold>
                </Row>
                <Text>{taxRate}% do valor parcelado</Text>
              </InstallmentFeeContainer>
              <div>
                <Row>
                  <Bold>IOF (Imposto sobre operações financeiras)</Bold>
                  <Bold>{format.currency(iofValue)}</Bold>
                </Row>
                <Text>{iofRate}% do valor parcelado</Text>
              </div>
            </Row>
            <CETBox>
              <Bold>CET: {CetRatePerYear}% ao ano</Bold>
            </CETBox>
          </CollapsableContainer>
        )}
      </>
    );
  }
);

import styled from 'styled-components';

const COLUMN_GAPS = {
  MOBILE: 8,
  TABLET: 22,
  DESKTOP: 32,
};

const VERTICAL_GAP = 16;

export const Section = styled.div`
  background: #252525;
  margin-bottom: 40px;
  position: relative;
  padding: 28px 0 21px;
  @media screen and (min-width: 900px) {
    margin-bottom: 72px;
    padding: 40px 0;
  }
`;

export const SectionBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  column-gap: ${COLUMN_GAPS.DESKTOP}px;
  row-gap: ${VERTICAL_GAP}px;

  @media (max-width: 900px) {
    margin-top: inherit;
    transform: initial;
    column-gap: ${COLUMN_GAPS.TABLET}px;
  }

  @media (max-width: 492px) {
    justify-content: center;
    width: 100%;
    column-gap: ${COLUMN_GAPS.MOBILE}px;
  }

  > div,
  a {
    min-width: 80px;
    min-height: 80px;
    flex: 0 0 calc(33% - ${COLUMN_GAPS.MOBILE}px);
    max-width: calc(33% - ${COLUMN_GAPS.MOBILE}px);
    @media screen and (min-width: 492px) {
      flex: 0 0 calc(33% - ${COLUMN_GAPS.TABLET}px);
      max-width: calc(33% - ${COLUMN_GAPS.TABLET}px);
    }
    @media screen and (min-width: 900px) {
      min-height: 134px;
      flex: 0 0 calc(20% - ${COLUMN_GAPS.DESKTOP}px);
      max-width: calc(20% - ${COLUMN_GAPS.DESKTOP}px);
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      flex: 0 0 calc(50% - ${COLUMN_GAPS.MOBILE}px);
      max-width: calc(50% - ${COLUMN_GAPS.MOBILE}px);

      @media screen and (min-width: 492px) {
        flex: 0 0 calc(50% - ${COLUMN_GAPS.TABLET}px);
        max-width: calc(50% - ${COLUMN_GAPS.TABLET}px);
      }
      @media screen and (min-width: 900px) {
        flex: 0 0 calc(20% - ${COLUMN_GAPS.DESKTOP}px);
        max-width: calc(20% - ${COLUMN_GAPS.DESKTOP}px);
      }
    }
    &:focus {
      border: 3px solid #a06d2c;
    }
  }
`;

export const SectionHeader = styled.div`
  padding-bottom: 20px;
  h2 {
    color: #fff;
    font-style: normal;
  }
`;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@midway/web-ui-component';
import {
  MaskedInput,
  MASK_TYPES,
} from '~/components/Common/Inputs/MaskedInput';
import { validarCPF, validarDataNascimento } from '~/utils/utils';
import { trackingSelectContent } from '~/analytics';
import { flow, contentPage, contentType } from '~/analytics/cards';

import * as Styles from './styles';

const BankSlipFormEntry = ({
  documentNumber: dn,
  birthDate: bd,
  type: tp,
  onSubmit,
  loading,
}) => {
  const [documentNumber, setDocumentNumber] = useState(dn);
  const [birthDate, setBithDate] = useState(bd);
  const [type, setType] = useState(tp);

  const firesSelectContent = taggingContentType =>
    trackingSelectContent(
      taggingContentType,
      flow.payments,
      contentPage.billetInvoiceOrLending
    );

  const handleValueChange = event => {
    const { value } = event.target;
    if (value === 'invoice') {
      firesSelectContent(contentType.checkInvoice);
    } else {
      firesSelectContent(contentType.checkLoan);
    }
    setType(value);
  };

  const handleNegociationButtonPress = () =>
    firesSelectContent(contentType.paymentNegociation);

  const onSubmitForm = event => {
    if (type === 'invoice') {
      firesSelectContent(contentType.generateBilletInvoice);
    } else {
      firesSelectContent(contentType.generateBilletLoan);
    }
    event.preventDefault();
    if (onSubmit) {
      onSubmit({
        documentNumber,
        birthDate,
        type,
      });
    }
  };

  return (
    <>
      <Styles.Card onSubmit={onSubmitForm}>
        <Styles.Title>
          Acesse e pague a sua fatura do cartão RCHLO ou o seu empréstimo.
        </Styles.Title>
        <Styles.SubTitle>O que você quer acessar?</Styles.SubTitle>

        <Styles.RadiusContainer>
          <Styles.InputRadioContainer>
            <Styles.InputRadio
              aria-label="Boleto fatura cartão riachuelo"
              checked={type === 'invoice'}
              onChange={handleValueChange}
              disabled={loading}
              type="radio"
              id="invoice"
              name="invoice"
              value="invoice"
            />
            <Styles.LabelRadio htmlFor="invoice">
              Fatura cartão RCHLO/Midway
            </Styles.LabelRadio>
          </Styles.InputRadioContainer>
          <Styles.InputRadioContainer>
            <Styles.InputRadio
              aria-label="Boleto fatura cartão riachuelo"
              checked={type === 'loan'}
              onChange={handleValueChange}
              disabled={loading}
              type="radio"
              id="loan"
              name="loan"
              value="loan"
            />
            <Styles.LabelRadio htmlFor="loan">Empréstimo</Styles.LabelRadio>
          </Styles.InputRadioContainer>
        </Styles.RadiusContainer>

        <Styles.SubTitle>
          Insira abaixo seus dados. É rápido e fácil!
        </Styles.SubTitle>

        <Styles.InputTextsContainer>
          <MaskedInput
            id="input-cpf"
            maskType={MASK_TYPES.CPF}
            placeholder="Insira o CPF do titular"
            pressOnKeyDown={validarCPF(documentNumber)}
            maxLength="14"
            disabled={loading}
            value={documentNumber}
            aria-required="true"
            setValue={setDocumentNumber}
            name="cpf"
          />
          <Styles.MaskedInputLabel
            show={documentNumber?.length >= 11 && !validarCPF(documentNumber)}
            htmlFor="input-cpf"
          >
            Insira um CPF válido.
          </Styles.MaskedInputLabel>
          <MaskedInput
            id="input-birth-date"
            maskType={MASK_TYPES.BIRTHDATE}
            placeholder="Data de Nascimento"
            maxLength="10"
            disabled={loading}
            pressOnKeyDown={() => null}
            value={birthDate}
            aria-required="true"
            setValue={setBithDate}
            name="data-nascimento"
          />
          <Styles.MaskedInputLabel
            show={birthDate?.length >= 10 && !validarDataNascimento(birthDate)}
            htmlFor="input-birth-date"
            className="text-error"
          >
            Insira uma data válida.
          </Styles.MaskedInputLabel>
        </Styles.InputTextsContainer>

        <Styles.ButtonContainer>
          {loading && (
            <Styles.IconLoading>
              <Icon color="white" icon="spinner" size="2x" />
            </Styles.IconLoading>
          )}
          <Styles.Button
            loading={loading}
            value={loading ? '' : 'Entrar'}
            type="submit"
            disabled={
              documentNumber?.length < 11 ||
              !validarCPF(documentNumber) ||
              birthDate?.length < 10 ||
              !validarDataNascimento(birthDate) ||
              !type ||
              loading
            }
            aria-label="Entrar"
          />
        </Styles.ButtonContainer>

        <Styles.FooterInfo>
          Se a sua fatura do Cartão RCHLO estiver vencida, você pode consultar
          opções de{' '}
          <Link
            onClick={handleNegociationButtonPress}
            to="/campanha-quitacao?utm_source=Conta-Online&utm_medium=Website&utm_campaign=quitacao-de-divida&utm_term=Conta-Online&utm_content=Home"
          >
            negociação de pagamento.
          </Link>
        </Styles.FooterInfo>
      </Styles.Card>
    </>
  );
};

BankSlipFormEntry.propTypes = {
  type: PropTypes.oneOf(['invoice', 'loan']).isRequired,
  documentNumber: PropTypes.string.isRequired,
  birthDate: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default React.memo(BankSlipFormEntry);

import styled from 'styled-components';

export const StyledButton = styled.button`
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 210px;
  border: 1px solid #000;
  border-radius: 9px;
  font-size: 14px;
  font-family: Montserrat;
  margin-bottom: 20px;
  padding: 6px;
`;

export const IconDownload = styled.img`
  max-width: 25px;
  margin-right: 20px;
`;

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';
import { trackingSelectContent } from '~/analytics';
import RenderBannerImage from './components/RenderBannerImage';
import { Banner } from './data';
import GridCards from './components/GridCards';
import DigitalAccount from './components/DigitalAccount';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor href={href} target="_blank" onClick={onClick}>
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const FinancialProducts = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getHeroBannerProductsFinancial(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(Banner);
    } catch (error) {
      setBannerData(Banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banner = bannerData[0] ?? Banner;

  const handleAnchorClick = () => {
    trackingSelectContent(
      'acesse-aqui-os-ultimos-sorteados',
      'produtos-financeiros'
    );
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BackgroundContainer>
          <S.BannerContainer>
            <RenderBannerImage {...banner} />
          </S.BannerContainer>
        </S.BackgroundContainer>
        <S.CentralizeContent>
          <S.CentralizeGrid>
            <S.ContainerCards>
              <S.Title>
                Conheça todos os nossos produtos de Seguros e Assistências
              </S.Title>
              <GridCards />
            </S.ContainerCards>
          </S.CentralizeGrid>
          <S.CentralizePanel>
            <S.InformationPanel>
              <DigitalAccount />
            </S.InformationPanel>
            <S.TextFinal>
              A partir de março de 2023 os sorteios mensais de Capitalização
              foram descontinuados para os produtos: Assistências - Auto, Moto,
              Residência, Odontológica, Mais Saúde e Seguros - Celular,
              Acidentes Pessoais Individuais e Familiar. Não se preocupe caso
              você tenha seguro e/ou assistência contratado vai continuar com
              todas as coberturas vigentes.{' '}
              <Anchor
                href="https://www.midway.com.br/sorteados-do-mes"
                onClick={handleAnchorClick}
              >
                Acesse aqui os últimos sorteados.
              </Anchor>
            </S.TextFinal>
          </S.CentralizePanel>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default FinancialProducts;

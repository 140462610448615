import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: #f7f7f7;
  width: 100%;
  border-radius: 8px;
  border: 0;
  margin-bottom: 16px;

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const ContainerTryAgain = styled.div`
  width: 100%;
  background: #fff2f4;
  padding: 16px;
  border-radius: 8px;
  border: 0;
`;

export const TopContainer = styled.div`
  width: 100%;
  background: #f7f7f7;
  padding: 16px 16px 8px 16px;
  border-radius: 8px;
  border: 0;
  transition: background 0.2s linear;

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
    background: ${({ isExpanded }) => (isExpanded ? '#f7f7f7' : '#e6e6e6')};
  }
`;

export const AnimatedCard = styled.div`
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? 232 : 0)}px;
  transition:
    max-height 0.3s,
    display 0.3s;
`;

export const BottomContainer = styled.div`
  padding: 0 16px 0 16px;
`;

export const ContainerLine = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

export const InputRadioContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const InputRadio = styled.input`
  width: 20px;
  height: 20px;
  border: solid 2px;
`;

export const Spotlight = styled.span`
  color: #00726d;
`;

export const LabelRadio = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  color: ${({ isExpanded }) => (isExpanded ? '#00726d' : '#1f2b2a')};
  transition: color 0.3s linear;
  font-size: 16px;
  line-height: 16px;
  padding-left: 14px;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export const Image = styled.img`
  width: 18px;
  margin-left: 4px;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #3b4443;
  padding-left: 14px;
`;

export const Dot = styled.span`
  min-height: 25px;
  min-width: 25px;
  height: 25px;
  width: 25px;
  border: solid 2px #00726d;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
`;

export const Dot2 = styled.span`
  height: 100%;
  width: 100%;
  border: solid 2px white;
  background-color: ${({ isChecked }) =>
    isChecked ? '#00726d' : 'transparent'};
  border-radius: 50%;
  display: inline-block;
`;

export const TextBodyContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const TextBody = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
`;

export const TextBarcode = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const BarcodeContainer = styled.div`
  margin: 16px 0;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
`;

export const Button = styled.button`
  background: #00726d;
  padding: 11px 10px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border-radius: 24px;
  border: 0;
  width: 100%;
  transition:
    background 0.3s ease 0s,
    width 0.3s ease-in-out 0s;

  ${({ state }) =>
    state === 'secondary' &&
    css`
      color: #00726d;
      background: #fff;
      border: 1px solid #00726d;
    `}
  :hover {
    background: #004a47;

    ${({ state }) =>
      state === 'secondary' &&
      css`
        color: #00726d;
        background: #d6fffe;
      `}
  }

  :disabled {
    background: rgba(0, 128, 122, 0.2);
    cursor: not-allowed;
  }
`;

export const MarginBottom = styled.div`
  margin-bottom: ${({ value }) => value}px;
`;

export const TitleError = styled.p`
  color: #1f2b2a;
  padding: 8px 0 18px 0;
  font-size: 12px;
  text-align: center;
`;

export const ImageError = styled.img`
  width: 24px;
`;

export const InfoButton = styled.button`
  padding: 0 4px;
  border: 0;
  background: transparent;
`;

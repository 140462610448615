import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';
import { trackingSelectContent } from '~/analytics';
import RenderBannerImage from '~components/RenderBannerImage';
import { Banner } from './data';
import Card from './components/Card';
import TextPhoneNumber from '~/components/TextPhoneNumber';
import DownloadStore from '~/components/DownloadStore';
import ListStepNumber from '~/components/ListStepNumber';

import BagIcon from '~/assets/img/Financial/more-protection/bag-icon.svg';
import CardIcon from '~/assets/img/Financial/more-protection/card-icon.svg';
import CoinIcon from '~/assets/img/Financial/more-protection/coin-icon.svg';
import CardLossIcon from '~/assets/img/Financial/more-protection/card-loss-icon.svg';
import Iphone from '~/assets/img/Financial/more-protection/iphone.svg';
import PlayStore from '~/assets/img/lending/play-store.svg';
import AppleStore from '~/assets/img/lending/apple-store.svg';
import ZurichLogo from '~/assets/img/Financial/cell-phone-insurance/zurichLogo.png';

const PHONE_CAPITALS_AND_REGIONS = '3004 5417';
const PHONE_OTHER_LOCATIONS = '0800 727 4417';
const PHONE_SUPPORT_CAPITALS_AND_REGION = '4020 4345';
const PHONE_SUPPORT_OTHER_LOCATIONS = '0800 285 4345';
const SAC = '0800 284 4848';
const PHONE_SUPPORT_HEARING_IMPAIRED = '0800 275 8585';
const FLOW = 'seguro-bolsa-pix-e-cartao-protegido';

const STEP_LIST = [
  {
    value: '1',
    text: 'Baixe o nosso aplicativo Midway;',
  },
  {
    value: '2',
    text: 'Encontre a opção Serviços e procure por Seguros;',
  },
  {
    value: '3',
    text: 'Encontre o Seguro Bolsa, PIX e Cartão Protegido;',
  },
  {
    value: '4',
    text: 'Selecione o plano e a forma de pagamento;',
  },
  {
    value: '5',
    text: 'Confira o resumo, aceite os termos e finalize a contratação.',
  },
];

const MoreProtection = () => {
  const [bannerData, setBannerData] = useState([]);

  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerMoreProtection(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(Banner);
    } catch (error) {
      setBannerData(Banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banner = bannerData[0] || Banner;

  const handleClickPlayStore = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
      '_blank'
    );
    trackingSelectContent('download:google-play', FLOW);
  };

  const handleClickAppleStore = () => {
    window.open(
      'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
      '_blank'
    );
    trackingSelectContent('download:apple-store', FLOW);
  };

  const handleClickGeneralConditionsSuper = () => {
    trackingSelectContent(
      'resumo-das-condicoes-gerais-do-seguro-mais-protecao-plano-super',
      FLOW
    );
  };

  const handleClickGeneralConditionsMega = () => {
    trackingSelectContent(
      'resumo-das-condicoes-gerais-do-seguro-mais-protecao-plano-mega',
      FLOW
    );
  };

  const handleClickMoreProtection = () => {
    trackingSelectContent(
      'condicoes-gerais-na-integra-cobertura-de-pix-e-perda-roubo-e-furto-do-cartao',
      FLOW
    );
  };

  const handleClickGeneralConditions = () => {
    trackingSelectContent(
      'condicoes-gerais-na-integra-cobertura-de-bolsa-protegida',
      FLOW
    );
  };

  const handleClickAnchor = () => {
    trackingSelectContent('www-consumidor-gov-br', FLOW);
  };

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banner} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>
              Seguro Bolsa, PIX e Cartão Protegido - Mais Proteção
            </S.TitleCards>
            <S.subTitleCards>
              Sair de casa com a sensação de ter seus pertences protegidos agora
              é realidade, afinal, você agora pode contar com o Mais Proteção.
              Conheça nossas coberturas.
            </S.subTitleCards>
          </S.AdjustamentTitleCard>
          <S.ContainerCards>
            <Card
              icon={BagIcon}
              title="Bolsa protegida"
              description="Caso sua bolsa ou mochila seja roubada com o cartão segurado dentro, cobrimos os prejuízos do conteúdo e as transações indevidas, até o limite estabelecido."
            />
            <Card
              icon={CardIcon}
              title="Roubo ou furto qualificado do cartão"
              description="Proteção completa em caso de roubo ou furto qualificado do seu cartão. Cobrimos transações não autorizadas, mediante boletim de ocorrência e bloqueio imediato do cartão."
            />
            <Card
              icon={CoinIcon}
              title="Transações financeiras protegidas"
              description="Fique protegido contra transferências indevidas via PIX ou TED realizadas sob coação, extorsão ou sequestro relâmpago. Garantimos o reembolso de valores transferidos de forma indevida, usando senha, biometria ou outro método de autenticação. "
            />
            <Card
              icon={CardLossIcon}
              title="Perda ou furto simples do cartão"
              description="Garanta sua tranquilidade! Se seu cartão físico ou virtual for perdido ou furtado, cobrimos transações indevidas realizadas até 3 dias antes da notificação. Basta registrar um boletim de ocorrência e informar à operadora para o bloqueio imediato."
            />
          </S.ContainerCards>

          <S.AdditionalInfoCard>
            <S.CardWrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://www.midway.com.br/contrato/Resumo-CG-Plano-Super-bolsa"
                  onClick={handleClickGeneralConditionsSuper}
                  target="_blank"
                >
                  Resumo das condições gerais do Seguro Mais Proteção - Plano
                  Super{' '}
                </S.CardWrapperUnderline>
                Comercializado a partir de 21 de julho de 2022{' '}
              </S.WrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://www.midway.com.br/contrato/resumoCG-plano-mega-PIX"
                  onClick={handleClickGeneralConditionsMega}
                  target="_blank"
                >
                  Resumo das condições gerais do Seguro Mais Proteção - Plano
                  Mega{' '}
                </S.CardWrapperUnderline>
                Comercializado a partir de 21 de julho de 2022{' '}
              </S.WrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://www.midway.com.br/contrato/seguro-mais-prote%C3%A7%C3%A3o-cart%C3%A3o-pix"
                  onClick={handleClickMoreProtection}
                  target="_blank"
                >
                  Condições gerais na íntegra cobertura de PIX e perda, roubo e
                  furto do cartão{' '}
                </S.CardWrapperUnderline>
                Comercializado a partir de 21 de julho de 2022{' '}
              </S.WrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline
                  href="https://www.midway.com.br/contrato/seguro-mais-protecao-bolsa"
                  onClick={handleClickGeneralConditions}
                  target="_blank"
                >
                  Condições gerais na íntegra cobertura de Bolsa Protegida{' '}
                </S.CardWrapperUnderline>
                Comercializado a partir de 21 de julho de 2022
              </S.WrapperText>
            </S.CardWrapperText>
          </S.AdditionalInfoCard>

          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanel src={Iphone} />
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Para contratar o Seguro Bolsa, PIX e Cartão Protegido é
                  simples!{' '}
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStepNumber data={STEP_LIST} />
                </S.GroupLeft>
                <S.GroupColumn>
                  <S.TextMidway>Baixe o aplicativo Midway</S.TextMidway>
                  <S.Agroup>
                    <DownloadStore
                      iconSrc={PlayStore}
                      onClick={handleClickPlayStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Google Play</S.TextBold>
                    </DownloadStore>
                    <DownloadStore
                      iconSrc={AppleStore}
                      onClick={handleClickAppleStore}
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                    </DownloadStore>
                  </S.Agroup>
                </S.GroupColumn>
              </S.CentralizeRightPanel>
            </S.RightPanel>
          </S.InformationPanel>

          <S.ContentText>
            <S.TextContent>
              Você também pode contratar nas Lojas Riachuelo mais próximas ou,
              se preferir, pode optar pelas centrais de atendimento através dos
              telefones:
            </S.TextContent>
            <TextPhoneNumber
              text="Capitais e regiões metropolitanas:"
              phoneNumber={PHONE_CAPITALS_AND_REGIONS}
              trackingId={PHONE_CAPITALS_AND_REGIONS}
              trackingFlow={FLOW}
            />
            <TextPhoneNumber
              text="Demais localidades:"
              phoneNumber={PHONE_OTHER_LOCATIONS}
              trackingId={PHONE_OTHER_LOCATIONS}
              trackingFlow={FLOW}
            />
          </S.ContentText>

          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar o Seguro
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText paddingBottom="16px">
              Ligue para Central de Atendimento Zurich <br />
              Horário de Atendimento: Segunda a Sexta-feira, das 8h ás 20h e aos
              Sábados das 8h ás 18h, exceto feriados. <br />
            </S.CardBlackBottomText>
            <S.CardBlackBottomText>
              <S.UnderlineText>
                {PHONE_SUPPORT_CAPITALS_AND_REGION}
              </S.UnderlineText>{' '}
              (capitais e regiões metropolitanas)
              <br />
              <S.UnderlineText>
                {PHONE_SUPPORT_OTHER_LOCATIONS}
              </S.UnderlineText>{' '}
              (demais localidades) <br />
              SAC: <S.UnderlineText>{SAC}</S.UnderlineText>
              <br />
              Deficiente auditivo:{' '}
              <S.UnderlineText>
                {PHONE_SUPPORT_HEARING_IMPAIRED}
              </S.UnderlineText>
              . Horário de atendimento: 24 horas, 7 dias na semana. <br />{' '}
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>

          <S.ContentZurich>
            <S.ZurichLeft src={ZurichLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                Fundada na Suíça em 1872, com sede na cidade de Zurique, somos
                uma das seguradoras globais mais experientes do mundo. Com cerca
                de 56 mil funcionários, oferecemos uma ampla gama de produtos e
                serviços de ramos elementares e de vida em mais de 210 países e
                territórios. Atendemos pessoas físicas e jurídicas – de pequenas
                empresas a multinacionais.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>
          <S.ContentFinal>
            <S.ContentTextFinal>
              Seguro Mais Proteção garantido pela Zurich Minas Brasil Seguros
              S/A – CNPJ 17.197.385/0001-21 – Código Susep: 05495 e Processo
              Susep Perda ou Roubo do Cartão 15414.002552/2005-81. O registro do
              produto é automático e não representa aprovação ou recomendação
              por parte da Susep. Central de atendimento Zurich: SAC: 0800 284
              4848, Deficiente Auditivo: 0800 275 8585, Horário de atendimento:
              24 horas, 7 dias na semana; Ouvidoria: 0800 770 1061, Horário de
              atendimento: segunda a sexta-feira, das 8h30 às 18h, exceto
              feriados. Link da plataforma digital oficial para registro de
              reclamações dos consumidores dos mercados supervisionados
              <S.StyleAnchor
                onClick={handleClickAnchor}
                href="https://consumidor.gov.br/"
                target="_blank"
              >
                (www.consumidor.gov.br)
              </S.StyleAnchor>
              .
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default MoreProtection;

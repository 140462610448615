import styled from 'styled-components';

export const Content = styled.div`
  transition: max-height 0.5s ease-in-out;
  .TitleRoot {
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      margin-bottom: 30px;
    }

    @media screen and (min-width: 992px) {
      margin-bottom: 40px;
    }
  }
`;

export const ShowMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;
export const ShowMoreButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
export const PageWrapper = styled.div`
  @media screen and (max-width: 492px) {
    margin-top: -60px;
  }
`;

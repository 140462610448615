import styled from 'styled-components';

export const Bold = styled.strong`
  font-weight: bold;
`;

export const Modal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  #background {
    width: 100%;
    height: 100%;
    background: transparent #00000090;
    position: fixed;
    z-index: 5;
    top: 0;
  }
  .content {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #fff;
    max-width: 670px;
    z-index: 5;
    display: flex;
    padding: 50px;
    flex-direction: column;
    justify-content: center;

    h2 {
      font: bold 40px Montserrat !important;
      margin-top: 28px;
    }

    p {
      font: 24px Montserrat !important;
      margin: 28px 0;
    }

    .qrcode {
      width: 120px;
      margin-top: 20px;
      border-radius: 3px;
    }

    a {
      margin-top: 40px;
      width: 10px;
      align-self: flex-end;
      border-radius: 50%;
      .closeButtom {
        width: 50px;
      }
    }
  }
`;

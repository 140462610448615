import React from 'react';
import { Button } from '@midway/web-ui-component';
import info from '~/assets/img/Icones/triangle-info.svg';

import {
  ImageError,
  Text,
  UnavailableInstallmentContainer,
  Bold,
  BottomContainer,
} from './styles';

export const InstallmentErrorView = ({ onPressRetryButton }) => {
  return (
    <>
      <UnavailableInstallmentContainer>
        <ImageError src={info} aria-hidden="true" />
        <Bold>Algo deu errado...</Bold>
        <Text>
          Tivemos um problema e não conseguimos carregar o parcelamento
        </Text>
      </UnavailableInstallmentContainer>
      <BottomContainer>
        <Button
          block
          outline
          title="Tentar novamente"
          onClick={onPressRetryButton}
        />
      </BottomContainer>
    </>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;
export const MidwayContent = styled.div`
  padding-bottom: 50px;

  h1 {
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-top: 35px;
    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    margin: 20px 0 50px;
  }
  h4 {
    font-weight: bold;
    font-size: 14px;
    color: #252525;
  }
  ul {
    display: block;
    margin-bottom: 80px;
    > li {
      & > p {
        margin-bottom: 10px;
        color: #6f6f6f;
        & > strong {
          font-weight: bold;
        }
        u {
          display: inline;
        }
      }
      & > a,
      u {
        display: block;
        margin-top: 20px;
        color: #007bff;
        text-decoration: none;
      }
    }
  }
`;
export const ColumContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Colum = styled.div`
  width: 42%;
  h2 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const Info = styled.div`
  margin-top: 50px;
  padding-top: ${props => (props.top ? `${props.top}px` : '0px')};
  p {
    padding-bottom: 0;
    font-size: 14px;
    color: #252525;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`;

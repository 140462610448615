import React from 'react';
import styled from 'styled-components';
import trackEventGa from '../GoogleAnalytics';

const BenefitsSection = styled.section`
  padding: 24px;

  @media (min-width: 1024px) {
    padding: 80px 0;
  }
`;

const BenefitsContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (min-width: 1024px) {
    padding: 0 30px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    gap: 60px;
  }
`;

const TitleImage = styled.img`
  width: 114px;

  @media (min-width: 1024px) {
    width: 280px;
    max-height: 215px;
  }
`;

const Title = styled.h2`
  color: #061c3d;
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;

  @media (min-width: 1024px) {
    font-size: 36px;
  }
`;

const SubtitleContainer = styled.div``;

const Subtitle = styled.p`
  color: #061c3d;
  text-align: left;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

const CardsContainer = styled.div``;

const Cards = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 32px;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 50px;
    padding: 0;
  }
`;

const Card = styled.li`
  border: 2px solid #ff5e03;
  padding: 24px;
  padding-bottom: 50px;
  border-radius: 16px;
  flex: 1;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const CardImage = styled.img`
  width: 114px;
`;

const CardTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  margin: 9px 0 12px;

  @media (min-width: 1024px) {
    margin: 12px 0 24px;
  }
`;

const CardSubtitle = styled.p`
  font-size: 14px;
  text-align: left;
`;

const DescriptionContainer = styled.div``;

const Description = styled.div`
  color: #333333;
  text-align: left;
  margin: 12px 0;

  .block {
    margin-bottom: 8px !important;
    font-weight: bold;
  }

  strong {
    text-transform: uppercase;
    font-weight: bold;

    @media (min-width: 1024px) {
      text-transform: none;
      &.uppercase {
        font-size: 24px !important;
      }
    }
  }

  p {
    font-size: 16px !important;
  }
`;

const HowToItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px !important;

  @media (min-width: 1024px) {
    gap: 0;
  }
`;

const HowToItem = styled.li`
  font-size: 16px;

  .hidden {
    display: none !important;

    @media (min-width: 1024px) {
      display: inline-block;
    }
  }
  .text-orange {
    color: #ff5e03;
    font-weight: 700;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`;

const OfferedBenefits = styled.div`
  display: flex;
  flex-direction: column;

  gap: 44px;
`;

const OfferedBenefitsTitle = styled.p`
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 0;

  .subtitle {
    display: block;
    font-weight: normal;
  }
`;

const BenefitItems = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 28px;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const BenefitItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;

  @media (min-width: 1024px) {
    width: 250px;
    gap: 16px;
  }
`;

const FeaturedBenefit = styled.span`
  background-color: #ff5e0359;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff5e03;
  font-weight: bold;
  font-size: 55px;
  line-height: 80%;
  text-align: center;

  @media (min-width: 1024px) {
    width: 180px;
    height: 180px;
    font-size: 65px;
  }

  &.differente-text {
    font-size: 25px;
    text-align: center;
    @media (min-width: 1024px) {
      font-size: 30px;
      text-align: center;
    }
  }
`;

const BenefitTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin: 8px 0;
`;

const BenefitDescription = styled.p`
  font-size: 16px;
  text-align: center;
`;

const CtaSubscribe = styled.a`
  font-weight: bold;
  color: white;
  background-color: black;
  padding: 20px;
  max-width: 80%;
  font-size: 20px;
  text-align: center;
  margin: 0 auto;

  @media (min-width: 1024px) {
    font-size: 16px;
    max-width: 50%;
  }
`;

const Beneficios = () => {
  const handleEvent = buttonName => {
    trackEventGa(buttonName);
  };

  return (
    <BenefitsSection id="beneficios">
      <BenefitsContainer>
        <TitleContainer>
          <TitleImage src="/mastercard.svg" alt="" />
          <Title>MASTERCARD SURPREENDA</Title>
        </TitleContainer>

        <SubtitleContainer>
          <Subtitle>
            Mastercard® Surpreenda é o programa de benefícios da Mastercard,
            que lhe dá 1 ponto a cada compra realizada com seu Cartão Riachuelo
            Mastercard, independentemente do valor.
            <br />
            Quanto mais pontos você acumular, mais possibilidades terá de trocar
            por ofertas especiais.
            <br />
            Cadastre seu Cartão Riachuelo Mastercard e descubra os benefícios
            para surpreender alguém.
          </Subtitle>
        </SubtitleContainer>

        <CardsContainer>
          <Cards>
            <Card>
              <CardImage src="/man-with-card.svg" alt="" />
              <CardTitle>Cadastre seu Cartão Mastercard®</CardTitle>
              <CardSubtitle>
                Ao receber o seu cartão e realizar o desbloqueio, faça o
                cadastro no site surpreenda.naotempreco.com.br ou pelo App
                Surpreenda Mastercard.
              </CardSubtitle>
            </Card>
            <Card>
              <CardImage src="/credit-card.svg" alt="" />
              <CardTitle>Acumule pontos</CardTitle>
              <CardSubtitle>
                Toda compra feita com seu Cartão Mastercard® cadastrado no
                programa vale 1 ponto, independente do valor de sua compra.
              </CardSubtitle>
            </Card>
            <Card>
              <CardImage src="/gift.svg" alt="" />
              <CardTitle>Troque pontos por benefícios</CardTitle>
              <CardSubtitle>
                Você troca os pontos acumulados por vouchers. Resgate seus
                vouchers pelo app ou pelo site com o nome do parceiro do produto
                que você deseja.
              </CardSubtitle>
            </Card>
          </Cards>
        </CardsContainer>

        <DescriptionContainer>
          <Description>
            <p>
              <strong>E mais:</strong> para te dar as boas-vindas ao Mastercard
              Surpreenda você acaba de ganhar{' '}
              <strong class="uppercase">30 pontos</strong> no programa! Além de
              15 pontos adicionais para novos cadastros, ficando com um saldo de{' '}
              <strong class="uppercase">45 pontos</strong>!
            </p>
            <br />
            <p class="block">Confira como usar:</p>
            <HowToItems>
              <HowToItem>
                <span class="hidden lg:inline-block">1.</span> Cadastre seu novo
                Cartão Riachuelo Mastercard no site{' '}
                <a
                  class="text-orange font-bold"
                  href="https://surpreenda.naotempreco.com.br"
                >
                  {' '}
                  surpreenda.naotempreco.com.br
                </a>
                ;
              </HowToItem>
              <HowToItem>
                <span class="hidden lg:inline-block">2.</span> Digite{' '}
                <span class="text-orange font-bold"> RCHLO30*</span> no campo
                CÓDIGO PROMOCIONAL;
              </HowToItem>
              <HowToItem>
                <span class="hidden lg:inline-block">3.</span> PRONTO! Com 45
                pontos você resgata diversos benefícios em nossos parceiros de
                gastronomia, beleza, esporte e muito mais.
              </HowToItem>
            </HowToItems>
          </Description>
        </DescriptionContainer>

        <OfferedBenefits>
          <OfferedBenefitsTitle>
            TROQUE PONTOS POR BENEFÍCIOS
            <span class="subtitle">
              Conheça as ofertas que o programa oferece
            </span>
          </OfferedBenefitsTitle>
          <BenefitItems>
            <BenefitItem>
              <FeaturedBenefit>2x1</FeaturedBenefit>
              <BenefitTitle>Compre 1 leve 2</BenefitTitle>
              <BenefitDescription>
                Com seus pontos, compre 1 produto e ganhe outro para presentear!
              </BenefitDescription>
            </BenefitItem>
            <BenefitItem>
              <FeaturedBenefit>% OFF</FeaturedBenefit>
              <BenefitTitle>Pontos por desconto</BenefitTitle>
              <BenefitDescription>
                Utilize os seus pontos para ganhar descontos imperdíveis no
                produto que você escolher!
              </BenefitDescription>
            </BenefitItem>
            <BenefitItem>
              <FeaturedBenefit className="differente-text">
                Trocou, GANHOU!
              </FeaturedBenefit>
              <BenefitTitle>Pontos por produtos</BenefitTitle>
              <BenefitDescription>
                Troque os seus pontos diretamente pelo produto que você gostou!
              </BenefitDescription>
            </BenefitItem>
          </BenefitItems>
        </OfferedBenefits>

        <CtaSubscribe
          target="_blank"
          href="https://surpreenda.naotempreco.com.br/cadastro"
          aria-label="Clique aqui para fazer seu cadastro no Mastercard Surpreenda"
          onClick={() => handleEvent('saiba-mais:surpreenda')}
        >
          Faça seu cadastro no Mastercard Surpreenda
        </CtaSubscribe>
      </BenefitsContainer>
    </BenefitsSection>
  );
};

export default Beneficios;

import styled, { keyframes } from 'styled-components';

const backgroundAnimation = keyframes`    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }`;

const LoadingSkeleton = styled.span`
  width: ${props => props?.width ?? '100%'};
  height: ${props => props?.height ?? '100%'};
  margin-bottom: 0.4rem;
  border-radius: 0.25rem;
  animation: ${backgroundAnimation} 1s linear infinite alternate;
  display: block;
`;

export default LoadingSkeleton;

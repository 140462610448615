import SubjectItem from '~/pages/AttendancePortal/components/SubjectItem';
import WrapperBase from '~/pages/AttendancePortal/components/Wrapper';
import { breakpoints } from '../../utils';
import { colors } from '~/constants';
import styled from 'styled-components';

export const Wrapper = styled(WrapperBase)`
  padding-bottom: 5rem;
`;

export const ShowCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const AccordionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (${breakpoints[1176]}) {
    flex-direction: row;
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LastSubjectItem = styled(SubjectItem)`
  strong {
    color: ${colors.orangeRoof};
  }
`;

export const CategoryTitleContainer = styled.div`
  svg,
  img {
    width: 24px;
    height: auto;
  }
`;

export const CategoryTitle = styled.strong`
  font-size: 1rem;
  font-weight: 500;

  @media (${breakpoints[1176]}) {
    font-size: 1.25rem;
  }
`;

/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Image } from '@midway/web-ui-component';
import { isAndroid } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import chatIcon from '~/assets/img/AppDifference/chat.svg';
import pagamentosIcon from '~/assets/img/AppDifference/pagamentos.svg';
import recargaIcon from '~/assets/img/AppDifference/recarga.svg';
import faturaIcon from '~/assets/img/AppDifference/fatura.svg';
import saqueIcon from '~/assets/img/AppDifference/saque.svg';
import transferenciaIcon from '~/assets/img/AppDifference/transferencia.svg';
import LogoMidwaySvg from '~/assets/img/AppDifference/logo_midway.svg';
import LogoRiachueloSvg from '~/assets/img/AppDifference/logo_riachuelo.svg';
import LogoMidwayMobile from '~/assets/img/AppDifference/midway_24.svg';
import LogoRiachueloMobile from '~/assets/img/AppDifference/rchlo_24.svg';
import CardPng from '~/assets/img/AppDifference/cartoes-rchlo-mastercard.png';
import CardPngMobile from '~/assets/img/AppDifference/cartoes-rchlo-2.png';
import BarcodeIconSvg from '~/assets/img/AppDifference/elipseIcon.svg';
import MockupAppMidway from '~/assets/img/AppDifference/mockup-app-midway.png';
import appleStoreBadge from '~/assets/img/AppDifference/apple-store-badge.png';
import googleStoreBadge from '~/assets/img/AppDifference/google-store-badge.png';
import personMidway from '~/assets/img/AppDifference/person-midway.png';
import personMidwayMobile from '~/assets/img/AppDifference/person-midway-mobile.png';
import Hifen from '~/assets/img/AppDifference/hifen_1.png';
import check from '~/assets/img/AppDifference/check.svg';
import midwayERiachueloBig from '~/assets/img/new-home/midwayeriachuelo-big.svg';

import { showCallToAction } from '~/store/modules/calltoaction/actions';
import { showSmartBannerAction } from '~/store/modules/smartbanner/actions';
import { showCallToActionRiachuelo } from '~/store/modules/calltoactionriachuelo/actions';
import CallToAction from '~/components/CallToAction';
import CallToActionRiachuelo from '~/components/CallToActionRiachuelo';
import * as S from './styles';
import { CarouselMobile } from './components/CarouselMobile';
import Faq from './components/Faq';
import { faqApps, productsAndServices } from './data';

export default function Apps() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  // eslint-disable-next-line no-unused-vars
  const [isShowQuestion, setIsShowQuestion] = useState(false);
  const dispatch = useDispatch();

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 800);

  useEffect(() => {
    dispatch(showSmartBannerAction(false));
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, [dispatch]);

  const handleShowModal = () => {
    if (isMobile) {
      isAndroid
        ? window.open(
            'https://play.google.com/store/apps/details?id=br.com.midway&hl=pt_BR&gl=US'
          )
        : window.open('https://apps.apple.com/br/app/midway-app/id1548732480');
    } else {
      dispatch(showCallToAction(true));
    }
  };

  const handleShowModalRiachuelo = () => {
    if (isMobile) {
      isAndroid
        ? window.open(
            'https://play.google.com/store/apps/details?id=br.com.riachuelo.app&hl=pt_BR&gl=US'
          )
        : window.open(
            'https://apps.apple.com/br/app/riachuelo-compre-pelo-app/id1488532824'
          );
    } else {
      dispatch(showCallToActionRiachuelo(true));
    }
  };

  const handleOpenStoreMidway = isiOSdevice => {
    if (isiOSdevice) {
      window.open(
        'https://apps.apple.com/br/app/midway-app/id1548732480',
        '_blank'
      );
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=br.com.midway&hl=pt_BR&gl=US'
      );
    }
  };

  const handleOpenStoreRiachuelo = isiOSdevice => {
    if (isiOSdevice) {
      window.open(
        'https://apps.apple.com/br/app/riachuelo-compre-pelo-app/id1488532824',
        '_blank'
      );
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=br.com.riachuelo.app&hl=pt_BR&gl=US'
      );
    }
  };

  const handleOpenUrlRiachuelo = () => {
    window.open('https://riachuelo.com.br/lojas', '_blank');
  };

  const handleOpenUrlFatura = () => {
    window.open(
      'https://contaonline.midwayfinanceira.com.br/contaonline/consulta-boleto',
      '_blank'
    );
  };

  const renderContent = id => {
    switch (id) {
      case 0: {
        return (
          <div className="faq-text">
            <span>
              Como posso consultar a minha fatura do Cartão Riachuelo?
              <br />
              <br />
              Você pode consultar a fatura fechada e os últimos lançamentos do
              seu Cartão Riachuelo no <S.Bold>novo app RCHLO</S.Bold>.
              Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo()}>
                Google Play
              </S.Link>{' '}
              (Android).
              <br />
              <br />
              No seu 1° acesso, é só clicar na opção <S.Bold>Cartões</S.Bold> e
              preencher as informações solicitadas até a confirmação do vínculo.
              <br />
              <br />
              Se preferir baixe o <S.Bold>app Midway</S.Bold>. Lá, além da
              fatura, você tem a Conta Digital da Riachuelo, acesso facilitado a
              empréstimos, parcelamento de fatura e saldo rendendo todo dia.
              Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreMidway(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreMidway()}>
                Google Play
              </S.Link>{' '}
              (Android).
            </span>
          </div>
        );
      }
      case 1: {
        return (
          <div className="faq-text">
            <span>
              Se a sua fatura estiver fechada e no prazo de vencimento,{' '}
              <S.Link onClick={() => handleOpenUrlFatura()}>clique aqui</S.Link>{' '}
              para <S.Bold>gerar e copiar o número do código de barras</S.Bold>{' '}
              (linha digitável). Basta informar o seu CPF e data de nascimento.
              <br />
              <br />
              Se preferir, consulte a fatura e gere o boleto no{' '}
              <S.Bold>novo app RCHLO</S.Bold>. Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo()}>
                Google Play
              </S.Link>{' '}
              (Android)..
              <br />
              <br /> No seu 1° acesso, é só clicar na opção Cartões e preencher
              as informações solicitadas até a confirmação do vínculo.
              <br />
              <br /> Ou ainda, baixe o <S.Bold>app Midway</S.Bold>. Lá, além do
              fatura, você tem a <S.Bold>Conta Digital da Riachuelo</S.Bold>,
              acesso facilitado a empréstimos, saldo rendendo todo dia e
              concorre a sorteios mensais de R$ 10 mil reais. Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreMidway(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreMidway()}>
                Google Play
              </S.Link>{' '}
              (Android).
            </span>
          </div>
        );
      }
      case 2: {
        return (
          <div className="faq-text">
            <span>
              Se a sua fatura estiver fechada e no prazo de vencimento, você
              poderá <S.Bold>parcelar o pagamento</S.Bold> no{' '}
              <S.Bold>novo app RCHLO</S.Bold>. Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo()}>
                Google Play
              </S.Link>{' '}
              (Android).
              <br />
              <br /> No seu 1° acesso, é só clicar na opção{' '}
              <S.Bold>Cartões</S.Bold> e preencher as informações solicitadas
              até a confirmação do vínculo.
            </span>
          </div>
        );
      }
      case 3: {
        return (
          <div className="faq-text">
            <span>
              Se o pagamento do seu Cartão Riachuelo estiver atrasado,{' '}
              <S.Link onClick={() => handleOpenUrlFatura()}>clique aqui</S.Link>{' '}
              para <S.Bold>negociar a dívida</S.Bold>. Basta informar o seu CPF
              e data de nascimento.
            </span>
          </div>
        );
      }
      case 4: {
        return (
          <div className="faq-text">
            <span>
              Você pode consultar o limte do seu Cartão Riachuelo no{' '}
              <S.Bold>novo app RCHLO</S.Bold>. Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo()}>
                Google Play
              </S.Link>{' '}
              (Android).
              <br />
              <br /> No seu 1° acesso, é só clicar na opção Cartões e preencher
              as informações solicitadas até a confirmação do vínculo.
              <br />
              <br />
              Se preferir, baixe o <S.Bold>app Midway</S.Bold>. Lá, além do
              limite, você tem a <S.Bold>Conta Digital da Riachuelo</S.Bold>,
              acesso facilitado a empréstimos, saldo rendendo todo dia e
              concorre a sorteios mensais de R$ 10 mil reais. Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreMidway(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreMidway()}>
                Google Play
              </S.Link>{' '}
              (Android).
            </span>
          </div>
        );
      }
      case 5: {
        return (
          <div className="faq-text">
            <span>
              Você pode alterar a senha do seu cartão em uma das{' '}
              <S.Bold>Lojas Riachuelo</S.Bold>. Vá ao ATM - Terminal de
              Autoatendimento - insira o cartão, selecione a opção{' '}
              <S.Bold>Troca de senha</S.Bold> e siga o passo a passo indicado.
              Para consultar o endereço da loja mais próxima, acesse{' '}
              <S.Link onClick={() => handleOpenUrlRiachuelo()}>
                riachuelo.com.br/lojas
              </S.Link>
              .
            </span>
          </div>
        );
      }
      case 6: {
        return (
          <div className="faq-text">
            <span>
              Em caso de perda, roubo ou furto, realize o{' '}
              <S.Bold>bloqueio imediato do seu Cartão Riachuelo</S.Bold>. Em
              capitais e regiões metropolitanas, ligue para{' '}
              <S.Bold>3004 5417</S.Bold>. Nas demais localidades, ligue para{' '}
              <S.Bold>0800 727 4417</S.Bold>.
            </span>
          </div>
        );
      }
      case 7: {
        return (
          <div className="faq-text">
            <span>
              Você pode solicitar um Cartão Riachuelo de uso exclusivo nas lojas
              no novo app RCHLO. Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo()}>
                Google Play
              </S.Link>{' '}
              (Android).
              <br />
              <br /> Se preferir, você também pode solicitar nas Lojas
              Riachuelo, apresentando documento oficial com foto e CPF. Para
              consultar o endereço da loja mais próxima, acesse{' '}
              <S.Link onClick={() => handleOpenUrlRiachuelo()}>
                riachuelo.com.br/lojas
              </S.Link>
              .<br />
              <br /> Importante: para solicitar o{' '}
              <S.Bold>Cartão Riachuelo Visa ou Mastercard</S.Bold>, você precisa
              ter um Cartão Riachuelo de uso exclusivo nas lojas. Então, no novo{' '}
              <S.Bold>app RCHLO</S.Bold>, você poderá consultar se a mudança
              está pré-aprovada e solicitá-lo.
            </span>
          </div>
        );
      }
      case 8: {
        return (
          <div className="faq-text">
            <span>
              Se você contratou o empréstimo na <S.Bold>loja física</S.Bold> ou
              por <S.Bold>telefone</S.Bold>,
              <S.Link onClick={() => handleOpenUrlFatura()}>clique aqui</S.Link>{' '}
              para <S.Bold>gerar e copiar o número do código de barras</S.Bold>{' '}
              (linha digitável). Basta informar o seu CPF e data de nascimento.
              <br />
              <br />
              Se preferir, baixe o <S.Bold>app Midway</S.Bold>. Lá, além de
              Empréstimos, você tem a{' '}
              <S.Bold>Conta Digital da Riachuelo</S.Bold>, saldo rendendo todo
              dia e concorre a sorteios mensais de R$ 10 mil reais. Disponível
              na{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreRiachuelo()}>
                Google Play
              </S.Link>{' '}
              (Android).
              <br />
              <br /> Importante: se você contratou o empréstimo no{' '}
              <S.Bold>app Midway</S.Bold>, o pagamento é em débito automático na
              sua conta digital. Lembre-se de ter saldo suficiente no dia do
              vencimento.
            </span>
          </div>
        );
      }
      case 9: {
        return (
          <div className="faq-text">
            <span>
              Se a parcela do seu Empréstimo estiver atrasada,{' '}
              <S.Link onClick={() => handleOpenUrlFatura()}>clique aqui</S.Link>{' '}
              para
              <S.Bold>negociar a dívida</S.Bold> no Serasa Limpa Nome.
              <br />
              <br /> Se preferir, você também pode negociar por telefone. Em
              capitais e regiões metropolitanas, ligue para{' '}
              <S.Bold>3004 5417</S.Bold>. Nas demais localidades, ligue para{' '}
              <S.Bold>0800 727 4417</S.Bold>.
            </span>
          </div>
        );
      }
      case 10: {
        return (
          <div className="faq-text">
            <span>
              Você pode simular e contratar Empréstimos no{' '}
              <S.Bold>app Midway</S.Bold>. Disponível na{' '}
              <S.Link onClick={() => handleOpenStoreMidway(true)}>
                APP Store
              </S.Link>{' '}
              (iOS) e no{' '}
              <S.Link onClick={() => handleOpenStoreMidway()}>
                Google Play
              </S.Link>{' '}
              (Android).
              <br />
              <br /> Lá, além de Empréstimos, você tem a{' '}
              <S.Bold>Conta Digital da Riachuelo</S.Bold>, saldo rendendo todo
              dia e concorre a sorteios mensais de R$ 10 mil reais.
              <br />
              <br /> Se preferir, você pode simular e contratar por{' '}
              <S.Bold>telefone</S.Bold>. Em capitais e regiões metropolitanas,
              ligue para <S.Bold>3004 5417</S.Bold>. Nas demais localidades,
              ligue para <S.Bold>0800 727 4417</S.Bold>.
              <br />
              <br /> Ou ainda, você pode simular e contratar nas{' '}
              <S.Bold>Lojas Riachuelo</S.Bold>, apresentando documento oficial
              com foto, CPF e o seu cartão. Para consultar o endereço da loja
              mais próxima, acesse
              <S.Link onClick={() => handleOpenUrlRiachuelo()}>
                riachuelo.com.br/lojas
              </S.Link>
              .
            </span>
          </div>
        );
      }
      default:
        break;
    }
  };

  const selectIcons = {
    chat: chatIcon,
    fatura: faturaIcon,
    pagamentos: pagamentosIcon,
    recarga: recargaIcon,
    saque: saqueIcon,
    transferencia: transferenciaIcon,
  };

  return (
    <>
      <CallToAction />
      <CallToActionRiachuelo />
      <S.CardAccess>
        <div className="headerCard">
          <div>
            <img
              alt="Ícone de código de barras"
              src={BarcodeIconSvg}
              className="img-fluid"
              id="icon"
            />
          </div>
          <div className="displayWidth">
            <span>ACESSO RÁPIDO</span>
            <p>Acesse faturas de cartão ou empréstimo Midway Riachuelo</p>
          </div>
          <div className="botaoAR">
            <Link aria-label="Baixe o app Riachuelo" to="/consulta-boleto">
              Acessar
            </Link>
          </div>
        </div>
      </S.CardAccess>
      <S.ContainerColor
        id="ready-page-for-info-app"
        className="container-fluid"
        aria-label="Página sobre informações do App"
        aria-hidden="true"
      >
        <S.Banner className="space-layout">
          <div className="container">
            <div className="row">
              {!isMobile && (
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <S.AccessibleH1>
                    PAGUE A SUA FATURA <br />
                    PELO APP E APROVEITE: <br />
                    AGORA O LIMITE É <br />
                    LIBERADO EM ALGUNS <br />
                    INSTANTES
                  </S.AccessibleH1>
                  <div className="p.title-p">
                    <p>
                      <S.Bold>
                        Agora você pode consultar limite e pagar <br />
                        a fatura do Cartão Riachuelo. Baixe o app <br />
                        gratuitamente agora mesmo.
                      </S.Bold>
                    </p>
                  </div>

                  <div className="badges" onClick={() => handleShowModal()}>
                    <img
                      alt="Cartões riachuelo"
                      src={googleStoreBadge}
                      className="img-fluid"
                      id="logo1"
                    />
                    <img
                      alt="Cartões riachuelo"
                      src={appleStoreBadge}
                      className="img-fluid"
                      id="logo1"
                    />
                  </div>
                </div>
              )}

              {isMobile && (
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <Image
                    alt="Midway é Riachuelo"
                    src={MockupAppMidway}
                    id="mockupAppMidway"
                  />
                  <S.AccessibleH1>
                    PAGUE A SUA FATURA <br />
                    PELO APP E APROVEITE: <br />
                    AGORA O LIMITE É <br />
                    LIBERADO EM ALGUNS <br />
                    INSTANTES
                  </S.AccessibleH1>
                  <div className="p.title-p">
                    <p>
                      <S.Bold>
                        Agora você pode consultar limite e pagar a fatura do
                        Cartão Riachuelo. Baixe o app gratuitamente agora mesmo.
                      </S.Bold>
                    </p>
                  </div>
                  <div
                    className="badgesMobile"
                    onClick={() => handleShowModal()}
                  >
                    <img
                      alt="Cartões riachuelo"
                      src={googleStoreBadge}
                      className="img-fluid"
                      id="logo1"
                    />
                    <img
                      alt="Cartões riachuelo"
                      src={appleStoreBadge}
                      className="img-fluid"
                      id="logo1"
                    />
                  </div>
                </div>
              )}

              {!isMobile && (
                <div className="col-6">
                  <div className="col">
                    <div className="celulares">
                      <Image
                        alt="Midway é Riachuelo"
                        src={MockupAppMidway}
                        id="mockupAppMidway"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </S.Banner>
      </S.ContainerColor>
      {isMobile && (
        <S.ContainerGray id="produtos-e-servicos">
          <CarouselMobile />
        </S.ContainerGray>
      )}
      {!isMobile && (
        <S.Comparative className="container pt-5">
          <div className="col-6 choice">
            <p>
              <S.Bold>
                Compare as vantagens e escolha o <br />
                melhor app para você:
              </S.Bold>
            </p>
          </div>
          <div className="col">
            <div className="celulares">
              <Image alt="Midway é Riachuelo" src={LogoMidwaySvg} id="logo1" />
              <div className="pt-2 pl-2">
                <p className="title">Midway</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="celulares">
              <Image
                alt="Midway é Riachuelo"
                src={LogoRiachueloSvg}
                id="logo2"
              />
              <div className="pt-2 pl-2">
                <p className="title">Riachuelo</p>
              </div>
            </div>
          </div>
        </S.Comparative>
      )}
      <S.Container className="container container-comparative-mobile">
        {isMobile && (
          <div className="row p-2 align-middle">
            <div className="col-6 title-top">
              <S.Bold>Escolha a melhor opção para você:</S.Bold>
            </div>
            <div className="col text-center container-icon-mobile">
              <img
                src={LogoMidwayMobile}
                alt="Logo Midway"
                className="img-fluid icon-mobile"
                styles="max-height: 70px;"
              />
            </div>
            <div className="col text-center container-icon-mobile">
              <img
                alt="Logo RCHLO"
                src={LogoRiachueloMobile}
                className="img-fluid icon-mobile"
                styles="max-height: 70px;"
              />
            </div>
          </div>
        )}

        <div className="background-table-gray">
          <div className="align row p-2">
            <div className="col-6 align-middle">
              <span>Consultar e pagamento da fatura do cartão Riachuelo.</span>
            </div>
            <div className="col text-center">
              <div className="check text-center">
                <img alt="Ícone de check" src={check} className="img-fluid" />
              </div>
            </div>
            <div className="col text-center">
              <div className="check text-center">
                <span>Somente consulta</span>
              </div>
            </div>
          </div>
        </div>

        <div className="background-table">
          <div className="align row p-2">
            <div className="col-6 align-middle">
              <span>Empréstimo facilitado e direto na sua conta</span>
            </div>
            <div className="col text-center">
              <div className="check text-center">
                <img alt="Ícone de check" src={check} className="img-fluid" />
              </div>
            </div>
            <div className="col text-center">
              <div className="retangulo text-center">
                <img alt="Ícone de traço" src={Hifen} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="background-table-gray">
          <div className="align row p-2">
            <div className="col-6 align-middle">
              <span>Conta digital gratuita com rendimento de 103% do CDI</span>
            </div>
            <div className="col text-center">
              <div className="check text-center">
                <img alt="Ícone de check" src={check} className="img-fluid" />
              </div>
            </div>
            <div className="col text-center">
              <div className="retangulo text-center">
                <img alt="Ícone de traço" src={Hifen} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="background-table">
          <div className="align row p-2">
            <div className="col-6 align-middle">
              <span>Parcelamento de fatura</span>
            </div>
            <div className="col text-center">
              <div className="check text-center">
                <img alt="Ícone de check" src={check} className="img-fluid" />
              </div>
            </div>
            <div className="col text-center">
              <div className="retangulo text-center">
                <img alt="Ícone de traço" src={Hifen} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="background-table-gray">
          <div className="align row p-2">
            <div className="col-6 align-middle">
              <span>Transferências gratuitas e ilimitadas</span>
            </div>
            <div className="col text-center">
              <div className="check text-center">
                <img alt="Ícone de check" src={check} className="img-fluid" />
              </div>
            </div>
            <div className="col text-center">
              <div className="retangulo text-center">
                <img alt="Ícone de traço" src={Hifen} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="container content-buttons">
          <div className="row">
            {!isMobile && <div className="col-6" />}
            {isMobile && (
              <div className="col-md-6">
                <a
                  aria-label="Baixe o app Midway"
                  href="javascript:;"
                  onClick={handleShowModal}
                >
                  Baixe o App Midway
                </a>
              </div>
            )}
            {!isMobile && (
              <div className="col">
                <a
                  aria-label="Baixe o app Midway"
                  href="javascript:;"
                  onClick={handleShowModal}
                >
                  Baixe o App Midway
                </a>
              </div>
            )}
            {isMobile && (
              <div className="col-md-6">
                <div className="botaoR">
                  <a
                    aria-label="Baixe o app Riachuelo"
                    href="javascript:;"
                    onClick={handleShowModalRiachuelo}
                  >
                    Baixe o App Riachuelo
                  </a>
                </div>
              </div>
            )}
            {!isMobile && (
              <div className="col">
                <div className="botaoR">
                  <a
                    aria-label="Baixe o app Riachuelo"
                    href="javascript:;"
                    onClick={handleShowModalRiachuelo}
                  >
                    Baixe o App Riachuelo
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </S.Container>
      {!isMobile && (
        <S.ContainerGray id="produtos-e-servicos">
          <S.Container className="container">
            <S.ProductsAndServices>
              {productsAndServices.map(({ title, description, icon }) => (
                <div className="item">
                  <img src={selectIcons[icon]} alt={title} />
                  <h1 className="title">{title}</h1>
                  <p className="description">{description}</p>
                </div>
              ))}
            </S.ProductsAndServices>
          </S.Container>
        </S.ContainerGray>
      )}
      <S.ContainerAdvertising isMobile={isMobile}>
        <div className="firstBox">
          <img
            alt="person-midway"
            src={isMobile ? personMidwayMobile : personMidway}
            className="img-fluid"
          />
        </div>
        <div className="secondBox">
          <h1 className="title">
            {isMobile
              ? 'QUANTO CABE NO SEU BOLSO?'
              : 'QUANTO CABE NO SEU BOLSO?'}
          </h1>
          <p className="subtitle">
            Aproveite as nossas condições para <br />
            parcelamento de fatura do seu cartão <br />
            Riachuelo e ganhe o tempo que precisa
            <br /> para se organizar.
          </p>
          <div className="badges" onClick={() => handleShowModal()}>
            <img
              alt="Cartões riachuelo"
              src={googleStoreBadge}
              className="img-fluid"
              id="logo1"
            />
            <img
              alt="Cartões riachuelo"
              src={appleStoreBadge}
              className="img-fluid"
              id="logo1"
            />
          </div>
        </div>
      </S.ContainerAdvertising>

      <S.Container className="container container-comparative-mobile">
        {isMobile ? (
          <S.CardAccess>
            <div>
              <img
                alt="Cartões riachuelo"
                src={CardPngMobile}
                className="img-fluid"
                id="logo1"
              />
            </div>
            <div className="mobileCard">
              <div>
                <img
                  alt="Ícone de código de barras"
                  src={BarcodeIconSvg}
                  className="img-fluid"
                  id="icon"
                />
              </div>
              <div className="displayWidth">
                <p>
                  ACESSO RÁPIDO: <br />
                  <span>
                    {' '}
                    Acesse faturas de cartão ou empréstimo Midway Riachuelo
                  </span>
                </p>
              </div>
              <div className="botaoAR">
                <a
                  aria-label="Baixe o app Riachuelo"
                  href="javascript:;"
                  onClick={handleOpenUrlFatura}
                >
                  Acessar
                </a>
              </div>
            </div>
          </S.CardAccess>
        ) : (
          <S.AccessBanner>
            <div className="boxDisplay">
              <img
                alt="Ícone de código de barras"
                src={BarcodeIconSvg}
                className="img-fluid"
                id="icon"
              />
              <div className="displayWidth">
                <p className="title">Acesso rápido</p>
                <S.Bold className="subtitle">
                  Pague via boleto a fatura do seu <br />
                  cartão Riachuelo
                </S.Bold>
                <div className="botaoAR">
                  <a
                    aria-label="Baixe o app Riachuelo"
                    href="javascript:;"
                    onClick={handleOpenUrlFatura}
                  >
                    Acessar fatura
                  </a>
                </div>
              </div>
            </div>
            <div>
              <img
                alt="Cartões riachuelo"
                src={CardPng}
                className="img-fluid"
                id="logo1"
              />
            </div>
          </S.AccessBanner>
        )}
        <p className="titleFaq">Perguntas frequentes</p>

        {faqApps.map(item => (
          <Faq
            id={item.id}
            title={item.title}
            content={renderContent(item.id)}
          />
        ))}

        {isShowQuestion && (
          <S.Doubts className="col-6">
            <h1>Ainda tem dúvidas?</h1>
            <p>Entre em contato com a nossa Central de atendimento:</p>
            <div className="contact">
              <div>
                <p>Capitais e Regiões Metropolitanas</p>
                <span>3004 5417</span>
              </div>
              <div>
                <p>Demais localidades</p>
                <span>0800 727 4417</span>
              </div>
            </div>
          </S.Doubts>
        )}

        <div className="bgMdRchlo">
          <Image
            alt="Midway é Riachuelo"
            src={midwayERiachueloBig}
            customClass="mid-is-rchlo"
          />
        </div>
      </S.Container>
    </>
  );
}

import styled from 'styled-components';
import Background from '~/assets/img/Financial/lending/fundoBoleto.png';
import Banner from '~/assets/img/Financial/lending/banner17360.png';
import BannerMobile from '~/assets/img/Financial/lending/bannerMobile.png';

export const Container = styled.div`
  background-color: #fff;
  padding: 0 !important;
`;

export const MidwayLogo = styled.div`
  width: 100%;
  height: 77px;
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const BannerContainer = styled.div`
  background-image: url(${Banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  min-height: 550px;
  max-height: 550px;
  padding: 0 !important;

  @media (max-width: 768px) {
    min-height: 600px;
    max-height: 600px;
  }
  @media (max-width: 500px) {
    background-image: url(${BannerMobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  div.text-error {
    color: #c0392b;
    font-weight: bold;
    font-size: 16px !important;
    margin-top: -10px;
  }
`;

export const BannerTitle = styled.div`
  width: 40%;
  height: 54%;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: baseline;
  padding: 0px 46px;

  position: absolute;
  left: 4%;
  top: 135px;
  background-color: #fff;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 92%;
    top: 2%;

    border-radius: 10px;
  }

  div.captcha-invalid {
    color: red;
    font-weight: bold;
  }

  h2 {
    text-transform: uppercase;
    font-weight: bold;
    color: #000 !important;

    @media (max-width: 767px) {
      margin-top: 5%;
      font-size: 26px !important;
    }
  }

  input {
    width: 70%;
    font-size: 20px !important;
    margin-bottom: 10px;

    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 1px;
  }

  input::placeholder {
    color: #000;
    font-size: 16px !important;
  }

  h3 {
    font-size: 20px !important;
    color: #606060 !important;

    @media (max-width: 767px) {
      font-size: 16px !important;
    }
  }

  button {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const Content = styled.div``;

export const Session1 = styled.section`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  h1 {
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-top: 95px;
    margin-bottom: 15px;

    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    color: #707070;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  .benefits-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;

    @media (max-width: 768px) {
      gap: 30px;
    }

    .benefits-loan {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 768px) {
        margin-top: 1%;
      }

      &:hover {
        & > button {
          margin-top: 15px;
          margin-bottom: 5px;
        }
      }

      .icon {
        width: 120px;
        @media (max-width: 768px) {
          width: 100px;
        }
      }

      .arrow {
        width: 15px;
        margin: 25px 0 0 50px;
        @media (max-width: 768px) {
          margin: 24px 0px 12px 42px;
        }
      }

      h2 {
        font: bold 31px Montserrat !important;
        margin: 30px 40px 30px 48px;
        @media (max-width: 768px) {
          margin: 0;
          font-size: 26px !important;
        }
      }

      p {
        font: 22px Montserrat;
        color: #707070;
        margin-left: 50px;
        @media (max-width: 768px) {
          margin: 0;
          font-size: 18px !important;
        }
      }
    }
  }
`;

export const Session2 = styled.section`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  h1 {
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-top: 95px;
    margin-bottom: 15px;

    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    color: #707070;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  .contracts-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;

    @media (max-width: 768px) {
      gap: 30px;
    }

    .contracts-loan {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 768px) {
        width: 100%;
      }

      &:hover {
        & > button {
          margin-top: 15px;
          margin-bottom: 5px;
        }
      }

      .imagemContrato {
        width: 100%;
      }

      .contents-info {
        margin-right: 45px;

        a {
          font-weight: bold;
          color: #00726d;
          text-decoration: underline;
        }
        .contents-button {
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          gap: 25px;
          img {
            height: 40px;
          }
        }
        .contacts-number {
          display: flex;
          justify-content: space-evenly;
          div {
            width: 50%;
          }
          p {
            margin-top: 12px;
            @media (max-width: 768px) {
              font-size: 15px;
            }
          }
          h3 {
            font-weight: bold;
            color: #00726d;
            margin-top: 5px;
          }
          h3:last-child {
            width: 180px;
          }
        }
      }

      h2 {
        font: bold 25px Montserrat !important;
        margin-top: 30px;
      }

      p {
        font: 16px Montserrat;
        color: #707070;
        margin-top: 20px;
      }
      .text-atendimento {
        margin-top: 9px;
        width: 360px;
        font-size: 14px !important;
        @media (max-width: 768px) {
          font-size: 12px !important;
        }
      }
    }
  }
`;

export const BoletoContainer = styled.div`
  min-height: 400px;
  margin-top: 35px;

  @media (max-width: 768px) {
    min-height: 600px;
  }

  .container-info {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .boleto-info {
    background-color: #f3f3f3;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 65px;

    @media (max-width: 768px) {
      width: auto;
      height: auto;
      padding: 30px;
    }

    a {
      font-weight: bold;
      color: #00726d;
      text-decoration: underline;
    }
    span {
      font-weight: bold;
    }
  }

  .gerar-boleto {
    background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 90px;

    @media (max-width: 768px) {
      width: auto;
      height: auto;
      padding: 30px;
    }

    img {
      height: 77px;
      width: 77px;
    }
    a {
      background: #000000;
      padding: 20px 0px;
      border-radius: 50px;
      color: #47c8cd;
      font-size: 15px;
      max-width: 300px;
      text-align: center;
      margin-top: 30px;
      transition: all 0.2s ease-out;
      &:hover {
        margin-top: 27px;
        margin-bottom: 2px;
        text-decoration: none;
        color: #47c8cd;
      }
    }
  }

  h1 {
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      font-size: 23px !important;
    }
  }
  h3 {
    font-weight: bold !important;
    margin-top: 36px;
    @media (max-width: 768px) {
      font-size: 20px !important;
    }
  }

  p {
    font-size: 24px !important;
    @media (max-width: 768px) {
      font-size: 14px !important;
    }
  }
`;

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  h1 {
    margin-top: 30px;
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-bottom: 15px;

    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }

  h3 {
    font-size: 18px !important;
    @media (max-width: 992px) {
      font-size: 14px !important;
    }
  }

  a {
    font-weight: bold;
    color: #00726d;
    text-decoration: underline;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  padding: 40px;

  font-size: 20px;

  button {
    width: 38% !important;
    margin-top: 30px;
  }

  div.ahl-title {
    width: 100%;
    font-weight: bold;
    font-size: 23px !important;
  }

  div.ahl-subtitle {
    width: 55%;
    color: #9f9f9f;
    font-size: 11px !important;
    margin-top: 10px;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  div.ahl-middle {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;

    padding-top: 15px;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    &-body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      &-text-small {
        width: 70%;
        color: #9f9f9f;
        font-size: 11px !important;

        padding-bottom: 10px;

        &-deficient {
          width: 70%;
          color: #9f9f9f;
          font-size: 11px !important;

          padding-bottom: -10px;
          padding-top: 8px;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }

      &-text-bold {
        font-weight: bold;
        font-size: 16px !important;

        margin-bottom: 7px;
        /* margin: 10px 0px; */
      }

      &-images {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-arround;
        flex-direction: row;
        a {
          border-style: none;
        }
        img {
          width: 110px;
          height: 30px;
          padding-right: 16px;
        }
      }
    }
  }

  div.ahl-contract-app-top {
    margin-bottom: 26px;

    @media (min-width: 480px) {
      display: none;
    }
  }

  div.ahl-contract-app-bottom {
    @media (max-width: 480px) {
      display: none;
    }
  }
  div.ahl-icon-closed {
    position: absolute;

    top: 0;
    right: 0;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

import styled from 'styled-components';
import { ArrowIosBackOutline } from '@styled-icons/evaicons-outline/ArrowIosBackOutline';
import { ArrowIosForwardOutline } from '@styled-icons/evaicons-outline/ArrowIosForwardOutline';

export const NavigationContainer = styled.div`
  height: 60px;
  width: 60px;
  background-color: #e8e8e8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    height: 30px;
    width: 30px;
  }
`;

export const Caption = styled.div`
  div.carousel-caption {
    top: 150px;
    text-align: left;
    font-size: 40px;
    color: ${props => (props.colorText ? props.colorText : '#fff')} !important;
    white-space: pre-wrap;
    b {
      font-weight: Bold;
    }
  }
`;

export const PrevIcon = styled(ArrowIosBackOutline)`
  color: #7d7d7d;
  width: 30px;
  height: 30px;
`;

export const NextIcon = styled(ArrowIosForwardOutline)`
  color: #7d7d7d;
  width: 30px;
  height: 30px;
`;

import styled from 'styled-components';

import { colors } from '~/constants';

export const Container = styled.section`
  padding: 80px;
  background-color: ${colors.light_gray};

  @media (max-width: 900px) {
    padding: 40px 16px;
  }
`;

export const Content = styled.div`
  max-width: 1176px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    gap: 16px;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 48px;
  width: 100%;

  @media (max-width: 720px) {
    font-size: 20px !important;
    line-height: 30px !important;
    margin-bottom: 32px;
  }
`;

export const Questions = styled.div`
  width: 100%;

  .accordion-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const ShowMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;
export const ShowMoreButton = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
export const PageWrapper = styled.div`
  @media screen and (max-width: 492px) {
    margin-top: -60px;
  }
`;

import styled from 'styled-components';

export const Forms = styled.div`
  height: 340px;

  @media (max-width: 768px) {
    height: fit-content;
  }

  h1 {
    font-weight: bold;
    color: #000000;
    font-size: 32px !important;

    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }

  h2 {
    color: #606060;
    padding: 10px 0px 20px 0px;
    font-size: 20px !important;

    @media (max-width: 768px) {
      font-size: 18px !important;
    }
  }
  button {
    width: 26%;
    margin-top: 100px;
    @media (max-width: 768px) {
      width: 100%;
      margin: 20px;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 20px;

  :last-child {
    margin-top: 40px;
  }

  input {
    border: 0;
    border-bottom: 1px solid #606060;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
  }

  label {
    top: 0;
    left: 0;
    right: 0;
    color: #000000;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 16px !important;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
  }

  input,
  label {
    width: 100%;
    height: 2rem;
    font-size: 1rem;
  }

  input:valid + label,
  input:focus + label {
    font-size: 16px !important;
    top: -25px;
    color: #606060;
  }
`;

import React, { memo, useState } from 'react';
import { Button } from '@midway/web-ui-component';
import format from '~/services/format';
import { trackingSelectContent } from '~/analytics';
import { flow, contentPage, contentType } from '~/analytics/cards';

import {
  Container,
  Dropdown,
  InstallmentItem,
  Header,
  Text,
  Divider,
} from './styles';

export const InstallmentOptionsInput = memo(
  ({ installments, onChangeInstallment }) => {
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const toggleIsOptionsVisible = () => setIsOptionsVisible(prev => !prev);

    const getOptionDescription = installment =>
      `${installment.amountOf} ${
        installment.amountOf > 1 ? 'parcelas' : 'parcela'
      } de ${format.currency(installment.value)}`;

    const handleInstallmentItemPress = (id, amountOf) => {
      trackingSelectContent(
        contentType.installmentOptionSelected(amountOf),
        flow.payments,
        contentPage.installmentSimulation
      );
      onChangeInstallment(id);
      toggleIsOptionsVisible();
    };

    const handleMoreOptionsButtonPress = () => {
      if (!isOptionsVisible) {
        trackingSelectContent(
          contentType.moreOptions,
          flow.payments,
          contentPage.installmentSimulation
        );
      }
      toggleIsOptionsVisible();
    };

    return (
      <Container>
        <Button
          leftIcon="chevron-down"
          stateIcon="secundary"
          title="Mais opções"
          onClick={handleMoreOptionsButtonPress}
          block
        />

        {isOptionsVisible && (
          <Dropdown>
            {installments.map(installment => (
              <InstallmentItem
                onClick={() =>
                  handleInstallmentItemPress(
                    installment.idInstallment,
                    installment.amountOf
                  )
                }
                key={installment.idInstallment}
              >
                <Header>{getOptionDescription(installment)}</Header>
                <Text>{`${installment.taxPercentage}% a.m`}</Text>
                <Text>{`Valor final ${format.currency(
                  installment.totalFinanced
                )}`}</Text>
                <Divider />
              </InstallmentItem>
            ))}
          </Dropdown>
        )}
      </Container>
    );
  }
);

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 24px;
  @media (max-width: 1000px) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 32px;
  }
`;

export const StepIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Icon = styled.img`
  width: 64px;
  height: 64px;
  @media (max-width: 1000px) {
    width: 48px;
    height: 48px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

export const TextSection = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding-bottom: 8px;

  @media (max-width: 1000px) {
    font-weight: 500;
  }
`;

export const DescriptionSection = styled.p`
  font-family: Montserrat;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

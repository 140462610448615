import React from 'react';
import Check from '~/assets/img/Financial/lending/icone-check.svg';
import * as S from './styles';

const Steppers = ({ select }) => {
  return (
    <S.List>
      <S.Item complete>
        <span>
          <img src={Check} alt="Ícone de verificação" />
        </span>
      </S.Item>
      <S.Line complete />
      <S.Item active={select === 2 || select === 3} complete={select > 3}>
        <span>
          {select > 3 ? <img src={Check} alt="Ícone de verificação" /> : '2'}
        </span>
        {(select === 2 || select === 3) && <p>Dados pessoais</p>}
      </S.Item>
      <S.Line complete={select > 2} />
      <S.Item active={select === 4} complete={select > 4}>
        <span>
          {select > 4 ? <img src={Check} alt="Ícone de verificação" /> : '3'}
        </span>
        {select === 4 && <p>Valor</p>}
      </S.Item>
      <S.Line complete={select > 3} />
      <S.Item active={select === 5} complete={select > 5}>
        <span>
          {select > 5 ? <img src={Check} alt="Ícone de verificação" /> : '4'}
        </span>
        {select === 5 && <p>Primeiro pagamento</p>}
      </S.Item>
      <S.Line complete={select > 5} />
      <S.Item active={select === 6} complete={select > 6}>
        <span>
          {select > 6 ? <img src={Check} alt="Ícone de verificação" /> : '5'}
        </span>
        {select === 6 && <p>Parcelas</p>}
      </S.Item>
    </S.List>
  );
};

export default Steppers;

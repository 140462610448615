import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
 from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
export const Container = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export default function ContainerFadeIn({ children }) {
  return <Container>{children}</Container>;
}

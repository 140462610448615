import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding: 0 !important;
`;

export const BannerContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#E8E8E8')};
  position: relative;
  min-height: 400px;
  max-height: 400px;
  padding: 0 !important;
  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }
  img {
    min-height: 400px;
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      min-height: 300px;
      max-height: 300px;
    }
  }
  div.banner-title {
    position: absolute;
    white-space: pre-wrap;
    left: 10%;
    top: 200px;
    h1 {
      font-weight: bold;
      font-size: 32px !important;
    }
    p {
      font-size: 22px;
    }
    max-width: 500px;
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  a {
    text-decoration: underline;
  }
  u {
    color: #0076ff;
    cursor: pointer;
  }
`;

export const Session1 = styled.section`
  h1 {
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-top: 45px;
    margin-bottom: 15px;
    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  h2 {
    color: #252525 !important;
    font-size: 30px !important;
    font-weight: bold !important;
    margin-top: 15px;
    margin-bottom: 25px;
    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    color: #707070;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 10px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
  b {
    color: #707070;
    font-size: 16px;
    font-weight: bold !important;
  }
`;

export const Session2 = styled.section`
  margin-top: 50px;
  p {
    color: #252525 !important;
    margin-bottom: 30px;
  }
  b {
    color: #252525;
    font-weight: bold !important;
  }
`;

export const Title = styled.h1`
  font-size: 25px !important;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  font-weight: bold;
  color: #006766;
`;

export const Circle = styled.div`
  white-space: pre-wrap;
  background-color: #fff;
  border-radius: 50%;
  max-width: 300px;
  text-align: center;
  height: 300px;
  margin: 0 auto 30px 0;
  padding: 0 8px 0 8px;
  h2 {
    font-size: 14px !important;
    text-align: center;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  span {
    font-size: 12px !important;
    text-align: center;
  }
`;

export const IconCircle = styled.div`
  margin: 0;
  display: table;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const IconCard = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid #6ec592;
  margin: 0 auto 0 auto;
  position: relative;
`;

export const ShowIcon = styled.div`
  margin: 0;
  display: table;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

import React from 'react';
import SaveImg from '~/assets/img/CardBenefits/save-model.png';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const SaveWithoutUsingPoints = () => {
  return (
    <S.GrayBackground>
      <S.Container>
        <S.Image src={SaveImg} alt="" role="presentation" />
        <S.TextContainer>
          <S.Title>Economize sem usar seus pontos</S.Title>
          <S.Text>
            Comece a aproveitar os benefícios do programa sem esperar pela
            acumulação de pontos.
          </S.Text>
          <S.Text>Você tem desconto garantido!</S.Text>
          <S.Text>
            <a
              href="https://surpreenda.naotempreco.com.br/ofertas/?filter=sem-pontos"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                trackingSelectContent(
                  'confira-parceiros-participantes',
                  'beneficios'
                )
              }
            >
              Confira agora os parceiros participantes
            </a>
          </S.Text>
        </S.TextContainer>
      </S.Container>
    </S.GrayBackground>
  );
};

export default SaveWithoutUsingPoints;

import React from 'react';
import LoadingIcon from '~/components/Loading';

import { LoadingContainer, Text } from './styles';

export const Loading = () => {
  return (
    <LoadingContainer>
      <LoadingIcon size={6} verticalMargin={32} />
      <Text>Carregando, aguarde.</Text>
    </LoadingContainer>
  );
};

import Chaveiro from '~/assets/img/Financial/protected-home-insurance/chaveiro.svg';
import ServiçoEletricos from '~/assets/img/Financial/protected-home-insurance/servico-eletricos-e-hidraulico.svg';
import Vidraceiro from '~/assets/img/Financial/protected-home-insurance/vidraceiro.svg';
import CoberturaDeTelhados from '~/assets/img/Financial/protected-home-insurance/cobertura-de-telhados.svg';
import LimpezaEManutencao from '~/assets/img/Financial/protected-home-insurance/limpeza-e-manutencao.svg';
import ServicosGerais from '~/assets/img/Financial/protected-home-insurance/servicos-gerais.svg';
import ConsultoriaAmbiental from '~/assets/img/Financial/protected-home-insurance/consultoria-ambiental.svg';
import ConsertoDeEletrodomesticos from '~/assets/img/Financial/protected-home-insurance/conserto-de-eletrodomesticos.svg';
import Instalacoes from '~/assets/img/Financial/protected-home-insurance/instalacoes.svg';
import InspecaoDomiciliar from '~/assets/img/Financial/protected-home-insurance/inspecao-domiciliar.svg';
import SegurancaAcessibilidade from '~/assets/img/Financial/protected-home-insurance/seguranca-e-acessibilidade.svg';

export const mockData = [
  {
    image: Chaveiro,
    title: `Chaveiro`,
    description: 'Conserto de fechaduras ou confecção de chaves.',
    key: '1',
  },
  {
    image: ServiçoEletricos,
    title: `Serviço elétricos e hidráulico`,
    description: 'Reparos de vazamento e problemas elétricos.',
    key: '2',
  },
  {
    image: Vidraceiro,
    title: `Vidraceiro`,
    description: 'Conserto de portas/janelas ou colocação de tapume.',
    key: '3',
  },
  {
    image: CoberturaDeTelhados,
    title: `Cobertura de telhados`,
    description: 'Colocação de lona plástica em telhados danificados.',
    key: '4',
  },
  {
    image: LimpezaEManutencao,
    title: `Limpeza e manutenção`,
    description:
      'Limpeza de ar-condicionado, caixa d’água, desentupimento e pequenos reparos.',
    key: '5',
  },
  {
    image: ServicosGerais,
    title: `Serviços gerais`,
    description: 'Instalação de ventiladores, antenas e prateleiras.',
    key: '6',
  },
  {
    image: ConsultoriaAmbiental,
    title: `Consultoria ambiental`,
    description: 'Orientação para economia de energia e descarte ecológico.',
    key: '7',
  },
  {
    image: ConsertoDeEletrodomesticos,
    title: `Conserto de eletrodomésticos`,
    description:
      'Linha branca (fogão, geladeira, lavadora) e linha marrom (Tv/som).',
    key: '8',
  },
  {
    image: Instalacoes,
    title: `Instalações`,
    description:
      'Pias, portões automáticos, películas de box, ventiladores, interfones.',
    key: '9',
  },
  {
    image: InspecaoDomiciliar,
    title: `Inspeção domiciliar`,
    description:
      'Reparos, troca de vidros, olho mágico, lubrificação e revisões elétricas.',
    key: '10',
  },
  {
    image: SegurancaAcessibilidade,
    title: `Segurança e acessibilidade`,
    description:
      'Serviços para crianças e idosos como instalação de protetores, grades, barras e rampas.',
    key: '11',
  },
];

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  button {
    width: 90%;
  }
`;

export const Title = styled.h3`
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const SubTitle = styled.span`
  margin-bottom: 1rem;
`;
export const Authenticate = styled.span`
  font-weight: 600;
  margin-bottom: 1rem;
  svg {
    margin-right: 8px;
  }
`;
export const ContainerDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cacecd;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 1rem;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 12px;
    }
  }
`;
export const BoldDetails = styled.span`
  font-size: 16px !important;
  font-weight: 600;
`;
export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  span {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .qrcode {
    margin-bottom: 1rem;
  }
`;

export const TitleContent = styled.span`
  font-weight: 600;
`;

export const ContainerNewNegotiation = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin: 1rem auto;
  }
`;

export const TitleNewNegotiation = styled.h5`
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const ContainerContentWP = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #cacecd;
  border-radius: 8px;
  padding: 10px;
  margin: 16px 0 32px 0;
  line-height: 25px;

  button {
    margin-top: 10px;
    width: 90%;
  }
`;

export const ContainerMessage = styled.div`
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
`;
export const TitleBold = styled.h4`
  font-weight: bold;
`;

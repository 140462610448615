import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.336);
  z-index: 999;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: 0;
  padding: 8px;
  margin-right: 10px;
  font-size: 24px;
  font-family: Montserrat;
  font-weight: bold;
  color: #1f2b2a;

  :hover {
    color: #4f4f4f;
  }
`;

export const Box = styled.div`
  position: relative;
  background-color: #fff;
  max-width: 1088px;
  border-radius: 15px;
  animation: ${animation} 500ms none alternate;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

import cmsService from '~/services/cms/pages';
import { useState } from 'react';

export const useAttendancePortal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchAttendancePortalData = async () => {
    try {
      setIsLoading(true);
      const { data } = await cmsService.getDataByPermalink('attendance-portal');
      setIsError(false);
      const output = JSON.parse(data.response.draft.content);
      return output;
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    fetchAttendancePortalData,
  };
};

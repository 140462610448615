import { last } from 'lodash';
import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNegotiation } from '~/context/negotiationV2';
import useIsMobile from '~/hooks/useIsMobile';
import ArrowBack from '../components/ArrowBack';
import Contracts from '../components/Flow/Contracts';
import Elegibility from '../components/Flow/Elegibility';
import Registered from '../components/Flow/Registered';
import Duplicate from '../components/Flow/Duplicate';

import { ContainerFlow, ContainerContent } from './styles';
import PaymentMethod from '../components/Flow/PaymentMethod';
import { scroll } from '../../../services/helpers';

const FlowV2 = () => {
  const { toogles } = useSelector(state => state.featuretoggle);
  const { historyPages, negotiationData } = useNegotiation();
  const [currentPage, setCurrentPage] = useState('');
  const isMobile = useIsMobile();

  useEffect(() => {
    setCurrentPage(last(historyPages));
  }, [historyPages]);

  const showArrow = () => {
    if (currentPage === 'registered') {
      if (negotiationData?.contracts.length < 2) {
        return false;
      }
    }
    return true;
  };

  useLayoutEffect(() => {
    scroll();
  }, [currentPage]);

  return (
    <ContainerFlow>
      <ContainerContent
        mtop={
          toogles.isEnabledMtopPaymentMethod &&
          isMobile &&
          currentPage === 'paymentMethod'
        }
        isMobile={isMobile}
      >
        {showArrow() && <ArrowBack />}
        {currentPage === 'contracts' && <Contracts />}
        {currentPage === 'eligibility' && <Elegibility />}
        {currentPage === 'registered' && <Registered />}
        {currentPage === 'duplicate' && <Duplicate />}
        {currentPage === 'paymentMethod' && <PaymentMethod />}
      </ContainerContent>
    </ContainerFlow>
  );
};

export default FlowV2;

import React, { useMemo } from 'react';

import styled from 'styled-components';
import RchloDesktop from '../../../assets/img/Footer/riachuelo.svg';
import FanLabDesktop from '../../../assets/img/Footer/fanlab.svg';
import CasaRchlDesktop from '../../../assets/img/Footer/casa-rchlo.svg';
import CartersDesktop from '../../../assets/img/Footer/carters.svg';

import useIsMobile from '../../../hooks/useIsMobile';
import { trackingSelectContent } from '../../../analytics';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: ${props => (props.flex1 ? 1 : 'initial')};
  max-width: 543px;
  margin-top: ${props => (props.isMobile ? '24px' : 'initial')};
`;

const Item = styled.div`
  max-width: ${props => (props.isMobile ? '58px' : 'initial')};
  a {
    img {
      transition: all 0.2s ease-out;
      padding: 3px 0;
      &:hover {
        padding: 0px 0px 6px;
      }
    }
  }
`;

const Partners = () => {
  const isMobile = useIsMobile();
  const items = useMemo(() => {
    return [
      {
        name: 'Riachuelo',
        alt: 'Riachuelo',
        img: RchloDesktop,
        link: 'https://www.riachuelo.com.br/',
      },
      {
        name: 'Fan Lab',
        alt: 'Fan Lab',
        img: FanLabDesktop,
        link: 'https://www.fanlab.com.br/',
      },
      {
        name: 'Casa Riachuelo',
        alt: 'Casa Riachuelo',
        img: CasaRchlDesktop,
        link: 'https://www.riachuelo.com.br/moda-casa',
      },
      {
        name: "Carter's",
        alt: 'Carters',
        img: CartersDesktop,
        link: 'https://www.riachuelo.com.br/campanha/r/marca/carters?page=1',
      },
    ];
  }, []);
  return (
    <StyledContainer isMobile={isMobile} flex1>
      {items.map(item => (
        <Item
          isMobile={isMobile}
          key={item.name}
          onClick={() => trackingSelectContent(`footer:${item.name}`, 'home')}
        >
          <a href={item.link} rel="noopener noreferrer" target="_blank">
            <img src={item.img} alt={item.alt} title={item.name} />
          </a>
        </Item>
      ))}
    </StyledContainer>
  );
};

export default Partners;

import React, { useEffect, useState } from 'react';
import { Button, Image } from '@midway/web-ui-component';
import { useDispatch } from 'react-redux';

import Header from '~/components/Header';
import CallToAction from '~/components/CallToAction';
import { menuItems } from '~/constants';
import { showCallToAction } from '~/store/modules/calltoaction/actions';
import history from '~/services/history';

// importing images
import phoneBanner from '~/assets/img/new-home/phoneBanner.png';
import midwayERiachuelo from '~/assets/img/new-home/midwayeriachuelo.svg';
import midwayERiachueloBig from '~/assets/img/new-home/midwayeriachuelo-big.svg';
import logoWithCard from '~/assets/img/new-home/logowithcard.png';
import logoSmall from '~/assets/img/new-home/logoSmall.svg';
import appContaPremiada from '~/assets/img/new-home/appContaPremiada.png';
import modeloCartao from '~/assets/img/new-home/new-model.png';
import badgeGoogle from '~/assets/img/new-home/disponivel-google-play-badge.png';
import badgeApple from '~/assets/img/new-home/disponivel-na-app-store-botao.png';
import mcardfinal from '~/assets/img/new-home/mcardfinal.png';

import iconContaRemunerada from '~/assets/img/new-home/Iconly-Bold-Discount.svg';
import iconPix from '~/assets/img/new-home/Iconly-Bold-Wallet.svg';
import iconContaPremiada from '~/assets/img/new-home/Iconly-Bold-Chart.svg';
import iconOpenBank from '~/assets/img/new-home/Iconly-Bold-openbank.svg';

import iconEmprestimo from '~/assets/img/new-home/Iconly-emprestimo.svg';
import iconFatura from '~/assets/img/new-home/Iconly-fatura.svg';
import iconTransfer from '~/assets/img/new-home/Iconly-transfer.svg';
import iconSaques from '~/assets/img/new-home/Iconly-saque.svg';
import iconPagamentos from '~/assets/img/new-home/Iconly-pagamento.svg';
import iconChat from '~/assets/img/new-home/Iconly-chat.svg';

import circleSeguro from '~/assets/img/new-home/icon-seguros.svg';
import circleAssistencias from '~/assets/img/new-home/icon-assistencias.svg';
import circleEmprestimos from '~/assets/img/new-home/icon-emprestimos.svg';
import circleSaude from '~/assets/img/new-home/icon-saude.svg';
import circleDente from '~/assets/img/new-home/icon-dente.svg';
import circleLimpanome from '~/assets/img/new-home/icon-limpanome.svg';
import arrow from '~/assets/img/new-home/icon-arrow.svg';
import divider from '~/assets/img/new-home/divider.svg';

import leftArrow from '~/assets/img/new-home/left-arrow.svg';
import * as S from './styles-old';

const MAIN_FEATURES = [
  {
    img: iconContaRemunerada,
    title: 'Conta Remunerada:',
    feats: [
      { name: 'Mais um benefício exclusivo para sua saúde financeira.' },
      { name: 'Com a Midway o seu dinheiro tem rendimentos de 103% do CDI.' },
    ],
  },

  {
    img: iconPix,
    title: 'Pix na conta digital Midway:',
    feats: [
      { name: 'A sua facilidade é da nossa conta.' },
      {
        name: 'O jeito mais rápido e prático de fazer pagamentos também terá na sua conta digital Midway.',
      },
    ],
  },
  {
    img: iconContaPremiada,
    title: 'Conta ativa é Conta Premiada:',
    feats: [
      { name: '20 prêmios de R$500 todos os meses.' },
      {
        name: 'Abra e movimente a sua conta, pegue seu número da sorte e aproveite! Você pode ser o próximo sorteado.',
      },
    ],
  },
  {
    img: iconOpenBank,
    title: 'Open Finance está chegando:',
    feats: [
      {
        name: 'Agora você escolhe como, quando e com quais instituições você quer compartilhar os seus dados. ',
      },
      {
        name: 'Na Midway você tem benefícios e condições especiais. Mais autonomia, liberdade e segurança para você é da nossa conta.',
      },
      {
        name: (
          <a
            className="link-button"
            aria-label="Saiba mais"
            href="https://openfinance.midway.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Saiba mais
          </a>
        ),
      },
    ],
  },
];

const OTHER_FEATURES = [
  {
    img: iconEmprestimo,
    title: 'Empréstimos',
    feats: [{ name: 'Empréstimo on-line em poucos cliques' }],
  },
  {
    img: iconFatura,
    title: 'Fatura RCHLO',
    feats: [{ name: 'Fatura do cartão Riachuelo na sua mão' }],
  },
  {
    img: iconTransfer,
    title: 'Transferências',
    feats: [{ name: 'Transferências gratuitas e ilimitadas' }],
  },
  {
    img: iconSaques,
    title: 'Saques',
    feats: [{ name: 'Saques na rede Banco24Horas' }],
  },
  {
    img: iconPagamentos,
    title: 'Pagamentos',
    feats: [{ name: 'Pagamento de água, luz e boletos com saldo da conta' }],
  },
  {
    img: iconChat,
    title: 'WhatsApp',
    url: 'https://api.whatsapp.com/send/?phone=5511%2030030950&text=Ol%C3%A1%2C%20quero%20conversar%20com%20a%20Midway%20pelo%20WhatsApp%2E',
    feats: [{ name: 'Fale com a gente pelo WhatsApp' }],
  },
];

const PRODUCTS = [
  {
    icon: circleSeguro,
    title: 'Seguros',
    text: 'Conte com a nossa ajuda para proteger o que importa para você.',
    link: '/seguros',
  },
  {
    icon: circleAssistencias,
    title: 'Assistências',
    text: 'Estamos com você até nos imprevistos.',
    link: '/assistencias',
  },
  {
    icon: circleEmprestimos,
    title: 'Empréstimos',
    text: 'Precisa de dinheiro? \t\t\t\t A gente te ajuda.',
    link: '/emprestimos',
  },
  {
    icon: circleSaude,
    title: 'Serviços para Saúde',
    text: 'Na saúde ou na doença estamos com você.',
    link: '/saude',
  },
  {
    icon: circleDente,
    title: 'Plano Odontológico',
    text: 'A gente cuida do seu sorriso e da sua saúde.',
    link: '/plano-odontologico',
  },
  {
    icon: circleLimpanome,
    title: 'Negocie sua Dívida',
    text: 'Renegocie suas dívidas e planeje sua vida financeira',
    link: '/campanha-quitacao',
  },
];

export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 712);
  const dispatch = useDispatch();

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleShowModal = () => {
    if (isMobile) {
      window.open('http://onelink.to/ngrm9z', '_blank');
    } else {
      dispatch(showCallToAction(true));
    }
  };

  const Banner = () => {
    return (
      <S.Banner id="home" className="container-fluid">
        <div className="container">
          <div className="left-side">
            <h1>O seu bem-estar é da nossa conta</h1>
            <h2>Confira nossos serviços e vantagens.</h2>

            <a
              aria-label="Abra sua conta"
              href="javascript:;"
              onClick={handleShowModal}
            >
              Abra sua conta
            </a>
          </div>
          <div className="right-side">
            <Image
              alt="Imagem do app com cartão midway"
              src={phoneBanner}
              id="app"
            />
            <Image
              alt="Midway é Riachuelo"
              src={midwayERiachuelo}
              customClass="mid-is-rchlo"
            />
          </div>
        </div>
      </S.Banner>
    );
  };

  const FeatureMidway = ({ item }) => {
    return (
      <div className="feature-midway">
        <h2>
          {!item.url ? (
            <img src={item?.img} alt={`ícone de ${item?.title}`} />
          ) : (
            <a href={item?.url} target="_blank" rel="noopener noreferrer">
              <img src={item?.img} alt={`ícone de ${item?.title}`} />
            </a>
          )}

          {item?.title}
        </h2>
        <ul>
          {item?.feats.map(feature => (
            <li>{feature.name}</li>
          ))}
        </ul>
      </div>
    );
  };

  const AdvantagesMidway = ({ item }) => {
    return (
      <div className="advantage-midway">
        <div>
          <Image
            src={item?.icon}
            className="icon"
            alt={`ícone de ${item?.title}`}
          />
          <Image src={arrow} className="arrow" alt="seta para baixo" />
          <h2>{item?.title}</h2>
          <p>{item.text}</p>
        </div>
        <Button
          title="Saiba mais"
          outline
          block
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            item?.externalLink
              ? window.open(item?.link, '_blank')
              : history.push(item?.link);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <CallToAction />
      {!isMobile && (
        <S.WrapperHeader>
          <Header menuItems={menuItems} buttonOpenModal={handleShowModal} />
        </S.WrapperHeader>
      )}
      <main>
        <Banner />
        <S.Features>
          <div className="container">
            <div className="row center">
              <Image src={logoSmall} id="midway-logo" alt="Logo da Midway" />
            </div>
            <div className="row side-by-side">
              <div className="left-side">
                <div className="feature-midway-content">
                  {MAIN_FEATURES.map((feats, index) => (
                    <>
                      {index === 3 && (
                        <Image
                          alt="Midway é Riachuelo"
                          src={appContaPremiada}
                          id="appContaPremiadaMobile"
                        />
                      )}
                      <FeatureMidway item={feats} />
                      {index === 1 && (
                        <Image
                          alt="Imagem do app com cartão midway"
                          src={logoWithCard}
                          id="logoWithCardMobile"
                        />
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className="right-side">
                <Image
                  alt="Imagem do app com cartão midway"
                  src={logoWithCard}
                  id="logoWithCard"
                />
                <Image
                  alt="Midway é Riachuelo"
                  src={appContaPremiada}
                  id="appContaPremiada"
                />
              </div>
            </div>
          </div>
        </S.Features>
        <S.ColoredFeatures>
          <div className="side-by-side">
            <Image
              src={modeloCartao}
              alt="foto de uma mulher segunrando o cartão midway"
            />
            <div className="coloredBg">
              {OTHER_FEATURES.map(feats => (
                <FeatureMidway item={feats} />
              ))}
            </div>
          </div>
        </S.ColoredFeatures>

        <S.AppDownload>
          <div className="container bgCircle">
            <div className="content">
              <h2>A sua Praticidade é da nossa conta</h2>
              <p>Um app interativo e super fácil de usar.</p>
              <div>
                <a
                  href="https://apps.apple.com/br/app/midway-app/id1548732480"
                  title="app store"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Baixe o nosso app na App Store (abre uma nova janela)"
                >
                  <Image alt="disponível na App store" src={badgeApple} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.midway"
                  title="app store"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Baixe o nosso app na Play Store (abre uma nova janela)"
                >
                  <Image alt="disponível no google play" src={badgeGoogle} />
                </a>
              </div>
            </div>
            <Image
              alt="Midway é Riachuelo"
              src={midwayERiachueloBig}
              customClass="mid-is-rchlo"
            />
          </div>
        </S.AppDownload>

        <S.Advantages>
          <div className="container">
            <div className="header">
              <h2>
                Seu bem-estar e sua <br /> vida financeira andando juntos
              </h2>
              <p>Confira nossos serviços e vantagens.</p>
            </div>
            <div className="advantages-list">
              {PRODUCTS.map((item, index) => (
                <>
                  <AdvantagesMidway item={item} />
                  {(index + 1) % 3 === 0 && (
                    <Image src={divider} className="divider" alt="divisor" />
                  )}
                </>
              ))}
            </div>
            <Image
              alt="Midway é Riachuelo"
              src={midwayERiachuelo}
              customClass="mid-is-rchlo"
            />
          </div>
        </S.Advantages>
        <S.MultipleCard>
          <div className="container">
            <div className="left-side">
              <Image
                src={leftArrow}
                id="arrow"
                alt="Seta apontanto pra direita"
              />
              <div className="content">
                <h2>Cartão multiplo com função débito e crédito</h2>
                <p>Escolha como quer pagar.</p>
                <p>A sua liberdade é da nossa conta.</p>
              </div>
            </div>
            <div className="right-side">
              <Image
                src={mcardfinal}
                id="biglogo"
                alt="Imagem de um cartão de credito e logo da Midway"
              />
            </div>
          </div>
        </S.MultipleCard>
      </main>
    </>
  );
}

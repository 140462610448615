import React, { memo, useCallback, useEffect, useState } from 'react';
import Slider from 'react-slick/lib/slider';
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';
import { trackingViewPromotion } from '~/analytics';
import * as S from './style';
import 'slick-carousel/slick/slick.css';
import { mockData } from './data';
import { flow } from '~/analytics/home';
import Card from './components/card';

const checkTopStyle = () => {
  const element = document.getElementById('m-main');
  if (element) {
    const style = window.getComputedStyle(element);
    return style.top !== '0px';
  }
  return false;
};

const SampleNextArrow = memo(props => {
  const { className, onClick } = props;
  return (
    <div
      aria-label="Ir para o próximo"
      tabIndex={0}
      onClick={onClick}
      className={className}
    >
      <IconArrowRight size={30} />
    </div>
  );
});

const SamplePrevArrow = memo(props => {
  const { className, onClick } = props;
  return (
    <div
      role="button"
      aria-label="Voltar para o anterior"
      tabIndex={0}
      onClick={onClick}
      className={className}
    >
      <IconArrowLeft size={30} />
    </div>
  );
});

const AssistanceCarrousel = () => {
  const [cardsData, setCardsData] = useState([]);
  const [height, setHeight] = useState(0);
  const hasMobileHeader = useHasMobileDownloadHeader();

  useEffect(() => {
    const header = document.getElementById('m-main-header');
    const hasTopOnBody = checkTopStyle();

    if (header && !hasTopOnBody) {
      setHeight(header.offsetHeight);

      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (entry.target.id === 'm-main-header') {
            setHeight(entry.target.offsetHeight);
          }
        });
      });

      resizeObserver.observe(header);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const settings = {
    afterChange: index => {
      const banner = cardsData[index];
      trackingViewPromotion(
        banner.redirectUrl,
        flow.seguros,
        banner.title,
        banner.name
      );
    },
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 6000,
    overflow: 'hidden',
    slidesToShow: 4,
    slidesToScroll: 1,
    className: 'HeroRoot',
    accessibility: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          variableWidth: true,
        },
      },
    ],
  };

  const handleGetCardsData = useCallback(async () => {
    setCardsData(mockData);
  }, []);

  useEffect(() => {
    handleGetCardsData();
  }, [handleGetCardsData]);

  if (cardsData.length > 0) {
    return (
      <S.StyledContainer
        aria-label="lar-protegido"
        bannerHeight={height}
        hasMobileHeader={hasMobileHeader}
      >
        <Slider
          {...settings}
          onInit={() => {
            const banner = cardsData[0];
            trackingViewPromotion(
              banner.redirectUrl,
              flow.seguros,
              banner.title,
              banner.name
            );
          }}
        >
          {cardsData?.map(item => (
            <Card key={item.title} {...item} />
          ))}
        </Slider>
      </S.StyledContainer>
    );
  }
  return null;
};

export default AssistanceCarrousel;

import styled from 'styled-components';

export const Modal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  #background {
    width: 100%;
    height: 100%;
    background: #00000090;
    position: fixed;
    z-index: 5;
    top: 0;
  }
`;

export const ModalContent = styled.div`
  background: transparent linear-gradient(180deg, #c1fd95 0%, #46cbd0 100%) 0%
    0% no-repeat padding-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  max-width: 670px;
  z-index: 5;
  display: flex;
  padding: 50px;
  flex-direction: column;
  justify-content: center;

  h1 {
    font: bold 40px Montserrat;
    margin-top: 28px;
    padding-bottom: 28px;
  }

  p {
    font: 24px Montserrat !important;
    padding-bottom: 28px;
  }

  div.ahl-icon-closed {
    margin-top: 40px;
    width: 10px;
    align-self: flex-end;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .qrcode {
    width: 180px;
    margin-top: 20px;
    border-radius: 3px;
  }
`;

export const Bold = styled.strong`
  font-weight: bold;
`;

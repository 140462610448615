export const banner = [
  {
    name: 'BannerEducacaoFinanceira',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-educacao-financeira-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-educacao-financeira.png',
    title: 'Uma vida financeira organizada com um futuro mais tranquilo',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

import React from 'react';
import UserDetails from './UserDetails';
import PersonalData from './PersonalData';
import ValueLoan from './ValueLoan';
import FirstPayment from './FirstPayment';
import Installments from './Installments';
import SimulationSummary from './SimulationSummary';
import Welcome from './Welcome';

function StageStep(props) {
  const {
    step,
    form,
    onChange,
    onSetCustomer,
    customer,
    showNavigation,
    token,
    onSetToken,
  } = props;

  const renderContent = () => {
    const steps = {
      1: <Welcome />,
      2: <UserDetails form={form} onChange={onChange} />,
      3: <PersonalData form={form} onChange={onChange} />,
      4: (
        <ValueLoan
          form={form}
          onChange={onChange}
          onSetCustomer={onSetCustomer}
          showNavigation={showNavigation}
          token={token}
          onSetToken={onSetToken}
        />
      ),
      5: (
        <FirstPayment
          form={form}
          onChange={onChange}
          customer={customer}
          onSetCustomer={onSetCustomer}
        />
      ),
      6: <Installments form={form} onChange={onChange} customer={customer} />,
      7: (
        <SimulationSummary
          form={form}
          onChange={onChange}
          customer={customer}
        />
      ),
    };
    // eslint-disable-next-line no-prototype-builtins
    if (steps.hasOwnProperty(step)) return steps[step];
    return steps[1];
  };
  return renderContent();
}
export default StageStep;

import React, { useEffect, useState, useCallback } from 'react';

import { useAuth } from '~/context/auth';
import useConfirmInstallment from '~/hooks/useConfirmInstallment';
import useGetInstallmentSimulation from '~/hooks/useGetInstallmentSimulation';
import { trackingSelectContent, trackingException } from '~/analytics';
import { flow, contentPage, contentType, description } from '~/analytics/cards';

import { PAYMENT_METHOD } from '~/constants/installment';

import { InstallmentSimulation } from './components/InstallmentSimulation';
import { Loading } from './components/Loading';
import { GenericError } from './components/GenericError';
import { InstallmentSuccess } from './components/InstallmentSuccess';

const CHECK_BILLET_DELAY_FIRST_ATTEMPT = 4000;
const CHECK_BILLET_DELAY = 1000;
export const CHECK_BILLET_RETRY_QUANTITY = 4;

const InvoiceInstallment = ({
  installmentData: {
    activeInstallment,
    entryValueMin,
    entryValueMax,
    isSimulating,
    installmentOffer,
  },
  onBack,
  onSimulateNewInstallment,
  onConfirmInstallment,
}) => {
  const { maskedPan, cardFlag } = useAuth();
  const [
    confirmInstallment,
    {
      loading: confirmInstallmentLoading,
      error: confirmInstallmentError,
      data: newInstallment,
    },
    cleanupDoneConfirmation,
  ] = useConfirmInstallment();
  const [
    fetchSimulationById,
    {
      loading: isSimulationByIdLoading,
      error: isSimulationByIdWithError,
      data: simulation,
    },
    cleanupFetchedSimulation,
  ] = useGetInstallmentSimulation();

  const [lastConfirmation, setLastConfirmation] = useState();
  const [maxBilletChecksAttempt, setMaxBilletChecksAttempt] = useState(false);
  const [showProcessingView, setShowProcessingView] = useState(false);

  const simulationId =
    newInstallment?.simulationId ?? activeInstallment?.simulationId;
  const installmentState = activeInstallment?.state;
  const simulationState = simulation?.state;

  const startCheckingForBillet = useCallback(
    (checkBilletCount = 1, withDelay = true) => {
      const canKeepTrying = checkBilletCount <= CHECK_BILLET_RETRY_QUANTITY;
      if (canKeepTrying) {
        const isFirstAttempt = checkBilletCount === 1;
        let delay = 0;
        if (withDelay) {
          delay = isFirstAttempt
            ? CHECK_BILLET_DELAY_FIRST_ATTEMPT
            : CHECK_BILLET_DELAY;
        }

        setTimeout(async () => {
          try {
            await fetchSimulationById(simulationId);
          } catch (err) {
            if (!err.message.match('failed')) {
              checkBilletCount += 1;
              startCheckingForBillet(checkBilletCount);
            }
          }
        }, delay);
      } else {
        setMaxBilletChecksAttempt(true);
        setShowProcessingView(false);
      }
    },
    [fetchSimulationById, simulationId]
  );

  const handleErrorGoBack = () => {
    trackingSelectContent(
      contentType.goBack,
      flow.payments,
      contentPage.genericError
    );
    onBack();
  };

  const handleErrorSimulateNewInstallment = () => {
    trackingSelectContent(
      contentType.newInstallment,
      flow.payments,
      contentPage.genericError
    );
    handleDoNewInstallment();
  };

  const handleConfirmInstallment = confirmDTO => {
    const payload = { ...confirmDTO, allowMultipleInstallments: true };
    setLastConfirmation(payload);
    confirmInstallment(payload);
    onConfirmInstallment();
  };

  const handleDoNewInstallment = () => {
    setMaxBilletChecksAttempt(false);
    cleanupFetchedSimulation();
    cleanupDoneConfirmation();
    onSimulateNewInstallment();
  };

  const handleTryAgain = () => {
    trackingSelectContent(
      contentType.retry,
      flow.payments,
      contentPage.genericError
    );

    if (confirmInstallmentError) {
      confirmInstallment(lastConfirmation);
    }

    if (isSimulationByIdWithError) {
      const withDelay = false;
      startCheckingForBillet(undefined, withDelay);
    }
  };

  const hasPaymentResourceDone =
    installmentState === 'GERADO' || simulationState === 'GERADO';
  const hasPaymentResourceError =
    installmentState === 'BANK SLIP ERRO' ||
    simulationState === 'BANK SLIP ERRO';
  const hasAnyError =
    confirmInstallmentError ||
    isSimulationByIdWithError ||
    hasPaymentResourceError ||
    maxBilletChecksAttempt;

  useEffect(() => {
    const isActiveSimulationProcessing = installmentState === 'PROCESSANDO';
    if (newInstallment?.simulationId || isActiveSimulationProcessing) {
      const withDelay = !isActiveSimulationProcessing;
      startCheckingForBillet(undefined, withDelay);
    }
  }, [installmentState, simulationId, newInstallment, startCheckingForBillet]);

  useEffect(() => {
    if (installmentOffer) {
      const payload = {
        ...installmentOffer,
        allowMultipleInstallments: true,
      };
      setLastConfirmation(payload);
      confirmInstallment(payload);
    }
  }, [confirmInstallment, installmentOffer]);

  /**
   * Show Processing View on screen while confirming installment
   * or accessing an active installment with payment method by billet (for
   * an already made installment),
   *
   * This is necessary to display the Processing View properly.
   * Without this code, it'd display while confirming, then hiding it,
   * and then showing it again only when the first check for billet would
   * be done after the first call delay - CHECK_BILLET_DELAY_FIRST_ATTEMPT
   */
  useEffect(() => {
    const isActiveSimulationProcessing = installmentState === 'PROCESSANDO';
    if (
      confirmInstallmentLoading ||
      isSimulationByIdLoading ||
      isActiveSimulationProcessing
    ) {
      setShowProcessingView(true);
    }
  }, [installmentState, confirmInstallmentLoading, isSimulationByIdLoading]);

  useEffect(() => {
    if (!isSimulationByIdLoading && simulationState === 'GERADO') {
      setShowProcessingView(false);
    }
  }, [isSimulationByIdLoading, simulationState]);
  useEffect(() => {
    if (confirmInstallmentError || isSimulationByIdWithError) {
      setShowProcessingView(false);
    }
  }, [confirmInstallmentError, isSimulationByIdWithError]);

  useEffect(() => {
    if (hasAnyError) {
      trackingException(description.getBilletError, flow.payments);
    }
  }, [hasAnyError]);

  if (showProcessingView) {
    const paymentMethod =
      lastConfirmation?.paymentMethod ?? activeInstallment.paymentMethod;
    return <Loading paymentMethod={paymentMethod} />;
  }

  if (hasAnyError) {
    const simulateNewInstallment =
      maxBilletChecksAttempt || hasPaymentResourceError;

    return (
      <GenericError
        onTryAgain={handleTryAgain}
        onBack={handleErrorGoBack}
        simulateNewInstallment={
          simulateNewInstallment && handleErrorSimulateNewInstallment
        }
      />
    );
  }

  if (hasPaymentResourceDone) {
    const {
      payline,
      entryValue,
      installments,
      paymentDueDate,
      totalFinancing: totalValue,
      paymentMethod,
      pixCopyAndPaste,
      pixIdentifier,
    } = simulation ?? activeInstallment;

    const props = {
      flag: cardFlag,
      cardLastFourDigits: maskedPan.substring(
        maskedPan.length - 4,
        maskedPan.length
      ),
      dueDate: paymentDueDate,
      installmentValue: installments.value,
      amountOf: installments.quantity,
      entryValue,
      payline:
        paymentMethod === PAYMENT_METHOD.BILLET ? payline : pixCopyAndPaste,
      totalValue,
      paymentMethod,
      pixId: pixIdentifier,
      onGoBackButtonPress: onBack,
      onNewInstallmentButtonPress: handleDoNewInstallment,
      isActiveInstallment: !!activeInstallment,
      isSimulatedInstallment: !installmentOffer,
    };

    return <InstallmentSuccess {...props} />;
  }

  if (isSimulating) {
    return (
      <InstallmentSimulation
        entryValueMax={entryValueMax}
        entryValueMin={entryValueMin}
        onBack={onBack}
        onConfirmInstallment={handleConfirmInstallment}
      />
    );
  }

  return null;
};

export default InvoiceInstallment;

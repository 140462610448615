import { useState, useCallback } from 'react';

import { getSimulation } from '~/services/InvoiceInstallment';
import { useAuth } from '~/context/auth';

const useGetInstallmentSimulation = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const { documentNumber, machineId: deviceId } = useAuth();

  const memoized = useCallback(
    simulationId =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        setLoading(true);
        setError(false);
        try {
          const response = await getSimulation({
            documentNumber,
            deviceId,
            simulationId,
          });
          if (response.data.state === 'PROCESSANDO') {
            return reject(new Error('Billet still being processed'));
          }
          if (response.data.state === 'BANK SLIP ERRO') {
            setError(true);
            return reject(new Error('Billet has failed'));
          }
          setData(response.data);
          return resolve(response.data);
        } catch (err) {
          setError(true);
          return reject(err);
        } finally {
          setLoading(false);
        }
      }),
    [deviceId, documentNumber]
  );

  const cleanup = () => {
    setData(null);
    setError(false);
    setLoading(false);
  };

  return [
    memoized,
    {
      loading,
      error,
      data,
    },
    cleanup,
  ];
};

export default useGetInstallmentSimulation;

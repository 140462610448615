import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 105px;
  padding-bottom: 48px;
  @media (max-width: 992px) {
    margin-top: 42px;
    padding-bottom: 28px;
  }

  .error-body {
    border-radius: 0 0 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #252525;
      font-weight: bold;
      padding-bottom: 0;
      line-height: 35px;
      @media (max-width: 992px) {
        line-height: 20px;
        font-size: 15px;
      }
    }
    span {
      color: #252525;
      line-height: 35px;
      @media (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
`;

export const scroll = id => {
  const element = document.getElementById(id || 'm-body');
  if (element)
    if (id) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      element.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
};

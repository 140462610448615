import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  padding: 0 !important;
`;

const inputStyles = css`
  input {
    width: 40% !important;
    font-size: 16px !important;
    text-align: center;
    margin-bottom: 25px !important;

    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 1px;

    :last-child {
      margin-top: 25px;
    }

    @media (max-width: 790px) {
      width: 60% !important;
    }

    @media (max-width: 490px) {
      width: 95% !important;
      text-align: start !important;
      padding-left: 10px;
    }
  }

  input::placeholder {
    color: #000000;
    font-size: 16px !important;
    font-weight: bold;
  }

  input.input-email {
    display: flex;
    position: relative;
  }

  div.input-email-text {
    color: red;
    text-align: center;
    font-size: 12px !important;
    margin-top: -26px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  position: relative;
  min-height: 200px;
  max-height: 400px !important;
  padding: 0 !important;

  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }

  div.ahl-title {
    width: 43%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 23px;
    text-align: center;
    margin-bottom: 30px;

    @media (max-width: 886px) {
      width: 60%;
    }

    @media (max-width: 415px) {
      width: 90%;
    }
  }

  div.ahl-subtitle {
    color: grey;
    font-size: 16px;
    text-align: center;
    padding: 30px;

    span {
      font-weight: bold;
    }
  }

  div.ahl-input {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom-style: solid;
    border-width: 1px;
    position: relative;

    margin-bottom: -14px;

    @media (max-width: 886px) {
      width: 40%;
    }

    @media (max-width: 500px) {
      width: 60%;
    }

    span {
      left: 10px;
      position: absolute;
      margin-right: 5px;
      color: #006464;
      font-weight: bold;
    }

    input {
      width: 75%;
      border-style: hidden;
      border-color: #000;
      border-width: 1px;
      color: #006464;
      font-weight: bold;
      text-align: center;
    }

    img {
      position: absolute;

      width: 20px;
      height: 20px;

      right: 0;
    }

    .maskedInputStyle {
      margin-bottom: 0px !important;
    }
  }

  ${inputStyles};
`;

export const BirthDateInput = styled.input`
  ${inputStyles};

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  border: 1px solid grey;
  color: #000000;

  @media (max-width: 490px) {
    width: 95% !important;
    justify-content: flex-start !important;
    padding-left: 10px;
  }

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  background-color: #ffffff;

  ::before {
    ${props =>
      !props.value &&
      css`
        content: attr(placeholder) !important;
        font-weight: bold;
        width: 100%;
      `};
  }

  :focus:before {
    content: '' !important;
  }

  &:not(:focus) {
    ::-webkit-datetime-edit {
      ${props => !props.value && 'display: none;'}
    }
  }
`;

export const FooterButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 400px) {
    flex-direction: column;
  }

  div.ahl-button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 400px) {
      width: 70%;
    }

    &:last-child {
      justify-content: flex-start;
      margin-left: 10px;

      @media (max-width: 400px) {
        justify-content: center;

        order: 1;
      }
    }

    &:first-child {
      justify-content: flex-end;

      @media (max-width: 400px) {
        justify-content: center;

        margin-top: 8px;

        order: 2;
      }
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  padding: 40px;

  font-size: 20px;

  button {
    width: 38% !important;
    margin-top: 30px;
  }

  div.ahl-title {
    width: 100%;
    font-weight: bold;
    font-size: 23px !important;
  }

  div.ahl-subtitle {
    width: 100%;
    color: #9f9f9f;
    font-size: 11px !important;
    margin-top: 10px;

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  div.ahl-middle {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;

    padding-top: 15px;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    &-body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      &-text-small {
        width: 70%;
        color: #9f9f9f;
        font-size: 11px !important;

        padding-bottom: 10px;

        &-deficient {
          width: 70%;
          color: #9f9f9f;
          font-size: 11px !important;

          padding-bottom: -10px;
          padding-top: 8px;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }

      &-text-bold {
        font-weight: bold;
        font-size: 16px !important;

        margin-bottom: 7px;
      }

      &-images {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-arround;
        flex-direction: row;
        a {
          border-style: none;
        }
        img {
          width: 110px;
          height: 30px;
          padding-right: 16px;
        }
      }
    }
  }

  div.ahl-contract-app-top {
    margin-bottom: 26px;

    @media (min-width: 480px) {
      display: none;
    }
  }

  div.ahl-contract-app-bottom {
    @media (max-width: 480px) {
      display: none;
    }
  }
  div.ahl-icon-closed {
    position: absolute;

    top: 0;
    right: 0;
    cursor: pointer;

    img {
      width: 50px;
      height: 50px;
    }
  }
`;

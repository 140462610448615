import React, { memo } from 'react';
import { Modal } from '@midway/web-ui-component';

import info from '~/assets/img/Icones/triangle-info.svg';
import arrowsRotate from '~/assets/img/Icones/arrows-rotate.svg';

import { ModalHeader } from '../ModalHeader';

import {
  Content,
  Title,
  Description,
  ImageError,
  Button,
  ButtonImage,
} from './styles';

export const GenericError = memo(
  ({ simulateNewInstallment, onHide, onTryAgain, onBack }) => (
    <Modal
      title={
        <ModalHeader
          title="PARCELAMENTO DE FATURA"
          showBackButton={false}
          showCloseButton
          onBack={onBack}
        />
      }
      size="md"
      openModal
      onHide={onHide ?? (() => null)}
    >
      <Content>
        <ImageError src={info} aria-hidden="true" />
        <Title>Algo deu errado...</Title>
        <Description>
          Infelizmente não podemos seguir com sua{'\n'} solicitação no momento,
          tente novamente.
        </Description>
        {simulateNewInstallment ? (
          <Button state="secondary" onClick={simulateNewInstallment}>
            Simular um novo parcelamento
          </Button>
        ) : (
          <Button
            state="secondary"
            aria-label="Tentar novamente"
            onClick={onTryAgain}
          >
            <ButtonImage src={arrowsRotate} />
            Tentar novamente
          </Button>
        )}
      </Content>
    </Modal>
  )
);

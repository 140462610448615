import moment from 'moment';

// Empréstino Início
// Tageamento após a interação com o campo de CPF e clicar em "prosseguir"
const tagSimulacaoEmprestimoInteracao = loading => {
  if (!loading) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:simulacao-emprestimo',
      eventAction: 'interacao:campo',
      eventLabel: 'cpf',
    });
  }
};

// Página para inserir dados pessoais para novo CPF
// Tageamento no callback após tentar prosseguir
const tagSimulacaoEmprestimoDadosPessoais = (nomeBotao, loading) => {
  if (!loading) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:simulacao-emprestimo',
      eventAction: 'dados-pessoais:callback',
      eventLabel: `${nomeBotao}`,
    });
  }
};

// Tageamento no callback após tentar prosseguir e gerar erro
const tagSimulacaoEmprestimoDadosPessoaisErro = (erro, loading) => {
  if (!loading) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:simulacao-emprestimo',
      eventAction: 'dados-pessoais:callback',
      eventLabel: `erro:${erro}`,
    });
  }
};

// Página 'Vi que você ainda não tem um cartão RCHLO.', caso ainda não cadastrado
// Tageamento no clique dos botões
const tagSimulacaoEmprestimoRCHLOButton = nomeBotao => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:simulacao-emprestimo',
    eventAction: 'clique:botao:vi-que-nao-tem-cartao-rchlo',
    eventLabel: `baixe-app:${nomeBotao}`,
  });
};

// Tageamento no clique dos botões, caso seja um correntista sem limite
const tagSimulacaoEmprestimoNoLimit = (nomeBotao, loading) => {
  if (!loading) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:simulacao-emprestimo',
      eventAction: 'clique:botao:nao-tem-limite-pre-aprovado',
      eventLabel: `baixe-app:${nomeBotao}`,
    });
  }
};

// Página de Boas Vindas
// Tageamento no clique dos botões
const tagSimulacaoEmprestimoWellComeScreenButton = nomeBotao => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:simulacao-emprestimo',
    eventAction: 'clique:botao:bem-vindo-simulador-emprestimo',
    eventLabel: `${nomeBotao}`,
  });
};

// Página para inserir dados pessoais para cliente correntista
// Tageamento no callback após tentar prosseguir
const tagSimulacaoEmprestimoDadosPessoaisCorrentista = (nomeBotao, loading) => {
  if (!loading) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:simulacao-emprestimo',
      eventAction: 'dados-pessoais-correntista',
      eventLabel: `${nomeBotao}`,
    });
  }
};

// Tageamento no callback após tentar prosseguir e gerar erro
const tagSimulacaoEmprestimoDadosPessoaisCorrentistaErro = (erro, loading) => {
  if (!loading) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'event',
      eventCategory: 'midway:simulacao-emprestimo',
      eventAction: 'dados-pessoais-correntista:callback',
      eventLabel: `erro:${erro}`,
    });
  }
};

// Na página "Quanto você precisa?", caso seja um correntista com limite
// Tageamento no clique dos botões
const tagSimulacaoEmprestimoStep1Button = nomeBotao => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:simulacao-emprestimo',
    eventAction: 'clique:botao:quanto-voce-precisa',
    eventLabel: `${nomeBotao}`,
  });
};

// Na página "Primeiro pagamento em até:", caso seja um correntista com limite
// Tageamento no clique dos botões
const tagSimulacaoEmprestimoStep2Button = nomeBotao => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:simulacao-emprestimo',
    eventAction: 'clique:botao:primeiro-pagamento-ate',
    eventLabel: `${nomeBotao}`,
  });
};

// Tageamento no clique dos botões, quanto ao resumo da simulação caso seja um correntista com limite
const tagSimulacaoEmprestimoStep3Resumo = (
  nomeBotao,
  valor,
  parcelas,
  primeiroPagamento,
  taxas
) => {
  valor = parseFloat(valor).toFixed(2);
  parcelas = parcelas.replace('R$', '-').replace(/\s/g, '').replace(',', '.');
  const duration = moment.duration(new Date(primeiroPagamento) - new Date());
  const days = Math.trunc(duration.asDays());
  if (days > 50) {
    primeiroPagamento = 80;
  } else {
    primeiroPagamento = 50;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:simulacao-emprestimo',
    eventAction: 'clique:botao:resumo-simulacao',
    eventLabel: `${nomeBotao}`,
    dimension4: `emprestimo-${valor}`,
    dimension5: `emprestimo-${parcelas}`,
    dimension6: `ate-${primeiroPagamento}-dias`,
    dimension7: `${taxas}%`,
  });
};

// Tageamento no clique dos botões, quanto à contratação caso seja um correntista com limite
const tagSimulacaoEmprestimoStep3Final = nomeBotao => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:simulacao-emprestimo',
    eventAction: 'clique:botao:gostou-quer-contratar',
    eventLabel: `baixe-app:${nomeBotao}`,
  });
};

// Tageamento no clique dos botões de parceiros
const tagBotoesParceiros = (botao, parceiro) => {
  const ParceiroName = parceiro
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[.]/g, 'ponto')
    .replace(/[êé]/g, 'e')
    .replace(/[óôõ]/g, 'o')
    .replace(/[áâã]/g, 'a')
    .replace(/[í]/g, 'i')
    .replace(/[&]/g, '')
    .replace(/[!?#]/g, '')
    .replace('+', '');
  console.log(ParceiroName);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:parceiros',
    eventAction: `clique:${botao}`,
    eventLabel: `card:${ParceiroName}`,
  });
};

// Página Conta Online Consulta de Boleto
// Tageamento no clique dos campos
const tagContaOnlineConsultaBoletoForm = nomeCampo => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:conta-online',
    eventAction: 'interacao:form',
    eventLabel: nomeCampo,
  });
};

// Página Conta Online Consulta de Boleto
// Tageamento no clique dos botões
const tagContaOnlineConsultaBoletoButton = nomeBotao => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:conta-online',
    eventAction: 'clique:botao',
    eventLabel: nomeBotao,
  });
};

// Página Conta Online Consulta de Boleto
// Tageamento no clique dos links
const tagContaOnlineConsultaBoletoLink = link => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:conta-online',
    eventAction: 'clique:link',
    eventLabel: link,
  });
};

// Página Conta Online Consulta de Boleto
// Tageamento no clique dos links
const tagContaOnlineUpsellLink = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:conta-online',
    eventAction: 'view:banner-cartao',
  });
};

// Página Conta Online Consulta de Boleto
// Tageamento de callback
const tagContaOnlineConsultaBoletoCallback = (opcao, callback) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'midway:conta-online',
    eventAction: 'envio:callback-form',
    eventLabel: `${opcao}-${callback}`,
  });
};

const tagMiAjudaButton = nomeBotao => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'lp-midway:quitacao-de-divida',
    eventAction: 'clique:botao',
    eventLabel: nomeBotao,
  });
};

const tagMiAjudaCallback = (opcao, callback) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'lp-midway:quitacao-de-divida',
    eventAction: 'callback:consulte-sua-divida',
    eventLabel: `${opcao}-${callback}`,
  });
};

const tagMiAjudaView = screen => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'lp-midway:quitacao-de-divida',
    eventAction: 'view',
    eventLabel: screen,
  });
};

export {
  tagSimulacaoEmprestimoInteracao,
  tagSimulacaoEmprestimoDadosPessoais,
  tagSimulacaoEmprestimoDadosPessoaisErro,
  tagSimulacaoEmprestimoRCHLOButton,
  tagSimulacaoEmprestimoWellComeScreenButton,
  tagSimulacaoEmprestimoNoLimit,
  tagSimulacaoEmprestimoDadosPessoaisCorrentista,
  tagSimulacaoEmprestimoDadosPessoaisCorrentistaErro,
  tagSimulacaoEmprestimoStep1Button,
  tagSimulacaoEmprestimoStep2Button,
  tagSimulacaoEmprestimoStep3Resumo,
  tagSimulacaoEmprestimoStep3Final,
  tagBotoesParceiros,
  tagContaOnlineConsultaBoletoForm,
  tagContaOnlineConsultaBoletoButton,
  tagContaOnlineConsultaBoletoLink,
  tagContaOnlineConsultaBoletoCallback,
  tagContaOnlineUpsellLink,
  tagMiAjudaButton,
  tagMiAjudaCallback,
  tagMiAjudaView,
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1173px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 16px;

  @media screen and (max-width: 1219px) {
    margin-bottom: 24px;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 376px;
  max-height: 280px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 4px 6px -1px #1f2b2a1f;
  padding: 24px;

  @media (max-width: 1219px) {
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
  }
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  @media (max-width: 1219px) {
    font-size: 18px;
  }
`;

export const Line = styled.div`
  max-width: 35px;
  max-height: 38px;
  border: 1px solid #a06d2c;
  margin-bottom: 10px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding-bottom: 32px;
`;

export const Button = styled.button`
  color: #fff;
  width: 328px;
  height: 49px;
  padding: 16px 60px 16px 60px;
  border-radius: 50px;
  background-color: #1f2b2a;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: backgroud-color 0.5s ease;

  &:hover {
    color: inherit;
    background-color: #cdcdcd;
  }
`;

import styled from 'styled-components';

export const Section = styled.div``;
export const ProductWrapper = styled.div`
  padding-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  /* box-sizing: border-box; */
  @media (max-width: 768px) {
    padding-top: 16px;
  }
  @media (max-width: 492px) {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 16px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 0 33px;
  padding-top: 100px;
  margin: 0 auto;
  margin-bottom: 50px;
  max-width: 1600px;

  @media screen and (max-width: 900px) {
    padding: 0 15px !important;
    padding-top: 40px !important;
  }
`;

import styled from 'styled-components';
import * as CommonStyles from '../styles';

export const WhiteTopContainer = styled.div`
  background-image: linear-gradient(
    to bottom,
    #fff,
    #fff 48px,
    #221f20 48px,
    #221f20
  );
  width: 100%;
  margin: 64px 0;

  @media screen and (min-width: 1176px) {
    background-image: linear-gradient(
      to bottom,
      #fff,
      #fff 31px,
      #221f20 31px,
      #221f20
    );
    margin: 144px 0 112px;
    padding-top: 32px;
  }
`;

export const Container = styled(CommonStyles.Container)`
  padding: 32px 16px;
  display: grid;
  grid-template-columns: 1fr minmax(auto, 271px);
  grid-template-rows: auto;
  grid-template-areas:
    'image image'
    'title title'
    'text text'
    'buttons buttons';

  @media screen and (min-width: 1176px) {
    padding: 32px 0;
    grid-template-columns: 1fr minmax(auto, 381px);
    grid-template-areas:
      'title image'
      'text image'
      'buttons image';
  }
`;

export const Title = styled(CommonStyles.Title)`
  color: #fff;
  margin-bottom: 24px;
  grid-area: title;
  max-width: 279px;
  font-size: 1.25rem !important;
`;

export const Text = styled(CommonStyles.Text)`
  grid-area: text;
  &:last-of-type {
    margin-bottom: 40px !important;
  }
`;

export const Image = styled.img`
  height: 179px;
  width: auto;
  object-fit: contain;
  margin-top: -22px;
  margin-bottom: 11px;
  grid-area: image;

  @media screen and (min-width: 1176px) {
    margin-bottom: -32px;
    margin-top: -64px;
    width: 381px;
    height: 271px;
  }
`;

export const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  max-height: 41px;

  img {
    height: 100%;
  }
`;

export const LinkButton = styled.a``;

import styled, { css, keyframes } from 'styled-components';

export const ContainerStatus = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh);
  position: fixed;
  z-index: 1;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  overflow: hidden;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Title = styled.p`
  color: #1f2b2a;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  padding-bottom: 18px;
`;

export const ImageContainer = styled.div`
  width: 96px;
  padding: 24px 0;
`;

export const Description = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3b4443;
  text-align: center;
  b {
    font-weight: bold;
  }
`;

export const GetApp = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3b4443;
  text-align: center;
  margin-top: 30px;
`;

export const Button = styled.button`
  background: #00726d;
  padding: 11px 10px 12px;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #00726d;
  font-weight: bold;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border-radius: 24px;
  width: 100%;
  transition:
    width 0.3s ease-in-out 0s,
    background 0.2s linear,
    color 0.2s linear;

  &:hover {
    color: #00726d;
    background: #d6fffe;
  }

  :disabled {
    background: rgba(0, 128, 122, 0.2);
    cursor: not-allowed;
  }

  @media (min-width: 540px) {
    width: 80%;
  }

  ${({ outline }) =>
    outline &&
    css`
      color: #00726d;
      background: #ffffff;
    `}
`;

export const Footer = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3b4443;
  text-align: center;
  position: absolute;
  bottom: 15px;
  width: 40%;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 40%;
  margin-top: 40px;
  align-items: center;
  gap: 12px;

  @media (max-width: 768px) {
    width: 80%;
  }

  ${({ confirm }) =>
    confirm &&
    css`
      width: 30%;
    `}
`;

export const ContainerLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  background: #00726d;

  svg {
    animation: ${rotate} 2s linear infinite;
  }
  p {
    color: #ffffff;
    margin-top: 20px;
    font-size: 20px;
    text-align: center;
  }
`;

export const ContainerContent = styled.div`
  background: #fff;
  width: 100%;
  min-width: 232px;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 32px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  animation: ${opacity} 0.2s linear;
`;

export const BackArrowWrapper = styled.div`
  position: absolute;
  top: 116px;
  left: 30px;
`;

/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from '@midway/web-ui-component';
import {
  cloneDeep,
  debounce,
  first,
  isArray,
  isEmpty,
  kebabCase,
  last,
  lowerCase,
} from 'lodash';
import BallonDiscount from '~/pages/NewNegotiationCampaign/components/BallonDiscount';
import { simulationResponseMapper, percent } from '~/utils/negotiationHelpers';
import { useNegotiation } from '~/context/negotiationV2';
import useSimulation from '~/hooks/useSimulation';
import format from '~/services/format';
import LoadingSkeleton from '~/components/NegotiationCampaign/LoadingSkeleton';
import useGetCollectionPaymentMethod from '~/hooks/useGetCollectionPaymentMethod';
import useCorrectText from '~/hooks/useCorrectText';
import { gtagSelectContent, gtagException } from '~/utils/TaggingGA4';
import {
  ButtonsContainer,
  Label,
  RoundButton,
  InfoButton,
  Value,
  Container,
} from './styles';
import { Title, Content } from '../ProposalFactory/styles';

const GTAG_CONTENT_PAGE = 'visualizacao-de-proposta';
const GTAG_FLOW = 'miajuda';

const GenericInstallmentProposal = ({
  buttonText,
  simulationParams,
  typeEligibility,
  eligibilityData,
}) => {
  const { toogles } = useSelector(state => state.featuretoggle);
  const {
    negotiationData,
    setNegotiationData,
    system,
    setHistoryPages,
    setTypeNegotiationRegistered,
    type,
    handleModalDetails,
    hideAgreement,
    setHideAgreement,
  } = useNegotiation();
  const { eligibility } = negotiationData;

  const negotiable = eligibility[typeEligibility];
  const installment = negotiable?.simulation?.selectedInstallmentData;

  const [installmentSelected, setInstallmentSelected] = useState(
    isEmpty(installment)
      ? simulationParams.maxInstallmentNumber
      : installment?.installmentQuantity
  );
  const installmentDifferenceByClicks = useRef(installmentSelected);
  const [getPaymentMethod, getActions, loadingPaymentMethod] =
    useGetCollectionPaymentMethod();

  const { invoicementInOverdue } = useCorrectText();

  const entryValue = simulationParams.entry;
  const isOnlyOneSimulation =
    simulationParams.system === 'TOPAZ' ||
    simulationParams.system === 'SIPF' ||
    typeEligibility === 'invoicement';

  const { data, isLoading, error } = useSimulation({
    ...simulationParams,
    installmentNumber: isOnlyOneSimulation ? undefined : installmentSelected,
  });
  const maxInstallmentNumber =
    data?.installmentPlans?.length || simulationParams.maxInstallmentNumber;

  if (isOnlyOneSimulation && maxInstallmentNumber < installmentSelected) {
    setInstallmentSelected(maxInstallmentNumber);
    installmentDifferenceByClicks.current = maxInstallmentNumber;
  }
  useEffect(() => {
    if (error?.message)
      gtagException(GTAG_CONTENT_PAGE, GTAG_FLOW, kebabCase(error?.message));
  }, [error]);

  const simulation = simulationResponseMapper(data, type, installmentSelected);

  const selectedInstallmentData = simulation?.selectedInstallmentData;

  const contentType =
    typeEligibility === 'invoicement'
      ? 'selecionar-parcelamento-de-fatura'
      : 'selecionar-parcelado';

  const submitHandler = async () => {
    gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, contentType, {
      desconto: data?.discount?.maxTotal || 0,
      valor_das_parcelas: selectedInstallmentData?.installmentAmount,
      numero_de_parcelas: installmentSelected,
      valor_de_entrada: entryValue,
      tipo_proposta: typeEligibility,
      tipo_cartao: type,
      sistema: lowerCase(system),
      proposta: negotiationData?.gaParamProposta,
      contrato: negotiationData?.selectedContract?.contract,
    });

    if (negotiationData.registered)
      return setHistoryPages(prevHistory => [...prevHistory, 'registered']);

    const clonedData = cloneDeep(negotiationData);
    if (isArray(clonedData.eligibility[typeEligibility]))
      clonedData.eligibility[typeEligibility] = first(
        clonedData.eligibility[typeEligibility]
      );
    clonedData.eligibility[typeEligibility].simulation = simulation;

    setTypeNegotiationRegistered(typeEligibility);
    setNegotiationData(clonedData);

    const { simulationToken: token } = simulation;

    if (toogles.isEnabledPaymentMethodMiAjuda) {
      getPaymentMethod({ system: type, token, typeEligibility });
    } else
      getActions({
        typeProposal: typeEligibility,
        token,
        discount: eligibilityData?.discount,
        installment: installmentSelected,
      }).request();
  };

  const debouncedInstallmentSelection = debounce(
    () => {
      gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, 'parcela-alterada', {
        parcela: installmentDifferenceByClicks.current,
        ...ga4Params(),
      });
      setInstallmentSelected(installmentDifferenceByClicks.current);
    },
    isOnlyOneSimulation ? 0 : 200
  );

  const handleButton = plusOrMinus => {
    installmentDifferenceByClicks.current += plusOrMinus;
    debouncedInstallmentSelection();
  };

  const ga4Params = () => {
    return {
      sistema: lowerCase(system),
      tipo_proposta: typeEligibility,
      proposta: negotiationData?.gaParamProposta,
      contrato: negotiationData?.selectedContract?.contract,
    };
  };
  const handleMinusButton = () => {
    gtagSelectContent(
      GTAG_CONTENT_PAGE,
      GTAG_FLOW,
      'diminuir_parcela',
      ga4Params()
    );
    if (installmentDifferenceByClicks.current <= 1) return;
    handleButton(-1);
  };

  const handlePlusButton = () => {
    gtagSelectContent(
      GTAG_CONTENT_PAGE,
      GTAG_FLOW,
      'aumentar_parcela',
      ga4Params()
    );
    if (installmentDifferenceByClicks.current >= maxInstallmentNumber) return;
    handleButton(1);
  };

  const plusButtonDisabled = installmentSelected >= maxInstallmentNumber;
  const minusButtonDisabled = installmentSelected <= 1;

  const renderSkeleton = width => {
    if (isLoading || isEmpty(simulation))
      return <LoadingSkeleton height="1rem" width={width} />;

    return null;
  };

  const handleDetails = () => {
    const discount = eligibilityData?.discount;

    if (discount)
      discount.antecipatedDiscountValue =
        eligibilityData.antecipatedDiscountValue;

    const entry =
      eligibilityData?.minEntryValueApplyAllDiscounts ??
      eligibilityData?.minEntryValue ??
      eligibilityData?.entry;

    setNegotiationData({
      ...negotiationData,
      details: {
        ...selectedInstallmentData,
        discount: eligibilityData?.discount,
        entry,
        type: typeEligibility,
        financingTotal:
          selectedInstallmentData?.financingTotalAmount ||
          selectedInstallmentData?.totalAmountContract,
      },
    });
    handleModalDetails();
  };

  const renderTitle = () => {
    if (typeEligibility === 'agreement') {
      if (system === 'AGREEMENTS') return <>Parcele todo o saldo</>;
      if (system === 'SICC')
        return <>Parcele o valor em atraso + demais faturas</>;
      return <>Parcele o valor em atraso + demais parcelas</>;
    }

    if (typeEligibility === 'invoicement') {
      const maxInstallmentQuantity = last(
        data?.installments
      )?.installmentQuantity;
      if (negotiationData?.onlyInvoicement)
        return (
          <>
            Parcele sua fatura em até <b>{maxInstallmentQuantity}x</b>:
          </>
        );
      return (
        <>
          Parcele o valor {invoicementInOverdue()} em até{' '}
          <b>{maxInstallmentQuantity}x:</b>
        </>
      );
    }
  };

  useEffect(() => {
    if (
      !isLoading &&
      typeEligibility === 'agreement' &&
      isEmpty(simulation) &&
      !hideAgreement
    ) {
      setHideAgreement(!hideAgreement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <Content>
        <Title>{renderTitle()}</Title>
        <InfoButton
          onClick={() => handleDetails()}
          aria-label="Mostrar informações da proposta"
        >
          <Icon icon="info" />
        </InfoButton>
      </Content>
      <LoadingSkeleton />
      <>
        {data?.discount?.maxTotal > 0 && data?.discount && (
          <BallonDiscount
            text={`${percent(data?.discount?.maxTotal, data?.totalValue)}%`}
          />
        )}
        <Container>
          <div>
            <Label>Entrada</Label>
            <Value>{format.currency(entryValue)}</Value>
          </div>
        </Container>
        <Container>
          <div className="">
            <p style={{ paddingBottom: '6px' }}>
              Selecione em quantas vezes deseja parcelar
            </p>
            <Label>
              {renderSkeleton('82px') ??
                `${selectedInstallmentData?.installmentQuantity} Parcela${
                  selectedInstallmentData?.installmentQuantity === 1 ? '' : 's'
                }`}
            </Label>
            <Value>
              {renderSkeleton('66px') ??
                format.currency(selectedInstallmentData?.installmentAmount)}
            </Value>
          </div>
          <ButtonsContainer>
            <RoundButton
              disabled={minusButtonDisabled}
              onClick={handleMinusButton}
              aria-label="Diminuir número de parcelas"
            >
              <Icon
                color={minusButtonDisabled ? '#aaa' : '#222'}
                icon="minus"
              />
            </RoundButton>
            <RoundButton
              disabled={plusButtonDisabled}
              onClick={handlePlusButton}
              aria-label="Aumentar número de parcelas"
            >
              <Icon color={plusButtonDisabled ? '#aaa' : '#222'} icon="plus" />
            </RoundButton>
          </ButtonsContainer>
        </Container>
      </>
      <Button
        disabled={loadingPaymentMethod || isLoading || error}
        loading={loadingPaymentMethod || isLoading}
        title={
          toogles.isEnabledPaymentMethodMiAjuda ? 'Selecionar' : buttonText
        }
        type="submit"
        onClick={() => submitHandler()}
      />
    </>
  );
};

export default GenericInstallmentProposal;

import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = action => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const captchaKey = process.env.REACT_APP_CAPTCHA_MIAJUDA_KEY;

  const handleReCaptchaVerify = useCallback(async () => {
    return executeRecaptcha(action);
  }, [action, executeRecaptcha]);

  return { handleReCaptchaVerify, captchaKey };
};

export default useRecaptcha;

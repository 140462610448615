/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Image, Button, Modal } from '@midway/web-ui-component';
import history from '~/services/history';
import Accordion from '~/components/Accordion';
import {
  MaskedInput,
  MASK_TYPES,
} from '~/components/Common/Inputs/MaskedInput';
import loanService from '~/services/LoanApi';
import { FaqPrincipaisDuvidas } from '~/constants/faq';
import { cardsProductsLending, cardsContractLoan } from '~/constants/index';
import appleStoreIcon from '~/assets/img/apple-store.png';
import googlePlayIcon from '~/assets/img/google-play.png';
import IconClosed from '~/assets/img/icons/IconX.png';
import logoSmall from '~/assets/img/Financial/lending/logoSmall.svg';
import iconBoletos from '~/assets/img/Financial/lending/iconBoleto.svg';
import arrow from '~/assets/img/new-home/icon-arrow.svg';
import { validarCPF } from '~/utils/utils';
import {
  tagSimulacaoEmprestimoInteracao,
  tagSimulacaoEmprestimoNoLimit,
} from '~/services/tagging';
import cmsService from '~/services/cms/pages';
import { saveLimitInfo } from '../../store/modules/loan/actions';
import * as S from './styles';

const Lending = () => {
  const appleAppStoreUrl =
    'https://apps.apple.com/br/app/midway-app/id1548732480';
  const googlePlayStoreUrl =
    'https://play.google.com/store/apps/details?id=br.com.midway';

  const dispatch = useDispatch();

  const [valueCPF, setValueCPF] = useState();
  const [loading, setLoading] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(false);

  const [showModalContract, setShowModalContract] = useState(false);
  const [, setTableLendingBanner] = useState([]);

  const [, setIsLoading] = useState(false);
  const [, setIsError] = useState(false);

  localStorage.removeItem('keyDispLimite');
  localStorage.removeItem('keyCpf');
  localStorage.removeItem('keyValidatedCpf');

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = () => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_KEY}`,
      () => {}
    );

    cmsService
      .getLending(process.env.REACT_APP_SITE_CMS_ENV)
      .then(res => {
        const x = JSON.stringify(res, null, '');
        const y = JSON.parse(x);
        const z = JSON.parse(y);
        setTableLendingBanner(z);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(error => {
        setIsLoading(false);
        setIsError(true);

        console.log(error);
      });
  }, []);

  const submitData = (cpf, token) => {
    loanService
      .getLimitCPFCaptcha({ cpf, token })
      .then(res => {
        setLoading(false);
        dispatch(saveLimitInfo(res));

        if (res.isClient) {
          localStorage.setItem('keyDispLimite', res.creditLimit);
          localStorage.setItem('keyCpf', res.cpf);

          if (res.creditLimit > 1) {
            return history.push('/emprestimos/correntista');
          }
          if (res.creditLimit === 0) {
            return setShowModalContract(true);
          }
        }
        localStorage.setItem('keyValidatedCpf', true);
        return history.push('/emprestimos/nao-correntista');
      })
      .catch(_ => {
        setErrorCaptcha(true);
      });
  };

  const sendCPF = () => {
    setLoading(true);
    tagSimulacaoEmprestimoInteracao(loading);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: 'submit' })
        .then(token => {
          submitData(parseInt(valueCPF, 10), token);
        });
    });
  };

  const BenefitsLoan = ({ item }) => {
    return (
      <div className="benefits-loan">
        <div>
          <Image
            src={item?.icon}
            className="icon"
            alt={`ícone de ${item?.title}`}
          />
          <Image src={arrow} className="arrow" alt="seta para baixo" />
          <h2>{item?.title}</h2>
          <p>{item.text}</p>
        </div>
      </div>
    );
  };

  const ContractsLoan = ({ item }) => {
    return (
      <div className="contracts-loan">
        <div>
          <Image
            src={item?.icon}
            className="imagemContrato"
            alt={`ícone de ${item?.title}`}
          />
          <div className="contents-info">
            <h2>{item?.title}</h2>
            <p>{item.text}</p>
            {item.paragraph ? (
              <p>
                {item.paragraph}
                {item?.id === 2 ? (
                  <>
                    <a
                      href="https://www.midway.com.br/buscar-lojas/"
                      target="_blank"
                    >
                      clicando aqui
                    </a>
                    <span>.</span>
                  </>
                ) : null}
              </p>
            ) : null}
            {item.buttonApple ? (
              <div className="contents-button">
                <a
                  href={appleAppStoreUrl}
                  onClick={() => {
                    tagSimulacaoEmprestimoNoLimit('app-store', loading);
                  }}
                >
                  <img src={item?.buttonApple} alt="applestoreicon" />
                </a>
                <a
                  href={googlePlayStoreUrl}
                  onClick={() => {
                    tagSimulacaoEmprestimoNoLimit('google-play', loading);
                  }}
                >
                  <img src={item?.buttonGoogle} alt="googlePlayIcon" />
                </a>
              </div>
            ) : null}
            {item.subtitleOne ? (
              <>
                <div className="contacts-number">
                  <div>
                    <p>{item.subtitleOne}</p>
                    <h3>{item.telefoneOne}</h3>
                  </div>
                  <div>
                    <p>{item.subtitleTwo}</p>
                    <h3>{item.telefoneTwo}</h3>
                  </div>
                </div>
                <p className="text-atendimento">
                  Atendimento 24h por dia, todos os dias da semana
                </p>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <S.Container className="container-fluid" id="ready-page-for-screen-reader">
      <S.BannerContainer className="container-fluid">
        <S.BannerTitle>
          <h2 className="is-primary-default">Empréstimo Pessoal</h2>
          <h3 className="is-primary-default">
            O seu bem-estar financeiro é da nossa conta. <br />
            Dinheiro rápido e descomplicado.
          </h3>
          <MaskedInput
            maskType={MASK_TYPES.CPF}
            placeholder="Informe o seu CPF"
            maxLength="14"
            pressOnKeyDown={validarCPF(valueCPF) && sendCPF}
            value={valueCPF}
            setValue={setValueCPF}
          />

          <div className="text-error">
            {valueCPF &&
              !validarCPF(valueCPF) &&
              valueCPF.length >= 2 &&
              'CPF inválido'}
          </div>
          <Button
            aria-label="Simular empréstimo"
            state="primary"
            title="Simular empréstimo"
            onClick={sendCPF}
            disabled={!validarCPF(valueCPF)}
          />
          {errorCaptcha && (
            <div className="captcha-invalid">Recaptcha inválido</div>
          )}
        </S.BannerTitle>
      </S.BannerContainer>
      <S.MidwayLogo>
        <Image src={logoSmall} />
      </S.MidwayLogo>
      <S.Content className="container">
        <S.Session1>
          <h1>Benefícios do Empréstimo Midway</h1>
          <p>Conheça os principais benefícios:</p>
          <div className="benefits-list">
            {cardsProductsLending.map(item => (
              <BenefitsLoan item={item} />
            ))}
          </div>
        </S.Session1>

        <S.Session2>
          <h1>Contrate do jeito que você quiser</h1>
          <p>
            Precisa de dinheiro? A gente te ajuda. Empréstimo on-line a poucos{' '}
            <br /> cliques de você. Escolha uma das três formas de contratação.
          </p>
          <div className="contracts-list">
            {cardsContractLoan.map(item => (
              <ContractsLoan item={item} />
            ))}
          </div>
        </S.Session2>
      </S.Content>
      <S.BoletoContainer className="space-layout">
        <div className="container-info">
          <div className="boleto-info">
            <h1>Saiba como pagar as parcelas do seu empréstimo</h1>
            <h3>Lojas Riachuelo</h3>
            <p>
              Pague a sua parcela em qualquer loja física da Riachuelo. Encontre
              a loja mais próxima de você{' '}
              <a href="https://www.midway.com.br/buscar-lojas/" target="_blank">
                clicando aqui
              </a>
              .
            </p>
            <h3>Débito Automático</h3>
            <p>
              Contratando pelo <span>app Midway</span>, o valor da parcela é
              debitado automaticamente da sua conta na data de vencimento.
            </p>
          </div>
          <div className="gerar-boleto">
            <img alt="icone boletos" src={iconBoletos} />
            <h3>Boleto</h3>
            <p>
              O pagamento do boleto pode ser feito via internet banking, em
              bancos, lotéricas ou correspondentes bancários, como farmácias e
              supermercados.
            </p>
            <a
              aria-label="Gerar boleto"
              href="https://contaonline.midwayfinanceira.com.br/contaonline/home"
              target="_blank"
            >
              Gerar boleto
            </a>
          </div>
        </div>
      </S.BoletoContainer>

      <S.FaqContainer>
        <div className="container">
          <h1>Perguntas Frequentes</h1>
          {FaqPrincipaisDuvidas.map(item => (
            <Accordion
              key={item.id}
              title={item.title}
              content={item.content}
            />
          ))}
          <h1>Ainda tem dúvidas?</h1>
          <h3>
            Confira mais perguntas frequentes{' '}
            <Link to="/emprestimos/faq">clicando aqui</Link>.
          </h3>
        </div>
      </S.FaqContainer>

      {showModalContract && (
        <Modal
          state="white-lighter"
          openModal={setShowModalContract}
          id="ready-modal-for-screen-reader"
          onHide={() => setShowModalContract(false)}
        >
          <S.ModalContent>
            <div
              className="ahl-icon-closed"
              onClick={() => setShowModalContract(false)}
            >
              <img src={IconClosed} alt="" />
            </div>

            <div className="ahl-title">
              Infelizmente nesse momento você não tem limite pré-aprovado para
              contratação de empréstimo.
            </div>
            <div className="ahl-subtitle">
              Mas fique de olho pois atualizamos periodicamente essas
              informações.
            </div>

            <div className="ahl-middle">
              <div className="ahl-contract-app-top">
                <div className="ahl-middle-body-text-bold">Baixe nosso app</div>

                <div className="ahl-middle-body-images">
                  <a
                    href={appleAppStoreUrl}
                    onClick={() => {
                      tagSimulacaoEmprestimoNoLimit('app-store', loading);
                    }}
                  >
                    <img src={appleStoreIcon} alt="applestoreicon" />
                  </a>
                  <a
                    href={googlePlayStoreUrl}
                    onClick={() =>
                      tagSimulacaoEmprestimoNoLimit('google-play', loading)
                    }
                  >
                    <img src={googlePlayIcon} alt="googlePlayIcon" />
                  </a>
                </div>
              </div>

              <div className="ahl-middle-body">
                <div className="ahl-middle-body-text-bold">
                  Entre em contato pelo telefone
                </div>

                <div className="ahl-middle-body-text-bold">0800 727 4417</div>

                <div className="ahl-middle-body-text-small-deficient">
                  Deficientes Auditivos
                </div>
                <div className="ahl-middle-body-text-bold">0800 722 0626</div>

                <div className="ahl-middle-body-text-small">
                  Horário de atendimento 24h por dia, todos os dias da semana,
                  incluindo feriados nacionais.
                </div>
              </div>

              <div className="ahl-middle-body">
                <div className="ahl-middle-body-text-bold">
                  Vá a uma loja física
                </div>

                <div className="ahl-middle-body-text-small">
                  Visite alguma loja física Riachuelo e solicite a contratação
                  de um empréstimo
                </div>

                <div className="ahl-contract-app-bottom">
                  <div className="ahl-middle-body-text-bold">
                    Baixe o nosso app
                  </div>

                  <div className="ahl-middle-body-images">
                    <a
                      href={appleAppStoreUrl}
                      onClick={() => {
                        tagSimulacaoEmprestimoNoLimit('app-store', loading);
                      }}
                    >
                      <img src={appleStoreIcon} alt="applestoreicon" />
                    </a>
                    <a
                      href={googlePlayStoreUrl}
                      onClick={() =>
                        tagSimulacaoEmprestimoNoLimit('google-play', loading)
                      }
                    >
                      <img src={googlePlayIcon} alt="googlePlayIcon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </S.ModalContent>
        </Modal>
      )}
    </S.Container>
  );
};

export default Lending;

import React, { useEffect } from 'react';
import moment from 'moment';
import { kebabCase } from 'lodash';
import { tagMiAjudaView } from '~/services/tagging';
import { useNegotiation } from '~/context/negotiationV2';

import format from '~/services/format';
import {
  Content,
  Title,
  DigitableLine,
  ContainerButton,
  Header,
  Container,
  Amount,
  ContainerQRCode,
} from './styles';
import Important from '../Important';
import ButtonCopy from '../../../ButtonCopy';
import QRCode from '../../../QRCode';

function Billet() {
  const {
    system,
    negotiationData,
    typeNegotiationRegistered,
    paymentMethodSelected,
  } = useNegotiation();
  const { registered, eligibility } = negotiationData;

  useEffect(() => {
    tagMiAjudaView(
      kebabCase(
        `${system} ${typeNegotiationRegistered} pagar ${paymentMethodSelected}`
      )
    );
  }, [paymentMethodSelected, system, typeNegotiationRegistered]);

  const negotiable = eligibility[typeNegotiationRegistered];
  const installment = negotiable?.simulation?.selectedInstallmentData;

  const isDebit =
    typeNegotiationRegistered === 'debit' ||
    typeNegotiationRegistered === 'acquittance';

  return (
    registered && (
      <Container>
        <Header>
          <Title>
            {typeNegotiationRegistered !== 'invoicement'
              ? 'Acordo'
              : 'Parcelamento'}{' '}
            gerado com sucesso!!!
          </Title>
        </Header>
        <DigitableLine>
          <span>Valor negociado</span>
          <Amount>{format.currency(registered?.amount)}</Amount>
          <span>
            Pagar até <b>{moment(registered?.dueDate).format('DD/MM')}</b>
          </span>
          <hr />
          <span>
            O acordo só é efetivado após o pagamento{' '}
            {isDebit ? 'do valor negociado' : 'da entrada'}
          </span>
          <ContainerQRCode>
            <QRCode value={registered?.pixCopyAndPaste} />
          </ContainerQRCode>
        </DigitableLine>
        <Content>
          <ContainerButton>
            <ButtonCopy
              type="pix"
              title="Copiar código Pix"
              toCopy={registered?.pixCopyAndPaste}
            />
          </ContainerButton>
          <Important
            pixText={`${installment?.installmentQuantity}X de ${format.currency(
              installment?.installmentAmount
            )}`}
          />
        </Content>
      </Container>
    )
  );
}

export default Billet;

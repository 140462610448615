import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  gap: 8px;
  list-style: none;
  align-items: center;
  align-self: center;

  @media (max-width: 778px) {
    gap: 4px;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: black;

  p {
    margin-left: 6px;
    font-size: 12px;
  }

  span {
    display: flex;
    width: 20px;
    height: 20px;
    border: 1px solid #606060;
    border-radius: 50px;
    color: #909090;
    justify-content: center;
    font-size: 12px;
    color: ${props => (props.active || props.complete) && 'white'};
    background-color: ${props =>
      (props.active && 'black') || (props.complete && '#00726D')};
    border-color: ${props =>
      (props.active && 'black') || (props.complete && '#00726D')};
    img {
      width: 10px;
      height: 10px;
      align-self: center;
    }
    @media (max-width: 778px) {
      margin: 0;
    }
  }

  @media (max-width: 778px) {
    p {
      display: none;
    }
  }
`;

export const Line = styled.li`
  min-width: 40px;
  height: 1px;
  background-color: #606060;
  position: relative;
  background-color: ${props => props.complete && '#00726D'};

  @media (max-width: 778px) {
    min-width: 24px;
  }
`;

import { BackButtonContainer } from './styles';
import { IconChevronLeft } from '@tabler/icons-react';

const BackButton = ({ onClick }) => {
  return (
    <BackButtonContainer onClick={onClick}>
      <IconChevronLeft />
    </BackButtonContainer>
  );
};

export default BackButton;

import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
  .cartao-pl {
    min-height: 400px;
  }
`;
export const MidwayContent = styled.div`
  h1 {
    color: #252525 !important;
    font-size: 38px !important;
    font-weight: bold !important;
    margin-top: 35px;
    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    margin: 20px 0 50px;
  }
  h2 {
    font-weight: bold;
    font-size: 25px !important;
    color: #252525;
  }
  ul {
    display: block;
    margin-bottom: 80px;
    > li > u {
      display: block;
      margin-top: 20px;
    }

    > li > a {
      margin-top: 20px;
      font-size: 16px;
      display: block;
    }
  }
`;

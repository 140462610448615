import IconSVGBase from '../IconSVG';
import InnerHtmlBase from '../InnerHtml';
import SubjectItemBase from '../SubjectItem';
import { colors } from '~/constants';
import styled from 'styled-components';

export const FeedbackContainer = styled.div`
  padding-bottom: 1rem;
  margin-top: 0.5rem;
`;

export const WasThisHelpfulContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

export const WasThisHelpfulTitle = styled.strong`
  font-weight: 500;
  font-size: 1rem;
`;

export const WasThisHelpfulSubtitle = styled.strong`
  font-weight: 400;
`;

export const WasThisHelpfulMessageContainer = styled.div`
  margin-top: 1rem;
`;

export const InnerHtml = styled(InnerHtmlBase)`
  gap: 0.25rem;
  font-weight: 400;
`;

export const WasThisHelpfulOptions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const WasThisHelpfulOption = styled.button`
  font-size: 14px;
  border-radius: 100px;
  background: ${props => (props.selected ? colors.chumbo : colors.white)};
  color: ${props => (props.selected ? colors.white : colors.chumbo)};
  border: 0.125rem solid ${colors.chumbo};
  padding: 0.375rem 1rem;
`;

export const ContactsContainer = styled.div`
  margin-top: 0.5rem;
`;

export const SubjectItem = styled(SubjectItemBase)`
  strong {
    color: ${colors.orangeRoof};
  }
`;

export const IconSVG = styled(IconSVGBase)`
  width: 1rem;
  height: 1rem;
`;

export const WhatsAppButtonContainer = styled.div`
  padding: 1.5rem 0;
  border-top: 1px solid #0000001a;
  margin-top: 1.5rem;
`;

export const WhatsappButton = styled.button`
  width: max-content;
  border-radius: 100px;
  border: 2px solid #36c637;
  padding: 0.25rem 1rem;
  background: none;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #36c637;
  }

  span {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '~/context/auth';
import { trackingViewContent, trackingSelectContent } from '~/analytics';
import { flow, contentPage, contentType } from '~/analytics/cards';
import useSendInvoiceDataToBI from '~/hooks/useSendInvoiceDataToBI';

import LoanBankslipItem from '../LoanBankslipItem';
import InvoiceBankSlipItem from '../InvoiceBankSlipItem';
import InvoiceBankSlipDetails from '../InvoiceBankSlipDetails';
import * as Styles from './styles';

const EMPTY_DETAILS = {
  visible: false,
  payline: null,
  dueDate: null,
  value: null,
  maskedPan: null,
  flag: null,
};

const BankSlipList = ({
  items = [],
  onError,
  billetType = 'invoice',
  onConfirmSuggestedInstallment,
  onSimulateOtherOptions,
  onAccessActiveInstallment,
  documentNumber,
}) => {
  const [selected, setSelected] = useState(null);
  const [details, setDetails] = useState(EMPTY_DETAILS);
  const { setMaskedPan, setCardFlag, setNextDueDate } = useAuth();
  const sendInvoiceDataToBI = useSendInvoiceDataToBI();

  const isInvoiceAccess = billetType === 'invoice';

  const firesSelectContent = analyticsContentType =>
    trackingSelectContent(
      analyticsContentType,
      flow.payments,
      contentPage.bankSlipList
    );

  const handleGoToInvoiceDetails = id => {
    const foundInvoice = items.find(item => item.id === id);
    firesSelectContent(contentType.selectInvoice(foundInvoice.flag));
    setMaskedPan(foundInvoice.maskedPan);
    setCardFlag(foundInvoice.flag);
    setNextDueDate(foundInvoice.nextDueDate);
    const params = {
      maskedPan: foundInvoice.maskedPan,
      flag: foundInvoice.flag,
    };
    setDetails({
      ...params,
      visible: true,
      payline: foundInvoice.payline,
      dueDate: foundInvoice.dueDate,
      value: foundInvoice.value,
    });
    sendInvoiceDataToBI({
      ...params,
      documentNumber,
      valueInvoice: foundInvoice.valueInvoice,
      product: foundInvoice.product,
      valuePay: foundInvoice.value,
    });
  };

  const renderBankSlipItem = ({ id, ...rest }) => {
    const Item = isInvoiceAccess ? InvoiceBankSlipItem : LoanBankslipItem;
    const invoiceBankSlipProps = {
      onClick: handleGoToInvoiceDetails,
    };
    const loanBankSlipProps = {
      selected,
      onSelect: setSelected,
    };
    const props = isInvoiceAccess ? invoiceBankSlipProps : loanBankSlipProps;
    const onErrorWrapper = () => {
      firesSelectContent(contentType.retry);
      onError();
    };

    return (
      <Item key={id} id={id} onError={onErrorWrapper} {...props} {...rest} />
    );
  };

  const amountListItems = useMemo(() => {
    let amount = 0;
    if (items.length > 0) {
      amount = items.length.toLocaleString('pt-BR', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    }
    return amount;
  }, [items.length]);

  const spolightText = useMemo(() => {
    const billetText = 'boleto';
    const invoiceText = 'fatura';
    const isThereMultiItems = items && items.length > 0;
    const spolight = isInvoiceAccess ? invoiceText : billetText;
    return isThereMultiItems ? `${spolight}s` : spolight;
  }, [items, isInvoiceAccess]);

  const description = useMemo(() => {
    let text = 'Selecione de qual boleto deseja copiar o código de barras';
    if (isInvoiceAccess) {
      text = 'Selecione qual fatura deseja pagar';
    }
    return text;
  }, [isInvoiceAccess]);

  useEffect(() => {
    if (!details.visible) {
      trackingViewContent(contentPage.bankSlipList, flow.payments);
    }
  }, [details]);

  if (details.visible) {
    return (
      <InvoiceBankSlipDetails
        details={details}
        onGoBackDetails={() => setDetails(EMPTY_DETAILS)}
        onConfirmSuggestedInstallment={onConfirmSuggestedInstallment}
        onSimulateOtherOptions={onSimulateOtherOptions}
        onAccessActiveInstallment={onAccessActiveInstallment}
      />
    );
  }

  return (
    <Styles.Container>
      <Styles.ContainerTexts>
        <Styles.Title>
          Encontramos
          <Styles.Spotlight>
            {` ${amountListItems} ${spolightText} `}
          </Styles.Spotlight>
          para pagamento, veja os detalhes abaixo:
        </Styles.Title>
        <Styles.Text bold>{description}</Styles.Text>
      </Styles.ContainerTexts>

      {items && items.map(renderBankSlipItem)}
      <Styles.Text>
        Para realizar o pagamento, este número deverá ser copiado e digitado nos
        caixas de auto-atendimento, aplicativos ou internet banking do seu
        banco.
      </Styles.Text>
    </Styles.Container>
  );
};

BankSlipList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      payline: PropTypes.string,
      dueDate: PropTypes.string,
      financedAmount: PropTypes.string,
      valueBankSlip: PropTypes.string,
      value: PropTypes.string,
      fourDigitsOrContract: PropTypes.string,
      type: PropTypes.oneOf(['invoice', 'loan']),
      flag: PropTypes.oneOf(['MASTER', 'VISA', 'PL']),
    })
  ),
  billetType: PropTypes.oneOf(['invoice', 'loan']),
  documentNumber: PropTypes.string,
};

export default BankSlipList;

import React, { useCallback } from 'react';

export const MASK_TYPES = {
  CPF: 'CPF',
  PHONE_NUMBER: 'PHONE_NUMBER',
  BR_CURRENCY: 'BR_CURRENCY',
  BIRTHDATE: 'BIRTHDATE',
};

export const MaskedInput = React.forwardRef((props, ref) => {
  const {
    placeholder,
    maxLength,
    pressOnKeyDown,
    value,
    setValue,
    className,
    name,
    maskType,
    ...rest
  } = props;

  const mask = useCallback(
    prevValue => {
      if (prevValue === '' || prevValue === undefined || prevValue === null)
        return '';

      switch (maskType) {
        case MASK_TYPES.CPF: {
          let cpf = prevValue.replace(/\D/g, '');
          cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
          cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
          cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
          return cpf;
        }
        case MASK_TYPES.BR_CURRENCY: {
          let currency = prevValue.replace(/\D/g, '');
          currency = currency.replace(/(\d+)(\d{2})$/, '$1,$2');

          currency = currency.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          return currency;
        }
        case MASK_TYPES.PHONE_NUMBER: {
          let phone = prevValue.replace(/\D/g, '');
          phone = phone.replace(/(\d{2})/, '$1');
          phone = phone.replace(/^(\d{2})(\d)/g, '($1)$2');
          phone = phone.replace(/(\d)(\d{4})$/, '$1-$2');
          return phone;
        }

        case MASK_TYPES.BIRTHDATE: {
          let date = prevValue.replace(/\D/g, '');
          date = date.replace(/(\d{2})(\d)/, '$1/$2');
          date = date.replace(/(\d{2})(\d)/, '$1/$2');
          return date;
        }

        default:
          return prevValue;
      }
    },
    [maskType]
  );

  const unmask = useCallback(
    prevValue => {
      if (prevValue === '' || prevValue === undefined || prevValue === null)
        return '';

      switch (maskType) {
        case MASK_TYPES.CPF:
          return prevValue.replace(/(\.|\/|-)/g, '');

        case MASK_TYPES.BR_CURRENCY:
          return prevValue.replace(/[^\d]/g, '');

        case MASK_TYPES.PHONE_NUMBER:
          return prevValue.replace(/(\(|\/|-|\))/g, '');

        case MASK_TYPES.BIRTHDATE:
          return prevValue;

        default:
          return prevValue;
      }
    },
    [maskType]
  );

  return (
    <input
      type="text"
      ref={ref}
      name={name && name}
      value={mask(value)}
      placeholder={placeholder}
      onChange={e => setValue(unmask(e.target.value))}
      maxLength={maxLength}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          pressOnKeyDown();
        }
      }}
      className={className}
      {...rest}
    />
  );
});

export const banner = [
  {
    name: 'BannerContaPaga',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-conta-paga-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-seguro-conta-paga.png',
    title:
      'Desfrute suas compras com total segurança. conte Com o Seguro Conta Paga',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

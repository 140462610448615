export const CONTRACTS_PATH = {
  POLITICA_DE_PRIVACIDADE_APLICATIVO_CARTAO_RIACHUELO:
    '/contrato/Politica_de_privacidade_aplicativo_cartao_riachuelo',
  MIDWAY_CONTRATO_PL: '/contrato/Midway_Contrato_PL.pdf',
  POLITICA_DE_SEGURANCA_DA_INFORMACAO:
    '/contrato/POLITICA_DE_SEGURANCA_DA_INFORMACAO',
  RESPONSABILIDADE_SOCIOAMBIENTAL_MIDWAY:
    '/contrato/Responsabilidade_Socioambiental_Midway',
  REGULAMENTO_CAPITALIZACAO_ASSISTENCIA_24HS_AUTOMOVEL_PREMIAVEL_MEGA:
    '/contrato/Regulamento-Capitalizacao-Assistencia-24hs-Automovel-Premiavel-Mega',
  REGULAMENTO_CAPITALIZACAO_ASSISTENCIA_24HS_AUTOMOVEL_PREMIAVEL_SUPER:
    '/contrato/Regulamento-Capitalizacao-Assistencia-24hs-Automovel-Premiavel-Super',
  CG_ASSISTENCIA_AUTO_24HS_MEGA: '/contrato/CG-ASSISTENCIA-AUTO-24HS-MEGA',
  CG_ASSISTENCIA_AUTO_24HS_SUPER: '/contrato/CG-ASSISTENCIA-AUTO-24HS-SUPER',
  REGULAMENTO_CAPITALIZACAO_ASSISTENCIA_24HS_MOTO_PREMIAVEL_MEGA:
    '/contrato/Regulamento-Capitalizacao-Assistencia-24hs-Moto-Premiavel-Mega',
  REGULAMENTO_CAPITALIZACAO_ASSISTENCIA_24HS_MOTO_PREMIAVEL_SUPER:
    '/contrato/Regulamento-Capitalizacao-Assistencia-24hs-Moto-Premiavel-Super',
  CG_ASSISTÊNCIA_24HS_MOTO_SUPER: '/contrato/CG-ASSISTÊNCIA-24HS-MOTO-SUPER',
  CG_ASSISTÊNCIA_24HS_MOTO_MEGA: '/contrato/CG-ASSISTÊNCIA-24HS-MOTO-MEGA',
  // Odontologico
  ODONTOLOGICO_TOPAZIO:
    'https://www.midway.com.br/static/documents/tags/contratos/condicoes-gerais-assistencia-odontologica_plano-topazio.pdf',
  ODONTOLOGICO_DIAMOND:
    'https://midway.com.br/static/documents/tags/contratos/condicoes-gerais-assistencia-odontologica_plano-diamond.pdf',
  REGULAMENTO_CAPITALIZACAO_ASSISTENCIA_24HS_ODONTO:
    '/contrato/Regulamento-Capitalizacao-Assistencia-24hs-Odonto',
  PLANO_ODONTO_MANUAL_A5_FINAL_V2: '/contrato/Plano_Odonto_Manual_A5_FINAL_V2',
  ODONTOLOGICO_TOPAZIO_E_DIAMOND_PLUS:
    '/contrato/Odontologico_Topazio_e_Diamond-plus',
  ODONTOLOGICO_GOLD_PLUS_DIAMOND: '/contrato/Odontologico_Gold-plus_Diamond',
  ODONTOLOGICO_SILVER_E_GOLD: '/contrato/Odontologico_Silver_e_Gold',
  REGULAMENTO_CAPITALIZACAO_ASSISTENCIA_24HS_RESIDENCIA_PREMIAVEL_MEGA:
    '/contrato/Regulamento-Capitalizacao-Assistencia-24hs-Residencia-Premiavel-Mega',
  REGULAMENTO_CAPITALIZACAO_ASSISTENCIA_24HS_RESIDENCIA_PREMIAVEL_SUPER:
    '/contrato/Regulamento-Capitalizacao-Assistencia-24hs-Residencia-Premiavel-Super',
  CG_ASSISTÊNCIA_24HS_RESIDÊNCIA_SUPER:
    '/contrato/CG_ASSISTÊNCIA-24HS-RESIDÊNCIA-SUPER',
  CG_ASSISTÊNCIA_24HS_RESIDÊNCIA_MEGA:
    '/contrato/CG_ASSISTÊNCIA-24HS-RESIDÊNCIA-MEGA',
  MIDWAY_CONTRATO_BANDEIRA: '/contrato/Midway_Contrato_Bandeira.pdf',
  DEBITO_AUTOMATICO_TERMO_DE_ADESAO:
    '/contrato/debito-automatico-termo-de-adesao',
  CONDICOES_GERAIS_SEGURO_DESEMPREGO_RSA_SURA:
    '/contrato/1-condicoes-gerais-seguro-desemprego-rsa-sura',
  RESUMO_CG_DESEMPREGO_PREMIAVEL_COMERCIALIZADO_ATE_DEZ_2014:
    '/contrato/2-resumo-cg-desemprego-premiavel-comercializado-ate-dez-2014',
  RESUMO_CG_SEGURO_DESEMPREGO_SURA_ATE_05_11_2017:
    '/contrato/3-resumo-cg-seguro-desemprego-sura-ate-05-11-2017',
  RESUMO_CG_DESEMPREGO_PREMIAVEL_ATE_2019:
    '/contrato/4-resumo-cg-desemprego-premiavel-ate-2019',
  CONDICOES_GERAIS_INTEGRA_DESEMPREGO_ACE_BANDEIRA:
    '/contrato/5-condicoes-gerais-integra-desemprego-ace-bandeira',
  CONDICOES_GERAIS_NA_INTEGRA_SEGURO_DESEMPREGO_ZURICH_CARTOES_VISA_E_MASTERCARD:
    '/contrato/6-condicoes-gerais-na-integra-seguro-desemprego-zurich-cartoes-visa-e-mastercard',
  RESUMO_CONDICOES_GERAIS_SEGURO_DESEMPREGO_BANDEIRA_2015:
    '/contrato/7-resumo-condicoes-gerais-seguro-desemprego-bandeira-2015',
  RESUMO_CONDICOES_GERAIS_SEGURO_DESEMPREGO_BANDEIRA_ATE_2017:
    '/contrato/8-resumo-condicoes-gerais-seguro-desemprego-bandeira-ate-2017',
  RESUMO_DO_SEGURO_DESEMPREGO_PREMIAVEL_VISA_E_MASTER_ATE_2019:
    '/contrato/9-resumo-do-seguro-desemprego-premiavel-visa-e-master-ate-2019',
  CONDICOES_GERAIS_NA_INTEGRA_PERDA_ROUBO_OU_FURTO_DE_CARTAO_ZURICH:
    '/contrato/10-condicoes-gerais-na-integra-perda-roubo-ou-furto-de-cartao-zurich',
  RESUMO_CG_PROTECAO_PERDA_E_ROUBO_ATE_2013:
    '/contrato/11-resumo-cg-protecao-perda-e-roubo-ate-2013',
  RESUMO_CG_PROTECAO_PERDA_E_ROUBO_ATE_2017:
    '/contrato/resumo-cg-protecao-perda-e-roubo-ate-2017',
  _12_15414_900031_2019_96: '/contrato/12-15414.900031.2019.96',
  RESUMO_DO_SEGURO_CONTA_PAGA_PLANO_1:
    '/contrato/13-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-1',
  RESUMO_DO_SEGURO_CONTA_PAGA_PLANO_2:
    '/contrato/14-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-2',
  RESUMO_DO_SEGURO_CONTA_PAGA_PLANO_3:
    '/contrato/15-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-3',
  CONDICOES_GERAIS_PROTECAO_DE_PORTATEIS_COLETIVO:
    '/contrato/CONDICOES-GERAIS-PROTECAO-DE-PORTATEIS–COLETIVO',
  RESUMO_CONDICOES_GERAIS_BOLSAPROTEGIDA_ASSISTENCIAVITIMASDECRIMES_ATE_11_2017:
    '/contrato/Resumo-Condicoes-Gerais-BolsaProtegida-AssistenciaVitimasDeCrimes-ate-11-2017',
  RESUMO_CONDICOES_GERAIS_BOLSAPROTEGIDA_ASSISTENCIAVITIMASDECRIMES_ATUAL:
    '/contrato/Resumo-Condicoes-Gerais-BolsaProtegida-AssistenciaVitimasDeCrimes-atual',
  RESUMO_CONDICOES_GERAIS_BOLSA_PROTEGIDA:
    '/contrato/Resumo-Condicoes-Gerais-Bolsa_Protegida',
  RESUMO_CONDICOES_GERAIS_PERDA_E_ROUBO:
    '/contrato/Resumo-Condicoes-Gerais-Perda-e-Roubo',
  CONDICOES_GERAIS_NA_INTEGRA_BOLSA_PROTEGIDA:
    '/contrato/Condicoes-Gerais-na-Integra-Bolsa-Protegida',
  CONDICOES_GERAIS_NA_INTEGRA_PERDA_E_ROUBO:
    '/contrato/Condicoes-Gerais-na-Integra-Perda-e-Roubo',
  CONDICOES_GERAIS_RESIDENCIAL_INTEGRA:
    '/contrato/Condicoes-gerais-residencial-integra',
  RESUMO_SEGURO_RESIDENCIAL_ATE_2017:
    '/contrato/Resumo-seguro-residencial-ate-2017',
  RESUMO_SEGURO_RESIDENCIAL_ATUAL_2020:
    '/contrato/Resumo-seguro-residencial-atual-2020',
  RESUMO_CONDICOESGERAIS_ASSISTENCIA_RESIDENCIAL:
    '/contrato/Resumo-CondicoesGerais-Assistencia-Residencial',
  RESUMO_CONDICOES_ESPECIFICAS_DO_SEGURO_DE_PESSOAS_COLETIVOS_ASS_24HS_RESIDENCIA3:
    '/contrato/RESUMO-CONDICOES-ESPECIFICAS-DO-SEGURO-DE-PESSOAS-COLETIVOS-ASS.24HS-RESIDENCIA3',
  AUTO_RESUMO_CONDICOES_GERAIS_ASSISTENCIA_VEICULO:
    '/contrato/Auto_Resumo-Condicoes-Gerais-Assistencia-Veiculo',
  AUTO_A_RESUMO_CONDICOES_24HS_VEICULOS1:
    '/contrato/Auto_A_RESUMO-CONDICOES-24HS-VEICULOS1',
  PROPOSTA_DE_CONTRATO_EMPRESTIMO_PESSOAL_E_SAQUE_FACIL:
    '/contrato/Proposta-de-contrato-emprestimo-pessoal-e-saque-facil',
  BALANCETE_082022: '/contrato/BALANCETE_082022',
  BALANCETE_072022: '/contrato/BALANCETE_072022',
  BALANCETE_062022: '/contrato/BALANCETE_062022',
  BALANCETE_052022: '/contrato/BALANCETE_052022',
  BALANCETE_042022: '/contrato/BALANCETE_042022',
  BALANCETE_032022: '/contrato/BALANCETE_032022',
  BALANCETE_022022: '/contrato/BALANCETE_022022',
  BALANCETE_012022: '/contrato/BALANCETE_012022',
  BALANCETE_122021: '/contrato/BALANCETE_122021',
  BALANCETE_112021: '/contrato/BALANCETE_112021',
  BALANCETE_102021: '/contrato/BALANCETE_102021',
  BALANCETE_092021: '/contrato/BALANCETE_092021',
  BALANCETE_082021: '/contrato/BALANCETE_082021',
  BALANCETE_072021: '/contrato/BALANCETE_072021',
  BALANCETE_062021: '/contrato/BALANCETE_062021',
  BALANCETE_052021: '/contrato/BALANCETE_052021',
  BALANCETE_042021: '/contrato/BALANCETE_042021',
  BALANCETE_032021: '/contrato/BALANCETE_032021',
  BALANCETE_022021: '/contrato/BALANCETE_022021',
  BALANCETE_012021: '/contrato/BALANCETE_012021',
  BALANCETE_122020: '/contrato/BALANCETE_122020',
  BALANCETE_112020: '/contrato/BALANCETE_112020',
  BALANCETE_102020: '/contrato/BALANCETE_102020',
  BALANCETE_092020: '/contrato/BALANCETE_092020',
  BALANCETE_082020: '/contrato/BALANCETE_082020',
  BALANCETE_072020: '/contrato/BALANCETE_072020',
  BALANCETE_062020: '/contrato/BALANCETE_062020',
  BALANCETE_052020: '/contrato/BALANCETE_052020',
  BALANCETE_042020: '/contrato/BALANCETE_042020',
  BALANCETE_032020: '/contrato/BALANCETE_032020',
  BALANCETE_022020: '/contrato/BALANCETE_022020',
  BALANCETE_012020: '/contrato/BALANCETE_012020',
  BALANCETE_122019: '/contrato/BALANCETE_122019',
  BALANCETE_112019: '/contrato/BALANCETE_112019',
  BALANCETE_102019: '/contrato/BALANCETE_102019',
  BALANCETE_092019: '/contrato/BALANCETE_092019',
  BALANCETE_082019: '/contrato/BALANCETE_082019',
  BALANCETE_072019: '/contrato/BALANCETE_072019',
  BALANCETE_062019: '/contrato/BALANCETE_062019',
  BALANCETE_052019: '/contrato/BALANCETE_052019',
  BALANCETE_042019: '/contrato/BALANCETE_042019',
  BALANCETE_032019: '/contrato/BALANCETE_032019',
  BALANCETE_022019: '/contrato/BALANCETE_022019',
  BALANCETE_012019: '/contrato/BALANCETE_012019',
  PUBLICACAO_MIDWAY_JAN_2022: '/contrato/PUBLICACAO_MIDWAY_JAN_2022',
  PUBLICACAO_MIDWAY_DEZ_2021: '/contrato/PUBLICACAO_MIDWAY_DEZ_2021',
  PUBLICAÇÃO_MIDWAY_JUN_2021: '/contrato/PUBLICAÇÃO_MIDWAY_JUN_2021',
  PUBLICACAO_MIDWAY_DEZ_2020: '/contrato/PUBLICACAO_MIDWAY_DEZ_2020',
  PUBLICACAO_MIDWAY_JUN_2020: '/contrato/PUBLICACAO_MIDWAY_JUN_2020',
  PUBLICACAO_MIDWAY_DEZ_2019: '/contrato/PUBLICACAO_MIDWAY_DEZ_2019',
  PUBLICACAO_MIDWAY_JUN_2019: '/contrato/PUBLICACAO_MIDWAY_JUN_2019',
  PUBLICACAO_MIDWAY_DEZ_2018: '/contrato/PUBLICACAO_MIDWAY_DEZ_2018',
  PUBLICACAO_MIDWAY_JUN_2018: '/contrato/PUBLICACAO_MIDWAY_JUN_2018',
  PUBLICACAO_MIDWAY_DEZ_2017: '/contrato/PUBLICACAO_MIDWAY_DEZ_2017',
  PUBLICACAO_MIDWAY_JUN_2017: '/contrato/PUBLICACAO_MIDWAY_JUN_2017',
  PUBLICACAO_MIDWAY_DEZ_2016: '/contrato/PUBLICACAO_MIDWAY_DEZ_2016',
  PUBLICACAO_MIDWAY_JUN_2016: '/contrato/PUBLICACAO_MIDWAY_JUN_2016',
  PUBLICACAO_MIDWAY_JUN_2015: '/contrato/PUBLICACAO_MIDWAY_JUN_2015',
  REGULAMENTO_CAPITALIZACAO_SERVICO_MAIS_SAUDE_CANAL_LOJA:
    '/contrato/Regulamento-Capitalizacao-Servico-Mais-Saude-Canal-Loja',
  REGULAMENTO_CAPITALIZACAO_SERVICO_MAIS_SAUDE_CANAL_APP:
    '/contrato/Regulamento-Capitalizacao-Servico-Mais-Saude-Canal-App',
  CONDICOES_GERAIS_VSS: '/contrato/Condicoes-Gerais-VSS',
  PROGRAMA_MI_INDICA: '/contrato/PROGRAMA_MI_INDICA',
  RESUMO_DAS_CONDICOES_GERAIS_SEGURO_CELULAR:
    '/contrato/Resumo-das-condicoes-gerais-seguro-celular',
  CONDICOES_INTEGRAS_SEGURO_CELULAR:
    '/contrato/Condicoes-Integras-Seguro-Celular',
  CONDIÇÃO_GERAL_RESIDÊNCIAL: '/contrato/condição-geral-Residêncial',
  RESUMO_DE_CONDIÇÃO_GERAL: '/contrato/resumo-de-condição-geral',
  CONDIÇÕES_GERAIS_DA_ASSISTÊNCIA_DESCARTE_ECOLÓGICO:
    '/contrato/Condições-Gerais-da-Assistência-descarte-Ecológico',
  CONDICOES_GERAIS_NA_INTEGRA_SEGURO_ACIDENTES_PESSOAISCOLETIVO:
    '/contrato/CONDICOES-GERAIS-NA-INTEGRA_Seguro-Acidentes-PessoaisColetivo',
  CGS_RESUMO_SEGURO_ACIDENTES_PESSOAIS_ATE_2017:
    '/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-ATE-2017',
  CGS_RESUMO_SEGURO_ACIDENTES_PESSOAIS_APOS_OUTUBRO_2017:
    '/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-APOS-OUTUBRO-2017',
  RESUMO_CG_PLANO_SUPER_BOLSA: '/contrato/Resumo-CG-Plano-Super-bolsa',
  RESUMOCG_PLANO_MEGA_PIX: '/contrato/resumoCG-plano-mega-PIX',
  SEGURO_MAIS_PROTEÇÃO_PREMIÁVEL_CARTÃO_PIX:
    '/contrato/seguro-mais-proteção-premiável-cartão-pix',
  SEGURO_MAIS_PROTECAO_PREMIAVEL_BOLSA:
    '/contrato/seguro-mais-protecao-premiavel-bolsa',
  CONDICOES_GERAIS_SEGURO_CONTA_PAGA_NOVO:
    '/contrato/CONDICOES-GERAIS-SEGURO-CONTA-PAGA-NOVO',
  REGULAMENTO_CAPITALIZACAO_SEGURO_CONTA_PAGA:
    '/contrato/Regulamento-Capitalizacao-Seguro-Conta-Paga',
  REGULAMENTO_CAPITALIZACAO_SEGURO_CELULAR_PREMIAVEL:
    '/contrato/Regulamento-Capitalizacao-Seguro-Celular-Premiavel',
  REGULAMENTO_CAPITALIZACAO_SEGURO_ACIDENTES_PESSOAIS_PREMIAVEIS:
    '/contrato/Regulamento-Capitalizacao-Seguro-Acidentes-Pessoais-Premiaveis',
  REGULAMENTO_CAPITALIZACAO_SEGURO_RESIDENCIAL:
    '/contrato/Regulamento-Capitalizacao-Seguro-Residencial',
  REGULAMENTO_CAPITALIZACAO_SEGURO_BOLSA_PROTEGIDA_PREMIAVEL:
    '/contrato/Regulamento-Capitalizacao-Seguro-Bolsa-Protegida-Premiavel',
  REGULAMENTO_MAIS_PROTECAO_PREMIAVEL:
    '/contrato/REGULAMENTO_MAIS-PROTECAO-PREMIAVEL',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_1_SEM_2022:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2022',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_2_SEM_2021:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2021',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_1_SEM_2021:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2021',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_2_SEM_2020_VS2:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2020_vs2',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_1_SEM_2020:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2020',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_2_SEM_2019:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2019',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_1_SEM_2019:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2019',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_2_SEM_2018:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2018',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_1_SEM_2018:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2018',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_2_SEM_2017:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_2_sem_2017',
  OUVIDORIA_SITE_MIDWAY_RELATORIO_SEMESTRAL_DE_ATIVIDADES_1_SEM_2017:
    '/contrato/Ouvidoria_-_Site_Midway_-_Relatorio_Semestral_de_Atividades_-_1_sem_2017',
  RELATORIO_DE_GESTAO_DE_RISCO: '/contrato/Relatorio-de-Gestao-de-Risco',
  CONDICOES_GERAIS_CONTA_PAGAMENTO_MIDWAY:
    '/contrato/condicoes-gerais-conta-pagamento-midway',
  CONTA_PREMIADA: '/conta-digital/conta-premiada',
};

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import RenderBannerImage from './components/RenderBannerImage';
import { banner } from './data';
import Card from './components/Card';

import ToRankIcon from '../../assets/img/Financial/safe-buy-award-winning/icon-to-rank.svg';
import NoCoinIcon from '../../assets/img/Financial/safe-buy-award-winning/icon-no-coin.svg';
import InfoIcon from '../../assets/img/Financial/safe-buy-award-winning/icon-info.svg';
import ButtonFull from '../../assets/img/Financial/cell-phone-insurance/button-full.svg';
import ZurichLogo from '../../assets/img/Financial/cell-phone-insurance/zurichLogo.png';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor onClick={onClick} href={href} target="_blank">
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const CardWrapper = ({ title, icon, widthCard, href }) => {
  return (
    <S.ContainerCardWrapper>
      <S.CardWrapper minWidth={widthCard}>
        <S.CardAnchor href={href}>
          <S.Title>{title}</S.Title>
          <S.Icon src={icon} />
        </S.CardAnchor>
      </S.CardWrapper>
    </S.ContainerCardWrapper>
  );
};

const SafeBuyAwardWinning = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerSeguroCompraPremiada(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(banner);
    } catch (error) {
      setBannerData(banner);
    }
  }, []);

  useEffect(() => {
    handleGetBannerData();
  }, [handleGetBannerData]);

  const banners = bannerData[0] || banner;

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banners} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Seguro Compra Premiada</S.TitleCards>
            <S.subTitleCards>
              Em caso de perda de renda, você poderá receber um valor de R$
              300,00, além de contar com uma assistência para a recolocação
              profissional, e ainda concorrer a sorteios semanais!
            </S.subTitleCards>
          </S.AdjustamentTitleCard>

          <S.ContainerCards>
            <Card
              icon={ToRankIcon}
              title="Sorteio semanal de R$ 2.500,00"
              description="1 sorteio semanal no valor líquido de R$ 2.500, através da Loteria Federal, durante a vigência do produto."
            />
            <Card
              icon={NoCoinIcon}
              title="Indenização em caso de perda de renda"
              description="Coberturas para Desemprego involuntário, Incapacidade física total e temporária e Diária de internação hospitalar*."
            />
            <Card
              icon={InfoIcon}
              title="Assistência recolocação profissional"
              description="Uma prestação de serviço que te auxiliará na organização da sua vida profissional."
            />
          </S.ContainerCards>

          <S.AdditionalInfoList>
            <S.AdditionalInfoTitle>
              Serviços da assistência recolocação profissional
            </S.AdditionalInfoTitle>
            <S.OrderedList>
              <S.ListItem>
                Avaliação de perfil comportamental, capacidades, habilidades e
                experiências;
              </S.ListItem>
              <S.ListItem>
                Elaboração e ou revisão do currículo e carta de apresentação;
              </S.ListItem>
              <S.ListItem>Oportunidades de vagas;</S.ListItem>
              <S.ListItem>Orientação para entrevistas;</S.ListItem>
              <S.ListItem>
                Indicação de cursos para aperfeiçoamento e desenvolvimento;
              </S.ListItem>
              <S.ListItem>
                Auxílio na criação ou revisão do perfil LinkedIn
              </S.ListItem>
            </S.OrderedList>
            <S.TextGroup>
              <S.RunningText>
                Tipo de evento: Auxílio à: Recolocação profissional, colocação
                profissional ou primeiro emprego.
              </S.RunningText>
              <S.RunningText>
                Limite serviço: 01 (um) acionamento por vigência anual.
              </S.RunningText>
            </S.TextGroup>
            <S.CardWrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline href="https://www.midway.com.br/static/documents/tags/contratos/cg-seguro-compra-premiada.pdf">
                  Condições gerais do Seguro Compra Premiada{' '}
                </S.CardWrapperUnderline>
                <S.CardWrapperUnderline href="https://www.midway.com.br/static/documents/tags/contratos/cg-assistencia-orientacao-profissional.pdf">
                  Condições gerais Assistência Recolocação Profissional{' '}
                </S.CardWrapperUnderline>
              </S.WrapperText>
            </S.CardWrapperText>
          </S.AdditionalInfoList>

          <S.ContainerCardsZurich>
            <S.ContentCardWrapper>
              <CardWrapper
                title="Central de atendimento Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/atendimento"
              />
              <CardWrapper
                widthCard="531px"
                title="Fale com a Laiz - Assistente virtual Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/chatonline"
              />
              <CardWrapper
                title="Consultar número da sorte"
                icon={ButtonFull}
                href="https://www.segurocomprapremiada.com.br"
              />
            </S.ContentCardWrapper>
          </S.ContainerCardsZurich>

          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar o Seguro
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText paddingBottom="16px">
              Ligue para Central de Atendimento Zurich <br />
              Horário de Atendimento: Segunda a Sexta-feira, das 8h ás 20h e aos
              Sábados das 8h ás 18h, exceto feriados. <br />
            </S.CardBlackBottomText>
            <S.CardBlackBottomText>
              <S.UnderlineText>4020 8986 </S.UnderlineText> (Capitais e região
              metropolitana) <br />
              <S.UnderlineText>0800 085 8986 </S.UnderlineText> (demais
              localidades) <br />
              SAC: <S.UnderlineText>0800 284 4848 </S.UnderlineText>
              <br />
              Ouvidoria: <S.UnderlineText> 0800 770 1061</S.UnderlineText>
              <br />
              Deficiente Auditivo:{' '}
              <S.UnderlineText> 0800 275 8585 </S.UnderlineText>. Horário de
              atendimento: 24 horas, 7 dias na semana. <br />
              <br />
              Central de atendimento Midway
              <br />
              3004 5417 (capitais e regiões metropolitanas)
              <br />
              0800 727 4417 (demais localidades)
              <br />
              SAC: 0800 721 3344
              <br />
              Ouvidoria Midway: 0800 727 3255
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>

          <S.ContentZurich>
            <S.ZurichLeft src={ZurichLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                Fundada na Suíça em 1872, com sede na cidade de Zurique, somos
                uma das seguradoras globais mais experientes do mundo. Com cerca
                de 56 mil funcionários, oferecemos uma ampla gama de produtos e
                serviços de ramos elementares e de vida em mais de 210 países e
                territórios. Atendemos pessoas físicas e jurídicas – de pequenas
                empresas a multinacionais.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>
          <S.ContentTextFinalTop>
            <S.TextTop>
              *Consultar condições gerais para coberturas por categoria
              profissional
            </S.TextTop>
            <S.TextTop>
              Sobre a elegibilidade do seguro, todos são elegíveis ao seguro,
              exceto aposentados por invalidez e que não exercem atividade
              laborativa e funcionários públicos com instabilidade de emprego.
            </S.TextTop>
          </S.ContentTextFinalTop>
          <S.ContentFinal>
            <S.ContentTextFinal>
              Seguro Compra Premiada garantido pela Zurich Minas Brasil Seguros
              S/A – CNPJ 17.197.385/0001-21 – Código Susep: 05495 e Processo
              Susep Perda de Renda 15414.900539/2016-41. A Zurich Minas Brasil
              Seguros S/A é proprietária de Títulos de Capitalização da
              modalidade incentivo, emitidos e administrados pela Zurich Brasil
              Capitalização S/A, CNPJ nº 17.266.009/0001-41, processo SUSEP CAP:
              15414.901583/2018-31. A aprovação deste título pela SUSEP não
              implica, por parte da Autarquia, em incentivo ou recomendação a
              sua aquisição, representando exclusivamente, sua adequação as
              normas em vigor. Regulamento da promoção comercial de
              capitalização disponível no site:{' '}
              <Anchor
                href="https://www.segurocomprapremiada.com.br"
                fontSize="16px"
              >
                www.segurocomprapremiada.com.br.
              </Anchor>{' '}
              www.segurocomprapremiada.com.br. O registro do produto é
              automático e não representa aprovação ou recomendação por parte da
              Susep. Central de atendimento Zurich: SAC: 0800 284 4848,
              Deficiente Auditivo: 0800 275 8585, Horário de atendimento: 24
              horas, 7 dias na semana; Ouvidoria: 0800 770 1061, Horário de
              atendimento: segunda a sexta-feira, das 8h30 às 18h, exceto
              feriados. Link da plataforma digital oficial para registro de
              reclamações dos consumidores dos mercados supervisionados
              <Anchor href="https://www.consumidor.gov.br" fontSize="16px">
                (www.consumidor.gov.br).
              </Anchor>
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default SafeBuyAwardWinning;

import styled from 'styled-components';

export const InnerHtmlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 0.875rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    font-size: inherit !important;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    strong {
      font-weight: 500;
    }
  }
`;

import { IconMinus, IconPlus } from '@tabler/icons-react';

import { IconChevronDown } from '@tabler/icons-react';
import { TriggerContainer } from './styles';

const AccordionTrigger = ({ open, triggerChevron, ...props }) => {
  return (
    <TriggerContainer {...props} isAnimated={triggerChevron} open={open}>
      {triggerChevron ? (
        <IconChevronDown />
      ) : open ? (
        <IconMinus />
      ) : (
        <IconPlus />
      )}
    </TriggerContainer>
  );
};

export default AccordionTrigger;

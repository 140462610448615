import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin-top: ${pros => (pros.hasMobile ? '116px' : '0')};
`;
export const HeaderImage = styled.div`
  img {
    object-fit: contain;
  }
`;
export const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #221f20;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const BannerContainer = styled.div`
  width: 100%;
  max-width: 1442px;
  position: relative;
  object-fit: cover;
`;

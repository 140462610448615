import styled from 'styled-components';
import * as Common from '../styles';

export const GrayBackground = styled.div`
  background-color: #f7f7f7;
  margin-bottom: 104px;
`;

export const Container = styled(Common.Container)`
  display: grid;
  grid-template-columns: 1fr minmax(0, 423px);
  grid-template-rows: auto;
  grid-template-areas:
    'title title'
    'image image'
    'text text'
    'buttons buttons';

  @media screen and (min-width: 1176px) {
    grid-template-areas:
      'title image'
      'text image'
      'buttons image';
    padding: 40px 0;
  }
`;

export const Title = styled(Common.Title)`
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
  margin-bottom: 40px !important;
  color: #221f20;
  grid-area: title;

  @media screen and (min-width: 1176px) {
    max-width: 390px;
  }
`;

export const TextContainer = styled.div`
  grid-area: text;
  margin-bottom: 40px;

  @media screen and (min-width: 1176px) {
    max-width: 680px;
  }
`;

export const Text = styled(Common.Text)`
  font-weight: 400 !important;
  margin-bottom: 16px !important;

  &:last-of-type {
    margin-bottom: 0 !important;
  }
`;

export const ImageContainer = styled.div`
  width: 313px;
  height: 416px;
  grid-area: image;
  position: relative;
  margin: 0 auto 40px;

  @media screen and (min-width: 1176px) {
    margin: -95px auto -105px;
  }
`;

export const GrayCircle = styled.div`
  position: absolute;
  width: 313px;
  height: 313px;
  border-radius: 50%;
  background-color: #cacecd;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
`;

export const Mockup = styled.img`
  position: absolute;
  width: 203px;
  height: 391px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
`;

export const Video = styled.video`
  position: absolute;
  height: 370px;
  object-fit: contain;
  border-radius: 20px;
  margin-top: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

export const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  max-height: 41px;

  img {
    height: 100%;
  }
`;

export const LinkButton = styled.a``;

export const Modal = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  position: absolute;
  background-color: #fff;
  padding: 18px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  min-height: 620px;
  max-width: 1020px;
  border-radius: 11px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
`;

/* eslint-disable no-unused-expressions */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal } from '@midway/web-ui-component';
import IconCheck from '~/assets/img/icons/IconCheck.png';
import { formatterCurrency } from '~/utils/utils';
import { tagSimulacaoEmprestimoStep2Button } from '~/services/tagging';
import IconClosed from '~/assets/img/icons/IconX.png';
import AccountHolderLoanHeader from '../../components/header/index';
import { AccountHolderLoanButtons } from '../../components/buttons/index';
import * as S from './styles';

const AccountHolderLoanStep2 = props => {
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [firstDayPayment, setFirstDayPayment] = useState(0);

  const [showModalPortion, setShowModalPortion] = useState(false);
  const [quantityPortion, setQuantityPortion] = useState(null);

  const [codPlan, setCodPlan] = useState(null);

  const {
    changeButtonNext,
    simulationLoan,
    changeButtonReturn,
    propsFstDayPay,
  } = props;

  useEffect(() => {
    props.propsQttPortion(quantityPortion);
  }, [props, quantityPortion]);

  useEffect(() => {
    props.propsCodPlan(codPlan);
  }, [codPlan, props]);

  const disabledButtonLoan = useCallback(() => {
    if (firstDayPayment && quantityPortion) {
      return false;
    }
    return true;
  }, [firstDayPayment, quantityPortion]);

  const bodyModal = useMemo(() => {
    if (showModalPayment) {
      const firstPaymentFilter = [
        ...new Set(simulationLoan?.map(e => e.carencia)),
      ];

      return (
        <Modal
          state="white-lighter"
          openModal={showModalPayment}
          id="ready-modal-for-screen-reader"
          onHide={() => setShowModalPayment(false)}
        >
          <S.ModalContent>
            <div
              className="ahl-icon-closed"
              onClick={() => setShowModalPayment(false)}
            >
              <img src={IconClosed} alt="" />
            </div>

            <div className="ahl-title">Primeiro pagamento em até: </div>

            <div className="ahl-options">
              {firstPaymentFilter.map(item => (
                <div
                  className="ahl-option"
                  onClick={() => {
                    setFirstDayPayment(item);
                    setShowModalPayment(false);
                    setQuantityPortion(null);
                    tagSimulacaoEmprestimoStep2Button('editar');
                  }}
                >
                  <div>
                    {firstDayPayment === item && <img src={IconCheck} alt="" />}
                  </div>
                  <span>{`${item} dias`}</span>
                </div>
              ))}
            </div>
          </S.ModalContent>
        </Modal>
      );
    }
    if (showModalPortion) {
      return (
        <Modal
          state="white-lighter"
          openModal={showModalPortion}
          id="ready-modal-for-screen-reader"
          onHide={() => setShowModalPortion(false)}
        >
          <S.ModalContent>
            <div
              className="ahl-icon-closed"
              onClick={() => setShowModalPortion(false)}
            >
              <img src={IconClosed} alt="" />
            </div>
            <div className="ahl-title">Quantidade de Parcelas </div>

            <div className="ahl-options">
              {simulationLoan.map(
                item =>
                  firstDayPayment === item.carencia && (
                    <div
                      className="ahl-option"
                      onClick={() => {
                        setQuantityPortion(
                          `${
                            item.quantidadePrestacoes
                          }x ${formatterCurrency.format(item.valorPrestacao)}`
                        );
                        setCodPlan(item.codigoPlano);
                        propsFstDayPay(item.dataPrimeiraPrestacao);
                        setShowModalPortion(false);
                        tagSimulacaoEmprestimoStep2Button('editar');
                      }}
                    >
                      <div>
                        {quantityPortion ===
                          `${
                            item.quantidadePrestacoes
                          }x ${formatterCurrency.format(
                            item.valorPrestacao
                          )}` && <img src={IconCheck} alt="" />}
                      </div>
                      <span>{`${
                        item.quantidadePrestacoes
                      }x ${formatterCurrency.format(
                        item.valorPrestacao
                      )}`}</span>
                    </div>
                  )
              )}
            </div>
          </S.ModalContent>
        </Modal>
      );
    }
  }, [
    showModalPayment,
    showModalPortion,
    firstDayPayment,
    simulationLoan,
    quantityPortion,
    propsFstDayPay,
  ]);

  return (
    <S.Container className="container-fluid">
      <S.Content>
        <AccountHolderLoanHeader />

        <div className="ahl-title">Primeiro pagamento em até: </div>
        <div className="ahl-choice" onClick={() => setShowModalPayment(true)}>
          {firstDayPayment <= 0
            ? 'Escolha os dias'
            : `Em até ${firstDayPayment} dias`}
        </div>

        <div className="ahl-title">Selecione a quantidade de parcelas: </div>
        <div
          className="ahl-choice"
          onClick={() => {
            firstDayPayment && setShowModalPortion(true);
          }}
        >
          {quantityPortion === null
            ? 'Digite a quantidade de parcelas'
            : quantityPortion}
        </div>
      </S.Content>

      <AccountHolderLoanButtons
        changeButtonNext={changeButtonNext}
        disabledButton={disabledButtonLoan()}
        changeButtonReturn={changeButtonReturn}
      />

      {bodyModal}
    </S.Container>
  );
};

export default AccountHolderLoanStep2;

import React from 'react';
import { ChevronBottom, ChevronTop } from 'styled-icons/open-iconic';
import AppleStore from '~/assets/img/apple-store.png';
import GooglePlay from '~/assets/img/google-play.png';
import { trackingSelectContent } from '~/analytics';
import {
  Wrapper,
  Col,
  MidwayLogo,
  SectionIcons,
  SocialMediaIcon,
  SocialMediaSection,
  StoreIcon,
  ListLinks,
  Link,
  Section,
  StoreSection,
  ExpandableButton,
  HiddenLinks,
  StyledFooter,
  Line,
} from './styles.mobile';
import data from './data';
import LogoMidway from '../../assets/img/Footer/logo_midway_white.svg';
import useToggle from '../../hooks/useToggle';
import Partners from './components/Partners';
import Copyright from './components/Copyright';
import BackToTop from './components/BackToTop';

const MenuItems = ({ items }) => {
  const [expanded, toggleMenu] = useToggle(false);

  return (
    <ListLinks>
      <div onClick={() => trackingSelectContent('footer:veja-tambem', 'home')}>
        <ExpandableButton onClick={toggleMenu} aria-expanded={expanded}>
          <div>{items.title}</div>
          {expanded ? <ChevronTop size={18} /> : <ChevronBottom size={18} />}
        </ExpandableButton>
      </div>

      <HiddenLinks expanded={expanded}>
        {items.items.map(item => {
          return (
            <div key={item.id}>
              <Link
                onClick={item.onClickFn}
                href={item.link}
                target={item.target}
                rel={item.rel}
              >
                {item.title}
              </Link>
            </div>
          );
        })}
      </HiddenLinks>
    </ListLinks>
  );
};

const Mobile = () => {
  const renderFirstColumn = () => {
    return data.col1.items.map(item => {
      return (
        <div className="SectionIcon" key={item.id}>
          <a
            href={item.link}
            target={item.target}
            aria-label={item.ariaLabel}
            rel={item.rel}
            onClick={item.onClickFn}
          >
            <img
              src={item.icon}
              alt={item.ariaLabel}
              aria-label={item.ariaLabel}
            />
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: item.title }} />
          </a>
        </div>
      );
    });
  };

  return (
    <StyledFooter>
      <Wrapper>
        <Col className="bottom-spacer">
          <MidwayLogo src={LogoMidway} alt="Midway logo" title="Midway" />
          <SectionIcons>{renderFirstColumn()}</SectionIcons>
        </Col>
        <Col className="flex-1">
          <MenuItems items={data.col4} />
          <MenuItems items={data.col3} />
          <Section padding="36px 0 0 0">
            <div>
              <h3>Siga a Midway</h3>
              <SocialMediaSection>
                {data.col2.items.map(({ Icon, ...item }) => {
                  return (
                    <div key={item.id}>
                      <a
                        href={item.link}
                        target={item.target}
                        aria-label={item.ariaLabel}
                        rel={item.rel}
                        onClick={item.onClickFn}
                      >
                        <SocialMediaIcon className="socialMediaIcon">
                          <Icon />
                        </SocialMediaIcon>
                      </a>
                    </div>
                  );
                })}
              </SocialMediaSection>
            </div>
          </Section>
          <Section>
            <h3>Baixe nosso app</h3>
            <StoreSection>
              <a
                onClick={() =>
                  trackingSelectContent('footer:app-store', 'home')
                }
                alt="Apple store"
                href="https://apps.apple.com/br/app/midway-app/id1548732480"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Baixar app na Apple store"
              >
                <StoreIcon
                  width={140}
                  height={41}
                  src={AppleStore}
                  alt="Apple Store"
                />
              </a>
              <a
                onClick={() =>
                  trackingSelectContent('footer:google-play', 'home')
                }
                alt="Play store"
                href="https://play.google.com/store/apps/details?id=br.com.midway"
                rel="noopener noreferrer"
                target="_blank"
                aria-label="Baixar app na play store"
              >
                <StoreIcon
                  width={140}
                  height={41}
                  src={GooglePlay}
                  alt="Play store"
                />
              </a>
            </StoreSection>
          </Section>
        </Col>
      </Wrapper>

      <Line />

      <Section padding="8px 24px 0 24px">
        <Partners />
      </Section>
      <Section padding="8px 24px 0 24px">
        <Copyright />
      </Section>
      <Section padding="8px 24px 0 24px" contentRight flex>
        <BackToTop />
      </Section>
    </StyledFooter>
  );
};

export default Mobile;

import React from 'react';
import CheckIcon from '~/assets/img/card-credit/check.svg';

import * as S from './styles';

export const tableData = [
  {
    column1: 'Ganhe 20% de cashback para a próxima compra',
    subtext1:
      'Faça o Cartão Riachuelo Private Label e use em qualquer uma de nossas lojas sem pagar taxa alguma.',
    column2: <S.Check src={CheckIcon} />,
    column3: <S.Check src={CheckIcon} />,
  },
  {
    column1: 'Parcele compras em 5 vezes sem juros',
    subtext1:
      'Com o Cartão Riachuelo você compra em até 5 vezes sem juros em nossas lojas físicas e virtual.',
    column2: <S.Check src={CheckIcon} />,
    column3: <S.Check src={CheckIcon} />,
  },
  {
    column1: 'Cadastro simplificado',
    subtext1:
      'Aquisição do seu cartão de uma forma simplificada e em menos de 7 minutos* em nossas lojas Riachuelo e aplicativo Riachuelo. *Valor de referência da rede das nossas lojas, podendo ter variações. ',
    column2: <S.Check src={CheckIcon} />,
    column3: <S.Check src={CheckIcon} />,
  },
  {
    column1: 'Aquisição de outros produtos financeiros',
    subtext1:
      'Tenha liberdade para adquirir os produtos financeiros que a Riachuelo oferece (seguros, assistências e empréstimos).',
    column2: <S.Check src={CheckIcon} />,
    column3: <S.Check src={CheckIcon} />,
  },
  {
    column1: 'Descontos e benefícios no ecossistema Riachuelo',
    subtext1:
      'Utilize nas nossas lojas Riachuelo, Casa Riachuelo, Carter’s, FanLab.',
    column2: <S.Check src={CheckIcon} />,
    column3: <S.Check src={CheckIcon} />,
  },
  {
    column1: 'Limite de até R$15.000,00',
    subtext1:
      'Usado no ecossistema de lojas Riachuelo e demais lojas de mercado.',
    column2: <S.NoCheck>X</S.NoCheck>,
    column3: <S.Check src={CheckIcon} />,
  },
  {
    column1: 'São 3 possibilidades de benefícios Surpreenda*',
    subtext1:
      'Compre 1, leve 2. Troque pontos por desconto. Troque pontos por produtos.',
    column2: <S.NoCheck>X</S.NoCheck>,
    column3: <S.Check src={CheckIcon} />,
  },
  {
    column1: 'Desconto de até 35% em farmácias parceiras',
    subtext1:
      'Descontos exclusivo em nossos parceiros (Drogaria São Paulo, Drogaria Pacheco, Pague Menos e Extrafarma).',
    column2: <S.NoCheck>X</S.NoCheck>,
    column3: <S.Check src={CheckIcon} />,
  },
];

import styled from 'styled-components';
import { colors } from '~/constants';

export const HeaderContainer = styled.div`
  transform: translateY(-42px);
  padding-bottom: 32px;
`;

export const Header = styled.p`
  font-family: 'Montserrat';
  color: ${colors.dark};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  margin: 6px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
`;

export const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Section = styled.section`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  max-width: 1182px;

  @media (max-width: 1000px) {
    padding-bottom: 27px;
    width: 90%;
  }
`;

export const Title = styled.h1`
  font-family: Montserrat;
  font-size: 36px !important;
  line-height: 35px;
  text-align: left;
  padding-bottom: 8px;
  font-weight: 800 !important;

  @media (max-width: 1000px) {
    font-size: 24px !important;
    font-weight: 700;
    line-height: 28px;
  }
`;

export const SubTitle = styled.h6`
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 19px !important;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 40px;
  margin-bottom: ${props => props.marginBottom || '40px'};
`;

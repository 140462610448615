import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  min-height: 592px;
  background: #f1ebdf;

  @media screen and (max-width: 900px) {
    min-height: 392px;
    padding: 2em 0px;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
  gap: 33px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const ImageContainer = styled.div`
  height: 100%;
`;
export const Image = styled.img`
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  transform: translateY(50px);
  position: relative;
  bottom: -13px;
`;

export const Title = styled.h1`
  font-size: 56px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 33px;
`;
export const Description = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
`;

export const ContentWrapper = styled.div`
  padding: 0 33px;
`;

import styled from 'styled-components';
import * as CommonStyles from '../styles';

export const Container = styled(CommonStyles.Container)`
  padding: 32px 16px;

  @media screen and (min-width: 900px) {
    margin: 32px auto;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const GrayBackground = styled.div`
  width: 100%;
  background-color: #f7f7f7;

  @media screen and (min-width: 900px) {
    margin: 144px 0;
  }
`;

export const Title = styled(CommonStyles.Title)`
  color: #e4602f;
  line-height: 1;
  font-size: 1.25rem !important;
`;

export const Image = styled.img`
  width: 298px;
  margin-bottom: 16px;

  @media screen and (min-width: 900px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1176px) {
    width: 381px;
  }
`;

export const TextContainer = styled.div``;

export const Text = styled(CommonStyles.Text)`
  margin-bottom: 8px !important;

  &:nth-of-type(2) {
    margin-bottom: 24px !important;
  }
`;

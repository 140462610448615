import React from 'react';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { colors } from '~/constants';

import dollarSign from '~/assets/img/GuaranteedLimitLP/dollar.svg';
import { Step, Text, Title } from './styles';

export const Timeline = () => {
  const info = [
    {
      step: 1,
      title: 'Visite uma loja Riachuelo',
      text: 'Vá até uma das nossas lojas participantes do piloto e peça o seu Cartão Riachuelo para um(a) atendente.',
      textPosition: 'right',
    },
    {
      step: 2,
      title: 'Abra uma Conta Digital Midway',
      text: 'Na hora de pedir o cartão, garanta seu limite abrindo uma Conta Digital Midway em poucos minutos.',
      textPosition: 'left',
    },
    {
      step: 3,
      title: 'Escolha um valor e faça seu depósito',
      text: 'Agora, é só escolher um valor entre R$ 50,00 e R$ 333,00 para depositar e investir em sua Conta Digital Midway.',
      textPosition: 'right',
    },
  ];

  return (
    <VerticalTimeline lineColor={colors.orangeRoof} animate={false}>
      {info.map(item => (
        <VerticalTimelineElement
          key={item.step}
          iconClassName="custom-icon"
          icon={<Step>{item.step}</Step>}
        >
          <Title position={item.textPosition}>{item.title}</Title>

          <Text position={item.textPosition}>{item.text}</Text>
        </VerticalTimelineElement>
      ))}
      <VerticalTimelineElement
        iconClassName="custom-icon"
        icon={<Step>4</Step>}
      >
        <Title position="left">Pronto! O seu limite está garantido</Title>
        <Text position="left">
          Após o depósito, você tem o valor escolhido{' '}
          <span style={{ fontWeight: '600' }}>+50% de limite</span> no seu
          Cartão Riachuelo!
        </Text>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconClassName="dollar-sign-icon"
        icon={
          <Step>
            <img src={dollarSign} alt="dollar sign" />
          </Step>
        }
      />
    </VerticalTimeline>
  );
};

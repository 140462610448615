import React from 'react';
import styled from 'styled-components';
import trackEventGa from '../GoogleAnalytics';

const CentralSection = styled.section`
  background: #f1f1f1;
`;

const CentralContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 10px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 1024px) {
    padding: 24px 10px 50px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    width: 528px;
  }

  .title {
    font-size: 32px;
    text-align: center;
    margin-top: 20px;
    line-height: 125%;
    font-weight: 600;
    .font-bold {
      font-weight: bold;
      font-size: 20px;
      display: block;
    }
  }
`;

const SecondRow = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px 16px;

    @media (min-width: 1024px) {
      justify-content: space-evenly;
    }
  }
`;

const TelephoneItem = styled.li`
  &:first-child {
    max-width: 95px;

    @media (min-width: 1024px) {
      max-width: unset;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 139px;
    font-size: 11px;
    letter-spacing: -0.228px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    text-decoration: none;
    font-size: 12px;
    color: black;
    font-weight: bold;

    @media (min-width: 1024px) {
      max-width: 255px;
      font-size: 20px;
      font-weight: 600;
    }

    .lg-text {
      font-size: 16px;
      letter-spacing: -0.304px;
      @media (min-width: 1024px) {
        font-size: 32px;
      }
    }
  }
`;

const handleEvent = description => {
  let normalized = description
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .replace(/\s+/g, '-');
  trackEventGa(`telefone:${normalized}`);
};

const ContactItem = ({ number, description }) => {
  return (
    <TelephoneItem>
      <a href={`tel:${number}`} onClick={() => handleEvent(description)}>
        <strong className="lg-text">{number}</strong> {description}
      </a>
    </TelephoneItem>
  );
};

const CentralDeRelacionamento = () => {
  return (
    <CentralSection id="central-de-atendimento">
      <CentralContainer>
        <FirstRow>
          <img src="/footer-credit-card.png" alt="Cartão Riachuelo" />
          <p className="title">
            CENTRAL DE RELACIONAMENTO
            <span className="font-bold">Cartões Riachuelo</span>
          </p>
        </FirstRow>
        <SecondRow>
          <ul>
            <ContactItem
              number="3004-5417"
              description="Capitais e Regiões Metropolitanas"
            />
            <ContactItem
              number="0800-727-4417"
              description="Demais Localidades"
            />
            <ContactItem
              number="0800-727-3255"
              description="Ouvidoria Midway"
            />
            <ContactItem number="0800-721-3344" description="SAC" />
            <ContactItem
              number="0800-722-0626"
              description="SAC deficientes auditivos (24h e 7 dias por semana)"
            />
          </ul>
        </SecondRow>
      </CentralContainer>
    </CentralSection>
  );
};

export default CentralDeRelacionamento;

import {
  Icon,
  SubjectItemContainer,
  Title,
  TitleContainer,
  TitleIconContainer,
  TriggerContainer,
} from './styles';

import { IconChevronRight } from '@tabler/icons-react';
import InnerHtml from '../InnerHtml';
import urlLink from '~/assets/img/attendance-portal/url-link.svg';

const SubjectItem = ({
  icon,
  triggerIcon,
  redirectUrl,
  subtitle,
  title,
  onClick,
  ...props
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    }
  };

  return (
    <SubjectItemContainer {...props} onClick={handleClick} title={title}>
      <TitleContainer>
        <TitleIconContainer>
          {icon}
          <Title>{title}</Title>
        </TitleIconContainer>
        {subtitle && <InnerHtml raw={subtitle} />}
      </TitleContainer>

      <TriggerContainer>
        {triggerIcon || redirectUrl ? (
          <Icon src={urlLink} alt="Ícone de redirecionamento" />
        ) : (
          onClick && <IconChevronRight />
        )}
      </TriggerContainer>
    </SubjectItemContainer>
  );
};

export default SubjectItem;

import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  #background {
    width: 100%;
    height: 100%;
    background: #00000090;
    position: fixed;
    z-index: 5;
    top: 0;
  }
  .content {
    width: 660px;
    height: 478px;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    position: fixed;
    max-width: 670px;
    z-index: 5;
    display: flex;
    padding: 50px;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;

    @media (max-width: 768px) {
      width: 90%;
      height: fit-content;
      padding: 25px;
    }
  }
  h2 {
    font: bold 32px Montserrat !important;
    margin-top: 28px;
  }

  p {
    font: 20px Montserrat !important;
    color: #606060;
    padding-bottom: 6px;
  }

  a {
    cursor: pointer;
    width: 10px;
    align-self: flex-end;
    border-radius: 50%;
    display: flex;
    justify-content: end;
    .closeButtom {
      width: 50px;
    }
  }

  img {
    width: 96px;
  }
`;

export const WrapperButtons = styled.div`
  justify-content: space-around;
  width: 100%;
  display: flex;
  margin-top: 30px;

  button {
    width: 40%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    gap: 10px;
  }
`;

import styled, { css } from 'styled-components';

import { colors } from '~/constants';

import creditBlackRchlo from '~/assets/img/GuaranteedLimitLP/card-black-rchlo.svg';
import mobileCreditBlackRchlo from '~/assets/img/GuaranteedLimitLP/card-black-rchlo.png';

const BACKGROUND_IMAGE_OFFSET = 224;
export const Container = styled.section`
  background-color: ${colors.chumbo};
  padding: 80px;

  @media (max-width: 720px) {
    background-image: url(${mobileCreditBlackRchlo});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: ${BACKGROUND_IMAGE_OFFSET}px;
  }

  @media (min-width: 1160px) {
    background-image: url(${creditBlackRchlo});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
  }

  @media (max-width: 900px) {
    padding: 40px 16px;
  }
`;

export const Content = styled.div`
  max-width: 1176px;
  margin: 0 auto;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 720px) {
    margin-top: ${BACKGROUND_IMAGE_OFFSET + 156}px;
  }

  @media (max-width: 1160px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  color: ${colors.white};
  max-width: 640px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: ${colors.white};
  margin-bottom: 16px;

  @media (max-width: 720px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
`;

export const Text = styled.p`
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  margin: 56px 0px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 720px) {
    margin-top: 0px;
    margin-bottom: 48px;
  }
`;

export const BenefitRow = styled.div`
  display: flex;

  & + & {
    margin-top: 24px;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      & + & {
        margin-top: 16px;
      }
    `}
`;

export const BenefitContent = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.orangeRoof};
  display: flex;
  padding: 16px;
  width: 296px;
  height: 140px;

  @media (max-width: 900px) {
    width: 156px;
    height: 140px;
    padding: 12px;
  }

  img {
    width: 32px;
    height: 32px;
  }

  & + & {
    margin-left: 24px;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      & + & {
        margin-left: 16px;
      }
    `}
`;

export const BenefitCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BenefitText = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 16px;

  strong {
    font-weight: 600;
  }

  @media (max-width: 720px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;

  color: ${colors.white};
  max-width: 640px;
  width: 100%;

  img {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  p {
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
`;

import styled from 'styled-components';

export const CardContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 5px;
`;

export const CardIcon = styled.div`
  svg {
    height: 24px;
    width: 24px;
    stroke-width: 1.25px;
    stroke: #221f20;

    @media screen and (min-width: 900px) {
      height: 46px;
      width: 46px;
    }
  }
`;

export const CardTitle = styled.div`
  flex: 1;

  .CardTitleRoot {
    font-size: 0.75rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    white-space: pre-wrap;

    @media screen and (min-width: 492px) {
      font-size: 1rem !important;
      font-weight: 600;
    }
  }
`;

export const CardContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DesktopArrowWrapper = styled.div`
  position: absolute;
  right: 0;
  display: none;
  @media screen and (min-width: 900px) {
    display: block;
  }
`;

export const MobileArrowWrapper = styled.div`
  display: block;

  @media screen and (min-width: 900px) {
    display: none;
  }

  svg {
    height: 24px;
    width: 24px;
    stroke-width: 1.25px;
    stroke: #959d9d;

    @media screen and (min-width: 900px) {
      height: 32px;
      width: 32px;
    }
  }
`;

export const IconRow = styled.div`
  flex: 1;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

import React from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000b3;
  inset: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWindow = styled.div`
  background: black;
  width: 80vw;
  height: 590px;
  border-radius: 16px;
  position: relative;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 21px;
  right: 21px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Modal = ({ children, handleCloseModal }) => {
  return (
    <ModalWrapper>
      <ModalWindow>
        <ModalCloseButton onClick={() => handleCloseModal(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ModalCloseButton>
        {children}
      </ModalWindow>
    </ModalWrapper>
  );
};

export default Modal;

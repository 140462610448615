import styled from 'styled-components';

export const Forms = styled.div`
  height: 340px;

  @media (max-width: 768px) {
    height: fit-content;
  }

  h1 {
    font-weight: bold;
    color: #000000;
    font-size: 32px !important;

    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }
  h2 {
    font-size: 18px !important;
    color: #606060;
    padding: 10px 0px 50px 0px;
  }
  button {
    width: 36%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 20px;
    }
  }

  div.ahl-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: unset;
    flex-direction: row;
    margin-top: 20px;

    label {
      display: flex;
      color: #606060;
      align-items: center;
      justify-content: center;
      font-size: 15px !important;
      margin: 10px 0px;

      @media (max-width: 630px) {
        font-size: 10px !important;
      }
      @media (max-width: 530px) {
        font-size: 14px !important;
      }
    }
  }

  div.ahl-check {
    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-color: #606060;
    border-width: 1px;
    border-radius: 3px;
    border-style: solid;

    margin-right: 12px;

    @media (max-width: 530px) {
      width: 34px;
      height: 20px;
    }

    &-selected {
      img {
        width: 13px;
      }
    }
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;

  div {
    display: flex;
    gap: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 30px;
    }
  }
`;

export const FloatingLabel = styled.div`
  position: relative;
  width: 100%;

  select {
    margin: 0;
  }

  .floating-select,
  input {
    display: block;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #606060;

    @media (max-width: 768px) {
      padding: 10px 0px 4px;
    }
  }

  .floating-select:focus {
    outline: none;
  }

  label {
    color: #000000;
    font-size: 1rem;
    font-weight: normal;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 6px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
  }

  .Active {
    font-size: 14px;
    color: #606060;
    transform: translate(0, -16px) scale(1);
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  input {
    border: 0;
    border-bottom: 1px solid #606060;
    outline: none;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
  }

  label {
    top: 0;
    left: 0;
    right: 0;
    color: #000000;
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 1rem;
    cursor: text;
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
  }

  input,
  label {
    width: 100%;
    height: 2rem;
    font-size: 1rem;
  }

  input:valid + label,
  input:focus + label {
    font-size: 14px;
    top: -30px;
    color: #606060;
  }
`;

import React from 'react';
import { healthServices } from '~/constants';
import * as S from '../styles';
import { CardAssistance, CardAssistanceImageIcon } from '~/components';
import format from '~/services/format';

const SessionOurServices = () => (
  <S.Session3>
    <h2>Nossos serviços para sua saúde</h2>
    <h6>Cuidamos da sua saúde e do seu bolso!</h6>
    <S.Spacer size="48px" />
    <S.GridContainer>
      {healthServices.map(elm => {
        return (
          <CardAssistance
            key={elm.id}
            title={elm.title}
            btnAriaLabel={elm.btnTitle}
            description={elm.text}
            imageAlt={elm.title}
            imagePath={elm.imagePath}
            btnTitle={elm.btnTitle}
            action={elm.action}
            photo={elm.photo}
            gtmCategory="midway:assistencias"
            gtmAction="clique:botao"
            gtmLabel={`clique-aqui:card:${format.normalizeString(elm.title)}`}
            contentHeight={100}
          >
            <CardAssistanceImageIcon
              imageAlt={elm.title}
              imagePath={elm.image}
            />
          </CardAssistance>
        );
      })}
    </S.GridContainer>
  </S.Session3>
);

export default SessionOurServices;

import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 218.182% */
  flex: ${props => (props.flex1 ? 1 : 'initial')};
  max-width: 543px;
  margin: auto;
`;
const anoCorrente = new Date().getFullYear();

const Copyright = ({ flex1 }) => {
  return (
    <StyledContainer flex1={flex1}>
      © Copyright {anoCorrente}. Todos os direitos reservados - Midway. CNPJ
      09.464.032/0001-12 R. Lemos Monteiro, 120 - Butantã, São Paulo - SP -
      Brasil - CEP: 05501-050.
    </StyledContainer>
  );
};

export default Copyright;

import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import trackEventGa from '../GoogleAnalytics';

const ReceiveSection = styled.section`
  background: #f1f1f1;
`;

const ReceiveContainer = styled.div`
  padding: 24px 0;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 0;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0 150px;
    justify-content: space-between;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  @media (min-width: 1024px) {
    width: 55%;
  }
`;

const Title = styled.h3`
  font-size: 36px !important;
  font-weight: 400;
  text-align: left;
  line-height: 116%;

  strong {
    font-weight: bold;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Subtitle = styled.p`
  text-align: left;
  font-size: 16px !important;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Steps = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

const StepItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  color: #919896;

  &.border-outline {
    color: #000000;
    border: 1px solid transparent;
    outline: 1px solid #000;
    outline-offset: -15px;
    height: 110px;
    border: none;
    border-radius: 9999px;
    margin: -15px 0;
    padding-right: 50px;
  }
`;

const StepNumber = styled.span`
  border-radius: 50%;
  color: #919896;
  border: 2px solid #919896;
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 21px !important;

  &.third-step {
    color: #fff;
    border: 2px solid #000;
    background-color: #000000;
  }
`;

const Label = styled.p`
  text-align: left;
  color: #919896;

  strong {
    color: #919896;
  }

  &.third-step {
    color: #000000;

    strong {
      color: #000;
    }
  }
`;

const CTAButton = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: black;
  color: white;
  padding: 20px;
  font-weight: bold;
  font-size: 24px !important;
`;

const SecondColumn = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const CardImage = styled.img``;

const SectionFooter = styled.div`
  background: #d9d9d9;
  padding: 14px;
  margin-top: 32px;

  @media (min-width: 1024px) {
    margin-top: 80px;
  }

  p {
    text-align: center;
    font-size: 14px !important;
    font-weight: normal;
    @media (min-width: 1024px) {
      font-size: 16px !important;
    }
  }

  .font-bold {
    font-weight: 700;
    color: black;
  }

  a {
    text-decoration: underline;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 78px;
  padding: 70px;

  .second-row {
    display: flex;
    gap: 120px;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 24px !important;
    text-align: left;
    color: white;
  }

  .subtitle {
    font-size: 16px !important;
    text-align: left;
    color: white;
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 120px;
  }

  img {
    width: 250px;
  }
`;

const AddressUpdate = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleEvent = buttonName => {
    trackEventGa(buttonName);

    if (window.innerWidth > 1024) {
      setIsOpen(true);
    } else {
      window.open(
        'https://www.riachuelo.com.br/cartoes/desbloqueio?utm_source=crm&utm_medium=landing-page&utm_campaign=tombamento-master&utm_term=master&utm_content=informacao-adicional',
        '_blank'
      );
    }
  };

  const scrollToTestDiv = anchor => {
    const divElement = document.getElementById(anchor);
    divElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <ReceiveSection id="receba">
        <ReceiveContainer>
          <FirstRow>
            <FirstColumn>
              <Title>
                ATUALIZE SEU ENDEREÇO E RECEBA SEU <strong>NOVO CARTÃO</strong>
              </Title>
              <Subtitle>
                Para que você receba seu novo cartão em sua casa ou em outro
                endereço que indicar, é necessário que você atualize seu
                endereço completo. Siga o passo a passo abaixo:
              </Subtitle>
              <Steps>
                <StepItem>
                  <StepNumber>1</StepNumber>
                  <Label>Baixe o App Midway ou Riachuelo;</Label>
                </StepItem>
                <StepItem>
                  <StepNumber>2</StepNumber>
                  <Label>Faça o login na sua conta;</Label>
                </StepItem>
                <StepItem className="border-outline third-step">
                  <StepNumber className="third-step">3</StepNumber>
                  <Label className="third-step">
                    <strong style={{ fontWeight: 'bold' }}>
                      Atualize seu endereço
                    </strong>{' '}
                    clicando no banner inicial ou então na aba “cartões”;
                  </Label>
                </StepItem>
                <StepItem>
                  <StepNumber>4</StepNumber>
                  <Label>
                    <strong>Pronto!</strong> Seu novo cartão está a caminho e
                    você pode acompanhar a entrega por um dos apps.
                  </Label>
                </StepItem>
              </Steps>
              <CTAButton onClick={() => handleEvent('baixar-app')}>
                Baixar app
              </CTAButton>
            </FirstColumn>
            <SecondColumn>
              <CardImage src="/app-preview.png" alt="" />
            </SecondColumn>
          </FirstRow>
          <SectionFooter>
            <p>
              Se preferir você pode atualizar seu endereço ligando em nossa{' '}
              <a
                onClick={() => scrollToTestDiv('central-de-atendimento')}
                className="font-bold underline"
              >
                Central de Atendimento
              </a>
            </p>
          </SectionFooter>
        </ReceiveContainer>
      </ReceiveSection>
      {isOpen ? (
        <Modal handleCloseModal={setIsOpen}>
          <ModalContainer>
            <div className="first-row">
              <div className="title">Download de app</div>
              <div className="subtitle">
                Escolha o app de sua preferência e faça o download para
                atualizar seu endereço.
              </div>
            </div>
            <div className="second-row">
              <img
                className="qrcode-image"
                src="/qrcode-riachuelo.png"
                alt=""
              />
              <img className="qrcode-image" src="/qrcode-midway.png" alt="" />
            </div>
          </ModalContainer>
        </Modal>
      ) : null}
    </>
  );
};

export default AddressUpdate;

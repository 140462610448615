import React from 'react';
import { IconConfetti, IconInfoCircle } from '@tabler/icons-react';
import IlustrationImg from '~/assets/img/CardBenefits/how-to-use-ilustra.svg';
import VideoSrc from '~/assets/img/CardBenefits/use-points.webm';
import MockupImg from '~/assets/img/CardBenefits/mastercard-mockup.png';
import * as S from './styles';

const HowToUseYourPoints = () => {
  return (
    <S.Container>
      <S.HowToUseTitle>Como usar meus pontos?</S.HowToUseTitle>
      <S.ImageContainer>
        <S.Ilustration src={IlustrationImg} alt="" role="presentation" />
        <S.Mockup src={MockupImg} alt="" role="presentation" />
        <S.Video loop autoPlay muted>
          <source src={VideoSrc} type="video/webm" />
        </S.Video>
      </S.ImageContainer>
      <S.ListContainer>
        <S.List>
          <S.ListItem>
            <S.Counter>1</S.Counter>
            Faça o seu login no site ou aplicativo Surpreenda;
          </S.ListItem>
          <S.ListItem>
            <S.Counter>2</S.Counter>
            <S.VerticalLine />
            Encontre um parceiro e escolha a oferta desejada;
          </S.ListItem>
          <S.ListItem>
            <S.Counter>3</S.Counter>
            <S.VerticalLine />
            Confira se a sua pontuação é suficiente para regaste do cupom;
          </S.ListItem>
          <S.ListItem>
            <S.Counter>
              <IconConfetti />
            </S.Counter>
            <S.VerticalLine />
            Resgate o seu cupom e aproveite muito!
          </S.ListItem>
        </S.List>
        <S.Info>
          <IconInfoCircle />
          <S.InfoTextContainer>
            <S.InfoText bold>
              A utilização do voucher pode variar de acordo com o parceiro
              escolhido
            </S.InfoText>
            <S.InfoText>Leia com atenção as condições e regras</S.InfoText>
          </S.InfoTextContainer>
        </S.Info>
      </S.ListContainer>
    </S.Container>
  );
};

export default HowToUseYourPoints;

import styled from 'styled-components';
import { colors } from '~/constants';

export const HeaderContainer = styled.div`
  width: fit-content;
  margin-top: 24px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    opacity: 0.3;
  }
`;

export const CollapsableContainer = styled.div`
  margin-top: 12px;
`;

export const Header = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: ${colors.dark};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InstallmentFeeContainer = styled.div`
  @media (max-width: 992px) {
    margin-bottom: 12px;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin-right: 12px;
  color: ${colors.dark_gray};
`;

export const Bold = styled(Text)`
  color: ${colors.dark};
`;

export const CETBox = styled.div`
  width: 100%;
  margin-top: 20px;
  text-align: center;
  padding: 10px 12px;
  border: 1px ${colors.dark_gray_2} solid;
  border-radius: 4px;
`;

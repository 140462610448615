import React, { useState } from 'react';
import styled from 'styled-components';
import trackEventGa from '../GoogleAnalytics';

const FaqSection = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 1024px) {
    padding: 44px 50px;
  }
`;

const FaqTitle = styled.h3`
  font-weight: bold;
  font-size: 30px !important;
  text-align: center;
`;

const BackToTop = styled.button`
  border: none;
  display: flex;
  max-width: 30%;
  cursor: pointer;
  background: none;
  align-items: center;
  margin: 20px auto 0;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1024px) {
    margin: 40px auto 0;
  }
`;

const Icon = styled.img`
  width: 30px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500 !important;
  line-height: normal;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;

const FaqItems = styled.ul``;

const FaqItem = styled.li`
  cursor: pointer;
  text-align: left;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid #000;
`;

const FaqTitleText = styled.p`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: space-between;

  img {
    width: 15px;
    transition: transform 300ms ease-in-out;
    transform: ${({ accordion }) => (accordion ? 'rotate(180deg)' : 'none')};
  }
`;

const FaqDescription = styled.p`
  font-size: 14px;
  overflow: hidden;
  max-height: ${({ accordion }) => (accordion ? '400px' : '0')};
  padding-bottom: ${({ accordion }) => (accordion ? '20px' : '0')};
`;

const Faq = () => {
  const [accordion, setAccordion] = useState();

  const scrollToTestDiv = anchor => {
    const divElement = document.getElementById(anchor);
    divElement.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleAccordion = index => {
    if (accordion === index) {
      return setAccordion();
    }
    setAccordion(index);
  };

  const faqItems = [
    {
      title: '1. Não recebi a senha. O que eu faço?',
      description:
        'Para gerar uma nova senha, é necessário ir até a loja Riachuelo mais próxima. No balcão de atendimento, você pode solicitar uma senha provisória via aplicativo Riachuelo ou Central de Relacionamento. Essa senha deverá ser informada ao atendente e realizar o processo de criação de nova senha.',
    },
    {
      title: '2. A minha fatura permanece igual?',
      description: 'Sim. Não temos alteração de fatura.',
    },
    {
      title:
        '3. Por que meu VISA está sendo trocado se o meu cartão está longe de vencer?',
      description:
        'A bandeira VISA deixará de ser emitida pelo Cartão Riachuelo e por isso todos os cartões passarão a ser da bandeira Mastercard.',
    },
    {
      title: '4. Posso continuar usando meu VISA?',
      description:
        'Seu VISA será bloqueado a partir do momento do desbloqueio do novo Cartão Riachuelo Mastercard. Recomendamos o desbloqueio imediato ao recebê-lo, pois em breve os cartões antigos serão bloqueados.',
    },
    {
      title:
        '5. Como ter certeza de que não corro o risco de cair em um caso de fraude?',
      description:
        'Realize o desbloqueio apenas quando estiver com o cartão em mãos! Para sua segurança, em nossos contatos nunca solicitamos a senha do seu cartão ou que faça o desbloqueio utilizando a sua biometria no App. Sempre confirme que está em contato com nossos canais oficiais.',
    },
    {
      title:
        '6. Se atraso a fatura do cartão de crédito, perco o limite disponível do Empréstimo?',
      description:
        'Sim, a inadimplência no cartão de crédito Riachuelo torna o cliente inelegível para a contratação do Empréstimo.',
    },
    {
      title: '7. Se atraso o Empréstimo, perco o limite do cartão de crédito?',
      description:
        'Sim, a inadimplência do Empréstimo causa o bloqueio do limite do Cartão Riachuelo.',
    },
    {
      title:
        '8. O limite do Empréstimo influencia no limite do meu cartão de crédito?',
      description:
        'Não, os limites são independentes. A utilização de um não influencia no limite do outro.',
    },
    {
      title:
        '9. Tenho limite disponível, porém na simulação meu Empréstimo foi negado. O que acontece?',
      description:
        'Após a simulação para contratação de Empréstimo, ainda realizamos uma avaliação final com dados internos e externos, o que pode ocasionar na não aprovação do Empréstimo Pessoal.',
    },
  ];

  const handleFaqTitleClick = (title, isOpen) => {
    const action = !isOpen ? 'abir' : 'fechar';
    const faqTitleGa = `faq:${action}:${title
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[0-9.]/g, '')
      .trim()
      .toLowerCase()
      .replace(/\s/g, '-')}`;

    trackEventGa(faqTitleGa);
  };

  return (
    <FaqSection id="faq">
      <FaqTitle>Dúvidas</FaqTitle>
      <FaqItems>
        {faqItems.map((faq, index) => (
          <FaqItem
            key={index}
            accordion={accordion === index}
            onClick={() => {
              toggleAccordion(index);
              handleFaqTitleClick(faq.title, accordion === index);
            }}
          >
            <FaqTitleText accordion={accordion === index}>
              {faq.title}
              <img src="/chevron-down.svg" alt="" />
            </FaqTitleText>
            <FaqDescription accordion={accordion === index}>
              {faq.description}
            </FaqDescription>
          </FaqItem>
        ))}
      </FaqItems>
      <BackToTop onClick={() => scrollToTestDiv('app-lp-master')}>
        <Icon src="/arrow-top.svg" />
        <Text>Voltar ao topo</Text>
      </BackToTop>
    </FaqSection>
  );
};

export default Faq;

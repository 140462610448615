import React, { memo } from 'react';
import useClipboard from 'react-hook-clipboard';
import { Icon } from '@midway/web-ui-component';
import { toast } from 'react-toastify';

import Calendar from '~/assets/img/Financial/BilletInvoiceOrLendingV2/calendar.svg';
import Cash from '~/assets/img/Financial/BilletInvoiceOrLendingV2/cash.svg';
import { tagContaOnlineConsultaBoletoButton } from '~/services/tagging';

import * as Styles from './styles';

const LoanBankslipItem = ({
  payline,
  dueDate,
  id,
  selected,
  onSelect,
  financedAmount,
  valueBankSlip,
  fourDigitsOrContract,
}) => {
  const [, copyToClipboard] = useClipboard();

  const handleOnClick = () => {
    onSelect(id);
  };

  const handleCopyCode = () => {
    tagContaOnlineConsultaBoletoButton('botao:copiar-codigo');
    copyToClipboard(payline);
    toast('Código do boleto copiado com sucesso');
  };

  const handleEventInfo = event => {
    event.stopPropagation();
    toast(
      'O valor total a pagar no boleto poderá vir com acréscimo de juros e multa em caso de atraso.'
    );
  };

  const ButtonInfo = () => {
    return (
      <Styles.InfoButton onClick={handleEventInfo}>
        <Icon icon="info-circle" />
      </Styles.InfoButton>
    );
  };

  return (
    <Styles.Container>
      <Styles.TopContainer
        disabled={selected === id}
        isExpanded={selected === id}
        aria-label={`Expandir para exibir o código de barras do contrato ${fourDigitsOrContract}`}
        onClick={handleOnClick}
        data-testid="bank-slip-item"
      >
        <>
          <Styles.InputRadioContainer>
            <Styles.ContainerLine>
              <Styles.Dot>
                <Styles.Dot2 isChecked={selected === id} />
              </Styles.Dot>
              <Styles.LabelRadio isExpanded={selected === id}>
                {`Contrato: ${fourDigitsOrContract}`}
              </Styles.LabelRadio>
            </Styles.ContainerLine>
          </Styles.InputRadioContainer>

          <Styles.ContainerLine>
            <Styles.Image src={Calendar} />
            <Styles.Text>Data de vencimento: {dueDate}</Styles.Text>
          </Styles.ContainerLine>

          {financedAmount && (
            <Styles.ContainerLine>
              <Styles.Image src={Cash} />
              <Styles.Text>Valor financiado: {financedAmount}</Styles.Text>
            </Styles.ContainerLine>
          )}

          {valueBankSlip && (
            <Styles.ContainerLine>
              <Styles.Image src={Cash} />
              <Styles.Text>
                Valor do boleto: {valueBankSlip} <ButtonInfo />
              </Styles.Text>
            </Styles.ContainerLine>
          )}
        </>
      </Styles.TopContainer>

      <Styles.BottomContainer>
        <Styles.AnimatedCard isExpanded={selected === id}>
          <Styles.TextBodyContainer>
            <Styles.TextBody>
              Código de barras digitável para o pagamento:
            </Styles.TextBody>
          </Styles.TextBodyContainer>

          <Styles.BarcodeContainer>
            <Styles.TextBarcode>{payline}</Styles.TextBarcode>
          </Styles.BarcodeContainer>

          <div>
            <Styles.Button
              onClick={handleCopyCode}
              disabled={selected !== id}
              aria-label={`Copiar código de barras do contrato ${fourDigitsOrContract}`}
            >
              <Icon icon="copy" color="#fff" /> Copiar código
            </Styles.Button>

            <Styles.MarginBottom value={16} />
          </div>
        </Styles.AnimatedCard>
      </Styles.BottomContainer>
    </Styles.Container>
  );
};

export default memo(LoanBankslipItem);

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
`;

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const CentralizeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const AdjustamentTitleCard = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  padding-bottom: 50px;
  margin-top: 50px;
  width: 90%;
  max-width: 1149px;

  @media (max-width: 1000px) {
    padding-bottom: 27px;
    margin-top: 37px;
  }
`;

export const TitleCards = styled.h1`
  font-family: Montserrat;
  font-size: 32px !important;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  padding-bottom: 24px;

  @media (max-width: 1000px) {
    font-size: 24px !important;
    font-weight: 700;
    line-height: 28px;
  }
`;

export const subTitleCards = styled.h6`
  font-family: Montserrat;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 19px !important;
  }
`;

export const ContainerCards = styled.div`
  width: 90%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 35px;

  @media (max-width: 1000px) {
    margin-bottom: 30px;
  }
`;

export const CardWrapperText = styled.div`
  width: 100%;
  max-width: 1180px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 97px;
  margin-bottom: 85px;
  @media (max-width: 1000px) {
    gap: 1rem;
    margin-top: 57px;
  }
`;

export const WrapperText = styled.p`
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

export const CardWrapperUnderline = styled.a`
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px;
  text-align: left;
  padding-bottom: 8px;
  color: #1f2b2a !important;
`;

export const AdditionalInfoCard = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 90%;
  max-width: 1149px;
`;

export const InformationPanel = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1180px;
  margin-top: 120px;
  @media (max-width: 1000px) {
    flex-direction: column;
    width: 90%;
    margin-top: 285px;
  }
`;

export const LeftPanel = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const LeftImagePanel = styled.img`
  width: 60%;
  margin-top: -30px;
  @media (max-width: 1000px) {
    width: 65%;
    margin-top: -250px;
  }
`;

export const RightPanel = styled.div`
  width: 50%;
  max-width: 481px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 90%;
    margin-top: 24px;
    margin-bottom: 100px;
    align-items: center;
    justify-content: center;
  }
`;

export const CentralizeRightPanel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const TextPanel = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  padding-bottom: 32px;
  @media (max-width: 1000px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const TextMidway = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: start;
  padding-bottom: 24px;
  padding-top: 32px;
`;

export const GroupLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
`;

export const GroupColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  @media (max-width: 1000px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Agroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
`;

export const ContentText = styled.div`
  width: 90%;
  max-width: 1180px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 152px;
  @media (max-width: 1000px) {
    margin-top: 57px;
  }
`;

export const TextContent = styled.p`
  font-size: 20px;
  color: #1f2b2a;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-bottom: 16px;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const UnderlineText = styled.span`
  text-decoration: underline;
`;
export const ContainerCardBlackBottom = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background-color: #1f2b2a;
  width: 90%;
  max-width: 1180px;
  @media (max-width: 1000px) {
    margin-top: 41px;
  }
`;

export const CardBlackBottomTitle = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #ffff;
  padding-bottom: 16px;
  @media (max-width: 1000px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const CardBlackBottomText = styled.p`
  font-family: Montserrat;
  color: #ffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  padding-bottom: ${props => props.paddingBottom || '16'};
  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const ContentZurich = styled.div`
  margin-top: 80px;
  margin-bottom: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 90%;
  max-width: 1180px;

  @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 51px;
    margin-top: 56px;
  }
`;

export const ZurichLeft = styled.img`
  max-width: 139px;
  max-height: 87px;
  margin-right: 40px;

  @media (max-width: 1000px) {
    margin-right: 0px;
  }
`;

export const ZurichRight = styled.div`
  max-width: 622px;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Line = styled.div`
  height: 140px;
  border: 1px solid #959d9d;
  margin-bottom: 10px;
  margin-right: 40px;

  @media (max-width: 1000px) {
    width: 100%;
    height: 1px;
    margin-bottom: 0px;
    margin: 24px 0px 24px 0px;
  }
`;

export const TextZurich = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ContentFinal = styled.div`
  max-height: 120px;
  margin-bottom: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 90%;
  max-width: 1180px;
  color: #242424;
  @media (max-width: 1000px) {
    max-height: 460px;
    margin-bottom: 76px;
  }
`;

export const ContentTextFinal = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  @media (max-width: 1000px) {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StyleAnchor = styled.a`
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
  font-size: '16px';
`;

import styled from 'styled-components';
import Background from '~/assets/img/Financial/BilletInvoiceOrLendingV2/background.png';

export const Container = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 12px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 48px;
  }

  @media (min-width: 720px) {
    padding: 48px;
  }
`;

export const LeftContainer = styled.div`
  flex: 1;
`;

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
`;

export const Text = styled.p`
  padding: 24px 0;
  width: 60%;
  text-align: center;
  color: #1f2b2a;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
`;

export const Button = styled.button`
  padding: 16px;
  border: 0;
  border-radius: 24px;
  color: #00726d;
  font-weight: bold;
  transition: background 0.3s ease 0s;

  :hover {
    background: #d6fffe;
  }
`;

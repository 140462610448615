import instance from './OAFInstance';

const getToken = async () => {
  const uri = `${process.env.REACT_APP_APPLICATION_ID}/get-token`;

  const body = {
    value: JSON.stringify(process.env.REACT_APP_OAF_BODY),
  };

  return instance
    .post(uri, body)
    .then(response => {
      return response.data.IdToken;
    })
    .catch(() => {
      return null;
    });
};

const getTokenEmp = async () => {
  const uri = `${process.env.REACT_APP_EMP_WEB_APLICATION_ID}/get-token`;

  const body = {
    value: JSON.stringify(process.env.REACT_APP_EMP_WEB_OAF_BODY),
  };

  return instance
    .post(uri, body)
    .then(response => {
      return response.data.IdToken;
    })
    .catch(() => {
      return null;
    });
};

export default { getToken, getTokenEmp };

import {
  SHOW_DRAWER,
  HIDE_DRAWER,
  SHOW_MODAL_PL,
  HIDE_MODAL_PL,
  SHOW_MODAL_CREDIT,
  HIDE_MODAL_CREDIT,
} from './patterns';

export function showDrawer() {
  return {
    type: SHOW_DRAWER,
  };
}

export function hideDrawer() {
  return {
    type: HIDE_DRAWER,
  };
}

export function showModalPL() {
  return {
    type: SHOW_MODAL_PL,
  };
}

export function hideModalPL() {
  return {
    type: HIDE_MODAL_PL,
  };
}

export function showModalCredit() {
  return {
    type: SHOW_MODAL_CREDIT,
  };
}

export function hideModalCredit() {
  return {
    type: HIDE_MODAL_CREDIT,
  };
}

import React from 'react';
import { IconWorld } from '@tabler/icons-react';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';

const GlobalAssistance = () => {
  const buttonClickHandler = () => {
    trackingSelectContent('saiba-mais-sobre-a-assistencia', 'beneficios');
    window.open(
      'https://surpreenda.naotempreco.com.br/beneficios/global-service/',
      '_blank'
    );
  };

  return (
    <S.Container>
      <S.TitleWrapper>
        <IconWorld />
        <S.Title>Assistência global para o seu cartão</S.Title>
      </S.TitleWrapper>
      <S.Text>
        Com atendimento 24 horas e em qualquer idioma oferecendo serviço de
        orientação e emergência para casos de perda e roubo de cartão, início
        das solicitações de seguros, entre outros serviços.
      </S.Text>
      <S.Button onClick={buttonClickHandler}>
        Saiba mais sobre a assistência
      </S.Button>
    </S.Container>
  );
};

export default GlobalAssistance;

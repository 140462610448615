import React, { useEffect, useState } from 'react';
import { Image } from '@midway/web-ui-component';
import { Link } from '~/components/AccessibleLink/styles';
import Banner from '~/assets/img/compra-protegida/banner.png';
import iconBaggage from '~/assets/img/compra-protegida/icon-mala.svg';
import iconCross from '~/assets/img/compra-protegida/icon-cross.svg';
import iconAuto from '~/assets/img/compra-protegida/icon-auto.svg';
import ButtonFull from '~/assets/img/compra-protegida/button-full.svg';
import Zurich from '~/assets/img/compra-protegida/logo-zurich.svg';
import * as S from './styles';

export default function ProtectedPurchase() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 767);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <>
      <S.Container id="ready-page-for-screen-reader">
        <div className="banner">
          <Image
            src={!isMobile ? Banner : Banner}
            alt="Imagem com uma mulher sorrindo olhando diretamente para a câmera."
            aria-hidden="true"
          />
          <div className="banner-text">
            <p className="title">PROTEÇÃO PARA O DIA A DIA.</p>
            <p className="subtitle">
              Contrate o Seguro COMPRA
              <br />
              PROTEGIDA
            </p>
          </div>
        </div>
      </S.Container>
      <S.Session className="container">
        <h2>Seguro Compra protegida</h2>
        <p>
          Em caso de perda de renda, você poderá receber um valor de R$ 300,00,
          além de contar com uma assistência para a recolocação profissional, e
          ainda concorrer a sorteios semanais!
        </p>

        <div className="quickAccess">
          <div className="card">
            <h3>
              <Image
                src={iconBaggage}
                alt="Imagem com uma mulher sorrindo olhando diretamente para a câmera."
                aria-hidden="true"
              />
              Indenização em caso de perda de renda
            </h3>
            <p>
              Coberturas para Desemprego involuntário, Incapacidade física total
              e temporária e Diária de internação hospitalar*.
            </p>
          </div>
          <div className="card">
            <h3>
              <Image
                src={iconCross}
                alt="Imagem com uma mulher sorrindo olhando diretamente para a câmera."
                aria-hidden="true"
              />
              Assistência recolocação profissional
            </h3>
            <p>
              Uma prestação de serviço que te auxiliará na organização da sua
              vida profissional.
            </p>
          </div>
          <div className="card">
            <h3>
              <Image
                src={iconAuto}
                alt="Imagem com uma mulher sorrindo olhando diretamente para a câmera."
                aria-hidden="true"
              />
              Sorteio semanal de R$ 2.500,00
            </h3>
            <p>
              1 sorteio semanal no valor líquido de R$ 2.500, através da Loteria
              Federal, durante a vigência do produto.
            </p>
          </div>
        </div>

        <p className="link">
          <Link
            src="https://www.midway.com.br/contrato/Resumo-Condicoes-Gerais-Bolsa_Protegida"
            target="_blank"
          >
            Condições gerais do Seguro Compra Protegida
          </Link>
        </p>

        <div className="barlink-session">
          <div className="linkrow">
            <S.BarLink
              onClick={() =>
                window.open(
                  'https://www.odontoprev.com.br/redecredenciada/selecaoProduto?cdMarca=1',
                  '_blank'
                )
              }
              data-gtm-event-action="clique:botao"
              data-gtm-event-label="atendimento-odontoprev"
            >
              <span>Central de atendimento Zurich</span>
              <Image src={ButtonFull} alt="seta" aria-hidden="true" />
            </S.BarLink>
            <S.BarLink
              onClick={() =>
                window.open(
                  'https://www.odontoprev.com.br/redecredenciada/selecaoProduto?cdMarca=1',
                  '_blank'
                )
              }
              data-gtm-event-action="clique:botao"
              data-gtm-event-label="atendimento-odontoprev"
            >
              <span>Fale com a Laiz - Assistente virtual Zurich</span>
              <Image src={ButtonFull} alt="seta" aria-hidden="true" />
            </S.BarLink>
          </div>
          <div className="linkrow">
            <S.BarLink
              onClick={() =>
                window.open(
                  'https://www.odontoprev.com.br/redecredenciada/selecaoProduto?cdMarca=1',
                  '_blank'
                )
              }
              data-gtm-event-action="clique:botao"
              data-gtm-event-label="atendimento-odontoprev"
            >
              <span>Consultar número da sorte</span>
              <Image src={ButtonFull} alt="seta" aria-hidden="true" />
            </S.BarLink>
          </div>
        </div>
      </S.Session>
      <S.GreySession className="container">
        <h4>Para utilizar o Seguro</h4>
        <p>
          Ligue para Central de Atendimento Zurich <br />
          Horário de Atendimento: Segunda a Sexta-feira, das 8h ás 20h e aos
          Sábados das 8h ás 18h, exceto feriados.
        </p>

        <p>
          <span>4020 8986</span> (capitais e regiões metropolitanas)
          <br />
          <span>0800 085 8986</span> (demais localidades)
          <br />
          SAC: <span>0800 284 4848</span>
          <br />
          Ouvidoria: <span>0800 770 1061</span>
          <br />
          Deficiente auditivo: <span>0800 275 8585</span>. Horário de
          atendimento: 24 horas, 7 dias na semana.
        </p>
        <p>
          Central de atendimento Midway
          <br />
          3004 5417 (capitais e regiões metropolitanas)
          <br />
          0800 727 4417 (demais localidades)
          <br />
          SAC: 0800 721 3344
          <br />
          Ouvidoria Midway: 0800 727 3255
        </p>
      </S.GreySession>

      <S.Session className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 odontoprevImg">
            <Image src={Zurich} alt="Logo Zurich" aria-hidden="true" />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7 col-xl-7 odontoprevTxt">
            <p>
              Fundada na Suíça em 1872, com sede na cidade de Zurique, somos uma
              das seguradoras globais mais experientes do mundo. Com cerca de 56
              mil funcionários, oferecemos uma ampla gama de produtos e serviços
              de ramos elementares e de vida em mais de 210 países e
              territórios. Atendemos pessoas físicas e jurídicas – de pequenas
              empresas a multinacionais.
            </p>
          </div>
        </div>
      </S.Session>
      <S.Session className="container">
        <div className="minor">
          <p>
            *Consultar condições gerais para coberturas por categoria
            profissional
          </p>

          <p>
            Sobre a elegibilidade do seguro, todos são elegíveis ao seguro,
            exceto aposentados por invalidez e que não exercem atividade
            laborativa e funcionários públicos com instabilidade de emprego.
          </p>
        </div>

        <p>
          Seguro Compra Protegida garantido pela Zurich Minas Brasil Seguros S/A
          – CNPJ 17.197.385/0001-21 – Código Susep: 05495 e Processo Susep Perda
          de Renda 15414.900539/2016-41. A Zurich Minas Brasil Seguros S/A é
          proprietária de Títulos de Capitalização da modalidade incentivo,
          emitidos e administrados pela Zurich Brasil Capitalização S/A, CNPJ nº
          17.266.009/0001-41, processo SUSEP CAP: 15414.901583/2018-31. A
          aprovação deste título pela SUSEP não implica, por parte da Autarquia,
          em incentivo ou recomendação a sua aquisição, representando
          exclusivamente, sua adequação as normas em vigor. Regulamento da
          promoção comercial de capitalização disponível no site:
          www.segurocompraprotegida.com.br. O registro do produto é automático e
          não representa aprovação ou recomendação por parte da Susep. Central
          de atendimento Zurich: SAC: 0800 284 4848, Deficiente Auditivo: 0800
          275 8585, Horário de atendimento: 24 horas, 7 dias na semana;
          Ouvidoria: 0800 770 1061, Horário de atendimento: segunda a
          sexta-feira, das 8h30 às 18h, exceto feriados. Link da plataforma
          digital oficial para registro de reclamações dos consumidores dos
          mercados supervisionados www.consumidor.gov.br.{' '}
        </p>
      </S.Session>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1176px;
  padding-bottom: 10px;
  display: flex;
`;

export const Card = styled.div`
  max-width: 281px;
  min-width: 200px;
  min-height: 292px;
  max-height: 300px;
  margin: 0px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4.01px 6.01px -1px #1f2b2a1f;

  @media (max-width: 1000px) {
    max-width: 200px;
  }
`;

export const Image = styled.img`
  width: 281px;
  @media (max-width: 1000px) {
    width: 200px;
  }
`;

export const GroupsContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 18px;
`;

export const LineCards = styled.div`
  width: 37px;
  border: 1px solid #a06d2c;
  margin-top: 12px;
  margin-bottom: 16px;
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
`;

import styled from 'styled-components';

export const ContentPhone = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-top: 16px;
`;

export const Text = styled.p`
  font-size: 20px;
  color: #1f2b2a;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const UnderlineText = styled.span`
  text-decoration: underline;
`;

import React from 'react';
import * as S from './styles';

const TextPhoneNumber = ({ text, phoneNumber }) => {
  const handlePhoneNumberClick = () => {
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(
      navigator.userAgent
    );
    if (isMobileDevice) {
      window.location.href = `tel:${phoneNumber}`;
    }
  };

  return (
    <S.ContentPhone>
      <S.Text>{text}</S.Text>
      <S.Text onClick={handlePhoneNumberClick}>
        <S.UnderlineText>{phoneNumber}</S.UnderlineText>
      </S.Text>
    </S.ContentPhone>
  );
};

export default TextPhoneNumber;

import styled from 'styled-components';

export const ContainerAccordion = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  margin-top: 56px;
  @media (max-width: 1000px) {
    margin-top: 32px;
  }
`;

export const SpadeAccordion = styled.div`
  width: 100%;
  max-width: 1176px;
`;

export const HandleAccordion = styled.div``;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  width: 100%;
  margin: 0 auto;
`;

// BANNER
export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f1ebdf;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  max-width: 2000px;
`;

const Banner = styled.img`
  width: 100%;
  object-fit: cover;
  justify-content: center;
`;

export const BannerDesktop = styled(Banner)`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const BannerMobile = styled(Banner)`
  @media (min-width: 800px) {
    display: none;
  }
`;
// BANNER

// STEPS
export const StepsSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
`;

export const StepsLeftSection = styled.div`
  padding-top: 88px;

  @media (max-width: 800px) {
    padding-top: 24px;
  }
`;

export const StepsRightSection = styled.div`
  padding-top: 88px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const StepsTexts = styled.div`
  width: 100%;
  max-width: 1180px;

  @media (max-width: 800px) {
    padding-top: 16px;
  }
`;

export const StepsTitle = styled.p`
  font-weight: 500;
  line-height: 24px;
  font-size: 20px;
  padding-bottom: 8px;
  font-family: Montserrat;
  color: #1f2b2a;
`;

export const StepsSubTitle = styled.p`
  font-weight: 400;
  line-height: 20px;
  font-size: 16px;
  padding-top: 8px;
  font-family: Montserrat;
  color: #1f2b2a;

  @media (max-width: 800px) {
    padding-top: 16px;
  }
`;

export const StepsDesktop = styled.img`
  width: 100%;
  max-width: 1180px;
  object-fit: cover;
  padding-top: 54px;
  padding-bottom: 28px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const StepsMobile = styled.img`
  width: 100%;
  object-fit: cover;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
  padding-bottom: 20px;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const StepsImg = styled.img`
  width: 150px;
  height: 320px;
  object-fit: cover;
  padding-top: 20px;
`;
// STEPS

// PAYMENT STEPS
export const PaymentStepsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 48px;
  background-color: #f7f7f7;
`;

export const PaymentStepsTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const PaymentStepsTitle = styled.p`
  font-weight: 500;
  line-height: 40px;
  font-size: 32px;
  padding-top: 24px;
  font-family: Montserrat;
  color: #1f2b2a;

  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const PaymentStepsSubTitle = styled.p`
  font-weight: 400;
  line-height: 24px;
  font-size: 20px;
  font-family: Montserrat;
  color: #1f2b2a;

  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const PaymentStepsText = styled.p`
  font-weight: 400;
  line-height: 22px;
  font-size: 18px;
  padding-top: 8px;
  font-family: Montserrat;
  color: #1f2b2a;

  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const PaymentStepsImg = styled.img`
  width: 100%;
  max-width: 568px;
  object-fit: cover;
  padding-top: 48px;
  padding-bottom: 48px;
`;

export const CicleCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 164px;
  margin-bottom: -82px;
  background-color: #f7f7f7;

  @media (max-width: 800px) {
    height: 294px;
    margin-bottom: -147px;
  }
`;

export const CicleCardContent = styled.div`
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 778px;
  padding-left: 48px;
  padding-right: 48px;
  border-radius: 32px;
  background-color: #e4602f;

  @media (max-width: 800px) {
    justify-content: center;
    flex-direction: column;
    padding-left: 28px;
    padding-right: 28px;
    max-width: 328px;
  }
`;

export const CicleText = styled.div`
  width: 50%;

  @media (max-width: 800px) {
    text-align: center;
    width: 100%;
    padding-bottom: 38px;
  }
`;

export const CicleTitle = styled.p`
  font-weight: 600;
  line-height: 30px;
  font-size: 24px;
  color: white;
  font-family: Montserrat;

  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const CicleSubTitle = styled.p`
  font-weight: 500;
  line-height: 22px;
  font-size: 18px;
  color: white;
  font-family: Montserrat;
  padding-top: 16px;

  @media (max-width: 800px) {
    padding-top: 8px;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CicleImg = styled.img`
  width: 100%;
  max-width: 220px;
  object-fit: cover;
`;

export const CicleDesktop = styled(CicleImg)`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const CicleMobile = styled(CicleImg)`
  @media (min-width: 800px) {
    display: none;
  }
`;
// PAYMENT STEPS

// HOW TO
export const HowToSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: ${144 + 82}px;
  padding-bottom: 144px;
  background-color: white;

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    padding-top: ${60 + 150}px;
    padding-bottom: 60px;
  }
`;

export const HowToPanel = styled.div`
  background-color: #f7f7f7;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 620px;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 48px;
  padding-bottom: 48px;

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 20px;
    margin-bottom: 32px;
  }
`;

export const ContentPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const Device = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  height: 305px;
  margin-top: 16px;

  @media (max-width: 1280px) {
    margin-top: 32px;
  }
`;

export const DeviceImage = styled.img`
  position: absolute;
  width: 169px;
  height: 285px;
`;

export const DeviceVideo = styled.video`
  width: 156px;
  border-radius: 22px;
`;

export const HowToTexts = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const HowToTitle = styled.p`
  font-weight: 600;
  color: #1f2b2a;
  line-height: 28px;
  font-size: 24px;
  font-family: Montserrat;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

export const HowToText = styled.p`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: ${props => props.fontWeight || '400'};
  line-height: 20px;
  color: #1f2b2a;
  font-family: Montserrat;
  padding-top: 16px;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 0)};
`;
// HOW TO

// OUR BENEFITS
export const OurBenefits = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #221f20;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const OurBenefitsWrapper = styled.div`
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: center;
`;

export const OurBenefitsContainer = styled.div`
  z-index: 10;
  display: flex;
  width: 100%;
  max-width: 1180px;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const OurBenefitsContent = styled.div`
  margin-bottom: 32px;
  margin-top: 32px;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 800px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const OurBenefitsModelWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1480px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const OurBenefitsModel = styled.img`
  position: relative;
  width: 100%;
  max-width: 843px;
  height: 460px;
  object-fit: cover;
`;

export const OurBenefitsTitle = styled.p`
  font-weight: 600;
  color: white;
  line-height: 28px;
  font-size: 24px;
  font-family: Montserrat;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`;

export const OurBenefitsSubTitle = styled.p`
  font-weight: 400;
  color: white;
  line-height: 20px;
  font-size: 16px;
  font-family: Montserrat;
  padding-top: 16px;
`;

export const OurBenefitsImgWapper = styled.div`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const OurBenefitsImg = styled.img`
  width: 100%;
  max-width: 436px;
  object-fit: cover;
  padding-top: 48px;
  padding-bottom: 48px;
`;

export const OurBenefitsDesktop = styled.img`
  width: 100%;
  max-width: 334px;
  object-fit: cover;
  padding-bottom: 48px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const OurBenefitsMobile = styled.img`
  width: 100%;
  max-width: 225px;
  object-fit: cover;
  padding-bottom: 48px;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const OurBenefitsButton = styled.button`
  background-color: white;
  width: 314px;
  height: 40px;
  border-radius: 120px;
  border: 0px;
  transition: backgroud-color 0.5s ease;
  cursor: pointer;
  color: #1f2b2a;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 800px) {
    width: 100%;
    max-width: 338px;
  }
`;
// OUR BENEFITS

// QUESTIONS
export const Questions = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1180px;
  padding-top: 80px;
  padding-bottom: 64px;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 800px) {
    padding-top: 32px;
    padding-bottom: 48px;
  }
`;

export const QuestionsTitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  font-weight: 40px;
  font-family: Montserrat;
  color: #221f20;
  padding-bottom: 32px;

  @media (max-width: 800px) {
    font-size: 20px;
    padding-bottom: 16px;
  }
`;

export const HandleShow = styled.div``;
// QUESTIONS

import React from 'react';
import PropTypes from 'prop-types';
import { trackingSelectContent } from '~/analytics';
import * as S from './style';
import { Card, Typography } from '../../NewUi';

function formatStringToButtonStyle(input) {
  const formattedString = input.toLowerCase().replace(/\s+/g, '-');
  return `${formattedString}`;
}

const RenderButton = ({ link, actionText, action }) => {
  const handleAction = () => {
    if (action) return action;
    trackingSelectContent(
      `produtos-midway:${formatStringToButtonStyle(actionText)}`,
      `home`
    );
  };
  if (link) {
    return (
      <a href={link} tabIndex={-1} aria-label={`${actionText}`}>
        <S.ActionButton
          tabIndex={0}
          role="button"
          aria-label={`${actionText}`}
          onClick={handleAction}
        >
          {actionText}
        </S.ActionButton>
      </a>
    );
  }

  return (
    <S.ActionButton
      tabIndex={0}
      role="button"
      aria-label={`${actionText}`}
      onClick={action}
    >
      {actionText}
    </S.ActionButton>
  );
};

const CardProduct = ({
  icon,
  title,
  description,
  actionText,
  action = null,
  link,
}) => {
  return (
    <Card aria-label={`Card Produto ${title}`}>
      <S.CardContent>
        <S.IconContainer>{icon}</S.IconContainer>
        <S.Title>
          <Typography styleType="cardTitle" variant="h3">
            {title}
          </Typography>
        </S.Title>
        <S.Description arial-aria-label="descrição">
          {description}
        </S.Description>
        <RenderButton link={link} actionText={actionText} action={action} />
      </S.CardContent>
    </Card>
  );
};

export default CardProduct;

CardProduct.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  action: PropTypes.func,
};

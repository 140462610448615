import styled from 'styled-components';

export const ProductsAndServices = styled.div`
  padding: 40px 0;
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  .item {
    width: 500px;
    height: 200px;

    img {
      width: 56px;
      height: 56px;
    }
    .title {
      font-weight: 700;
      font-size: 18px !important;
      padding: 12px 0;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      padding-top: 0;
      max-width: 240px;
    }
  }

  .BrainhubCarousel__trackContainer {
    padding: 0 12px;
  }

  .BrainhubCarousel__dots {
    align-items: center;

    .BrainhubCarousel__dot {
      background: transparent;

      &::before {
        width: 8px;
        height: 8px;
      }

      &.BrainhubCarousel__dot--selected::before {
        background: #9efaf6;
        width: 12px;
        height: 12px;
      }
    }
  }

  .BrainhubCarousel__arrows {
    background: #e5fffe;
    border-radius: 50px;

    &:hover,
    :enabled,
    :hover:enabled {
      background: #e5fffe;
    }

    span {
      border-color: #004d49;

      &:hover,
      :enabled,
      :hover:enabled {
        border-color: #004d49 !important;
      }
    }
  }

  .BrainhubCarousel__arrowLeft {
    position: absolute;
    bottom: 35px;
    left: 10px;

    &:hover,
    :enabled,
    :hover:enabled span {
      margin-left: 2.45px;
    }
  }

  .BrainhubCarousel__arrowRight {
    position: absolute;
    bottom: 35px;
    right: 10px;

    &:hover,
    :enabled,
    :hover:enabled span {
      margin-left: -2.45px;
    }
  }
`;

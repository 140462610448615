import styled from 'styled-components';

export const Container = styled.div`
  min-width: 320px;
  max-width: 320px;
  display: flex;

  @media (max-width: 1000px) {
    min-width: 300px;
    max-width: 300px;
  }
`;

export const Card = styled.div`
  min-width: 280px;
  max-width: 280px;
  height: 100%;
  padding: 24px;
  border: 1.41px solid #1f2b2a;
  border-radius: 24px;
  background-color: #fff;
  padding-bottom: 12px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-family: Montserrat;
  line-height: 32px;
  font-weight: bold;
  padding-bottom: 16px;
`;

export const Description = styled.p`
  font-size: 18px;
`;

import axios from 'axios';

const URL = process.env.REACT_APP_MIDWAY_URL;

export const getLuckyNumbers = async (year, month) => {
  return axios.get(
    `${URL}/financial-products/v1/lucky-numbers/winners?year=${year}&month=${month}`
  );
};

export const getLastLuckyNumbers = async () => {
  return axios.get(`${URL}/financial-products/v1/lucky-numbers/winners/latest`);
};

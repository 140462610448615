import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1173px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 16px;

  @media screen and (max-width: 1219px) {
    margin-bottom: 24px;
  }
`;

export const Centralize = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 24px 40px 24px;

  @media (max-width: 1219px) {
    padding: 24px, 16px, 24px, 16px;
  }
`;

export const Agroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 381px;
  max-height: 212px;
  border-radius: 16px;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 6px -1px #1f2b2a1f;

  @media (max-width: 1219px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Icon = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 16px;
`;

export const Title = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;

  @media (max-width: 1219px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-top: 32px;

  @media (max-width: 1219px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

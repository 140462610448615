import React from 'react';
import history from '~/services/history';
import * as S from './styles';

const StageButtons = props => {
  const { step, onPrev, onNext, onNew, nextStepEnabled } = props;
  const canShowDefault = ![1, 2, 4, 7].includes(step);
  return (
    <S.WrapperButton>
      {step === 1 && (
        <div className="align-center">
          <S.Button onClick={onNext}>Simular Empréstimo</S.Button>
        </div>
      )}
      {step === 2 && (
        <div className="align-right">
          <S.Button disabled={!nextStepEnabled} onClick={onNext}>
            Continuar
          </S.Button>
        </div>
      )}

      {step === 4 && (
        <>
          <div className="align-left">
            <S.ButtonBack onClick={onPrev}>Voltar</S.ButtonBack>
          </div>
          <div className="align-right">
            <S.ButtonContinue onClick={onNext} disabled={!nextStepEnabled}>
              Continuar
            </S.ButtonContinue>
          </div>
        </>
      )}

      {step === 7 && (
        <>
          <S.ButtonBack onClick={onNew}>Nova simulação</S.ButtonBack>
          <S.Button onClick={() => history.push('/emprestimos/contrate')}>
            Continuar
          </S.Button>
        </>
      )}
      {canShowDefault && (
        <>
          <S.ButtonBack onClick={onPrev}>Voltar</S.ButtonBack>
          <S.Button onClick={onNext} disabled={!nextStepEnabled}>
            Continuar
          </S.Button>
        </>
      )}
    </S.WrapperButton>
  );
};
export default StageButtons;

import React from 'react';
import { IconX } from '@tabler/icons-react';
import * as S from './styles';

const Modal = ({ children, isOpen, onClose, title }) => {
  if (!isOpen) return null;

  return (
    <S.Overlay>
      <S.ModalContainer onClick={e => e.stopPropagation()}>
        <S.BottomSheetRetangle />
        <S.TitleContainer>
          <S.Title>{title}</S.Title>
          <S.CloseButton onClick={onClose}>
            <IconX />
          </S.CloseButton>
        </S.TitleContainer>
        {children}
      </S.ModalContainer>
    </S.Overlay>
  );
};

export default Modal;

import WrapperBase from '../../components/Wrapper';
import { breakpoints } from '../../utils';
import { colors } from '~/constants';
import styled from 'styled-components';

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const CategoryTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 1.5rem;
  }
`;

export const CategoryTitle = styled.strong`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const WrapperContacts = styled(WrapperBase)`
  background: ${colors.white};
  padding-bottom: 5rem;

  @media (${breakpoints[1176]}) {
    background: none;
    padding-top: 0;
  }
`;

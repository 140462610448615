import { v4 as uuidv4 } from 'uuid';
import OAFApi from './OAFApi';
import { instance, instanceMidway } from './LoanApiInstance';

const getLimit = async ({ documentNumber }) => {
  const OAFToken = await OAFApi.getToken();

  if (!OAFToken) {
    return null;
  }

  const uri = `/consolidado/cpf/${documentNumber}/limites`;
  const headers = {
    channel: 'WEB',
    'x-app-token': OAFToken,
  };

  return instance
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

const getMinimumValues = async () => {
  const OAFToken = await OAFApi.getToken();

  if (!OAFToken) {
    return null;
  }

  const uri = '/produtos/1/valores-minimo';
  const headers = {
    channel: 'WEB',
    'x-app-token': OAFToken,
  };

  return instance
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

const getSimulation = async ({
  productCode,
  loanAmount,
  planCode,
  numberInstallments,
  userCode,
}) => {
  const OAFToken = await OAFApi.getToken();

  if (!OAFToken) {
    return null;
  }

  const uri =
    '/empresas/2/filiais/37/clientes/812006/sistemas/1/canais/1/simulacoes';
  const headers = {
    channel: 'WEB',
    'x-app-token': OAFToken,
  };

  const body = {
    codigoProduto: productCode,
    valorEmprestimo: loanAmount,
    codigoPlano: planCode,
    quantidadeParcelas: numberInstallments,
    codigoUsuario: userCode,
  };

  return instance
    .post(uri, { body }, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

// API QUE RETORNA A PARTIR DO CPF DO CLIENTE OS DADOS PARA O PROSSEGUIMENTO DO EMPRÉSTIMO
const getLimitCPF = async ({ documentNumber }) => {
  const OAFToken = await OAFApi.getTokenEmp();

  if (!OAFToken) {
    return null;
  }

  const uri = `/borrower/credit-limit/cpf/${documentNumber}`;
  const headers = {
    channel: 'WEB',
    'x-app-token': OAFToken,
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

// API DE SIMULAÇÃO DE EMPRÉSTIMO PARA O CARTONISTA
const getSimulationLoan = async ({ cpf, valorAFinanciar }) => {
  const uri = '/simulated-detailed/installments';
  const headers = {
    channel: 'WEB',
  };

  const body = {
    codigoProduto: 1,
    cpf,
    valorAFinanciar,
  };

  return instanceMidway
    .post(uri, body, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

// API QUE BUSCA A VALIDAÇÃO DO CPF COM O RECAPTCHA ATIVO
const getLimitCPFCaptcha = async ({ cpf, token }) => {
  const uri = `/recaptcha-valid/cliente/limite-credito/cpf/${cpf}`;
  const headers = {
    channel: 'WEB',
    'c-token': token,
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

// API QUE VALIDA O CLIENTE
const getValidateClient = async ({
  cpf,
  nome,
  dataDeAniversario,
  nacionalidade,
  genero,
  celular,
  email,
  oferta,
}) => {
  const uri = `/loan-simulation-borrower/clientWeb`;
  const headers = {
    channel: 'WEB',
  };

  const body = {
    cpf,
    nome,
    dataDeAniversario,
    nacionalidade,
    genero,
    celular,
    email,
    oferta,
    isClient: true,
    creditLimit: 0,
  };

  return instanceMidway
    .post(uri, body, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

// API QUE SALVA OS DADOS DO CLIENTE
const saveDataLoanSimulation = async ({
  cpf,
  codigoPlano,
  carencia,
  quantidadePrestacoes,
  valorPrestacao,
  valorTotal,
  dataPrimeiraPrestacao,
  taxaJuros,
  taxaTac,
}) => {
  const uri = `/loan-simulation-borrower/clientWeb/simulation`;
  const headers = {
    channel: 'WEB',
  };

  const body = {
    cpf,
    codigoPlano,
    carencia,
    quantidadePrestacoes,
    valorPrestacao,
    valorTotal,
    dataPrimeiraPrestacao,
    taxaJuros,
    taxaTac,
  };

  return instanceMidway
    .post(uri, body, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

const getCustomerLimit = async ({ documentNumber = '', token }) => {
  const uri = '/channel-site-loan/v1/loan/customer/limit';
  const headers = {
    'document-number': documentNumber,
    'x-midway-transaction-id': uuidv4(),
    'c-token': token,
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

const getCustomerLimitValidation = async ({
  documentNumber = '',
  token,
  firstName = '',
  birthDate = '',
}) => {
  const uri = `/recaptcha-valid/cliente/limite-credito/cpf/${documentNumber}/nome/${firstName}/dataNascimento/${birthDate}`;
  const env = process.env.REACT_APP_ENV;
  const ctoken = env === 'uat' || env === 'dev' ? 'midway' : token;
  const headers = {
    'c-token': ctoken,
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

const getLoanSimulation = async data => {
  const uri = '/simulated-detailed/v1/api/simulate';

  const headers = {
    'x-midway-transaction-id': uuidv4(),
    produto: 'EMPRESTIMO_PESSOAL',
    dataPrimVencto: new Date(data.firstDueDate).toISOString(),
    financiaIof: 'SIM',
    valorContratado: data.contractedValue,
    Channel: 'MOBILE',
    perfil: data.profileCode,
    cpf: data.cpf,
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

const getDetailedLoanSimulation = async data => {
  const uri = '/simulated-detailed/v1/api/simulateDetail';
  const headers = {
    'x-midway-transaction-id': uuidv4(),
    produto: 'EMPRESTIMO_PESSOAL',
    dataPrimVencto: new Date(data.firstDueDate).toISOString(),
    financiaIof: 'SIM',
    valorContratado: data.contractedValue,
    Channel: 'MOBILE',
    qtdeParcelas: data.installments,
    perfil: data.profileCode,
    cpf: data.cpf,
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

const getLoanProfileRate = async data => {
  const uri = '/channel-site-loan/v1/loan/profile/rate';
  const headers = {
    'profile-code': data.profileCode,
    'x-midway-transaction-id': uuidv4(),
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

const getBilletLoan = async body => {
  const uri =
    '/loan-simulation-borrower/v1/api/bank-slip-online-account?ignorarErro=true'; // Prod
  // const uri = '/api-gateway/boleto/emprestimo/linha-digitavel?ignorarErro=true' // Test
  const headers = {
    Channel: 'MOBILE',
    'x-midway-transaction-id': uuidv4(),
  };

  return instanceMidway
    .post(uri, body, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

const getLoanInsurance = async (amount, categoryId, channelId) => {
  const env = process.env.REACT_APP_ENV;
  const sufix = env === 'uat' || env === 'dev' ? `-${env}` : '-prod';
  const uri = `https://apiman-marketplace-financial${sufix}.azure-api.net/sa/loan-insurance/plans/coverages?loanAmount=${amount}&categoryId=${categoryId}&channelId=${channelId}`;

  const headers = {
    Channel: 'MOBILE',
    'x-midway-transaction-id': uuidv4(),
  };

  return instanceMidway
    .get(uri, { headers })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

export default {
  getLimit,
  getMinimumValues,
  getSimulation,
  getLimitCPF,
  getSimulationLoan,
  getLimitCPFCaptcha,
  getValidateClient,
  saveDataLoanSimulation,
  getCustomerLimit,
  getCustomerLimitValidation,
  getLoanSimulation,
  getDetailedLoanSimulation,
  getLoanProfileRate,
  getBilletLoan,
  getLoanInsurance,
};

import React from 'react';
import { Icon } from '@midway/web-ui-component';
import { colors } from '~/constants';
import history from '~/services/history';
import * as S from './styles';

const Breadcrumb = () => {
  return (
    <S.ContainerBreadcrumb>
      <div className="breadcrumb-content">
        <div className="breadcrumb-item-mobile">
          <Icon
            icon={['fa', 'chevron-left']}
            size="1x"
            color={colors.breadcrumb_dark}
          />
          <div onClick={() => history.push('/emprestimos')}>
            <span>Empréstimos</span>
          </div>
        </div>
        <div className="breadcrumb-item">
          <div onClick={() => history.push('/')}>
            <span>Início</span>
            <Icon
              icon={['fa', 'chevron-right']}
              size="1x"
              color={colors.breadcrumb_dark}
            />
          </div>
          <div onClick={() => history.push('/produtos-financeiros')}>
            <span>Produtos Financeiros</span>
            <Icon
              icon={['fa', 'chevron-right']}
              size="1x"
              color={colors.breadcrumb_dark}
            />
          </div>
          <div id="1" onClick={() => history.push('/emprestimos')}>
            <span>Empréstimos</span>
            <Icon
              icon={['fa', 'chevron-right']}
              size="1x"
              color={colors.breadcrumb_dark}
            />
          </div>
          <span>Crédito Pessoal</span>
        </div>
      </div>
    </S.ContainerBreadcrumb>
  );
};

export default Breadcrumb;

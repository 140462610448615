export const banner = [
  {
    name: 'BannerLarProtegido',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-lar-protegido-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-lar-protegido.png',
    title:
      'Sua casa, nosso compromisso. Descubra as vantagens do Seguro LAR Protegido',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cardService from '~/services/CardApi';

const useGetUpsell = () => {
  const { toogles } = useSelector(state => state.featuretoggle);
  const [data, setData] = useState({ hasLimitPreApproved: false });

  const memoized = useCallback(
    params => {
      if (toogles.isEnabledUpsell) {
        cardService
          .getUpsell(params)
          .then(response => {
            setData({
              hasLimitPreApproved: response?.data?.hasLimitPreApproved,
            });
          })
          .catch(_ => {});
      }
    },
    [toogles.isEnabledUpsell]
  );

  return [memoized, { data }];
};

export default useGetUpsell;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Spinner } from '@midway/web-ui-component';
import LoanApi from '~/services/LoanApi';
import * as S from './styles';
import Fail from '../../../components/Fail';

const Installments = props => {
  const [installmentsList, setInstallmentsList] = useState([]);
  const [otherValue, setOther] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const maskCurrency = value =>
    new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(value);
  const getInstallmentValue = () => {
    const { installments } = props.form;
    const selectedInstallment = installmentsList.find(
      item => item?.qtdeParcelas === installments
    );

    return selectedInstallment?.valorParcelas || 0;
  };
  const onChange = installments => {
    if (props.form) {
      const minInstallments = installmentsList[0]?.qtdeParcelas || 0;
      const maxInstallments =
        installmentsList[installmentsList.length - 1]?.qtdeParcelas || 0;

      if (
        (installments < minInstallments || maxInstallments < installments) &&
        installments > 0
      )
        if (installments > 0) return;
    }
    const eventData = {
      target: {
        name: 'installments',
        value: installments,
      },
    };
    props.onChange(eventData);
  };

  const getSimulationInstallments = async () => {
    setLoading(true);
    const response = await LoanApi.getLoanSimulation({
      firstDueDate: moment(
        props.form.firstPaymentDate,
        'DD/MM/YYYY'
      ).toString(),
      contractedValue: props.form.loanValue,
      profileCode: props.customer?.profile,
      cpf: props.form.cpf.replace(/[^0-9]/g, ''),
    });

    if (response.data && !response.data.statusCode) {
      setInstallmentsList(response.data?.dadosSimulacoes || []);
      setError(false);
    }
    if (response.status !== 200) {
      setError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    getSimulationInstallments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.Forms>
        {loading ? (
          <S.WrapperSpinner>
            <Spinner animation="border" state="primary" />
          </S.WrapperSpinner>
        ) : !error ? (
          <>
            <h1>Em quantas vezes você quer pagar?</h1>
            <h2>
              Você selecionou:{' '}
              <strong>R$ {maskCurrency(props.form.loanValue)}</strong>{' '}
            </h2>
            <S.WrapperContent>
              <S.WrapperButton>
                {[4, 10, 18, 24, 36].map((item, key) => {
                  const maxInstallments =
                    installmentsList[installmentsList.length - 1]
                      ?.qtdeParcelas || 0;

                  if (
                    item === props.form.installments &&
                    otherValue === false
                  ) {
                    return (
                      <S.ButtonActive onClick={() => onChange(item)} key={key}>
                        {' '}
                        {item}x{' '}
                      </S.ButtonActive>
                    );
                  }
                  if (item > maxInstallments) {
                    return <S.ButtonDisable> {item}x </S.ButtonDisable>;
                  }
                  return (
                    <S.Button
                      onClick={() => {
                        onChange(item);
                        setOther(false);
                      }}
                      key={key}
                    >
                      {' '}
                      {item}x{' '}
                    </S.Button>
                  );
                })}
                {otherValue ? (
                  <S.ButtonActive>Outras opções</S.ButtonActive>
                ) : (
                  <S.Button onClick={() => setOther(true)}>
                    Outras opções
                  </S.Button>
                )}
              </S.WrapperButton>

              {otherValue ? (
                <S.InstallmentsValue>
                  <p>Valor das parcelas: </p>
                  <S.InstallmentSelect
                    className="floating-select no-border-focus"
                    onChange={event => {
                      onChange(event.target.value);
                    }}
                  >
                    {installmentsList.map((item, index) => (
                      <option key={index} value={item?.qtdeParcelas}>
                        {' '}
                        {item?.qtdeParcelas}x de R${' '}
                        {maskCurrency(item?.valorParcelas)}
                      </option>
                    ))}
                  </S.InstallmentSelect>
                </S.InstallmentsValue>
              ) : (
                <S.InstallmentsValue>
                  <p>Valor das parcelas: </p>
                  {props.form.installments}x de R${' '}
                  {maskCurrency(getInstallmentValue())}
                </S.InstallmentsValue>
              )}
            </S.WrapperContent>
          </>
        ) : (
          <Fail onTryAgainClick={getSimulationInstallments} />
        )}
      </S.Forms>
    </>
  );
};

export default Installments;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  padding: 0 !important;

  min-height: 450px;
`;

const inputStyles = css`
  input {
    width: 40% !important;
    font-size: 16px !important;
    text-align: center;
    margin-bottom: 25px !important;

    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 1px;

    :last-child {
      margin-top: 25px;
    }

    @media (max-width: 790px) {
      width: 60% !important;
    }

    @media (max-width: 490px) {
      width: 95% !important;
      text-align: start !important;
      padding-left: 10px;
    }
  }

  input::placeholder {
    color: #000000;
    font-size: 16px !important;
    font-weight: bold;
  }

  input.input-email {
    display: flex;
    position: relative;
  }

  div.input-email-text {
    color: red;
    text-align: center;
    font-size: 12px !important;
    margin-top: -26px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  min-height: 200px;
  max-height: 400px !important;
  padding: 0 !important;

  margin-bottom: 30px;

  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }

  div.ahl-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 23px;
    text-align: center;

    margin-bottom: 30px;

    @media (max-width: 886px) {
      width: 60%;
    }

    @media (max-width: 415px) {
      width: 90%;
    }
  }

  ${inputStyles};
`;

export const BirthDateInput = styled.input`
  ${inputStyles};

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  border: 1px solid grey;
  color: #000000;

  @media (max-width: 490px) {
    width: 95% !important;
    justify-content: flex-start !important;
    padding-left: 10px;
  }

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  background-color: #ffffff;

  ::before {
    ${props =>
      !props.value &&
      css`
        content: attr(placeholder) !important;
        font-weight: bold;
        width: 100%;
      `};
  }

  :focus:before {
    content: '' !important;
  }

  &:not(:focus) {
    ::-webkit-datetime-edit {
      ${props => !props.value && 'display: none;'}
    }
  }
`;

export const FooterButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 400px) {
    flex-direction: column;
  }

  div.ahl-button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @media (max-width: 400px) {
      width: 70%;
    }

    &:last-child {
      justify-content: flex-start;
      margin-left: 10px;

      @media (max-width: 400px) {
        justify-content: center;

        order: 1;
      }
    }

    &:first-child {
      justify-content: flex-end;

      @media (max-width: 400px) {
        justify-content: center;

        margin-top: 8px;

        order: 2;
      }
    }
  }
`;

export const flow = {
  limiteGarantido: 'limite-garantido',
};

export const contentPage = {};

export const contentType = {
  HowDoIGetTheLimit: 'faq:como-eu-faco-para-ter-o-limite?',
  CanTheCustomerDepositAnAmountAbove:
    'faq:o-cliente-pode-depositar-um-valor-acima-de-r$-333,33?',
  CanTheCustomerOpenTheAccountAndDepositALowerAmount:
    'faq:o-cliente-pode-abrir-a-conta-e-depositar-um-valor-inferior?',
  IfIRedeemTheAmountWillTheLimitOnThePrivateLabelCardRemain:
    'faq:se-eu-resgatar-o-valor-permaneco-com-o-limite-no-cartao-private-label?',
  DoesTheDepositedAmountYieldIncome: 'faq:o-valor-depositado-tem-rendimento?',
  WhereCanICheckTheBlockedAmount: 'faq:onde-consultar-o-valor-bloqueado?',
  EveryTimeIMakeADepositIntoMyMidwayAccount:
    'faq:toda-vez-que-eu-realizar-um-deposito-na-minha-conta-midway-terei-o-limite-de-1-5-no-cartao-riachuelo?',
  HowDoIPayTheInvoice: 'faq:como-realizo-o-pagamento-da-fatura?',
  DoIPayInterestIfIPayMyBillLate:
    'faq:pago-juros-se-atrasar-o-pagamento-da-fatura?',
  CanIWithdrawTheAmountIDeposited:
    'faq:posso-fazer-o-resgate-do-valor-que-depositei?',
  HowDoIRedeemTheBlockedAmount:
    'faq:Como-faco-para-resgatar-o-valor-bloqueado?',
  WhatIfTheInvoicePaymentIsNotMadeOnTheDueDate:
    'faq:se-o-pagamento-da-fatura-nao-ocorrer-na-data-de-vencimento?',
  CanIBeBlacklistedIfIDontPayTheBill:
    'faq:posso-ser-negativado-se-nao-pagar-a-fatura?',
};

import styled, { css } from 'styled-components';

export const ContainerFlow = styled.div`
  width: 100vw;
  height: max-content;
  min-height: 558px;

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.4%;
    }
  }

  .bold,
  strong,
  b {
    font-weight: bold !important;
  }
`;

export const ContainerContent = styled.div`
  padding: 28px 16px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      height: max-content;
    `};

  ${({ isMobile }) =>
    !isMobile &&
    css`
      width: 580px;
    `};

  ${({ mtop }) =>
    mtop &&
    css`
      margin-top: 2.2rem;
    `}
`;

import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;

  .faq {
    background-color: #fff;
    cursor: pointer;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.6s ease;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 30px;
  }

  .active {
    background-color: #00726d;
    color: #fff;
    border: none;
    border-radius: 10px 10px 0px 0px;
  }

  .faq-title {
    font-size: 14px/18px !important;
    text-align: left;
  }

  .faq-title-active {
    color: #fff;
    font-size: 14px/18px !important;
  }

  .faq-content {
    border-top: none !important;
    background-color: #f3f3f3;
    overflow: hidden;
    transition: max-height 0.6s ease;
    border-radius: 0px 0px 10px 10px;
  }

  .faq-text {
    padding: 18px 20px;
    font-size: 18px !important;
  }
`;

export const IconChevron = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;

  width: 48px;
  border: 2px solid #00726d;
  height: 48px;
  border-radius: 28px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 56px;
  height: 56px;
`;

export const Bold = styled.strong`
  font-weight: bold;
`;

export const Link = styled.strong`
  font-weight: bold;
  text-decoration: underline !important;
  color: #00726d;
  font-size: 18px;
`;

import styled from 'styled-components';

export const PageWrapper = styled.main`
  background-color: #fff;
`;

export const Sections = styled.div`
  // Adicione as propriedades do container do Bootstrap aqui
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  max-width: 1270px;
  margin-top: 80px;

  @media screen and (max-width: 768px) {
    margin-top: 60px;
  }

  @media screen and (max-width: 492px) {
    margin-top: 40px;
    padding-right: 8px;
    padding-left: 8px;
  }
`;

export const SectionsItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media screen and (max-width: 768px) {
    gap: 60px;
  }

  @media screen and (max-width: 492px) {
    gap: 40px;
  }
`;
export const SectionsTitle = styled.h2`
  font-size: 2.5rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 72px;

  @media screen and (max-width: 1024px) {
    font-size: 2.2rem !important;
    margin-bottom: 1em;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.5rem !important;
    margin-bottom: 1em;
  }

  @media screen and (max-width: 492px) {
    font-size: 1.2rem !important;
    margin-bottom: 1em;
  }
`;

import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AuthContext = createContext({});

const PERSISTENT_IDENTIFIER_KEY = '@midway/persistent_identifier';

const getPersistentIdentifier = () =>
  localStorage.getItem(PERSISTENT_IDENTIFIER_KEY);
const savePersistentIdentifier = value =>
  localStorage.setItem(PERSISTENT_IDENTIFIER_KEY, value);

const AuthProvider = ({ children }) => {
  const [documentNumber, setDocumentNumber] = useState();
  const [maskedPan, setMaskedPan] = useState();
  const [cardFlag, setCardFlag] = useState();
  const [nextDueDate, setNextDueDate] = useState();
  const [machineId, setMachineId] = useState(getPersistentIdentifier());

  useEffect(() => {
    if (!machineId) {
      const uuid = uuidv4();
      setMachineId(uuid);
      savePersistentIdentifier(uuid);
    }
  }, [machineId]);

  return (
    <AuthContext.Provider
      value={{
        documentNumber,
        maskedPan,
        cardFlag,
        nextDueDate,
        setDocumentNumber,
        setMaskedPan,
        setCardFlag,
        setNextDueDate,
        machineId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };

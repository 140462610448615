import {
  ContactsContainer,
  FeedbackContainer,
  IconSVG,
  InnerHtml,
  SubjectItem,
  WasThisHelpfulContainer,
  WasThisHelpfulMessageContainer,
  WasThisHelpfulOption,
  WasThisHelpfulOptions,
  WasThisHelpfulTitle,
  WhatsAppButtonContainer,
  WhatsappButton,
} from './style';

import { IconBrandWhatsapp } from '@tabler/icons-react';
import arrowHook from '~/assets/img/attendance-portal/arrow-hook.svg';
import { trackUserEvent } from '~/pages/AttendancePortal/analytics';
import { useAttendancePortalState } from '~/pages/AttendancePortal/context/AttendancePortalContext';

const Feedback = ({
  children,
  onYesClick,
  onNoClick,
  wasHelpful,
  questionTitle,
}) => {
  const { category } = useAttendancePortalState();
  const contacts = category.subCategories.feedback.no.contacts;
  const wpp = category.contacts.wpp;

  return (
    <FeedbackContainer>
      {children}

      <WasThisHelpfulContainer>
        <WasThisHelpfulTitle>Essa resposta foi útil?</WasThisHelpfulTitle>

        <WasThisHelpfulOptions>
          <WasThisHelpfulOption
            onClick={() => {
              trackUserEvent({
                contentType: `${questionTitle}:sim`,
              });
              onYesClick();
            }}
            selected={wasHelpful != null && wasHelpful}
          >
            Sim
          </WasThisHelpfulOption>
          <WasThisHelpfulOption
            onClick={() => {
              trackUserEvent({
                contentType: `${questionTitle}:nao`,
              });
              onNoClick();
            }}
            selected={wasHelpful != null && !wasHelpful}
          >
            Não
          </WasThisHelpfulOption>
        </WasThisHelpfulOptions>

        {wasHelpful != null && (
          <WasThisHelpfulMessageContainer>
            <InnerHtml
              raw={
                wasHelpful
                  ? category.subCategories.feedback.yes.description
                  : category.subCategories.feedback.no.description
              }
            />
          </WasThisHelpfulMessageContainer>
        )}
      </WasThisHelpfulContainer>

      {wasHelpful != null && !wasHelpful && (
        <>
          <ContactsContainer>
            {wpp && (
              <WhatsAppButtonContainer>
                <WhatsappButton
                  onClick={() => {
                    trackUserEvent({
                      contentType: `${questionTitle}:${wpp.value}`,
                    });
                    window.open(wpp.value, '_blank');
                  }}
                >
                  <IconBrandWhatsapp />
                  <span>{wpp.label}</span>
                </WhatsappButton>
              </WhatsAppButtonContainer>
            )}

            {contacts.map((contact, index) => (
              <SubjectItem
                key={index}
                icon={contact.icon}
                triggerIcon={
                  <IconSVG src={arrowHook} alt="Ícone de redirecionamento" />
                }
                title={contact.label}
                subtitle={contact.value}
                onClick={() => {
                  trackUserEvent({
                    contentType: `${questionTitle}:${contact.label}`,
                  });
                  window.location.href = `tel:${contact.label}`;
                }}
              />
            ))}
          </ContactsContainer>
        </>
      )}
    </FeedbackContainer>
  );
};

export default Feedback;

/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { IconChevronRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import {
  DesktopArrowWrapper,
  CardContent,
  CardContentWrapper,
  CardIcon,
  CardTitle,
  IconRow,
  MobileArrowWrapper,
} from './style';
import { Typography } from '../../NewUi';

const CardLink = ({ title, icon, setMaxHeight = () => {} }) => {
  const itemRef = useRef();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const paddingSize = useMemo(() => (size.width <= 125 ? 4 : 18), [size]);

  useEffect(() => {
    if (itemRef.current) {
      setSize({
        width: itemRef.current.offsetWidth,
        height: itemRef.current.offsetHeight,
      });

      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target === itemRef.current) {
            setSize({
              width: entry.contentRect.width,
              height: entry.contentRect.height,
            });
          }
        }
      });

      resizeObserver.observe(itemRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (itemRef.current) {
      const height = itemRef.current.offsetHeight;
      setMaxHeight(prev => Math.max(prev, height));

      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target === itemRef.current) {
            const newHeight = entry.contentRect.height;
            if (newHeight) {
              setMaxHeight(prev => Math.max(prev, newHeight));
            }
          }
        }
      });

      resizeObserver.observe(itemRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [setMaxHeight]);

  return (
    <CardContent paddingSize={paddingSize} className="QuickAccessItemRoot">
      <CardContentWrapper ref={itemRef}>
        <IconRow>
          <CardIcon>{icon}</CardIcon>
          <MobileArrowWrapper>
            <IconChevronRight size={32} color="#959D9D" />
          </MobileArrowWrapper>
        </IconRow>
        <CardTitle width={size.width}>
          <Typography className="CardTitleRoot" variant="h3" aria-label={title}>
            {title}
          </Typography>
        </CardTitle>
      </CardContentWrapper>
      <DesktopArrowWrapper>
        <IconChevronRight size={32} color="#959D9D" />
      </DesktopArrowWrapper>
    </CardContent>
  );
};

export default CardLink;

CardLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

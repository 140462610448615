import styled from 'styled-components';
import { DownArrow } from '@styled-icons/boxicons-solid/DownArrow';

export const Container = styled.div`
  h1 {
    padding-top: 36px;
    font-size: 40px !important;
    color: #252525;
    font-weight: bold !important;
    @media (max-width: 992px) {
      font-size: 20px !important;
    }
  }
  h2 {
    font-size: 28px;
    color: #707070;
    padding-top: 25px;
    @media (max-width: 992px) {
      padding-top: 16px;
      font-size: 14px;
    }
  }
  p {
    font-size: 16px;
  }
`;

export const Content = styled.div``;

export const BannerTitle = styled.div`
  white-space: pre-wrap;
  max-width: 520px;
  h2 {
    color: #50ebe6;
    font-size: 40px !important;
    font-weight: bold;
    padding-bottom: 18px;
  }
  @media (max-width: 712px) {
    max-width: 520px;
    p {
      padding-top: 16px;
      font-size: 22px;
      color: #006464;
    }

    h2 {
      color: #fff;
    }
  }
`;

export const BannerDescription = styled.div`
  max-width: 500px;
  p {
    color: #fff;
    font-size: 16px;
    line-height: 30px;
  }
  @media (max-width: 712px) {
    max-width: 500px;
    p {
      color: #006464;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const FirstSection = styled.section`
  margin-top: 77px;
  @media (max-width: 992px) {
    margin-top: 12px;
  }
  div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    p {
      font-size: 18px;
      color: #707070;
      line-height: 35px;
      @media (max-width: 992px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  button {
    max-width: 267px;
    margin-top: 16px;
    @media (max-width: 992px) {
      max-width: 100%;
    }
  }
`;

export const SecondSection = styled.section`
  margin-top: 70px;
  padding-bottom: 100px;
  @media (max-width: 992px) {
    margin-top: 24px;
    padding-bottom: 90px;
  }
  div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    font-size: 16px;
    color: #707070;
    line-height: 30px;
    @media (max-width: 992px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const FourthSection = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;
  h2 {
    font-size: 40px;
    font-weight: bold;
    color: #252525;
    padding-bottom: 18px;
    @media (max-width: 992px) {
      padding-top: 16px;
      font-size: 22px;
    }
  }
  p {
    font-size: 16px;
    color: #707070;
    line-height: 30px;
    @media (max-width: 992px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  div:first-child {
    margin-top: 12px;
  }
`;

export const FifthSection = styled.section`
  padding-bottom: 70px;
  h2 {
    font-size: 40px;
    font-weight: bold;
    color: #252525;
    padding-bottom: 18px;
  }
  iframe {
    width: 100%;
    height: 570px;
    border: 1px solid #ccc;
  }
`;

export const DropDown = styled.div`
  border-bottom: 1px solid #006464;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 18px;
    font-weight: bold;
    color: #252525;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

export const Arrow = styled(DownArrow)`
  color: #006464;
  width: 14px;
  height: 13px;
  margin-top: 18px;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-left: 15px;
`;

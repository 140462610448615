import React, { useState } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';

import chatIcon from '~/assets/img/AppDifference/chat.svg';
import pagamentosIcon from '~/assets/img/AppDifference/pagamentos.svg';
import recargaIcon from '~/assets/img/AppDifference/recarga.svg';
import faturaIcon from '~/assets/img/AppDifference/fatura.svg';
import saqueIcon from '~/assets/img/AppDifference/saque.svg';
import transferenciaIcon from '~/assets/img/AppDifference/transferencia.svg';
import { productsAndServices } from '../../data';
import * as S from './styles';

export const CarouselMobile = () => {
  const [value, setValue] = useState(0);

  const onChange = activeIndex => {
    setValue(activeIndex);
  };

  const selectIcons = {
    chat: chatIcon,
    fatura: faturaIcon,
    pagamentos: pagamentosIcon,
    recarga: recargaIcon,
    saque: saqueIcon,
    transferencia: transferenciaIcon,
  };

  return (
    <S.ProductsAndServices>
      <Carousel
        value={value}
        onChange={onChange}
        slidesPerPage={1}
        infinite
        arrows
      >
        {productsAndServices.map(({ title, icon, description }) => (
          <div className="item">
            <img src={selectIcons[icon]} alt={title} />
            <h1 className="title">{title}</h1>
            <p className="description">{description}</p>
          </div>
        ))}
      </Carousel>
      <Dots
        value={value}
        onChange={onChange}
        number={productsAndServices.length}
      />
    </S.ProductsAndServices>
  );
};

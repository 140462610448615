import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const TitleElegibility = styled.span`
  font-size: 16px;
  span {
    color: #22c9c3;
    font-weight: bold;
  }
`;

export const TitleDetail = styled.p`
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  span {
    font-weight: bold;
  }
`;

export const ContainerDetails = styled.div`
  background: #f7f7f7;
  border-radius: 8px 8px 0px 0px;

  div {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        span {
          small {
            font-size: 11px;
          }
        }
      `}
  }
`;

export const ContainerRedirect = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: 1px solid #e5e5e5;

  button {
    width: 60%;
    margin-top: 16px;
  }
`;

import styled from 'styled-components';

export const StyleAnchor = styled.a`
  color: #4e4b59;
  text-decoration: underline;
  font-family: Montserrat;

  &:hover {
    color: inherit;
  }
`;

export const banner = [
  {
    name: 'BannerAssistênciaAutomóvel',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-ass-automovel-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-ass-automovel.png',
    title:
      'Cuidamos do seu veículo como se fosse nosso. Conte com a nossa Assistência Auto',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

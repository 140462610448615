/* eslint-disable no-unused-vars */
import React from 'react';
import useIsMobile from '~/hooks/useIsMobile';

const Mobile = ({ children }) => {
  const isMobile = useIsMobile();

  if (!isMobile) return null;
  return children;
};

export default Mobile;

import styled from 'styled-components';

export const Forms = styled.div`
  height: 340px;

  @media (max-width: 768px) {
    height: fit-content;
  }
  h1 {
    font-size: 32px !important;
    font-weight: bold;
    color: #000000;

    @media (max-width: 768px) {
      font-size: 26px !important;
    }
  }
`;

export const WrapperSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const WrapperSummary = styled.div`
  margin-top: 16px;
  height: 320px;
  background-color: rgba(0, 114, 109, 0.1);
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width: 768px) {
    padding: 20px 14px;
  }

  span {
    padding-left: 10px;
  }

  div {
    color: #707070;
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 16px !important;
      @media (max-width: 768px) {
        font-size: 12px !important;
      }
    }
    p:nth-child(2) {
      font-weight: bold;
      color: #464646;
    }
  }
  p.link {
    color: #00726d;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 40px 0px;

  font-size: 20px;

  div.ahl-icon-closed {
    position: absolute;
    padding: 14px;

    top: 0;
    right: 0;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
    }
  }

  h2 {
    width: 100%;
    font-weight: bold;
    font-size: 23px !important;
    padding: 0px 20px;
  }

  p {
    color: #606060;
    font-size: 16px;
    margin-top: 10px;
    padding: 0px 20px;

    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;

export const Content = styled.div``;

export const GridRowHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 2fr;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 2fr;

  :nth-child(even) {
    background-color: #f1f1f1;
  }
`;

export const GridHeaderItem = styled.div`
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #252525;
`;
export const GridItem = styled.div`
  padding: 10px;
  font-size: 16px;
  color: #606060;
`;

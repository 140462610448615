import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkWrapper = styled(Link)`
  &:focus {
    outline: none;
    border: none;
  }
  &:hover,
  &:focus,
  &:active {
    .Button-root {
      color: #fff;
      border-color: #a06d2c;
      background-color: ${props => (props.outlined ? '#A06D2C' : '#221F20')};
      transform: translateY(-3px);
    }
  }
`;

const StyledButton = styled.div`
  text-align: center;
  display: flex;
  width: ${props => (props.block ? '100%' : 'auto')};
  padding: max(8px, 0.7vw) max(40px, 0.7vw);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background-color: ${props => (props.outlined ? 'transparent' : '#221F20')};
  color: ${props => (props.outlined ? '#221F20' : '#fff')};
  border: 2px solid #221f20;
  cursor: pointer;
  transition: background-color 0.9s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
  margin-bottom: 1rem;

  @media screen and (max-width: 462px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ButtonWrapper = styled(StyledButton).attrs({ as: 'button' })`
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    border-color: #a06d2c;
    background-color: ${props => (props.outlined ? '#A06D2C' : '#221F20')};
    transform: translateY(-3px);
  }
`;

const ButtonLink = styled(StyledButton).attrs({ as: 'div' })``;

const Button = ({
  children,
  onClick,
  to,
  title,
  outlined,
  block,
  external,
}) => {
  const buttonContent = <span>{children}</span>;
  const isExternal = external && to.indexOf('http') !== -1;
  if (to) {
    let path = to;
    if (isExternal || to.indexOf('http') !== -1) {
      path = {
        pathname: to,
      };
    }
    return (
      <LinkWrapper
        outlined={outlined}
        to={path}
        className="ButtonLink-root"
        target={isExternal ? '_blank' : ''}
        rel={isExternal ? 'noopener noreferrer' : ''}
        role="button"
        aria-label={title}
        style={{ outline: 'none' }}
      >
        <ButtonLink block={block} outlined={outlined} className="Button-root">
          {buttonContent}
        </ButtonLink>
      </LinkWrapper>
    );
  }

  return (
    <ButtonWrapper
      className="Button-root"
      block={block}
      outlined={outlined}
      aria-label={title}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      {buttonContent}
    </ButtonWrapper>
  );
};

export default Button;

import React from 'react';
import { useSelector } from 'react-redux';
import miIndicaBanner from '~/assets/img/Indications/miIndica.png';
import dezOff from '~/assets/img/Indications/10off.png';

import history from '~/services/history';
import * as S from './styles';

const SecondBanner = () => {
  const { toggleContractRoutes } = useSelector(state => state.tools);

  return (
    <S.SecondBanner className="container-fluid">
      <div className="container">
        <div className="left-side">
          <img alt="Logo Mi Indica" src={miIndicaBanner} id="app" />
          <p className="title">
            GOSTA DA <br />
            CONTA DIGITAL <br />
            MIDWAY?
          </p>
        </div>
        <div className="right-side">
          <div>
            <p className="subtitle">
              <b>Divulgue ela</b> com a sua galera{' '}
              <b>e vocês ganham um voucher</b> exclusivo no site Riachuelo de:
            </p>
            <img alt="Imagem do app com cartão midway" src={dezOff} id="app" />
          </div>
          <p className="subtitle-link">
            <S.Link
              className={
                !toggleContractRoutes ? '--toggle-contract-routes' : ''
              }
              onClick={() => {
                history.push('/contrato/PROGRAMA_MI_INDICA');
              }}
            >
              Clique aqui e leia todo o regulamento do Mi Indica.
            </S.Link>
          </p>
        </div>
      </div>
    </S.SecondBanner>
  );
};

export default SecondBanner;

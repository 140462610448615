import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
  .cartao-pl {
    min-height: 400px;
  }
`;

export const Section = styled.section`
  padding-top: 60px;
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom : '20px'};
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
  margin-top: ${props => (props.mgNegative ? '-50px' : '0px')};
  @media (max-width: 992px) {
    padding-top: 20px;
    margin-top: 0;
    padding-bottom: 0;
  }
`;

export const Title = styled.h1`
  padding-bottom: 20px;
  max-width: 100%;
  font-size: ${props => (props.fontSize ? props.fontSize : '40px')};
  font-weight: bold;
  color: #252525;
  @media (max-width: 992px) {
    padding-top: 27px;
    padding-bottom: 15px;
    font-size: 22px;
  }
`;
export const Subtitle = styled.h2`
  padding-bottom: 20px;
  max-width: 100%;
  font-size: ${props => (props.fontSize ? props.fontSize : '22px')};
  font-weight: bold;
  color: #252525;
  @media (max-width: 992px) {
    padding-top: 27px;
    padding-bottom: 15px;
    font-size: 22px;
  }
`;

export const Text = styled.span`
  font-size: 16px;
  padding-top: 5px;
  margin-bottom: 26px;
  color: #707070;
  line-height: 34px;
  @media (max-width: 992px) {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 24px;
  }
`;

export const Link = styled.u`
  display: block;
  font-size: 16px;
  color: #006cff;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 992px) {
    font-size: 14px;
  }
`;

const Publish = styled.div`
  ol {
    margin-bottom: 80px;
    li {
      display: inline-block;
      margin-bottom: 40px;
      width: 50%;
    }
  }
`;

export const MonthlyPublish = styled(Publish)`
  ol {
    li {
      width: 25%;
    }
  }
  @media (max-width: 992px) {
    ol {
      li {
        width: 50%;
      }
    }
  }
  @media (max-width: 700px) {
    ol {
      li {
        width: 100%;
      }
    }
  }
`;

export const SemiannualPublish = styled(Publish)`
  ol {
    li {
      width: 50%;
    }
  }
  @media (max-width: 800px) {
    ol {
      li {
        width: 100%;
      }
    }
  }
`;

import React from 'react';
import styled from 'styled-components';

const CashbackSection = styled.section`
  background-color: #f1f1f1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 44px 150px;
    gap: 36px;
  }

  strong {
    font-weight: bold;
  }
`;

const Title = styled.h2`
  color: #061c3d;
  font-weight: bold;
  font-size: 40px;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
    font-size: 36px;
  }
`;

const Subtitle = styled.p`
  color: #061c3d;
  font-size: 16px;
  text-align: left;
`;

const CashbackItems = styled.ul`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

const CashbackItem = styled.li`
  max-width: 50%;
`;

const ItemImage = styled.img`
  max-width: 140px;
`;

const FinePrint = styled.p`
  font-style: italic;
  font-size: 12px;
  text-align: left;
`;

const Cashback = () => {
  const cashbackItems = [
    '/riachuelo-cashback.png',
    '/casa-riachuelo.png',
    '/fan-lab.png',
    '/carters.png',
  ];

  return (
    <CashbackSection id="cashback">
      <Title>CASHBACK 20%</Title>
      <Subtitle>
        Compre nas lojas físicas do grupo Riachuelo, pague com seu cartão
        bandeira Mastercard e tenha <strong>20% do valor de volta</strong> na
        sua próxima compra.
      </Subtitle>
      <CashbackItems>
        {cashbackItems.map((item, index) => (
          <CashbackItem key={index}>
            <ItemImage src={item} alt="" />
          </CashbackItem>
        ))}
      </CashbackItems>
      <FinePrint>
        Limitado a 20% do valor da nova compra, cashback válido somente em lojas
        físicas.
      </FinePrint>
    </CashbackSection>
  );
};

export default Cashback;

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Image, Breadcrumb } from '@midway/web-ui-component';
import Banner from '~/assets/img/Financial/lending/banner.png';
import history from '~/services/history';
import AccessibleLink from '~/components/AccessibleLink';
import * as S from './styles';

const CardParcel = ({ title, texts }) => {
  return (
    <>
      <S.Container
        className="container-fluid"
        id="ready-page-for-screen-reader"
        aria-label="Página de parcelamento de fatura carregada"
      >
        <S.BannerContainer className="container-fluid">
          <Image
            src={Banner}
            alt="Imagem com uma mulher sorrindo olhando diretamente para câmera."
          />
          <div className="banner-title">
            <h1 className="is-primary-default">Sua fatura sem susto!</h1>
            <p className="is-primary-default">{`Conheça o novo cálculo de\npagamento da sua fatura.`}</p>
          </div>
        </S.BannerContainer>

        <Breadcrumb
          routeBack="/cartao-rchlo/"
          textRouteBack="Cartão Riachuelo"
          actualPageText="Parcelamento de fatura"
          history={history}
        />

        <S.Content className="container">
          <S.Session1>
            <h2>{title}</h2>
            {texts.map(elm => (
              <p>{elm}</p>
            ))}
          </S.Session1>

          <S.Session2 className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-1 col-xl-1">
                  <S.IconCard>
                    <S.ShowIcon>
                      <Icon
                        icon={['mid', 'calendar']}
                        size="2x"
                        color="#6ec592"
                      />
                    </S.ShowIcon>
                  </S.IconCard>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11">
                  <p>
                    <b>
                      Agora você saberá exatamente quanto e por quanto tempo vai
                      pagar a sua dívida! Se optar pelo parcelamento o seu
                      limite será reestabelecido a cada parcela paga.
                    </b>
                  </p>

                  <S.Content className="container">
                    <span>
                      <b>Dúvidas? </b>
                      Entre em contato com nossa{' '}
                      <AccessibleLink
                        route="https://www.midway.com.br/atendimento"
                        text="Central de atendimento"
                      />{' '}
                      ou em uma de nossas{' '}
                      <AccessibleLink
                        route="https://www.midway.com.br/buscar-lojas/"
                        text="lojas"
                        target="_blank"
                      />{' '}
                      mais próximas
                    </span>
                  </S.Content>
                </div>
              </div>
            </div>
          </S.Session2>
        </S.Content>
      </S.Container>
    </>
  );
};

CardParcel.propTypes = {
  title: PropTypes.string,
  texts: PropTypes.any,
};

CardParcel.defaultProps = {
  title: '',
  texts: [],
};

export default CardParcel;

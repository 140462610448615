import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding-left: 0;
  padding-right: 0;

  .benefits {
    @media (max-width: 1000px) {
      h2 {
        font-size: 24px !important;
        font-weight: 700;
        line-height: 28px;
      }
    }
  }
`;

export const BlackSession = styled.div`
  background-color: #221f20;
  max-height: 470px;
  overflow: hidden;
  padding: 0;

  div {
    margin: 0;
    padding: 0;
    color: #ffffff;

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 470px;
      flex-direction: column;
      margin-bottom: 32px;
    }

    &.content {
      max-width: 580px;
      max-height: 325;
    }

    h4 {
      color: #ffffff;
      font-family: Montserrat;
      font-size: 32px !important;
      font-weight: 500;
      line-height: 39.01px;
      text-align: left;
      margin-bottom: 16px;
    }
    p {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      margin-bottom: 24px !important;
      strong {
        margin-bottom: 16px !important;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 1051px) {
    max-height: fit-content;
    overflow: auto;

    div .content {
      padding-top: 16px;
      margin: 32px 16px;
      flex-direction: column;
      h4 {
        font-size: 18px !important;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }
`;

export const BannerContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin: 64px 8px 0;
    font-family: Montserrat;
    font-size: 32px !important;
    font-weight: 500;
    line-height: 39px;
    color: #ffffff;
    z-index: 1;
  }

  .color-white {
    color: #f1f1f1 !important;
  }

  p.uppercase {
    text-transform: uppercase;
  }

  p.bold {
    font-weight: bold;
  }

  p.--title-page {
    font-size: 36px;
    line-height: 43.2px;
    @media (max-width: 1000px) {
      line-height: 28px;
      font-size: 24px !important;
    }
  }

  p.--subtitle-page {
    font-size: 22px !important;
    @media (max-width: 1000px) {
      font-size: 18px !important;
    }
  }

  @media (max-width: 1000px) {
    min-height: 500px;
    max-height: auto;
  }
  img {
    max-height: 600px;
    width: 100%;
    object-fit: cover;
  }

  .background-benefits {
    background: #221f20;
    width: 100%;
    height: 205px;
    position: absolute;
    @media (max-width: 1000px) {
      height: 360px;
    }
  }
`;

export const Benefits = styled.div`
  border: 2px solid var(--Colors-Menta, #b8c5bd);
  margin: 32px 8px;
  max-width: calc(25% - 16px);
  border-radius: 8px;
  background-color: #ffffff;
  @media (max-width: 1000px) {
    max-width: calc(50% - 16px);
    margin: 8px;
  }

  img {
    width: 40px;
    height: 40px;
    margin: 24px 0 16px 4px !important;

    @media (max-width: 1000px) {
      width: 32px;
      height: 32px;
      max-width: 32px;
      min-width: 32px;
      max-height: 32px;
      min-height: 32px;
    }
  }
  p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 0 0px 24px 4px;
  }
`;

export const AnchorText = styled.p`
  font-family: Montserrat;
  color: #4e4b59;
  font-size: 18px;
  padding-bottom: 30px;
`;

export const HandleShow = styled.div``;

export const AccordionTitle = styled.p`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  line-height: 39.01px;
  padding-top: 120px;
  padding-bottom: 41px;

  @media (max-width: 1000px) {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    padding-top: 64px;
  }
`;

export const Session = styled.section`
  margin-top: 10px;
  padding-top: 50px;
  @media (max-width: 1000px) {
    margin-top: 20px;
  }

  &.mock {
    position: relative;
    margin-top: 5px;
  }

  .rowPlan {
    display: flex;
    padding: 0 8px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 110px;
    @media (max-width: 1000px) {
      flex-direction: column;
      margin-bottom: 14px;
    }
  }

  p.title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700 !important;
    line-height: 28px;
    text-align: left;
    padding: 0 0px 24px 0;
    margin: 0;
    color: #1f2b2a;
  }

  .planTable {
    border: 2px solid var(--Colors-Menta, #b8c5bd);
    border-radius: 8px;
    padding: 48px;
    width: calc(50% - 16px);
    @media (max-width: 1000px) {
      width: calc(100% - 16px);
      padding: 24px 16px 24px 16px;
    }

    p.title {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 22px;
      color: #1f2b2a;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;

      div {
        width: 32px;
        margin-right: 16px;
      }
      @media (max-width: 1000px) {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
      }
    }
  }

  .iphoneMock {
    div {
      width: 325px;
      margin: auto;
    }
    @media (max-width: 1000px) {
      margin-bottom: 0px;
      div {
        width: 220px;
      }
    }
  }
  .BG {
    width: calc(100% - 32px);
    height: 600px;
    background-color: #f7f7f7;
    position: absolute;
    top: 60px;
    border-radius: 9px;
    @media (max-width: 1000px) {
      top: 225px;
      height: 980px;
    }
  }

  .assistData {
    margin-top: 28px;
    padding-right: 80px;

    @media (max-width: 1000px) {
      padding: 0 32px;
      p.title {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
      }
    }
  }

  p.subtitleStores {
    color: #1f2b2a !important;
    font-family: Montserrat;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 24px !important;
    margin-top: 65px;
    @media (max-width: 1000px) {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .store-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 225px;
    }
    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
      div {
        margin-bottom: 16px;
      }
    }
  }

  ol {
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
      padding: 16px 0 4px;

      &::before {
        content: counter(my-awesome-counter);
        display: flex;
        width: 40px;
        min-width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background: #a06d2c;
        color: #ffffff;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
        margin-right: 24px;
        @media (max-width: 1000px) {
          margin-right: 16px;
        }
      }
    }
  }

  h4 {
    font-family: Montserrat;
    font-size: 32px !important;
    font-weight: 500 !important;
    line-height: 39.01px;
  }
  .subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    font-family: Montserrat;
    font-weight: 500;
    color: #3b4443;
    margin-top: 24px;
    @media (max-width: 1000px) {
      font-size: 14px !important;
      line-height: 18px;
      margin-top: 16px;
    }

    .underline {
      text-decoration: underline;
    }
  }
  .listmoretext {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #000;
    padding-bottom: 24px;
    margin-top: 40px;
  }
  .listmoretextFinal {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  listmoredescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #1f2b2a;
  }

  .link {
    text-decoration: underline;
  }

  .--plan-page {
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .--title-page {
    font-size: 25px;
    @media (max-width: 1000px) {
      font-size: 24px !important;
      line-height: 28px;
    }
  }

  .--subtitle-page {
    font-size: 18px !important;
    @media (max-width: 1000px) {
      font-size: 18px !important;
    }
  }

  .odontoprevImg {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 1000px) {
      justify-content: center;
    }
    div {
      width: 239px;
    }
  }
  .valeSaudeTxt {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: Montserrat;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      padding-left: 30px;
      border-left: 2px solid #959d9d;
      color: #3b4443;
      @media (max-width: 1000px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border-left: none;
        border-top: 2px solid #959d9d;
        padding: 24px 0 0;
        margin: 0px;
      }
    }
  }
  .faq {
    padding: 16px;
  }
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 24px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  margin-left: 32px;
`;
export const GroupsItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
`;

export const ListItemContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
`;

export const Bullet = styled.span`
  font-size: 20px;
  margin-right: 10px;
`;

export const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

export const Description = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

export const ListMoreAdvantagesText = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
`;

export const Content = styled.div``;

export const Col = styled.div`
  margin-bottom: 32px;
  & > h2 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  & > h3 {
    font-size: 18px !important;
    font-weight: bold !important;
  }
  & > p {
    color: #707070;
    white-space: pre-wrap;
    &.bold {
      font-weight: bold !important;
    }
    b {
      font-weight: bold !important;
    }
  }
`;

export const ButtonBlackSession = styled.a`
  width: 100%;
  margin-left: -300px;
  max-width: 270px;
  padding: 20px 60px 20px 60px;
  border-radius: 50px;
  background-color: #a06d2c;
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: backgroud-color 0.5s ease;

  &:hover {
    color: inherit;
    background-color: #754d19;
  }
  @media (max-width: 1000px) {
    max-width: 90%;
    margin-left: 0px;
    padding: 16px 60px 16px 60px;
    margin-top: -30px;
  }
`;

export const StyleAnchor = styled.a`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 22px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  color: black;
  padding-bottom: 48px;

  &:hover {
    color: blue;
  }
`;
export const ColAnchor = styled.p`
  padding-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;
export const ContainerCardWrapper = styled.div`
  max-width: 1176px;
  display: flex;

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ContentCardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
  margin-top: 40px;
  margin-bottom: 150px;
  @media (max-width: 1000px) {
    margin-top: 40px;
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 8px;
  min-width: ${props => props.minWidth || '629px'};
  min-height: 71px;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 6px -1px #1f2b2a1f;

  @media (max-width: 1190px) {
    min-width: 100%;
    width: 100%;
  }
`;

export const CardAnchor = styled.a`
  width: 100% !important;
  display: flex;
  padding: 12px 16px 12px 16px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
`;

export const CardWrapperText = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  margin-right: 50px;
  @media (max-width: 1000px) {
    gap: 1rem;
    margin-top: 57px;
  }
`;
export const Title = styled.a`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #1f2b2a !important;
  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;
export const Icon = styled.img`
  width: 48px;
  height: 47px;
`;
export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
`;

export const SpaceFinal = styled.div`
  width: 100%;
  margin-top: 100px;
  margin-bottom: 200px;
  @media (max-width: 1000px) {
    margin-top: 64px;
    margin-bottom: 170px;
  }
`;

export const StoreButtons = styled.a``;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: -2px -2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
  align-items: center;
  button {
    margin-top: 1rem;
    width: 80%;
  }
`;

import styled from 'styled-components';

export const RightIconContainer = styled.div`
  width: 30px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .accordion {
    background-color: #ffffff;
    cursor: pointer;
    padding: 18px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.6s ease;
    border: 1px solid #dae5e3;
    border-radius: 10px;
    margin-bottom: 14px;
    &:active,
    &:focus {
    }
  }

  .active {
    background-color: #ececec;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #dae5e3;
    margin-bottom: 0;
  }

  .border-color {
    border: 1px solid #dae5e3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .accordion-title {
    font-size: 18px !important;
    text-align: left;
    margin-left: 12px;
    margin-right: 12px;
    flex: 1;
    @media (max-width: 1000px) {
      font-size: 16px !important;
    }
  }

  .accordion-content {
    border-top: none !important;
    background: '#ffff';
    width: 100%;
    overflow: hidden;
    transition: 0.6s ease;
  }

  .accordion-text {
    padding: 18px;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
`;

export const AcordionText = styled.div;

export const IconChevron = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 48px;
  height: 48px;
  border-radius: 28px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 48px;
  height: 48px;
`;

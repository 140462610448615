import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const opacity = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Card = styled.form`
  background: #fff;
  padding: 32px;
  width: 100%;
  min-width: 232px;
  min-height: 100px;
  border-radius: 24px;
  box-shadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);
  animation: ${opacity} 0.2s linear;

  @media (min-width: 1024px) {
    width: 422px;
  }
`;

export const Title = styled.p`
  color: #1f2b2a;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  padding-bottom: 18px;
`;

export const SubTitle = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3b4443;
`;

export const InputRadioContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 14px;
`;

export const InputRadio = styled.input`
  width: 20px;
  height: 20px;
  border: solid 2px;
`;

export const LabelRadio = styled.label`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #3b4443;
  padding-left: 14px;
`;

export const RadiusContainer = styled.div`
  padding: 18px 0;
`;

export const InputTextsContainer = styled.div`
  input {
    padding: 16px 8px 8px 8px;
    margin-top: 12px;
    width: 100%;
    height: 48px;
    font-size: 14px !important;
    display: flex;

    border-top-style: hidden;
    border-left-style: hidden;
    border-right-style: hidden;
    border-width: 2px;
    border-color: #959d9d;

    transition:
      border-color 0.3s ease 0s,
      color 0.3s ease 0s;

    :focus {
      border-color: #212121;
      color: #212121;
    }
  }
`;

export const MaskedInputLabel = styled.label`
  color: #c0392b;
  font-weight: 600;
  padding-top: 4px;
  font-size: 12px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 48px;
`;

export const Button = styled.input`
  background: #00726d;
  padding: 11px 10px 12px;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border-radius: 24px;
  border: 0;
  width: 100%;
  transition:
    background 0.3s ease 0s,
    width 0.3s ease-in-out 0s;

  :hover {
    background: #004a47;
  }

  :disabled {
    background: rgba(0, 128, 122, 0.2);
    cursor: not-allowed;

    ${({ loading }) =>
      loading &&
      css`
        background: #00726d;
      `};
  }

  @media (min-width: 540px) {
    width: 80%;
  }

  @media (min-width: 0px) {
    ${({ loading }) =>
      loading &&
      css`
        width: 40px;
        background: #00726d;
      `};
  }
`;

export const FooterInfo = styled.p`
  margin-top: 10px;
  font-size: 8pt;
  color: #000;
  text-align: center;
  width: 100%;
  padding-top: 20px;
`;

export const IconLoading = styled.div`
  position: absolute;
  bottom: 6px;
  animation: ${rotate} 2s linear infinite;
`;

import React from 'react';
import * as S from './styles';

export const CardAssistanceImage = ({ imagePath, imageAlt }) => {
  return (
    <S.CardImageContainer>
      <S.CardImage src={imagePath} alt={imageAlt} className="card-image" />
    </S.CardImageContainer>
  );
};

export default CardAssistanceImage;

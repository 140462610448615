const dataLayer = window.dataLayer || [];
const isDevEnvironment = process.env.NODE_ENV === 'development';

function textFormatter(text) {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(':', ' ')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, ':');
}

export const trackUserEvent = ({ contentType, otherParams = {} }) => {
  const contentTypeFormatted = textFormatter(contentType);
  const payload = {
    event: 'select_content',
    flow: 'atendimento:faq',
    content_type: contentTypeFormatted,
    ...otherParams,
  };
  if (isDevEnvironment) return;
  dataLayer.push(payload);
};

import { useCallback, useEffect, useState } from 'react';
import { isString, isArray } from 'lodash';
import cmsService from '~/services/cms/pages';

const categories = {
  varejo: 'Varejo',
  farmacia: 'Farmácia',
  viagens: 'Viagens',
  eletronicos: 'Eletrônicos',
  beleza: 'Beleza',
};

const useCardBenefits = () => {
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [featuredPartners, setFeaturedPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataByPermalink = useCallback(async () => {
    await cmsService
      .getDataByPermalink('parceiros-midway')
      .then(response => {
        const content = JSON.parse(response.data.response.draft.content);
        const numOfFeaturedPartners = 8;
        const featuredContent = content.slice(0, numOfFeaturedPartners);
        setPartners(content);
        setFeaturedPartners(featuredContent);
      })
      .catch(error => error)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getDataByPermalink();
  }, [getDataByPermalink]);

  const filterByCategories = useCallback(
    partnersArg => {
      if (!isArray(partnersArg)) return partnersArg;
      if (selectedCategories.length === 0) return partnersArg;

      return partnersArg.filter(partner => {
        return partner.categories.some(partnerCategory =>
          selectedCategories.includes(partnerCategory)
        );
      });
    },
    [selectedCategories]
  );

  const simpleText = text =>
    text
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/<[^>]*>?/gm, '')
      .replace(/\s/g, '')
      .replace(/[^\w-]+/g, '')
      .toLowerCase()
      .trim();

  const filterBySearch = useCallback(
    partnersArg => {
      if (!isString(search)) return partnersArg;
      if (search === '') return partnersArg;

      return partnersArg.filter(partner => {
        const searchTerm = simpleText(search);
        const partnerSearchString =
          simpleText(partner.title) + simpleText(partner.description);
        return partnerSearchString.includes(searchTerm);
      });
    },
    [search]
  );

  useEffect(() => {
    const filtered = filterByCategories(filterBySearch(partners));
    setFilteredPartners(filtered);
  }, [filterByCategories, filterBySearch, partners]);

  return {
    categories,
    filteredPartners,
    featuredPartners,
    selectedCategories,
    setSelectedCategories,
    setSearch,
    loading,
  };
};

export default useCardBenefits;

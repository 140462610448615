import styled from 'styled-components';
import { Image } from '@midway/web-ui-component';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 82px 0 32px 0;
`;

export const Title = styled.p`
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  line-height: 1.2;

  @media (max-width: 767px) {
    font-size: 26px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const SubTitle = styled(Title)`
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 45px;

  @media (max-width: 767px) {
    font-size: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const UnavailableImage = styled(Image)`
  max-width: 492px;
`;

import React from 'react';
import * as S from './styles';

const Card = ({ icon, title, description, observation }) => {
  return (
    <S.Container>
      <S.Card>
        <S.Icon src={icon} />
        <S.Title>{title}</S.Title>
        <S.Line />
        <S.Description>{description}</S.Description>
        <S.Observation>{observation}</S.Observation>
      </S.Card>
    </S.Container>
  );
};

export default Card;

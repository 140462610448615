import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.footer`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (!props.color ? colors.secondary : props.color)};
`;

import React from 'react';
import StepNumber from '../StepNumber';
import * as S from './styles';

const ListStepNumber = ({ data }) => {
  return (
    <>
      {data.map(value => (
        <S.Container>
          <S.StepNumberContainer>
            <StepNumber size={70} value={value.value} color="#242424" />
          </S.StepNumberContainer>
          <S.TextContainer>
            <S.ParagraphSection>{value.text}</S.ParagraphSection>
          </S.TextContainer>
        </S.Container>
      ))}
    </>
  );
};

export default React.memo(ListStepNumber);

import styled from 'styled-components';

export const TableContainer = styled.table`
  width: 80%;
  border-collapse: collapse;
  vertical-align: middle !important;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const TableHeader = styled.th`
  max-width: 140px;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  vertical-align: middle !important;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const TableData = styled.td`
  padding: 20px;
  text-align: left;
  max-width: 280px;
  vertical-align: middle !important;
  border-bottom: 1px solid #e1e0e7;

  @media (max-width: 1000px) {
    min-width: 123px;
    padding: 2px;
  }
`;

export const Text = styled.p`
  font-size: 22px;
  margin-bottom: 5px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 14px;
    margin-top: 10px;
  }
`;

export const SubText = styled.p`
  font-size: 15px;
  color: #7a7786;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const CentralizeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import styled from 'styled-components';

export const Link = styled.a`
  &.styleDefault {
    font-size: 16px !important;
    color: ${props => (props.isFooter ? '#fff' : '#0071eb')} !important;
    cursor: pointer !important;
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
      color: ${props => (props.isFooter ? '#fff' : '#0071eb')} !important;
    }
  }

  &:hover {
    color: ${props => (props.isFooter ? '#fff' : '#0071eb')} !important;
  }

  &.underline {
    text-decoration: underline !important;
    color: ${props => (props.isFooter ? '#fff' : '#0071eb')} !important;
  }
`;

import React, { useEffect, useState } from 'react';
import { generate } from 'shortid';
import cmsService from '~/services/cms/pages';

import TableCard from '~/components/ModalCard/TableCard';
import Error from '~/components/Error';
import Loading from '~/components/Loading';
import * as S from './styles';

const TarifsRchloCard = () => {
  const [tableCard, settableCard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const errorTitle = 'Tivemos um problema';
  const errorBody = 'Não foi possível carregar.';

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    cmsService
      .getTableTarifasVisaMastercard(process.env.REACT_APP_SITE_CMS_ENV)
      .then(res => {
        const x = JSON.stringify(res, null, '');
        const y = JSON.parse(x);
        const z = JSON.parse(y);
        settableCard(z);

        setIsLoading(false);
        setIsError(false);
      })
      .catch(error => {
        setIsLoading(false);
        setIsError(true);

        console.log(error);
      });
  }, []);

  return (
    <S.Page
      id="ready-page-for-screen-reader"
      aria-label="Página de tabela de tarifas cartão bandeira"
    >
      <S.Section>
        <div className="container">
          <S.PageTitle>
            Taxas e tarifas - Cartão Riachuelo Visa e MasterCard
          </S.PageTitle>
        </div>
      </S.Section>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error errorTitle={errorTitle} errorBody={errorBody} refreshPage />
      ) : (
        <S.Container className="container">
          {tableCard.map((elm, i) => (
            <div key={generate()}>
              <S.Title>{elm.title}</S.Title>
              <S.ContentTable>
                {elm.table.map(column => (
                  <div key={generate()} className="table-map">
                    <TableCard
                      tabStart={0}
                      key={generate()}
                      th={column.th}
                      td={column.td}
                      first={i === 0}
                      summary={elm?.summary}
                    />
                    {column.obs && (
                      <S.Obs>
                        <S.Text>{column.obs}</S.Text>
                      </S.Obs>
                    )}
                  </div>
                ))}
                <S.Text>{elm.obs}</S.Text>
              </S.ContentTable>
            </div>
          ))}
        </S.Container>
      )}
    </S.Page>
  );
};

export default TarifsRchloCard;

import Holidays from 'date-holidays';
import moment from 'moment';

const country = 'BR';

const useNextBusinessDay = () => {
  let daysToAdd = 1;
  const holidays = new Holidays(country);
  const isHoliday = date => holidays.isHoliday(date);

  const weekend = { 5: 'friday', 6: 'saturday', 0: 'sunday' };
  const isWeekend = date => !!weekend[date.getDay()];

  const payDate = new Date();

  if (!!isHoliday(payDate) || isWeekend(payDate)) {
    const day = payDate.getDay();

    if (day === 5) {
      daysToAdd = 3;
    }
    if (day === 6) {
      daysToAdd = 2;
    }
  }

  payDate.setDate(payDate.getDate() + daysToAdd);

  return { payDate: moment(payDate)?.format('YYYY-MM-DD') };
};

export default useNextBusinessDay;

import React from 'react';

import bannerImage from '~/assets/img/GuaranteedLimitLP/banner.svg';
import { Container, Content, Title, Text, Image } from './styles';

export const Banner = ({ isMobile }) => (
  <Container>
    <Content>
      <div>
        <Title>Limite Garantido</Title>

        <Text>
          A sua oportunidade de ter um Cartão Riachuelo com seu dinheiro
          rendendo mais que a poupança em nossa conta digital!
        </Text>
      </div>
      <Image
        mobile={isMobile}
        src={bannerImage}
        alt='Na imagem, uma mulher negra segura o cartão Riachuelo com um sorriso no rosto. Ela veste uma regata laranja. Ao lado dela, está o cartão Riachuelo com o texto destacado: "Aqui você tem limite garantido".'
      />
    </Content>
  </Container>
);

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import { mockData } from './data';

const TableAndTax = () => {
  const [tableAndTaxData, setTableAndTaxData] = useState([]);

  const handleGetTableAndTax = useCallback(async () => {
    try {
      const response = await cmsService.getTableAndTax(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setTableAndTaxData(response);
        return;
      }
      setTableAndTaxData(mockData);
    } catch (error) {
      setTableAndTaxData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetTableAndTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetTableAndTax]);

  return (
    <S.Container>
      {tableAndTaxData.map((item, index) => (
        <S.Content key={index}>
          <S.Title>{item.title}</S.Title>
          {item.children.map((children, childIndex) => (
            <S.Table key={childIndex}>
              <S.TableGroups>
                <S.SubTitle>{children?.subTitle}</S.SubTitle>
                <S.Group>
                  <S.Description>
                    {children.description}
                    <S.NumberStyle>{children?.number}</S.NumberStyle>
                  </S.Description>
                </S.Group>
              </S.TableGroups>
              {children.value.map(valueItem => (
                <>
                  <S.Value>
                    <S.Promotion>{valueItem?.promotion}</S.Promotion>
                    <S.Group>
                      <S.ValueBold>{valueItem?.valueBold}</S.ValueBold>
                      <S.ValueDescription>
                        {valueItem?.valueDescription}
                      </S.ValueDescription>
                    </S.Group>
                  </S.Value>
                  <S.Line />
                </>
              ))}
            </S.Table>
          ))}
        </S.Content>
      ))}
    </S.Container>
  );
};
export default React.memo(TableAndTax);

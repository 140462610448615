import styled from 'styled-components';

export const ContainerHeader = styled.div`
  .headerCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #3b4443;
    min-height: 80px;
    max-height: 85px;
  }

  .mobileCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #3b4443;
    min-height: 70px;
    max-height: 70px;
    margin-top: 20px;
    padding: 5px 10px 15px;
    border-radius: 10px;
  }

  .botaoAR {
    a {
      flex-direction: column;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      background: #fff;
      padding: 8px 17px;
      border-radius: 30px;
      color: #00726d;
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
      transition: all 0.2s ease-out;
      &:hover {
        margin-top: 7px;
        margin-bottom: 2px;
        text-decoration: none;
        color: #000;
      }
    }
  }

  .displayWidth {
    margin: 0px 15px;
    padding-top: 10px;

    span {
      color: #fff;
      font-weight: bold;
      font-size: 11px;
      @media screen and (max-width: 425px) {
        font-size: 2.5vw;
      }
    }

    p {
      color: #fff;
      font-weight: normal;
      font-size: 11px;
      padding-top: 0px;
      @media screen and (max-width: 425px) {
        font-size: 2.5vw;
      }
    }
  }

  #icon {
    padding-top: 10px;
    width: 50px;
    height: 50px;
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  @media screen and (max-width: 425px) {
    .headerCard {
      padding-bottom: 10px;
    }
    .botaoAR {
      a {
        padding: 5px 0px;
        width: 100px;
      }
    }
    #icon {
      width: 50px;
      height: 50px;
    }
    .displayWidth {
      text-align: left;
      width: 180px;
      font-size: 12px;
    }
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  padding: 15px 13px;
  box-shadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);
  border-radius: 16px;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    width: 422px;
  }

  img {
    width: 77px;
    height: 55px;
    margin-right: 1rem;
  }

  div {
    line-height: 40px;

    span {
      color: #009992;
      font-weight: 600;
      font-size: 16px;
    }

    p {
      span {
        color: #616b6a;
        font-size: 12px;
      }
    }
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';

const ReceiveSection = styled.section`
  background: #f1f1f1;
`;

const ReceiveContainer = styled.div`
  padding: 24px 0;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 80px 0;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0 150px;
    justify-content: space-between;
  }
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  @media (min-width: 1024px) {
    width: 55%;
  }
`;

const Title = styled.h3`
  font-size: 36px !important;
  font-weight: 400;
  text-align: left;
  line-height: 116%;

  strong {
    font-weight: bold;
  }

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Subtitle = styled.p`
  text-align: left;
  font-size: 16px !important;
  font-weight: 500;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const Steps = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

const StepItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  padding-right: 50px;
  color: #919896;

  &.border-outline {
    color: #000000;
    border: 1px solid transparent;
    outline: 1px solid #000;
    outline-offset: -15px;
    height: 110px;
    border: none;
    border-radius: 9999px;
    margin: -15px 0;
  }
`;

const StepNumber = styled.span`
  border-radius: 50%;
  border: 2px solid #919896;
  color: #919896;
  width: 50px;
  height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 21px !important;

  &.third-step {
    color: #fff;
    border: 2px solid #000;
    background-color: #000000;
  }
`;

const Label = styled.p`
  text-align: left;
  color: #919896;

  strong {
    color: #919896;
  }

  &.third-step {
    color: #000000;

    strong {
      color: #000;
    }
  }
`;

const CTAButton = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: black;
  color: white;
  padding: 20px;
  font-weight: bold;
  font-size: 24px;
`;

const SecondColumn = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const CardImage = styled.img``;

const SectionFooter = styled.div`
  background: #d9d9d9;
  padding: 14px 0;
  margin-top: 32px;

  @media (min-width: 1024px) {
    margin-top: 80px;
  }

  .text {
    font-size: 14px !important;
    text-align: center;
    font-weight: normal;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 35px;
    line-height: 180%;

    @media (min-width: 1024px) {
      font-size: 16px !important;
    }

    p {
      text-align: center;
      font-size: 14px !important;
      font-weight: normal;
      @media (min-width: 1024px) {
        font-size: 16px !important;
      }
    }
  }

  .font-bold {
    font-weight: 700;
    color: black;
  }

  a {
    text-decoration: underline;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 78px;
  padding: 70px;

  .second-row {
    display: flex;
    gap: 120px;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 24px !important;
    text-align: left;
    color: white;
  }

  .subtitle {
    font-size: 16px !important;
    text-align: left;
    color: white;
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 120px;
  }

  img {
    width: 250px;
  }
`;

const AddressUpdate = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleEvent = () => {
    if (window.innerWidth > 1024) {
      setIsOpen(true);
    } else {
      window.open(
        'https://www.riachuelo.com.br/cartoes/desbloqueio?utm_source=crm&utm_medium=landing-page&utm_campaign=tombamento-master&utm_term=master&utm_content=informacao-adicional',
        '_blank'
      );
    }
  };

  const scrollToTestDiv = anchor => {
    const divElement = document.getElementById(anchor);
    divElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <ReceiveSection id="desbloqueio">
        <ReceiveContainer>
          <FirstRow>
            <FirstColumn>
              <Title>
                DESBLOQUEIO DO <strong>CARTÃO</strong>
              </Title>
              <Steps>
                <StepItem>
                  <StepNumber>1</StepNumber>
                  <Label>
                    Assim que tiver seu cartão em mãos, entre no seu App
                    Midway/Riachuelo;
                  </Label>
                </StepItem>
                <StepItem>
                  <StepNumber>2</StepNumber>
                  <Label>Vá até a aba Cartões;</Label>
                </StepItem>
                <StepItem className="border-outline third-step">
                  <StepNumber className="third-step">3</StepNumber>
                  <Label className="third-step">
                    Vincule seu{' '}
                    <strong style={{ fontWeight: 'bold' }}>novo cartão</strong>{' '}
                    e siga as instruções que aparecerão em sua tela;
                  </Label>
                </StepItem>
                <StepItem>
                  <StepNumber>4</StepNumber>
                  <Label>
                    <strong>Pronto!</strong> Seu cartão antigo será
                    automaticamente cancelado.
                  </Label>
                </StepItem>
              </Steps>

              <Subtitle>
                Agora já pode sair usando seu novo Cartão Riachuelo Mastercard e
                aproveitando todos os benefícios que a bandeira Mastercard tem
                para te oferecer.
              </Subtitle>
              <Subtitle>
                Aproveite para cadastrar seu cartão nos seus aplicativos
                preferidos para acumular mais pontos e trocar por benefícios no
                Programa Mastercard Surpreenda!
              </Subtitle>

              <CTAButton onClick={() => handleEvent()}>Desbloquear</CTAButton>
            </FirstColumn>
            <SecondColumn>
              <CardImage src="/unlock-device.png" alt="" />
            </SecondColumn>
          </FirstRow>
          <SectionFooter>
            <p className="text">
              Se preferir você pode atualizar seu endereço ligando em nossa{' '}
              <a
                onClick={() => scrollToTestDiv('central-de-atendimento')}
                className="font-bold underline"
              >
                Central de Atendimento
              </a>
              <br />
              Fique atento: o desbloqueio de seu novo cartão só deverá ser
              realizado quando você estiver com ele em mãos! Para a sua
              segurança, em nossos contatos nunca solicitamos a senha de seu
              cartão ou que você faça o seu desbloqueio utilizando a sua
              biometria no App.
            </p>
          </SectionFooter>
        </ReceiveContainer>
      </ReceiveSection>
      {isOpen ? (
        <Modal handleCloseModal={setIsOpen}>
          <ModalContainer>
            <div className="first-row">
              <div className="title">Download de app</div>
              <div className="subtitle">
                Escolha o app de sua preferência e faça o download para
                desbloquear seu cartão.
              </div>
            </div>
            <div className="second-row">
              <img
                className="qrcode-image"
                src="/qrcode-riachuelo.png"
                alt=""
              />
              <img className="qrcode-image" src="/qrcode-midway.png" alt="" />
            </div>
          </ModalContainer>
        </Modal>
      ) : null}
    </>
  );
};

export default AddressUpdate;

import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import { mockData } from './data';

const SubTitle = () => {
  const [tableAndTaxDataSubTitle, setTableAndTaxDataSubTitle] = useState([]);

  const handleGetTableAndTax = useCallback(async () => {
    try {
      const response = await cmsService.getTableAndTaxSubTitle(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setTableAndTaxDataSubTitle(response);
        return;
      }
      setTableAndTaxDataSubTitle(mockData);
    } catch (error) {
      setTableAndTaxDataSubTitle([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetTableAndTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetTableAndTax]);

  return (
    <S.Container>
      <S.Title>Legenda</S.Title>
      {tableAndTaxDataSubTitle.map(item => (
        <S.Content key={item.id}>
          {item.children?.map((child, index) => (
            <S.Description key={index}>{child.description}</S.Description>
          ))}
        </S.Content>
      ))}
    </S.Container>
  );
};
export default React.memo(SubTitle);

import { ShortcutLinkContainer, Title } from './styles';

const ShortcutLink = ({ icon, title, ...props }) => {
  return (
    <ShortcutLinkContainer {...props} title={title}>
      {icon}
      <Title>{title}</Title>
    </ShortcutLinkContainer>
  );
};

export default ShortcutLink;

import React from 'react';
import * as S from './styles';

const Modal = ({ children, onClose }) => {
  const handleOnClose = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <S.Container onClick={handleOnClose}>
        <S.Box onClick={event => event.stopPropagation()}>
          <S.ButtonClose onClick={onClose}>x</S.ButtonClose>
          {children}
        </S.Box>
      </S.Container>
    </>
  );
};

export default Modal;

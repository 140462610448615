import React from 'react';
import * as S from './styles';

const Card = ({ image, title, description }) => {
  return (
    <S.Container>
      <S.Card>
        <S.Image src={image} />
        <S.GroupsContent>
          <S.Title>{title}</S.Title>
          <S.LineCards />
          <S.Description>{description}</S.Description>
        </S.GroupsContent>
      </S.Card>
    </S.Container>
  );
};

export default React.memo(Card);

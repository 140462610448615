import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f7f7f7;
  height: 63px;
`;

export const CloseButton = styled.div`
  position: absolute;
  left: 15px;
  top: 22px;
  width: 20px;
  height: 20px;
`;

export const Logo = styled.div`
  position: absolute;
  top: 10px;
  left: 50px;
  width: 44px;
  height: 44px;
`;

export const Title = styled.p`
  position: absolute;
  top: 7px;
  left: 110px;
  font-size: 12px;
  font-weight: bold;
`;

export const Description = styled.p`
  position: absolute;
  top: 27px;
  left: 110px;
  font-size: 12px;
  line-height: 14px;
`;

export const DownloadButton = styled.div`
  position: absolute;
  top: 14px;
  right: 15px;
  border-radius: 100px;
  width: 75px;
  height: 35px;
  background-color: #006464;
  text-align: center;
  color: white;
`;

export const DescriptionDownloadButton = styled.p`
  margin-top: 7px;
`;

import { Children } from 'react';
import { Wizard } from 'react-use-wizard';

import Search from '~/pages/AttendancePortal//components/Search';
import { AttendancePortalProvider } from '~/pages/AttendancePortal/context/AttendancePortalContext';
import { MemoizedShowCategories as StepOne } from '~/pages/AttendancePortal/steps/ShowCategories';
import { MemoizedSubCategory as StepThree } from '~/pages/AttendancePortal/steps/SubCategory';
import { MemoizedCategory as StepTwo } from '~/pages/AttendancePortal/steps/Category';

export const AttendancePortal = () => {
  const steps = Children.toArray([<StepOne />, <StepTwo />, <StepThree />]);

  return (
    <AttendancePortalProvider>
      <Search />
      <Wizard>{steps}</Wizard>
    </AttendancePortalProvider>
  );
};

import React from 'react';
import { useNegotiation } from '~/context/negotiationV2';
import Tag from '../../../Tag';

import { Container } from './styles';

function RulesForTags({ children, mtoplast }) {
  const { system, negotiationData } = useNegotiation();
  const overDueDays = negotiationData?.selectedContract?.overdueDays;

  const tagsSICC = () => {
    let first = '';
    let second = '';

    switch (true) {
      case overDueDays < 5:
        first = 'Evite bloqueio do cartão';
        break;
      case overDueDays === 5 || overDueDays <= 9:
        first = 'Reative seu cartão';
        break;
      case overDueDays === 10 || overDueDays <= 19:
        first = 'Limpe seu nome';
        break;
      case overDueDays >= 20:
        first = 'Parcele sua dívida';
        break;
      default:
        first = 'Evite bloqueio do cartão';
    }

    switch (true) {
      case overDueDays < 5:
        second = 'Evite negativação';
        break;
      case overDueDays === 5 || overDueDays <= 9:
        second = 'Evite negativação';
        break;
      case overDueDays === 10 || overDueDays <= 19:
        second = 'Reative seu cartão';
        break;
      case overDueDays >= 20:
        second = 'Limpe seu nome';
        break;
      default:
        second = 'Evite negativação';
    }

    if (negotiationData?.onlyInvoicement) {
      first = 'Continue utilizando o cartão';
      second = 'Libere seu limite';
    }
    return (
      <>
        <Tag text={first} />
        <Tag text={second} />
      </>
    );
  };

  const tagsLoan = () => {
    let first = '';
    const second = 'Evite acúmulo de juros';

    if (overDueDays <= 0) return <></>;

    if (overDueDays < 43) first = 'Evite negativação';
    else first = ' Limpe o seu nome';

    return (
      <>
        <Tag text={first} />
        <Tag text={second} />
      </>
    );
  };

  const tagsTSYS = () => {
    let first = 'Evite negativação';
    let second = '';

    if (overDueDays <= 0) return <></>;
    if (overDueDays <= 5) {
      second = 'Evite o bloqueio do cartão';
    }
    if (overDueDays > 5 && overDueDays <= 40) {
      second = 'Volte a utilizar seu cartão';
    }
    if (overDueDays > 40) {
      first = 'Limpe o seu nome';
      second = 'Evite acúmulo de juros';
    }
    return (
      <>
        <Tag text={first} />
        <Tag text={second} />
      </>
    );
  };

  const getSystemTags = () => {
    if (system === 'SICC') return tagsSICC();
    if (system === 'TOPAZ' || system === 'SIPF') return tagsLoan();
    if (system === 'AGREEMENTS' || system === 'TSYS') return tagsTSYS();
  };

  return (
    <Container mtoplast={mtoplast}>
      <>
        {children}
        {getSystemTags()}
      </>
    </Container>
  );
}

export default RulesForTags;

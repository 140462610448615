import React, { useState } from 'react';
import styled from 'styled-components';
import trackEventGa from '../GoogleAnalytics';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  @media (min-width: 640px) {
    padding: 12px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 1024px) {
    justify-content: space-around;
    max-width: 1920px;
  }
`;

const FirstColumn = styled.div``;

const LogoImage = styled.img`
  cursor: pointer;
  width: 5rem;
  display: block;
`;

const SecondColumn = styled.div``;

const MobileDrawerButton = styled.span`
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const NavMenu = styled.nav``;

const MenuItems = styled.ul`
  padding: 17px;
  display: flex;
  flex-direction: column;
  background-color: white;
  left: 0;
  top: 65px;
  position: fixed;
  gap: 40px;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: transform 400ms ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};

  @media (min-width: 1024px) {
    transition: transform 400ms ease-in-out;
    transform: translateX(0);
    flex-direction: row;
    position: relative;
    inset: 0;
    padding: 0;
  }
`;

const MenuItem = styled.li`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 4px;

  span {
    display: flex;
    align-items: center;
  }
`;

const Anchor = styled.a`
  font-weight: bold;
  color: black;
  font-size: 16px;
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const headerLinks = [
    {
      label: 'Saiba mais',
      anchor: 'saiba-mais',
    },
    {
      label: 'Receba seu novo cartão',
      anchor: 'receba',
    },
    {
      label: 'Benefícios',
      anchor: 'beneficios',
    },
    {
      label: 'Desbloqueio',
      anchor: 'desbloqueio',
    },
    {
      label: 'Dúvidas',
      anchor: 'faq',
    },
  ];

  const handleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const scrollToTestDiv = anchor => {
    setIsOpen(false);
    const divElement = document.getElementById(anchor);
    divElement.scrollIntoView({ behavior: 'smooth' });
    trackEventGa(`header:${anchor}`);
  };

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <FirstColumn>
          <LogoImage
            src="/header-logo.png"
            alt=""
            onClick={() => scrollToTestDiv('app-lp-master')}
          />
        </FirstColumn>
        <SecondColumn>
          <MobileDrawerButton onClick={handleDrawer}>
            {!isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="20"
                viewBox="0 0 29 20"
                fill="none"
              >
                <path
                  d="M2 2H27"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  d="M2 18H27"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
                <path
                  d="M2 10H27"
                  stroke="black"
                  strokeWidth="3"
                  strokeLinecap="round"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
              >
                <path
                  d="M22.4544 3.06147C21.8382 2.44524 20.8427 2.44524 20.2265 3.06147L12.4999 10.7723L4.77334 3.04567C4.15711 2.42944 3.16166 2.42944 2.54542 3.04567C1.92919 3.6619 1.92919 4.65735 2.54542 5.27358L10.272 13.0002L2.54542 20.7267C1.92919 21.343 1.92919 22.3384 2.54542 22.9547C3.16166 23.5709 4.15711 23.5709 4.77334 22.9547L12.4999 15.2281L20.2265 22.9547C20.8427 23.5709 21.8382 23.5709 22.4544 22.9547C23.0706 22.3384 23.0706 21.343 22.4544 20.7267L14.7278 13.0002L22.4544 5.27358C23.0548 4.67315 23.0548 3.6619 22.4544 3.06147Z"
                  fill="black"
                />
              </svg>
            )}
          </MobileDrawerButton>
          <NavMenu id="header">
            <MenuItems isOpen={isOpen}>
              {headerLinks.map(({ label, anchor }, index) => (
                <MenuItem key={index}>
                  <Anchor onClick={() => scrollToTestDiv(anchor)}>
                    {label}
                  </Anchor>
                  <span>
                    <img src="/chevron-down.svg" alt="" />
                  </span>
                </MenuItem>
              ))}
            </MenuItems>
          </NavMenu>
        </SecondColumn>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;

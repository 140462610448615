import styled from 'styled-components';
import { colors } from '../../constants';

export const StyledFooter = styled.footer`
  background-color: ${colors.darken};
  h3 {
    font-weight: bold;
    margin-bottom: 1em;
    font-size: 1rem !important;
    text-align: center;
  }

  padding-bottom: 20px;

  @media screen and (max-width: 492px) {
    padding-bottom: 170px;
  }
`;

export const Wrapper = styled.div`
  background-color: ${colors.darken};
  margin: 0px 24px;
  border-radius: 28px;
  padding: 5vw 0;
  color: ${colors.light};
  font-size: 0.9rem;

  a,
  h3 {
    color: #fff;
  }

  a:focus {
    border: 1px solid #efefef;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  &.evenly {
    justify-content: space-between;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &.flex-1 {
    flex: 1;
  }

  &.left-spacer {
    margin-left: 40px;
  }
  &.bottom-spacer {
    margin-bottom: 40px;
  }
`;

export const Line = styled.hr`
  background: #fff;
  margin: 0 24px;
`;

export const MidwayLogo = styled.img`
  max-width: 176px;
  margin-bottom: 2em;
`;
export const SectionIcons = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: calc(50% - 9em);
  justify-content: space-between;

  .SectionIcon {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 1em);
    max-width: 79px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0px 10px 32px 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.5px;
    min-width: calc(50% - 25px);
    a {
      display: flex;
      flex-direction: column;
    }

    img {
      margin-bottom: 8px;
      width: 32px;
    }
  }
`;

export const SocialMediaSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  margin-bottom: 24px;
  gap: 15px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SocialMediaIcon = styled.div`
  width: 36px;
  height: 36px;
  color: #fff;
`;

export const StoreIcon = styled.img`
  object-fit: contain;
  height: 47px;
  margin-bottom: 10px;
`;

export const ListLinks = styled.div`
  font-size: 0.9rem;
  div {
    margin-bottom: 0;
  }
`;
export const StoreSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 12px;
  a {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 140px;
      height: 41px;
    }
  }
`;

export const Link = styled.a`
  font-size: 14px !important;
  font-weight: 500;
  line-height: 40px;
  &:focus {
  }
`;

export const ExpandableButton = styled.button`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #fff;
  padding: 14px;
  width: 100%;
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 1em;
  > div {
    margin: auto;
    text-align: left;
    margin-left: 0;
    font-weight: bold;
  }
`;

export const HiddenLinks = styled.div`
  max-height: ${props => (props.expanded ? '600px' : '0')};
  overflow: hidden;
  opacity: ${props => (props.expanded ? '1' : '0')};
  transition:
    max-height 0.5s ease-in-out,
    opacity 0.3s ease-in-out;
`;

export const Section = styled.div`
  padding: ${props => (props.padding ? props.padding : 0)};
  display: ${props => (props.flex ? 'flex' : 'block')};
  justify-content: ${props => (props.contentRight ? 'flex-end' : 'flex-start')};
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
  }
`;

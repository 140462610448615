/* eslint-disable prefer-rest-params */
const trackEventGa = buttonName => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('event', 'select_content', {
    content_type: buttonName,
    flow: 'lp-mastercard',
  });
};

export default trackEventGa;

export const banner = [
  {
    name: 'BannerSaude',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-mais-saude-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-mais-saude.png',
    title:
      'Mais saúde, mais qualidade de vida. Conheça nossos planos e faça da sua saúde a prioridade número um',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

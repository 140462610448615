import React from 'react';
import * as S from './styles';

const Card = ({ widthCard, title, descriptionOne, descriptionTwo }) => {
  return (
    <S.Container>
      <S.Centralize>
        <S.CardWrapper maxWidth={widthCard}>
          <S.Title>{title}</S.Title>
          <S.Description>{descriptionOne}</S.Description>
          <S.Description>{descriptionTwo}</S.Description>
        </S.CardWrapper>
      </S.Centralize>
    </S.Container>
  );
};

export default Card;

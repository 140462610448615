import React from 'react';
import { useHistory } from 'react-router-dom';

import ImagePassoAPasso from '~/assets/img/digital-account/passo-a-passo.png';
import Desktop from '~/components/Responsive/Desktop';
import Button from '~/components/NewUi/Button';
import { trackingSelectContent } from '~/analytics';
import {
  SectionWrapper,
  Title,
  Subtitle,
  Body,
  ImageWrapper,
  ContentWrapper,
  Card,
  CardTItle,
  CardContent,
  ActionButton,
  CardsWrapper,
  SectionContainer,
} from './styles';

const steps = [
  {
    key: 1,
    title: 'Baixe o app Midway',
    content:
      'Acesse a loja de aplicativos do seu celular e baixe o app para abrir sua conta digital gratuita.',
  },
  {
    key: 2,
    title: 'Faça o cadastro',
    content:
      'Preencha seus dados e cadastre uma chave Pix para receber dinheiro em instantes.',
  },
  {
    key: 3,
    title: 'Aproveite',
    content:
      'O seu dinheiro já começa a render. Aproveite esse e outros benefícios da sua nova conta!',
  },
];

const HowToOpenAccount = () => {
  const history = useHistory();

  const renderSteps = () => {
    return steps.map(step => {
      return (
        <Card key={step.key}>
          <CardTItle>
            {step.key}. {step.title}
          </CardTItle>
          <CardContent>{step.content}</CardContent>
        </Card>
      );
    });
  };

  return (
    <SectionWrapper>
      <SectionContainer>
        <Title>Como abrir uma Conta digital Midway?</Title>
        <Subtitle>Siga o passo a passo:</Subtitle>
        <Body>
          <Desktop>
            <ImageWrapper>
              <img
                src={ImagePassoAPasso}
                alt="Como abrir uma Conta digital Midway?"
              />
            </ImageWrapper>
          </Desktop>

          <ContentWrapper>
            <CardsWrapper>{renderSteps()}</CardsWrapper>
            <ActionButton>
              <Button
                role="button"
                onClick={() => {
                  history.push('/app/');
                  trackingSelectContent(
                    'abrir-uma-conta-digital-midway:abrir-conta-gratis',
                    'conta-digital'
                  );
                }}
              >
                Abrir Conta grátis
              </Button>
            </ActionButton>
          </ContentWrapper>
        </Body>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default HowToOpenAccount;

/* eslint-disable react/prop-types */
import React from 'react';
import { first } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import useCorrectText from '~/hooks/useCorrectText';
import {
  agreementSimulationParametersMapper,
  invoicementSimulationParametersMapper,
} from '~/utils/negotiationHelpers';
import GenericCardProposal from '../GenericCardProposal';
import GenericInstallmentProposal from '../GenericInstallmentProposal';
import { ContainerCardProposal } from './styles';
import ModalDetails from '../ModalDetails';

const ProposalFactory = ({ typeElegibily, data }) => {
  const { type, system, negotiationData, hideAgreement } = useNegotiation();

  const { debitInOverdue, acquittanceInOverdue } = useCorrectText();

  const acquittanceFactory = () => {
    const title =
      system === 'AGREEMENTS'
        ? 'Pague todo o saldo à vista'
        : `Pague o valor ${acquittanceInOverdue()}`;
    const buttonText =
      system === 'AGREEMENTS'
        ? 'Pagar saldo à vista'
        : `Pagar valor ${acquittanceInOverdue()}`;
    return (
      <ContainerCardProposal className="container-card">
        <GenericCardProposal
          title={title}
          buttonText={buttonText}
          eligibilityData={data}
          typeEligibility="acquittance"
        />
      </ContainerCardProposal>
    );
  };

  const debitFactory = () => {
    const title = (
      <>
        Pague agora o valor {debitInOverdue()}{' '}
        {data?.discount?.maxTotal > 0 ? <b>com desconto:</b> : ':'}
      </>
    );
    const buttonText = `Pagar valor ${debitInOverdue()}`;
    return (
      <ContainerCardProposal className="container-card">
        <GenericCardProposal
          title={title}
          buttonText={buttonText}
          eligibilityData={data}
          typeEligibility="debit"
        />
      </ContainerCardProposal>
    );
  };

  const agreementFactory = () => {
    const simulationParams = agreementSimulationParametersMapper(
      first(negotiationData?.eligibility?.agreement) ??
        negotiationData?.eligibility?.agreement,
      system,
      type
    );

    const buttonText =
      system === 'AGREEMENTS' ? 'Parcelar saldo' : 'Parcelar saldo total';

    if (hideAgreement) return <></>;
    return (
      <ContainerCardProposal className="container-card">
        <GenericInstallmentProposal
          buttonText={buttonText}
          simulationParams={simulationParams}
          eligibilityData={data}
          typeEligibility="agreement"
        />
      </ContainerCardProposal>
    );
  };

  const invoicementFactory = () => {
    const simulationParams = invoicementSimulationParametersMapper(
      negotiationData?.eligibility?.invoicement,
      type
    );
    const buttonText = 'Parcelar fatura';
    return (
      <ContainerCardProposal className="container-card">
        <GenericInstallmentProposal
          buttonText={buttonText}
          simulationParams={simulationParams}
          eligibilityData={data}
          typeEligibility="invoicement"
        />
      </ContainerCardProposal>
    );
  };

  // Monta o objeto com os diferentes textos e ação de cada tipo de negociação
  const getCurrent = () => {
    switch (typeElegibily) {
      case 'debit':
        return debitFactory();
      case 'acquittance':
        return acquittanceFactory();
      case 'invoicement':
        return invoicementFactory();
      case 'agreement':
        return agreementFactory();
      default:
        return null;
    }
  };

  return (
    <>
      {getCurrent()}
      <ModalDetails />
    </>
  );
};

export default ProposalFactory;

import React from 'react';
import * as S from './styles';

const Index = () => {
  return (
    <S.Section>
      <S.Container>
        <S.Content>
          <S.Title>Benefícios</S.Title>
          <S.Text>
            Conheça todos os benefícios de ser um cliente Midway usando o seu
            Cartão de Crédito Riachuelo!
          </S.Text>
        </S.Content>
      </S.Container>
    </S.Section>
  );
};

export default Index;

import styled from 'styled-components';

export const StyleAnchor = styled.a`
  color: #4e4b59;
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
  text-align: center;

  &:hover {
    color: inherit;
  }
`;

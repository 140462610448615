import React from 'react';
import * as S from './styles';

const UserDetails = props => {
  const maskCPF = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const onChange = e => {
    if (e.target?.name === 'cpf') {
      const value = maskCPF(e.target.value);
      e.target.value = value;
    }
    props.onChange(e);
  };

  return (
    <S.Forms>
      <h1>Bem-vindo ao Simulador de Empréstimo</h1>
      <h2>Para simular o empréstimo, precisamos de alguns dados seus:</h2>

      <S.InputWrapper>
        <input
          id="name"
          type="text"
          pattern=".+"
          required
          name="fullname"
          value={props.form.fullname}
          onChange={onChange}
          className="no-border-focus"
        />
        <label className="label" htmlFor="name">
          Nome completo
        </label>
      </S.InputWrapper>

      <S.InputWrapper>
        <input
          id="cpf"
          maxLength={14}
          pattern=".+"
          required
          name="cpf"
          value={props.form.cpf}
          onChange={onChange}
          className="no-border-focus"
        />
        <label className="label" htmlFor="cpf">
          CPF
        </label>
      </S.InputWrapper>
    </S.Forms>
  );
};

export default UserDetails;

import styled from 'styled-components';

export const Container = styled.div`
  background: #f7f7f7;
  width: 100%;
  border-radius: 8px;
  border: 0;
  margin-bottom: 16px;

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const ContainerTryAgain = styled(Container)`
  padding: 16px;
  margin-bottom: 0px;
`;

export const TopContainer = styled.div`
  width: 100%;
  background: #f7f7f7;
  padding: 16px 27px;
  border-radius: 8px;
  border: 0;
  transition: background 0.2s linear;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
    background: #e6e6e6;
  }
`;

export const Title = styled.p`
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px;
  color: #00726d;
  margin-bottom: 16px !important;
`;

export const TitleError = styled(Title)`
  color: #c3223d;
  margin-left: 14px;
  margin-bottom: 0px !important;
`;

export const TitleErrorDescription = styled.p`
  color: #1f2b2a;
  padding: 8px 0 18px 0;
  font-size: 12px;
  text-align: center;
`;

export const ContainerLine = styled.div`
  display: flex;
  align-items: center;
`;

export const PointLabel = styled.p`
  color: #3b4443;
  font-family: 'Montserrat';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.5px;
  padding-left: 8px;
`;

export const PointValue = styled(PointLabel)`
  font-weight: 700;
  padding-left: 3px;
`;

export const Image = styled.img`
  width: 24px;
`;

export const InfoButton = styled.button`
  padding: 0 4px;
  border: 0;
  background: transparent;
`;

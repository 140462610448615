import React from 'react';
import GirlImage from '~/assets/img/midway/girl-with-card.png';
import Desktop from '~/components/Responsive/Desktop';
import {
  Column,
  Container,
  ContentWrapper,
  Description,
  HeaderWrapper,
  Image,
  ImageContainer,
  Title,
} from './styles';

const data = {
  title: 'MIDWAY SOLUÇÕES FINANCEIRAS',
  description:
    'Nossa história começou em 2008, como a empresa financeira do grupo Guararapes. Com o propósito de facilitar a vida financeira de quem nos procura, são anos de dedicação para oferecermos sempre o melhor para você.',
};

const Header = () => {
  return (
    <HeaderWrapper>
      <Container>
        <Desktop>
          <Column>
            <ImageContainer>
              <Image src={GirlImage} />
            </ImageContainer>
          </Column>
        </Desktop>
        <Column>
          <ContentWrapper>
            <Title>{data.title}</Title>
            <Description>{data.description}</Description>
          </ContentWrapper>
        </Column>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;

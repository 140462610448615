import moment from 'moment';
import React from 'react';
import { useNegotiation } from '~/context/negotiationV2';
import format from '~/services/format';
import Help from '../../../Help';
import { Content, Description, Item, Container } from './styles';

function DirectDebit() {
  const { negotiationData, typeNegotiationRegistered } = useNegotiation();

  const { registered } = negotiationData;
  const isAcquittance = typeNegotiationRegistered === 'acquittance';
  const diffDays = moment(registered?.dueDate).diff(
    registered?.issuanceDate,
    'days'
  );
  const dueDate = moment(registered?.dueDate, 'YYYY-MM-DD').format(
    'DD/MM/YYYY'
  );

  const getInstallmentSelectedText = () => {
    const current = negotiationData?.eligibility[typeNegotiationRegistered];
    const simulation = current?.simulation;
    const selectedInstallmentData = simulation?.selectedInstallmentData;

    const entryAmount = simulation?.entryAmount;
    const installmentQuantity = selectedInstallmentData?.installmentQuantity;
    const installmentAmount = selectedInstallmentData?.installmentAmount;

    return (
      <>
        Entrada de <strong>{format.currency(entryAmount)}</strong> +{' '}
        {installmentQuantity}X de{' '}
        <strong>{format.currency(installmentAmount)}</strong>
      </>
    );
  };
  return (
    <Container>
      <Description>Seguem informações sobre a sua negociação:</Description>
      <Content>
        <span>
          {isAcquittance ? (
            <>
              Pagamento de{' '}
              <strong>{format.currency(registered?.amount)}</strong>
            </>
          ) : (
            <>{getInstallmentSelectedText()}</>
          )}{' '}
        </span>
        <Item>
          <span>
            {isAcquittance
              ? 'Data prevista para débito:'
              : 'Vencimento da parcela da entrada'}
          </span>
          <strong>
            {`${dueDate} daqui ${diffDays} ${diffDays <= 1 ? 'dia' : 'dias'}`}
          </strong>
        </Item>
        <Item>
          <span>Importante:</span>
          <strong>
            {isAcquittance
              ? 'O valor da negociação deve estar disponível no saldo da sua conta Midway na data de vencimento, caso contrário, o acordo será quebrado.'
              : 'O valor da entrada e das parcelas devem estar disponíveis no saldo da sua conta Midway nas datas de vencimento, caso contrário, o acordo será quebrado. '}
          </strong>
        </Item>
      </Content>
      <Help />
    </Container>
  );
}

export default DirectDebit;

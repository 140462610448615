/* eslint-disable import/no-named-as-default-member */
import React, { useEffect } from 'react';
import { Pix } from 'styled-icons/fa-brands';
import { Icon } from '@midway/web-ui-component';
import { kebabCase, keys, lowerCase } from 'lodash';
import { useSelector } from 'react-redux';
import { tagMiAjudaButton } from '~/services/tagging';
import { useNegotiation } from '~/context/negotiationV2';
import useGetCollectionPaymentMethod from '~/hooks/useGetCollectionPaymentMethod';
import useInsiderQueue from '~/hooks/useInsiderQueue';
import { Button } from '~/components/Button';
import format from '~/services/format';
import { gtagSelectContent, gtagViewContent } from '~/utils/TaggingGA4';
import { Container, Title, Item, GreenBold } from './styles';
import ContainerFadeIn from '../../ContainerFadeIn';

const GTAG_CONTENT_PAGE = 'tela-de-pagamento';
const GTAG_FLOW = 'miajuda';

function PaymentMethod() {
  const { toogles } = useSelector(state => state.featuretoggle);
  const { insiderQueueCustom } = useInsiderQueue();

  const {
    system,
    paymentMethods,
    typeNegotiationRegistered,
    setPaymentMethodSelected,
    negotiationData,
    loadingNegotiation,
  } = useNegotiation();
  const [, getActions] = useGetCollectionPaymentMethod();
  const isDebit =
    typeNegotiationRegistered === 'debit' ||
    typeNegotiationRegistered === 'acquittance';

  const selected = negotiationData?.eligibility[typeNegotiationRegistered];

  const getInstallmentDetails = () => {
    const { /** @type SimulationData */ simulation } = selected;

    return {
      entry: simulation.entryAmount,
      installmentAmount: simulation.selectedInstallmentData.installmentAmount,
      installmentQuantity:
        simulation.selectedInstallmentData.installmentQuantity,
    };
  };

  const getDebitDetails = () => {
    return {
      valueWithDiscount: Number(
        parseFloat(selected?.totalValue - selected?.discount?.maxTotal).toFixed(
          2
        )
      ),
    };
  };

  const details = isDebit ? getDebitDetails() : getInstallmentDetails();

  const ga4Params = () => {
    const desconto = selected?.discount?.maxTotal;
    if (isDebit) {
      return {
        valor: details.valueWithDiscount,
        desconto: desconto || 0,
        tipo_proposta: typeNegotiationRegistered,
        sistema: lowerCase(system),
        proposta: negotiationData?.gaParamProposta,
        contrato: negotiationData?.selectedContract?.contract,
      };
    }
    return {
      valor_de_entrada: details.entry,
      valor_das_parcelas: details.installmentAmount,
      numero_de_parcelas: details.installmentQuantity,
      desconto: desconto || 0,
      tipo_proposta: typeNegotiationRegistered,
      sistema: lowerCase(system),
      proposta: negotiationData?.gaParamProposta,
      contrato: negotiationData?.selectedContract?.contract,
    };
  };

  useEffect(() => {
    gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW, ga4Params());
    insiderQueueCustom('visualizou_resumo', {
      tipo_negociacao: typeNegotiationRegistered,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methodsText = {
    BILLET: {
      icon: 'pix',
      title: 'Pagar com Boleto',
      description: 'Seu pagamento pode ser reconhecido em até 3 dias úteis',
      show: true,
    },
    PIX: {
      icon: 'billet',
      title: 'Pagar com Pix',
      description:
        'O vencimento é no mesmo dia, e o pagamento é reconhecido em instantes',
      show: true,
    },
    DEBIT_ACCOUNT: {
      icon: 'debit_account',
      title: 'Pagar com debito em conta',
      description:
        'O vencimento é no mesmo dia, e o pagamento é reconhecido em instantes',
      show: toogles.isEnablePaymentDebtAccount,
    },
  };
  const haDiscount = selected?.discount && selected?.discount?.maxTotal !== 0;

  return (
    <ContainerFadeIn>
      <Container>
        <Title>Resumo do acordo {haDiscount && 'com desconto'}</Title>
        {haDiscount && (
          <Item>
            <span>
              Pague <b>agora</b> e <b>economize</b>
            </span>
            <GreenBold>
              {format.currency(selected?.discount?.maxTotal)}
            </GreenBold>
          </Item>
        )}
        {isDebit && (
          <Item>
            <span>Total a pagar</span>
            <span>{format.currency(details.valueWithDiscount)}</span>
          </Item>
        )}
        {!isDebit && (
          <Item>
            <span>Detalhamento</span>
            <span id="installment">
              Entrada: <b>{format.currency(details.entry)}</b> +{' '}
              {details.installmentQuantity}X de{' '}
              <b>{format.currency(details.installmentAmount)}</b>
            </span>
          </Item>
        )}
        <Item bbottomnone>
          <span>{!isDebit ? 'Primeiro vencimento' : 'Vencimento'} </span>
          <span>
            <b>
              {format.date(
                selected?.dueDate || selected?.simulation?.entryDate
              )}
            </b>
            {/* <GreenBold>(hoje)</GreenBold> */}
          </span>
        </Item>

        <Title mtop>
          Escolha o método de pagamento para confirmar o acordo
        </Title>
        {keys(paymentMethods)
          ?.reverse()
          ?.map(method => {
            if (
              ((toogles.isDisablePaymentMethodPixSipf && system === 'SIPF') ||
                (system === 'SICC' &&
                  toogles.isDisablePaymentMethodPixSicc &&
                  typeNegotiationRegistered === 'agreement')) &&
              method === 'PIX'
            ) {
              return <div key={method} />;
            }

            if (!methodsText[method] || !methodsText[method]?.show)
              return <div key={method} />;
            return (
              <Button
                key={method}
                aria-label={methodsText[method]?.title}
                state={method === 'PIX' && 'secondary'}
                disabled={loadingNegotiation}
                onClick={() => {
                  tagMiAjudaButton(
                    kebabCase(
                      `${system} ${typeNegotiationRegistered} pgto ${method}`
                    )
                  );
                  gtagSelectContent(
                    GTAG_CONTENT_PAGE,
                    GTAG_FLOW,
                    method.toLowerCase(),
                    ga4Params()
                  );
                  setPaymentMethodSelected(method);

                  return getActions({
                    typeProposal: typeNegotiationRegistered,
                    token: paymentMethods[method],
                    discount:
                      negotiationData?.eligibility[typeNegotiationRegistered]
                        ?.discount ?? null,
                    installment: details.installmentQuantity,
                  })?.request();
                }}
              >
                {method === 'PIX' ? (
                  <Pix title="pix" size="20" color="#00726C" />
                ) : (
                  <Icon icon={['mid', 'barcode']} size="1x" color="#FFF" />
                )}
                {methodsText[method]?.title}
              </Button>
            );
          })}
      </Container>
    </ContainerFadeIn>
  );
}

export default PaymentMethod;

import React from 'react';
import Accordion from '~/components/Accordion';
import * as S from './styles';
import SessionProductNotFound from '../SessionProductNotFound';
import { trackingSelectContent } from '~/analytics';

const mostQuestions = [
  {
    title: 'Quais as assistências oferecidas pela Midway?',
    answer:
      'A Midway oferece as Assistências, Residência 24h, Auto 24h e Moto 24h em parceria com a empresa CDF.',
  },
  {
    title: 'Como posso contratar uma assistência?',
    answer:
      'As assistências oferecidas pela Midway podem ser contratadas nas lojas, no aplicativo Midway e por meio dos telefones das centrais de atendimento.',
  },
  {
    title: 'Como posso acionar minha assistência?',
    answer:
      'Para acionar as assistências oferecidas pela Midway é só ligar para o número 0800 704 5025 e estaremos à disposição 24 horas por dia, 7 dias na semana para realizar o atendimento.',
  },
  {
    title: 'Como cancelar minha assistência?',
    answer:
      'Para cancelar as assistências oferecidas pela Midway é só ligar para o 0800 704 5025.',
  },
];

const handleButtonClick = faqSection => {
  const normalizedTitle = faqSection
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const formattedString = normalizedTitle.toLowerCase().replace(/\s+/g, '-');
  trackingSelectContent(`faq:${formattedString}`, 'assistencia');
};

const FaqSection = () => {
  return (
    <S.FaqSection>
      <div className="faq-section">
        <div className="container">
          <div className="title">
            <h2>Perguntas Frequentes</h2>
          </div>
          <div className="questions">
            {mostQuestions.map((question, index) => (
              <div
                key={`${index}-${question.title}`}
                onClick={() => handleButtonClick(question.title)}
              >
                <Accordion
                  title={question.title}
                  content={question.answer}
                  className="question"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container">
        <SessionProductNotFound />
      </div>
    </S.FaqSection>
  );
};
export default FaqSection;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 450px;
  height: 450px;
`;

export const CircleImage = styled.img`
  position: absolute;
  z-index: 0;
`;

export const Celular1Image = styled.img`
  position: absolute;
  width: 550px;
  z-index: 2;
`;

export const Celular2Image = styled.img`
  position: absolute;
  width: 550px;
  z-index: 1;
`;

import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const opacityAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
`;
export const ContainerGlobal = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
`;

export const RigthContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
    display: flex;
    padding-bottom: 52px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  @media (max-width: 1000px) {
    width: 100%;
    padding-bottom: 18px;
  }
`;

export const ContainerAlingCenter = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 20px;
  margin-left: 13%;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 10px;
    padding-bottom: 18px;
    margin-left: 0;
  }
`;

export const ContainerAccordion = styled.div`
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 40px;
  @media (max-width: 1000px) {
    width: 90%;
    margin-bottom: 10px;
  }
`;

export const InformationPanel = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
  width: 75%;
  @media (max-width: 1000px) {
    flex-direction: column;
    height: 1049px;
    margin-top: 212px;
    max-height: 1049px;
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    width: 90%;
    max-height: 1049px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: min-content;
  }
`;

export const LeftPanel = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
export const LeftImagePanel = styled.img`
  width: 60%;
  margin-top: -30px;
  @media (max-width: 1000px) {
    width: 65%;
    margin-top: -212px;
  }
`;

export const RightPanel = styled.div`
  width: 50%;
  max-width: 481px;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 90%;
    margin-top: 24px;
    margin-bottom: 100px;
  }
`;

export const CentralizeRightPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const TextPanel = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  padding-bottom: 32px;
  @media (max-width: 1000px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const GroupLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
`;

export const TextMidway = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  align-self: left;
  padding-bottom: 24px;
  padding-top: 36px;
  color: #1f2b2a;
  @media (max-width: 1000px) {
    text-align: center;
    align-self: center;
  }
`;

export const Agroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
`;

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;

  padding: 0 !important;
`;

export const BannerDesktop = styled.img`
  width: 100%;
  object-fit: cover;
  position: relative;

  @media (max-width: 800px) {
    display: none;
  }
`;
export const BannerMobile = styled.img`
  width: 100%;
  object-fit: cover;

  @media (min-width: 800px) {
    display: none;
  }
`;
export const TextHeaderDesktop = styled.div`
  @media (max-width: 400px) {
    display: none;
  }
`;

export const TextHeaderMobile = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 135px;
`;

export const ContainerTextImg = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 32px;
  top: 0;
  background: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0) 32.26%,
    #2f2f2f 99.26%
  );
  @media (max-width: 1000px) {
    background: linear-gradient(
      0deg,
      rgba(217, 217, 217, 0) 70.26%,
      #2f2f2f 93.08%
    );
  }
`;

export const Content = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
  }
`;

export const TextImage = styled.div`
  font-family: Montserrat;
  color: #ffff;

  font-weight: 800;
  text-transform: uppercase;
  width: 540px;
  @media (max-width: 1000px) {
    font-size: 20px;
    font-weight: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-top: 10px;
    line-height: 21.2px;
    padding-bottom: 30px;
    padding-top: 20px;
    width: 360px;
  }

  flex-direction: column;
  margin-left: 200px;
  margin-top: 120px;

  font-family: Larsseit;
  font-size: 36px;
  font-weight: 800;
  line-height: 43.2px;
  text-align: left;
`;

export const BlockParagraph = styled.p`
  font-family: Montserrat;
  color: #ffff;
  text-align: start;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
  width: 430px;
  @media (max-width: 1000px) {
    font-size: 20px;

    margin-left: 0;
    width: auto;
    text-align: left;
  }
`;

export const ButtonCard = styled.button`
  width: 325px;
  height: 48px;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 500;
  background-color: #a06d2c;
  padding: 20px 60px 20px 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 32px;
  border: none;
  transition: backgroud-color 0.5s ease;

  @media (max-width: 1000px) {
    width: 287px;
  }
`;

export const BlockStyler = styled.div`
  width: 100%;
  margin-top: 80px;
  display: flex;
  padding: 51px 200px 51px 170px;

  justify-content: space-between;
  background-color: #212121;
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 48px 42px 0px 48px;
  }
`;

export const IframeYoutube = styled.iframe`
  width: 605px;
  height: 376px;
  padding: 30px;

  @media (max-width: 1000px) {
    width: 424px;
    height: 256px;
  }
`;

export const ButtonImage = styled.button`
  border: 1px;
  width: 605px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: transparent;
  /* align-items: center; */
  :hover {
    opacity: 0.8;
    animation: ${opacityAnimation} 0.2s linear;
  }
  @media (max-width: 500px) {
    width: 284px;
    height: 156px;
  }
`;

export const ImageVideo = styled.img``;

export const Centralized = styled.div`
  margin: 0 100px;

  @media (max-width: 1000px) {
    margin: 0 16px;
  }
`;

export const ContainerCards = styled.div`
  background: var(--Colors-Marrom, #a06d2c);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px;
  margin-bottom: 30px;
  margin-top: 0px;

  @media (max-width: 1000px) {
  }
`;

export const CardIcon = styled.div`
  width: 299px;
  height: 283px;

  padding: 40px 12px 40px 12px;

  border-radius: 12px;
  opacity: 0px;
  background: var(--Colors-Off-white, #f1ebdf);
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1000px) {
    margin: 10px;
  }
`;

export const TextIconCard = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.26px;
  color: #000000;
  margin-top: 20px;
  text-align: center;
`;

export const IconContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--Colors-Marrom, #a06d2c);
`;

export const IconCard = styled.img`
  width: 42px;
  height: 42px;
`;

export const Title = styled.p`
  font-family: Montserrat !important;
  font-size: 40px !important;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 22px;
    margin-bottom: 20px;
  }
`;

export const TitleQuestions = styled.p`
  font-family: Montserrat !important;
  font-size: 28px !important;
  font-weight: 700;
  line-height: 39px;
  align-self: left;

  @media (max-width: 1000px) {
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 22px;
    align-self: left;
  }
`;

export const Sized = styled.div`
  margin-bottom: 61px;

  @media (max-width: 1000px) {
    margin-bottom: 32px;
  }
`;
export const Subtitle = styled.p`
  font-family: Montserrat !important;
  font-size: 24px !important;
  font-weight: 500;

  text-align: left;
  margin-top: 40px;
  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 22px;
  }
`;

export const ContentTexts = styled.div`
  width: 75%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 180px;
  margin-top: 100px;
  @media (max-width: 1000px) {
    margin-bottom: 100px;
    width: 90%;
    margin-top: 30px;
  }
`;

export const ContainerMessageMidway = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-left: 70px;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-top: 0;
    margin-left: 0;
  }
`;

export const RightImage = styled.img`
  width: 60%;
  margin-left: 70px;
  @media (max-width: 1000px) {
    width: 65%;
    margin-left: 0;
  }
`;

export const TextAccount = styled.p`
  font-family: Montserrat !important;
  font-size: 32px !important;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  margin-bottom: 30px;

  @media (max-width: 1000px) {
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 22px;
    margin-bottom: 20px;
  }
`;

export const SubText = styled.p`
  font-family: Montserrat !important;
  font-size: 20px !important;
  font-weight: 500;

  text-align: left;
  margin-top: 16px;
  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 22px;
  }
`;

export const ContentPointText = styled.ul`
  list-style-type: disc;
  margin-left: 13px;
`;

export const SubTextPoint = styled.li`
  list-style-type: disc;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: '#221F20';
  margin-top: 40px;
  @media (max-width: 1000px) {
    margin-top: 24px !important;
  }
`;

export const ButtonDownloadApp = styled.button`
  width: 382px;
  height: 62px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.94px;

  background-color: #1f2b2a;
  padding: 20px 60px 20px 60px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  margin-top: 48px;
  border: none;
  transition: backgroud-color 0.5s ease;

  @media (max-width: 1000px) {
    width: 300px;
    height: 48px;
    align-self: center;
  }
`;

export const SeeMOre = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 10px;
  text-align: start;
`;

export const ContainerSeeMore = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: none;
  margin-bottom: 30px;
  background: transparent;
`;

import styled, { css } from 'styled-components';

import { colors } from '~/constants';

export const Container = styled.section`
  padding-top: 96px;
  padding-bottom: 144px;
  background-color: ${colors.white};

  @media (max-width: 720px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1176px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 16px;

  @media (max-width: 720px) {
    padding: 40px 16px;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 720px) {
    text-align: left;
  }
`;

export const Title = styled.h2`
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 40px !important;
  margin-bottom: 16px;

  @media (max-width: 720px) {
    font-size: 20px !important;
    line-height: 30px !important;
    text-align: left !important;
    width: 100%;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 100%;

  @media (min-width: 1160px) {
    width: 400px;
  }
`;

export const Main = styled.main`
  padding: 32px 0px;
  padding-bottom: 96px;

  @media (max-width: 720px) {
    padding: 48px 0px;
  }

  width: 100%;
  max-width: 1176px;

  .vertical-timeline {
    margin: 0px;
    padding: 0px;
    width: inherit;
    max-width: inherit;
  }

  .vertical-timeline::before {
    width: 1px;
    height: 90% !important;
    top: 8%;

    @media (max-width: 1170px) {
      top: 0%;
      height: 95% !important;
      left: 23px;
    }
  }

  .vertical-timeline-element {
    margin: 64px 0px;

    @media (max-width: 720px) {
      margin: 20px 0px;
    }
  }

  .vertical-timeline-element-icon {
    width: 58px;
    height: 58px;
  }
  .dollar-sign-icon {
    width: 72px;
    height: 72px;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    left: 96.7%;
    margin-left: -50%;
  }
  .vertical-timeline-element-icon,
  .dollar-sign-icon {
    @media (max-width: 1170px) {
      width: 48px;
      height: 48px;
      left: 0px;
      margin-left: 0px;
    }
  }

  .vertical-timeline-element-content {
    border-radius: 0;
    -webkit-box-shadow: none;
    top: -10px;
    padding: 0px;

    p {
      margin-top: 8px;
    }

    ${({ mobile }) =>
      mobile &&
      css`
        margin-left: 72px;

        p {
          font-size: 16px;
          line-height: 20px;
        }
      `}
  }

  .custom-icon {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border: 1px solid ${colors.chumbo};
  }
`;

export const EndTimelineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-top: -60px;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      margin-top: -50px;
      padding-bottom: 0px;
    `}
`;

export const EndTimelineInfo = styled.div`
  width: 51%;
  text-align: right;
  margin-top: 60px;

  ${({ mobile }) =>
    mobile &&
    css`
      margin-top: 24px;
    `}

  h3 {
    font-weight: 500;
    font-size: 20px !important;
    line-height: 30px;
    margin-bottom: 8px;
  }

  p {
    color: ${colors.chumbo};
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 900px) {
    width: 100%;
    text-align: left;

    h3 {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
`;

export const EndTimelineImageContainer = styled.div`
  width: 45%;
`;

export const EndTimelineImage = styled.img`
  width: 212px;
  height: 202px;
  margin-left: 32px;

  ${({ mobile }) =>
    mobile &&
    css`
      width: 184px;
      height: 160px;
      margin: 0px auto;
    `}
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  gap: 22px;
  background: ${colors.light_gray};
  padding: 16px 24px;
  border-radius: 8px;

  @media (max-width: 720px) {
    flex-direction: column;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      padding: 16px;
    `}
`;

export const FooterIconContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      img {
        width: 32px;
        height: 32px;
      }
    `}
`;

export const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      gap: 16px;

      span {
        font-size: 14px;
        line-height: 18px;
      }
    `}
`;

import styled from 'styled-components';

export const ContainerCardWrapper = styled.div`
  max-width: 1176px;
  display: flex;
  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 8px;
  min-width: ${props => props.minWidth || '629px'};
  min-height: 71px;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 6px -1px #1f2b2a1f;
  @media screen and (max-width: 1000px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const CardAnchor = styled.a`
  width: 100% !important;
  display: flex;
  padding: 12px 16px 12px 16px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
`;

export const Icon = styled.img`
  width: 48px;
  height: 47px;
`;

export const Title = styled.a`
  color: #1f2b2a !important;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

import styled from 'styled-components';
import { colors } from '../../constants';

export const Footer = styled.footer`
  width: 100%;
  margin: auto;
  background-color: #252525;
`;

export const StyledFooter = styled.div`
  max-width: 1900px;
  margin: auto;
  background-color: #252525;
`;

export const Wrapper = styled.div`
  max-width: 1900px;

  margin: auto;
  background-color: ${colors.darken};
  margin: 0px 52px;
  padding: 52px;
  margin-bottom: 0;
  color: ${colors.light};
  font-size: 13px;
  position: relative;

  h3 {
    font-family: Montserrat;
    font-size: 16px !important;
    font-weight: 600;
    margin-bottom: 24px;
  }
  h4 {
    font-family: Montserrat;
    font-size: 14px !important;
    font-weight: 400;
    margin-bottom: 24px;
  }

  a,
  h3,
  h4 {
    color: #fff;
  }

  hr {
    border-bottom: 1px solid #fff;
    margin: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &.flex-1 {
    flex: 1;
  }

  .socialWrapper {
    justify-content: space-between;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &.left-spacer {
    margin-left: 32px;
  }
  &.right-spacer {
    margin-right: 32px;
  }
`;

export const MidwayLogo = styled.img`
  max-width: 176px;
  margin-bottom: 40px;
  margin-left: 32px;
`;
export const SectionIcons = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  justify-content: space-evenly;
  width: 280px;

  .SectionIcon {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(50% - 1em);
    max-width: 115px;
    text-align: left;
    align-items: flex-start;
    justify-content: center;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5;
    a {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 3px;
      transition: all 0.2s ease-out;
      &:hover {
        padding: 0px 3px 6px 3px;
      }
    }

    img {
      margin-bottom: 8px;
      width: 32px;
    }
    .sesionDescription {
      text-align: left;
    }
  }
`;

export const SocialMediaSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin-bottom: 1.5em;
  > div {
    flex: 1;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-out;
    padding: 3px;
    &:hover {
      padding: 0px 3px 6px;
    }
  }
`;

export const SocialMediaIcon = styled.div`
  width: 36px;
  height: 36px;
  margin: 2px;
  transition: all 0.2s ease-out;
`;

export const StoreIcon = styled.img`
  object-fit: contain;
  height: 47px;
  margin-bottom: 10px;
`;

export const ListLinks = styled.div`
  font-size: 13px;
  div {
    margin-bottom: 24px;
  }
`;
export const StoreSection = styled.div`
  padding-bottom: 10px;
  width: 140px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    transition: all 0.2s ease-out;

    &:hover {
      padding: 0px 3px 6px;
    }
  }
`;

export const Link = styled.a`
  font-size: 13px !important;
  font-weight: 500;
  margin-right: 5px;
  transition: all 0.2s ease-out;
  &:hover,
  &:focus {
    margin-left: 5px;
    margin-right: 0;
    text-decoration: underline;
  }
`;
export const Section = styled.div`
  padding: ${props => (props.padding ? props.padding : 0)};
`;
export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
`;

// /* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import cmsService from '~/services/cms/pages';
import Error from '~/components/Error';
import Loading from '~/components/Loading';
import logo from '~/assets/img/Documents/logo.png';
import * as S from './styles';

const MOCK_DOCUMENTS = [];

const Documents = () => {
  const [documents, setDocuments] = useState(MOCK_DOCUMENTS);
  const [filteredDocuments, setFilteredDocuments] = useState(MOCK_DOCUMENTS);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [filter, setFilter] = useState({ title: '', tag: '' });
  const [inputValue, setInputValue] = useState('');

  const errorTitle = 'Tivemos um problema';
  const errorBody = 'Não foi possível carregar.';

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 992);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleUpdateDocuments = useCallback(async () => {
    try {
      setIsError(false);
      setIsLoading(true);
      const res = await cmsService.getDocuments(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (res) {
        setIsLoading(false);
        setDocuments(JSON.parse(res));
        return;
      }
      setDocuments(MOCK_DOCUMENTS);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setDocuments(MOCK_DOCUMENTS);
    }
  }, []);

  useEffect(() => {
    handleUpdateDocuments();
  }, [handleUpdateDocuments]);

  const handleOpenDocument = document => {
    window.open(document);
  };

  const handleUpdateFilteredDocuments = useCallback(() => {
    const docs = documents.filter(document => {
      const titleMatch = document?.title
        ?.toLowerCase()
        .includes(filter.title.toLowerCase());
      const tagMatch = filter.tag === 'Todos' || document.tag === filter.tag;
      return titleMatch && tagMatch;
    });
    setFilteredDocuments(docs);
  }, [documents, filter]);

  useEffect(() => {
    handleUpdateFilteredDocuments();
  }, [handleUpdateFilteredDocuments]);

  const tags = [
    ...Array.from(new Set(documents.map(document => document.tag))),
    'Todos',
  ];

  const handleUpdateFilter = useCallback(() => {
    setFilter({ ...filter, tag: 'Contratos' });
  }, [filter]);

  useEffect(() => {
    handleUpdateFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Page
      id="ready-page-for-screen-reader"
      aria-label="Página políticas e documentos"
    >
      {isMobile ? (
        <S.Header isMobile={isMobile}>
          <S.ContainerHeader className="container">
            <S.TitleHeaderMobile>Portal De Transparência</S.TitleHeaderMobile>
            <S.h2>Políticas e documentos</S.h2>
            <S.ImageHeaderMobile>
              <img alt="logo" src={logo} />
            </S.ImageHeaderMobile>
          </S.ContainerHeader>
        </S.Header>
      ) : (
        <S.Header>
          <S.ContainerHeader className="container">
            <S.ContainerImageHeader>
              <S.ImageHeader>
                <img alt="logo" src={logo} />
              </S.ImageHeader>
              <S.ContentHeader>
                <S.TitleHeader>Portal De Transparência</S.TitleHeader>
                <S.h2>Políticas e documentos</S.h2>
              </S.ContentHeader>
            </S.ContainerImageHeader>
          </S.ContainerHeader>
          <S.HeaderLiter />
        </S.Header>
      )}

      <S.Main>
        {!isMobile && (
          <S.Section>
            <div className="container">
              <S.InputContainer>
                <S.StoreListTitle isMobile={isMobile}>
                  Documentos
                </S.StoreListTitle>
                <label htmlFor="search" aria-label={tags}>
                  <S.Input
                    type="text"
                    id="search"
                    isMobile={isMobile}
                    placeholder="Buscar documento"
                    aria-label="Barra de pesquisa:"
                    value={inputValue}
                    onChange={e => {
                      setInputValue(e.target.value);
                      setFilter({
                        ...filter,
                        title: e.target.value,
                        tag: 'Todos',
                      });
                    }}
                  />
                </label>
              </S.InputContainer>
            </div>
          </S.Section>
        )}
        {isMobile && (
          <S.Section>
            <div className="container">
              <S.StoreListTitle isMobile={isMobile}>
                Documentos
              </S.StoreListTitle>
            </div>
          </S.Section>
        )}
        {isMobile && (
          <div className="container">
            <label className="labelSearch" htmlFor="search" aria-label={tags}>
              <S.Input
                type="text"
                isMobile={isMobile}
                placeholder="Buscar documento"
                aria-label="Barra de pesquisa:"
                id="search"
                value={inputValue}
                onChange={e => {
                  setInputValue(e.target.value);
                  setFilter({ ...filter, title: e.target.value, tag: 'Todos' });
                }}
              />
            </label>
          </div>
        )}
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Error errorTitle={errorTitle} errorBody={errorBody} refreshPage />
        ) : (
          <S.Container className="container">
            <div>
              {tags.map(tag => (
                <button
                  type="button"
                  key={tag}
                  onClick={() => {
                    setInputValue('');
                    setFilter({ ...filter, title: '', tag });
                  }}
                  className={
                    filter.tag === tag ? 'tagButtonActive' : 'tagButton'
                  }
                >
                  {tag}
                </button>
              ))}
            </div>
            {filteredDocuments?.length === 0 && (
              <S.NoPageItems isMobile={isMobile}>
                Nenhum documento encontrado
              </S.NoPageItems>
            )}
            <ul>
              {filteredDocuments?.map((item, index) => (
                <S.BorderTable key={index}>
                  <S.ContentTable>
                    {!isMobile && (
                      <S.ContentFirstColumn>
                        <S.Description>
                          {moment(item.date).format('DD/MM/YYYY')}
                        </S.Description>
                      </S.ContentFirstColumn>
                    )}
                    <S.ContentSecondColumn>
                      <S.Description>{item?.title}</S.Description>
                    </S.ContentSecondColumn>
                    <S.ContentThirdColumn>
                      <S.ViewButton
                        onClick={() => handleOpenDocument(item?.pdf)}
                        aria-label={`Visualizar ${item?.title}`}
                      >
                        Visualizar
                      </S.ViewButton>
                    </S.ContentThirdColumn>
                  </S.ContentTable>
                </S.BorderTable>
              ))}
            </ul>
          </S.Container>
        )}
      </S.Main>
      <style>{S.css}</style>
    </S.Page>
  );
};

export default Documents;

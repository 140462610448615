import React, { useState } from 'react';
import { Image, Button } from '@midway/web-ui-component';
import * as S from './styles';
import closeButtom from '~/assets/img/Financial/lending/icon-closed.svg';
import iconError from '~/assets/img/Financial/lending/icon-error.svg';
import history from '~/services/history';

const Fail = props => {
  const [showModal, setShowModal] = useState(true);

  return (
    showModal && (
      <S.Modal>
        <div id="background" />
        <div className="content">
          <a onClick={() => history.push('/emprestimos/simulacao')}>
            <Image
              src={closeButtom}
              className="closeButtom"
              alt="Botão de fechar"
            />
          </a>
          <Image src={iconError} className="iconError" alt="iconError" />
          <h2>Ocorreu um erro</h2>
          <p>Infelizmente não podemos seguir com a sua solicitação. </p>
          <p>Você pode voltar ao início ou tentar novamente.</p>

          <S.WrapperButtons>
            <Button
              outline
              aria-label="Voltar ao início"
              state="primary"
              title="Voltar ao início"
              onClick={() => {
                setShowModal(false);
                history.push('/emprestimos/simulacao');
              }}
            />

            <Button
              aria-label="Tentar novamente"
              state="primary"
              title="Tentar novamente"
              onClick={props.onTryAgainClick}
            />
          </S.WrapperButtons>
        </div>
      </S.Modal>
    )
  );
};

export default Fail;

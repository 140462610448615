import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;

  ${({ mtoplast }) =>
    mtoplast &&
    css`
      @media screen and (max-width: 580px) {
        div:last-child {
          margin-top: 10px;
        }
      }
    `}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Icon } from '@midway/web-ui-component';
import * as S from './styles';

const Cards = ({
  circle,
  title,
  btnAriaLabel,
  description,
  btnTitle,
  action,
  col,
  gtmCategory,
  gtmAction,
  gtmLabel,
}) => {
  return (
    <S.ContainerCard
      className={
        col ? 'col-sm-12 col-md-12 col-lg-4 col-xl-4 effectCard' : 'effectCard'
      }
    >
      <Card hasMicroContent radius={8}>
        <S.BodyCard id="card" className="body-card" col={col}>
          <div>
            <S.IconCard color={circle.color}>
              <S.ShowIconCard>
                <Icon icon={circle.icon} size="2x" color={circle.color} />
              </S.ShowIconCard>
            </S.IconCard>
            <S.TitleCard>{title}</S.TitleCard>
            <S.DescriptionCard>
              <span>{description}</span>
            </S.DescriptionCard>
          </div>
          <div className="action">
            {btnTitle !== '' ? (
              <Button
                aria-label={btnAriaLabel}
                state="primary"
                block
                title={btnTitle}
                onClick={() => {
                  action();
                }}
                data-gtm-event-category={gtmCategory}
                data-gtm-event-action={gtmAction}
                data-gtm-event-label={gtmLabel}
              />
            ) : null}
          </div>
        </S.BodyCard>
      </Card>
    </S.ContainerCard>
  );
};

Cards.propTypes = {
  circle: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  btnTitle: PropTypes.string,
  col: PropTypes.bool,
  action: PropTypes.func,
  btnAriaLabel: PropTypes.any,
  gtmCategory: PropTypes.string,
  gtmAction: PropTypes.string,
  gtmLabel: PropTypes.string,
};

Cards.defaultProps = {
  circle: {
    color: '',
    icon: ['mid', 'menu'],
  },
  title: '',
  btnAriaLabel: '',
  description: '',
  btnTitle: '',
  col: false,
  action: () => {},
  gtmCategory: '',
  gtmAction: '',
  gtmLabel: '',
};

export default Cards;

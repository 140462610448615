import React from 'react';
import AccessibleLink from '~/components/AccessibleLink';
import * as S from './styles';

const Complaint = () => {
  return (
    <S.ContainerPage
      id="ready-page-for-screen-reader"
      aria-label="Página envio de denúncia carregada"
    >
      <S.Section className="container">
        <S.Title>
          <h1>Envie sua denúncia</h1>
        </S.Title>
        <S.Text>
          <span>
            A Midway Financeira está disponibilizando um canal para
            colaboradores, clientes, parceiros e ou fornecedores para
            recepcionar denúncias relacionadas a situações identificadas como
            ilícitas (descumprimento na aplicação de leis) conforme abaixo. A
            denúncia poderá ser identificada ou anônima.
          </span>
        </S.Text>
        <S.Text>
          <span>
            <b>IMPORTANTE:</b> Aos colaboradores das Lojas Riachuelo: assuntos
            relacionados ao ambiente de loja utilizar o canal apropriado (Comitê
            de Ética). Além disso, neste canal <b>NÃO SERÃO TRATADAS</b> as
            consultas ou reclamações envolvendo o CARTÃO RIACHUELO. Neste caso,
            utilizar os canais específicos de atendimentos,{' '}
            <AccessibleLink
              route="/atendimento"
              text="consultar os telefones."
            />
          </span>
        </S.Text>
        <S.Text>
          <span>
            Utilize o e-mail abaixo apenas em casos de ilicitude. Lembre-se que
            em sua mensagem você deve: Relatar o mais detalhado possível o que
            aconteceu para que tenhamos condições de analisar corretamente a
            situação e tomar as providências cabíveis.
          </span>
        </S.Text>
        <S.Text>
          <span>
            <b>Quem?</b> Indique o nome (s) da (s) pessoa (s) que está (ão)
            envolvida (s) e quem foi afetado pelo fato. Quem sabe do fato (que
            você tenha conhecimento)?{`\n`}
            <b>Quando?</b> Período que ocorreu a situação e se continua
            ocorrendo.{`\n`}
            <b>Onde?</b> Local onde ocorreu o fato (unidade, área, departamento,
            gerência, diretoria, etc) {`\n`}
            <b>Como?</b>
            Descrever o passo a passo de como está ocorrendo ou ocorreu o fato.
            Descrever o que causou a situação e consequências. Dizer se você tem
            certeza do fato ou se é um boato / Dizer se você é um Funcionário,
            Cliente ou Fornecedor.
          </span>
        </S.Text>
        <S.Text>
          <span>
            E , se preferir, se identificar com:{`\n`}
            <b>Nome / Razão social</b>
            {`\n`}
            <b>CPF / CNPJ</b>
            {`\n`}
            <b>Telefone</b>
            {`\n`}
            <b>Email</b>
            {`\n`}
            Dessa forma seremos capazes de prosseguir com a denúncia enviada
            para{' '}
            <a
              aria-label="Enviar email para midwaycanaldenuncia@midwayfinanceira.com.br"
              href="mailto:midwaycanaldenuncia@midwayfinanceira.com.br"
            >
              midwaycanaldenuncia@midwayfinanceira.com.br
            </a>
          </span>
        </S.Text>
      </S.Section>
    </S.ContainerPage>
  );
};

export default Complaint;

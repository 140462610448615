import React, { useState } from 'react';
import { Icon } from '@midway/web-ui-component';
import { trackingSelectContent } from '~/analytics';
import Accordion from '~/components/Accordion';
import { colors } from '~/constants';
import { frequentlyQuestionsData } from './frequentlyQuestionsData';
import {
  Container,
  Content,
  ShowMoreButton,
  ShowMoreWrapper,
  Questions,
  Title,
} from './styles';

export const FrequentlyQuestions = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
    trackingSelectContent(
      showMore ? 'faq:mostrar-menos' : 'faq:mostrar-mais-perguntas-frequentes',
      'limite-garantido'
    );
  };

  const { title } = {
    title: showMore ? 'Mostrar menos' : 'Mostrar mais perguntas frequentes',
  };

  const displayedQuestions = showMore
    ? frequentlyQuestionsData
    : frequentlyQuestionsData.slice(0, 5);

  return (
    <Container id="perguntas-frequentes">
      <Content>
        <Title>Perguntas frequentes</Title>
        <Questions>
          {displayedQuestions.map(question => (
            <div key={question.title} onClick={question.onClickFn}>
              <Accordion
                key={question.id}
                title={question.title}
                content={question.answer}
              />
            </div>
          ))}

          <ShowMoreWrapper>
            <ShowMoreButton aria-label={title} onClick={toggleShowMore}>
              <span> {title} </span>
              <Icon
                icon={['fa', 'chevron-down']}
                size="2x"
                rotate180={showMore}
                color={colors.dark_brown}
              />
            </ShowMoreButton>
          </ShowMoreWrapper>
        </Questions>
      </Content>
    </Container>
  );
};

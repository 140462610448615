/*
REGEX para substituição dos CPF`s
.(\d{3}).(\d{3})-(\d{2})'
.XXX.XXX-XX'
 */

const optionsMonths = [
  { value: '01', label: 'Janeiro' },
  { value: '02', label: 'Fevereiro' },
  { value: '03', label: 'Março' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Maio' },
  { value: '06', label: 'Junho' },
  { value: '07', label: 'Julho' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' },
];

const optionsYears = function generateArrayOfYears(YEAR) {
  const max = YEAR;
  const min = 2020;
  const years = [];
  for (let i = max; i >= min; i--) {
    years.push({ value: i, label: i });
  }
  return years;
};

const winnersIcons = title => {
  switch (title) {
    case 'Seguro Perda e Roubo':
      return ['mid', 'money-check'];
    case 'Seguro Bolsa Protegida':
      return ['mid', 'bell'];
    case 'Assistência Residencial 24h':
      return ['mid', 'locker-check'];
    case 'Seguro Acidentes Pessoais Individuais':
      return ['mid', 'profile'];
    case 'Parcela Premiada':
      return ['mid', 'gift'];
    case 'Seguro Residencial':
      return ['mid', 'home'];
    case 'Seguro Desemprego':
      return ['mid', 'like'];
    case 'Assistência Odontológica':
      return ['mid', 'medical'];
    default:
      return ['mid', 'like'];
  }
};

export { winnersIcons, optionsMonths, optionsYears };

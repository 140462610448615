import styled, { keyframes, css } from 'styled-components';
import { Icon } from '@midway/web-ui-component';

import { colors } from '~/constants';
import { ReactComponent as CreditCard } from '~/assets/img/Financial/BilletInvoiceOrLendingV2/credit-card.svg';
import { ReactComponent as Calendar } from '~/assets/img/Financial/BilletInvoiceOrLendingV2/calendar.svg';
import { ReactComponent as Cash } from '~/assets/img/Financial/BilletInvoiceOrLendingV2/cash.svg';
import { ReactComponent as QRCodeImg } from '~/assets/img/Financial/BilletInvoiceOrLendingV2/qrcode.svg';

export const Container = styled.div`
  padding: 32px;
`;

export const CreditCardIcon = styled(CreditCard)`
  & path {
    stroke: ${colors.primary};
  }
  width: 24px;
  margin-right: 8px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.primary};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const Header = styled.p`
  font-size: 18px;
  font-weight: 700;
  text-align: ${({ align }) => align};

  @media (max-width: 400px) {
    font-size: 16px;
    text-align: left;
  }

  @media (max-width: 992px) {
    text-align: left;
  }
`;

export const TotalInstallmentContainer = styled.div`
  @media (max-width: 992px) {
    margin-bottom: 12px;
  }
`;

export const Box = styled.div`
  padding: 20px 12px;
  margin-top: 16px;
  margin-bottom: 40px;
  background-color: ${colors.light_gray};
  border-radius: 4px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.dark_gray_2};

  margin-top: 12px;
`;

export const EntryBilletInfoContainer = styled.div`
  flex-grow: 1;
`;

export const EntryBilletInfoRow = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const CalendarIcon = styled(Calendar)`
  & path {
    stroke: ${colors.dark};
  }
  width: 24px;
  margin-right: 8px;
`;

export const CashIcon = styled(Cash)`
  & path {
    stroke: ${colors.dark};
  }
  width: 24px;
  margin-right: 8px;
`;

export const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

export const Value = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 6px;
`;

export const Badge = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;

  padding: 3px 8px 4px 8px;

  background-color: ${colors.light_brown};
  color: ${colors.dark_brown};
  border-radius: 4px;

  @media (max-width: 992px) {
    width: 100%;
    margin-bottom: 12px;
  }
`;

export const PaylineContainer = styled.span`
  width: 230px;
  padding: 12px;
  background-color: ${colors.white};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 8px;
  color: ${colors.dark};
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;

  @media (max-width: 992px) {
    width: 100%;
    min-height: 60px;
  }
`;

export const CopyPaylineContainer = styled.div`
  margin: auto;
  width: 250px;
  padding-top: 42px;

  @media (max-width: 992px) {
    padding-top: 12px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 42px;
  margin-top: 48px;
`;

const animatedBlur = keyframes`
  from {
    filter: blur(5px);
    opacity: 1;
  }
  to {
    filter: blur(3px);
    opacity: 0.6;
  }
`;

export const AnimatedBlurredQRCode = styled(QRCodeImg)`
  animation: ${animatedBlur} 1.2s alternate infinite;

  ${({ pause }) =>
    pause &&
    css`
      animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      -webkit-animation-play-state: paused;
    `}

  @media (max-width: 992px) {
    margin: 0 auto;
  }
`;

export const QRCodeImage = styled.img`
  width: 134px;
  height: 134px;

  @media (max-width: 992px) {
    margin: 0 auto;
  }
`;

export const ErrorWrapper = styled.div`
  width: 134px;
  height: 134px;
  position: relative;
`;

export const QRCodeTryAgainIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 46px;
  left: 46px;
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-radius: 50%;
  padding: 10px;
  transition: all 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    scale: 1.2;
  }
`;

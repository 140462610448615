import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: #f2ebe1;
  position: relative;
  margin-top: ${props => props.bannerHeight}px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-top: ${props => (props.hasMobileHeader ? '86px' : '0')};
  }

  .HeroRoot {
    img {
      object-fit: contain;
    }
    a:active,
    a:focus {
      border: 1px solid #a06d2c;
    }
  }
  .slick-dots {
    bottom: 30px;
  }
  .slick-dots li button:before {
    color: #fff;
    opacity: 1;
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #a06d2c;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-prev {
    left: 25px;
    z-index: 1;
    color: #704810;
    &:before {
      display: none;
    }
  }
  .slick-next {
    right: 25px;
    z-index: 1;
    color: #704810;
    &:before {
      display: none;
    }
  }
  .slick-arrow:active,
  .slick-arrow:focus {
    border: 1px solid #a06d2c;
  }
`;

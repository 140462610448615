import React from 'react';
import * as S from '../styles';

const SessionHeader = () => (
  <S.Session1>
    <h1>Conheça nossas Assistências</h1>
    <p>
      Assistência 24 horas por dia, 7 dias por semana e por um preço que se
      encaixa no seu orçamento! Conte com a gente.
    </p>
  </S.Session1>
);

export default SessionHeader;

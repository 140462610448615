import styled from 'styled-components';
import IconSearch from '~/assets/img/icons/IconSearch.png';

export const Page = styled.div`
  background-color: #fff;
  @media (max-width: 800px) {
    .labelSearch {
      width: 100%;
    }
  }
`;

export const Header = styled.header`
  background-color: #f1ebdf;
  width: 100%;
`;
export const Main = styled.main``;

export const InputContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const ContainerHeader = styled.div`
  padding-top: 40px;
`;

export const HeaderLiter = styled.div`
  background-color: #a06d2c;
  width: 45%;
  height: 18px;
  position: absolute;
  right: 0;
  margin-top: -18px;
`;

export const ContainerImageHeader = styled.div`
  display: flex;
`;

export const TitleHeaderMobile = styled.h1`
  font-weight: bold;
`;

export const TitleHeader = styled.h1`
  font-size: 40px !important;
  font-weight: bold;
`;

export const ImageHeaderMobile = styled.div`
  justify-content: 'center';
  margin-top: 40px;
`;

export const ImageHeader = styled.div`
  justify-content: center;
  max-width: 429px;
`;

export const ContentHeader = styled.div`
  margin-left: 35px;
  margin-top: 130px;
`;

export const StoreListTitle = styled.h2`
  padding-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
`;

export const TextHelpSection = styled.h3`
  margin-bottom: 30px;
  font-weight: bold;
`;

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 60px;
`;

export const Section = styled.section`
  padding-top: 30px;
  padding-bottom: 10px;
`;

export const Input = styled.input`
  ${props => (props.isMobile ? 'width: 100%;' : 'width: 412px;')};
  ${props => (props.isMobile ? 'margin-bottom: 10px;' : 'margin-bottom: 0px;')};
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #c8c8c8;
  border-radius: 30px;
  background-image: url(${IconSearch});
  background-position: 15px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 40px;
`;

export const h2 = styled.h2`
  font-size: 30px !important;
  margin-top: 20px;
  color: #a06d2c;
`;

export const ViewButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #a06d2c;
  font-size: 18px;
`;

export const TextBold = styled.p`
  font-weight: bold;
  font-size: 18px;
`;

export const NoPageItems = styled.p`
  ${props => (props.isMobile ? 'padding-top: 20px;' : 'padding-top: 40px;')};
  text-align: center;
  font-size: 18px;
`;

export const Description = styled.p`
  font-size: 14px;
`;

export const BorderTable = styled.li`
  border-bottom: 1px solid #dcdcdc;
`;

export const ContentTable = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  justify-content: space-between;
`;

export const ContentFirstColumn = styled.div`
  float: left;
  text-align: left;
  width: 12%;
`;

export const ContentSecondColumn = styled.div`
  float: left;
  width: 60%;
  text-align: left;
`;

export const ContentThirdColumn = styled.div`
  float: left;
  text-align: right;
  width: 28%;
`;

export const ContentViewButton = styled.div`
  width: 180px;
  text-align: right;
`;

export const css = `
  .tagButton {
    background-color: #F7F7F7;
    padding: 5px;
    border: none;
    margin-right: 13px;
    margin-bottom: 18px;
    border-radius: 24px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .tagButton:hover {
    background-color: #B48B5E;
    color: white;
  }
  .tagButtonActive {
    background-color: #B48B5E;
    color: white;
    padding: 5px;
    border: none;
    margin-right: 13px;
    margin-bottom: 18px;
    border-radius: 24px;
    padding-left: 15px;
    padding-right: 15px;
    transition: width 1s ease-in, height 1s ease-in;
  }
`;

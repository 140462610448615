import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 0;
  padding-right: 0;

  .banner {
    max-width: 1450px;
    margin: 0 auto;
    position: relative;

    @media (max-width: 767px) {
      min-height: 500px;
      max-height: auto;
    }
    img {
      max-height: 600px;
      width: 100%;
      object-fit: cover;
      @media (max-width: 767px) {
        min-height: 480px;
        max-height: 480px;
      }
    }
  }

  .banner-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 132px;
    color: #f7f7f7;
    .title {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 800;
      line-height: 43.2px;
      text-align: left;
    }
    .subtitle {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 250;
      line-height: 43.2px;
      text-align: left;
      text-transform: uppercase;
    }
    @media (max-width: 767px) {
      justify-content: flex-start;
      padding-top: 42px;
      padding-left: 32px;

      .title,
      .subtitle {
        font-size: 18px !important;
        line-height: 21px;
      }
    }
  }
`;

export const Session = styled.div`
  padding-top: 70px;

  @media (max-width: 767px) {
    padding-top: 30px;
    h2 {
      font-size: 24px !important;
      line-height: 28px;
    }
    p {
      font-size: 16px !important;
      line-height: 19.5px !important;
    }
    .minor p {
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }

  h2 {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 500;
    line-height: 39.01px;
    text-align: left;
    color: #1f2b2a;
    margin-bottom: 24px;
  }
  p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    text-align: left;
    color: #3b4443;
  }

  .minor {
    margin-bottom: 32px;
    p {
      font-size: 16px;
      line-height: 19.5px;
      margin: 16px 0 !important;
    }
  }

  .link {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-decoration: underline;
  }

  .barlink-session {
    display: flex;
    flex-direction: column;
    margin-top: 48px;

    .linkrow {
      margin-bottom: 16px;
      flex-direction: row;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        margin-bottom: 0;
        flex-direction: column;
      }
    }
  }

  .quickAccess {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 48px 0;

    .card {
      width: calc(33% - 12px);
      background-color: #eee;
      border: none;
      border-radius: 16px;
      box-shadow: 0px 4px 6px -1px #1f2b2a1f;
      padding: 24px 16px;

      h3 {
        font-family: Montserrat;
        font-size: 18px !important;
        font-weight: 700;
        line-height: 22px;
        text-align: left;
        color: #1f2b2a;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        div {
          width: 48px;
          margin-right: 16px;
        }
      }
      p {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin: 48px 0 18px;

      .card {
        width: 100%;
        margin-bottom: 24px;
        h3 {
          font-size: 15px !important;
          line-height: 19px !important;
        }
        p {
          font-size: 12px !important;
          line-height: 15px !important;
        }
      }
    }
  }

  .odontoprevImg {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 767px) {
      justify-content: center;
      padding-bottom: 24px;
    }
    div {
      width: 139px;
    }
  }
  .odontoprevTxt {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      padding-left: 30px;
      border-left: 2px solid #959d9d;
      padding-right: 50px;
      @media (max-width: 767px) {
        color: #3b4443;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border-left: none;
        border-top: 2px solid #959d9d;
        padding: 24px 0 0;
        margin: 0px;
      }
    }
  }
`;

export const BarLink = styled.a`
  width: calc(50% - 8px);
  display: flex;
  flex-direction: row;
  background-color: #eee;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  transition: all 0.2s ease-out;
  box-shadow: 0px 0px 0px rgba(31, 43, 42, 0.16);

  span {
    transition: all 0.2s ease-out;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
  }
  div {
    transition: all 0.2s ease-out;
    width: 48px;
    height: 48px;
  }

  &:hover {
    box-shadow: 0px 5px 10px rgba(31, 43, 42, 0.16);
    span {
      margin-left: 3px;
    }
    div {
      margin-right: 3px;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 24px;

    span {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }

    &:hover {
      box-shadow: none;
      span {
        margin-left: 0;
      }
      div {
        margin-right: 0;
      }
    }
  }
`;

export const GreySession = styled.div`
  background-color: #1f2b2a;
  padding: 48px 46px;
  border-radius: 8px;
  margin-top: 88px;

  h4 {
    font-family: Montserrat;
    font-size: 24px !important;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 16px;
  }

  p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 16px !important;

    span {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    margin: 60px auto 0;
    width: calc(100% - 32px);

    h4 {
      font-size: 18px !important;
      font-weight: 700;
      line-height: 22px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.5px;
    }
  }
`;

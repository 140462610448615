export function usingTab() {
  return {
    type: '@accessibility/USING_TAB',
  };
}

export function usingMouse() {
  return {
    type: '@accessibility/USING_MOUSE',
  };
}

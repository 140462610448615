import React from 'react';
import HeaderBanner from './components/HeaderBanner';
import TableAndTax from './components/TableAndTax';
import SubTitle from './components/SubTitle';
import DocumentLink from './components/DocumentLink';
import * as S from './styles';

const ServicesAndFaresTable = () => {
  return (
    <S.Container>
      <S.ContainerGlobal>
        <HeaderBanner />
        <S.Main>
          <S.Section>
            <S.Title>Tarifas conta Midway </S.Title>
            <S.SubTitle>
              Tarifas em vigência a partir de 01 de outubro de 2024
            </S.SubTitle>
            <S.Description marginBottom="0px">
              <DocumentLink />
            </S.Description>
            <TableAndTax />
          </S.Section>
          <S.Section>
            <S.Description>
              <DocumentLink />
            </S.Description>
            <SubTitle />
          </S.Section>
        </S.Main>
      </S.ContainerGlobal>
    </S.Container>
  );
};
export default ServicesAndFaresTable;

export const banner = [
  {
    name: 'BannerEmprestimoPessoal',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-emprestimo-pessoal-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-emprestimo-pessoa.jpeg',
    title:
      'Tire seus planos do papel com o empréstimo pessoal midway, empréstimo sem burocracia e com muitas facilidades para você.',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

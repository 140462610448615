import styled, { keyframes } from 'styled-components';

const opacity = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Container = styled.div`
  background: #fff;
  padding: 24px;
  width: 100%;
  min-width: 232px;
  min-height: 100px;
  border-radius: 24px;
  box-shadow: 0px 8px 12px -4px rgba(31, 43, 42, 0.16);
  animation: ${opacity} 0.2s linear;

  @media (min-width: 1024px) {
    width: 422px;
  }
`;

export const Title = styled.p`
  color: #1f2b2a;
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  padding-bottom: 8px;
`;

export const ContainerTexts = styled.div`
  padding: 18px 0;
  width: 80%;
`;

export const Text = styled.p`
  color: #616b6a;
  font-weight: ${({ bold }) => (bold ? 700 : 0)};
  font-size: 11px;
`;

export const Spotlight = styled.span`
  color: #00726d;
`;

import { takeLatest, put, all } from 'redux-saga/effects';
import { usingTab, usingMouse } from './actions';

const tab = () => {
  put(usingTab());
};

const mouse = () => {
  put(usingMouse());
};

export default all([
  takeLatest('@accessibility/USING_TAB', tab),
  takeLatest('@accessibility/USING_MOUSE', mouse),
]);

import { colors } from '~/constants';
import styled from 'styled-components';

export const WizardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const WizardSubtitle = styled.span`
  font-size: 0.875rem;
  color: ${colors.chumbo};
`;

export const WizardText = styled.h2`
  font-size: 1.25rem !important;
  font-weight: 500;
`;

export const highlightedStyle = {
  color: colors.orangeRoof,
};

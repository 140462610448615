import styled from 'styled-components';
import { colors } from '~/constants';

export const Container = styled.div`
  position: relative;
  border-radius: 32px;
  min-width: 200px;
`;

export const Dropdown = styled.div`
  max-height: 320px;
  width: 320px;
  overflow-y: scroll;

  padding-top: 14px;

  position: absolute;
  left: -62px;
  background-color: ${colors.white};
  border-radius: 12px;

  z-index: 1;

  @media (max-width: 992px) {
    left: -52px;
  }
`;

export const InstallmentItem = styled.div`
  padding-top: 6px;
  padding-right: 20px;
  padding-left: 20px;

  cursor: pointer;

  transition: 0.2s ease-in-out;
  &:hover {
    background-color: ${colors.light_gray};
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${colors.gray};
`;

export const Text = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 4px;

  color: ${colors.dark};
`;

export const Header = styled(Text)`
  font-weight: 600;
`;

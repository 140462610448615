export const banner = [
  {
    name: 'BannerSeguroAcidentesPessoais',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-acidente-pessoal-mobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/banner-site-seguro-ap-desktop.png',
    title:
      'Cuide de si mesmo e de quem você ama. Conte com o Seguro de Acidentes Pessoais',
    subtitle: '',
    icon: '',
    enabled: true,
  },
];

import React, { useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import Error from '~/components/Error';
import Loading from '~/components/Loading';
import logo from '~/assets/img/SearchStores/logo.png';
import IconLink from '~/assets/img/icons/IconLink.png';
import IconHelp from '~/assets/img/icons/IconHelp.png';
import Modal from './components/Modal';
import PaginatedList from './components/PaginatedList';
import * as S from './styles';

const SearchStores = () => {
  const [stores, setStores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [storeModal, setStoreModal] = useState('');
  const [addressModal, setAddressModal] = useState('');
  const [stateModal, setStateModal] = useState('');
  const [phoneStoreModal, setPhoneStoreModal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [swiper, setSwiper] = useState();

  const itemsByPage = 10;
  const errorTitle = 'Tivemos um problema';
  const errorBody = 'Não foi possível carregar.';

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    cmsService
      .getStores(process.env.REACT_APP_SITE_CMS_ENV)
      .then(res => {
        setIsLoading(false);
        setStores(JSON.parse(res));
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleModal = value => {
    setStoreModal(value?.storeName);
    setAddressModal(value?.address);
    setStateModal(value?.state);
    setPhoneStoreModal(value?.phoneStore);
    setShowModal(!showModal);
  };

  const handleInputChange = e => {
    setFilter(e?.target?.value);
    swiper.slideTo(0);
  };

  const handleSwiper = index => {
    setSwiper(index);
  };

  const itemsFiltered = stores.filter(
    item =>
      item?.storeName?.toLowerCase()?.includes(filter?.toLowerCase()) ||
      item?.address?.toLowerCase()?.includes(filter?.toLowerCase()) ||
      item?.state?.toLowerCase()?.includes(filter?.toLowerCase())
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  return (
    <S.Page
      id="ready-page-for-screen-reader"
      aria-label="Página encontrar a loja mais próxima de você"
    >
      {isMobile ? (
        <S.Header isMobile={isMobile}>
          <S.ContainerHeader className="container">
            <S.TitleHeaderMobile>
              Encontre a loja mais próxima de você
            </S.TitleHeaderMobile>
            <S.ImageHeaderMobile>
              <img alt="Logo" src={logo} />
            </S.ImageHeaderMobile>
            <S.h2>
              Você encontra o Atendimento Midway em qualquer loja Riachuelo.
              <br />
              <br />
              Localize a loja mais próxima de você ou facilite seu dia a dia
              criando sua conta Midway! E tenha um app com diversos serviços
              para autoatendimento.
            </S.h2>
          </S.ContainerHeader>
        </S.Header>
      ) : (
        <S.Header>
          <S.ContainerHeader className="container">
            <S.ContainerImageHeader>
              <S.ImageHeader>
                <img alt="Logo" src={logo} />
              </S.ImageHeader>
              <S.ContentHeader>
                <S.TitleHeader>
                  Encontre a loja mais próxima de você
                </S.TitleHeader>
                <br />
                <br />
                <S.h2>
                  Você encontra o Atendimento Midway em qualquer loja Riachuelo.
                  <br />
                  <br />
                  Localize a loja mais próxima de você ou facilite seu dia a dia
                  criando sua conta Midway! E tenha um app com diversos serviços
                  para autoatendimento.
                </S.h2>
              </S.ContentHeader>
            </S.ContainerImageHeader>
          </S.ContainerHeader>
        </S.Header>
      )}
      {!isMobile && (
        <S.Section>
          <div className="container">
            <S.InputContainer>
              <S.StoreListTitle isMobile={isMobile}>
                Lojas Riachuelo
              </S.StoreListTitle>
              <S.Input
                type="text"
                isMobile={isMobile}
                placeholder="Pesquise por lojas, endereço e cidade"
                onChange={handleInputChange}
              />
            </S.InputContainer>
          </div>
        </S.Section>
      )}
      {isMobile && (
        <S.Section>
          <div className="container">
            <S.StoreListTitle isMobile={isMobile}>
              Lojas Riachuelo
            </S.StoreListTitle>
          </div>
        </S.Section>
      )}
      {isMobile && (
        <div className="container">
          <S.Input
            type="text"
            isMobile={isMobile}
            placeholder="Buscar Loja"
            onChange={handleInputChange}
          />
        </div>
      )}
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Error errorTitle={errorTitle} errorBody={errorBody} refreshPage />
      ) : (
        <S.Container className="container">
          <PaginatedList
            itens={itemsFiltered}
            handleSwiper={handleSwiper}
            handleModal={handleModal}
            isMobile={isMobile}
          />
          {!isMobile && (
            <S.ButtonsPaginationContainer>
              <S.ButtonsPagination>
                {itemsFiltered.length > 10 && (
                  <S.ButtonLeftPagination onClick={() => swiper.slidePrev()}>
                    {'<'}
                  </S.ButtonLeftPagination>
                )}
                {itemsFiltered.length > 10 && (
                  <S.ButtonRightPagination
                    onClick={() => swiper.slideNext()}
                    disabled={
                      currentPage ===
                      Math.ceil(itemsFiltered?.length / itemsByPage)
                    }
                  >
                    {'>'}
                  </S.ButtonRightPagination>
                )}
              </S.ButtonsPagination>
            </S.ButtonsPaginationContainer>
          )}
          <S.ContainerHelpSection>
            <S.TextHelpSection isMobile={isMobile}>
              Precisando de ajuda?
            </S.TextHelpSection>
            <S.HelpSection isMobile={isMobile}>
              <S.CardHelpSection isMobile={isMobile}>
                <S.HeaderCardHelpSection>
                  <S.TitleCardHelpSection>
                    Cartão Riachuelo
                  </S.TitleCardHelpSection>
                </S.HeaderCardHelpSection>
                <S.LinkCardHelpSection
                  to="/consulta-boleto"
                  aria-label="Pagar fatura"
                >
                  <S.ImageCardHelpSection>
                    <img alt="Logo" src={IconLink} />
                  </S.ImageCardHelpSection>
                  <S.TextLinkCardHelpSection>
                    Pagar fatura
                  </S.TextLinkCardHelpSection>
                </S.LinkCardHelpSection>
                <S.ExternalLinkCardHelpSection href="https://atendimento.riachuelo.com.br/">
                  <S.ImageCardHelpSection>
                    <img alt="Logo" src={IconLink} />
                  </S.ImageCardHelpSection>
                  <S.TextLinkCardHelpSection>
                    Acessar ajuda RCHLO
                  </S.TextLinkCardHelpSection>
                </S.ExternalLinkCardHelpSection>
                <S.LinkCardHelpSection
                  to="/campanha-quitacao"
                  aria-label="Negociar dívida"
                >
                  <S.ImageCardHelpSection>
                    <img alt="Logo" src={IconLink} />
                  </S.ImageCardHelpSection>
                  <S.TextLinkCardHelpSection>
                    Negociar dívida
                  </S.TextLinkCardHelpSection>
                </S.LinkCardHelpSection>
              </S.CardHelpSection>
              <S.CardHelpSection isMobile={isMobile}>
                <S.HeaderCardHelpSection>
                  <S.TitleCardHelpSection>
                    Empréstimo pessoal
                  </S.TitleCardHelpSection>
                </S.HeaderCardHelpSection>
                <S.LinkCardHelpSection
                  to="/consulta-boleto"
                  aria-label="Pagar fatura"
                >
                  <S.ImageCardHelpSection>
                    <img alt="Logo" src={IconLink} />
                  </S.ImageCardHelpSection>
                  <S.TextLinkCardHelpSection>
                    Pagar boleto
                  </S.TextLinkCardHelpSection>
                </S.LinkCardHelpSection>
                <S.LinkCardHelpSection
                  to="/campanha-quitacao"
                  aria-label="Negociar dívida"
                >
                  <S.ImageCardHelpSection>
                    <img alt="Logo" src={IconLink} />
                  </S.ImageCardHelpSection>
                  <S.TextLinkCardHelpSection>
                    Negociar dívida
                  </S.TextLinkCardHelpSection>
                </S.LinkCardHelpSection>
              </S.CardHelpSection>
              <S.LastCardHelpSection isMobile={isMobile}>
                <S.TitleLastCardHelpSection isMobile={isMobile}>
                  Aconteceu algum imprevisto?
                </S.TitleLastCardHelpSection>
                <S.SubTitleLastCardHelpSection isMobile={isMobile}>
                  Negocie seus débitos online com até 90% de desconto
                </S.SubTitleLastCardHelpSection>
                <S.LinkLastCardHelpSection
                  isMobile={isMobile}
                  to="/campanha-quitacao"
                  aria-label="Negociar dívida"
                >
                  <S.ImageLastCardHelpSection isMobile={isMobile}>
                    <img alt="Logo" src={IconHelp} />
                  </S.ImageLastCardHelpSection>
                  <S.TextLinkLastCardHelpSection isMobile={isMobile}>
                    Negociar dívida
                  </S.TextLinkLastCardHelpSection>
                </S.LinkLastCardHelpSection>
              </S.LastCardHelpSection>
            </S.HelpSection>
          </S.ContainerHelpSection>
        </S.Container>
      )}
      <Modal
        handleModal={handleModal}
        show={showModal}
        storeModal={storeModal}
        addressModal={addressModal}
        stateModal={stateModal}
        phoneStoreModal={phoneStoreModal}
      />
    </S.Page>
  );
};

export default SearchStores;

import styled from 'styled-components';

export const DiscountContainer = styled.span`
  background: #006464;
  color: #fff;
  border-radius: 72px;
  max-width: max-content;
  font-weight: 500;
  padding: 0 8px;
`;

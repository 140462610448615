import { useSelector } from 'react-redux';

const useInsiderQueue = () => {
  const { toogles } = useSelector(state => state.featuretoggle);

  const insiderQueueUser = user_content => {
    if (!toogles.isEnableInsider) return;

    window.InsiderQueue = window.InsiderQueue || [];
    window.InsiderQueue.push({
      type: 'user',
      value: user_content,
    });

    window.InsiderQueue.push({
      type: 'init',
    });
  };

  const insiderQueueCustom = (event_custom_name, event_custom_params) => {
    if (!toogles.isEnableInsider) return;

    window.InsiderQueue.push({
      type: 'custom_event',
      value: [
        {
          event_name: event_custom_name,
          event_parameters: event_custom_params,
        },
      ],
    });
  };

  return { insiderQueueUser, insiderQueueCustom };
};

export default useInsiderQueue;

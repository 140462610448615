import React from 'react';
import useIsMobile from '../../../../hooks/useIsMobile';
import * as S from './styles';

const RenderBannerImage = banner => {
  const mobile = useIsMobile();

  const ImageRender = () => (
    <S.ImageRender
      src={mobile ? banner.imgMobile : banner.img}
      alt={banner.title}
      width={1440}
      height={480}
    />
  );

  if (banner.redirecUrl) {
    return (
      <div>
        <a href={banner.redirecUrl}>
          <ImageRender />
        </a>
      </div>
    );
  }

  return <ImageRender />;
};

export default RenderBannerImage;

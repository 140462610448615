import React from 'react';
import styled from 'styled-components';
import trackEventGa from '../GoogleAnalytics';

const PartnersSection = styled.section`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 44px 150px;
    gap: 36px;
  }
`;

const Title = styled.h2`
  color: #061c3d;
  font-weight: bold;
  font-size: 40px;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: left;
    font-size: 36px;
  }
`;

const Subtitle = styled.p`
  color: #061c3d;
  font-size: 16px;
  text-align: left;

  strong {
    font-weight: bold;
  }
`;

const PartnersItems = styled.ul`
  display: flex;
  gap-x: 10px;
  gap-y: 6px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

const PartnersItem = styled.li`
  max-width: 50%;
`;

const ItemImage = styled.img`
  max-width: 62px;

  @media (min-width: 1024px) {
    max-width: 95px;
  }
`;

const CtaButton = styled.a`
  margin-top: 34px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: black;
  padding: 20px 0;
  width: 100%;

  @media (min-width: 1024px) {
    font-size: 16px;
    width: 579px;
    margin: 0 auto;
  }
`;

const Parceiros = () => {
  const partnersItems = [
    '/extrafarma.png',
    '/shopclub.png',
    '/paguemenos.png',
    '/mobly.png',
    '/merqueo.png',
    '/buser.png',
    '/veloe.png',
    '/justo.png',
    '/cnago.png',
    '/extra.png',
  ];

  const handleEvent = buttonName => {
    trackEventGa(buttonName);
  };

  return (
    <PartnersSection id="parceiros">
      <Title>PARCEIROS</Title>
      <Subtitle>
        A Riachuelo e a Mastercard uniram-se aos seus parceiros para trazer a
        você super benefícios em gastronomia, lazer, pets, saúde e muito mais.
        Com seu <strong>Cartão Riachuelo Mastercard</strong> você tem descontos
        imperdíveis de até <strong>40%</strong> em nossos parceiros exclusivos!
      </Subtitle>
      <PartnersItems>
        {partnersItems.map((item, index) => (
          <PartnersItem key={index}>
            <ItemImage src={item} alt="" />
          </PartnersItem>
        ))}
      </PartnersItems>
      <CtaButton
        href="https://www.midway.com.br/parceiros-cartao-rchlo"
        target="_blank"
        aria-label="Botão: Conheça as parcerias exclusivas"
        onClick={() => handleEvent('saiba-mais:parceiros')}
      >
        Conheça as parcerias exclusivas
      </CtaButton>
    </PartnersSection>
  );
};

export default Parceiros;

import styled from 'styled-components';

export const ThirdBanner = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  img {
    padding: 10% 0 0 0;
    width: 70%;
  }

  .container {
    display: flex;
    flex-direction: column;

    img {
      align-self: center;
    }
    @media screen and (max-width: 768px) {
      margin-top: 30px;

      img {
        width: 100%;
        margin-bottom: 20px;
      }

      .espacamento {
        margin-top: 25px;
      }
    }
  }

  .conteudo {
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .left-side {
    width: 20%;

    div {
      width: 60%;
    }
  }

  .no-espacamento {
    margin: 0;
    @media screen and (max-width: 768px) {
      margin-top: 25px;
    }
  }

  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;

    img {
      height: 80%;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100% !important;

      div {
        width: 100%;

        h1 {
          width: 100%;
        }
      }
    }
  }

  .left-side div {
    width: 100%;
  }

  .right-side {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    .right-side {
      margin-bottom: 100px;
      width: 100%;
    }
  }

  p.title {
    font: bold 50px 'Larsseit-ExtraBold' !important;
    color: #000;
    line-height: 50px;
    text-transform: uppercase;
    width: 100%;

    @media screen and (max-width: 480px) {
      font: bold 36px 'Larsseit-ExtraBold' !important;
      letter-spacing: 0px;
      color: #000000;
    }
  }

  p.subtitle {
    margin-top: 10px;
    font-size: 30px !important;
    padding-right: 60px;

    b {
      font-weight: bold;
    }

    @media screen and (max-width: 768px) {
      padding-right: 0px;
      margin-top: 10px;
      font: 22px Montserrat !important;
      letter-spacing: 0px;
      color: #000000;
      width: 100%;
    }
  }

  .mid-is-rchlo {
    height: 80px;
    position: absolute;
    bottom: 55px;
    right: 0;
  }
  #app {
    width: '100%';
  }

  a {
    background: #000000;
    padding: 20px 0px;
    border-radius: 50px;
    color: #47c8cd;
    font-size: 15px;
    max-width: 300px;
    text-align: center;
    margin-top: 30px;
    transition: all 0.2s ease-out;
    &:hover {
      margin-top: 27px;
      margin-bottom: 2px;
      text-decoration: none;
      color: #47c8cd;
    }
  }

  ul {
    list-style-type: disc;

    li {
      margin-left: 5%;
      font-size: 24px !important;

      @media screen and (max-width: 768px) {
        font-size: 22px !important;
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import * as Styles from './styles';

const SelectCardRadioBox = ({
  id,
  selected,
  onSelect,
  accessibilityLabel,
  testId,
  children,
  title,
  disabled,
  labelText,
}) => {
  const handleContainerClick = () => !disabled && onSelect(id);

  return (
    <Styles.Container>
      <Styles.TopContainer
        isExpanded={selected}
        aria-label={accessibilityLabel}
        onClick={handleContainerClick}
        data-testid={testId}
      >
        <>
          <Styles.InputRadioContainer>
            {!disabled && (
              <Styles.DotBackground>
                <Styles.InnerDot
                  data-testid="radio-box-inner-dot"
                  isChecked={selected}
                />
              </Styles.DotBackground>
            )}
            <Styles.LabelRadio isExpanded={selected}>{title}</Styles.LabelRadio>
            {labelText && (
              <Styles.LabelTextContainer>{labelText}</Styles.LabelTextContainer>
            )}
          </Styles.InputRadioContainer>
        </>
      </Styles.TopContainer>
      <Styles.BottomContainer>
        <Styles.AnimatedCard
          data-testid="radio-box-animated-card"
          isExpanded={selected}
        >
          {children}
        </Styles.AnimatedCard>
      </Styles.BottomContainer>
    </Styles.Container>
  );
};

SelectCardRadioBox.propTypes = {
  id: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  accessibilityLabel: PropTypes.string,
  testId: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
};

export default React.memo(SelectCardRadioBox);

import React from 'react';
import * as S from './styles';

export default function Loading({ size = 20, verticalMargin = 100 }) {
  return (
    <S.Container size={size} verticalMargin={verticalMargin}>
      <div className="loader" />
    </S.Container>
  );
}

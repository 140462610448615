import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@midway/web-ui-component';
import { lowerCase } from 'lodash';
import { useNegotiation } from '~/context/negotiationV2';
import { gtagSelectContent } from '~/utils/TaggingGA4';
import { isWorkSchedule } from '~/utils/negotiationHelpers';
import { Container } from './styles';

const GTAG_FLOW = 'miajuda';

const CardRedirectWP = ({ overDueDays, totalAmount }) => {
  const { system, negotiationData } = useNegotiation();

  const days = 200;
  const value = 300;
  const { toogles } = useSelector(state => state.featuretoggle);

  const onHandleRedirect = () => {
    const contentType = isWorkSchedule()
      ? 'negociar-agora:whatsapp'
      : 'negociar-agora:monest';
    const params = {
      sistema: lowerCase(system),
      contrato: negotiationData?.selectedContract?.contract || '',
    };
    const linkForWorkSchedule =
      'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1%21+Estava+no+portal+negocia%C3%A7%C3%B5es+mi+ajuda+e+gostaria+de+atendimento.&type=phone_number&app_absent=0';

    const link = isWorkSchedule()
      ? linkForWorkSchedule
      : toogles.isEnableMonestLink
        ? 'https://wa.me/message/UEC33LPBGBQBJ1'
        : linkForWorkSchedule;

    gtagSelectContent(
      'visualizacao-de-proposta',
      GTAG_FLOW,
      contentType,
      params
    );
    window.open(link, '_blank');
  };

  if (!toogles.isEnabledCardRedirectWP) return <></>;
  if (overDueDays < days || totalAmount < value) return <></>;

  const text = isWorkSchedule()
    ? 'Prefere negociar diretamente com um consultor?'
    : 'Prefere negociar via chat?';

  return (
    <Container>
      <span>{text}</span>
      <Button
        outline
        title="Negociar agora"
        type="submit"
        onClick={() => onHandleRedirect()}
        leftIcon={['fab', 'whatsapp']}
        aria-label="Negociar agora"
        stateIcon="primary"
        size="sm"
        iconSize="2x"
        disabled={false}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </Container>
  );
};
export default CardRedirectWP;

import React from 'react';
import { Breadcrumb } from '@midway/web-ui-component';
import history from '~/services/history';
import AccessibleLink from '~/components/AccessibleLink';

import * as S from './styles';

const FinancialStatements = () => {
  return (
    <S.Container
      id="ready-page-for-screen-reader"
      aria-label="Página de Demonstrações financeiras carregada"
    >
      <Breadcrumb
        routeBack="/midway#history"
        textRouteBack="Nossa história"
        actualPageText="Demonstrações Financeiras"
        history={history}
      />
      <S.Section>
        <S.Container className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <S.Title>Demonstrações Financeiras</S.Title>
              <S.Text>
                Disponibilizamos aqui nossas demonstrações financeiras.
              </S.Text>
            </div>
          </div>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <S.Subtitle>Publicações Mensais</S.Subtitle>
              <S.MonthlyPublish>
                <ol>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Março de 2023"
                      route="/contrato/BALANCETE_032023"
                      text="Balancete Março/2023"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Fevereiro de 2023"
                      route="/contrato/BALANCETE_022023"
                      text="Balancete Fevereiro/2023"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Janeiro de 2023"
                      route="/contrato/BALANCETE_012023"
                      text="Balancete Janeiro/2023"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Dezembro de 2022"
                      route="/contrato/BALANCETE_122022"
                      text="Balancete Dezembro/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Novembro de 2022"
                      route="/contrato/BALANCETE_112022"
                      text="Balancete Novembro/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Outubro de 2022"
                      route="/contrato/BALANCETE_102022"
                      text="Balancete Outubro/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Setembro de 2022"
                      route="/contrato/BALANCETE_092022"
                      text="Balancete Setembro/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Agosto de 2022"
                      route="/contrato/BALANCETE_082022"
                      text="Balancete Agosto/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Julho de 2022"
                      route="/contrato/BALANCETE_072022"
                      text="Balancete Julho/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Junho de 2022"
                      route="/contrato/BALANCETE_062022"
                      text="Balancete Junho/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Maio de 2022"
                      route="/contrato/BALANCETE_052022"
                      text="Balancete Maio/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Abril de 2022"
                      route="/contrato/BALANCETE_042022"
                      text="Balancete Abril/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Março de 2022"
                      route="/contrato/BALANCETE_032022"
                      text="Balancete Março/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Fevereiro de 2022"
                      route="/contrato/BALANCETE_022022"
                      text="Balancete Fevereiro/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Janeiro de 2022"
                      route="/contrato/BALANCETE_012022"
                      text="Balancete Janeiro/2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Dezembro de 2021"
                      route="/contrato/BALANCETE_122021"
                      text="Balancete Dezembro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Novembro de 2021"
                      route="/contrato/BALANCETE_112021"
                      text="Balancete Novembro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Outubro de 2021"
                      route="/contrato/BALANCETE_102021"
                      text="Balancete Outubro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Setembro de 2021"
                      route="/contrato/BALANCETE_092021"
                      text="Balancete Setembro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Agosto de 2021"
                      route="/contrato/BALANCETE_082021"
                      text="Balancete Agosto/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Julho de 2021"
                      route="/contrato/BALANCETE_072021"
                      text="Balancete Julho/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Junho de 2021"
                      route="/contrato/BALANCETE_062021"
                      text="Balancete Junho/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Outubro de 2021"
                      route="/contrato/BALANCETE_102021"
                      text="Balancete Outubro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Setembro de 2021"
                      route="/contrato/BALANCETE_092021"
                      text="Balancete Setembro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Agosto de 2021"
                      route="/contrato/BALANCETE_082021"
                      text="Balancete Agosto/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Julho de 2021"
                      route="/contrato/BALANCETE_072021"
                      text="Balancete Julho/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Junho de 2021"
                      route="/contrato/BALANCETE_062021"
                      text="Balancete Junho/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Maio de 2021"
                      route="/contrato/BALANCETE_052021"
                      text="Balancete Maio/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Abril de 2021"
                      route="/contrato/BALANCETE_042021"
                      text="Balancete Abril/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Março de 2021"
                      route="/contrato/BALANCETE_032021"
                      text="Balancete Março/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Fevereiro de 2021"
                      route="/contrato/BALANCETE_022021"
                      text="Balancete Fevereiro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Janeiro de 2021"
                      route="/contrato/BALANCETE_012021"
                      text="Balancete Janeiro/2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Dezembro de 2020"
                      route="/contrato/BALANCETE_122020"
                      text="Balancete Dezembro/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Novembro de 2020"
                      route="/contrato/BALANCETE_112020"
                      text=" Balancete Novembro/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Outubro de 2020"
                      route="/contrato/BALANCETE_102020"
                      text=" Balancete Outubro/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Setembro de 2020"
                      route="/contrato/BALANCETE_092020"
                      text=" Balancete Setembro/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Agosto de 2020"
                      route="/contrato/BALANCETE_082020"
                      text=" Balancete Agosto/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Julho de 2020"
                      route="/contrato/BALANCETE_072020"
                      text=" Balancete Julho/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      route="/contrato/BALANCETE_062020"
                      text=" Balancete Junho/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      route="/contrato/BALANCETE_052020"
                      text=" Balancete Maio/2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Abril de 2020"
                      text="Balancete Abril/2020"
                      route="/contrato/BALANCETE_042020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Março de 2020"
                      text="Balancete Março/2020"
                      route="/contrato/BALANCETE_032020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Fevereiro de 2020"
                      text="Balancete Fevereiro/2020"
                      route="/contrato/BALANCETE_022020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Janeiro de 2020"
                      text="Balancete Janeiro/2020"
                      route="/contrato/BALANCETE_012020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Dezembro de 2019"
                      text="Balancete Dezembro/2019"
                      route="/contrato/BALANCETE_122019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Novembro de 2019"
                      text=" Balancete Novembro/2019"
                      route="/contrato/BALANCETE_112019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Outubro de 2019"
                      text="Balancete Outubro/2019"
                      route="/contrato/BALANCETE_102019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Setembro de 2019"
                      text=" Balancete Setembro/2019"
                      route="/contrato/BALANCETE_092019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Agosto de 2019"
                      text="Balancete Agosto/2019"
                      route="/contrato/BALANCETE_082019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Julho de 2019"
                      text="Balancete Julho/2019"
                      route="/contrato/BALANCETE_072019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Junho de 2019"
                      text="Balancete Junho/2019"
                      route="/contrato/BALANCETE_062019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Maio de 2019"
                      text="Balancete Maio/2019"
                      route="/contrato/BALANCETE_052019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Abril de 2019"
                      text="Balancete Abril/2019"
                      route="/contrato/BALANCETE_042019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Março de 2019"
                      text="Balancete Março/2019"
                      route="/contrato/BALANCETE_032019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Fevereiro de 2019"
                      text="Balancete Fevereiro/2019"
                      route="/contrato/BALANCETE_022019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balancete Janeiro de 2019"
                      text="Balancete Janeiro/2019"
                      route="/contrato/BALANCETE_012019"
                      hasUnderline
                    />
                  </li>
                </ol>
              </S.MonthlyPublish>
            </div>
          </div>
        </S.Container>
      </S.Section>
      <S.Section>
        <S.Container className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <S.Subtitle>Publicações Semestrais</S.Subtitle>
              <S.SemiannualPublish>
                <ol>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2023"
                      text="Balanço e Resultado 1° Semestre / 2023"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2023"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 2° Semestre de 2022"
                      text="Balanço e Resultado 2° Semestre / 2022"
                      route="/contrato/PUBLICACAO_MIDWAY_DEZ_2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2022"
                      text="Balanço e Resultado 1° Semestre / 2022"
                      route="/contrato/PUBLICACAO_MIDWAY_JAN_2022"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 2° Semestre de 2021"
                      text="Balanço e Resultado 2° Semestre / 2021"
                      route="/contrato/PUBLICACAO_MIDWAY_DEZ_2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2021"
                      text="Balanço e Resultado 1° Semestre / 2021"
                      route="/contrato/PUBLICAÇÃO_MIDWAY_JUN_2021"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 2° Semestre de 2020"
                      text="Balanço e Resultado 2° Semestre / 2020"
                      route="/contrato/PUBLICACAO_MIDWAY_DEZ_2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2020"
                      text="Balanço e Resultado 1° Semestre / 2020"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2020"
                      text="Balanço e Resultado 1° Semestre / 2020"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2020"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 2° Semestre de 2019"
                      text="Balanço e Resultado 2° Semestre / 2019"
                      route="/contrato/PUBLICACAO_MIDWAY_DEZ_2019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2019"
                      text="Balanço e Resultado 1° Semestre / 2019"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2019"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 2° Semestre de 2018"
                      text="Balanço e Resultado 2° Semestre / 2018"
                      route="/contrato/PUBLICACAO_MIDWAY_DEZ_2018"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2018"
                      text="Balanço e Resultado 1° Semestre / 2018"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2018"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 2° Semestre de 2017"
                      text="Balanço e Resultado 2° Semestre / 2017"
                      route="/contrato/PUBLICACAO_MIDWAY_DEZ_2017"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2017"
                      text="Balanço e Resultado 1° Semestre / 2017"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2017"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 2° Semestre de 2016"
                      text="Balanço e Resultado 2° Semestre / 2016"
                      route="/contrato/PUBLICACAO_MIDWAY_DEZ_2016"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2016"
                      text="Balanço e Resultado 1° Semestre / 2016"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2016"
                      hasUnderline
                    />
                  </li>
                  <li>
                    <AccessibleLink
                      aria-label="Balanço e Resultado 1° Semestre de 2015"
                      text="Balanço e Resultado 1° Semestre / 2015"
                      route="/contrato/PUBLICACAO_MIDWAY_JUN_2015"
                      hasUnderline
                    />
                  </li>
                </ol>
              </S.SemiannualPublish>
            </div>
          </div>
        </S.Container>
      </S.Section>
    </S.Container>
  );
};

export default FinancialStatements;

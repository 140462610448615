import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #211f20;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const BannerContainer = styled.div`
  width: 100%;
  max-width: 1442px;
  position: relative;
  object-fit: cover;
`;

export const CentralizeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 16px;

  @media (max-width: 1000px) {
  }
`;

export const CentralizeGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 130px;
  margin-top: 86px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left-start;

  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 80px;
    margin-top: 32px;
  }
`;
export const Title = styled.h2`
  font-family: Montserrat;
  font-size: 32px !important;
  font-weight: 500;
  line-height: 39px;

  @media (max-width: 1000px) {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 22px;
  }
`;
export const CentralizePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin-bottom: 180px;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const InformationPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const StyleAnchor = styled.a`
  color: #4e4b59;
  margin-top: 20px;
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: inherit;
  }
  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const TextFinal = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

import React from 'react';
import * as S from './styles';
import { trackingSelectContent } from '~/analytics';

const TextPhoneNumber = ({ text, phoneNumber, trackingId, trackingFlow }) => {
  const handlePhoneNumberClick = () => {
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(
      navigator.userAgent
    );
    if (isMobileDevice) {
      window.location.href = `tel:${phoneNumber}`;
    }
    trackingSelectContent(trackingId, trackingFlow);
  };

  return (
    <S.ContentPhone>
      <S.Text>{text}</S.Text>
      <S.Text onClick={handlePhoneNumberClick}>
        <S.UnderlineText>{phoneNumber}</S.UnderlineText>
      </S.Text>
    </S.ContentPhone>
  );
};

export default React.memo(TextPhoneNumber);

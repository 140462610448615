import axios from 'axios';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_MIDWAY_URL,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': process.env.REACT_APP_API_KEY_MGM,
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import AppleStore from '~/assets/img/apple-store.png';
import GooglePlay from '~/assets/img/google-play.png';
import Print from '../../assets/img/home/print.png';
import * as S from './styles';

const LookApp = ({ title, subtitle, maxHeight }) => {
  return (
    <>
      <S.TutorialContainer className="space-layout" maxHeight={maxHeight}>
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <S.TutorialMobile className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.Print
                src={Print}
                alt="Imagem com tela do aplicativo do banco Midway"
              />
            </S.TutorialMobile>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.Title>{title}</S.Title>
              <span aria-label="Baixe o APP dos Cartões Riachuelo, consulte limite, pague sua fatura e muito mais.">
                {subtitle}
              </span>
              <S.LinkApp>
                <a
                  href="https://apps.apple.com/br/app/midway-app/id1548732480"
                  title="app store"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Baixe o nosso app na App Store (abre uma nova janela)"
                >
                  <img
                    src={AppleStore}
                    alt="botão redirecionador para download do app da midway na apple store."
                    aria-hidden
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.midway"
                  title="app store"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Baixe o nosso app na Play Store (abre uma nova janela)"
                >
                  <img
                    src={GooglePlay}
                    alt="botão redirecionador para download do app da midway na google play."
                    aria-hidden
                  />
                </a>
              </S.LinkApp>
            </div>
          </div>
        </div>
      </S.TutorialContainer>
    </>
  );
};

LookApp.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  maxHeight: PropTypes.string,
};

LookApp.defaultProps = {
  title: `Olha\no nosso\napp aí!`,
  subtitle:
    'Baixe o APP dos Cartões RCHLO,\nconsulte limite, pague sua fatura\ne muito mais.',
  maxHeight: '522px',
};

export default LookApp;

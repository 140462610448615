/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { IconSignRight, IconBuildingStore } from '@tabler/icons-react';
import { trackingSelectContent } from '~/analytics';
import * as S from './styles';
import Modal from '../../../components/Modal';

const OurCashbacks = () => {
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [isCashbackModalOpen, setIsCashbackModalOpen] = useState(false);

  return (
    <>
      <S.DarkBackground>
        <S.Container>
          <S.TitleContainer>
            <S.Title>Nossos cashbacks</S.Title>
            <S.Text white>
              Quanto mais você compra, mais desconto você tem!
            </S.Text>
          </S.TitleContainer>
          <S.CardContainer>
            <S.Card>
              <S.CardTitle>
                <IconBuildingStore />
                Compras na Riachuelo
              </S.CardTitle>
              <S.CardDescription>
                Garanta 20% de desconto na próxima compra em nossas lojas!
              </S.CardDescription>
              <S.CardText>
                Válido nas compras em nossas lojas com o seu Cartão Riachuelo
              </S.CardText>
              <S.CardText>
                O desconto é limitado a 20% do valor total da nova compra
              </S.CardText>
              <S.Button
                onClick={() => {
                  trackingSelectContent(
                    'confira-as-condicoes-do-desconto',
                    'beneficios'
                  );
                  setIsDiscountModalOpen(true);
                }}
              >
                Confira as condições do desconto
              </S.Button>
            </S.Card>
            <S.Card>
              <S.CardTitle>
                <IconSignRight />
                Compras fora das Lojas Riachuelo
              </S.CardTitle>
              <S.CardDescription>
                Garanta 5% de cashback em compras fora da Riachuelo!
              </S.CardDescription>
              <S.CardText>
                Disponível para uso nas Lojas Riachuelo e vinculado ao CPF
              </S.CardText>
              <S.CardText>
                Exclusivo para clientes selecionados e com Cartão Mastercard
              </S.CardText>
              <S.Button
                onClick={() => {
                  trackingSelectContent(
                    'confira-as-condicoes-do-cashback',
                    'beneficios'
                  );
                  setIsCashbackModalOpen(true);
                }}
              >
                Confira as condições do cashback
              </S.Button>
            </S.Card>
          </S.CardContainer>
        </S.Container>
      </S.DarkBackground>
      <Modal
        title="Condições do cashback"
        isOpen={isCashbackModalOpen}
        onClose={() => setIsCashbackModalOpen(false)}
      >
        <S.ModalContent>
          <S.Text bold>
            Cashback de 5% válido no uso do Cartão Riachuelo Mastercard como
            forma de pagamento em estabelecimentos fora das lojas do Grupo
            Riachuelo, durante o período de um mês e totalizando um valor acima
            de R$1.500,00.
          </S.Text>
          <S.Text>
            O valor do Cashback será considerado como desconto nas compras
            realizadas com o mesmo Cartão nas lojas físicas Riachuelo, FANLAB,
            Carter's ou Casa Riachuelo, exceto para as lojas de Recife, Vitória
            de Santo Antão, Cabo de Santo Agostinho, Jaboatão dos Guararapes,
            Camaragibe, Paulista, Olinda, Sorocaba, Votorantim e Itu, durante o
            mês seguinte (Aapós o dia 10 com validade de 30 dias).
          </S.Text>
          <S.Text>
            O desconto do cashback é limitado a 50% do valor total da compra e
            válido mediante ao uso do Cartão Riachuelo Mastercard como forma de
            pagamento. Não é válido nas compras de produtos das categorias de
            Eletrônicos, Eletroportáteis, Celulares, Relógios e Recarga de
            Celulares. Não é cumulativo e no momento da compra será aplicado o
            maior desconto disponível e atrelado ao CPF do portador do cartão.
          </S.Text>
          <S.Text>
            Em caso de cancelamento da compra, o valor do crédito gerado será
            estornado automaticamente. Para valores em fatura, relacionados a
            Seguros, Assistências e Empréstimos, não serão considerados para o
            cálculo do Cashback de 5% sobre o valor gasto em estabelecimentos
            fora do Grupo Riachuelo.
          </S.Text>
          <S.Text>
            A campanha é condicionada aos clientes que estiverem com seus
            pagamentos em dia (sem atraso ou inadimplência). O limite disponível
            poderá sofrer alterações mediante uso do cartão ou política vigente.
            Para conferir o seu, acesse o aplicativo Riachuelo.
          </S.Text>
        </S.ModalContent>
      </Modal>
      <Modal
        title="Condições do desconto"
        isOpen={isDiscountModalOpen}
        onClose={() => setIsDiscountModalOpen(false)}
      >
        <S.ModalContent>
          <S.Text bold>Compra futura nas Lojas Físicas</S.Text>
          <S.Text>
            O crédito de 20% é gerado sobre o valor total com pagamento através
            do Cartão Riachuelo, exceto produtos das categorias Recarga de
            Celulares. Para utilização do crédito, o valor fica limitado a 20%
            do valor da próxima compra com pagamento exclusivo dos Cartões
            Riachuelo.
          </S.Text>
          <S.Text>
            O crédito estará atrelado ao CPF do comprador durante o período de
            cada mês e será aplicado em sua próxima compra nas lojas físicas a
            partir do primeiro dia após a compra que originou o crédito e até o
            último dia do mês subsequente.
          </S.Text>
          <S.Text>
            O crédito não poderá ser utilizado nas compras de produtos das
            categorias de Eletrônicos, Eletroportáteis, Celulares, Relógios e
            Recarga de Celulares. Em caso de cancelamento da compra, o valor do
            crédito gerado será estornado automaticamente. Cartão sujeito à
            análise e aprovação de crédito para clientes selecionados. O limite
            disponível poderá sofrer alterações mediante uso do cartão ou
            política vigente.
          </S.Text>
          <S.Text>
            Promoção não cumulativa e válida para compras realizadas nas lojas
            físicas Riachuelo, FANLAB, Carter's e Casa Riachuelo, exceto para as
            lojas de Recife, Vitória de Santo Antão, Cabo de Santo Agostinho,
            Jaboatão dos Guararapes, Camaragibe, Paulista, Olinda, Sorocaba,
            Votorantim e Itu. Válida para pagamentos com Cartões Riachuelo, sem
            limite mínimo de valor para a geração do cashback e respeitado o
            limite de crédito do seu cartão Riachuelo.
          </S.Text>
          <S.Text>
            Condição de parcelamento somente para compras nas lojas físicas da
            Riachuelo, com pagamento exclusivo com Cartão Riachuelo, em até 5x
            sem juros ou até 8x sem entrada, com início do pagamento em até 120
            dias e juros de 7,9% ao mês ou 149,03% ao ano + IOF, inclusive sobre
            o período de carência (120 dias), condicionada à data de compra e à
            data de vencimento da fatura. Sujeita a atualização cadastral. O
            limite disponível poderá sofrer alterações mediante uso do cartão ou
            política vigente. Para conferir o seu, acesse o aplicativo
            Riachuelo.
          </S.Text>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default OurCashbacks;

import styled from 'styled-components';
import * as Common from '../styles';

export const DarkBackground = styled.div`
  width: 100%;
  background-color: #221f20;
  margin-bottom: 72px;

  @media screen and (min-width: 1176px) {
    margin-bottom: 144px;
  }
`;

export const Container = styled(Common.Container)`
  @media screen and (min-width: 1176px) {
    padding: 96px 0;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled(Common.Title)`
  color: #fff;
  font-size: 1.5rem !important;
`;

export const ModalTitle = styled(Title)`
  color: #e4602f;
`;

export const Text = styled(Common.Text)``;

export const CardContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    flex-direction: row;
    gap: 16px;
  }
`;

export const Card = styled.div`
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 24px;

  @media screen and (min-width: 900px) {
    flex: 1 0 50%;
  }
`;

export const CardTitle = styled.h4`
  color: #e4602f;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
  vertical-align: center;

  svg {
    flex: 0 0 auto;
    height: 24px;
    width: 24px;
    stroke: #e4602f;
    stroke-width: 1.5px;
    margin-right: 16px;
  }

  @media screen and (min-width: 1176px) {
    font-size: 1.25rem !important;
    font-weight: 500 !important;

    svg {
      height: 32px;
      width: 32px;
      align-items: center;
    }
  }
`;

export const CardDescription = styled(Text)`
  font-weight: 500 !important;
  margin-bottom: 24px !important;
`;

export const CardText = styled(Text)`
  margin-bottom: 8px !important;
  &:last-of-type {
    margin-bottom: 24px !important;
  }
`;

export const Button = styled(Common.Button)``;

export const ModalContent = styled.div`
  ${Text} {
    margin-bottom: 16px !important;

    &:first-of-type {
      margin-bottom: 24px !important;
    }
  }
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  button {
    width: 90%;
    margin-bottom: 1rem;
    svg {
      margin-right: 4px;
    }
  }
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;

  ${({ mtop }) =>
    mtop &&
    css`
      margin-top: 2rem;
    `};
`;

export const Text = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Description = styled.span`
  width: 90%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cacecd;
  padding: 8px 0;
  width: 90%;
  color: #616b6a;

  ${({ bbottomnone }) =>
    bbottomnone &&
    css`
      border-bottom: none;
    `};

  span {
    b {
      font-weight: bold;
    }
  }

  #installment {
    text-align: right;
  }
`;

export const GreenBold = styled.span`
  font-weight: bold;
  color: #009992;
`;

import React from 'react';
import * as S from './styles';

const ListStep = ({ data }) => {
  return (
    <>
      {data.map(value => (
        <S.Container key={value.id}>
          <S.StepIconContainer>
            <S.Icon src={value.icons} />
          </S.StepIconContainer>
          <S.TextContainer>
            <S.TextSection>{value.text}</S.TextSection>
            <S.DescriptionSection>{value.description}</S.DescriptionSection>
          </S.TextContainer>
        </S.Container>
      ))}
    </>
  );
};

export default ListStep;

import {
  AccordionsContainer,
  CategoryContainer,
  CategoryTitle,
  CategoryTitleContainer,
  LastSubjectItem,
  ShowCategoriesContainer,
  Wrapper,
} from './styles';
import React, { useEffect, useState } from 'react';

import Accordion from '~/pages/AttendancePortal/components/Accordion';
import SubjectItem from '~/pages/AttendancePortal/components/SubjectItem';
import WizardTitle from '~/pages/AttendancePortal/components/WizardTitle';
import { getWindowDimensions } from '~/pages/AttendancePortal/utils';
import { trackUserEvent } from '~/pages/AttendancePortal/analytics';
import { useAttendancePortalState } from '~/pages/AttendancePortal/context/AttendancePortalContext';
import { useWizard } from 'react-use-wizard';

const ShowCategories = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { nextStep } = useWizard();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    attendanceState: { categories },
    setCategory,
  } = useAttendancePortalState();

  const handleDropdownClick = index => {
    setActiveDropdown(prevIndex => (prevIndex === index ? null : index));
  };

  const isAccordionOpen = index => {
    return activeDropdown === index || windowDimensions.width >= 1176;
  };

  const handleOtherSubjectClick = category => {
    handleTrackUserEvent(`${category.title}:outros-assuntos`);
    setCategory(category);
    nextStep();
  };

  const handleTrackUserEvent = contentType => {
    trackUserEvent({ contentType });
  };

  return (
    <Wrapper>
      <ShowCategoriesContainer>
        <WizardTitle role="title" title="Selecione um assunto abaixo" />
        <AccordionsContainer>
          {categories.map((category, index) => (
            <Accordion
              key={index}
              title={category.title}
              isOpen={isAccordionOpen(index)}
              triggerChevron
              triggerDisabled={windowDimensions.width >= 1176}
              onDropdownClick={() => handleDropdownClick(index)}
              headerImage={category.img}
              headerContent={
                <CategoryTitleContainer>
                  <CategoryTitle role="title">{category.title}</CategoryTitle>
                </CategoryTitleContainer>
              }
              bodyContent={
                <CategoryContainer>
                  {category.links.map((link, index) => (
                    <SubjectItem
                      key={index}
                      title={link.title}
                      redirectUrl={link.url}
                      onClick={() =>
                        handleTrackUserEvent(`${category.title}:${link.title}`)
                      }
                    />
                  ))}
                  <LastSubjectItem
                    title={'Ver perguntas frequentes'}
                    onClick={() => handleOtherSubjectClick(category)}
                  />
                </CategoryContainer>
              }
            />
          ))}
        </AccordionsContainer>
      </ShowCategoriesContainer>
    </Wrapper>
  );
};

export const MemoizedShowCategories = React.memo(ShowCategories);

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const BaseCardContainer = css`
  border-radius: 17.021px;
  border: 3.292px solid var(--material-theme-sys-light-surface-variant, #dae5e3);
  background: var(--Bsico-3, #fff);
  padding: 8px;
  display: flex;
  outline: none;
  transition: 0.5s ease;
  &:hover,
  &:active {
    color: initial !important;
  }
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
      &:hover,
      &:focus {
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
        outline: none;
      }
    `}
`;

const CardContainer = styled.div`
  ${BaseCardContainer}
`;

const CardLink = styled(Link)`
  ${BaseCardContainer}
  text-decoration: none;
  color: inherit;
`;

const Card = ({ children, onClick, to, ...rest }) => {
  const clickable = Boolean(onClick || to);
  const Container = to ? CardLink : CardContainer;

  return (
    <Container
      to={to}
      onClick={onClick}
      clickable={`${clickable}`}
      role={clickable ? 'button' : undefined}
      tabIndex={clickable ? '0' : undefined}
      {...rest}
    >
      {children}
    </Container>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export default Card;

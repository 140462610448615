import styled, { css } from 'styled-components';

export const Button = styled.button`
  background: #00726d;
  padding: 11px 10px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  border-radius: 24px;
  border: 0;
  width: 100%;
  transition:
    background 0.3s ease 0s,
    width 0.3s ease-in-out 0s;

  ${({ state }) =>
    state === 'secondary' &&
    css`
      color: #00726d;
      background: #fff;
      border: 1px solid #00726d;
    `}
  :hover {
    background: #004a47;

    ${({ state }) =>
      state === 'secondary' &&
      css`
        color: #00726d;
        background: #d6fffe;
      `}
  }

  :disabled {
    background: rgba(0, 128, 122, 0.2);
    cursor: not-allowed;
  }
`;

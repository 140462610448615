import React from 'react';
import { Breadcrumb } from '@midway/web-ui-component';
import AccessibleLink from '~/components/AccessibleLink';
import DiscontinuedFile16 from '~/assets/files/descontinuados/16-como-abrir-um-sinistro-do-seguro-desemprego-riachuelo.pdf';
import DiscontinuedFile17 from '~/assets/files/descontinuados/17-NOVO_MODELO_PERDA_E_ROUBO.pdf';
import history from '~/services/history';
import {
  Container,
  MidwayContent,
  Colum,
  ColumContainer,
  Info,
} from './styles';

const DiscontinuedProducts = () => {
  return (
    <>
      <Container
        id="ready-page-for-screen-reader"
        aria-label="Página de Cartão da Loja Riachuelo carregada"
      >
        <Breadcrumb
          routeBack="/midway"
          textRouteBack="Nossa História"
          actualPageText="Contratos de produtos"
          history={history}
        />
        <MidwayContent className="container">
          <h1>Contratos de produtos</h1>
          <p>Produtos que não são mais comercializados pela Midway</p>
          <ColumContainer>
            <Colum>
              <h2>Condições Gerais Seguro Desemprego PL</h2>
              <ul>
                <li>
                  <AccessibleLink
                    route="/contrato/1-condicoes-gerais-seguro-desemprego-rsa-sura"
                    text="Condições Gerais"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/2-resumo-cg-desemprego-premiavel-comercializado-ate-dez-2014"
                    text="Resumo de Condições Gerais Seguro Desemprego
                    (Comercializado até dezembro 2014):"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/3-resumo-cg-seguro-desemprego-sura-ate-05-11-2017"
                    text="Resumo de Condições Gerais Seguro Desemprego
                    (Comercializado até novembro 2017):"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/4-resumo-cg-desemprego-premiavel-ate-2019"
                    text="Resumode Condições Gerais Seguro Desemprego
                    (Comercializado até setembro 2019):"
                  />
                </li>
              </ul>
            </Colum>
            <Colum>
              <h2>Condições Gerais Seguro Desemprego Bandeira</h2>
              <ul>
                <li>
                  <p>
                    Condições Gerais{' '}
                    <AccessibleLink
                      route="/contrato/5-condicoes-gerais-integra-desemprego-ace-bandeira"
                      text="ACE"
                    />{' '}
                    e{' '}
                    <AccessibleLink
                      route="/contrato/6-condicoes-gerais-na-integra-seguro-desemprego-zurich-cartoes-visa-e-mastercard"
                      text="Zurich"
                    />
                  </p>
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/7-resumo-condicoes-gerais-seguro-desemprego-bandeira-2015"
                    text="Resumo de Condições Gerais Seguro Desemprego Cartões Visa e
                      Master (Comercializado até abril de 2015):"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/8-resumo-condicoes-gerais-seguro-desemprego-bandeira-ate-2017"
                    text="Resumo de Condições Gerais Seguro Desemprego Cartões Visa e
                    Master (Comercializado até outubro de 2017):"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/9-resumo-do-seguro-desemprego-premiavel-visa-e-master-ate-2019"
                    text="Resumode Condições Gerais Seguro Desemprego Cartões Visa e
                    Master (Comercializado até setembro de 2019):"
                  />
                </li>
              </ul>
            </Colum>
            <Colum>
              <h2>Condições Gerais Seguro Perda e Roubo</h2>
              <ul>
                <li>
                  <AccessibleLink
                    route="/contrato/10-condicoes-gerais-na-integra-perda-roubo-ou-furto-de-cartao-zurich"
                    text="Condições Gerais"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/11-resumo-cg-protecao-perda-e-roubo-ate-2013"
                    text="Resumo de Condições Gerais Proteção Perda e Roubo ou Furto
                    dos Cartões Riachuelo Visa e Mastercard (Comercializados até
                    outubro de 2013):"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/resumo-cg-protecao-perda-e-roubo-ate-2017"
                    text="Resumo de Condições Gerais Proteção Perda e Roubo ou Furto
                    dos Cartões Riachuelo Visa e Mastercard (Comercializado até
                    outubro 2017):"
                  />
                </li>
              </ul>
            </Colum>
            <Colum>
              <h2>Seguro Conta Paga</h2>
              <ul>
                <li>
                  <AccessibleLink
                    route="/contrato/12-15414.900031.2019.96"
                    text="Condições Gerais"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/13-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-1"
                    text="Resumo do Seguro Conta Paga - Plano 1 (Comercializados até Janeiro de 2021)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/14-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-2"
                    text="Resumo do Seguro Conta Paga - Plano 2 (Comercializados até Janeiro de 2021)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/15-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-3"
                    text="Resumo do Seguro Conta Paga - Plano 3 (Comercializados até Janeiro de 2021)"
                  />
                </li>
              </ul>
            </Colum>
            <Colum>
              <h2>Seguro Bolsa Protegida</h2>
              <ul>
                <li>
                  <AccessibleLink
                    route="/contrato/CONDICOES-GERAIS-PROTECAO-DE-PORTATEIS–COLETIVO"
                    text="Resumo de Condições Gerais Bolsa Protegida (Comercializada a
                      partir de 06/11/2017)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/Resumo-Condicoes-Gerais-BolsaProtegida-AssistenciaVitimasDeCrimes-ate-11-2017"
                    text="Resumo de Condições Gerais Bolsa Protegida – Assistência à
                    vitimas de crimes (Comercializada até 05/11/2017)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/14-RESUMO-DO-SEGURO-CONTA-PAGA-PLANO-2"
                    text="Resumo do Seguro Conta Paga - Plano 2 (Comercializados até Janeiro de 2021)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/Resumo-Condicoes-Gerais-BolsaProtegida-AssistenciaVitimasDeCrimes-atual"
                    text="Resumo de Condições Gerais Bolsa Protegida – Assistência à
                    vitimas de crimes (Comercializada a partir de 06/11/2017)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/Resumo-Condicoes-Gerais-Bolsa_Protegida"
                    text="Resumo de Condições Gerais Bolsa Protegida
                    vitimas de crimes (Comercializada a partir de 06/11/2017)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/Resumo-Condicoes-Gerais-Perda-e-Roubo"
                    text=" Resumo de Condições Gerais Perda e Roubo (não mais
                      comercializado)"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/Condicoes-Gerais-na-Integra-Bolsa-Protegida"
                    text="Condições Gerais na Íntegra Bolsa Protegida"
                  />
                </li>
                <li>
                  <AccessibleLink
                    route="/contrato/Condicoes-Gerais-na-Integra-Perda-e-Roubo"
                    text="Condições Gerais na Íntegra Perda e Roubo (não mais
                      comercializado)"
                  />
                </li>
              </ul>
            </Colum>

            <Colum>
              <h2>Sinistro</h2>
              <ul>
                <li>
                  <a
                    href={DiscontinuedFile16}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Como abrir um sinistro do Seguro Desemprego?"
                  >
                    Como abrir um sinistro do Seguro Desemprego?
                  </a>
                </li>
                <li>
                  <a
                    href={DiscontinuedFile17}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Formulário Seguro Perda e Roubo"
                  >
                    Formulário Seguro Perda e Roubo
                  </a>
                </li>
                <li>
                  <p>
                    Em caso de Sinistro no <strong>Seguro Conta Paga,</strong>{' '}
                    discar para o telefone abaixo:
                  </p>
                </li>
                <li>
                  <p>4020-4345 de Capitais e Regiões Metropolitanas</p>
                </li>
                <li>
                  <p>0800 285 4345 demais regiões</p>
                </li>
                <li>
                  <p>Ou acesse www.zurich.com.br/atendimento.</p>
                </li>
              </ul>
              <h2>Realocação profissional - Conta Paga</h2>
              <ul>
                <li>
                  <p>
                    Ligue para central de atendimento da assistência 0800 777
                    7820
                  </p>
                </li>
              </ul>
            </Colum>

            <Colum>
              <h2>Seguro Residencial</h2>
              <div>
                <AccessibleLink
                  hasUnderline
                  route="/contrato/Condicoes-gerais-residencial-integra"
                  text="Condições Gerais"
                  data-gtm-event-category="midway:seguros"
                  data-gtm-event-action="clique:link"
                  data-gtm-event-label="link:condicoes-gerais-integra-seguro-residencial"
                />
                <br />
                <br />
                <p className="font-weight-bold">
                  Resumo de Condições Gerais Seguro Residencial
                </p>
                (Comercializado até 5 de novembro de 2017):{' '}
                <AccessibleLink
                  route="/contrato/Resumo-seguro-residencial-ate-2017"
                  text="clique aqui"
                  hasUnderline
                  data-gtm-event-category="midway:seguros"
                  data-gtm-event-action="clique:link"
                  data-gtm-event-label="link:resumo-condicoes-gerais-seguro-residencial-ate-2017"
                />
                <br />
                <p className="font-weight-bold">
                  Resumo de Condições Gerais Seguro Residencial
                </p>
                (Comercializado até 25 de Outubro de 2021):{' '}
                <AccessibleLink
                  hasUnderline
                  route="/contrato/Resumo-seguro-residencial-atual-2020"
                  text="clique aqui"
                  data-gtm-event-category="midway:seguros"
                  data-gtm-event-action="clique:link"
                  data-gtm-event-label="link:condicoes-gerais-integra-seguro-residencial-atual"
                />
              </div>
              <Info>
                <h2>Seguro Seguro Celular</h2>
                <p className="font-weight-bold">
                  Resumo das Condições Gerais Seguro Celular :{' '}
                  <AccessibleLink
                    hasUnderline
                    route="/contrato/Resumo-das-condicoes-gerais-seguro-celular"
                    text="clique aqui"
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:resumo-condicoes-gerais-seguro-celular"
                  />
                </p>
                <p className="font-weight-bold">
                  Condições Gerais na íntegra Seguro Celular :{' '}
                  <AccessibleLink
                    hasUnderline
                    route="/contrato/Condicoes-Integras-Seguro-Celular"
                    text="clique aqui"
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:condicoes-gerais-integra-seguro-celular"
                  />
                </p>
              </Info>
            </Colum>

            <br />
            <Colum>
              <br />
              <br />
              <h2>Assistência Farmácia, Conta Paga</h2>
              <ul>
                <li>
                  <p>
                    Para consultar a rede de atendimento mais próxima, ter
                    acesso a lista de medicamentos e muito mais:
                  </p>
                </li>
                <li>
                  <p>
                    1) Baixe o aplicativo ePharma <br />
                    2) Insira o seu CPF no campo carteirinha
                  </p>
                </li>
                <li>
                  <p>Ou se preferir:</p>
                </li>
                <li>
                  <p>
                    1) Acesse o link{' '}
                    <a
                      href="https://www.epharma.com.br/"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="https://www.epharma.com.br/"
                    >
                      https://www.epharma.com.br/
                    </a>{' '}
                    <br />
                    2) No canto superior direito clique em
                    “MedicamentoseFarmácias”
                    <br />
                    3) Nos campos apresentados na tela, insira o primeiro nome
                    do titular do seguro e o CPF
                    <br />
                    4) Clique em ENTRAR e selecione o plano “Riachuelo – Conta
                    Paga” para começar a busca.
                  </p>
                </li>

                <li>
                  <p>Conte também com a central de atendimento ePharma:</p>
                </li>
                <li>
                  <p>
                    3003-4812 (custo de ligação local) de segunda a sexta-feira
                    das 07:00 às 23:00
                  </p>
                </li>
              </ul>
            </Colum>
            <Colum>
              <h2>Assistência 24hs Residencial</h2>
              <br />
              <p className="font-weight-bold">
                Resumo de Condições Assistência 24hs
              </p>
              (comercializado até 08/08/2019):{' '}
              <AccessibleLink
                hasUnderline
                route="/contrato/Resumo-CondicoesGerais-Assistencia-Residencial"
                text="clique aqui"
                data-gtm-event-category="midway:seguros"
                data-gtm-event-action="clique:link"
                data-gtm-event-label="link:Resumo-CondicoesGerais-Assistencia-Residencial"
              />
              <br />
              <p className="font-weight-bold">
                Seguro de Pessoas Coletivo – Acidentes Pessoais
              </p>
              (comercializado até 08/08/2019):{' '}
              <AccessibleLink
                hasUnderline
                route="/contrato/RESUMO-CONDICOES-ESPECIFICAS-DO-SEGURO-DE-PESSOAS-COLETIVOS-ASS.24HS-RESIDENCIA3"
                text="clique aqui"
                data-gtm-event-category="midway:seguros"
                data-gtm-event-action="clique:link"
                data-gtm-event-label="link:resumo-condicoes-especificas-do-seguro-de-pessoas-coletivos-ass.24hs-residencial3"
              />
            </Colum>
            <Colum>
              <h2>Assistência Veículo 24H</h2>
              <br />
              <p className="font-weight-bold">
                Condições gerais Assistência Veículo 24H
              </p>
              comercializado até 08/08/2019):{' '}
              <AccessibleLink
                route="/contrato/Auto_Resumo-Condicoes-Gerais-Assistencia-Veiculo"
                text="clique aqui"
                hasUnderline
                data-gtm-event-category="midway:seguros"
                data-gtm-event-action="clique:link"
                data-gtm-event-label="link:Auto_Resumo-Condicoes-Gerais-Assistencia-Veiculo"
              />
              <br />
              <p className="font-weight-bold">
                Seguro de Pessoas Coletivos – Acidentes Pessoais
              </p>
              comercializado até 08/08/2019):{' '}
              <AccessibleLink
                route="/contrato/Auto_A_RESUMO-CONDICOES-24HS-VEICULOS1"
                text="clique aqui"
                hasUnderline
                data-gtm-event-category="midway:seguros"
                data-gtm-event-action="clique:link"
                data-gtm-event-label="link:Auto_A_RESUMO-CONDICOES-24HS-VEICULOS1"
              />
            </Colum>
          </ColumContainer>
        </MidwayContent>
      </Container>
    </>
  );
};

export default DiscontinuedProducts;

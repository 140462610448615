/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-await-in-loop */
import {
  capitalize,
  first,
  includes,
  isEmpty,
  isNull,
  join,
  kebabCase,
  keys,
  remove,
  words,
} from 'lodash';
import moment from 'moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useDynamics from '~/hooks/useDynamicsV2';
import CollectionApi from '~/services/CollectionApi';
import { tagMiAjudaCallback, tagMiAjudaView } from '../services/tagging';
import { gtagException } from '../utils/TaggingGA4';
import useNextBusinessDay from '../hooks/useNextBusinessDay';
import useInsiderQueue from '../hooks/useInsiderQueue';

const NegotiationContext = createContext({});
const queryClient = new QueryClient();

const GTAG_FLOW = 'miajuda';

const NegotiationProvider = ({ children }) => {
  const [sendDynamics] = useDynamics();
  const { payDate } = useNextBusinessDay();
  const { toogles } = useSelector(state => state.featuretoggle);
  const { insiderQueueUser } = useInsiderQueue();

  const [clientData, setClientData] = useState({
    document: '',
    birthDate: '',
  });
  const [newClient, setNewClient] = useState(false);
  const [loadingNegotiation, setLoading] = useState(false);
  const [tokenDynamics, setTokenDynamics] = useState('');
  const [showFlow, setShowFlow] = useState(false);
  const [showAlertStatus, setShowAlertStatus] = useState('');
  const [historyPages, setHistoryPages] = useState([]);

  const [sendedTagView, setSendedTagView] = useState(false);
  // tipo da negociaçao cards/loan
  const [type, setType] = useState(/** @type {'cards'|'loan'|''} */ '');
  // Tipo de produto Sicc,tsyssipf etc..
  const [system, setSystem] = useState('');
  // Dados sobre a negociaçao, elegibilidade, contrato selecionado etc..
  const [negotiationData, setNegotiationData] = useState();
  const [duplicateData, setDuplicateData] = useState();
  const [typeNegotiationRegistered, setTypeNegotiationRegistered] =
    useState('');
  const [loadingText, setLoadingText] = useState('');

  const [showModalDetails, setShowModalDetails] = useState(false);

  const [nonNegotiableContracts, setNonNegotiableContracts] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState({});
  const [paymentMethodSelected, setPaymentMethodSelected] = useState('');
  const [hideAgreement, setHideAgreement] = useState(false);

  const negotiable = {
    SICC: ['agreement', 'debit', 'acquittance', 'invoicement'],
    TOPAZ: ['acquittance', 'agreement', 'debit'],
    SIPF: ['debit', 'acquittance', 'agreement'],
    AGREEMENTS: ['acquittance', 'agreement'],
    TSYS: ['debit', 'invoicement'],
  };

  // Controle das negociações por toogle
  const tooglesContracts = {
    SICC: toogles.isEnabledNegotiationSicc,
    TOPAZ: toogles.isEnabledNegotiationTopaz,
    SIPF: toogles.isEnabledNegotiationSipf,
    AGREEMENTS: toogles.isEnabledNegotiationAgreements,
    TSYS: toogles.isEnabledNegotiationTsys,
  };

  const handleModalDetails = async () => {
    setShowModalDetails(!showModalDetails);
  };

  const gaContentPage = page => {
    if (page === 'contracts') return 'selecione-o-contrato-que-deseja-negociar';
    if (page === 'eligibility') return 'visualizacao-de-proposta';
    if (page === 'register') return 'tela-de-comprovante';
    if (page === 'billet')
      return 'pegue-aqui-a-segunda-via-do-boleto-do-seu-acordo';
  };

  const balance = async (systemType, contract, document) => {
    const response = await CollectionApi.getBalance(
      document,
      contract,
      systemType
    );
    if (response.error || !response.detail) {
      return;
    }

    return systemType === 'cards'
      ? response.detail.cards
      : response.detail.loans;
  };

  const processElegibility = eligibility => {
    // Os campos da quitaçao por algum motivo podem retornar zerados
    if (eligibility?.debit) {
      if (isNull(eligibility?.debit?.debitToken)) delete eligibility.debit;
    }
  };

  const getPaymentDate = systemParam => {
    if (
      toogles.isEnablePaymentDate &&
      (systemParam === 'SICC' || systemParam === 'AGREEMENTS')
    )
      return payDate;

    return moment().format('YYYY-MM-DD');
  };

  const eligibility = async ({
    typeSystem,
    contractProps,
    contract,
    hasDuplicate,
    dn,
    system: systemParam,
  }) => {
    setLoadingText('Procurando melhor proposta...');
    setLoading(true);

    const response = await CollectionApi.getEligibility(
      getPaymentDate(systemParam),
      dn,
      contractProps || negotiationData?.details?.contract,
      typeSystem
    );
    const selectedContract =
      contract ||
      first(
        negotiationData?.contracts?.filter(c => c.contract === contractProps)
      );

    // Regra adicionada para enviar clientes em dia que nao tenha parcelamento de fatura para o conta online
    if (
      systemParam === 'SICC' &&
      selectedContract?.overdueDays <= 0 &&
      !response?.detail?.invoicement
    ) {
      setShowAlertStatus('no_debt');
      tagMiAjudaView('sem-pendencias');
      setLoading(false);
      return;
    }
    if (response?.message && !hasDuplicate) {
      // Trata retorno de contratos ativos
      const hasDuplicates =
        response.message.includes('Contrato já possui um acordo') ||
        response.message.includes('possui') ||
        response.message.includes('Nao existe');
      if (hasDuplicates && (hasDuplicate || systemParam === 'TOPAZ')) {
        setLoading(false);
        gtagException(
          gaContentPage('eligibility'),
          GTAG_FLOW,
          kebabCase(response?.message)
        );
        handleHistory('duplicate');
        setShowFlow(true);
        return;
      }
    }

    if (toogles.isEnabled412TOPAZ) {
      if (
        response.status === 412 &&
        response.message ===
          'Falha no sistema de origem, causa: Valor Parcela Nao Permitido'
      ) {
        gtagException(
          gaContentPage('eligibility'),
          GTAG_FLOW,
          kebabCase(response?.message)
        );
        setLoading(false);
        setShowAlertStatus('erro412');
        tagMiAjudaView('erro-generico');
        if (negotiationData?.contracts.length === 1) setNegotiationData();
        return;
      }
    }

    if (
      (response?.error ||
        !response ||
        (response.message && !response.error && response.code !== 200)) &&
      !hasDuplicate
    ) {
      setLoading(false);
      gtagException(
        gaContentPage('eligibility'),
        GTAG_FLOW,
        kebabCase(response?.message)
      );
      setShowAlertStatus('error');
      tagMiAjudaView('erro-generico');
      if (negotiationData?.contracts.length === 1) setNegotiationData();
      return;
    }

    if (isEmpty(response?.detail) && !hasDuplicate) {
      setLoading(false);
      setShowAlertStatus('no_debt');
      tagMiAjudaView('sem-pendencias');
      return;
    }

    if (!response?.detail && !hasDuplicate) {
      setLoading(false);
      tagMiAjudaCallback('eligibility', kebabCase(response.message));
      gtagException(
        gaContentPage('eligibility'),
        GTAG_FLOW,
        kebabCase(response.message)
      );
      toast.error('Ocorreu um erro ao buscar as propostas desse contrato');
      return;
    }

    const responseBalance = await balance(typeSystem, contractProps, dn);

    // Função que processa os dados da elegibilidade pelas regras que há em
    // cada tipo de negociação e contrato
    const eligibilityData = response.detail;
    processElegibility(eligibilityData);
    const newContracts = negotiationData?.contracts || [contract];
    // Seta os valores do negotiationData que serão utilizados na tela de elegibilidade
    setNegotiationData({
      ...negotiationData,
      eligibility: eligibilityData,
      contracts: newContracts,
      gaParamProposta: newContracts?.length > 1 ? 'multipla' : 'unica',
      selectedContract,
      balance: responseBalance,
      overDueDays: selectedContract?.overdueDays,
      overDueDate: moment()
        .subtract(selectedContract?.overdueDays, 'days')
        .format('DD/MM/YYYY'),
      overdueDaysText:
        selectedContract?.overdueDays > 0 ? (
          <>
            {selectedContract?.overdueDays} dias{' '}
            <small>
              (desde{' '}
              {moment()
                .subtract(selectedContract?.overdueDays, 'days')
                .format('DD/MM/YYYY')}
              )
            </small>
          </>
        ) : (
          <>
            {moment()
              .add(Math.abs(selectedContract?.overdueDays), 'days')
              .format('DD/MM/YYYY')}{' '}
            (em {Math.abs(selectedContract?.overdueDays)} dias)
          </>
        ),
      productName: selectedContract?.productDescription?.replace(/[0-9]/g, ''),
    });

    // Manda para tela de 2 via caso tenha
    if (!hasDuplicate) {
      handleHistory('eligibility');
      setShowFlow(true);
    } else {
      handleHistory('duplicate');
      setShowFlow(true);
    }

    setLoading(false);
  };

  const duplicate = async contracts => {
    const newDuplicateData = [];
    await Promise.all(
      contracts.map(async contract => {
        try {
          // if (
          //   contract?.system === 'TOPAZ' &&
          //   !toogles.isEnabledPaymentMethodTopaz
          // )
          //   return;
          const response = await CollectionApi.getUnifiedBillet({
            contract: contract.contract,
            document: concatCPF(contract.documentNumber),
          });
          if (!response?.detail) return;
          newDuplicateData.push({
            ...response.detail,
            contract: contract.contract,
          });
        } catch (error) {
          return error;
        }
      })
    );
    return newDuplicateData;
  };

  const contracts = async ({ token, doc, birthD, isNewClient }) => {
    setNewClient(isNewClient);
    const dn = doc || clientData?.document;
    const bd = birthD || clientData?.birthDate;

    setLoadingText('Procurando seus contratos...');
    setLoading(true);

    if (toogles.isEnabledDataValidation)
      if (isEmpty(dn) || isEmpty(bd)) return setLoading(false);

    const formatBD = moment(bd, 'DD/MM/YYYY').format('YYYY-MM-DD');
    insiderQueueUser({
      birthday: formatBD,
      custom: {
        cpf: CryptoJS.MD5(dn).toString(),
      },
    });

    const response = await CollectionApi.getContracts(dn, formatBD, token);

    if (
      response?.message?.includes('Não existe Cliente para o cpf informado.')
    ) {
      setShowAlertStatus('no_debt_new');
      tagMiAjudaView('sem-pendencias');
      setLoading(false);
      return;
    }

    if (response?.message?.includes('CPF e Data de nascimento não conferem')) {
      toast.error(response.message);
      tagMiAjudaCallback('contracts', kebabCase(response.message));
      gtagException(
        gaContentPage('contracts'),
        GTAG_FLOW,
        kebabCase(response.message)
      );
      setLoading(false);
      return;
    }

    if (!response?.contracts || response.error) {
      setLoading(false);
      tagMiAjudaCallback('contracts', kebabCase(response.message));
      gtagException(
        gaContentPage('contracts'),
        GTAG_FLOW,
        kebabCase(response.message)
      );
      setNegotiationData();
      setShowAlertStatus('error');
      tagMiAjudaView('erro-generico');
      return;
    }

    // Valida contratos não negociáveis no canal
    const negotiableContracts = response.contracts.filter(contract => {
      return (
        includes(keys(negotiable), contract.system) &&
        tooglesContracts[contract.system]
      );
    });

    const nonNegotiableFiltered = response?.contracts?.filter(
      contract => !tooglesContracts[contract.system]
    );

    // Caso tenha contratos não negociáveis envia para tela de status
    if (!isEmpty(nonNegotiableFiltered)) {
      setHistoryPages(['contracts']);
      setShowFlow(true);
      setNegotiationData({
        ...negotiationData,
        contracts: response.contracts,
      });

      setLoading(false);
      return;
    }

    // Busca 2º via de todos os contratos carregados
    const duplicates = await duplicate(response.contracts);
    if (!isEmpty(duplicates)) setDuplicateData(duplicates);

    if (isEmpty(negotiableContracts)) return setLoading(false);
    setNegotiationData({
      ...negotiationData,
      contracts: negotiableContracts,
      gaParamProposta: negotiableContracts?.length > 1 ? 'multipla' : 'unica',
    });

    // Se tiver apenas um contrato pula a tela de listagem de contratos
    if (negotiableContracts.length === 1) {
      // Valida se tem 2 via de boletos e envia para a tela de 2via
      const {
        contract,
        type: firstNegotiableContractType,
        system: firstNegotiableContractSystem,
      } = first(negotiableContracts);

      // Armazena o boleto carregado daquele contrato
      const hasDuplicate = !isEmpty(
        first(duplicates.filter(d => d.contract === contract))
      );
      if (hasDuplicate) {
        setLoading(false);
        setHistoryPages(['duplicate']);
        tagMiAjudaView('segunda-via');
      }
      setSystem(firstNegotiableContractSystem);
      if (isNewClient) {
        setHistoryPages([]);
      }

      await eligibility({
        typeSystem: firstNegotiableContractType,
        contractProps: contract,
        contract: first(negotiableContracts),
        hasDuplicate,
        dn,
        system: firstNegotiableContractSystem,
      });
      return;
    }

    setHistoryPages(['contracts']);
    tagMiAjudaView('multiplos-contratos');
    setLoading(false);
    setShowFlow(true);
  };

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const register = async ({ token, discount, typeProposal, installment }) => {
    setLoadingText('Finalizando negociação...');
    setLoading(true);

    const discharge =
      typeProposal === 'acquittance' || typeProposal === 'debit';

    const installmentsNumber =
      installment ||
      (discharge
        ? 0
        : negotiationData?.eligibility[typeProposal]?.maxInstallmentNumber);

    const body = {
      discount: {
        chargeRate: discount ? discount.maxChargesRate : 0,
        mainRate: discount ? discount.maxMainRate : 0,
      },
      authorizationCodeDiscount: 0,
      installmentsNumber,
      paymentToken: token,
    };

    const response = await CollectionApi.postRegister(body, type);
    if (response?.error || !response.detail) {
      toast.error(response.message);
      setLoading(false);
      tagMiAjudaCallback('register', kebabCase(response.message));
      gtagException(
        gaContentPage('register'),
        GTAG_FLOW,
        kebabCase(response.message)
      );
      return;
    }

    // Boletos TSYS não são assíncronos, então temos um loop com timeout quando TSYS que busca
    // o boleto até que retorne com o status "Sucesso"
    let ms = 0;
    if (system === 'TSYS') {
      ms = 2000;
    }
    const attempts = system === 'TSYS' ? 6 : 0;

    let billet;

    await (async function loop() {
      for (let i = 0; i <= attempts; i++) {
        await delay(ms);
        billet = await CollectionApi.getUnifiedBillet({
          billetId: response.detail.uuid,
        });
        if (billet?.detail?.status === 'Sucesso') i = attempts;
      }
    })();

    if (billet?.error) {
      toast.error(response.message);
      tagMiAjudaCallback('billet', kebabCase(response.message));
      gtagException(
        gaContentPage('billet'),
        GTAG_FLOW,
        kebabCase(response.message)
      );
      setLoading(false);
      return;
    }

    setNegotiationData({
      ...negotiationData,
      registered: billet?.detail || {},
    });
    // Envia o evento que finaliza o fluxo do dynamics indicando que o cliente finalizou a negociação
    sendDynamics({
      event: 'gerou_boleto',
      client: clientData?.document,
      token: tokenDynamics,
    });
    handleHistory('registered');
    setLoading(false);
  };

  // Adiciona nova pagina passada por params no historyPages
  const handleHistory = page => {
    const newHistory = historyPages;
    newHistory.push(page);
    setHistoryPages(newHistory);
  };

  //
  const concatCPF = cpf => {
    let concatDocument = '';
    if (cpf.length < 11) {
      concatDocument = `00000000000${cpf}`.slice(-11);
    } else concatDocument = cpf;

    return concatDocument;
  };

  // Ao clicar ou caso tenha apenas um contrato, é setado dentro do negotiationData o selectedContract
  // Que corresponde ao contrato selecionado, com isso atuaalizamos algumas informaçoes que sao necessárias
  // nas próximas telas
  useEffect(() => {
    if (!isEmpty(negotiationData?.selectedContract)) {
      const {
        name,
        type: selectedContractType,
        system: selectedContractSystem,
      } = negotiationData?.selectedContract;

      setClientData({
        ...clientData,
        name: capitalize(first(words(name))),
        surname: capitalize(
          join(
            remove(words(name), (n, idx) => {
              return idx !== 0;
            }),
            ' '
          )
        ),
      });
      setSystem(selectedContractSystem);
      setType(selectedContractType);
    }
  }, [negotiationData?.selectedContract]);

  useEffect(() => {
    if (!showFlow && first(historyPages) === 'duplicate') setHistoryPages([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFlow]);

  return (
    <QueryClientProvider client={queryClient}>
      <NegotiationContext.Provider
        value={{
          typeNegotiationRegistered,
          setTypeNegotiationRegistered,
          showFlow,
          loadingText,
          setShowAlertStatus,
          setShowFlow,
          showAlertStatus,
          historyPages,
          setHistoryPages,
          system,
          type,
          negotiable,
          contracts,
          balance,
          eligibility,
          register,
          clientData,
          setClientData,
          loadingNegotiation,
          negotiationData,
          duplicateData,
          setDuplicateData,
          setNegotiationData,
          showModalDetails,
          handleModalDetails,
          concatCPF,
          nonNegotiableContracts,
          sendedTagView,
          newClient,
          setSendedTagView,
          tokenDynamics,
          setTokenDynamics,
          setSystem,
          paymentMethods,
          setPaymentMethods,
          paymentMethodSelected,
          setPaymentMethodSelected,
          setNonNegotiableContracts,
          hideAgreement,
          setHideAgreement,
        }}
      >
        {children}
      </NegotiationContext.Provider>
    </QueryClientProvider>
  );
};

const useNegotiation = () => {
  const context = useContext(NegotiationContext);

  if (!context) {
    throw new Error('useNegotiation must be used within NegotiationProvider');
  }

  return context;
};

export { NegotiationProvider, useNegotiation };

import {
  IconCircle,
  MainQuestionIcon,
  MainQuestionsAccordionTitle,
  MainQuestionsTitleContainer,
} from './styles';
import React, { useState } from 'react';

import Accordion from '~/pages/AttendancePortal/components/Accordion';
import Feedback from '~/pages/AttendancePortal/components/Feedback';
import { IconStar } from '@tabler/icons-react';
import InnerHtml from '~/pages/AttendancePortal/components/InnerHtml';
import { trackUserEvent } from '~/pages/AttendancePortal/analytics';
import { useAttendancePortalState } from '~/pages/AttendancePortal/context/AttendancePortalContext';

export function Questions({ items, isStar = false }) {
  const [wasHelpful, setWasHelpful] = useState(Array(items.length).fill(null));
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { subCategory, category } = useAttendancePortalState();

  const handleYesClick = index => {
    setWasHelpful(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleNoClick = index => {
    setWasHelpful(prevState => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const handleDropdownClick = (index, label) => {
    setActiveDropdown(prevIndex => (prevIndex === index ? null : index));
    trackUserEvent({
      contentType: `${subCategory.title}:${category.title}:${label}`,
    });
  };

  return (
    <>
      {items.map((item, index) => (
        <Accordion
          key={index}
          isOpen={activeDropdown === index}
          onDropdownClick={() => handleDropdownClick(index, item.label)}
          headerContent={
            <MainQuestionsTitleContainer>
              <MainQuestionIcon>
                {isStar ? <IconStar /> : <IconCircle>{index + 1}</IconCircle>}
              </MainQuestionIcon>

              <MainQuestionsAccordionTitle>
                {item.label}
              </MainQuestionsAccordionTitle>
            </MainQuestionsTitleContainer>
          }
          bodyContent={
            <Feedback
              onYesClick={() => handleYesClick(index)}
              onNoClick={() => handleNoClick(index)}
              wasHelpful={wasHelpful[index]}
              questionTitle={item.label}
            >
              <InnerHtml raw={item.value} />
            </Feedback>
          }
        />
      ))}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Button } from '@midway/web-ui-component';
import { useDispatch } from 'react-redux';
import AppMidway from '~/assets/img/Financial/lending/AppMidwayMockup.svg';
import { showCallToAction } from '~/store/modules/calltoaction/actions';
import QrCode from '../components/QRcode';
import Breadcrumb from '../components/Breadcrumb';
import * as S from './styles';

const ContractLoan = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 712);
  const dispatch = useDispatch();

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const handleShowModal = () => {
    if (isMobile) {
      window.open('http://onelink.to/ngrm9z', '_blank');
    } else {
      dispatch(showCallToAction(true));
    }
  };
  return (
    <>
      <QrCode />
      <S.Container className="container-fluid">
        <Breadcrumb />
        <S.Content>
          <S.Forms>
            <h1>Gostou da simulação e quer contratar?</h1>
            <h2>
              Entre em contato através de um dos canais para finalizar a
              contratação.
            </h2>
            <S.WrapperContent>
              <img
                src={AppMidway}
                alt="Celular com a tela mostrando a interface do app midway"
              />

              <div>
                <h2>Baixe o App midway</h2>
                <p>
                  Dinheiro na hora. Contrate direto pelo app. A conta Midway é
                  gratuita e você ainda recebe condições especiais.
                </p>
                <Button
                  aria-label="Contratar pelo app"
                  state="primary"
                  title="Contratar pelo app"
                  onClick={handleShowModal}
                />
                <h2>Lojas Riachuelo</h2>
                <p>
                  Vá até uma de nossas lojas físicas e contrate o empréstimo na
                  hora. Encontre a loja mais próxima de você{' '}
                  <a href="https://www.midway.com.br/buscar-lojas/">
                    clicando aqui
                  </a>
                  .
                </p>
              </div>
              <div>
                <h2>Central de Atendimento</h2>
                <p>Dinheiro na conta em até 1 dia útil.</p>
                <p>Capitais e Regiões Metropolitanas</p>
                <S.TextContact>3003 7729</S.TextContact>
                <p>Demais localidades</p>
                <S.TextContact>0800 664 3929</S.TextContact>
                <p>Decreto Federal (SAC)</p>
                <S.TextContact>0800 724 3939</S.TextContact>
                <p className="text-atendimento">
                  Atendimento 24h por dia, todos os dias da semana.
                </p>
                <a
                  href="https://privacidade.grupoguararapes.com.br/politicas-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Política Geral de Privacidade
                </a>
              </div>
            </S.WrapperContent>
          </S.Forms>
        </S.Content>
      </S.Container>
    </>
  );
};

export default ContractLoan;

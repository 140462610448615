import styled from 'styled-components';
import appMockCircle from '~/assets/img/new-home/AppMockCircle.png';
import Background from '../../assets/img/new-home/bgBanner.png';
import coloredFeaturesBg from '../../assets/img/new-home/coloredFeaturesBg.svg';

export const Bold = styled.strong`
  font-weight: bold;
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 900px;
  position: relative;

  .container {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    max-height: 1000px;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      margin-top: 30px;
    }
  }

  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }

  @media screen and (max-width: 480px) {
    .right-side {
      margin-bottom: 100px;
    }
  }

  h1 {
    font: bold 50px Montserrat !important;
    color: #ffffff;
    line-height: 50px;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font: bold 36px Montserrat !important;
      letter-spacing: 0px;
      color: #000000;
      width: 290px;
    }
  }

  h2 {
    margin-top: 50px;
    font-size: 30px !important;
    padding-right: 60px;

    @media screen and (max-width: 480px) {
      padding-right: 0px;
      margin-top: 10px;
      font: 16px Montserrat !important;
      letter-spacing: 0px;
      color: #000000;
      width: 170px;
    }
  }

  .mid-is-rchlo {
    height: 80px;
    position: absolute;
    bottom: 55px;
    right: 0;
  }
  #app {
    width: '100%';
  }

  a {
    background: background: #00726D;
    padding: 20px 0px;
    border-radius: 50px;
    color: #fff;
    font-size: 15px;
    max-width: 300px;
    text-align: center;
    margin-top: 30px;
    transition: all 0.2s ease-out;
    &:hover {
      margin-top: 27px;
      margin-bottom: 2px;
      text-decoration: none;
      color: #47c8cd;
    }
  }
`;

export const Features = styled.section`
  display: flex;

  .center {
    display: flex;
    justify-content: center;
  }

  #midway-logo {
    width: 77px;
    margin-left: 20px;
  }

  .side-by-side {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  .left-side,
  .right-side {
    display: flex;
    flex-direction: column;
    flex: 1;

    #logoWithCard {
      width: 1390px;
      position: absolute;
      margin-left: -28px;
      margin-top: -85px;
    }
    #appContaPremiada {
      width: 768px;
      margin: 1125px -42px 0;
      position: absolute;
    }
    #appContaPremiadaMobile {
      display: none;
    }

    #logoWithCardMobile {
      display: none;
      width: 600px;
      position: relative;
      margin-left: 25px;
      margin-top: 0;
    }

    @media screen and (max-width: 480px) {
      #logoWithCard,
      #appContaPremiada {
        display: none;
      }
      #logoWithCardMobile,
      #appContaPremiadaMobile {
        display: block;
      }
      #appContaPremiada {
        width: 555px;
        margin: 0px 0 60px -15%;
        position: relative;
      }
    }
  }
  .left-side {
    width: calc(50% - 43px);
    @media screen and (max-width: 480px) {
      width: 100%;
      padding: 0 20px;
    }
  }

  .feature-midway-content {
    margin-top: 80px;
    .feature-midway {
      margin-top: 80px;
      margin-bottom: 310px;
      &:last-child {
        margin-bottom: 150px;
      }
      &:hover {
        h2 > img {
          margin-right: 20px;
          margin-left: 5px;
        }
      }
    }
    h2 {
      color: #252525;
      line-height: 30px;
      font: normal bold 20px Montserrat;
      white-space: nowrap;
      img {
        width: 50px;
        display: inline;
        margin-right: 25px;
        margin-bottom: 0px;
        transition: all 0.2s ease-out;
      }
    }
    ul {
      margin-left: 80px;
      font: 20px Montserrat;
      color: #707070;
      li {
        margin-bottom: 35px;
        width: 420px;
      }
    }
    a {
      background-color: #47c8cd;
      padding: 15px;
      color: #000;
      border-radius: 30px;
      display: flex;
      max-width: 290px;
      justify-content: center;
      transition: all 0.2s ease-out;
      font-size: 15px;
      &:hover {
        text-decoration: none;
        background-color: #000;
        color: #47c8cd;
      }
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 60px;
      h2 {
        text-align: center;
        margin: 20px 0;

        img {
          width: 50px;
          display: block;
          margin-right: 0;
          margin-left: calc(50% - 25px);
          margin-bottom: 20px;
        }
      }
      ul {
        margin-left: 0;
        text-align: center;
        li {
          width: 100%;
          a {
            max-width: 100%;
          }
        }
      }
      .feature-midway {
        margin-bottom: 150px;
      }
    }
  }
`;

export const ColoredFeatures = styled.section`
  .side-by-side {
    display: flex;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    @media screen and (max-width: 480px) {
      width: calc(100% - 32px);
      flex-direction: column;
      margin: 0 16px;
    }

    > div {
      flex: 1;
      width: 50%;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
    .coloredBg {
      background-image: url(${coloredFeaturesBg});
      background-position: center;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;

      @media screen and (max-width: 480px) {
        background-size: cover;
      }
    }
  }
  .feature-midway {
    width: 265px;
    padding: 0 25px;
    &:hover {
      h2 img {
        margin-bottom: 11px;
        margin-top: 5px;
      }
    }
    h2 {
      color: #252525;
      line-height: 34px;
      font: bold 20px Montserrat !important;
      margin-bottom: 8px;
      img {
        transition: all 0.2s ease-out;
        width: 40px;
        margin-bottom: 16px;
      }
    }
    ul > li {
      font: normal 18px Montserrat;
    }

    @media screen and (max-width: 480px) {
      margin-bottom: 30px;
      h2 {
        text-align: center;
        img {
          width: 48px;
          margin-left: calc(50% - 24px);
        }
      }
      ul > li {
        text-align: center;
        font-size: 14px;
      }
    }
  }
`;

export const AppDownload = styled.section`
  .bgCircle {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
    background-image: url(${appMockCircle});
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 50%;

    @media screen and (max-width: 480px) {
      background-position: center 260px;
      background-size: 90%;
    }
  }
  .content {
    margin-top: 265px;
    width: 340px;
    margin-left: 220px;

    h2 {
      text-align: left;
      font: bold 40px Montserrat !important;
      color: #252525;
    }
    p {
      font: 24px Montserrat;
      color: #707070;
      margin: 30px 0 25px !important;
    }
    > div {
      display: flex;

      img {
        width: 180px;
        margin-right: 5px;
        transition: all 0.2s ease-out;
        border-radius: 10px;
        border: none;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
        &:hover {
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
          margin-top: -2px;
          margin-bottom: 2px;
        }
      }
    }

    @media screen and (max-width: 480px) {
      margin-top: 50px;
      width: 295px;
      margin-left: 0;

      h2 {
        font-size: 28px !important;
      }
      p {
        font-size: 14px;
      }

      > div img {
        width: 130px;
        margin-right: 20px;
      }
    }
  }
  .mid-is-rchlo {
    margin-top: 130px;
    @media screen and (max-width: 480px) {
      margin-top: 420px;
    }
  }
`;

export const Advantages = styled.section`
  .header {
    margin-left: 110px;
    margin-top: 65px;
    h2 {
      font: bold 40px Montserrat !important;
      color: #252525;
    }

    p {
      font: 24px Montserrat;
      color: #707070;
      padding-right: 10px;
    }

    @media screen and (max-width: 480px) {
      margin-left: 0;
      h2 {
        font: bold 28px Montserrat !important;
      }
      p {
        font: 14px Montserrat;
      }
    }
  }
  .mid-is-rchlo {
    align-self: center;
    width: 175px;
    margin: 100px auto 0;
    @media screen and (max-width: 480px) {
      width: 125px;
      margin: 0 auto;
    }
  }
  .advantages-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 200px;

    .divider {
      margin: 100px 0;
      @media screen and (max-width: 480px) {
        margin: 30px 0 60px;
      }
    }

    .advantage-midway {
      width: 360px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        & > button {
          margin-top: 15px;
          margin-bottom: 5px;
        }
      }

      .icon {
        width: 120px;
      }

      .arrow {
        width: 15px;
        margin: 25px 0 0 50px;
      }

      h2 {
        font: bold 40px Montserrat !important;
        margin: 25px 50px 25px;
      }

      p {
        font: 22px Montserrat;
        color: #707070;
        margin-left: 50px;
      }

      button {
        max-width: 250px;
        margin-left: 50px;
        margin-top: 20px;
        transition: all 0.2s ease-out;
      }
    }
    @media screen and (max-width: 480px) {
      margin-top: 50px;
      .advantage-midway {
        margin-bottom: 60px;
        .icon {
          width: 80px;
        }
        .arrow {
          margin: 25px 0 0 32px;
        }
        h2 {
          font: bold 28px Montserrat !important;
          margin: 25px 0px;
        }
        p {
          margin: 0 120px 0 0;
          font: 14px Montserrat;
        }
        button {
          max-width: 200px;
          margin-left: 0;
        }
      }
    }
  }
`;

export const WrapperHeader = styled.div`
  position: relative;
  z-index: 4;
`;

export const MultipleCard = styled.section`
  height: 1239px;

  .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &:hover {
      #arrow {
        margin-left: 5px;
      }
    }

    .left-side,
    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      #biglogo {
        position: absolute;
        margin-left: -196px;
        width: 1808px;
      }
    }

    #arrow {
      width: 83px;
      padding-top: 385px;
      margin-left: 0px;
      transition: all 0.2s ease-out;
    }

    .content {
      padding-left: 180px;
      width: 515px;

      h2 {
        font: bold 45px Montserrat !important;
        color: #47c8cd;
        text-transform: uppercase;
      }
      p {
        font: 24px Montserrat;
        color: #707070;
        width: 220px;
        margin-top: 32px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    height: 701px;
    .container {
      padding-top: 80px;
      flex-direction: column;
      height: 725px;

      #arrow {
        display: none;
      }
      .left-side,
      .right-side {
        flex-direction: row;

        #biglogo {
          position: relative;
          margin-left: -30px;
          width: 720px;
        }
      }

      .content {
        padding-left: 0;
        width: 100%;
        padding-bottom: 10px;

        h2 {
          font: bold 28px Montserrat !important;
          padding-right: 65px;
        }
        p {
          font: 14px Montserrat;
          margin-top: 15px;
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const OrangeButton = styled.a`
  background-color: transparent;
  text-decoration: underline;
  color: #ff5224;
  font-size: 18px;

  :hover {
    color: #ff5224;
    text-decoration: underline;
  }
`;

export const ContentModal = styled.div`
  padding: 20px;
`;

export const TextContentModal = styled.div`
  margin-bottom: 30px;
`;

export const TextBold = styled.p`
  font-weight: bold;
  font-size: 18px;
`;

export const Description = styled.p`
  font-size: 18px;
`;

export const HeaderModal = styled.div`
  padding-top: 50px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 30px;
`;

export const FooterModal = styled.div`
  width: 100%;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 100px;
`;

export const BackButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 30px;
  background-color: #242424;
  color: white;
  border: none;
`;

export const css = `
  .modal-content {
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .modal-dialog {
    position: fixed;
    top: auto;
    right: auto;
    left: auto;
    bottom: 0;
    margin: 0;
    width: 100%;
    @media (min-width: 576px) {
      max-width: 100% !important;
    }
  };
  .container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
`;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { first, size } from 'lodash';
import { useNegotiation } from '../../../context/negotiationV2';
import { Status } from '../components/Status';
import { tagMiAjudaView } from '../../../services/tagging';

const NonNegotiable = () => {
  const { nonNegotiableContracts, setNonNegotiableContracts } =
    useNegotiation();
  const { toogles } = useSelector(state => state.featuretoggle);

  const { contracts } = nonNegotiableContracts;
  const linkApp = 'https://www.midway.com.br/apps';
  const footerText =
    'Você também pode acionar a nossa central de atendimento através dos números 3004 5417 (capitais e regiões metropolitanas) e 0800 7274417 (demais localidades)';

  const dataToRedirect = {
    TOPAZ: {
      link: 'https://rchlo.love/negocieEP',
      tag: 'view-alert-topaz',
    },
    TSYS: {
      link: 'https://rchlo.love/WhCT',
      tag: 'view-alert-tsys',
    },
  };
  let currentData;

  if (size(contracts) > 1) {
    let hasTsys = false;
    let hasTopaz = false;
    let newData;

    contracts.forEach(contract => {
      if (contract?.system === 'TOPAZ') hasTopaz = true;
      if (contract?.system === 'TSYS') hasTsys = true;
    });

    if ((hasTopaz && hasTsys) || (!hasTsys && hasTopaz)) {
      newData = dataToRedirect.TOPAZ;
      newData.overDueDays = first(
        contracts.filter(
          contract => contract?.system === 'TOPAZ' && contract?.overdueDays > 0
        )
      )?.overdueDays;
      currentData = newData;
    }
    if (hasTsys) {
      newData = dataToRedirect.TSYS;
      newData.overDueDays = first(
        contracts.filter(contract => contract?.system === 'TSYS')
      )?.overdueDays;
      currentData = newData;
    }
  } else {
    const contract = first(contracts);
    dataToRedirect[contract?.system] = {
      ...dataToRedirect[contract?.system],
      overDueDays: contract?.overdueDays,
    };
    currentData = dataToRedirect[contract?.system];
  }

  useEffect(() => {
    if (currentData?.tag && nonNegotiableContracts.has)
      tagMiAjudaView(currentData?.tag);
  }, [nonNegotiableContracts.has, currentData.tag]);

  if (!toogles.isEnableNonNegotiable) return <></>;

  const isPre60 =
    currentData?.overDueDays < 60 &&
    dataToRedirect.TSYS.link === currentData.link;

  return (
    <Status
      status={isPre60 ? 'pre' : 'pos'}
      footer={footerText}
      onAction={() => {
        const link = isPre60 ? linkApp : currentData.link;
        window.open(link, '_blank');
      }}
      onBackPress={() =>
        setNonNegotiableContracts({
          has: false,
        })
      }
    />
  );
};

export default NonNegotiable;

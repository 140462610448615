import styled from 'styled-components';

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
`;
export const IconContainer = styled.div`
  width: 47px;
  height: 47px;
  img {
    width: 100%;
    height: 100%;
  }
`;
export const Title = styled.div`
  h3 {
    font-size: 1.2rem !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media screen and (max-width: 492px) {
      font-size: 1rem !important;
    }
  }
  position: relative;
  &:after {
    content: '';
    height: 3px;
    width: 37.8px;
    background-color: #a06d2c;
    position: absolute;
    bottom: -8px;
  }
`;
export const Description = styled.div``;
export const ActionButton = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  border-radius: 22px;
  background: #221f20;
  border: 1px solid #221f20;
  color: var(--Bsico-3, #fff);
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 19.5px;
  column-gap: 16px;
  padding: 8px 16px;
  &:focus,
  &:active {
    border: 2px solid #a06d2c;
  }
  &:hover {
    border: 1px solid #a06d2c;
  }
`;

import React from 'react';

// eslint-disable-next-line react/prop-types
const ContactItem = ({ icon, title, children }) => {
  return (
    <div className="contact">
      <img src={icon} alt="" />
      <div>
        <span className="title">{title}</span>
        <span>{children}</span>
      </div>
    </div>
  );
};

export default ContactItem;

import React from 'react';
import * as S from './styles';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor onClick={onClick} href={href}>
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

export default Anchor;

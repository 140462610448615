import { useState, useCallback } from 'react';
import { kebabCase } from 'lodash';
import CollectionApi from '~/services/CollectionApi';
import { useNegotiation } from '~/context/negotiationV2';
import { tagMiAjudaButton, tagMiAjudaView } from '~/services/tagging';
import { gtagException } from '../utils/TaggingGA4';

const GTAG_CONTENT_PAGE = 'tela-de-pagamento';
const GTAG_FLOW = 'miajuda';

const useGetCollectionPaymentMethod = () => {
  const [loadingPaymentMethod, setLoading] = useState(false);
  const { register, system, setPaymentMethods, setHistoryPages } =
    useNegotiation();

  const getPaymentMethod = params => {
    setLoading(true);
    CollectionApi.getPaymentMethod(params)
      .then(response => {
        if (response?.error) {
          gtagException(
            GTAG_CONTENT_PAGE,
            GTAG_FLOW,
            kebabCase(response?.message)
          );
        }
        setPaymentMethods(response?.detail?.paymentMethods);
        setHistoryPages(prevHistory => {
          return [...prevHistory, 'paymentMethod'];
        });
        tagMiAjudaView(
          kebabCase(`${system} ${params.typeEligibility} pgto opcao`)
        );
        setLoading(false);
        return response;
      })
      .catch(error => {
        gtagException(GTAG_CONTENT_PAGE, GTAG_FLOW, kebabCase(error?.message));
        setLoading(false);
      });
  };

  const getRegisterBySystem = async ({
    typeProposal,
    token,
    discount,
    installment,
  }) => {
    await register({
      token,
      discount,
      typeProposal,
      installment,
    });
  };

  const actions = useCallback(
    ({ typeProposal, token, discount, installment }) => {
      const service = {
        debit: {
          request: () => {
            tagMiAjudaButton(kebabCase(`${system} ${typeProposal} pagar`));
            getRegisterBySystem({
              token,
              discount,
              typeProposal,
            });
          },
        },
        acquittance: {
          request: () => {
            tagMiAjudaButton(kebabCase(`${system} ${typeProposal} pagar`));
            getRegisterBySystem({ typeProposal, token, discount });
          },
        },
        agreement: {
          request: () => {
            tagMiAjudaButton(kebabCase(`${system} ${typeProposal} pagar`));
            getRegisterBySystem({
              token,
              discount,
              typeProposal,
              installment,
            });
          },
        },
        invoicement: {
          request: () => {
            tagMiAjudaButton(kebabCase(`${system} ${typeProposal} pagar`));
            getRegisterBySystem({
              token,
              typeProposal,
              installment,
            });
          },
        },
      };
      const getKey = () => {
        switch (typeProposal) {
          case 'debit':
            return 'debit';
          case 'acquittance':
            return 'acquittance';
          case 'agreement':
            return 'agreement';
          case 'invoicement':
            return 'invoicement';
          default:
            break;
        }
      };

      return service[getKey()];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [getPaymentMethod, actions, loadingPaymentMethod];
};

export default useGetCollectionPaymentMethod;

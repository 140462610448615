/* eslint-disable import/no-cycle */
import styled from 'styled-components';
import { colors } from '~/constants';
import { MaskedInput } from '~/components/Common/Inputs/MaskedInput';

import { INPUT_STATE } from '.';

export const Container = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

export const Real = styled.div`
  position: absolute;
  top: 22px;

  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};

  pointer-events: none;
`;

export const Input = styled(MaskedInput)`
  width: 100%;

  border: none;
  border-bottom: 1px solid
    ${({ state }) => (state === INPUT_STATE.ERROR ? colors.error : colors.dark)};
  background-color: ${colors.light_gray};
  padding: 4px 8px 8px 28px;

  font-size: 18px;
  font-weight: 500;

  color: ${colors.black};
`;

export const Label = styled.p`
  font-size: 12px;
  font-weight: 400;

  color: ${colors.dark_gray_3};
`;

export const Message = styled.p`
  font-size: 12px;
  font-weight: 500;

  margin-top: 4px;

  color: ${({ state }) =>
    state === INPUT_STATE.ERROR ? colors.error : colors.dark_gray_3};
`;

import styled from 'styled-components';

export const TableHeaderText = styled.th`
  font-size: 20px;
  color: #006464;
  font-weight: bold;
`;

export const Service = styled.th.attrs(() => ({
  colSpan: 3,
}))`
  height: 56px;
  background-color: #50ebe6;
  font-size: 20px;
  color: #006464;
  font-weight: bold;
  border-radius: 20px;
  vertical-align: middle;
  padding: 20px 20px 20px 20px;
  span {
    margin-left: 20px;
    align-self: center;
  }
`;

export const ServiceDescription = styled.div`
  font-size: 16px;
  margin: 20px 20px 20px 20px;
  span {
    color: #ff0000;
  }
`;

export const TdCard = styled.div`
  background-color: #f2f2f2;
  font-size: 16px;
  text-align: center;
  border-radius: 20px;
  padding: 20px 20px 20px 20px;
  margin: 20px 20px 20px 20px;
  align-self: center;
  span {
    color: #ff0000;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding: 0 !important;

  min-height: 450px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  position: relative;
  min-height: 200px;
  max-height: 400px !important;
  padding: 0 !important;

  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }

  div.ahl-title {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 23px;
    text-align: center;

    @media (max-width: 886px) {
      width: 60%;
    }

    @media (max-width: 415px) {
      width: 90%;
    }
  }

  div.ahl-subtitle {
    color: grey;
    font-size: 16px;
    text-align: center;
    padding: 30px;
  }

  div.ahl-checkbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px !important;
      margin: 10px 0px;

      @media (max-width: 630px) {
        font-size: 10px !important;
      }
      @media (max-width: 530px) {
        font-size: 8px !important;
      }
    }
  }

  div.ahl-check {
    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-color: #000;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;

    cursor: pointer;

    margin-right: 5px;

    &-selected {
      img {
        width: 13px;
      }
    }
  }
`;

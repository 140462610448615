export const breakpoints = {
  320: 'min-width: 320px',
  360: 'min-width: 360px',
  480: 'min-width: 480px',
  768: 'min-width: 768px',
  900: 'min-width: 900px',
  1176: 'min-width: 1176px',
  1900: 'min-width: 1900px',
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

import styled, { css } from 'styled-components';

import { colors } from '~/constants';

export const Container = styled.section`
  background: ${colors.light_gray};
  padding: 80px;

  @media (max-width: 1024px) {
    padding: 40px 16px;
  }
`;

export const Content = styled.div`
  max-width: 1176px;
  margin: 0 auto;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 16px;

  @media (max-width: 720px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
`;

export const Text = styled.p`
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 20px;
`;

export const AdvantagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 80px;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 48px;
  }
`;

export const AdvantageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;

  @media (max-width: 1200px) {
    padding: 12px 16px;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      margin: 20px 40px;
      padding: 0px;
    `}
`;

export const AdvantageImageContainer = styled.div`
  margin-bottom: 32px;
`;

export const AdvantageImage = styled.img`
  width: 200px;
  height: 200px;

  ${({ mobile }) =>
    mobile &&
    css`
      width: 160px;
      height: 160px;
    `}
`;

export const AdvantageTitle = styled.h3`
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px;
  margin-bottom: 8px;

  @media (max-width: 720px) {
    font-size: 18px !important;
    line-height: 27px !important;
  }
`;

export const AdvantageText = styled.p`
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 20px;
  width: 296px;
`;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Button, Modal } from '@midway/web-ui-component';
import {
  MaskedInput,
  MASK_TYPES,
} from '~/components/Common/Inputs/MaskedInput';
import IconPen from '~/assets/img/icons/IconPen.png';
import appleStoreIcon from '~/assets/img/apple-store.png';
import googlePlayIcon from '~/assets/img/google-play.png';
import IconClosed from '~/assets/img/icons/IconX.png';
import loanService from '~/services/LoanApi';
import history from '~/services/history';
import { formatterCurrency, validateEmailFormat } from '~/utils/utils';
import AccountHolderLoanStep3 from '../step3/index';
import AccountHolderLoanStep2 from '../step2/index';
import { CartoonistLoanHeader } from './components/header/index';
import AccountHolderLoanHeader from '../../components/header/index';
import { AccountHolderLoanButtons } from '../../components/buttons/index';
import * as S from './styles';

import {
  tagSimulacaoEmprestimoDadosPessoaisCorrentista,
  tagSimulacaoEmprestimoDadosPessoaisCorrentistaErro,
  tagSimulacaoEmprestimoStep1Button,
  tagSimulacaoEmprestimoStep2Button,
} from '../../../../services/tagging';

const AccountHolderLoanStep1 = () => {
  const [loanValue, setLoanValue] = useState();
  const [minValue, _] = useState(100);

  const [showModalContract, setShowModalContract] = useState(false);
  const [valueDisponible, setValueDisponible] = useState();
  const [customerCPF, setCustomerCPF] = useState();
  const [valuePhone, setValuePhone] = useState('');
  const [valueName, setValueName] = useState('');
  const [valueBirth, setValueBirth] = useState('');
  const [valueEmail, setValueEmail] = useState('');

  const [step3, setStep3] = useState(false);
  const [step2, setStep2] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step0, setStep0] = useState(true);

  const appleAppStoreUrl =
    'https://apps.apple.com/br/app/midway-app/id1548732480';
  const googlePlayStoreUrl =
    'https://play.google.com/store/apps/details?id=br.com.midway';

  const [qttPortion, setQttPortion] = useState();
  const [firstDayPayment, setFirstDayPayment] = useState();

  const [simulationLoan, setSimulationLoan] = useState(0);

  const [codPlan, setCodPlan] = useState(null);

  const parseInputValue = value => {
    const loanValueIntPart = value.slice(0, -2);
    const loanValueFloatPart = value.slice(-2);
    const parsedLoanValue = parseFloat(
      `${loanValueIntPart}.${loanValueFloatPart}`
    );
    return parsedLoanValue;
  };

  const ValidatedPhone = useCallback(() => {
    if (valuePhone) {
      if (valuePhone.length >= 10) return true;
    }
    return false;
  }, [valuePhone]);

  const fieldVerification = useMemo(() => {
    if (
      valueName &&
      valueBirth &&
      validateEmailFormat(valueEmail) &&
      ValidatedPhone()
    )
      return false;
    return true;
  }, [valueName, valueBirth, valueEmail, ValidatedPhone]);

  const step0ToStep1 = async () => {
    await loanService
      .getValidateClient({
        cpf: customerCPF,
        nome: valueName,
        dataDeAniversario: valueBirth,
        nacionalidade: 'Brasileiro',
        genero: 'M',
        celular: valuePhone,
        email: valueEmail,
        oferta: '0',
      })
      .then(res => {
        if (res) {
          if (res.isClient && !res.isFraude) {
            const a = setStep0(false);
            const b = setStep1(true);
            tagSimulacaoEmprestimoDadosPessoaisCorrentista('prosseguir');
            return a, b;
          }
          if (!res.isClient && !res.isFraude) {
            tagSimulacaoEmprestimoDadosPessoaisCorrentistaErro(
              'nao-correntista',
              false
            );
            history.push('/emprestimos/nao-correntista/cartao-rchlo');
          } else if (res.isFraude) {
            tagSimulacaoEmprestimoDadosPessoaisCorrentistaErro('fraude', false);
            return setShowModalContract(true);
          } else {
            tagSimulacaoEmprestimoDadosPessoaisCorrentistaErro(
              'api-fraude-resposta-incomum',
              false
            );
            const a = setStep0(false);
            const b = setStep1(true);
            return a, b;
          }
        } else {
          tagSimulacaoEmprestimoDadosPessoaisCorrentistaErro(
            'api-fraude-sem-resposta',
            false
          );
          const a = setStep0(false);
          const b = setStep1(true);
          return a, b;
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const step1ToStep2 = async () => {
    if (loanValue) {
      tagSimulacaoEmprestimoStep1Button('prosseguir');
      await loanService
        .getSimulationLoan({
          cpf: customerCPF,
          valorAFinanciar: parseInputValue(loanValue),
        })
        .then(res => {
          setSimulationLoan(res);
        })
        .catch(error => {
          console.log(error);
        });
      const a = setStep1(false);
      const b = setStep2(true);
      return a, b;
    }
  };

  const step2ToStep3 = async () => {
    const tempCodigoPlano = localStorage.getItem('loanCheckSelected');
    await loanService
      .saveDataLoanSimulation({
        cpf: customerCPF,
        codigoPlano: tempCodigoPlano || simulationLoan[0].codigoPlano,
        carencia: simulationLoan[0].carencia,
        quantidadePrestacoes: simulationLoan[0].quantidadePrestacoes,
        valorPrestacao: simulationLoan[0].valorPrestacao,
        valorTotal: simulationLoan[0].valorTotal,
        dataPrimeiraPrestacao: simulationLoan[0].dataPrimeiraPrestacao,
        taxaJuros: simulationLoan[0].taxaJuros,
        taxaTac: simulationLoan[0].taxaTac,
      })
      .then(res => {
        // setSimulationLoan(res);
      })
      .catch(error => {
        console.log(error);
      });
    tagSimulacaoEmprestimoStep2Button('prosseguir');
    const a = setStep2(false);
    const b = setStep3(true);
    return a, b;
  };

  useEffect(() => {
    setValueDisponible(localStorage.getItem('keyDispLimite'));
    setCustomerCPF(localStorage.getItem('keyCpf'));
  }, []);

  const disabledButtonLoanValue = useCallback(() => {
    if (minValue !== undefined && loanValue) {
      const parsedLoanValue = parseInputValue(loanValue);

      if (
        parsedLoanValue >= minValue &&
        parsedLoanValue <= parseFloat(valueDisponible)
      ) {
        return false;
      }
    }

    return true;
  }, [loanValue, minValue, valueDisponible]);

  const bodyComponent = useMemo(() => {
    if (step0)
      return (
        <S.Container className="container-fluid">
          <S.Content>
            <CartoonistLoanHeader />

            <div className="ahl-title">Dados pessoais </div>

            <input
              placeholder="Nome completo"
              type="text"
              value={valueName}
              onFocus={() =>
                tagSimulacaoEmprestimoDadosPessoaisCorrentista(
                  'clique-campo-nome-completo'
                )
              }
              onChange={e => {
                const { value } = e.target;
                const regex = /^\D+$/;

                if (regex.test(value) || !value) {
                  setValueName(value);
                }
              }}
            />

            <S.BirthDateInput
              placeholder="Data de nascimento"
              type="date"
              inputMode="none"
              value={valueBirth}
              onChange={e => setValueBirth(e.target.value)}
              onFocus={e => {
                tagSimulacaoEmprestimoDadosPessoaisCorrentista(
                  'clique-campo-data-de-nascimento'
                );
              }}
            />

            <>
              <input
                placeholder="E-mail"
                type="email"
                inputMode="email"
                value={valueEmail}
                onFocus={() =>
                  tagSimulacaoEmprestimoDadosPessoaisCorrentista(
                    'clique-campo-email'
                  )
                }
                onChange={e => setValueEmail(e.target.value)}
                className="input-email"
              />
              <div className="input-email-text">
                {valueEmail && !validateEmailFormat(valueEmail)
                  ? 'e-mail inválido!'
                  : ''}
              </div>{' '}
            </>

            <MaskedInput
              maskType={MASK_TYPES.PHONE_NUMBER}
              placeholder="Celular"
              maxLength="14"
              value={valuePhone}
              setValue={setValuePhone}
              onFocus={() =>
                tagSimulacaoEmprestimoDadosPessoaisCorrentista(
                  'clique-campo-telefone'
                )
              }
            />
          </S.Content>

          <S.FooterButton>
            <div className="ahl-button">
              <Button
                aria-label="voltar"
                state="primary"
                block
                outline
                title="Voltar"
                onClick={() => {
                  history.goBack();
                }}
              />
            </div>
            <div className="ahl-button">
              {fieldVerification ? (
                <Button
                  aria-label="Prosseguir com o empréstimo"
                  state="primary"
                  block
                  title="Prosseguir"
                  disabled={fieldVerification}
                />
              ) : (
                <Button
                  aria-label="Prosseguir com o empréstimo"
                  state="primary"
                  block
                  title="Prosseguir"
                  onClick={() => {
                    step0ToStep1();
                  }}
                  disabled={fieldVerification}
                />
              )}
            </div>
          </S.FooterButton>

          {showModalContract && (
            <Modal
              state="white-lighter"
              openModal={setShowModalContract}
              id="ready-modal-for-screen-reader"
              onHide={() => setShowModalContract(false)}
            >
              <S.ModalContent>
                <div
                  className="ahl-icon-closed"
                  onClick={() => setShowModalContract(false)}
                >
                  <img src={IconClosed} alt="" />
                </div>

                <div className="ahl-title">
                  Infelizmente nesse momento não é possível a contratação de
                  empréstimos.
                </div>
                <div className="ahl-subtitle">
                  Mas fique de olho pois atualizamos periodicamente essas
                  informações.
                </div>

                <div className="ahl-middle">
                  <div className="ahl-contract-app-top">
                    <div className="ahl-middle-body-text-bold">
                      Baixe nosso app
                    </div>

                    <div className="ahl-middle-body-images">
                      <a href={appleAppStoreUrl}>
                        <img src={appleStoreIcon} alt="applestoreicon" />
                      </a>
                      <a href={googlePlayStoreUrl}>
                        <img src={googlePlayIcon} alt="googlePlayIcon" />
                      </a>
                    </div>
                  </div>

                  <div className="ahl-middle-body">
                    <div className="ahl-middle-body-text-bold">
                      Entre em contato pelo telefone
                    </div>

                    <div className="ahl-middle-body-text-bold">
                      0800 727 4417
                    </div>

                    <div className="ahl-middle-body-text-small-deficient">
                      Deficientes Auditivos
                    </div>
                    <div className="ahl-middle-body-text-bold">
                      0800 722 0626
                    </div>

                    <div className="ahl-middle-body-text-small">
                      Horário de atendimento 24h por dia, todos os dias da
                      semana, incluindo feriados nacionais.
                    </div>
                  </div>

                  <div className="ahl-middle-body">
                    <div className="ahl-middle-body-text-bold">
                      Vá a uma loja física
                    </div>

                    <div className="ahl-middle-body-text-small">
                      Visite alguma loja física Riachuelo e solicite a
                      contratação de um empréstimo
                    </div>

                    <div className="ahl-contract-app-bottom">
                      <div className="ahl-middle-body-text-bold">
                        Baixe o nosso app
                      </div>

                      <div className="ahl-middle-body-images">
                        <a href={appleAppStoreUrl}>
                          <img src={appleStoreIcon} alt="applestoreicon" />
                        </a>
                        <a href={googlePlayStoreUrl}>
                          <img src={googlePlayIcon} alt="googlePlayIcon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </S.ModalContent>
            </Modal>
          )}
        </S.Container>
      );

    if (step1)
      return (
        <S.Container className="container-fluid">
          <S.Content>
            <AccountHolderLoanHeader />

            <div className="ahl-title">Quanto você precisa?</div>

            <div className="ahl-subtitle">
              Valor disponível para simulação:
              <span className="ahl-value-disponible">
                {` ${formatterCurrency.format(valueDisponible)}`}
              </span>
            </div>

            <div
              className="ahl-input"
              onClick={() => tagSimulacaoEmprestimoStep1Button('editar')}
            >
              <span>R$ </span>
              <MaskedInput
                maskType={MASK_TYPES.BR_CURRENCY}
                placeholder=""
                maxLength="14"
                value={loanValue}
                setValue={setLoanValue}
                pressOnKeyDown={step1ToStep2}
                className="maskedInputStyle"
              />
              <img src={IconPen} alt="" />
            </div>

            <div className="ahl-subtitle">
              {'O valor mínimo é de '}
              {minValue !== undefined && formatterCurrency.format(minValue)}
            </div>
          </S.Content>

          <AccountHolderLoanButtons
            changeButtonNext={step1ToStep2}
            disabledButton={disabledButtonLoanValue()}
            changeButtonReturn={() => {
              tagSimulacaoEmprestimoStep1Button('voltar');
              history.goBack();
            }}
            data-gtm-event-category="midway:valor-disponivel-emprestimo"
            data-gtm-event-action="clique:botao"
            data-gtm-event-label="continuar"
          />
        </S.Container>
      );

    if (step2)
      return (
        <AccountHolderLoanStep2
          propsQttPortion={setQttPortion}
          propsFstDayPay={setFirstDayPayment}
          propsCodPlan={setCodPlan}
          changeButtonNext={step2ToStep3}
          simulationLoan={simulationLoan}
          changeButtonReturn={() => {
            tagSimulacaoEmprestimoStep2Button('voltar');
            setStep1(true);
            setStep2(false);
          }}
        />
      );

    if (step3)
      return (
        <AccountHolderLoanStep3
          loanValue={parseInputValue(loanValue)}
          qttPortion={qttPortion}
          firstDayPayment={firstDayPayment}
          firstInstallment={simulationLoan[0].taxaJuros}
        />
      );
  }, [
    step0,
    valueName,
    valueBirth,
    valueEmail,
    valuePhone,
    fieldVerification,
    showModalContract,
    step1,
    valueDisponible,
    loanValue,
    step1ToStep2,
    minValue,
    disabledButtonLoanValue,
    step2,
    step2ToStep3,
    simulationLoan,
    step3,
    qttPortion,
    firstDayPayment,
    step0ToStep1,
  ]);

  if (!localStorage.getItem('keyCpf')) return history.push('/emprestimos');

  return <>{bodyComponent}</>;
};

export default AccountHolderLoanStep1;

import styled from 'styled-components';

export const Title = styled.h3`
  color: #fff;
  font-weight: bold;
`;

export const Content = styled.div`
  color: #606060;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  button {
    margin: 16px 0;
  }
  span {
    font-size: 12px;
    text-align: center;
  }
`;

export const DigitableLine = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  line-height: 125%;
  text-align: center;
  width: 90%;
  margin: -4rem auto 2rem auto;
`;

export const Header = styled.div`
  background-color: #00726d;
  padding-top: 2rem;
  padding-left: 1rem;
  min-height: 180px;
`;

export const Container = styled.div`
  background-color: #f5f5f5;
`;

export const Amount = styled.span`
  font-weight: bold;
  font-size: 1.25rem;
`;

export const ContainerQRCode = styled.div`
  margin-top: 10px;
`;

import styled from 'styled-components';

export const InformationPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const LeftPanel = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 115px;

  @media (max-width: 1000px) {
    width: 100%;
    margin-right: 0px;
  }
`;
export const Midway = styled.img`
  max-width: 481px;
  max-height: 573px;

  @media (max-width: 1000px) {
    max-width: 297px;
    max-height: 358px;
  }
`;
export const RightPanel = styled.div`
  width: 60%;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 64px;
    flex-direction: column;
  }
`;
export const TextRight = styled.h3`
  font-family: Montserrat;
  font-size: 32px !important;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  margin-bottom: 16px;
  @media (max-width: 1000px) {
    font-size: 24px;
    line-height: 29px;
  }
`;
export const SubTextRight = styled.h6`
  font-family: Montserrat !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px;
  text-align: left;
  color: #3b4443;
  @media (max-width: 1000px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const CentralizeCards = styled.div`
  max-width: 580px;
  max-height: 311px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;

  @media (max-width: 1000px) {
    max-height: 1299px;
  }
`;

export const Options = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
    flex-direction: column;
  }
`;

export const ContainerBlock = styled.div`
  width: 50%;
  display: flex;
  padding-bottom: 5px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 44px;

  @media (max-width: 1000px) {
  }
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 14px;

  @media (max-width: 800px) {
    max-width: 28px;
  }
`;

export const CentralizeText = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  @media (max-width: 800px) {
    font-size: 16px;
    font-weight: 600;
  }
`;
export const RightButton = styled.a`
  background-color: #000;
  border-radius: 120px;
  border: 0px;
  padding: 20px 60px 20px 60px;
  transition: backgroud-color 0.5s ease;
  cursor: pointer;
  color: #fff !important;
  width: 100%;
  max-width: 382px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;

  &:hover {
    background-color: #222;
  }
`;

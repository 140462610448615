import React from 'react';

import history from '~/services/history';
import * as S from './styles';

export const NotCartoonistLoanHeader = () => {
  return (
    <S.ContentHeader>
      <div className="ahl-content-header">
        <span
          className="is-primary-default"
          onClick={() => history.push('/midway')}
        >
          {' Início >'}
        </span>
        <span
          className="is-primary-default"
          onClick={() => history.push('/produtos-financeiros')}
        >
          {' Produtos Financeiros > '}
        </span>
        <span>{' Empréstimo '}</span>
      </div>
    </S.ContentHeader>
  );
};
